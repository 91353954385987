<template>
  <div>

    <WorkSummary
      v-if="count_work_category_summary"
      :work_category_group_label="count_work_category_summary.work_category_group_label"
      :week_cnt="count_work_category_summary.week_cnt"
      :one_team_workpatterns_by_category="count_work_category_summary.workpatterns_by_team_and_category"
      :settings_office_staff_workpatterns="count_work_category_summary.settings_office_staff_workpatterns"
      :settings_office_staffs="count_work_category_summary.settings_office_staffs"
      :assignment="assignment"
      :office_wp_list_assignment="office.wp_list_assignment"
      :team_id="team_id"
      style="margin-bottom: 20px;"
    />

      <VuePerfectScrollbar
      style="font-size: 14px; min-height: calc(95vh - 435px); max-height: calc(95vh - 435px); width: 100%; overflow: auto;">
      <b-table-simple class="table_Permanent">
        <b-thead>
          <b-tr>
            <b-th style="min-width: 230px; max-width: 230px;">勤務区分</b-th>
            <b-th v-for="(day_of_week, key, index) in day_of_weeks" :key="index">
              {{ day_of_week }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(workpattern, workpattern_id) in workpatterns">
            <template v-if="workpattern.type.indexOf('_holidays') === -1
              && workpattern.type.indexOf('_holiday') === -1
              && workpattern.type.indexOf('half_work') === -1">
              <WorkpatternRow
                :index="workpattern_id"
                :workpattern="workpattern"
                :assignment="assignment[workpattern_id]"
                :border_color_style="border_color_style"
                @updateWpAssignment="updateWpAssignment"
                @setChange="setChange"
              />

              <b-tr v-show="('wp_staff_sum_def' + '_' + workpattern_id in staff_sum_def_error_message) && (staff_sum_def_error_message['wp_staff_sum_def_' + workpattern_id] !== '')">
                <b-td></b-td>
                <b-td
                  style="color: #ff0000; font-weight: bold; text-align: center;" nowrap>
                  {{ staff_sum_def_error_message['wp_staff_sum_def_' + workpattern_id] }}
                </b-td>
                <template v-for="index in 8">
                  <b-td></b-td>
                </template>
              </b-tr>
              <b-tr
                v-show="'wp_' + workpattern_id in error_message && error_message['wp_' + workpattern_id] !== '' || 'wp_combi_' + workpattern_id in work_pattern_error_message && work_pattern_error_message['wp_combi_' + workpattern_id] !== ''">
                <b-td style="color: #ff0000; font-weight: bold;" nowrap>
                  {{ work_pattern_error_message['wp_combi_' + workpattern_id] }}
                </b-td>
                <b-td colspan="9" style="color: #ff0000; font-weight: bold;" nowrap>
                  {{ error_message['wp_' + workpattern_id] }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <!-- 勤務区分グループ -->
          <template v-for="(list, key) in wp_list_workpattern_id_list">
            <WpListRow
              :index="key"
              :info="functions.getWpListInfo(workpatterns, list)"
              :assignment="wp_list_assignment[key]"
              :border_color_style="border_color_style"
              @openWpListDialog="openWpListDialog"
              @deleteWpList="deleteWpList"
              @updateWpListAssignment="updateWpListAssignment"
              @setChange="setChange"
            />
            <b-tr
              v-show="'wp_list_' + key in error_message && error_message['wp_list_' + key] !== ''
                      || 'wp_combi_' + key in work_pattern_error_message && work_pattern_error_message['wp_combi_' + key] !== ''">
              <b-td style="color: #ff0000; font-weight: bold;" nowrap>
                {{ work_pattern_error_message['wp_combi_' + key] }}
              </b-td>
              <b-td colspan="9" style="color: #ff0000; font-weight: bold;" nowrap>
                {{ error_message['wp_list_' + key] }}
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
        <b-tfoot class="custom-b-tfoot">
          <b-tr style="text-align:center;" >
            <b-td colspan="2" style="padding: 5px;">最小値の合計/職員数</b-td>
            <b-td v-for="(min_count, day) of minCountListForDay" :key="day" style="padding: 5px;">
              {{ min_count }}/{{ totalStaffCount }}
            </b-td>
          </b-tr>
          <b-tr v-if="!totalStaffCountCheck">
            <b-td colspan="10" style="color:red;padding: 5px;">
              &nbsp;職員数の合計は最小値の合計以上の値を設定してください。この状態では最適なシフトが作成できない可能性があります。
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="10" style="color:red;padding: 5px;">
              &nbsp;{{ sumErrorMessage1 }}<br />
              &nbsp;{{ sumErrorMessage2 }}&nbsp;&nbsp;
              <span v-html="sumErrorMessage3"></span>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <div style="display: flex; width: 100%; justify-content: space-between; padding-right: 35px;">
        <div>
          <b-button class="btn_shift_save" @click="openWpListDialog(-1)">勤務区分グループ登録</b-button>
        </div>
      </div>
    </VuePerfectScrollbar>
    <div style="display: flex; justify-content: space-between; margin-top: 20px; margin-right: 35px;">
      <div>
        <b-button class="btn_white btn_autocreatefooter" @click="beforeSubmit('submit')" :disabled="isDisabled()">保存して閉じる</b-button>
      </div>
      <div>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_back()" :disabled="isDisabled()">戻る</b-button>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_next()" :disabled="isDisabled()">次へ</b-button>
      </div>
    </div>

    <WpListDialog
      ref="wpListDialog"
      :work_categories="work_categories_except_holiday"
      :workpatterns="workpatterns"
      @updateWpList="updateWpList"
    />
  </div>
</template>

<style deep>
table {
  position: relative;
  height: 100%;
  border: 1px solid #DEE2E6 !important;
}
th {
  position: sticky;
  top: 0;
}
td {
  height: 100%;
  padding: 0px;
}
.td_none {
  display: none;
}
.custom-b-tfoot {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn_shift_save {
  width: auto;
  padding: 0px 20px!important;
}
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import functions from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/functions';
import WorkpatternRow from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/AssignmentRow/Workpattern';
import WpListRow from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/AssignmentRow/WpList';
import WpListDialog from '@/Pages/Shifts/Components/Dialog/WpListDialog';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import axios from 'axios';
import WorkSummary from '@/Pages/Workpatterns/Components/WorkSummary.vue';

export default {
  name: 'PermanentPlacement',
  components: {
    VuePerfectScrollbar,
    WorkpatternRow,
    WpListRow,
    WpListDialog,
    WorkSummary,
  },
  props: {
    office: {
      type: Object,
      default: function () {
        return {};
      }
    },
    office_staff_workpatterns: {
      type: Array
    },
    team_id: {
      type: Number,
      default: 0,
    },
    workpatterns: {
      type: Object,
      default: () => {
        return {};
      }
    },
    work_categories: {
      type: Object
    },
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    basic_infos: {
      type: Array,
      default: () => {
        return [];
      }
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
    count_work_category_summary: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      // functions
      functions: functions,

      dialog: false,
      bulk_add_url: '',
      day_of_weeks: {
        def: '一括指定',
        mon: '月',
        tue: '火',
        wed: '水',
        thu: '木',
        fri: '金',
        sat: '土',
        sun: '日',
        hol: '祝'
      },
      assignment: {},
      error_message: {},
      work_pattern_error_message: {},
      staff_sum_def_error_message: {},
      border_color_style: {},
      tr_message: false,
      // work_categoriesからholidayを除いたもの
      work_categories_except_holiday: {},
      // wp_listのassignment
      wp_list_workpattern_id_list: {},
      wp_list_assignment: {},
      // workpattern group選択dialogを開く際に設定
      // どのindexの情報を開いているかを記録する
      target_wp_dialog_index: -1,
      // wp_list_list 新規追加分の中で次に付与されるindex
      next_index: 0,
      // 勤務カテゴリのチェックボックス保持
      select_categories: {},
      // 勤務カテゴリ
      categories_list: {},

      // 初期のassignmentデータ
      initial_assignment: {
        def: { min: 0, max: 0 },
        sun: { min: 0, max: 0 },
        mon: { min: 0, max: 0 },
        tue: { min: 0, max: 0 },
        wed: { min: 0, max: 0 },
        thu: { min: 0, max: 0 },
        fri: { min: 0, max: 0 },
        sat: { min: 0, max: 0 },
        hol: { min: 0, max: 0 },
      },
      childError: {},
      interval_check_auto_creation: null,
      staffCheckValue: {},
      staffSumByWorkpattern: {},
      sumErrorMessage1: "",
      sumErrorMessage2: "",
      sumErrorMessage3: "",
      staffCheckValue: [],
      checkValue: [],
      holidayDays: [],
    }
  },
  watch: {
    'select_categories.night': function (newVal) {
      if (newVal) {
        this.$set(this.select_categories, 'dawn', true);
      } else {
        this.$set(this.select_categories, 'dawn', false);
      }
    }
  },
  methods: {
    // 親画面に変更状態を通知
    setChange(val) {
      this.$emit('setChange', val);
    },
    // ダイアログを開く
    openDialog() {
      this.assignment = this.staff_assignments[this.team_id];
      // wp_listを初期化する team_idのリストが存在しなければ空
      this.wp_list_workpattern_id_list = {};
      this.wp_list_assignment = {};
      let initial = this.office.wp_list_assignment;
      if (initial !== null) {
        if (this.team_id in initial) {
          for (let key in initial[this.team_id]) {
            let group = initial[this.team_id][key];
            for (let index in group.workpattern_id_list) {
              if (this.workpatterns[group.workpattern_id_list[index]] === undefined) {
                // 勤務区分配列に含まれていない場合は削除対象とする
                group.workpattern_id_list = group.workpattern_id_list.filter(x => x !== group.workpattern_id_list[index]);
              }
            }

            if (group.workpattern_id_list !== null && group.workpattern_id_list.length > 1) {
              this.$set(this.wp_list_workpattern_id_list, key, group.workpattern_id_list);
              this.$set(this.wp_list_assignment, key, group.staff_assignment);
            }
          }
        }
      }
      this.initErrorMessage();

      this.staffCheckValue = [];

      this.initErrorForCal();
      for (const workpatternId in this.assignment) {
        for (const daysOfWeek in this.assignment[workpatternId]) {
          this.validate(this.assignment, 'wp', workpatternId, daysOfWeek)
        }
      }

      this.dialog = true;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    // モーダルの外側をクリックした時にフォーカスを当てる
    onClickOutside() {
      this.$refs.modalform.focus();
    },
    // ダイアログ終了
    closeDialog(nextstep = -1) {
      Object.keys(this.wp_list_workpattern_id_list).forEach(key => {
        this.$delete(this.wp_list_workpattern_id_list, key)
        this.$delete(this.wp_list_assignment, key)
      });

      Object.keys(this.error_message).forEach(key => {
        this.$delete(this.error_message, key)
      });
      Object.keys(this.border_color_style).forEach(key => {
        this.$delete(this.border_color_style, key)
      });
      Object.keys(this.staff_sum_def_error_message).forEach(key => {
        this.$delete(this.staff_sum_def_error_message, key)
      });
      // モーダル画面終了
      if (nextstep > -1) {
        // 画面遷移
        this.$emit('nextStep', nextstep);
      } else {
        // モーダル画面終了
        this.$emit('closeDialog');
      }
    },
    // 下限・上限の変更時
    updateWpAssignment(workpattern_id, key, type, value) {
      this.assignment[workpattern_id][key][type] = value;
      this.validate(this.assignment, 'wp', workpattern_id, key);
    },
    // 勤務区分グループの下限・上限の変更時
    updateWpListAssignment(index, key, type, value) {
      this.wp_list_assignment[index][key][type] = value;
      this.validate(this.wp_list_assignment, 'wp_list', index, key);
    },
    // バリデーション
    // a: workpattern_id/index, b: day of week
    validate(list, type, workpatternIdIndex, dayOfWeek) {
      const daysOfWeek = ['def', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'hol'];
      let conditionMet = true;
      for (let index = 0; index < daysOfWeek.length; index++) {
          if (Number(list[workpatternIdIndex][daysOfWeek[index]].max) < Number(list[workpatternIdIndex][daysOfWeek[index]].min)) {
            conditionMet = false;
          }
      }
      const index_error_message = type + '_' + workpatternIdIndex;
      const index_border_color_style = type + '_' + workpatternIdIndex + '_' + dayOfWeek;
      const staff_sum_index_error_message = type + "_staff_sum_def_" + workpatternIdIndex;
      if (!conditionMet) {
        this.$set(this.error_message, index_error_message, '最大値は最小値以上に設定してください。');
        this.$set(this.border_color_style, index_border_color_style, 'border-color: #ff0000');
      } else {
        this.$set(this.error_message, index_error_message, '');
        this.$set(this.border_color_style, index_border_color_style, '');
      }

      const days = new Date(this.year, this.month, 0).getDate();
      const holidayCount = this.getHolidayCount(this.year, this.month);

      let maxValues = {};
      let workpatternVal = 0;

      for (let day of daysOfWeek) {
        if (day === 'def') continue;
        if (day === 'hol') {
          maxValues[day] = list[workpatternIdIndex]["hol"].max * 1 === 0 ? list[workpatternIdIndex]["def"].max * holidayCount : list[workpatternIdIndex]["hol"].max * holidayCount;
        }
        else {
          maxValues[day] = list[workpatternIdIndex][day].max * 1 === 0 ? list[workpatternIdIndex]["def"].max * this.countDaysMonth(this.year, this.month, day) : list[workpatternIdIndex][day].max * this.countDaysMonth(this.year, this.month, day);
        }
      }

      let maxSum = Object.values(maxValues).reduce((acc, val) => acc + val, 0);

      Object.keys(list).map(workpattern_id => {
        if (!this.staffCheckValue[workpattern_id]) {
          this.staffCheckValue[workpattern_id] = [];
        }
        // 一括指定の場合
        if (daysOfWeek.every(day => list[workpatternIdIndex][day].max * 1 === 0)) {
          return 0;
        }

        if (maxSum < this.staffSumByWorkpattern[workpattern_id]) {
          this.staffCheckValue[workpattern_id][dayOfWeek] = this.staffSumByWorkpattern[workpattern_id] - maxSum;
        }
        workpatternVal = this.staffSumByWorkpattern[workpatternIdIndex] - maxSum;
      });

      this.updateErrorAndCheckValues(staff_sum_index_error_message, workpatternVal, workpatternIdIndex);

      if (this.checkValue.length !== 0) {
        this.sumError(this.staffCheckValue);
      }
      else {
        this.initErrorMessage();
      }

      let is_error = false;
      for (let i in this.border_color_style) {
        if (i.indexOf(workpatternIdIndex) === -1) continue;
        if (this.border_color_style[i] !== '') {
          is_error = true;
          break;
        }
      }
      if (!is_error) {
        this.error_message[index_error_message] = '';
      }
    },
    // 勤怠区分の重複チェック
    check_list_workpattern() {
      // 常設されている勤務区分リストの抽出
      let permanent_work = [];
      for (const id in this.workpatterns) {
        if (this.workpatterns[id].type.indexOf('_holidays') === -1
          && this.workpatterns[id].type.indexOf('_holiday') === -1
          && this.workpatterns[id].type.indexOf('half_work') === -1) {
          permanent_work[id] = this.workpatterns[id];
        }
      }
      // 追加された勤務区分より重複された組み合わせがあるかチェックする
      Object.keys(this.wp_list_workpattern_id_list).forEach(key => {
        let is_error = false;
        // 常設されている勤務区分リストの中に重複があるかをチェック
        if (this.wp_list_workpattern_id_list[key].length === 1) {
          // 複数組み合わせの場合は常設分と重複しないはずなのでチェックせず、単一の勤務区分が指定されている場合のみチェックを行う
          Object.keys(permanent_work).forEach(index => {
            if (this.wp_list_workpattern_id_list[key][0] === index) {
              // 同じ勤務区分があればエラー扱いとする
              this.$set(this.work_pattern_error_message, 'wp_combi_' + key, '勤務区分の組み合わせが重複しています。');
              is_error = true;
              return;
            }
          });
        }
        if (is_error) {
          // 既にエラー済みである場合は次の勤務区分を検査する
          return;
        }

        // 追加された勤務区分の中に重複があるかチェック
        Object.keys(this.wp_list_workpattern_id_list).forEach(key2 => {
          if (key === key2) {
            // 自身のチェックは除外する
            return;
          }
          // 指定されている勤務区分全てを検証する
          let is_dup = false;
          this.wp_list_workpattern_id_list[key].forEach(id => {
            if (is_dup) {
              // 既に重複ありと判断している場合はチェックをスキップ
              return;
            }
            if (this.wp_list_workpattern_id_list[key2].includes(id)) {
              // 同一の勤務区分が含まれている場合はエラーとみなす
              is_dup = true;
            }
          });
          if (is_dup) {
            // 勤務区分の組み合わせが重複している場合はエラー扱いとする
            is_error = true;
            this.$set(this.work_pattern_error_message, 'wp_combi_' + key, '勤務区分の組み合わせが重複しています。');
          }
        });
      });
    },
    // 保存前処理（勤怠区分の重複チェック）
    beforeSubmit(kind) {
      // エラー情報のリセット
      this.work_pattern_error_message = [];

      if (this.wp_list_workpattern_id_list === null
          || this.wp_list_workpattern_id_list === undefined
          || this.wp_list_workpattern_id_list.length === 0)
      {
        if (kind === 'submit') {
          // 追加された勤務区分がない場合は保存処理へ移行
          this.submit();
        } else if (kind === 'autoCreate') {
          this.autoCreate();
        }
        return;
      }

      // 追加された勤務区分より重複された組み合わせがあるかチェックする
      this.check_list_workpattern();
      if (Object.keys(this.work_pattern_error_message).length === 0) {
        if (kind === 'submit') {
          // 保存処理へ移行
          this.submit();
        } else if (kind === 'autoCreate') {
          // 自動作成へ移行
          this.autoCreate();
        }
      }
    },
    // ボタンの制御
    isDisabled () {
      let is_ok = true;
      let error_message = '';
      // 上限下限
      for (let key in this.error_message) {
        if (this.error_message[key] !== '') {
          is_ok = false;
          error_message = this.error_message[key] +'<br>';
          break;
        }
      }

      // 勤務区分の組み合わせが重複
      this.check_list_workpattern();
      for (let key in this.work_pattern_error_message) {
        if (this.work_pattern_error_message[key] !== '') {
          is_ok = false;
          error_message += this.work_pattern_error_message[key] +'<br>';
          break;
        }
      }

      if (is_ok) {
        this.$emit('setchildError', false);
        return false;
      } else {
        this.$emit('setchildError', true, error_message);
        return true;
      }
    },
    isSaveNext() {
      let inputError = this.isDisabled();
      let categoriesError = Object.values(this.select_categories).every(value => !value);
      return inputError || categoriesError;
    },

    // 戻る
    save_back() {
      // 保存処理
      this.submit(false, false, 4);
    },
    // 次へ
    save_next() {
      // 保存処理
      this.submit(false, false, 6);
    },
    // 与えられた年、月、曜日に対応する週の特定の曜日が何回出現するかをカウントする関数
    countDaysMonth(year, month, day) {
      // 曜日の配列
      const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      // 月の日数を取得
      const daysInMonth = new Date(year, month, 0).getDate();
      let daysMonth = [];
      // 月の全ての日に対して処理を行うループ
      for (let i = 1; i <= daysInMonth; i++) {
        var d = new Date(year, month - 1, i);
        // 日付から曜日を取得
        var dayName = days[d.getDay()];
        daysMonth.push(dayName);
      }
      // 特定の曜日が何回出現するかをカウント
      let weekDays = this.countOccurrences(daysMonth, day);
      // 祝日の場合、カウントから祝日の数を引く
      if (this.holidayDays[day]) {
        weekDays = weekDays - this.holidayDays[day];
      }
      return weekDays;
    },
    // 指定された年と月の間の祝日の数を取得する関数
    getHolidayCount(year, month) {
      // 開始日を設定
      const startDate = new Date(year, month - 1, 1);
      // 終了日を設定
      const endDate = new Date(year, month, 0);
      // 指定された期間内の祝日を取得
      const holidays = holiday_jp.between(startDate, endDate);
      // 祝日の数を返す
      return holidays.length;
    },
    // 配列内の特定の要素の出現回数をカウントする関数
    countOccurrences(arr, element) {
      let count = 0;
      // 配列を繰り返し処理して特定の要素の出現回数をカウント
      arr.forEach(item => {
        if (item === element) {
          count++;
        }
      });
      // 出現回数を返す
      return count;
    },
    updateErrorAndCheckValues(index_error_message, workpatternVal, workpatternIdIndex) {
      const errorKey = `staff_sum_def_error_message`;

      if (workpatternVal > 0) {
        this.$set(this[errorKey], index_error_message, `${workpatternVal}日超過`);
        if (!this.checkValue.includes(`def_${workpatternIdIndex}`)) {
          this.checkValue.push(`def_${workpatternIdIndex}`);
        }
      } else {
        this.$set(this[errorKey], index_error_message, "");
        if (this.checkValue.includes(`def_${workpatternIdIndex}`)) {
          this.checkValue = this.checkValue.filter(e => e !== `def_${workpatternIdIndex}`);
        }
      }
    },
    submit(message_flg = true, close_flg = true, nextstep = -1) {
      let is_ok = true;
      for (let key in this.error_message) {
        if (this.error_message[key] !== '') {
          is_ok = false;
          break;
        }
      }
      if (is_ok) {
        this.savePlacement(this.assignment, this.wp_list_workpattern_id_list, this.wp_list_assignment, 'save', message_flg, close_flg, nextstep);
      } else {
        this.$swal.fire({
          icon: 'error',
          text: '最大値は最小値以上に設定してください。'
        })
      }
    },
    autoCreate() {
      if (Object.keys(this.assignment).length === 0) {
        // URLの作成
        let url = this.$route('staffs.workcons.bulk-add');
        if (url.slice(-1) === '/') {
          url = url.slice(0, -1);
        }
        url += '?office=' + this.office.id;
        url += '&type=1';

        this.$swal({
          html: '<a href="' + url + '">勤務条件一括登録画面</a>にて職員へ勤務区分を割り当ててください。',
          icon: 'warning',
          confirmButtonText: '閉じる',
        })
        return;
      }
      // 常時配置人数設定(max,min)がすべて0の場合はエラー
      if (!this.checkStaffAssignmentNum()) {
        this.$swal.fire({
          icon: 'error',
          text: '常時配置人数設定では、最低1つの勤務区分に対して、上限値を1以上に設定する必要があります。'
        })
        return;
      }

      let is_ok = true;
      for (let key in this.error_message) {
        if (this.error_message[key] !== '') {
          is_ok = false;
          break;
        }
      }
      if (is_ok) {
        this.savePlacement(
          this.assignment, this.wp_list_workpattern_id_list,
          this.wp_list_assignment, 'auto_create'
        );
      } else {
        this.$swal.fire({
          icon: 'error',
          text: '最大値は最小値以上に設定してください。'
        })
      }
    },
    // 常時配置人数 保存処理
    savePlacement(assignment, wp_list_workpattern_id_list, wp_list_assignment, type, message_flg, close_flg, nextstep) {
      this.updateLoading(true);
      const params = {
        year: this.year,
        month: this.month,
        office: this.office.id,
      };
      axios.post(this.$route('shifts.schedule.assign', params),
        {
          team_id: this.team_id,
          assignment: assignment,
          wp_list_workpattern_id_list: wp_list_workpattern_id_list,
          wp_list_assignment: wp_list_assignment,
        }).then(result => {
          this.updateLoading(false);
            if (type === 'auto_create') {
              this.execAutoCreate();
            } else {
              if (message_flg) {
                this.$swal('保存しました', '', 'success');
              }
              if (close_flg) {
                // モーダル画面終了
                this.closeDialog();
              } else if(nextstep > 0) {
                // ひとつ前画面へ遷移
                this.closeDialog(nextstep);
              }
            }
        })
        .finally(() => {
          this.updateLoading(false);
        });

    },
    // 自動作成実行
    execAutoCreate() {
      // ロックの有無をチェックする
      this.$swal({
          text: '現在配置されているシフトをロックしてシフトを作成しますか？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ロックしてシフト作成',
          cancelButtonText: 'ロックせずにシフト作成',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
      }).then((result) => {
        if (result.dismiss && result.dismiss !== this.$swal.DismissReason.cancel) {
          return;
        }

        if (this.summary.status !== 'opened') {
          // ロックする場合は、シフトを保存する
          if (result.isConfirmed) {
            EventBus.$emit('shift-save-with-lock');
          } else {
            this.startAutoCreate();
          }
        } else {
          this.$swal({
            text: '作成対象のシフトは、すでに職員に展開済みです。自動作成を実行すると、現在確定しているシフトが変更されますので、確認後、「職員へ展開」ボタンをクリックして、最新のシフトを職員に展開してください。',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'はい',
            cancelButtonText: 'いいえ',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result2) => {
            if (result2.isConfirmed) {
              // ロックする場合は、シフトを保存する
              if (result.isConfirmed) {
                EventBus.$emit('shift-save-with-lock');
              } else {
                this.startAutoCreate();
              }
            }
          });
        }
      });
    },
    startAutoCreate() {
      const params = {
        year: this.year,
        month: this.month,
        office: this.office.id,
      };
      const work_categories = Object.keys(this.select_categories).filter(key => this.select_categories[key]);
      this.updateLoading(true);
      this.$emit('updatePreparingFlg', true);
      this.$inertia.post(
        this.$route('shifts.schedule.auto-create', params),
        {
          team_id: this.team_id,
          work_categories: work_categories,
        },
        {
          onSuccess: () => {
            if (this.summary.is_optimizing) {
              this.$swal({
                html: '自動作成処理を開始しました。<br>二重で実行すると先に実行した結果は<br>上書きされます。',
                icon: 'warning',
              });
              this.checkAutoCreationStatus();
            } else if (this.summary.optimization_result === 6) {
              // 最適化ステータスがエラーならalert表示
              this.$swal('予期せぬエラーが発生しました。画面右下の「お困りですか？」をクリックし、「担当者へ問い合わせしたい」からメールアドレスを入力し、お問い合わせください。', '', 'warning');
              this.$inertia.post(
                this.$route('shifts.schedule.auto-create-status', params),
                {
                  optimization_result: 0,
                },
                {
                  onSuccess:()=>{
                    //
                  }
                }
              );
            }
          },
          onError: () => {
            this.$swal('自動作成の実行に失敗しました。', '', 'error');
          },
          onFinish: () => {
            this.updateLoading(false);
            this.$emit('updatePreparingFlg', false);
            // モーダル画面終了
            this.closeDialog();
          }
        }
      );
    },
    async execCheckStatus() {
      const params = {
        year: this.year,
        month: this.month,
        office: this.office.id,
      };
      const url = this.$route('shifts.schedule.auto-create.check', params);
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const res = await fetch(url, options);
        if (!res.ok) {
          throw new Error('エラーが発生しました');
        } else {
          const data = await res.json();
          if (data.success && !data.data.is_creating) {
            clearInterval(this.interval_check_auto_creation);
            this.interval_check_auto_creation = null;
            // すべての自動作成処理が完了したら画面をリロードする
            setTimeout(() => {
              this.$inertia.reload();
            }, 30000);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
     // 自動作成処理の状況確認
    checkAutoCreationStatus() {
      if (this.interval_check_auto_creation !== null) return;

      this.interval_check_auto_creation = setInterval(this.execCheckStatus, 30000);
    },
    // 常時配置人数設定(max,min)がすべて0の場合はエラー
    checkStaffAssignmentNum() {
      // オブジェクトのvalueが全て0であるかのチェック
      const checkZeroWorkpattern = Object.keys(this.assignment).map((workpattern_id) => {
        const workpattern = this.workpatterns[workpattern_id]
        // workpattern削除済み（idがない）だがoffice_staff_workpatternに残っているデータはチェック対象外
        if(!workpattern) return false
        // 休日系の勤務区分はチェック対象外
        if(workpattern.type.indexOf('_holidays') !== -1 || workpattern.type.indexOf('_holiday') !== -1) return false
        // 半日勤務系の勤務区分はチェック対象外
        if(workpattern.type.indexOf('half_work') !== -1) return false

        // 勤務区分のmin,maxがすべて0であるかのチェック
        const office_staff_workpattern = this.assignment[workpattern_id]
        const checkZeroMinMax =  Object.values(office_staff_workpattern).map((value) => {
          // 曜日（def含む）ごとのmin,maxがすべて0であるかのチェック
          if (Number(value.min) > 0 || Number(value.max) > 0) {
            return true
          }
          return false
        }).filter(Boolean) // falseをフィルタリング
        // 一つでもtrueならtrueを返す
        if (checkZeroMinMax.length > 0) {
          return true
        }
        return false
      }).filter(Boolean)

      // 一つでもtrueならtrueを返す
      return checkZeroWorkpattern.length > 0;
    },
    // 勤務区分選択ダイアログ表示
    openWpListDialog(index) {
      let list = [];
      if (index !== -1) {
        // 編集処理
        this.target_wp_dialog_index = index;
        list = this.wp_list_workpattern_id_list[index];
      } else {
        this.target_wp_dialog_index = -1;
        list = [];
      }
      this.$refs.wpListDialog.openDialog(list);
    },
    // 勤務区分選択ダイアログから返却
    updateWpList(list) {
      // 親画面に変更を通知
      this.$emit('setChange', true);

      if (this.target_wp_dialog_index === -1) {
        this.$set(this.wp_list_workpattern_id_list, 'new_' + this.next_index, list);
        this.$set(this.wp_list_assignment, 'new_' + this.next_index, structuredClone(this.initial_assignment));
        this.next_index++;
      } else {
        this.$set(this.wp_list_workpattern_id_list, this.target_wp_dialog_index, list);

      }
      this.target_wp_dialog_index = -1;
    },
    // 勤務区分グループの削除
    deleteWpList(index) {
      this.$delete(this.wp_list_workpattern_id_list, index);
      this.$delete(this.wp_list_assignment, index);
      this.$delete(this.work_pattern_error_message, 'wp_combi_' + index);
      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
    // loadingの制御
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // 勤務条件一括登録で割り当っている勤務区分ごとの下限合計値が、常時配置人数の上限設定値×歴日分の合計値を超えた場合に以下のようなアラート
    sumError() {
      let query_strings = [];

      query_strings.push('office=' + this.office.id);
      if (this.team_id !== 0) {
        query_strings.push('team=' + this.team_id);
      }
      query_strings.push('type=2');
      query_strings.push('year=' + this.year);
      query_strings.push('month=' + this.month);

      let newUrl = this.$route('staffs.workcons.bulk-add');

      if (query_strings.length > 0) {
        newUrl += '?' + query_strings.join('&');
      }

      this.sumErrorMessage1 = "勤務回数の下限合計値が、1か月のシフト表に配置できる職員数の上限を超えており、最適なシフトを作成できない可能性があります。";
      this.sumErrorMessage2 = "勤務回数を指定する画面で、勤務区分に対する勤務回数の下限の合計値が、（勤務区分ごとの常時配置人数の上限値）×（月間の日数）以下となるように調整してください。";
      this.sumErrorMessage3 = "　<a href='" + newUrl + "'>【勤務回数画面で修正する】</a>";

    },
    initErrorMessage() {
      this.sumErrorMessage1 = "";
      this.sumErrorMessage2 = "";
      this.sumErrorMessage3 = "";
    },
    initErrorForCal() {
      this.staffSumByWorkpattern = {};
      const workpattern_ids = Object.values(this.workpatterns).map(item => item.id);
      let office_staff_ids = [];
      if (this.team_id === 0) {
        office_staff_ids = this.basic_infos;
      }
      else if (this.team_id === -1) {
        office_staff_ids = Object.values(this.basic_infos).filter(item => item.team_id === null);
      }
      else {
        office_staff_ids = Object.values(this.basic_infos).filter(item => item.team_id === this.team_id);
      }
      office_staff_ids = Object.values(office_staff_ids).map(item => item.office_staff_id);

      const staffValue = [];
      this.office_staff_workpatterns.forEach(element => {

        if (office_staff_ids.includes(element.office_staff_id)) {
          if (workpattern_ids.includes(element.workpattern_id)) {
            staffValue.push(element);
          }
        }
      });


      for (let i = 0; i < staffValue.length; i++) {
        const workPattern = staffValue[i].workpattern_id;
        const count = staffValue[i].min_monthly_working_count;

        if (this.staffSumByWorkpattern[workPattern]) {
          this.staffSumByWorkpattern[workPattern] += count;
        } else {
          this.staffSumByWorkpattern[workPattern] = count;
        }
      }

    },
    initHoliday() {
      //祝日に含まれる曜日に対するロジック
      const startDate = new Date(this.year, this.month - 1, 1);
      const endDate = new Date(this.year, this.month, 0);
      const holidays = holiday_jp.between(startDate, endDate);
      let holidayWeekdays = [];
      holidays.forEach(holiday => {
        switch (holiday.week_en) {
          case "Monday":
          case "Tuesday":
          case "Wednesday":
          case "Thursday":
          case "Friday":
          case "Saturday":
          case "Sunday":
            const day = holiday.week_en.toLowerCase().slice(0, 3); // Get the first three letters of the day
            holidayWeekdays[day] = (holidayWeekdays[day] || 0) + 1;
            break;
          default:
            break;
        }
      });
      this.holidayDays = holidayWeekdays;
    }

  },
  computed: {
    // 有効な職員数
    totalStaffCount() {
      this.initHoliday();
      // basic_infosからstaff_id数を取得
      return (new Set(this.basic_infos.map(basic_info => basic_info.staff_id))).size;
    },

    // 常時配置人数のminの合計
    minCountListForDay() {
      // 勤務区分合計のmin
      let total_min_for_day = {}
      Object.keys(this.assignment).map(workpattern_id => {
        // dayごと（縦列）の合計
        Object.keys(this.assignment[workpattern_id]).map(day => {
          // dayがdefなら飛ばす
          if (day === 'def') return
          // 初期化
          if (total_min_for_day[day] === undefined) {
            total_min_for_day[day] = 0
          }
          total_min_for_day[day] += Number(this.assignment[workpattern_id][day].min) || Number(this.assignment[workpattern_id]['def'].min)
        })
      })

      // mon,tue,wed,thu,fri,sat,sun,holの順に並び替え
      const day_of_weeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'hol']
      const total_min_for_day_sorted = {}
      day_of_weeks.forEach(day => {
        total_min_for_day_sorted[day] = total_min_for_day[day]
      })
      return total_min_for_day_sorted;
    },
    // 曜日のうち一つでもNGならfalseを返す
    totalStaffCountCheck() {
      return Object.keys(this.minCountListForDay).find(day => this.minCountListForDay[day] > this.totalStaffCount) === undefined
        ? true
        : false
    },
  },
  mounted() {
    this.openDialog();
    this.work_categories_except_holiday = { ...this.work_categories };
    delete this.work_categories_except_holiday.holiday;

    // 明番を削除して、「夜勤」を「夜勤・明番」にする
    this.categories_list = { ...this.work_categories };
    delete this.categories_list.dawn;
    this.categories_list.night = '夜勤・明番';

    // 勤務カテゴリの初期値を true で設定
    if (!this.select_categories) {
      this.select_categories = {};
    }
    for (let key in this.work_categories) {
      this.$set(this.select_categories, key, true);
    }

    // TODO: WorkCategory.vueに移動しているのでリファクタリングとともに削除
    // EventBus.$on('shift-saved-with-lock', this.startAutoCreate);
  },
  beforeDestroy() {
    // TODO: WorkCategory.vueに移動しているのでリファクタリングとともに削除
    // EventBus.$off('shift-saved-with-lock', this.startAutoCreate);
  },
}
</script>
