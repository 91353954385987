<template>
  <v-dialog v-model="dialog" style="padding: 16px;" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="1700">
    <div id="modalform" tabindex="-1" ref="modalform" style="font-size: 16px; background: #F9FAFB; padding: 24px;">
      <div style="font-size: 24px; padding: 16px 0px;">詳細設定
        <span style="color: #33A272; padding-left: 40px;">
          職員名：{{ staff.lastname + ' ' + staff.firstname }}
        </span>
      </div>
      <div class="staffs_area">
        <h5>主務を変更してください</h5>
        <p class="explain_label" style="padding: 0px;">
          変更対象の方は複数の職種を兼務しているため、変更後の主務を選択し保存ボタンをクリックしてください。<br>
        </p>
        <table class="table_1 table_2">
          <tr class="table_hd_color">
            <th width="343px">事業所名</th>
            <th width="300px">職種</th>
            <th width="72px">主務</th>
          </tr>
          <!-- 事業所名、職種名、パーセンテージ、主務フラグ -->
          <template v-for="(office_staff) in office_staffs">
            <template v-for="(occupation) in office_staff.occupations">
              <tr>
                <td width="343px">{{ getOfficeName(office_staff.office_id) }}</td>
                <td width="300px">{{ getOccupationName(occupation.id) }}</td>
                <td width="72px" style="text-align: center;">
                  <input
                    v-model="occupation.pivot.main_flg"
                    type="radio"
                    name="main_flg"
                    value="1"
                    style="width: 20px; height: 20px;"
                    @change="changeOffOtherMainFlg(office_staff, occupation)"
                  />
                </td>
              </tr>
            </template>
          </template>
        </table>
      </div>
			<v-btn
				color="primary"
				@click="save()"
				style="margin: 0px 0px 0px 16px;"
        :disabled="disabled"
			>
				保存
			</v-btn>
      <v-btn
				color="secondary"
				@click="cancel()"
				style="margin: 0px 0px 0px 16px;"
			>
				キャンセル
			</v-btn>

		</div>

  </v-dialog>
</template>

<style scoped>
</style>

<script>
import axios from "axios"
import OccupationRatio from '@/Pages/Staffs/OfficeStaff/OccupationRatio.vue';

export default {
  name: 'SelectMainFlgDialog',
  components: {
		OccupationRatio,
  },
  props: {
  },
  computed: {
		// 事業所リスト
		office_list () {
      return this.offices.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
    },
  },
  data() {
    return {
      dialog: false,
      offices: [],      // 画面表示用リアクティブ要素
      occupations: [],  // 画面表示用リアクティブ要素
      office_staffs: [],// 画面表示用リアクティブ要素
      staff: {},        // 画面表示用リアクティブ要素
      form: {  // 保存用のフォーム要素
        year: '',             // 開いてる画面の年
        month: '',            // 開いてる画面の月
        tab_value: '',        // 開いてる画面のタブ
        office_id: '',        // 開いている画面事業所ID
        office_staff_id: '',  // 変更対象のoffice_staff_id
        occupation_id: '',    // 変更対象のoccupation_id
      },
      disabled:  true, // 保存ボタン制御用
    }
  },
  methods: {
    /**
     * BtnSelectMainFlgから呼び出される
     * @param {*} data
     */
    openDialog: async function(data) {
      this.dialog = true;
      this.errors = {};

      // データ取得用のaxiosのparam
      let param = {
        year: data.year,
        month: data.month,
        tab_value: data.tab_value,
        staff_id: data.staff.staff_id,
        office_id: data.staff.office_id,
      }

      // 保存用（form）のparam
      this.form.year = data.year;
      this.form.month = data.month;
      this.form.tab_value = data.tab_value
      this.form.office_id = data.staff.office_id;

      // 画面表示用のりアクティブ要素
      this.staff = data.staff;

      // 要素取得
      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);

      // axiosを使って事業所データ、職種データ、業務割合、主務フラグを取得
      let url = this.$route('shifts.staff.main-flg.office.occupation', param);
      await axios.get(url).then(response =>{
        let results = response.data;

          if(results.status !== "success"){
            this.$swal({
                html: "データの取得に失敗しました。",
                icon: 'error',
            });
          } else {
            // 画面表示用のりアクティブ要素にデータをセット
            this.office_staffs = results.office_staffs;
            this.offices = results.offices;
            this.occupations = results.occupations;
          }
      })
    },
    // 主務フラグ変更保存
    save() {
      // axiosを使って保存
      this.$inertia.patch(this.$route('shifts.staff.main-flg'), this.form);
      // ダイアログ閉じる
      this.dialog = false;
    },
    // キャンセル
    cancel() {
      this.dialog = false;
    },
    // 事業所名を取得
		getOfficeName(val) {
			return this.office_list.find(office => office.value === val)
        ? this.office_list.find(office => office.value === val).text
				: '事業所を選択してください。'
		},
    // 職種名を取得
    getOccupationName(val) {
			return this.occupations.find(occupation => occupation.id === val)
        ? this.occupations.find(occupation => occupation.id === val).name
				: '職種を選択してください。'
		},
    // 主務フラグが選択された時に、他の事業所の他の職種の主務フラグを外す
    changeOffOtherMainFlg (office_staff, occupation) {
      this.office_staffs.forEach(office_staff_data => {
        office_staff_data.occupations.forEach(occupation_data => {
          // 他の事業所の他の職種の主務フラグを外す
          if(occupation_data.main_flg && office_staff_data.office_id !== office_staff.office_id) {
            occupation_data.main_flg = false
            return
          }

          // 同一事業所の他の職種の主務フラグを外す
          if(occupation_data.main_flg && occupation_data.id !== occupation.id) {
            occupation_data.main_flg = false
            return
          }
        })
      })

      // 保存用のoffice_staff_idとoccpation_idをセットする
      this.form.office_staff_id = office_staff.id
      this.form.occupation_id = occupation.id

      // 保存ボタンを有効にする
      this.disabled = false
    },
	},
  async mounted() {
  },
}
</script>
