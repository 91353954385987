<template>
  <div v-show="loading">
    <div class="fullview">
      <img :src="$url('/images/loading.gif')" v-click-outside="onClickOutsideImage">
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // modalが表示されているかどうか
    is_show_modal: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClickOutsideImage(e) {
      e.preventDefault();
      if (!this.is_show_modal) {
        EventBus.$emit('onClickOutsideLoadingImage');
      }
    }
  }
}
</script>

<style>
.fullview {
  position: fixed;
  z-index: 99999;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000;
  opacity: 0.6;
}
.fullview img {
  width: 150px;
  height: 150px;
  mix-blend-mode: hard-light;
}
</style>
