<template>
  <div style="font-size: 14px;">
    <div>
      <a class="link_text" :href="$route('teams.list')" @click="pageSend">チーム管理</a>
      <span style="color: #D2D6DB; margin: 0px 10px;">></span>
      <span style="color: #6C737F">チームの複製</span>
    </div>
    <h4 style="font-weight: 700; padding-top: 24px;">チームの複製</h4>
    <p style="color: #384250; padding-top: 16px;">事業所に紐づくチームの情報を登録してください。<br />チーム単位でシフトを作成・確認することができます。</p>
    <div style="margin: 0px; padding: 0px">
      <div class="teams-area" style="padding-bottom: 40px;">
        <div class="input_row">
          <label for="office_id" class="required">事業所</label>
          <div>
            <v-autocomplete
              v-model="form.office_id"
              class="autocomplete-style"
              style="width: 600px; min-width: 600px;"
              :items="office_list"
              :class="{'is-invalid': errors.office_id}"
              @change="handleSelectChangedOffice"
              @blur="check_office_id"
              item-text="text"
              item-value="value"
              hide-details="true"
              placeholder="選択してください"
              no-data-text="事業所が見つかりません。"
              dense
              filled
            ></v-autocomplete>
            <div v-if="errors.office_id" class="invalid-feedback">{{ errors.office_id }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="team_name" class="required">チーム名</label>
          <div>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              style="width: 600px; min-width: 600px;"
              :class="{'is-invalid': errors.name}"
              @blur="check_team_name"
            />
            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="team_member" class="required">チームメンバー</label>
          <div>
            <v-autocomplete
              v-model="form.team_member"
              class="autocomplete-style"
              style="width: auto; min-width: 600px"
              :items="office_staff_list"
              :class="{'is-invalid': errors.team_member}"
              @blur="check_team_member"
              item-text="text"
              item-value="value"
              hide-details="true"
              placeholder="選択してください"
              no-data-text="チームメンバーが見つかりません。"
              multiple
              chips
              dense
              filled
            ></v-autocomplete>
            <div v-if="form_errors['team_member']">
              <p class="invalid-feedback">{{ form_errors['team_member'] }}</p>
            </div>
          </div>
        </div>
      </div>
      <b-button :class='[checkValidation ? "btn_save" : "btn_save_disabled"]' :disabled="!checkValidation" @click="save">保存</b-button>
    </div>
  </div>
</template>

<style>
.autocomplete-style .v-input__control {
  border: 1px solid #ced4da!important;
  border-radius: 8px!important;
}
.v-input__slot {
  background: #ffffff!important;
}
.v-select__selections input {
  min-height: 40px;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>

<script>
import { Inertia } from "@inertiajs/inertia";

export default {
  components :{
  },
  props : {
    team : {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    errorBags: {
      required: false,
    },
    offices :{
      type:Object,
      required :true
    },
    staffs : {
      type:Object,
      required :true
    }
  },
  computed : {
    office_list () {
      return this.offices.data.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
    },
    // team_memberのエラーをform_errorsに追加
    form_errors () {
      let form_errors = {}
      // 複数の同じメッセージを表示させないために、1件でもあればエラーを表示する
      form_errors['team_member'] = "";
      if(!this.team_member_validationCheck && this.team_member_focus) {
        form_errors['team_member'] = "チームメンバーは必ず指定してください。";
      }
      return form_errors
    },
    checkValidation() {
        return this.office_id_validationCheck
            && this.name_validationCheck
            && this.team_member_validationCheck;
    },
  },
  methods: {
    handleSelectChangedOffice (val) {
      this.form.office_id = val
      if (val != '' && val != null && val != undefined) {
        this.setOfficeStaffs(val)
      } else {
        this.office_staff_list = [];
      }

      // 事務所選択時にリセットさせる
      this.form.team_member = [];
      this.team_member_validationCheck = false;
    },
    check_office_id() {
      if (this.form.office_id !== null && this.form.office_id !== '') {
        this.office_id_validationCheck = true;
        this.$delete(this.errors, 'office_id');
      } else {
        this.office_id_validationCheck = false;
        this.$set(this.errors, 'office_id', '事業所は必ず指定してください。');
      }
    },
    check_team_name() {
      if (this.form.name !== null && this.form.name !== '') {
        this.name_validationCheck = true;
        this.$delete(this.errors, 'name');
      } else {
        this.name_validationCheck = false;
        this.$set(this.errors, 'name', 'チーム名は必ず指定してください。');
      }
    },
    check_team_member() {
      this.team_member_focus = true;
      if (this.form.team_member.length > 0) {
        this.team_member_validationCheck = true;
      } else {
        this.team_member_validationCheck = false;
      }
    },
    setOfficeStaffs(office_id) {
      // console.log('【staffs】', this.staffs[office_id].data)
      if(!this.staffs[office_id].data) return
      // staff.firstname + staff.lastnameの配列
      this.office_staff_list = this.staffs[office_id].data.map(staff => {
        return {
          text: staff.lastname + staff.firstname,
          value: staff.office_staff_id
        }
      })
    },
    save() {
      // console.log("【POSTデータ】", this.form)
      this.updateLoading(true);
      this.form.post(route('teams.store'), {
        onSuccess:()=>{
          this.updateLoading(false);
          this.$swal(
            '保存しました',
            '',
            'success')
        },
        onError: () => {
           this.updateLoading(false);
        },
      })
    },
    cancel() {
      this.updateLoading(true);
      this.form.get(route('teams.list'))
    },
    pageSend() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  mounted() {
	  this.updateLoading(false);
  },
  data () {
    return {
      form: Inertia.form(this.team.data),
      office_staff_list:this.staffs[this.team.data.office_id].data.map(staff => {
        return {
          text: staff.lastname + staff.firstname,
          value: staff.office_staff_id
        }
      }),
      office_id_validationCheck: true,
      name_validationCheck: true,
      team_member_validationCheck: true,
    }
  },
}
</script>
