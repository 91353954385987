<template>
  <div style="display: flex; margin-right: 12px;">
    <b-button
      class="btn_shift_reset"
      style="font-size: 12px!important; margin-right: 0px! important;"
      @click="showAboutIcon"
    >
      アイコン説明
      <font-awesome-icon :icon="['far', 'circle-question']" style="color: #1059CC" />
    </b-button>
    <AboutIconDialog ref="AboutIconDialog" />
  </div>
</template>

<script>
import AboutIconDialog from '@/Pages/Shifts/Components/Dialog/AboutIconDialog';

export default {
  name: 'BtnAboutIcon',
  components: {
    AboutIconDialog,
  },
  data () {
    return {};
  },
  methods: {
    showAboutIcon() {
      this.$refs.AboutIconDialog.openDialog();
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
