<template>
    <div style="font-size: 14px;">
        <div style="margin-bottom: 24px;">
            <a href="../list" @click="pageSend">勤務区分マスタ</a><span style="color: #D2D6DB; margin: 0px 10px;">></span><span style="color: #6C737F">勤務区分の編集</span>
        </div>
        <h4 style="margin-bottom: 16px;">勤務区分の編集</h4>
        <p style="color: #384250; margin-bottom: 32px; padding: 0px;">勤務区分は「早番」「日勤」「遅番」「夜勤」「明番」を設定することができ、シフト自動作成で職員に割り当てることができます。</p>
        
        <div style="width: 100%; margin: 0px; padding: 0px;">
            <div class="input_row btn_left_area" style="display: flex; justify-content: flex-end;margin-bottom: 8px; padding: 0px;">
                <b-button class="btn_actions" @click="copy"><i class="pe-7s-pen"></i> 複製</b-button>
                <input
                    type="hidden"
                    v-model="form.id"
                />
            </div>
        </div>
        <WorkpatternForm
            :activatable="activatable"
            :dawn_work="dawn_work"
            :form="form"
            :errors="errors"
            :external_kintai_list="external_kintai_list"
            :workcategories_rest="workcategories_rest"
            :workcategories_work="workcategories_work"
            :workpatterncolors="workpatterncolors"
            :workpatterns="workpatterns"
            :worktypes="worktypes"
            @submit="update"
        ></WorkpatternForm>
    </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import WorkpatternForm from '@/Pages/Workpatterns/WorkpatternForm.vue'

export default {
    components: {
        WorkpatternForm,
    },
    data () {
        return {
            form: Inertia.form(this.workpatterns.data),
        }
    },
    props: {
        errors: {
            type: Object,
            required: false,
        },
        errorBags: {
            required: false,
        },
        workpatterns: {
            type: Object,
            required: true
        },
        worktypes: {
            type: Object,
            required: true
        },
        workcategories_work: {
            type: Array,
            required: true
        },
        workcategories_rest: {
            type: Array,
            required: true
        },
        workpatterncolors: {
            type: Object,
            required: true
        },
        activatable: {
            type: Boolean,
            required: true
        },
        dawn_work: {
            type: Array,
            required: true
        },
        external_kintai_list :{
            type : Array,
            required :true
        },
    },
    mounted: function() {
        this.updateLoading(false);
        this.form.color = this.workpatterns.data.color ? this.workpatterns.data.color : "#FFFFFF";
        this.form.active = this.activatable ? this.workpatterns.data.active : false;
    },
    methods: {
        pageSend() {
            // Ctrl+クリックでメニューを開いたときはローディングはしない
            if (event && !event.ctrlKey) {
                this.updateLoading(true);
            }
        },
        update() {
            this.updateLoading(true);
            this.form.post(this.$route('workpatterns.update', this.workpatterns.data.id), {
                onSuccess:()=>{
                    this.updateLoading(false);
                    this.$swal(
                        '保存しました',
                        '',
                        'success')
                        ;
                },
                onError: (p) => {
                    this.updateLoading(false);
                    const keys = Object.keys(p)
                    keys.forEach((key) => this.$set(this.errors, key, p[key]));
                },
            })
        },
        copy() {
            this.updateLoading(true);
            this.form.get(route('workpatterns.copy', this.workpatterns.data.id), {})
        },
        updateLoading(flg) {
            EventBus.$emit('updateLoading', flg);
        },
    }
};
</script>
