<template>
  <div class="header-dots">
<!--
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right>
      <span slot="button-content">
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-danger"></div>
          <i class="lnr-bullhorn text-danger"></i>
          <div class="badge badge-dot badge-dot-sm badge-danger">通知</div>
        </div>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-deep-blue">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-dark">
            <h5 class="menu-header-title">通知</h5>
            <h6 class="menu-header-subtitle">
              未読メッセージが <b>21</b>通 あります
            </h6>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <b-tabs class="card-header-tab-animation" card>
          <b-tab title="イベント" class="p-3" active>
            <div class="scroll-gradient">
              <div class="scroll-area-sm">
                <VuePerfectScrollbar class="scrollbar-container" v-once>
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">○○プロジェクトGMT</h4>
                          <p> 今日はありがとうございました<a href="javascript:void(0);">12:00 PM</a></p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">××プロジェクトGMT</h4>
                          <p> 今日はもう一つ打ち合わせがあります。<span class="text-success">15:00 PM</span></p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">本日期日となるプロジェクト</h4>
                          <p>期日が守れるように頑張りましょう。</p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-dropdown>
-->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowUp, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faCog);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  data: () => ({}),
  methods: {}
};
</script>

