<template>
  <div>
    <b-button
      class="btn_white"
      style="color: #FFFFFF!important; background: #02b28a!important; padding: 0px!important;"
      :style="'font-size: ' + ( edit_mode ? '12' : '16' ) + 'px; height: ' + (edit_mode ? '30px' : '60px' ) + '!important; width: '+ ( edit_mode ? '40px' : '144px' ) + '!important;'"
      @click="changeEditMode"
    >
      <span v-if="edit_mode">作</span>
      <span v-else>作成モード</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'BtnEditMode',
  components: {
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
 },
  data () {
    return {
    }
  },
  methods: {
    // 作成モード・通常モード切替
    changeEditMode() {
      this.$emit('changeEditMode');
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
