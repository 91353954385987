<template>
  <b-col lg="4" class="d-none d-lg-block">
    <div class="slider-light">
      <slick ref="slick" :options="slickOptions6">
        <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate" >
          <div class="slide-img-bg" />
          <div class="slider-content text-light">
            <h3><a href="https://support.synchroshift.jp/portal/en/kb/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F" target="_blank" rel="noopener noreferrer" style="color: #ffffff !important; text-decoration:none !important;">シンクロシフトに対するよくあるお問い合わせはこちら</a></h3>
          </div>
        </div>
        <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
          <div class="slide-img-bg opacity-6" />
          <div class="slider-content text-light">
            <h3><a href="https://synchroseries.jp/synchroshift/contact_form.html" target="_blank" rel="noopener noreferrer" style="color: #ffffff !important; text-decoration:none !important;">シンクロシフトに関するお問い合わせはこちら</a></h3>
          </div>
        </div>
      </slick>
    </div>
  </b-col>
</template>

<script>
import Slick from "vue-slick"

export default {
  name: 'MySlick',
  components: {
    Slick
  },
  data: () => ({
    slickOptions6: {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true
    },

    slide: 0,
    sliding: null,
  }),

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  }
};
</script>
