<template>
  <div class="m-3 p-3">
    <b-row class="pb-5">
      <b-col cols="12">
        <h4>連携済ユーザ　一覧</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="キーワード検索"
            outlined
            hide-details
            dense
            style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 8px;"
        ></v-text-field>
      </b-col>
    </b-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="oura_list"
      :footer-props="footerProps"
      :search="search"
      :custom-filter="customFilter"
      no-data-text="データが存在しません"
      no-results-text="データが存在しません"
      fixed-header
      height="calc(100vh - 310px)"
      class="elevation-1"
      @update:items-per-page="savePerPage"
    >
      <template v-slot:item.id="{ item }">
        {{ getFullName(item.id) }}
      </template>

      <template v-slot:item.role="{ item }">
        {{ getRoleName(item.role) }}
      </template>
      <template v-slot:item.employment_type="{ item }">
        {{ getEmploymentTypeName(item.employment_type) }}
      </template>

      <template v-slot:footer.page-text="props">
        {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
        {{ props.pageStop }} 件までを表示
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  components: {
  },
  props: {
    oura_users: {
      type : Object,
      required : true,
    },
    codes: {
      type: Object,
      required :true,
    },
  },
  computed: {
    // システム権限リスト
    role_list () {
      return this.codes.data.filter(function (code) {
        return code.type === "system_role";
      });
    },
    // 雇用タイプリスト
    employment_type_list () {
      return this.codes.data.filter(function (code) {
        return code.type === "employment_type";
      });
    },
    items () {
      return this.oura_users.data
    }
  },
  data() {
    return {
      search: '',
      // 表データ
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      // 表ヘッダー
      headers: [
        { text: '社員番号', value: 'employee_number', align: 'start', sortable: true, class: "text-center", width: '120'},
        { text: '職員名', value: 'id', width: '150'},
        { text: 'システム権限', value: 'role', width: '140'},
        { text: '雇用形態', value: 'employment_type', width: '120'},
      ],
      oura_list: Number(this.$cookie.get('oura_list'))
    }
  },
  mounted: function() {
    this.updateLoading(false);
  },
  methods: {
    /**
     * 表の操作
     */
    // 社員名フィルター
    customFilter (value, search, item) {
      return (item.employee_number && item.employee_number.indexOf(search) !== -1)
        || (this.getFullName(item.id) && this.getFullName(item.id).indexOf(search) !== -1)
        || (this.getRoleName(item.role) && this.getRoleName(item.role).indexOf(search) !== -1)
        || (this.getEmploymentTypeName(item.employment_type) && this.getEmploymentTypeName(item.employment_type).indexOf(search) !== -1);
    },
    /**
     * 初期値設定用
     */
    // フルネーム取得
    getFullName(value) {
      const oura_user = this.oura_users.data.find(oura_user => oura_user.id === value)
      return value ? oura_user.lastname + oura_user.firstname  : null
    },
    // システム権限名取得
    getRoleName (value) {
      return value ? this.role_list.find(role => role.code === value).ja : null
    },
    // 雇用形態名取得
    getEmploymentTypeName (value) {
      return value
        ? this.employment_type_list.find(employment_type => employment_type.code === value)
        ? this.employment_type_list.find(employment_type => employment_type.code === value).ja
        : null
        : null
    },
    savePerPage(val) {
      this.$cookie.set('oura_list', val, 1);
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  }
}
</script>
