<template>
  <b-button class="btn-shift-save" @click="save">保存</b-button>
</template>

<style scoped>
.btn_white {
  color: #FFFFFF!important;
  background: #0BB0BE!important;
  padding: 0px 50px!important;
  margin-right: 15px!important;
}
</style>

<script>
export default {
  name: 'BtnSaveShift',
  components: {
  },
  props: {
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data () {
    return {
    }
  },
  methods: {
    save() {
      let message = '';
      if (this.summary.status !== 'opened') {
        message = '保存します。よろしいですか？';
      } else {
        message = '現在確定しているシフトが変更されますので、確認後、「職員へ展開」ボタンをクリックして、最新のシフトを職員に展開してください。';
      }
      this.$swal({
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('saveShift');
        }
      });
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
