<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="650">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div></div>
          <div>アイコン説明</div>
          <div><button class="btn_close" @click="dialog = false">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <div class="title_green_line">
            <font-awesome-icon icon="fa-lock" style="color: #6c757d;" />
            ：鍵アイコン（濃い色）
          </div>
          <div>
            <a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E7%89%B9%E5%AE%9A%E3%81%AE%E8%81%B7%E5%93%A1%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6-%E6%AF%8E%E9%80%B1%E5%90%8C%E3%81%98%E6%9B%9C%E6%97%A5%E3%81%AB%E5%8B%A4%E5%8B%99%E5%8C%BA%E5%88%86%E3%82%92%E9%85%8D%E7%BD%AE%E3%81%97%E3%81%9F%E3%81%84" target="_blank"  rel="noopener noreferrer">シフトパターン</a>で設定したシフト、希望申請状況画面からシフト画面に設定したシフトを <font-awesome-icon icon="fa-lock" style="color: #6c757d;" /> で表示します。
          </div>
          <div class="title_green_line">
            <font-awesome-icon icon="fa-lock" style="color: #9ea9b3;" />
            ：鍵アイコン（薄い色）
          </div>
          <div>
            シフト画面でシフト管理者が手入力で設定したシフト、シフト画面でシフト管理者がインポートしたシフトを <font-awesome-icon icon="fa-lock" style="color: #9ea9b3;" /> で表示します。
          </div>
          <div class="title_green_line" style="display: flex;">
            <div class="hope-icon" style="background: #F04438;">希</div>
            ：有休アイコン 
          </div>
          <div style="display: ruby-text;">
            職員が申請、またはシフト管理者が代理で申請した有休を <span class="hope-icon hope-icon-text" style="background: #F04438;">希</span> で表示します。
          </div>
          <div class="title_green_line">
            <div class="hope-icon" style="background: #1498CC;">希</div>
            ：公休、希望勤務アイコン
          </div>
          <div style="display: ruby-text;">
            職員が申請、またはシフト管理者が代理で申請した公休、希望勤務を <span class="hope-icon hope-icon-text" style="background: #1498CC;">希</span> で表示します。
          </div>
          <div class="title_green_line">
            <div class="hope-icon" style="background: #1498CC;">1</div>
            ：数字アイコン（青色）
          </div>
          <div style="display: ruby-text;">
            職員が申請した公休の希望休、希望勤務で、優先順位が設定されている公休、希望勤務を <span class="hope-icon hope-icon-text" style="background: #1498CC;">1</span> で表示します。
          </div>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
            <div style="display: flex; justify-content: center; width: 100%; padding: 0px 70px 70px 70px;">
            <button class="btn_gray_shadow" style="width:150px; padding: 5px 0;" @click="dialog = false">閉じる</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
a {
  color: #1059CC!important;
  text-decoration: underline!important;
}
a:hover {
  cursor: pointer;
}
.title_green_line {
  display: flex;
}
.hope-icon {
  display: flex;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 0;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 2px;
}
.hope-icon-text {
  font-size: 14px!important;
  min-width: 16px!important;
  max-width: 16px!important;
  min-height: 16px!important;
  max-height: 16px!important;
  padding-top: 3px;
}
</style>

<script>
export default {
  name: 'AboutIconDialog',
  components: {
  },
  props: {
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  },
  computed: {
  },
  mounted() {
  }
}
</script>
