<template>
  <div @click="handleClick">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- 黄色の三角形 -->
      <polygon
        points="12,2 22,22 2,22"
        fill="yellow"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
      />
      <!-- 黒い感嘆符 -->
      <rect x="11" y="7" width="2" height="8" fill="black" />
      <circle cx="12" cy="19" r="1.5" fill="black" />
    </svg>

    <AlertHopeHolidayDialog
      ref="AlertHopeHolidayDialog"
      :filterd_days_hope_holiday_error="filterd_days_hope_holiday_error"
    />

  </div>
</template>

<script>
import AlertHopeHolidayDialog from '@/Pages/Shifts/Components/Dialog/AlertHopeHolidayDialog';

export default {
  name: 'ExclamationTriangle',
  components: {
    AlertHopeHolidayDialog
  },
  props: {
    width: {
      type: String,
      default: '42'
    },
    height: {
      type: String,
      default: '24'
    },
    strokeColor: {
      type: String,
      default: 'black'
    },
    strokeWidth: {
      type: String,
      default: '1'
    },
    filterd_days_hope_holiday_error:{
      type: Array
    }
  },
  methods :{
    handleClick() {
      this.$refs.AlertHopeHolidayDialog.openDialog();
    },
  }
}
</script>
