<template>
  <div style="font-size: 14px;">
    <h4 style="margin-bottom: 24px;">役職　一覧</h4>
    <div class="input_row">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="キーワード検索"
        outlined
        hide-details
        dense
        style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 32px;"
      ></v-text-field>
      <div class="btn_left_area" style="width: calc(100% - 320px); padding: 0;">
        <a href="add"><button class="btn_white" style="height: 37px!important;" @click="pageSend"><font-awesome-icon icon="plus" class="icon-style" />役職を追加</button></a>
      </div>
    </div>
    <v-data-table
      :headers = "headers"
      :items = "desserts"
      :items-per-page = "positions_list"
      :footer-props = "footerProps"
      :search="search"
      class="elevation-1 table_workpatterns"
      fixed-header
      height="calc(100vh - 280px)"
      no-data-text="データが存在しません"
      no-results-text="データが存在しません"
      @update:items-per-page="savePerPage"
    >
      <template v-slot:footer.page-text="props">
        {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
        {{ props.pageStop }} 件までを表示
      </template>

      <template #item.position_id="{ item }">
        <span v-if="item.lodgment_id !== null" style="display: flex;">
          <b-button class="btn_actions" @click="gotoEdit(item)"><i class="pe-7s-pen"></i> 変更</b-button>
          <b-button class="btn_actions" @click="deletePosition(item)"><i class="pe-7s-trash"></i> 削除</b-button>
        </span>
        <span v-else>―</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  props: {
    positions: Array
  },

  data: function () {
    return {
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '役職名', value: 'position_name', width: "80%", sortable: true },
        { text: '操作', value: 'position_id', width: "20%", sortable: false },
      ],
      desserts: this.positions,
      positions_list: Number(this.$cookie.get('positions_list'))
    }
  },

  mounted: function() {
    this.updateLoading(false);
  },

  methods: {
    pageSend() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    savePerPage(val) {
      this.$cookie.set('positions_list', val, 1);
    },

    gotoEdit: function (item) {
      this.updateLoading(true);
      location.href = this.$route('positions.edit') + '?id=' + encodeURIComponent(item.position_id)
    },

    deletePosition: function (item) {
      event.preventDefault()
      this.$swal
        .fire({
          icon: 'warning',
          title: '本当に削除しますか？',
          showConfirmButton: true,
          confirmButtonText: '削除',
          showCancelButton: true,
          cancelButtonText: 'キャンセル',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateLoading(true);
            const axios = require('axios')
            axios
              .delete(this.$route('positions.do-remove') + '?id=' + encodeURIComponent(item.position_id))
              .then((res) => {
                this.updateLoading(false);
                if (res.status === 200) {
                  if (res.data.status !== 200) {
                    this.$swal.fire({
                      icon: 'error',
                      text: res.data.message
                    })
                    .then(function(isConfirm) {
                    })
                  } else {
                    this.$swal.fire({
                      icon: 'success',
                      title: '役職を削除しました',
                    });
                    // 削除した役職をリスト一覧から削除
                    let deleteId = item.position_id;
                    this.desserts = this.desserts.filter(data => data.position_id !== deleteId);
                  }
                } else {
                  throw res.status
                }
              })
              .catch((err) => {
                this.updateLoading(false);
                this.$swal.fire({
                  icon: 'error',
                  text: '役職の削除に失敗しました。しばらくたってから再試行してください。'
                })
                .then(function(isConfirm) {
                })
              })
          }
        })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  }
}
</script>
