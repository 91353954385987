<template>
  <b-modal
    ref="my-modal"
    size="xl"
    :no-enforce-focus="true"
    centered
    @hidden='cancel'
  >
    <template #modal-header="">
      <h5>勤務区分を選択</h5>
      <button class="btn_close" @click="cancel">✕</button>
    </template>

    <VuePerfectScrollbar fluid style="height: 60vh; overflow-y: auto;">
      <table class="table_1 table_2" style="width: 100%;">
        <tr style="position: sticky!important; top: 0!important;">
          <th style="min-width: 20px; max-width: 20px;">
            <input
              @change="handleChangeAllCheck"
              v-model="all_check"
              type="checkbox"
              class="office-staff-checkbox"
              style="height: "
              >
          </th>
          <th style="min-width: 250px; max-width: 250px; padding-left: 12px!important;" colspan="2">勤務区分名</th>
          <th style="min-width: 160px; max-width: 160px;">勤務種別</th>
          <th style="min-width: 100px; max-width: 100px;">勤務カテゴリ</th>
          <th style="min-width: 96px; max-width: 96px;">勤務開始</th>
          <th style="min-width: 96px; max-width: 96px;">勤務終了</th>
          <th style="min-width: 96px; max-width: 96px;">勤務時間</th>
          <th style="min-width: 96px; max-width: 96px;">休憩開始</th>
          <th style="min-width: 96px; max-width: 96px;">休憩終了</th>
          <th style="min-width: 96px; max-width: 96px;">休憩時間</th>
        </tr>
        <tr v-for="(workpattern, index) in workpatterns.data" :key="index">
          <td style="max-width: 20px; max-width: 20px;">
            <input
              v-model="form.workpatterns"
              type="checkbox"
              :value="workpattern.id"
              class="office-staff-checkbox"
              >
          </td>
          <td style="min-width: 250px; max-width: 250px; padding-left: 12px!important;">{{ workpattern.name }}</td>
          <td style="min-width: 160px; max-width: 160px; padding-left: 12px!important;">{{ getWorkpatternTypeJa(workpattern.type) }}</td>
          <td style="min-width: 100px; max-width: 100px;">
            <span>{{ getWorkpatternCategoryJa(workpattern.category) }}</span>
          </td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.working_start_time || `--:--`  }}</td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.working_end_time || `--:--`  }}</td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.working_hours || `--:--`  }}</td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.rest_start_time || `--:--` }}</td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.rest_end_time || `--:--` }}</td>
          <td style="min-width: 96px; max-width: 96px;">{{ workpattern.rest_time || `--:--`  }}</td>
        </tr>
      </table>
    </VuePerfectScrollbar>
    <template #modal-footer>
      <b-button v-if="form.workpatterns.length" @click="save" class="btn_blue" style="padding: 0px 16px!important; margin: 10px 16px!important;">完了</b-button>
      <b-button v-else class="btn_blue" style="padding: 0px 16px!important; margin: 10px 16px!important;" disabled>完了</b-button>
    </template>
  </b-modal>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "ModalWorkpattern",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    // 事業所ごとスタッフ情報
    office_staff: {
      type: Object,
      required :true,
    },
    // 事業所
    offices: {
      type: Object,
      required : true,
    },
    // 勤務区分
    workpatterns: {
      type: Object,
      required :true,
    },
    // 勤務カテゴリー名用
    codes : {
      type: Object,
      required :true,
    }
  },
  data() {
    return {
      // workpatternsは配列データ:例[1,4,6,7]
      form: this.$inertia.form({
        workpatterns : this.getDefaultWorkpattern()
      }),
      all_check : false
    }
  },
  watch: {
    'form.workpatterns':{
      handler(val, oldval){
        // 一つでもOFFになった場合に全選択はOFF
        if (val.length < this.workpattern_list.length) {
          this.all_check = false
        }
      },
      deep :true
    },
  },
  methods: {
    // スイッチ全操作
    handleChangeAllCheck () {
      if (this.all_check) {
        // 全件ON
        this.form.workpatterns = this.workpatterns.data.map(workpattern => workpattern.id);
      } else {
        // 全件OFF
        this.form.workpatterns = [];
      }
    },
    // モーダル内の選択勤務区分の初期表示
    getDefaultWorkpattern () {
      return this.office_staff.workpatterns.length
        ? this.office_staff.workpatterns.map(workpattern => workpattern.id) // 選択済みのidを配列にして返す
        : []
      },
    // 完了ボタン
    save () {
      // 公休（全休）の勤務区分が含まれるデータを取得
      const public_holidays_duplicated = this.publicHolidaysWorkpatternIds.filter(publicHolidaysWorkpattern => this.form.workpatterns.indexOf(publicHolidaysWorkpattern.id) !== -1)

      if (public_holidays_duplicated.length > 1) {
        let message = '<p style="text-align: left">'
          + '勤務種別：公休（全休）の勤務区分は1人の職員に対し1つまでしか割り当てできません。'
          + '<br>'
          + '以下の勤務区分が重複しています：</p>';

        // 公休（全休）の勤務区分を取得
        let duplicate_workpattern_names = [];
        // 重複している勤務区分を取得
        public_holidays_duplicated.forEach(workpattern => {
          duplicate_workpattern_names.push(workpattern.name);
        })

        // 重複している勤務区分を表示
        message += '<p>勤務区分：' + this.htmlspecialchars(duplicate_workpattern_names.join('、')) + '</p>';

        this.$swal({
          icon: 'error',
          html: message,
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      // workpatternsの並び順に合わせてthis.form.workpatternsを並び替える
      this.form.workpatterns = this.workpatterns.data.map(workpattern => {
        if (this.form.workpatterns.indexOf(workpattern.id) !== -1) {
          return workpattern.id
        }
      }).filter(Boolean)

      // ShiftSettingコンポーネントに送る
      this.$emit('workpatterns', this.form.workpatterns, this.office_staff)
      // モーダル閉じる前に全選択スイッチを元に戻す
      this.all_check = false
      // モーダル閉じる
      this.hideModal();

    },
    // キャンセルボタン or ✕ボタン or 背景クリック
    cancel () {
      // 保存されたもの以外のデータに戻す
      this.all_check = false
      this.form.workpatterns = this.getDefaultWorkpattern()
      this.$refs['my-modal'].hide()
    },
    // モーダル開く
    showModal() {
      this.$refs['my-modal'].show()
    },
    // モーダル閉じる
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    // 事業所名取得
    getOfficeName (value) {
      return value
        ? this.offices.data.find(office => Number(office.id) === Number(value))
        ? this.offices.data.find(office => Number(office.id) === Number(value)).name
        : null
        : null
    },
    // 勤務区分名取得
    getWorkpatternName (value) {
      return value
        ? this.workpatterns.data.find(workpattern => Number(workpattern.id) === Number(value))
        ? this.workpatterns.data.find(workpattern => Number(workpattern.id) === Number(value)).name
        : null
        : null
    },
    // 勤務カテゴリー名称取得
    getWorkpatternCategoryJa (value) {
      return value
        ? this.codes.data.find(code => code.code === value)
        ? this.codes.data.find(code => code.code === value).ja
        : null
        : null
    },
		// 勤務種別名取得
    getWorkpatternTypeJa (value) {
      return value
        ? this.codes.data.find(code => code.code === value)
        ? this.codes.data.find(code => code.code === value).ja
        : null
        : null
    },
    // 勤務区分の色取得
    getWorkpatternsColor(val) {
      if (val == 'early') {
          return '#fffacd';
      } else if (val == 'day') {
          return '#f0e68c';
      } else if (val == 'late') {
          return '#ffff00';
      } else if (val == 'night') {
          return '#7fffd4';
      } else if (val == 'dawn') {
          return '#66cdaa';
      } else if (val == 'holiday') {
          return '#00ced1';
      } else {
          return '#FFFFFF';
      }
    },
    // HTML表示用のエスケープ処理
    htmlspecialchars(string) {
      if (typeof string != 'string') {
        return string;
      };
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          };
          return escaped[match];
        });
    },
  },
  computed: {
    workpattern_list () {
      return this.workpatterns.data.map(workpattern => {
        return {
          text : workpattern.name
            + '（'
            + workpattern.working_start_time.substr( 0, 5 )
            + '〜'
            + workpattern.working_end_time.substr( 0, 5 )
            + '）',
          value: workpattern.id
        }
      })
    },
    // 勤務種別が公休（全休）の勤務区分ID配列
    publicHolidaysWorkpatternIds() {
      return this.workpatterns.data.map(workpattern => {
        if (workpattern.type === 'public_holidays') {
          return {
            id : workpattern.id,
            name : workpattern.name
          }
        }
      }).filter(Boolean)
    },

  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      // 保存後に再度openする際に表示が保存前に戻ってしまう件の対策
      this.all_check = false
      this.form.workpatterns = this.getDefaultWorkpattern()
    })
  }
}
</script>

<style scoped>
.modal-backdrop {
  opacity: 0.4 !important;
}
.custom-switch {
  padding-left: 3.25rem;
  padding-bottom: 0.2rem;
  transform: scale(1.2);
}
.modal-content {
  width: auto!important;
  margin: 0 auto!important;
}
</style>
