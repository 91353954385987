<template>
    <b-form-group>
        <template v-if="step <= 1">
            <b-form-checkbox v-model="value.has_team" inline>チーム</b-form-checkbox>
            <b-form-checkbox v-model="value.has_occupation" inline>職種</b-form-checkbox>
            <b-form-checkbox v-model="value.has_work_hours" inline>労働時間</b-form-checkbox>
            <b-form-checkbox v-model="value.has_limited_work_types" inline>勤務種別：勤務と公休(全休)のみを表示</b-form-checkbox>
        </template>
        <b-form-checkbox v-if="step == 1" v-model="value.has_only_enabled_workpatterns"
            inline>〇の勤務区分のみ表示</b-form-checkbox>
    </b-form-group>
</template>

<script>
export default {
    name: 'TableColumnFilters',
    props: {
        value: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
    },
};
</script>
