export default {
    getHHmm(val) {
        let resultText = val;

        // 先頭から5文字取得
        if (val.length > 5) {
            resultText = val.slice( 0, 5 );
        }
        return resultText;
    },
    getWpListInfo(workpatterns, list) {
        // 出力用のワークグループ名と時間の整形
        let name = '';
        let short_name = '';
        let time = '';
        let short_time = '';
        for (let workpattern_id of list) {
            let workpattern = workpatterns[workpattern_id];
            if (
                short_name === ''
                && short_time === ''
                && (name.length + workpattern.name.length + 1 >= 18 || time.length + 6 >= 31)
            ) {
                short_name = name.slice(1) + '...';
                short_time = time.slice(1) + '...';
            }
            name += ',' + workpattern.name;
            time += ',' + workpattern.working_start_time.slice(0, 5)
                + '-'
                + workpattern.working_end_time.slice(0, 5);
        }
        if (short_name === '') {
            short_name = name.slice(1);
            short_time = time.slice(1);
        }

        // データを配列に反映
        const info = {
            name: name.slice(1),
            short_name: short_name,
            time: time.slice(1),
            short_time: short_time,
        }
        return info;
    }
};

