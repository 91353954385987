<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
    :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        background-color="white"
        v-on="on"
        :rules="[rules.hhmm]"
        :disabled="disabled"
        :placeholder="placeholder"
        dense
        outlined
        @keydown="closeTimePicker"
        @click="checkTime(time)"
        >
      </v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      full-width
      @click:minute="$refs.menu.save(time)"
      >
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props :{
    // timepickerを複数利用時のfor用
    num : {
      type : Number,
      required :false
    },
    // edit時利用
    default : {
      type : String,
      required :false
    },
    // disabled
    disabled : {
      type : Boolean,
      required :false
    },
    // プレスホルダー
    placeholder : {
      type : String,
      required :false
    }
  },
  data() {
    return {
      time: null,
      menu: false,
      timeRule: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
      rules: {
        hhmm: value => {
          if (value !== undefined && value) {
            let pattern = this.timeRule
            return pattern.test(value) || '時刻が正しくありません。'
          } else {
            return ''
          }
        },
      }
    }
  },
  watch: {
    time:function (val) {
      this.$emit('time', val, this.num)
    }
  },
  mounted () {
    // edit時にtimeを入れる
    this.time = this.default || this.time
  },
  methods: {
    closeTimePicker() {
      this.menu = false
    },
    checkTime(value) {
      // 時間以外が入力されていたら''をセットする
      if (value !=='' && value !== null && value !== undefined) {
        let time_result = value.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/);
        if (!time_result) {
          this.time = ''
          this.menu = ture
        }
      }
    }
  }
};
</script>