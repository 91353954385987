<template>
  <div style="display: flex; height: 30px; padding-left: 37px;">
    <p style="padding: 0px;">行の表示設定</p>
    <v-radio-group v-model="switch_row_view" class="switch-row-view" row @change="changeshowAll">
      <v-radio label="全表示" value="0"></v-radio>
      <v-radio label="チェックした行を非表示" value="1"></v-radio>
    </v-radio-group>
    <v-radio-group v-model="switch_workpattern_count_view" class="switch-row-view" row @change="changeshowWorkpattern">
      <v-radio label="設定値を表示" value="0"></v-radio>
      <v-radio label="設定値を非表示" value="1"></v-radio>
    </v-radio-group>
  </div>
</template>
<style>
.switch-row-view .v-radio {
  margin-right: 0 !important;
}
</style>

<script>
export default {
  name: 'SwitchRolView',
  components: {
  },
  props: {
  },
  data() {
    return {
      switch_row_view: "0",
      switch_workpattern_count_view: "0",
    }
  },
  methods: {
    changeshowAll() {
      this.$emit('changeshowAll');
    },
    changeshowWorkpattern() {
      this.$emit('changeshowWorkpattern');
    }
  },
  computed: {
  },
}
</script>
