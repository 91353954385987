<template>
  <div style="display: flex; width: auto; margin-right: 14px;">
    <p style="margin: 0px;">事業所</p>
    <v-autocomplete
      v-model="target_office_id"
      :items="offices"
      @change="changeOffice"
      class="small-control"
      style="min-width: 190px; max-width: 190px;"
      item-text="name"
      item-value="id"
      hide-details="true"
      placeholder="選択してください"
      no-data-text="事業所が見つかりません。"
      dense
      filled
      return-object
    ></v-autocomplete>
  </div>
</template>

<style scoped>
p {
  display: flex;
  align-items: center;
  padding-right: 12px;
  margin: 0px;
}
</style>

<script>
export default {
  name: 'ShiftOfficeSelect',
  components: {
  },
  props: {
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    initial_office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    offices: {
      type: Array,
      default: function() {
        return [];
      }
    },
    view_type: {
      type: String,
      default: 'application_status'
    }
  },
  data () {
    return {
      target_office_id: 0,
    }
  },
  methods: {
    // 事業所変更処理
    changeOffice(e) {
      if (e) {
        let target_office = e.id;
        let route = '';
        if (this.view_type === 'application_status') {
          route = 'shifts.application-status';
        } else {
          route = 'shifts.schedule';
        }
        this.updateLoading(true);
        this.$inertia.get(this.$route(route, { year: this.year, month: this.month, office: target_office }));
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
    this.target_office_id = this.initial_office.id;
  }
}
</script>
