<template>
  <b-button class="btn_white btn-reflection" :disabled="!all_approval" @click="action">
    申請をシフト表へ反映
  </b-button>
</template>

<style scoped>
.btn-reflection {
  width: 170x!important;
  height: 34px!important;
  color: #FB82B4!important;
  border: solid 1px #FB82B4!important;
}
</style>

<script>
export default {
  name: 'BtnReflection',
  components: {
  },
  props: {
    is_changed: {
      type: Boolean,
      default: false
    },
    all_approval: {
      type: Boolean,
      default: false,
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
    }
  },

  methods: {
    action() {
      if (this.is_changed) {
        this.$swal({
          text: '保存していない変更があります。保存，承認後反映を行ってください。',
          icon: "notice",
        });
        return;
      }
      this.$swal({
        text: 'シフト表へ反映します。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateLoading(true);
          this.$inertia.post(
            this.$route('shifts.application-status.reflection', {
              year: this.year,
              month: this.month,
              office: this.office.id
            })
          );
        }
      });
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>

