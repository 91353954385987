<template>
  <div style="font-size: 14px;">
    <h4 style="margin-bottom: 24px;">事業所管理　一覧</h4>
    <div class="input_row">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="キーワード検索"
        outlined
        hide-details
        dense
        style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 32px;"
      ></v-text-field>
      <div style="width: calc(100% - 320px); display: flex; justify-content: flex-end;">
        <div class="" style="padding: 0;">
          <a href="add"><button class="btn_white" @click="sendPage"><font-awesome-icon icon="plus" class="icon-style" />事業所を追加</button></a>
        </div>
        <div class="import-btn">
          <b-button class="btn_white" @click="csvimport()">
            <font-awesome-icon icon="upload" class="icon-style" />CSVインポート
          </b-button>
        </div>
      </div>
    </div>
    <v-data-table
      :headers = "headers"
      :items = "items"
      :items-per-page = "offices_list"
      :footer-props = "footerProps"
      :search="search"
      class="elevation-1 table_workpatterns"
      fixed-header
      height="calc(100vh - 280px)"
      no-data-text="データが存在しません"
      no-results-text="データが存在しません"
      @update:items-per-page="savePerPage"
    >
      <template v-slot:footer.page-text="props">
        {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
        {{ props.pageStop }} 件までを表示
      </template>
      <template v-slot:item.actions="{ item, index }">
        <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i> 変更</b-button>
        <b-button class="btn_actions" @click="deleteItem(item, index)"><i class="pe-7s-trash"></i> 削除</b-button>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.btn_white {
  height: 37px!important;
  margin-left: 20px;
}
</style>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import { Inertia } from '@inertiajs/inertia'

Vue.use(VueCookie);

export default {
  components: {
  },
  props: {
    offices: {
      type:Object,
      required :true
    },
    errors: {
      type:Object,
      required :false
    }
  },
  data () {
    return {
      search: '',
      zoho_link: 'https://support.synchroshift.jp/portal/en/kb/articles/%E4%BA%8B%E6%A5%AD%E6%89%80%E3%81%8C%E5%89%8A%E9%99%A4%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '事業所番号', align: 'start', sortable: true, value: 'number' },
        { text: 'サービス種別', value: 'service_name' },
        { text: '事業所名', value: 'name' },
        { text: '操作', value: 'actions',sortable: false, width: '300' },
      ],
      items: this.offices.data,
      offices_list: Number(this.$cookie.get('offices_list')),
      csv_form: this.$inertia.form({
        csv: null,
        office_id: null
      }),
    }
  },
  mounted() {
    this.updateLoading(false);
  },
  methods : {
    sendPage() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    savePerPage(val) {
      this.$cookie.set('offices_list', val, 1);
    },
    editItem (item) {
      console.log(Ziggy.routes)
      this.updateLoading(true);
      this.$inertia.get(route('offices.edit', item.id))
    },
    deleteItem (item, index) {
      this.$swal({
        title: "本当に削除しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.$inertia.delete(route('offices.destroy', item.id), {
            preserveScroll: true,
            onError: (p) => {
              console.log(p)
              this.updateLoading(false);
              this.$swal(
                'error',
                '削除しようとしている事業所は、他の情報と紐づいているため削除ができません。削除方法については<a href="' + this.zoho_link + '" target="_BLANK">事業所が削除できない</a>をご確認ください。',
                'error')
            },
            onSuccess:()=>{
              this.updateLoading(false);
              this.$swal(
                '事業所を削除しました',
                '',
                'success')
              this.$delete(this.items, index)
            }
          })
        }
      })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    /**
     * CSVアップロード機能
     */
     csvimport(){
        this.$swal({
            text: "事業所データ（CSV）をインポートします。",
            icon: 'warning',
            showCancelButton: true,
            input: 'file',
            inputAttributes: {
                name: "csvupload",
                id: "csvupload",
                accept: "text/csv",
                multiple: "multiple"
            },
            confirmButtonText: 'アップロード',
            cancelButtonText: 'キャンセル',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
          if (! result.value) {
            return;
          }

          this.updateLoading(true);
          this.csv_form.transform((data) => ({
            ...data,
            csv: result.value[0],
          }))
          .post(this.$route('offices.csvupload'), {
            preserveScroll: true,
            onError: (p) => {
              this.updateLoading(false);
              let errorMessages = '';
              let parsed_message = {};
              // JSON文字列でなかった場合を考慮して例外処理
              try {
                parsed_message = JSON.parse(p.csv_upload);
              } catch (e) {
                parsed_message = {};
                if ('csv_upload' in p) {
                  errorMessages = p.csv_upload;
                } else {
                  errorMessages = '';
                }
              }
              // CsvUploadRequestのバリデーションエラーメッセージ対応
              try {
                  parsed_message += JSON.parse(p.csv);
              } catch (e) {
                  if ('csv' in p) {
                      errorMessages += p.csv;
                  }
              }
              Object.keys(parsed_message).forEach(index => {
                let errorMessage = [];
                const message = parsed_message[index].message;

                try {
                  Object.keys(JSON.parse(message)).forEach(field => {
                    JSON.parse(message)[field].forEach(message => {
                      errorMessage.push(message)
                    })
                  })
                  errorMessages = errorMessages + (Number(index) + 1) + '行目エラー：' + errorMessage.join(' ') + '<BR>'

                } catch(e) {
                  console.debug('【CSV_UPLOAD_ERROR】', message);
                  // エラー内容がDBエラー等の例外の場合
                  errorMessages = errorMessages + (Number(index) + 1) + '行目エラー：データ不正です。<BR>'
                }
              })
              this.$swal({
                title :'error',
                html :'CSV登録に失敗しました。<BR>再度やり直してください。'
                  + '<BR><BR>'
                  + '【エラー詳細】'
                  + '<BR>'
                  + '--------------------------------------'
                  + '<div style="text-align:left;padding-right:15%;padding-left:15%;font-size: 0.8em;">'
                  + errorMessages
                  + '</div>',
                icon : 'error'
              })
            },
            onSuccess: () => {
              this.updateLoading(false);
              const results = this.$page.props.flash.results

              let title = '取り込みが完了しました。';
              let icon = 'success';

              if (results.failure > 0) {
                title = '取り込みに失敗しました。';
                icon = 'error';
              }

              this.$swal({
                html: `${title}<br />
                    総件数:${results.total}件<br />
                    成功件数:${results.success}件<br />
                    失敗件数:${results.failure}件<br />
                    ${this.errorMessages(results.errors)}`,
                icon: icon,
              })
              .then(() => {
                Inertia.visit(this.$route('offices.list'))
              });
            },
            onFinish: () => {
              this.updateLoading(false);
              this.csv_form.csv = null;
            }
          })  
        })
      },
    errorMessages(errors) {
      if (!errors) {
        return '';
      }

      let errorMessages = '';
      const errorMessage = [];

      for(const [index, error] of Object.entries(errors)) {
        const message = error.message;

        try {
          for (const field of Object.keys(JSON.parse(message))) {
            for (const m of JSON.parse(message)[field]) {
              errorMessage.push(m)
            }
          }
          errorMessages = `${errorMessages + (Number(index) + 1)}行目エラー：${errorMessage.join(' ')}<BR>`

        } catch(e) {
          console.debug('【CSV_UPLOAD_ERROR】', message);
          // エラー内容がDBエラー等の例外の場合
          errorMessages = `${errorMessages + (Number(index) + 1)}行目エラー：データ不正です。<BR>`
        }
      }

      return errorMessages;
    }
  }
}
</script>
