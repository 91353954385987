<template>
  <div>
    <VuePerfectScrollbar style="font-size: 14px; min-height: calc(95vh - 275px); max-height: calc(95vh - 275px); width: 100%; overflow: auto;">
      <div style="font-size: 18px; font-weight: 600; border-left: solid 5px #02B28A; padding-left: 10px;">
      回避したいシフトパターン
      <b-button
        class="btn_shift_reset"
        style="color: #1059CC!important;"
        @click="help_shift"
      >
        <font-awesome-icon
          icon="circle-question"
          style="vertical-align: text-top; padding-left: 12px;"
      />
        ヘルプ
      </b-button>
      </div>
      <div style="padding: 30px 0px;">
        回避したいシフトパターンを設定します（最大6連続まで）<br />
        例：早番 → 早番 → 早番、遅番 → 遅番 → 遅番、等
      </div>
      <div
        v-for="(pattern, index) in modified_avoid_pattern"
        :key="index"
        style="background: #FAFAFA; margin-bottom:20px; padding: 20px 30px;"
      >
        <div style="display: flex; width: 100%; justify-content: flex-end;">
          <b-button class="del_button" @click="remove_pattern(index)">削除</b-button>
        </div>
        <div style="display: flex; width: 100%; margin: 10px 0px;">
          <div class="required" style="width: 200px; min-width: 200px;">シフトパターン</div>
          <template v-for="i in avoid_pattern_length">
            <div style="width: calc((100% - 360px) / 5);">
              <b-form-select
                label="選択"
                v-model="pattern.category_list[i - 1]"
                :options="getWorkCategoryOptions"
                :class="{'is-invalid': errors && errors['avoid_pattern' + index]}"
              >
                <template #first>
                  <b-form-select-option :value=undefined>選択</b-form-select-option>
                </template>
                </b-form-select>
            </div>
            <div
              v-if="i < avoid_pattern_length"
              style="display: flex; justify-content: center; align-items: center; width: 40px;"
            >
              →
            </div>
          </template>
        </div>
        <div v-if="errors && errors['avoid_pattern' + index]" class="invalid-feedback">{{ errors['avoid_pattern' + index][0] }}</div>
        <div style="display: flex; width: 100%; margin: 20px 0px;">
          <div style="width: 200px; min-width: 200px;">対象外の職員</div>
          <div>
            <v-combobox
              v-model="selectedExcluedStaffs[index]"
              :items="combo_staffs"
              item-value="office_staff_id"
              item-text="name"
              solo
              hide-selected
              hide-details
              multiple
              label="選択してください（複数選択可）"
              style="border: 1px solid #ced4da!important; background: #FFFFFF!important; width: 400px; min-width: 400px;"
              @change="handleChangeSelectedExcludedStaff(index, $event)"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  color="#297ADB"
                  text-color="#FFFFFF"
                  :input-value="selected"
                >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                  <v-icon
                    color="#FFFFFF"
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </div>
      </div>
      <div v-if="modified_avoid_pattern.length < avoid_pattern_max" style="margin: 20px 0px 0px 0px;">
        <b-button class="btn_white" style="color: #02B28A!important; padding: 0.375rem 0.75rem!important;" @click="add_pattern"><font-awesome-icon icon="plus" style="padding-right: 6px;" />追加</b-button>
      </div>
      <div style="margin: 20px 0px 0px 0px;">
        <b-button class="btn_shift_save" @click="save(true, false)">保存</b-button>
        <span style="font-size: 12px; color: #707070; margin-left: 35px;" id="save_message">{{smessage}}</span>
      </div>
    </VuePerfectScrollbar>
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>
        <b-button class="btn_white btn_autocreatefooter" @click="save_close()">保存して閉じる</b-button>
      </div>
      <div>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_back()">戻る</b-button>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_next()">次へ</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from 'axios'

export default {
  name: 'AvoidPattern',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    office: {
      type: Object,
      required: true
    },
    combo_staffs: {
        type: Array,
        required: true
    },
    avoid_pattern: {
      type: Array,
      required: true
    },
    work_categories :{
      type : Array,
      required :true
    },
  },
  computed: {
    // 勤務カテゴリー名取得
    getWorkCategoryOptions () {
      return this.work_categories.map(({ code, ja }) => {
          return {
            value: code,
            text: ja
          }
        });
      },
  },
  data() {
    return {
      errors: {},
      modified_avoid_pattern: this.avoid_pattern, // 回避したいシフトパターン
      // 選択済みの除外スタッフリスト
      selectedExcluedStaffs : this.defaultSelectedExcludedStaffs(this.avoid_pattern),
      avoid_pattern_max: 5,   // 回避したいシフトパターン数の最大値
      avoid_pattern_length: 6, // シフトパターンの最大値
      smessage: '', // 画面下メッセージ
      isSave: false, // 保存処理フラグ
    }
  },
  methods: {
    // 変更フラグ初期化
    initializeChange() {
      this.$nextTick(() => {
        this.$emit('setChange', false);
      });
    },
    // ローディング
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    help_shift() {
      window.open('https://support.synchroshift.jp/portal/ja/kb/articles/1044', '_blank');
    },
    // パターンの追加ボタン
    add_pattern() {
      if (this.modified_avoid_pattern.length < 5) {
        this.modified_avoid_pattern.push({
          category_list: [],
          excluded_staff_list: [],
        });
      }
    },
    // パターンの削除ボタン
    remove_pattern(index) {
      this.modified_avoid_pattern.splice(index, 1);
      this.selectedExcluedStaffs.splice(index, 1);
    },
    // 保存して終了
    save_close() {
      // 保存処理
      this.save(true, true);
    },
    // 戻る
    save_back() {
      // 保存処理
      this.save(false, false, 8);
    },
    // 次へ
    save_next() {
      // 保存処理
      this.save(false, false, 10);
    },
    /**
     * 回避したい組み合わせの保存
     */
    save(message_flg = true, close_flg = true, nextstep = -1) {
      this.updateLoading(true);
      // modified_avoid_pattern.category_listの空もしくは空行の場合は削除して詰める
      this.modified_avoid_pattern = this.modified_avoid_pattern.filter((pattern) => {
        // 空を削除
        pattern.category_list = pattern.category_list.filter(Boolean);
        // 空行を削除
        return pattern.category_list.length > 0;
      });

      // 除外スタッフの空もしくは空行の場合は削除して詰める
      this.selectedExcluedStaffs = this.defaultSelectedExcludedStaffs(this.modified_avoid_pattern)

      var formData = new FormData();
      formData.append('office_id',this.office.id);
      formData.append('avoid_pattern',JSON.stringify(this.modified_avoid_pattern));

      // axios用のURLを設定
      let url = this.$route('shifts.avoid_patterns.save');
      // axiosでデータを送信
      axios.post(url,formData).then(response => {
        this.updateLoading(false);
        let results = response.data.results;
        if(results.status !== "success"){
          this.$swal({
            icon: 'error',
            html: "保存に失敗しました。" + results.message,
          });
        } else {
          if (message_flg) {
            this.$swal({
                title: "保存しました",
                icon: 'success',
            });
          }
          this.smessage = "保存しました。 "+ new Date().toLocaleString();
          // エラーをクリア
          this.errors = {};
          this.$emit('getOfficeData');
          // 親画面に変更クリアを通知
          this.isSave = true;
          this.$emit('setChange', false);
          if (nextstep > -1) {
              // 画面遷移
              this.$emit('nextStep', nextstep);
            } else if (close_flg) {
              // モーダル画面終了
              this.$emit('closeDialog');
            }
        }
      })
      .catch(error => {
        this.updateLoading(false);
        let errors = error.response.data.errors;
        this.errors = errors;
      });
    },
    // コンボボックスのルール除外スタッフ選択時
    handleChangeSelectedExcludedStaff (index) {
      this.modified_avoid_pattern[index].excluded_staff_list = this.selectedExcluedStaffs[index].map(staff => staff.office_staff_id)
    },
    // 選択済みの除外スタッフリストの初期値
    defaultSelectedExcludedStaffs (avoid_pattern_data) {
      return avoid_pattern_data.map(pattern =>
        pattern.excluded_staff_list.map(office_staff_id => {
          return this.combo_staffs.find(combo_staff => combo_staff.office_staff_id === office_staff_id)
        }).filter(Boolean)  // 退職済みや休職中になったスタッフの除外
      )
    },
  },
  watch: {
    avoid_pattern(newVal) {
      this.modified_avoid_pattern = newVal;
    },
    modified_avoid_pattern : {
      handler (value) {
        // 親画面に変更を通知
        if (this.isSave) {
          this.isSave = false;
        } else {
          this.$emit('setChange', true);
        }
      },
      deep:true
    },
  },
  mounted() {
    // avoid_patternの初期値を設定
    if (this.avoid_pattern.length === 0) {
      this.modified_avoid_pattern.push({
        category_list: [],
        excluded_staff_list: [],
      });
    }

    // 親画面の変更をクリア
    this.initializeChange();
  },
}
</script>

<style scoped>
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
