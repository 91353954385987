<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
    transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="text"
        background-color="white"
        hide-details="false"
        placeholder="日付を選択してください"
        v-on="on"
        dense
        outlined
        :disabled="disabled"
        :class="[returnParentErr() ? 'error_border' : 'noerror_border'] + ' ' + (disabled ? 'text_disabled' : '')"
        @keydown="closeDatePicker"
        @click="openDatePicker"
        @change="checkDate(text)"
        @blur="focusOutText()"
      >
      </v-text-field>
      <div class="invalid-feedback">{{err_message}}</div>
    </template>
    <v-date-picker
      v-model="picker"
      locale="jp-ja"
      :max="max"
      :type="date_type"
      :day-format="date => new Date(date).getDate()"
      @input="formatDate(picker)"
      >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'date'
    },
    default: {
      type: String,
      required: false
    },
    no : { // 複数利用の場合の通し番号
      type:Number,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    validation_check: {
      type: Boolean,
      required: false,
      default: true
    },
    required_check: {
      type: Boolean,
      required: false,
      default: false
    },
    check_priority: {
      type: Boolean,
      required: false,
      default: false
    },
    check_item_name: {
      type: String,
      required: false,
      default: null
    },
    para_err: {
      type: String,
      required: false,
      default: ''
    },
    max: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      err_message: '',
      date_type: null,
      menu: "",
      text: "",
      picker: null,
    }
  },
  mounted() {
    this.date_type = this.type
    this.picker = this.default || this.picker
    this.formatDate(this.picker)
  },
  watch: {
    picker (val) {
      if (this.no !== null) {
        this.$emit('date', val, this.no)
      } else {
        this.$emit('date', val)
      }
    },
    text (val) {
      // datepickerのエラーを優先する
      if (this.check_priority) {
        this.checkDateCommon(this.text);
        if (this.err_message != '' && this.err_message != null) {
          this.$emit('check_result', val, true);
        } else {
          this.$emit('check_result', val, false);
        }
      // 呼び出し元の入力エラーを優先する
      } else {
        if (this.no !== null) {
          this.$emit('date', val, this.no)
        } else {
          this.$emit('date', val)
        }
      }
    }
  },
  methods: {
    returnParentErr() {
      if (this.para_err =='' && this.err_message == '') {
        return false
      } else {
        return true
      }
    },
    checkDate(value) {
      if (this.validation_check && !this.check_priority) {
        this.err_message = ''
        this.checkDateCommon(value);
      }
    },
    checkDateCommon(value) {
        // 形式チェック
        if (value !== undefined && value) {
          let is_date_type_date = this.date_type === 'date'
          if (is_date_type_date) {
            // フォーマット（YYYY/MM/DD）チェック
            if (!value.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
                this.err_message = (this.check_item_name==null ? '' : this.check_item_name + 'の') + '形式が正しくありません。'
                return
            }
          } else {
            // フォーマット（YYYY/MM）チェック
            if (!value.match(/^\d{4}\/\d{2}$/)) {
                this.err_message = (this.check_item_name==null ? '' : this.check_item_name + 'の') + '形式が正しくありません。'
                return
            }
          }

          // 日付妥当性チェック
          let yyyy = value.substr(0, 4)
          let mm = value.substr(5, 2) - 1; //1月は0から始まる為 -1 する。
          let dd = is_date_type_date ? value.substr(8, 2) : '01'
          if (mm >= 0 && mm <= 11 && dd >= 1 && dd <= 31) {

              var vDt = new Date(yyyy, mm, dd)

              if (isNaN(vDt)) {
                  this.err_message = '存在しない日付です。';
              } else if(vDt.getFullYear() == yyyy && vDt.getMonth() == mm && vDt.getDate() == dd) {
                  this.err_message = ''
              } else {
                  this.err_message = (this.check_item_name==null ? '' : this.check_item_name + 'は') + '存在しない日付です。'
              }
          } else {
              this.err_message = (this.check_item_name==null ? '' : this.check_item_name + 'は') + '存在しない日付です。'
          }
        } else {
          if (this.required_check) {
            this.err_message = (this.check_item_name==null ? '' : this.check_item_name + 'は') + '必ず指定してください。'
          } else {
            this.err_message = ''
          }
        }

        return;
    },
    focusOutText() {
      this.$emit('focusout', this.text);
    },
    closeDatePicker() {
      this.menu = false
    },
    openDatePicker() {
      this.menu = true
    },
    formatDate (date) {
      this.err_message = ''

      if (!date || this.disabled === true) return null
      const [year, month, day] = date.split("-")
      this.text = year + '/' + month
      if (day !== undefined) {
        this.text += '/' + day
      }
      this.menu = false
      return
    }
  }
}
</script>

<style>
.v-messages__wrapper {
  font-size: 0.94rem !important;
  font-weight: 400;
}
.v-messages__message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d92550;
}
.error_border {
  border : solid 1px #d92550 !important;
}
.noerror_border {
  border : solid 1px #ced4da !important;
}
.v-text-field--outlined fieldset {
  border-width: 0px !important;
}
.text_disabled .white{
  background: #E9ECEF!important;
}
</style>
