<template>
  <div>
    <div class="h-100">
      <b-row class="h-100 no-gutters">
        <MySlick />
        <b-col lg="8" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
          <b-col lg="6" md="8" sm="12" class="mx-auto app-login-box">
            <div class="app-logo" />
            <h4>
              <div>パスワードをお忘れですか？</div>
              <span>登録されているメールアドレスを入力してください。<br />※メールアドレスを登録されていない方は、システム管理者にお問い合わせください。</span>
            </h4>
            <div>
              <form @submit.prevent="submit">
                <b-row form>
                  <b-col md="12">
                    <b-form-group>
                      <Label for="exampleEmail">メールアドレス</Label>
                      <b-form-input type="email" name="email" v-model="form.email" placeholder="メールアドレスを入力してください。" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="ml-auto">
                  <b-button type="submit" variant="primary" size="lg" :disabled="form.processing">パスワードを発行する</b-button>
                </div>
                <h6 class="mb-0 pt-3">
                  <Link :href="$url('/login')" class="text-primary">ログイン画面に戻る</Link>
                </h6>
              </form>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Layout2 from '@/Pages/Layouts/Layout2'
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue'
import MySlick from "@/Pages/Components/MySlick"

export default {
  name: 'ForgotPassword',
  layout: Layout2,
  components: {
    MySlick,
    Link
  },
  data: () => ({
    form: Inertia.form({
      email: null,
    }),
  }),

  methods: {
    submit() {
      this.form.post(this.$url('/forgot-password'), {
        preserveScroll: true,
        onSuccess:()=>{
          this.$swal(
            '',
            'パスワード変更用のURLをメール送信しました。',
            'success')
        },
        onError:(data) => {
          this.$swal(
            '',
            data.email,
            'info')
        }
      })
    }
  }
};
</script>
