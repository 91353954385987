<template>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleUp);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon
  }
};
</script>