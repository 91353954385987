<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="650">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div></div>
          <div>フラグとは</div>
          <div><button class="btn_close" @click="dialog = false">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <p class="title_green_line">主：主務フラグ</p>
          <p>
            複数の職種、または複数の事業所に跨って勤務するスタッフの方は、主務となる職種を設定します。<br />
            主務フラグによって常勤換算表の作成が行われます。
          </p>
          <p class="title_green_line">新：新人フラグ</p>
          <p>
            シフト作成において、職員の方を新人扱いとしたい場合に設定します。新人扱いとなった職員の方は、特定の勤務で新人のみでシフトに配置されないようになります。<br />
            詳細は「
            <a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E6%96%B0%E4%BA%BA%E3%83%95%E3%83%A9%E3%82%B0%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%A7%E3%81%99%E3%81%8B" target="_blank" rel="noopener noreferrer">新人フラグとは何ですか？
              <font-awesome-icon
                icon="external-link-alt"
                style="font-size: 12px; font-weight: 600; color: #1059CC; vertical-align: text-top;"
              />
            </a>」をご覧ください。
          </p>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
            <div style="display: flex; justify-content: center; width: 100%; padding: 0px 70px 70px 70px;">
            <button class="btn_gray_shadow" style="width:150px; padding: 5px 0;" @click="dialog = false">閉じる</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
a {
  color: #1059CC!important;
  text-decoration: underline!important;
}
a:hover{
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'AboutFlagDialog',
  components: {
  },
  props: {
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  },
  computed: {
  },
  mounted() {
  }
}
</script>

