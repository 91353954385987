<template>
  <div class="app-sidebar sidebar-shadow">
    <div id="sideberListArea" class="app-sidebar-content disable-arrow" style="top: 80px!important;" :style="'width: ' + (isOpen === 0 ? '180px' : '70px') + ';'">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <SidebarList :role="user.role" :lodgment="lodgment" @openSideber="sideberMenuclick()" />
      </VuePerfectScrollbar>
    </div>
    <div v-if="isOpen === 0" class="app-copylight">
       © SynCube Co., Ltd.
    </div>
    <div class="switch_area" :style="'width: ' + (isOpen === 0 ? '180px' : '70px') + ';'" @click="sideberSwitch">
      <font-awesome-icon :icon="'angle-double-' + (isOpen === 0 ? 'left' : 'right')" style="height: 40px!important; margin: auto!important;" />
    </div>
  </div>
</template>

<style scope>
.switch_area {
  color: white;
  background: #02B28A;
  text-align: center;
  height: 50px!important;
  padding: 6px;
}
.switch_area:hover {
  cursor: pointer;
}
.closed-main_inner {
  padding-left : 100px!important;
}
.closed-outer_main_left {
  padding-left: 60px!important;
}
.opened-outer_main_left {
  padding-left: 120px!important;
}
.closed-footer_inner {
  padding-left : 70px!important;
}
.closed-shift_footer {
  padding-left : 90px!important;
  z-index: 9999;
}
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SidebarList from "./SidebarList";
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  name: 'Sidebar',
  components: {
    VuePerfectScrollbar,
    SidebarList,
  },
  data() {
    return {
      /* 0: open / 1: close */
      isOpen: parseInt(this.$cookie.get('sideber_open')),
      windowWidth: 0,
    };
  },
  props: {
    sidebarbg: String
  },
  methods: {
    sideberSwitch() {
      let elBody = document.body;
      let elOuter = document.getElementById('main_outer');
      let elInner = document.getElementById('main_inner');
      let elList = document.getElementById('sideberListArea');

      if (this.isOpen === 0) {
        elBody.classList.add('closed-sidebar');
        elOuter.classList.add('closed-outer_main_left');
        elOuter.classList.remove('opened-outer_main_left');
        elInner.classList.remove('closed-main_inner');
        elList.classList.add('disable-arrow');
        this.isOpen = 1;
      } else {
        elBody.classList.remove('closed-sidebar');
        elOuter.classList.remove('closed-outer_main_left');
        elOuter.classList.add('opened-outer_main_left');
        elInner.classList.add('closed-main_inner');
        elList.classList.remove('disable-arrow');
        this.isOpen = 0;
      }

      // シフト、希望休申請状況の場合のみ
      if (document.getElementById('shiftFooterArea') !== null) {
        let elShiftFooter = document.getElementById('shiftFooterArea');
        if (this.isOpen === 0) {
          elShiftFooter.classList.remove('closed-shift_footer');
        } else {
          elShiftFooter.classList.add('closed-shift_footer');
        }
      }

      // 開閉情報をセッションに保持
      this.$cookie.set("sideber_open", this.isOpen, 1);
    },
    getWindowWidth() {
      const el = document.body;
      this.windowWidth = document.documentElement.clientWidth;
    },
    sideberMenuclick() {
      // Ctrl+クリックでメニューを開いたときはローディングをキャンセルする
      if (event && event.ctrlKey) {
        this.$emit('closeUpdate', false);
      }

      if (this.isOpen !== 0) {
        this.sideberSwitch();
      }
    },
  },
  computed: {
    user() {
      return this.$page.props.auth.user
    },
    lodgment() {
      return this.$page.props.auth.lodgment
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();

      //サイドバー設定
      if (this.isOpen === 1) {
        this.isOpen = 0;
        this.sideberSwitch();
      } else if (this.isOpen === -1) {
        this.isOpen = 1;
        this.sideberSwitch();
      }
    });

    console.log(this.user);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>
