<template>
  <table id="total-table" :class="( edit_mode ? 'table-total-edit' : 'table-total')" class="table table-base" :style="'table-layout: fixed; width: ' + getWidth() + 'px;'">
    <thead>
    <tr>
      <th class="fixed fixed-add stripe_green"></th>
      <th class="fixed fixed01 show-workpattern" style="text-align: center; z-index: 3!important">勤務区分</th>
      <th class="fixed fixed02 show-total" style="text-align: center; z-index: 3!important">合計</th>
      <th v-for="(day, index) in days" :key="index" class="summary" style="text-align: center;">
        {{ day.day }}<span :style="{ color: getDateColor(day) }">{{ day.w }}</span>
      </th>
      <th v-if="!edit_mode" v-for="(item, index) in work_categories" class="stripe_green" style="text-align: center; width: 28px!important; border: none!important;">
      </th>
      <th class="stripe_green" style="text-align: center; width: 22px!important; border: none!important;"></th>
    </tr>
  </thead>
    <tbody>
      <DefaultCalcColumn
        v-for="(item, index) in work_categories"
        ref="defaultCalcColumn"
        :key="'default-calc-' + index"
        :category="item"
        :days="days"
        :basic_infos="basic_infos"
        :shift_data="shift_data"
        :staff_assignment_for_category="staff_assignment_for_category(item)"
        :edit_mode="edit_mode"
        :workpatterns="workpatterns"
        :time_list="time_list"
        :shift_summaries="shift_summaries"
        :last_category="(work_categories.length - 1) === index"
        :work_category_identical_groups="work_category_identical_groups"
        :one_team_office_wp_list_assignment="one_team_office_wp_list_assignment"
        @addCalcRow="addCalcRow"
      />
      <ManualCalcColumn
        v-for="(item, index) in shift_summaries"
        ref="manualCalcColumn"
        :key="index"
        :id="index"
        :item="item"
        :days="days"
        :workpatterns="workpatterns"
        :time_list="time_list"
        :basic_infos="basic_infos"
        :shift_data="shift_data"
        :staff_assignment_for_workpattern="staff_assignment_for_workpattern(index)"
        :edit_mode="edit_mode"
        @deleteRow="deleteRow"
      />
    </tbody>
  </table>
</template>

<style>
.table thead th {
  vertical-align: middle;
}
.table-base {
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  border: none!important;
  margin-bottom: 0;
}
.table-base th {
  position: sticky;
  top: 0;
  border : solid 1px #D2D6DB!important;
  background-color: #F3F4F6!important;
  word-break: break-all;
  z-index: 1!important;
}
.table-base td {
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  border : solid 1px #D2D6DB!important;
  background-color: #FFFFFF;
}

/* 通常モード */
.table-total th {
  height: 18px!important;
  color: #111927;
  font-size: 10px!important;
  font-weight: 500!important;
  padding: 0px!important;
}
.table-total td {
  height: 25px!important;
  color: #111927;
  font-size: 15px!important;
  font-weight: 500!important;
  padding: 0px!important;
}
.table-total tr {
  background-color: #FFFFFF!important;
}
.table-total .fixed {
  position: sticky;
  z-index: 2;
}
.table-total .fixed-add {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  left: 0px;
  border: none!important;
  z-index: 10!important;
}
.table-total .fixed01 {
  font-size: 10px!important;
  width: 276px;
  min-width: 276px;
  max-width: 276px;
  left: 30px;
}
.table-total .fixed02 {
  font-size: 10px;
  width: 154px;
  min-width: 154px;
  max-width: 154px;
  left: 306px;
}
.summary-row-todal {
  font-size: 15px!important;
}
.table-total .summary {
  width: 42px!important;
  min-width: 42px!important;
  max-width: 42px!important;
}

/* 作成モード */
.table-total-edit th {
  font-size: 9px;
  font-weight: 500;
  height: 24px!important;
}
.table-total-edit td {
  font-size: 11px;
  width: 39px;
  min-width: 39px;
  max-width: 39px;
}
.table-total-edit .fixed {
  position: sticky;
  left: 0;
  z-index: 2;
  height: 36px;
}
.table-total-edit .fixed01 {
  font-size: 10px!important;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  left: 0;
}
.table-total-edit .summary {
  font-size: 9px!important;
  width: 39px!important;
  min-width: 39px!important;
  max-width: 39px!important;
}
</style>

<script>
import DefaultCalcColumn from '@/Pages/Shifts/Components/Table/SummaryTable/Default';
import ManualCalcColumn from '@/Pages/Shifts/Components/Table/SummaryTable/Manual';

export default {
  name: 'ShiftsScheduleSummaryTable',
  components: {
    DefaultCalcColumn,
    ManualCalcColumn,
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: { type: Boolean, default: false },
    // 事業所情報
    office: { type: Object },
    // 表示データの年月
    year: { type: String },
    month: { type: String },
    // 日付データ
    days: { type: Array },
    workpatterns: {
      type: Array,
      default: () => {
        return {};
      }
    },
    office_staff_workpatterns: {
      type: Object,
      default: () => {
        return {};
      }
    },
    org_work_categories: {
      type: Object,
      default: () => {
        return {};
      }
    },
    time_list: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // ユーザの基本情報
    basic_infos: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // shift_summariesの初期データ
    initial_shift_summaries: {
      type: Object,
      default: function() {
        return {};
      }
    },
    shift_data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    is_available_change_holiday_limit: {
      type: Boolean,
      default: true
    },
    monthly_staff_holidays: {
      type: Object,
      default: function() {
        return {};
      }
    },
    monthly_alerts: {
      type: Object,
      default: function() {
        return {};
      }
    },
    daily_alerts: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 同一カテゴリの勤務ID
    work_category_identical_groups: {
      type: Object|Array,
    },
    one_team_office_wp_list_assignment: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data () {
    return {
      times: {
        1: { start: '06:00:00', end: '07:00:00' },
        2: { start: '07:00:00', end: '08:00:00' },
        3: { start: '08:00:00', end: '09:00:00' },
        4: { start: '09:00:00', end: '10:00:00' },
        5: { start: '10:00:00', end: '11:00:00' },
        6: { start: '11:00:00', end: '12:00:00' },
        7: { start: '12:00:00', end: '13:00:00' },
        8: { start: '13:00:00', end: '14:00:00' },
        9: { start: '14:00:00', end: '15:00:00' },
        10: { start: '15:00:00', end: '16:00:00' },
        11: { start: '16:00:00', end: '17:00:00' },
        12: { start: '17:00:00', end: '18:00:00' },
        13: { start: '18:00:00', end: '19:00:00' },
        14: { start: '19:00:00', end: '20:00:00' },
        15: { start: '20:00:00', end: '21:00:00' },
        16: { start: '21:00:00', end: '22:00:00' },
        17: { start: '22:00:00', end: '23:00:00' },
        18: { start: '23:00:00', end: '00:00:00' },
        19: { start: '00:00:00', end: '01:00:00' },
        20: { start: '01:00:00', end: '02:00:00' },
        21: { start: '02:00:00', end: '03:00:00' },
        22: { start: '03:00:00', end: '04:00:00' },
        23: { start: '04:00:00', end: '05:00:00' },
        24: { start: '05:00:00', end: '06:00:00' }
      },
      work_categories: [],
      shift_summaries: {}
    }
  },

  methods: {
    // tableの幅取得
    getWidth() {
      let day_width = 42;
      if (this.edit_mode) {
        return 60 + (this.days.length * 39);
      } else {
        return 460 + (this.days.length * day_width);
      }
    },
    // カレンダーの曜日色取得
    getDateColor(day) {
      let result_color = day.color;
      if (day.holiday === true) {
        result_color = '#D61515';
      }
      return result_color;
    },
    // 集計行追加
    addCalcRow(type_id) {
      this.calc(type_id, true);
    },
    // 集計行削除
    deleteRow(type_id) {
      this.$delete(this.shift_summaries, type_id);
      this.$emit('updateShiftSummaries', type_id, 'delete', true);
    },
    /**
     * 集計処理
     * @param type_id time_[時間帯ID] or workpattern_[workpattern_id]
     * @param is_changed
     */
    calc(type_id, is_changed) {
      let data = {};
      for (let day of this.days) {
        data[day.day] = 0;
      }
      let tmp = type_id.split('_');
      // 時間帯ID or 勤務区分ID
      let id = tmp[1];
      // 時間帯の場合
      if (type_id.match(/time/)) {
        data = this.getTimeRageData(id, data);
      } else { // 勤務区分の場合
        data = this.getWorkpatternData(id, data);
      }
      this.$set(this.shift_summaries, type_id, data);
      this.$emit('updateShiftSummaries', type_id, 'add', is_changed);
    },
    /**
     * 時間帯のデータを取得する
     * @param {*} id
     * @param {*} data
     */
    getTimeRageData(id, data) {
      // const name = this.time_list[id].ja;

      // 時間のFROM-TOを取得
      let target_interval = this.times[id];

      if (target_interval.end === '00:00:00') {
        target_interval.end = '24:00:00';
      }
      let target_interval2 = Object.assign({}, target_interval);
      tmp = target_interval2.start.split(':');
      tmp[0] = Number(tmp[0]) + 24;
      target_interval2.start = tmp.join(':');
      tmp = target_interval2.end.split(':');
      tmp[0] = Number(tmp[0]) + 24;
      target_interval2.end = tmp.join(':');

      // 集計処理
      let tmp = [];
      for (let staff of this.basic_infos) {
        let staff_id = staff.office_staff_id + '_' + staff.occupation_id;
        for (let fullday in this.shift_data[staff_id]) {
          let shift = this.shift_data[staff_id][fullday];
          let day = this.shift_data[staff_id][fullday].day.day;
          if ('shift' in shift && 'workpattern' in shift.shift) {
            let workpattern = shift.shift.workpattern;
            if (workpattern === null) continue;
            if (workpattern.type !== 'work' && workpattern.type !== 'half_work') continue;

            let working_start_time = workpattern.working_start_time;
            let working_end_time = workpattern.working_end_time;
            let rest_start_time = workpattern.rest_start_time;
            let rest_end_time = workpattern.rest_end_time;

            // 休憩があるかどうかで場合分け
            if (
              rest_start_time !== null
              && rest_end_time !== null
              && rest_start_time !== rest_end_time
            ) { // 休憩あり
              // 日付マタギの時間の場合はstartからendまでカウントアップするように24を加える
              let type = 0;
              if (working_start_time > rest_start_time) {
                type = 1;
              } else if (rest_start_time > rest_end_time) {
                type = 2;
              } else if (rest_end_time > working_end_time) {
                type = 3;
              }
              if (type === 1) {
                tmp = rest_start_time.split(':');
                tmp[0] = Number(tmp[0]) + 24;
                rest_start_time = tmp.join(':');
              }
              if (type === 1 || type === 2) {
                tmp = rest_end_time.split(':');
                tmp[0] = Number(tmp[0]) + 24;
                rest_end_time = tmp.join(':');
              }
              if (type === 1 || type === 2 || type === 3) {
                tmp = working_end_time.split(':');
                tmp[0] = Number(tmp[0]) + 24;
                working_end_time = tmp.join(':');
              }

              if (
                (working_start_time < target_interval.end && rest_start_time > target_interval.start)
                || (rest_end_time < target_interval.end && working_end_time > target_interval.start)
                || (working_start_time < target_interval2.end && rest_start_time > target_interval2.start)
                || (rest_end_time < target_interval2.end && working_end_time > target_interval2.start)
              ) {
                data[day]++;
              }
            } else if (
              (rest_start_time === null && rest_end_time === null)
              || (
                rest_start_time !== null
                && rest_end_time !== null
                && rest_start_time === rest_end_time
              )
            ) { //休憩なし
              // 日付マタギの時間の場合はstartからendまでカウントアップするように24を加える
              if (working_start_time > working_end_time) {
                tmp = working_end_time.split(':');
                tmp[0] = Number(tmp[0]) + 24;
                working_end_time = tmp.join(':');
              }

              if (
                working_start_time < target_interval.end && working_end_time > target_interval.start
                || working_start_time < target_interval2.end && working_end_time > target_interval2.start
              ) {
                data[day]++;
              }
            }
          }
        }
      }
      return data;
    },
    /**
     * 勤務区分のデータを取得する
     * @param {*} id
     * @param {*} data
     */
    getWorkpatternData(id, data) {
      const workpattern = this.workpatterns.find((workpattern) => Number(workpattern.id) === Number(id))

      // 勤務区分がない場合はそのまま返す（加算しない）
      if(!workpattern || !Object.keys(workpattern).length) {
        return data
      }

      // 勤務区分名を取得
      const name = workpattern.name

      // 同一staffの同一勤務区分は１回のみカウントするため
      let countedId = []

      // 集計処理(staffごとに集計)
      for (let staff of this.basic_infos) {
        let staff_id = staff.office_staff_id + '_' + staff.occupation_id;
        // シフトデータごとループ
        for (let fullday in this.shift_data[staff_id]) {
          // シフト
          let shift = this.shift_data[staff_id][fullday];
          // 日付
          let day = this.shift_data[staff_id][fullday].day.day;
          // workカテゴリーの勤務区分の場合
          if (
            'shift' in shift
            && 'workpattern' in shift.shift
            && shift.shift.workpattern_id !== null
            && shift.shift.workpattern !== null
            && 'name' in shift.shift.workpattern
            && shift.shift.workpattern.name === name
            && !countedId.includes(day + '_' + staff.office_staff_id + '_' + shift.shift.workpattern_id)
          ) {
            data[day]++;
            // 既カウントのIDを配列に入れておく（日_スタッフID_勤務区分ID）
            countedId.push(day + '_' + staff.office_staff_id + '_' + shift.shift.workpattern_id)
          }
          // holidayカテゴリーの勤務区分の場合
          if (
            'shift' in shift
            && 'holiday_workpattern' in shift.shift
            && shift.shift.holiday_workpattern_id !== null
            && shift.shift.holiday_workpattern !== null
            && 'name' in shift.shift.holiday_workpattern
            && shift.shift.holiday_workpattern.name === name
            && !countedId.includes(day + '_' + staff.office_staff_id + '_' + shift.shift.holiday_workpattern_id)
          ) {
            data[day]++;
            // 既カウントのIDを配列に入れておく（日_スタッフID_勤務区分ID）
            countedId.push(day + '_' + staff.office_staff_id + '_' + shift.shift.holiday_workpattern_id)
          }
        }
      }
      return data;
    },
    /**
     * カテゴリーごとのスタッフ配置を取得する
     * @param {*} value
     */
    staff_assignment_for_category (value) {
      return this.staff_assignment[this.getCategoryKey(value)];
    },
    /**
     * カテゴリーのキーを取得する（逆引き用）
     */
     getCategoryKey (value) {
      return Object.keys(this.org_work_categories).find(key => this.org_work_categories[key] === value)
    },
    /**
     * 勤務区分ごとのスタッフ配置を取得する
     */
     staff_assignment_for_workpattern (value) {
      if (value.split('_')[0] === 'workpattern') {
        return this.office_staff_workpatterns[Number(value.split('_')[1])]
      } else {
        return {}
      }
    },
  },
  computed: {
    staff_assignment() {
      let result = {}
      const workpatterns = this.workpatterns
      Object.keys(this.office_staff_workpatterns).map(workpattern_id => {
        // activeじゃないworkpattern_idがoffice_staff_workpatternに入っていることがあるため
        if(workpatterns.findIndex(workpattern => workpattern.id === Number(workpattern_id)) === -1) return

        const workpattern = workpatterns.find(workpattern => workpattern.id === Number(workpattern_id))
        // カテゴリー取得して、カテゴリーごとに集計(early,late,night,dawn,day)
        let category = workpattern.category
        // 半勤務の判断用にtypeを取得
        let type = workpattern.type

        // 初期化
        if (!result[category]) {
          result[category] = {}
        }
        Object.keys(this.office_staff_workpatterns[workpattern_id]).map(day => {
          // 初期化
          if (!result[category][day]) {
            result[category][day] = { min: 0, max: 0 }
          }

          // 曜日設定がある（maxで判断）
          // 平日の場合、曜日設定→一括設定の優先順位
          // 祝日の場合、祝設定→曜日設定→一括設定の優先順位のためholは0であろうと足しておく。
          if(Number(this.office_staff_workpatterns[workpattern_id][day].max) !== 0 || day === 'hol') {
            // 集計(各勤務区分の数値の合計が勤務カテゴリーの値になる)
            result[category][day].min = result[category][day].min
              + (type === 'half_work'
                ? Number(this.office_staff_workpatterns[workpattern_id][day].min) * 0.5  // 半勤務の場合は0.5倍
                : Number(this.office_staff_workpatterns[workpattern_id][day].min))

            result[category][day].max = result[category][day].max
              + (type === 'half_work'
                ? Number(this.office_staff_workpatterns[workpattern_id][day].max) * 0.5 // 半勤務の場合は0.5倍
                : Number(this.office_staff_workpatterns[workpattern_id][day].max))
          } else {
            // 曜日ごとのmaxが0の場合はdefのmin,maxを設定する（初期値および未設定のため）
            result[category][day].min = result[category][day].min
              + (type === 'half_work'
                ? Number(this.office_staff_workpatterns[workpattern_id]['def'].min) * 0.5 // 半勤務の場合は0.5倍
                : Number(this.office_staff_workpatterns[workpattern_id]['def'].min))

            result[category][day].max = result[category][day].max
              + (type === 'half_work'
              ? Number(this.office_staff_workpatterns[workpattern_id]['def'].max) * 0.5 // 半勤務の場合は0.5倍
              : Number(this.office_staff_workpatterns[workpattern_id]['def'].max))
          }
        })
      });
      return result
    },
  },
  watch: {
    // shift_dataが変更を検知してshift_summariesを再計算する
    shift_data: {
      handler: function (val, oldVal) {
        for (let type_id in this.shift_summaries) {
          this.calc(type_id, true);
        }
      },
      deep: true
    },
    basic_infos: {
      handler: function (val, oldVal) {
        for (let type_id in this.shift_summaries) {
          this.calc(type_id, true);
        }
      },
      deep: true
    },
  },
  mounted() {
    for (let type_id in this.initial_shift_summaries) {
      this.calc(type_id, true);
    }
    let i = 0;
    for (let key in this.org_work_categories) {
      if (key === 'holiday') continue;
      this.$set(this.work_categories, i, this.org_work_categories[key]);
      i++;
    }
    this.$set(this.work_categories, i, '労働時間');
    this.$set(this.work_categories, i + 1, '所定休日');
    this.$set(this.work_categories, i + 2, '有休');
    this.$set(this.work_categories, i + 3, 'その他');
  },
  destroyed() {
    Object.keys(this.work_categories).forEach(key => {
      this.$delete(this.work_categories, key)
    });
  },
}
</script>
