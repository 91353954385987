<template>
  <div style="display: flex; justify-content: space-between; font-size: 12px!important">
    <b-button class="btn_white btn-show-description-flg" @click="showAboutFlag">
      <font-awesome-icon
        icon="question-circle"
        style="font-size: 14px!important; color: #1059CC; vertical-align: middle; padding-bottom:2px!important"
      />
      フラグとは
    </b-button>
    <AboutFlagDialog ref="AboutFlagDialog" />
  </div>
</template>

<style scoped>
.btn-show-description-flg {
  font-size: 12px !important;
  color: #1059CC !important;
  width: 100px !important;
  height: 22px !important;
  border: solid 1px #1059CC !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
</style>

<script>
import AboutFlagDialog from '@/Pages/Shifts/Components/Dialog/AboutFlagDialog';

export default {
  name: 'BtnAboutFlg',
  components: {
    AboutFlagDialog,
  },
  data () {
    return {};
  },
  methods: {
    showAboutFlag() {
      this.$refs.AboutFlagDialog.openDialog();
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>

