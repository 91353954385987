<template>
    <div>
      <b-button class="btn_white" style="width: auto; padding: 12px 20px!important; margin: 0px!important;"  @click="schedule">
        <font-awesome-icon icon="chevron-left" style="padding-right: 10px;"  />シフト作成画面に戻る

      </b-button>
      <div style="font-size: 24px; padding: 16px 0px;">詳細設定<span style="color: #33A272; padding-left: 40px;">事業所名：{{ office.name }}</span></div>
      <div style="display: flex; padding-right: 15px;">
        <div :class="(tab_combination ? 'tab-active' : '')" class="tab tab_style" style="text-align: center; float: left; padding: 12px;" @click="changeTab(0)">
          <div :class="(tab_combination ? 'tab-active-caption' : 'tab-passive-caption')">組合せ設定</div>
        </div>
        <div :class="(tab_combination ? '' : 'tab-active')" class="tab tab_style" style="text-align: center; float: left; padding: 12px;" @click="changeTab(1)">
          <span :class="(tab_combination ? 'tab-passive-caption' : 'tab-active-caption')">回避したいシフトパターン</span>
        </div>
      </div>
      <div class="stripe_green" style="padding: 30px 40px;">
        <div v-if="tab_combination" style="background: #FFFFFF; border-top: solid 5px #02B28A; padding: 24px;">
          <form class="pt-5">
            <div style="font-size: 18px; font-weight: 600; border-left: solid 5px #02B28A; padding-left: 10px;">
              組合せ設定
              <b-button
                class="btn_shift_reset"
                style="color: #1059CC!important;"
                @click="help_combination"
              >
                <font-awesome-icon
                  icon="circle-question"
                  style="vertical-align: text-top; padding-left: 12px;"
                />
                ヘルプ
              </b-button>
            </div>
            <div style="padding: 30px 0px;">
              組合せ設定では、シフトに入る職員の組合せを指定することができます。<br />
              この設定を行うことで、より希望に近いシフトを自動作成することができます。<br />
              <span style="color: red;">組合せOKを設定するときは、職員①にベテラン職員、職員②に新人（OJTが必要な）職員を設定してください。</span><br />
              組合せNGを設定するときは、職員①、職員②のどちらにベテラン職員、新人（OJTが必要な）職員を設定しても構いません。
            </div>
            <table>
              <tr>
                <td style="border-right: solid 1px #D2D6DB; padding: 10px 20px 10px 0px;"><span class="required">組合せる職員</span></td>
                <td style="padding-left: 20px;">
                  <div style="display: flex;">
                    <div>
                      <v-combobox
                        v-model="form.staff_1"
                        :items="combo_staffs"
                        item-value="office_staff_id"
                        item-text="name"
                        solo
                        hide-selected
                        hide-details
                        multiple
                        label="職員①（複数選択可）"
                        style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px;"
                        :class="{'is-invalid': errors.staff_1}"
                      >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-bind="attrs"
                            color="#297ADB"
                            text-color="#FFFFFF"
                            :input-value="selected"
                          >
                            <span class="pr-2">
                              {{ item.name }}
                            </span>
                            <v-icon
                              color="#FFFFFF"
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                      <div v-if="errors.staff_1" class="text-danger">{{ errors.staff_1[0] }}</div>
                    </div>
                    <div class="text-center" style="display: grid; color: #02B28A; align-items: center; padding: 0px 10px;"><font-awesome-icon icon="plus" /></div>
                    <div>
                      <v-combobox
                        v-model="form.staff_2"
                        :items="combo_staffs"
                        item-value="office_staff_id"
                        item-text="name"
                        solo
                        hide-selected
                        hide-details
                        multiple
                        label="職員②（複数選択可）"
                        style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px;"
                        :class="{'is-invalid': errors.staff_2}"
                      >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-bind="attrs"
                            color="#297ADB"
                            text-color="#FFFFFF"
                            :input-value="selected"
                          >
                            <span class="pr-2">
                              {{ item.name }}
                            </span>
                            <v-icon
                              color="#FFFFFF"
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                      <div v-if="errors.staff_2" class="text-danger">{{ errors.staff_2[0] }}</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="border-right: solid 1px #D2D6DB;"><span class="required">組合せ</span></td>
                <td style="padding: 10px 0px 10px 20px;">
                  <b-form-radio-group v-model="form.is_valid" :class="{'is-invalid': errors.is_valid}">
                    <b-form-radio :value="1" :class="{'is-invalid': errors.is_valid}">OK</b-form-radio>
                    <b-form-radio :value="0" style="margin-left:10px;" :class="{'is-invalid': errors.is_valid}">NG</b-form-radio>
                    <div v-if="errors.is_valid" class="text-danger"><small>{{ errors.is_valid[0] }}</small></div>
                  </b-form-radio-group>
                </td>
              </tr>
              <tr>
                <td style="border-right: solid 1px #D2D6DB;"><span class="required">対象勤務区分</span></td>
                <td style="padding: 10px 0px 10px 20px;">
                  <v-combobox
                    v-model="form.workpattern_list"
                    :items="workpatterns"
                    item-value="id"
                    item-text="name"
                    solo
                    hide-selected
                    hide-details
                    multiple
                    label="選択してください（複数選択可）"
                    style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px;"
                    :class="{'is-invalid': errors.workpattern_list}"
                  >
                  <template v-slot:no-data>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title></v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  </v-combobox>
                  <div v-if="errors.workpattern_list" class="text-danger"><small>{{ errors.workpattern_list[0] }}</small></div>
                </td>
              </tr>
            </table>
            <div v-if="open_add == true">
            <b-button class="btn_shift_save" style="margin: 20px 0px;" @click="save">保存</b-button>
            <span style="font-size: 12px; color: #707070; margin-left: 35px;"
              id="save_message"
              v-text="smessage"
            >
              {{ smessage }}
            </span>
            </div>
            <div v-else>
              <input
                  type="hidden"
                  v-model="form.update_id"
              />
              <b-button class="btn_shift_save" style="margin: 20px 0px;" @click="edit">編集</b-button>
              <span style="font-size: 12px; color: #707070; margin-left: 35px;" id="update_message"></span>
            </div>
            <div style="margin: 50px 0px 20px 0px;">組合せ一覧</div>

            <div
              style="background: #FAFAFA; padding: 20px 30px;"
              v-for='(combination, index) in modified_combination_list'
              :key="index"
            >
              <table class="setting_table">
                <tr>
                  <th style="width: 200px;">
                    <div style="display: flex; justify-content: space-between;">
                      <div>職員①</div>
                      <button class="sort_btn" style="display: none;">
                        <span ><font-awesome-icon icon="long-arrow-alt-up" class="sort_active" /></span>
                        <span ><font-awesome-icon icon="long-arrow-alt-down" /></span>
                      </button>
                    </div>
                  </th>
                  <th style="width: 200px;">
                    <div style="display: flex; justify-content: space-between;">
                      <div>職員②</div>
                      <button class="sort_btn" style="display: none;">
                        <span ><font-awesome-icon icon="long-arrow-alt-up" class="sort_active" /></span>
                        <span ><font-awesome-icon icon="long-arrow-alt-down" /></span>
                      </button>
                    </div>
                  </th>
                  <th style="width: 200px;">組合せ</th>
                  <th>対象勤務区分</th>
                </tr>
                <tr>
                  <td>{{ getStaffName(combination.staff_1) }}</td>
                  <td>{{ getStaffName(combination.staff_2) }}</td>
                  <td>{{ combination.okng ? `OK` : `NG` }}</td>
                  <td>{{ getWorkpatternNames(combination.workpatterns) }}</td>
                </tr>
              </table>
              <b-button class="edit_button" @click="editItem(combination)">編集</b-button>
              <b-button class="del_button" style="margin-top: 20px; margin-left: 10px;" @click="deleteItem(combination)">削除</b-button>
            </div>
          </form>
        </div>
        <div v-else>
          <AvoidPatternTab
            :combo_staffs="combo_staffs"
            :avoid_pattern="avoid_pattern"
            :codes="codes.data"
            :office="office"
          />
        </div>
      </div>
    </div>
</template>

<style scoped>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  border: 1px solid #ced4da!important;
}
.tab {
  cursor: pointer;
}
.tab:focus {
  opacity: 0.7;
}
.tab-active {
  border-bottom: 3px solid #3f6ad8;
}
.tab-active-caption {
  color: #3f6ad8;
}
.tab-passive-caption {
  color: none;
}
.setting_table {
  width: 100%;
}
.setting_table th {
  height: 45px;
  background: #F3F4F6;
  border: solid 1px #D2D6DB;
  padding: 0px 5px;
}
.setting_table td {
  height: 45px;
  background: #FFFFFF;
  border: solid 1px #D2D6DB;
  padding: 0px 5px;
}
.edit_button {
  color: #02B28A;
  background: #FFFFFF;
  text-align: center;
  border: 1px solid #D2D6DB;
  border-radius: 4px;
  padding: 6px 16px;
  margin-top: 20px;
}
.del_button {
  color: #707070;
  background: #FFFFFF;
  text-align: center;
  border: 1px solid #D2D6DB;
  border-radius: 4px;
  padding: 6px 16px;
}
</style>

<script>
import AvoidPatternTab from "./AvoidPatternTab.vue";
import axios from "axios"

export default {
  components: {
    AvoidPatternTab
  },
  props: {
    // 勤務区分一覧
    workpatterns: {
      type: Array,
      required: true
    },
    // 職員一覧（休職、退職除く）
    staffs: {
      type: Array,
      required: true
    },
    office: {
      type: Object,
      required: true
    },
    // 組合せ一覧
    combination_list: {
        type: Array,
        required: true
    },
    // 回避したいシフトパターン
    avoid_pattern: {
        type: Array,
        required: true
    },
    codes :{
      type : Array|Object,
      required :true
    },
    year: {
      type: String,
      // 今年
      default: new Date().getFullYear()
    },
    month: {
      type: String,
      // 今月
      default: new Date().getMonth() + 1
    },
  },
  computed: {
  },
  data() {
    return {
      modified_combination_list: this.combination_list,
      form: {
          staff_1: [],
          staff_2: [],
          workpattern_list: [],
          is_valid: '',
      },
      combo_staffs: [],
      open_add: true,
      tab_combination: true,
      smessage: '',
      now: '',
      errors: {},
    }
  },
  methods: {
    // 職員名取得
    getStaffName(office_staff_ids) {
      let staffs = this.staffs.filter(staff => office_staff_ids.includes(staff.office_staff_id));
      return staffs.map(staff => staff.lastname + ' ' + staff.firstname).join(', ');
    },
    // 勤務区分名取得
    getWorkpatternNames(workpattern_ids) {
      let workpatterns = this.workpatterns.filter(workpattern => workpattern_ids.includes(workpattern.id));
      return workpatterns.map(workpattern => workpattern.name).join(', ');
    },
    // シフト作成画面に戻る
    schedule() {
      this.updateLoading(true);
      this.$inertia.get(this.$route('shifts.schedule', {office: this.office.id, year: this.year, month: this.month}), {})
    },
    // 組合せ設定ヘルプ
    help_combination() {
      window.open('https://support.synchroshift.jp/portal/ja/kb/articles/1070', '_blank');
    },
    /**
     * 組み合わせの新規（追加）保存
     */
     save() {
      this.updateLoading(true);
      var formData = new FormData();
      formData.append('staff_1',JSON.stringify(this.form.staff_1));
      formData.append('staff_2',JSON.stringify(this.form.staff_2));
      formData.append('workpattern_list', JSON.stringify(this.form.workpattern_list));
      formData.append('is_valid',this.form.is_valid);
      formData.append('office_id',this.office.id);
      let url = this.$route('shifts.combinations.save');
      axios.post(url,formData).then(response =>{
          let results = response.data.results;
          this.updateLoading(false);
          if(results.status !="success"){
              this.$swal({
                  title: "保存に失敗しました。",
                  icon: 'error',
              });
          }else{
              this.$swal({
                  title: "保存しました",
                  icon: 'success',
              });
              this.modified_combination_list = results.combination_list;
              this.form.staff_1 = '';
              this.form.staff_2 = '';
              this.form.is_valid = '';
              this.form.workpattern_list = '';
              this.form.update_id = '';
              this.errors = {};
              this.now = new Date().toLocaleString();
              this.smessage = "保存しました。 "+ this.now;
          }
      })
      .catch(error => {
        this.updateLoading(false);
         let errors = error.response.data.errors;
         this.errors = errors;
      });
    },
    /**
     * 表の操作(保存)
     */
    edit() {
      this.updateLoading(true);
      var formData = new FormData();
      formData.append('staff_1',JSON.stringify(this.form.staff_1));
      formData.append('staff_2',JSON.stringify(this.form.staff_2));
      formData.append('workpattern_list', JSON.stringify(this.form.workpattern_list));
      formData.append('is_valid',this.form.is_valid);
      formData.append('update_id',this.form.update_id);
      formData.append('office_id',this.office.id);

      let url = this.$route('shifts.combinations.save');

      axios.post(url,formData).then(response =>{
          let results = response.data.results;
          this.updateLoading(false);
          if(results.status !="success"){
              this.$swal({
                  title: "編集に失敗しました。",
                  icon: 'error',
              });
          }else{
              this.$swal({
                  title: "編集しました",
                  icon: 'success',
              });
              this.modified_combination_list = results.combination_list;
              this.open_add = true;
              this.form.staff_1 = '';
              this.form.staff_2 = '';
              this.form.is_valid = '';
              this.form.workpattern_list = '';
              this.form.update_id = '';
              this.now = new Date().toLocaleString();
              this.smessage = "編集しました。 "+ this.now;
              this.errors = {};
          }
      })
      .catch(error => {
        this.updateLoading(false);
        let errors = error.response.data.errors;
        this.errors = errors;
      });
    },

    /**
     * 表の操作(編集)
     */
    editItem (combination) {
        this.open_add = false;
        this.updateLoading(true);
        let url = this.$route('shifts.combinations.edit', combination.id);
        axios.get(url).then(response =>{
            let results = response.data.results;
            if(results.status =="success"){
              this.open_add = false;
              this.form.is_valid = results.combinations.is_valid;
              this.form.workpattern_list = results.work_list;
              this.form.update_id = results.combinations.id;

              let staffs_1 = this.staffs.filter(staff => results.combinations.office_staff_a_ids.includes(staff.office_staff_id));
              const staff_1_list = [];
              for (let staff_index in staffs_1) {
                staff_1_list.push(
                  {
                    id: staffs_1[staff_index].id,
                    office_staff_id: staffs_1[staff_index].office_staff_id,
                    name: staffs_1[staff_index].lastname + ' ' + staffs_1[staff_index].firstname
                  }
                );
              }
              this.form.staff_1 = staff_1_list;

              let staffs_2 = this.staffs.filter(staff => results.combinations.office_staff_b_ids.includes(staff.office_staff_id));
              const staff_2_list = [];
              for (let staff_index in staffs_2) {
                staff_2_list.push(
                  {
                    id: staffs_2[staff_index].id,
                    office_staff_id: staffs_2[staff_index].office_staff_id,
                    name: staffs_2[staff_index].lastname + ' ' + staffs_2[staff_index].firstname
                  }
                );
              }
              this.form.staff_2 = staff_2_list;
            }
        });
        this.updateLoading(false);
    },
    /**
     * 表の操作(削除)
     */
    deleteItem (item) {
        this.$swal({
            title: "本当に削除しますか？",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '削除',
            cancelButtonText: 'キャンセル',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
              this.updateLoading(true);
              let url = this.$route('shifts.combinations.delete', {office_id:this.office.id,combination_id:item.id});
              axios.delete(url).then(response =>{
                  let results = response.data.results;
                  this.updateLoading(false);
                  if(results.status !="success"){
                      this.$swal({
                        title: "削除に失敗しました。",
                          icon: 'error',
                      });
                  }else{
                      this.$swal({
                        title: "削除しました",
                          icon: 'success',
                      });
                      this.modified_combination_list = results.combination_list;
                      this.errors = {};
                      this.open_add = true;
                      this.form.staff_1 = '';
                      this.form.staff_2 = '';
                      this.form.is_valid = '';
                      this.form.workpattern_list = '';
                      this.form.update_id = '';
                      this.now = new Date().toLocaleString();
                      this.smessage = "削除しました。 "+ this.now;
                  }
              });
            }
        })
    },
    changeTab( tab ) {
      if (tab == 0) {
        this.tab_combination = true;
      } else {
        this.tab_combination = false;
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  mounted() {
    this.updateLoading(false);
    // 職員リスト用データ作成
    for (let staff_index in this.staffs) {
      this.combo_staffs.push(
        {
          id: this.staffs[staff_index].id,
          office_staff_id: this.staffs[staff_index].office_staff_id,
          name: this.staffs[staff_index].lastname + ' ' + this.staffs[staff_index].firstname
        }
      );
    }
    window.onload = ()=>{
      this.form.staff_1 = '';
      this.form.staff_2 = '';
      this.form.is_valid = '';
      this.form.workpattern_list = '';
      this.form.update_id = '';
      this.errors = {};
    }
  },
  watch: {
    combination_list (newVal) {
      this.modified_combination_list = newVal;
    }
  }
}
</script>
