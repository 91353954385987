<template>
  <div class="m-3 p-3" style="width: 600px;">
    <h4>解約完了</h4>
    <Form class="pt-5">
      <div>
        <b-row>
          <b-col class="pr-0 py-1" cols="12">
            ご利用ありがとうございます。解約しました。
          </b-col>
        </b-row>
      </div>
    </Form>
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";

export default {
  components: {
    datepicker
  },
  props: {
    base:Object,
  },
  data() {
    return {
      form: Inertia.form(this.base),
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.updateLoading(false);
  },
  updated() {
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
};
</script>