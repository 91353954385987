<template>
  <div>
    <label class="main_button">
      <input
        type="text"
        v-model="staff.occupation_main_flg"
        @click="changeMainFlg(staff)"
        :disabled="staff.affiliation_office_count.count === 1 && staff.occupation_percentage === 100"
      >
      <span
        :class="[staff.affiliation_office_count.count === 1 && staff.occupation_percentage === 100 ? 'mouseover-cursor' : '', staff.occupation_main_flg ? 'main-flg-checkbox-active' : '']">
        主
      </span>
    </label>
    <!-- mainflg dialog -->
    <SelectMainFlgDialog
        ref="SelectMainFlgDialog"
      />
  </div>
</template>

<style scoped>
.main-flg-checkbox-active {
  color: #4B9575;
  border: 0.800000011920929px solid #D6D6D6;
  background: linear-gradient(to top, #dddee0, #FFFFFF);
}
.mouseover-cursor {
  cursor: default;
}
</style>

<script>
import SelectMainFlgDialog from '@/Pages/Shifts/Components/Dialog/SelectMainFlgDialog.vue';

export default {
  name: 'BtnSelectMainFlg',
  components: {
		SelectMainFlgDialog,
  },
  props: {
    staff: {
      type: Object,
      default: function() {
        return {};
      }
    },
    year: { type: String },
    month: { type: String },
    view_type: { type: String },
  },
  data () {
    return {
    }
  },
  methods: {
    changeMainFlg(data) {
      data.year = this.year;
      data.month = this.month;
      data.tab_value = this.view_type;
      data.staff = JSON.parse(JSON.stringify(this.staff));

      // mainflg dialogを開く
      this.$refs.SelectMainFlgDialog.openDialog(data);
    },
  },
}
</script>
