<script>
export default {
  name: 'CategoryView',
  props: {
    categories: {
      type: Object
    },
    selected: {
      type: String,
      default: 'all',
    }
  },
  methods: {
    changeCategory(val) {
      this.$emit('changeCategory', val);
    },
  },
}
</script>

<template>
  <div style="display: flex; justify-content: space-between;">
    <label class="workpattern_change_button workpattern_change_category">
      <input
        type="radio"
        name="category"
        value="all"
        :checked="selected == 'all'"
        @change="changeCategory('all')"
      >
      <span>すべて</span>
    </label>
    <label
      v-for="(category, index) in categories"
      class="workpattern_change_button workpattern_change_category"
    >
      <input
        type="radio"
        name="category"
        :value="index"
        :checked="selected == index"
        @change="changeCategory(index)"
      ><span>{{ category }}</span>
    </label>
  </div>
</template>
