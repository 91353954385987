<template>
  <tr>
    <td class="fixed fixed-add stripe_green" style="border: none!important;">
      <AddCalcColumn
        v-if="last_category"
        :days="days"
        :workpatterns="workpatterns"
        :time_list="time_list"
        :shift_summaries="shift_summaries"
        @selectCalcTarget="selectCalcTarget"
        />
    </td>
    <td
      class="fixed fixed01 show-workpattern"
      style="text-align: center;"
    >
      {{ category }}
    </td>
    <td class="fixed fixed02 summary-row-todal show-total">
      {{ calcTotal() }}
    </td>
    <td
      v-for="(day, index) in days"
      style="text-align: center;"
      :class="colorSelect(day, category)"
      :key="index"
    >
      {{ outputData(day.day) }}
    </td>
  </tr>
</template>

<style scoped>
.lower{
  background: #FFFFFF!important;
  color: blue!important;
}
.upper {
  background: #FFFFFF!important;
  color: red;
  font-weight: 900;
  color: red!important;
  font-weight: 900!important;
}
.default {
  background: #FFFFFF!important;
  color: black!important;
}
</style>

<script>
import AddCalcColumn from '@/Pages/Shifts/Components/Table/SummaryTable/AddButton';

export default {
  name: 'ShiftsScheduleListDefaultCalcColumn',
  components: {
    AddCalcColumn,
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    // 日付データ
    days: {
      type: Array,
      default: function() {
        return [];
      }
    },
    category: {
      type: String,
      default: ''
    },
    basic_infos: {
      type: Array,
      default: () => {
        return [];
      }
    },
    shift_data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    staff_assignment_for_category: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 同一カテゴリの勤務ID
    work_category_identical_groups: {
      type: Object|Array,
    },
    one_team_office_wp_list_assignment: {
      type: Array,
      default: () => {
        return [];
      }
    },
    workpatterns: {
      type: Array,
      default: () => {
        return {};
      }
    },
    time_list: {
      type: Object,
      default: () => {
        return {};
      }
    },
    shift_summaries: {
      type: Object,
      default: function() {
        return {};
      }
    },
    last_category: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      list: {},
      list_working_time: {},
      group_assignment_identical_category: {},
    }
  },
  methods: {
    calc() {
      // 縦集計 0で初期化
      for (let key in this.days) {
        this.$set(this.list, this.days[key].day, 0);
        this.$set(this.list_working_time, this.days[key].day, 0);
      }

      // 日毎（縦の計算）
      for (let key in this.days) {
        // 日付
        let day = this.days[key].day;
        // 年月日
        let full = this.days[key].full;
        // office_staff_idとworkpattern_category(early,day,late,night,dawn）のペアを格納する配列
        let pair = [];
        // 事業所スタッフごとにループ
        staff_loop: for (let staff of this.basic_infos) {
          // 事業所スタッフID
          let office_staff_id = staff.office_staff_id;
          // 職種ID
          let occupation_id = 0;

          // 職種IDがnullでない場合は職種IDをセット
          if (staff.occupation_id !== null) {
            occupation_id = staff.occupation_id;
          }

          // シフトデータを取り出す(事業所スタッフIDと職種ID、年月日を指定)
          let col = this.shift_data[office_staff_id + '_' + occupation_id][full];

          // シフトが存在する場合のみカウント
          // ここからカウント処理
          if (col && 'shift' in col) {
            // シフトデータ
            let shift = col.shift;
            // 勤務タイプ
            let workpattern_type = shift.workpattern ? shift.workpattern.type : '';
            // 勤務カテゴリー
            let workpattern_category = shift.workpattern ? shift.workpattern.category : '';

            for (let tmp of pair) {
              // 半休（半勤務）の場合
              if(shift.holiday_workpattern) {
                // 半休のカウントはtypeごとに１回のみ
                if (tmp === office_staff_id + '_' + workpattern_category + '_' + shift.holiday_workpattern.type) {
                  continue staff_loop;
                }
              }
              // 勤務、休日（全日：有休、所定休日、その他）の場合
              // 同一の勤務カテゴリー＋勤務タイプは１回のみカウント
              if (tmp === office_staff_id + '_' + workpattern_category + '_' + workpattern_type) {
                continue staff_loop;
              }
            }

            // 重複チェック用のペアに追加
            if(shift.holiday_workpattern) {
              // 半休（半勤務）
              pair.push(office_staff_id + '_' + workpattern_category + '_' + shift.holiday_workpattern.type);
            }
            // 勤務、休日（全日）
            pair.push(office_staff_id + '_' + workpattern_category + '_' + workpattern_type);

            // 稼働時間を加算
            if(workpattern_type === 'work' || workpattern_type === 'half_work') {
                // 稼働時間を加算
                let parts = shift.workpattern.working_hours.split(':');
                let hours = parseInt(parts[0]);
                let minutes = parseInt(parts[1]);
                let seconds = parseInt(parts[2]);
                let addTime = hours + (minutes / 60) + (seconds / 3600);
                this.$set(this.list_working_time, day, this.list_working_time[day] + addTime);
            }

            // カウント対象のカテゴリーによって処理を分岐
            switch (true) {
              // 勤務をカウント
              case this.category === '早番' && workpattern_category === 'early':
              case this.category === '日勤' && workpattern_category === 'day':
              case this.category === '遅番' && workpattern_category === 'late':
              case this.category === '夜勤' && workpattern_category === 'night':
              case this.category === '明番' && workpattern_category === 'dawn':
                // 勤務なので１を加算
                if(workpattern_type === 'work') {
                  this.$set(this.list, day, this.list[day] + 1);
                  this.$set(this.list_working_time, day, this.list_working_time[day] + 1);
                } else if (workpattern_type === 'half_work') { // 半勤の場合（typeがhalf_workの場合）
                  // 半勤務分を加算
                  this.$set(this.list, day, this.list[day] + 0.5);
                  this.$set(this.list_working_time, day, this.list_working_time[day] + 10);
                }

                break;
              // 所定休日カウント
              case this.category === '所定休日':
                // 休日の場合
                if(shift.holiday_workpattern === null) {
                  if(workpattern_category === 'holiday') {
                    if(workpattern_type === 'public_holidays') {
                      // 休日なので１を加算
                      this.$set(this.list, day, this.list[day] + 1);
                    }
                    // 半休は必ず半勤務とセットなのでここではカウントしない
                  }
                } else if (shift.holiday_workpattern && shift.holiday_workpattern.category === 'holiday') {
                  // 半休の場合
                  if(['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1
                    && workpattern_type === 'half_work'
                    && shift.holiday_workpattern.type === 'public_half_holiday')
                  {
                    // 半休なので0.5を加算
                    this.$set(this.list, day, this.list[day] + 0.5);
                  }
                }
                break;
              // 有休をカウント
              case this.category === '有休':
                // 休日の場合
                if(shift.holiday_workpattern === null) {
                  if(workpattern_category === 'holiday') {
                    if(workpattern_type === 'paid_holidays') {
                      // 休日なので１を加算
                      this.$set(this.list, day, this.list[day] + 1);
                    }
                    // 半休は必ず半勤務とセットなのでここではカウントしない
                  }

                } else if (shift.holiday_workpattern && shift.holiday_workpattern.category === 'holiday') {
                  // 半休の場合
                  if(['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1
                    && workpattern_type === 'half_work'
                    && shift.holiday_workpattern.type === 'paid_half_holiday')
                  {
                    // 半休なので0.5を加算
                    this.$set(this.list, day, this.list[day] + 0.5);
                  }
                }
                break;
              // その他の休日をカウント
              case this.category === 'その他':
                // 休日の場合
                if(shift.holiday_workpattern === null) {
                  if(workpattern_category === 'holiday') {
                    if(workpattern_type === 'other_holiday') {
                      // 休日なので１を加算
                      this.$set(this.list, day, this.list[day] + 1);
                    }
                    // 半休は必ず半勤務とセットなのでここではカウントしない
                  }
                } else if (shift.holiday_workpattern && shift.holiday_workpattern.category === 'holiday') {
                  // 半休の場合
                  if(['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1
                    && workpattern_type === 'half_work'
                    && shift.holiday_workpattern.type === 'other_half_holiday')
                  {
                    // 半休なので0.5を加算
                    this.$set(this.list, day, this.list[day] + 0.5);
                  }
                }
                break;
              default:
                break;
            }
          }
        }
      }
    },
    /**
     * 横集計を出力
     */
     calcTotal() {
      let total = 0;
      if (this.category === '労働時間') {
        for (let key in this.list_working_time) {
          total += this.list_working_time[key];
        }
        let decimal = Math.floor(total * 100) / 100;
        return decimal.toFixed(1);
      } else {
        for (let key in this.list) {
          total += this.list[key];
        }
        return total;
      }
    },
    // 集計行追加
    selectCalcTarget(type_id) {
      this.$emit('addCalcRow', type_id);
    },
    /**
     * 労働時間の場合は、稼働時間計を出力
     */
    outputData(day) {
      if (this.category === '労働時間') {
        let decimal = Math.floor(this.list_working_time[day] * 100) / 100;
        return decimal.toFixed(1);
      } else {
        return this.list[day];
      }
    },
    /**
     * 曜日英文字の逆引き用
     * @param {*} num
     */
    getDayEn(num) {
      return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][num];
    },
    /**
     * 該当日のスタッフ数が最大値を超えている場合はlowerクラスを返す
     * 該当日のスタッフ数が最小値を下回っている場合はupperクラスを返す
     * Table.vueにてmin,mizが0,0の場合には一括指定のmin,maxを採用済
     */
    colorSelect(day, category) {
       
      // this.listに{__ob__: Observer}が入って邪魔なので対策
      if(this.list[day.day] === undefined) return;

      //勤務グループによる判定を通常のカテゴリごとのチェックより優先する
      let day_eng = day.holiday ? 'hol' : this.getDayEn(day.w_num)
      let category_group_status = this.colorSelectWorkCategoryGroup(day, day_eng, category);
      if(category_group_status){
        return category_group_status
      }

      if (day.holiday) {
        /**
         * 祝日の場合
         * 祝設定→曜日設定（曜日設定が0なら一括設定）
         */
        return  this.colorSelectHoliday(day, 'hol') || this.colorSelectWeekday(day, this.getDayEn(day.w_num));
      } else {
        /**
         * 曜日指定の場合
         */
        return this.colorSelectWeekday(day, this.getDayEn(day.w_num))
      }
    },
    /**
     * 祝日用のmin,maxによる色分け
     */
    colorSelectHoliday(day, key) {
      if(this.staff_assignment_for_category[key]
        && this.staff_assignment_for_category[key].max !== 0
        && this.staff_assignment_for_category[key].min !== 0
      ) {
          // 該当日のスタッフ数が最大値を超えている場合
          if(this.list[day.day] > this.staff_assignment_for_category[key].max) {
            return 'lower';
          }
          // 該当日のスタッフ数が最小値を下回っている場合
          if(this.list[day.day] < this.staff_assignment_for_category[key].min) {
            return 'upper';
          }
          // 範囲内なら何も返さない
          return 'default'
      }
      return null;
    },
    /**
     * 曜日指定のmin,maxによる色分け
     */
    colorSelectWeekday(day, key) {
      // maxとminがどちらも0の場合で、かつ、数値が0より大きい場合はupperクラスを返す
      if(this.staff_assignment_for_category[key]
        && this.staff_assignment_for_category[key].max === 0
        && this.staff_assignment_for_category[key].min === 0
        && this.list[day.day] > 0) {
        return 'upper';
      }

      // 該当日のスタッフ数が最大値を超えている場合
      if(this.staff_assignment_for_category[key]
        && this.list[day.day] > this.staff_assignment_for_category[key].max) {
          return 'lower';
      }
      // 該当日のスタッフ数が最小値を下回っている場合
      if(this.staff_assignment_for_category[key]
        && this.list[day.day] < this.staff_assignment_for_category[key].min) {
        return 'upper';
      }
    },

    /**
     * 勤務グループが設定されている場合
     */
    colorSelectWorkCategoryGroup(day, key, category) {
      let work_category_eng = this.getWorkPatternEnglishCategory(category)
      let assignment = this.group_assignment_identical_category[work_category_eng]

      if(assignment === undefined){
        return null
      }

      //初期値曜日指定
      let max = assignment[key].max
      let min = assignment[key].min
      //曜日指定の値が0-0ならdefの値で上書き
      if(assignment[key]
        && assignment[key].max === 0
        && assignment[key].min === 0
      ){
        max = assignment.def.max
        min = assignment.def.min
      }

      //def,曜日ともに0ならupperを返す
      if(max === 0 && min === 0){
        return 'upper';
      }
      //配置人数が設定値の最大よりも多い場合
      if(assignment && this.list[day.day] > max){
        return 'lower';
      }
      //配置人数が設定値の最小よりも少ない場合
      if(assignment && this.list[day.day] < min){
        return 'upper';
      }

      //範囲内なので何もしない
      return 'default';
    },
    //早番、遅番とかをearly、lateとかに変換したい
    getWorkPatternEnglishCategory(category_name_jpn){
      const shiftMap = new Map([
        ['早番', 'early'],
        ['遅番', 'late'],
        ['日勤', 'day'],
        ['夜勤', 'night'],
        ['明番', 'dawn'],
      ]);
      return shiftMap.get(category_name_jpn)
    },
    //同一ワークカテゴリIDと勤務グループに設定したIDを比較して、同一のカテゴリが設定してある勤務グループのassignmentを返す
    getIdenticalWorkGroupCategory(work_category_identical_groups, one_team_office_wp_list_assignment){
      let group_assignment_identical_category = {}

      if (work_category_identical_groups && one_team_office_wp_list_assignment) {
        for (let category in work_category_identical_groups) {
            let identical_group_ids = Array.from(work_category_identical_groups[category])
            
            for (let i = 0; i < one_team_office_wp_list_assignment.length; i++) {
              let team_workpattern_ids = Array.from(one_team_office_wp_list_assignment[i].workpattern_id_list)
              
              //同一勤務カテゴリworkpatternID配列の中に勤務グループで設定したworkpatternのIDが全て含まれている→同一カテゴリが設定されている
              if (this.isArrayInclusion(identical_group_ids, team_workpattern_ids)) {
                group_assignment_identical_category[category] = one_team_office_wp_list_assignment[i].staff_assignment
              }
            }
        }
      }
      return group_assignment_identical_category
    },
    //arr1の中にarr2の中の要素がすべて含まれているかチェック
    //arr1 [1,2,3,4] arr2 [1,2,3]→true
    //arr1 [1,2,3,4] arr2 [1,2,5]→false
    isArrayInclusion(arr1, arr2) {
      let set1 = new Set(arr1);
      let set2 = new Set(arr2);
      for (let elem of set2) {
          if (!set1.has(elem)) {
              return false;
          }
      }
      return true;
    }
  },
  computed: {
  },
  watch: {
    shift_data: {
      handler() {
        this.calc();
        this.group_assignment_identical_category = this.getIdenticalWorkGroupCategory(this.work_category_identical_groups, this.one_team_office_wp_list_assignment)
      },
      deep: true,
    },
    basic_infos: {
      handler() {
        this.calc();
        this.group_assignment_identical_category = this.getIdenticalWorkGroupCategory(this.work_category_identical_groups, this.one_team_office_wp_list_assignment)
      },
      deep: true,
    },
  },
  mounted() {
    this.calc();
  },
}
</script>
