<template>
  <b-button v-if="is_available && button_type == 'white'" class="btn_white" style="margin-left: 10px;" @click="download" :class="[ is_free_plan || !is_prepared ? 'disable-style' : '' ]">
    <font-awesome-icon icon="download" style="color: #0BB0BE; vertical-align: text-top; padding-right: 5px;" />{{ label }}
  </b-button>
  <b-button v-else-if="is_available" size="sm" @click="download" :class="[ is_free_plan || !is_prepared ? 'disable-style' : '' ]">{{ label }}</b-button>
</template>

<script>
import axios from "axios"
export default {
  name: 'BtnDownload',
  props: {
    button_type: {
      type: String,
      default: 'white'
    },
    label: {
      type: String
    },
    // 勤務形態一覧表の出力対象
    is_available: {
      type: Boolean,
      default: true
    },
    is_free_plan: {
      type: Boolean,
      default: false
    },
    // 勤務形態一覧表ダウンロード可
    is_prepared: {
      type: Boolean,
      default: false
    },
    office_id: {
      type: Number,
      default: null
    },
    team_id: {
      type: Number,
      default: null
    },
    year: {
      type: String,
      default: null
    },
    month: {
      type: String,
      default: null
    },
    type: {
      type: Number,
      default: 1
    },
    display_rule: {
      type: String,
      default: null
    },
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    download: function () {
      event.preventDefault()

      if (this.is_free_plan) {
        const url = this.$route('lodgments.plan-edit');
        const message = `機能制限無料プランでのご利用のため、ダウンロードができません。ダウンロードする場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        })
        return;
      }

      if (!this.is_prepared) {
        // TODO 常勤換算 一覧画面では文言を変える
        const error_message = this.type === 1
          ? '勤務形態一覧表の出力データ（予定）が存在しません。「シフトを職員へ展開」ボタンをクリックして職員へシフトを展開してください。'
          : '勤務形態一覧表の出力データ（実績）が存在しません。「勤怠実績データインポート」ボタンをクリックして勤怠実績データをインポートしてください。'
        this.$swal.fire({
          icon: 'warning',
          text: error_message
        })
        return;
      }

      const confirm_message = this.type === 1
        ? '勤務形態一覧表（予定）をダウンロードしますか？'
        : '勤務形態一覧表（実績）をダウンロードしますか？'
      this.$swal
        .fire({
          icon: 'warning',
          text: confirm_message,
          showConfirmButton: true,
          confirmButtonText: 'はい',
          showCancelButton: true,
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            this.updateLoading(true);
            let download_url = this.$route('fulltime-conversions.download', [ this.office_id, this.year, this.month, this.type ])
            let download_url_query = []
            if (this.display_rule !== null) {
              download_url_query.push('display_rule=' + encodeURIComponent(this.display_rule))
            }
            if (this.team_id !== null && this.team_id !== 0) {
              download_url_query.push('team_id=' + encodeURIComponent(this.team_id))
            }
            if (download_url_query.length > 0) {
              download_url = download_url + '?' + download_url_query.join('&')
            }
            axios
              .get(download_url, { responseType: 'blob' })
              .then(res => {
                let elem = document.createElement('a')
                elem.href = window.URL.createObjectURL(new Blob([res.data]))
                let file_name = decodeURI(res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1'))
                elem.setAttribute('download', file_name)
                document.body.appendChild(elem)
                elem.click()
                document.body.removeChild(elem)
              })
              .catch(error => {
                this.$swal.fire({
                  icon: 'error',
                  text: 'ダウンロードに失敗しました。'
                })
                return
              })
            this.updateLoading(false);
          }
        })
    },
  }
}
</script>
