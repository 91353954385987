<template>
  <b-overlay rounded="sm">
    <div style="font-size: 14px;">
      <h4 style="margin-bottom: 24px;">チーム管理　一覧</h4>
      <b-row style="margin: 0px 0px 32px 0px;">
        <b-col cols="6" style="padding: 0px;">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="キーワード検索"
            outlined
            hide-details
            dense
            style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 8px;"
          ></v-text-field>
        </b-col>
        <b-col cols="6" style="display:flex; justify-content: flex-end;">
          <a href="add">
            <b-button class="btn_white" style="height: 40px!important;" @click="lodingScreen">
              <font-awesome-icon icon="plus" class="icon-style" />チームを追加
            </b-button>
          </a>
          <div class="import-btn">
            <b-button class="btn_white" @click="csvimport()" style="height: 40px!important;">
                <font-awesome-icon icon="upload" class="icon-style" />CSVインポート
            </b-button>
          </div>
        </b-col>
      </b-row>
      <v-data-table
        :headers = "headers"
        :items = "items"
        :items-per-page = "teams_list"
        :footer-props = "footerProps"
        :search="search"
        class="elevation-1"
        fixed-header
        height="calc(100vh - 310px)"
        no-data-text="チームが存在しません"
        no-results-text="データが存在しません"
        @update:items-per-page="savePerPage"
      >
        <template v-slot:item.actions="{ item }">
          <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i>変更</b-button>
          <b-button class="btn_actions" @click="deleteItem(item)" style="padding-right: 0px;"><i class="pe-7s-trash"></i>削除</b-button>
        </template>
        <template v-slot:footer.page-text="props">
          {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
          {{ props.pageStop }} 件までを表示
        </template>
      </v-data-table>
    </div>
  </b-overlay>
</template>

<style scoped>
.btn_white {
  height: 37px!important;
  margin-left: 20px;
}
</style>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  components :{
  },
  props : {
    teams :{
      type:Object,
      required :true
    }
  },
  computed : {
    items () {
      return this.teams.data
    }
  },
  data () {
    // console.log('【チームデータ】', this.teams.data)
    return {
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '事業所名', value: 'office_name' },
        { text: 'チーム名', value: 'name' },
        { text: '職員数', value: 'num_of_staff' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      teams_list: Number(this.$cookie.get('teams_list')),
      csv_form: this.$inertia.form({
        csv: null,
      }),
    }
  },
  methods: {
    lodingScreen() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    savePerPage(val) {
      this.$cookie.set('teams_list', val, 1);
    },
    editItem (item) {
      this.updateLoading(true);
      console.log(Ziggy.routes)
      this.$inertia.get(route('teams.edit', item.id))
    },
    deleteItem (item) {
      this.$swal({
        title: "本当に削除しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.$inertia.delete(route('teams.destroy', item.id),
          {
            preserveScroll: true,
            onSuccess:() => {
              this.updateLoading(false);
              this.$swal(
                'チームを削除しました',
                '',
                'success')
            }
          })
          this.updateLoading(false);
        }
      })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    /**
     * CSVアップロード機能
     */
     csvimport(){
      this.$swal({
          text: "チーム（CSV）をインポートします。",
          icon: 'warning',
          showCancelButton: true,
          input: 'file',
          inputAttributes: {
              name: "csvupload",
              id: "csvupload",
              accept: "text/csv",
              multiple: "multiple"
          },
          confirmButtonText: 'アップロード',
          cancelButtonText: 'キャンセル',
          customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
      }).then(result => {
          if (result.value) {
            this.updateLoading(true);
            this.csv_form.transform((data) => ({
              ...data,
              csv: result.value[0],
            })).post(this.$url('/teams/csvupload'), {
              preserveScroll: true,
              onError: (p) => {
                this.updateLoading(false);
                let errorMessages = '';
                let parsed_message = {};
                console.log(p);
                // JSON文字列でなかった場合を考慮して例外処理
                try {
                  parsed_message = JSON.parse(p.csv_upload);
                } catch (e) {
                  parsed_message = {};
                  if ('csv_upload' in p) {
                    errorMessages = p.csv_upload;
                  } else {
                    errorMessages = '';
                  }
                }
                // CsvUploadRequestのバリデーションエラーメッセージ対応
                try {
                  parsed_message += JSON.parse(p.csv);
                } catch (e) {
                  if ('csv' in p) {
                    errorMessages += p.csv;
                  }
                }
                Object.keys(parsed_message).forEach(index => {
                  let errorMessage = [];
                  const message = parsed_message[index].message;
                  try {
                    Object.keys(JSON.parse(message)).forEach(field => {
                      JSON.parse(message)[field].forEach(message => {
                        errorMessage.push(message)
                      })
                    })
                    errorMessages = errorMessages + (Number(index) + 1) + '行目エラー：' + errorMessage.join(' ') + '<BR>'
                  } catch(e) {
                    console.debug('【CSV_UPLOAD_ERROR】', message);
                    // エラー内容がDBエラー等の例外の場合
                    errorMessages = errorMessages + (Number(index) + 1) + '行目エラー：データ不正です。<BR>'
                  }
                })
                this.$swal({
                  title :'error',
                  html :'CSV登録に失敗しました。<BR>再度やり直してください。'
                    + '<BR><BR>'
                    + '【エラー詳細】'
                    + '<BR>'
                    + '--------------------------------------'
                    + '<div style="text-align:left;padding-right:15%;padding-left:15%;font-size: 0.8em;">'
                    + errorMessages
                    + '</div>',
                  icon : 'error'
                })
              },
              onSuccess: () => {
                this.updateLoading(false);
                this.$swal(
                  'success',
                  'CSV登録が完了しました。',
                  'success');
              },
              onFinish: () => {
                this.updateLoading(false);
                this.show = false;
                this.csv_form.csv = null;
              }
            })
          } else {
            this.updateLoading(false);
            this.show = false;
          }
      })
    },
  },
  mounted() {
	  this.updateLoading(false);
  },
};
</script>
