<template>
  <b-tr>
    <b-td style="border-top: 1px solid #DEE2E6 !important; max-height: 55px; padding: 5px;">
      {{ workpattern.name }}<br />
      {{ functions.getHHmm(workpattern.working_start_time) }} - {{ functions.getHHmm(workpattern.working_end_time) }}
    </b-td>
    <b-td v-for="(day_of_week, key, index) in day_of_weeks" :key="index" style="vertical-align: middle;">
      <div v-if="key === 'def'" style="height: 100%;">
        <div style="display: flex; white-space: nowrap; width: 50%; height: 100%; float: left; justify-content: center; border-right: solid 1px #DEE2E6;">
          <input
            type="number"
            class="form-control px-0 text-center"
            :value="GetData(key, 'min')"
            @change="updateNum($event, key, 'min')"
            min="0"
            max="31"
            style="min-width: 38px; max-width: 38px; padding-right: 5px; margin: auto 0;"
            :style="(('wp_' + workpattern.id) in border_color_style) ? border_color_style['wp_' + workpattern.id] : ''"
          />
          <span style="margin: auto 0;">人<br />以上</span>
        </div>
        <div style="display: flex; white-space: nowrap; width: 50%; height:100%; justify-content: center;">
          <input
            type="number"
            class="form-control px-0 text-center"
            :value="GetData(key, 'max')"
            @change="updateNum($event, key, 'max')"
            min="0"
            max="31"
            style="min-width: 38px; max-width: 38px; padding-right: 5px; margin: auto 0;"
            :style="(('wp_' + workpattern.id) in border_color_style) ? border_color_style['wp_' + workpattern.id] : ''"
          />
          <span style="margin: auto 0;">人<br />以下</span>
        </div>
      </div>
      <div v-else style="display:flex; justify-content: center;">
        <div>
          <input
            type="number"
            class="form-control px-0 text-center"
            :value="GetData(key, 'min')"
            @change="updateNum($event, key, 'min')"
            min="0"
            max="31"
            style="min-width: 38px; max-width: 38px; margin: 0px 5px;"
            :style="(('wp_' + workpattern.id) in border_color_style) ? border_color_style['wp_' + workpattern.id] : ''"
          />
        </div>
        <div>
          <input
            type="number"
            class="form-control px-0 text-center"
            :value="GetData(key, 'max')"
            @change="updateNum($event, key, 'max')"
            min="0"
            max="31"
            style="min-width: 38px; max-width: 38px; padding-right: 5px;"
            :style="(('wp_' + workpattern.id) in border_color_style) ? border_color_style['wp_' + workpattern.id] : ''"
          />
        </div>
      </div>
    </b-td>
  </b-tr>
</template>

<script>
import functions from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/functions';
export default {
  name: 'WorkpatternRow',
  components: {
  },
  props: {
    workpattern: {
      type: Object,
    },
    assignment: {
      type: Object,
    },
    border_color_style: {
      type: Object,
    }
  },
  data () {
    return {
      functions: functions,

      day_of_weeks : {
        def: '一括指定',
        mon: '月',
        tue: '火',
        wed: '水',
        thu: '木',
        fri: '金',
        sat: '土',
        sun: '日',
        hol: '祝'
      },
    }
  },
  methods: {
    // 値取得(min,max)
    GetData(key, name) {
      let result = 0;
      if (this.assignment && this.assignment[key]) {
        if (name === 'min') {
          result = this.assignment[key].min;
        } else if (name === 'max') {
          result = this.assignment[key].max;
        }
      }
      return result;
    },
    // 数値の更新
    updateNum(e, key, type) {
      this.assignment[key][type] = e.target.value;
      this.$emit('updateWpAssignment', this.workpattern.id, key, type, e.target.value)
      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
  },
  watch: {
  },
  computed: {
  },
  mounted() {
  },
}
</script>
