<template>
    <!-- 勤務カテゴリごとの設定、実績集計値 -->
    <div>
        <table class="summary-table" border="1">
        <thead>
            <tr>
                <th rowspan="2">設定値</th>
                <th v-for="category in work_category_summary.settings" colspan="2">{{category.category_name_jpn}}</th>
            </tr>
            <tr>
                <template v-for="iteration in work_category_summary.settings">
                <th v-for="label in ['下限', '上限']" :key="`${iteration.category_name_jpn}-${label}`">{{ label }}</th>
                </template>
            </tr>
        </thead>
            <tbody>
            <tr>
                <td>常時配置人数</td>
                <template v-for="iteration in work_category_summary.settings">
                <td>{{ iteration.min }}</td>
                <td>{{ iteration.max }}</td>
                </template>
            </tr>
            <tr>
                <td>勤務回数</td>
                <template v-for="iteration in work_category_summary.archievement">
                <td>{{ iteration.min }}</td>
                <td>{{ iteration.max }}</td>
                </template>
            </tr>
            </tbody>
        </table>

        <!-- <div @click="calculateWorkCategoryArchivement()">勤務回数</div>
        <div @click="calculateWorkCategorySettings()">勤務カテゴリ</div>
        <div @click="calculateWorkCategoryGroupSettings()">勤務カテゴリグループ</div> -->
    </div>
</template>

<style scope>
.summary-table {
    table-layout: auto;
    text-align: center;
    min-width: 800px;
    max-width: 100%;
    border-collapse: collapse;
}

.summary-table th {
    font-size: 14px;
    font-weight: 500;
    border : solid 1px rgba(210, 214, 219, 0.5)!important;
    background-color: #F3F4F6;
}

.summary-table td {
    border : solid 1px rgba(210, 214, 219, 0.5)!important;
    text-align: center;
    vertical-align: middle;
}
</style>

<script>
export default {
  props: {
    //1チームのカテゴリごとのworkpattern配列
    one_team_workpatterns_by_category: {
      type: Object|Array,
      default: function() {
        return {};
      }
    },
    //常時配置人数の値(staff_assignment)
    assignment: {
      type: Object,
      default: function() {
        return {};
      }
    },
    //スタッフのワークパターン(実績値) [office_staff.id][workpattern.id]
    settings_office_staff_workpatterns: {
      type: Object|Array,
      default: function() {
        return {};
      }
    },
    //office_staffリスト
    settings_office_staffs: {
      type: Object|Array,
      default: function() {
        return {};
      }
    },
    //勤務グループ設定
    office_wp_list_assignment: {
      type: Object|Array,
      default: function() {
        return {};
      }
    },
    //勤務グループのラベル(遅A、遅B) keyはworkpattern_idを-でつないだもの 1-2-4
    work_category_group_label: {
      type: Object|Array,
      default: function() {
        return {};
      }
    },
    //1月の曜日ごとの日数
    week_cnt: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      work_category_summary:{
        settings:{},
        archievement: {}
      }, //再計算された常時配置人数と勤務回数
      wp_list_workpattern_id_list:{}, //勤務グループのチームのものだけ keyがwp_list_assignmentと連動
      wp_list_assignment:{}, //勤務グループ設定のチームのものだけ keyがwp_listworkpattern_id_listと連動
    };
  },
  mounted(){    
    this.calculateWorkCategoryArchivement()
    this.calculateWorkCategorySettings()
    this.calculateWorkCategoryGroupSettings()
  },
  created(){
    this.initialWpListAssignment()
    this.initialSettingsArchivement()
  },
  methods: {
    //常時配置人数を再計算する
    calculateWorkCategorySettings(){
      
      if(!this.one_team_workpatterns_by_category || !this.assignment){
        return
      }

      Object.entries(this.one_team_workpatterns_by_category).forEach(([category, category_workpattern_ids]) => {
        if(category === ""){
          return
        }
        //カテゴリごとの集計値
        let category_max_count = 0, category_min_count = 0

        Object.values(category_workpattern_ids).forEach(workpattern_id => {
          let calc_assignment = this.calcAssignment(this.assignment[workpattern_id], this.week_cnt)
          category_max_count += parseInt(calc_assignment.max)
          category_min_count += parseInt(calc_assignment.min)
        });

        //計算結果をセット
        this.work_category_summary.settings[category].max = category_max_count
        this.work_category_summary.settings[category].min = category_min_count
        
      });
    },
    //勤務グループの常時配置人数を再計算する
    calculateWorkCategoryGroupSettings(){
      if(!this.wp_list_assignment){
        return
      }

      Object.entries(this.wp_list_assignment).forEach(([key, assignment]) => {
        if(!this.wp_list_workpattern_id_list[key]){
          return
        }

        let workpattern_string = this.wp_list_workpattern_id_list[key].join("-")

        let calc_assignment = this.calcAssignment(assignment, this.week_cnt)
        if(this.work_category_summary.settings[workpattern_string]){
          this.work_category_summary.settings[workpattern_string].max = calc_assignment.max
          this.work_category_summary.settings[workpattern_string].min = calc_assignment.min
        }
      });
    },
    //勤務回数の集計
    calculateWorkCategoryArchivement(){
      if(!this.settings_office_staffs && !this.settings_office_staff_workpatterns){
        return
      }

      let count = {
        early:{min:0, max:0},
        late:{min:0, max:0},
        night:{min:0, max:0},
        day:{min:0, max:0},
        dawn:{min:0, max:0}
      }
      let count_workpattern = {}

      Object.entries(this.settings_office_staffs).forEach(([office_staff_id, dummy]) => {
        Object.entries(this.settings_office_staff_workpatterns[office_staff_id]).forEach(([workpattern_id, minmax_value]) => {
          if (!count_workpattern[workpattern_id]) {
            count_workpattern[workpattern_id] = { min: 0, max: 0 };
          }

          if(count[minmax_value.category] === undefined){
            return
          }

          count[minmax_value.category].max += parseInt(minmax_value.max_monthly_working_count)
          count[minmax_value.category].min += parseInt(minmax_value.min_monthly_working_count)
          count_workpattern[workpattern_id].max += parseInt(minmax_value.max_monthly_working_count)
          count_workpattern[workpattern_id].min += parseInt(minmax_value.min_monthly_working_count)
          
        });
      });

      //各勤務カテゴリの集計
      Object.entries(count).forEach(([category, minmax_value]) => {
          this.work_category_summary.archievement[category].max = minmax_value.max
          this.work_category_summary.archievement[category].min = minmax_value.min
      });

      //グループの集計
      Object.entries(this.wp_list_workpattern_id_list).forEach(([key, workpattern_ids]) => {
        let workpattern_string = this.wp_list_workpattern_id_list[key].join("-")
        let count_min = 0, count_max = 0
        Object.entries(workpattern_ids).forEach(([key, workpattern_id]) => {
          count_max += count_workpattern[workpattern_id]?.max
          count_min += count_workpattern[workpattern_id]?.min
        });
        this.work_category_summary.archievement[workpattern_string].max = count_max
        this.work_category_summary.archievement[workpattern_string].min = count_min
      });

      
    },
    /**
     * 1か月の常時配置人数の計算
     * assignment 曜日ごとの設定値 {def:{max:2, min:2},mon:{max:2, min:2}}...
     * week_cnt 1月の曜日ごとの日数 {mon:4,tue:4}...
     */
    calcAssignment(assignment, week_cnt){
      if(assignment === undefined){
        return {min:0, max:0}
      }

      const def_max = assignment.def.max
      const def_min = assignment.def.min
      let max_count = 0, min_count = 0

      Object.entries(assignment).forEach(([day, min_max]) => {
        if(day !== 'def'){
          if(parseInt(assignment[day].max) === 0 && parseInt(assignment[day].min) === 0){
            max_count += week_cnt[day] * def_max
            min_count += week_cnt[day] * def_min
          }else{
            max_count += week_cnt[day] * min_max.max
            min_count += week_cnt[day] * min_max.min
          }
        }
      });

      return {min:min_count, max:max_count}
    },
    initialWpListAssignment(){
      // wp_listを初期化する team_idのリストが存在しなければ空
      let initial = this.office_wp_list_assignment;

      if (initial !== null) {
        if (this.team_id in initial) {
          for (let key in initial[this.team_id]) {
            let group = initial[this.team_id][key];
            if (group.workpattern_id_list !== null && group.workpattern_id_list.length > 1) {
              this.$set(this.wp_list_workpattern_id_list, key, group.workpattern_id_list);
              this.$set(this.wp_list_assignment, key, group.staff_assignment);
            }
          }
        }
      }
    },
    //work_category_summary.settings work_category_summary.archivementを初期化する
    initialSettingsArchivement(){
      let settings_archivement ={
          early: {min: 0, max: 0, category_name_jpn: '早番'},
          day: {min: 0, max: 0, category_name_jpn: '日勤'},
          late: {min: 0, max: 0, category_name_jpn: '遅番'},
          night: {min: 0, max: 0, category_name_jpn: '夜勤'},
          dawn: {min: 0, max: 0, category_name_jpn: '明番'},
      }

      //勤務グループの初期設定
      Object.entries(this.wp_list_assignment).forEach(([key, assignment]) => {
        if(!this.wp_list_workpattern_id_list[key]){
          return
        }

        let workpattern_string = this.wp_list_workpattern_id_list[key].join("-")
        // 新しい勤務パターングループを追加
        settings_archivement[workpattern_string] = {
            min: 0,
            max: 0,
            category_name_jpn: this.work_category_group_label[workpattern_string]?.category_name_jpn
        };
      })

      this.work_category_summary.settings = JSON.parse(JSON.stringify(settings_archivement))
      this.work_category_summary.archievement = JSON.parse(JSON.stringify(settings_archivement))
    }
  },
  watch: {
    assignment: {
      handler: 'calculateWorkCategorySettings',
      deep: true
    },
    wp_list_assignment: {
      handler: 'calculateWorkCategoryGroupSettings',
      deep: true
    },
    settings_office_staff_workpatterns: {
      handler(new_data){
        this.calculateWorkCategoryArchivement()
      },
      deep: true
    },
    
  }
}
</script>
