<template>
  <div>
    <div v-for="(workpatternKintai, index) in workpatternKintaisData" :key="index" class="workpattern-apiinfo-area">
      <div class="btn_left_area">
        <b-button v-if="index > 0" class="btn_add" @click="removeWorkpatternKintai(index)"><i class="pe-7s-trash"></i>外部連携の削除</b-button>
      </div>
      <div class="input_row">
        <label for="external_point_name">外部連携先名</label>
        <div>
          <v-autocomplete
            v-model="workpatternKintai.external_kintai_id"
            :items="external_kintai_list"
            class="workpattern-apiinfo-area-autocomplete apiinfo-control"
            :class="{ 'is-invalid': errors.external_kintai_id }"
            item-value="id"
            item-text="name"
            hide-details="true"
            no-data-text="外部連携先が見つかりません。"
            dense
            filled
            @input="deleteExternalKintaiIdErrors()"
          ></v-autocomplete>
          <div v-if="errors['workpattern_kintais.' + index + '.external_kintai_id']" class="invalid-feedback">{{ errors['workpattern_kintais.' + index + '.external_kintai_id'] }}</div>
        </div>
      </div>
      <div class="input_row">
        <label for="external_link_id">外部連携先勤務区分ID</label>
        <div>
          <input
            v-model="workpatternKintai.external_link_id"
            name="external_link_id"
            type="text"
            class="form-control apiinfo-control"
            :class="{'is-invalid': errors.external_link_id}"
            placeholder=""
            @input="checkExternalLinkId(workpatternKintai.external_link_id, index)"
          />
          <div v-if="errors['workpattern_kintais.' + index + '.external_link_id']" class="invalid-feedback">{{ errors['workpattern_kintais.' + index + '.external_link_id'] }}</div>
        </div>
      </div>
    </div>
    <b-button class="btn_add" style="margin-bottom: 16px;" @click="addWorkpatternKintai"><span>＋</span>外部連携先を追加</b-button>
  </div>
</template>

<style scoped>
.btn_add {
  color: #384250!important;
  width: auto!important;
}
.apiinfo-control {
  width: 560px!important;
}
.invalid-feedback {
  display: block!important;
}
</style>

<script>
export default {
  name: "Apiinfos",
  components: {},
  props: {
    workpatternKintais: {
      type : Array,
      required :false
    },
    external_kintai_list: {
      type : Array,
      required :true
    },
    errors: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      workpatternKintaisData: this.workpatternKintais,
    };
  },
  watch : {
    workpatternKintaisData :  {
      handler () {
        const hasErrors = Object.keys(this.errors).some(key => key.startsWith('workpattern_kintais.'))
        this.$emit('workpatternKintaisChanged', this.workpatternKintaisData, hasErrors)
      },
      deep :true
    },
  },
  methods: {
     /**
     * 外部連携先事業所ID 入力チェック
     */
    checkExternalLinkId(externalLinkId, index) {
      // 100文字を超える場合はエラー
      const MAX_LENGTH = 100;

      if ([...externalLinkId].length > MAX_LENGTH) {
        this.$set(this.errors, 'workpattern_kintais.' + index + '.external_link_id', '外部連携先事業所IDは100文字以内で入力してください。');
      } else {
        this.$delete(this.errors, 'workpattern_kintais.' + index + '.external_link_id');
      }
    },
    /**
     * 連携先削除ボタン
     */
    removeWorkpatternKintai(index) {
      // エラーをクリア
      this.$delete(this.errors, 'workpattern_kintais.' + index + '.external_kintai_id');
      this.$delete(this.errors, 'workpattern_kintais.' + index + '.external_link_id');
      // データ削除
      this.workpatternKintaisData.splice(index, 1);
    },
    /**
     * 連携先追加ボタン
     */
     addWorkpatternKintai () {
      this.workpatternKintaisData.push(
        {
          external_kintai_id: '',
          external_link_id: ''
        }
      );
    },
    /**
     * 外部連携先サービス名エラー削除
     */
     deleteExternalKintaiIdErrors() {
      Object.keys(this.errors).filter(key => {
        return key.startsWith('workpattern_kintais.') && key.endsWith('.external_kintai_id');
      }).forEach(key => {
        this.$delete(this.errors, key);
      });
    },
  },
}
</script>
<style>
.v-input__slot {
  background: initial!important;
}
</style>
