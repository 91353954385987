<template>
  <div>
    <div v-for="(staff_qualification, index) in staff_qualifications" :key="index" class="qualification_area">
      <div class="input_row">
        <p style="font-weight: 700; margin: 0px;">資格</p>
        <div class="btn_left_area">
          <button class="btn_add" @click="removeClickLicence(index)"><i class="pe-7s-trash"></i>資格の削除</button>
        </div>
      </div>
      <div class="input_row">
        <label for="work_length">資格</label>
        <div>
          <v-autocomplete
            v-model="staff_qualification.id"
            :items="qualification_list"
            @change="handleSelectChangedQualification()"
            class="autocomplete-style"
            style="width: 330px; min-width: 330px; "
            item-text="text"
            item-value="value"
            hide-details="true"
            placeholder="選択してください"
            no-data-text="資格が見つかりません。"
            dense
            filled
          >
          </v-autocomplete>
          <!-- error message -->
          <div v-if="form_errors['qualifications'] && form_errors['qualifications'].length">
            <div v-if="!staff_qualification.id" class="invalid-feedback">選択してください</div>
            <div v-else class="invalid-feedback"></div>
          </div>
        </div>
      </div>

      <div class="input_row">
        <label for="work_length">資格取得日</label>
        <div>
          <div class="input_row">
            <b-form-select
              :id="'option_year_' + index"
              class="option_year"
              style="width: 96px; min-width: 96px; "
              v-model="option_year[index]"
              @change="setOptionYear(index)"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="year in option_year_list"
                :key="year"
                style="color: #000000;"
              >
                {{ year }}
              </option>
            </b-form-select>
            <p class="after_label">年</p>

            <b-form-select
              style="width: 76px; min-width: 76px;"
              v-model="option_month[index]"
              @change="setOptionMonth(index)"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="month in option_month_list[index]"
                :key="month"
              >
                {{ month }}
              </option>
            </b-form-select>
            <p class="after_label">月</p>
            <b-form-select
              style="width: 76px; min-width: 76px;"
              v-model="option_date[index]"
              @change="setOptionDate(index)"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="date in option_date_list[index]"
                :key="date"
              >
                {{ date }}
              </option>
            </b-form-select>
            <p class="after_label">日</p>
          </div>
          <!-- error message -->
          <div v-if="errors['qualifications.' + index + '.obtained_date']">
            <div class="invalid-feedback">{{ errors['qualifications.' + index + '.obtained_date'] }}</div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="!duplicateQualificationCheck" class="invalid-feedback">同じ資格が選択されています</p>
    <button class="btn_add" style="margin-bottom: 16px;" @click="addClickLicence"><span>＋</span>資格を追加</button>
  </div>
</template>

<style>
.autocomplete-style .v-input__control {
  border: 1px solid #ced4da!important;
  border-radius: 8px!important;
}
.v-input__slot {
    background: #ffffff!important;
}
</style>

<script>
import { sample } from 'lodash';

export default {
  name: "Qualification",
  components: {},
  props: {
    staff: {
      type : Object,
      required :false
    },
    qualifications :{
      type : Object,
      required :true
    },
    form_errors: {
      type: Object,
      required: false,
    },
    errors: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      // スタッフごとの資格データ
      staff_qualifications: (this.staff && this.staff.data.qualifications.length > 0)
        ? this.staff.data.qualifications
        : [{ obtained_date:null, id:'' }],
      // 資格リスト
      qualification_list : this.qualifications.data.map(qualification => {
        return {
          text: qualification.name,
          value: qualification.id
        }
      }),
      option_year : [],  // 資格取得年
      option_month : [], // 資格取得月
      option_date : [],  // 資格取得日
      open_date_count: 0, // 入社日・退職日のホバー制御
      open_date_flg: false, // 入社日・退職日のホバー制御
      duplicateQualificationCheck:true, // 重複チェック用
    };
  },
  methods: {
    /**
     * 資格のプルダウン選択時
     */
    handleSelectChangedQualification () {
      const staff_qualification_ids = this.staff_qualifications.map(staff_qualification => staff_qualification.id)

      // 重複選択した資格idの比較
      for(const staff_qualification_id of staff_qualification_ids) {
        // 未選択の重複を除く
        if(!staff_qualification_id) return

        const firstIndex = staff_qualification_ids.indexOf(staff_qualification_id);
        const lastIndex = staff_qualification_ids.lastIndexOf(staff_qualification_id);

        if(firstIndex !== lastIndex){
          this.duplicateQualificationCheck = false
          break
        }
        this.duplicateQualificationCheck = true
      }

      this.$emit("qualifications", this.staff_qualifications, this.checkValidation)
    },
    /**
     * 資格追加ボタン
     */
     addClickLicence () {
      this.staff_qualifications.push(
        {
          obtained_date:null,
          id:''
        }
      );

      this.$nextTick(function() {
        let arrayIndex = this.staff_qualifications.length - 1;
        let option_year_select = document.getElementById('option_year_' + arrayIndex);
        option_year_select.addEventListener("focus", this.focusYear);
        option_year_select.addEventListener("click", this.clickYear);
        option_year_select.addEventListener("focusout", this.focusoutYear);
      });

    },
    /**
     * 資格削除ボタン
     */
     removeClickLicence (index) {
      // 保存用の配列から削除
      this.staff_qualifications.splice(index, 1);

      // 各資格の年・月・日の配列から削除
      this.option_year.splice(index, 1);
      this.option_month.splice(index, 1);
      this.option_date.splice(index, 1);

      this.updateErrorsAndIndexes(index);
    },
    updateErrorsAndIndexes(index) {
      // エラーメッセージの削除
      this.$delete(this.errors, 'qualifications.' + index + '.obtained_date');

      // エラーメッセージのインデックスを振り直し
      let errorMessages = {};
      Object.keys(this.errors).forEach(key => {
        const regex = /^qualifications\.(\d{1,})\.obtained_date$/i;
        const found = key.match(regex);
        if (found) {
          errorMessages[found[1]] = this.errors[key];
          this.$delete(this.errors, key);
        }
      })
      for (const [key, errorMessage] of Object.entries(errorMessages)) {
        let i = (key > index) ? key - 1 : key;
        this.$set(this.errors, 'qualifications.' + i + '.obtained_date', errorMessage);
      }
    },
    /**
     * 年の未選択時はシステム年を選択 ホバー制御
     */
     setYearDefult(elem) {
      let setYear = new Date().getFullYear();
      for (let i=0; elem.length>i; i++) {
        if(elem.options[i].value == setYear) {
          elem.options[i].selected = true;
          elem.style.color = '#FFFFFF';
          this.open_date_flg = true;
          break;
        }
      }
    },
    /**
     * 年のフォーカス取得時 ホバー制御
     */
     focusYear(e) {
      if (e.target.selectedIndex < 0) {
        this.setYearDefult(e.target);
      }
    },
    /**
     * 年のクリック時（入社日・退職日共通） ホバー制御
     */
     clickYear(e) {
      // 奇数:open／偶数:close
      this.open_date_count++;
      if (this.open_date_count % 2 == 0) {
        if (e.target.selectedIndex < 0) {
          this.setYearDefult(e.target);
        }
      } else {
        if (this.open_date_flg) {
          e.target.selectedIndex = -1;
        }
      }
    },
    /**
     * 年のプルダウン選択時
     */
     setOptionYear (index) {
      const obtained_date = new Date(this.staff_qualifications[index].obtained_date)
      this.staff_qualifications[index].obtained_date = this.option_year[index]
        + '-'
        + (obtained_date.getMonth() + 1)
        + '-'
        + obtained_date.getDate()

      // ホバー制御
      let date_select = document.getElementById('option_year_' + index);
      date_select.style.color = "#000000";
      this.open_date_flg = false;
    },
    /**
     * 年のフォーカスアウト時（入社日・退職日共通） ホバー制御
     */
    focusoutYear(e) {
      this.open_date_count = 0;
      e.target.style.color = '#000000';
      if (this.open_date_flg) {
        e.target.selectedIndex = -1;
        this.open_date_flg = false;
      }
    },
    /**
     * 月のプルダウン選択時
     */
     setOptionMonth (index) {
      const obtained_date = new Date(this.staff_qualifications[index].obtained_date)
      this.staff_qualifications[index].obtained_date = obtained_date.getFullYear()
        + '-'
        + ("00" + this.option_month[index]).slice( -2 )
        + '-'
        + obtained_date.getDate()
    },
    /**
     * 日のプルダウン選択時
     */
     setOptionDate (index) {
      const obtained_date = new Date(this.staff_qualifications[index].obtained_date)
      this.staff_qualifications[index].obtained_date = obtained_date.getFullYear()
        + '-'
        + (obtained_date.getMonth() + 1)
        + '-'
        + ("00" + this.option_date[index]).slice( -2 );
    },
  },
  computed: {
    // 残りの資格リスト
    remainingQualifications () {
      // 資格リスト
      return this.qualifications.data.map(qualification => {
        // 残りの資格
        let remain = null
        for(const staff_qualification of this.staff_qualifications) {
          if(staff_qualification.id === qualification.id) {
            remain = null;
            break;
          }
          remain = qualification
        }
        return remain
      }).filter(Boolean) // nullになってるものを削除
    },
    // 年リスト
    option_year_list () {
       return [...Array(100)].map((_, i) => (new Date().getFullYear() - 99) + i)
    },
    // 月リスト
    option_month_list () {
      return this.staff_qualifications.map(staff_qualification => {
        if (new Date(staff_qualification.obtained_date).getFullYear() === new Date().getFullYear()) {
          return [...Array(new Date().getMonth() + 1)].map((_, i) => i + 1)
        } else {
          return [...Array(12)].map((_, i) => i + 1)
        }
      })
    },
    // 日リスト
    option_date_list () {
      return this.staff_qualifications.map(staff_qualification => {
        const obtained_date = new Date(staff_qualification.obtained_date)
        if (obtained_date.getFullYear() === new Date().getFullYear()
          && obtained_date.getMonth() === new Date().getMonth()) {
          return [...Array(new Date().getDate() - 1)].map((_, i) => i + 1)
        } else {
          return [...Array(new Date(obtained_date.getFullYear(), obtained_date.getMonth() + 1, 0).getDate())].map((_, i) => i + 1)
        }
      })
    },
    checkValidation () {
      return this.duplicateQualificationCheck
    }
  },
  watch : {
    staff_qualifications :  {
      handler (past, value) {
        this.$emit("qualifications", value, this.checkValidation)
      },
      deep :true
    },
  },
  mounted() {
    // editの場合
    if(this.staff && this.staff.data.qualifications.length) {
      this.staff_qualifications = this.staff.data.qualifications

      // 年
      this.option_year = this.staff_qualifications.map(staff_qualification =>
        staff_qualification.obtained_date ? new Date(staff_qualification.obtained_date).getFullYear() : null),

      // 月
      this.option_month = this.staff_qualifications.map(staff_qualification =>
        staff_qualification.obtained_date ? new Date(staff_qualification.obtained_date).getMonth() + 1 : null),

      // 日
      this.option_date = this.staff_qualifications.map(staff_qualification =>
        staff_qualification.obtained_date ? new Date(staff_qualification.obtained_date).getDate() : null)

      // 画面開いた時に資格情報バリデーションチェック
      this.handleSelectChangedQualification();
      this.$emit("qualifications", this.staff_qualifications, this.checkValidation)
    }

    // セレクトボックスのクリックイベント
    let option_year_select = document.getElementsByClassName('option_year');
    for (var i = 0; i < option_year_select.length; i++){
      option_year_select[i].addEventListener("focus", this.focusYear);
      option_year_select[i].addEventListener("click", this.clickYear);
      option_year_select[i].addEventListener("focusout", this.focusoutYear);
    }
  },
  beforeDestroy() {
    // イベントリスナー削除
    window.removeEventListener("focus", this.focusYear);
    window.removeEventListener("click", this.clickYear);
    window.removeEventListener("focusout", this.focusoutYear);
  },
}
</script>
