<template>
  <div style="display:flex; width: auto; margin-right: 25px;">
    <p>チーム</p>
    <v-autocomplete
      v-model="target_team_id"
      :items="teams"
      @change="changeTeam"
      class="small-control"
      style="min-width: 190px; min-width: 190px;"
      item-text="name"
      item-value="id"
      hide-details="true"
      placeholder="選択してください"
      no-data-text="チームが見つかりません。"
      dense
      filled
      return-object
    ></v-autocomplete>
  </div>
</template>

<style>
.v-input__slot {
  background: initial!important;
}
</style>

<script>
export default {
  name: 'ShiftTeamSelect',
  components: {
  },
  props: {
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office_id: {
      type: Number,
      default: 0
    },
    team_id: {
      type: Number,
      default: 0
    },
    teams: {
      type: Array,
      default: function() {
        return [];
      }
    },
    initial_team_id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      target_team_id: 0,
    }
  },
  methods: {
    // チーム変更処理
    changeTeam(e) {
      if (e) {
        this.$emit('changeTeam', e.id);
      }
    },
  },
  computed: {
  },
  mounted() {
    this.target_team_id = this.initial_team_id;
  }
}
</script>
