<template>
  <b-button
    class="btn_shift_reset"
    @click="toggle"
    style="font-size: 12px!important; margin-right:0px!important"
  >
    上部の表を{{ is_open_table ? '閉じる' : '開く' }}
    <span :style="'color: ' + (is_open_table ? '#707070' : '#1059CC') + ';'">
      <font-awesome-icon :icon="(is_open_table ? 'minus' : 'plus') + '-square'" />
    </span>
  </b-button>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'BtnToggleTable',
  components: {
  },
  props: {
    is_open_table: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {};
  },

  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>

