<template>
  <b-button class="btn-shift-data" style="margin-left: 10px;" @click="dataCheck">
    <font-awesome-icon icon="upload" style="color: #D2D6DB; vertical-align: text-top; padding-right: 5px;" />シフトデータインポート
  </b-button>
</template>

<script>
import axios from "axios"
export default {
  name: 'BtnShiftDataImport',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },

  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    dataCheck() {
      this.$swal({
        html: 'シフトデータ（CSV）をインポートします。',
        icon: 'warning',
        showCancelButton: true,
        input: 'file',
        inputAttributes: {
          name: 'shift_import_file',
          id: 'shift_import_file',
          accept: 'application/vnd.csv'
        },
        confirmButtonText: 'アップロード',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        this.updateLoading(true);
        let formData = new FormData();
        formData.append('shift_import_file', result.value)
        axios.post(
          this.$route('shifts.schedule.datacheck'),
          formData
        ).then((checkResult) => {
          if (checkResult.status !== 200) {
            this.$swal.fire({
              icon: 'error',
              text: 'シフトデータのアップロードに失敗しました。しばらくたってから再試行してください。'
            }).then((result) => {
                this.updateLoading(false);
                location.reload()
            })
          } else {
            if (checkResult.data.status !== 200) {
              this.$swal.fire({
                icon: 'error',
                html: checkResult.data.message.join('<br />')
              }).then((result) => {
                this.updateLoading(false);
                location.reload()
              })
            } else {
              if (checkResult.data.message === 'additionalOfficeOccurred=TRUE') {
                // 兼業事業所あり
                this.updateLoading(false);
                let officeMessage = '<div style="text-align: left; width: 90%; margin: auto;"><input type="checkbox" id="checkAll" onchange="window.checkAllChange()" />　<label style="margin: 0;" for="checkAll">すべて選択</label></div>';
                Object.values(checkResult.data.additionalOffice).forEach(staff => {
                  officeMessage += '<div style="text-align: left; margin: 2ex auto 0;">' + this.htmlspecialchars(staff['name']) + 'さんは以下の事業所にも所属しています。'
                    + '同じシフトを設定したい場合は、対象を選択してシフトインポート実行ボタンをクリックしてください。</div>';
                  staff.officeStaffs.forEach(officeStaff => {
                    officeMessage += '<div style="text-align: left; width: 90%; margin: auto;"><input type="checkbox" name="idValue" id="check' + officeStaff['id'] + '" value="' + officeStaff['id'] + '" onChange="window.checkChange()">　<label style="margin: 0;" for="check' + officeStaff['id'] + '">'
                      + this.htmlspecialchars(officeStaff['officeName'])
                      + '</label></div>';
                  })
                });

                this.$swal({
                  html: officeMessage,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'シフトインポート実行',
                  cancelButtonText: 'キャンセル',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
                  preConfirm: () => {
                    return [
                      document.getElementsByName('idValue'),
                    ]
                  },
                }).then((result) => {
                  this.updateLoading(true);
                  let overWriteId = [];
                  for (let i = 0; i < result.value[0].length; i++) {
                    if (result.value[0][i].checked === true) {
                      overWriteId.push(result.value[0][i].value);
                    }
                  }
                  let jsonOverWriteId = '';
                  jsonOverWriteId = JSON.stringify(overWriteId)
                  formData.append('overWriteId', jsonOverWriteId)
                  let config = {
                    headers: {
                      'content-type': 'multipart/form-data',
                    },
                  }
                  axios.post(
                    this.$route('shifts.schedule.importexec'),
                    formData,
                  ).then((result) => {
                    if (result.status !== 200) {
                      this.$swal.fire({
                        icon: 'error',
                        text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
                      }).then((result) => {
                          location.reload()
                      })
                    } else {
                      this.updateLoading(false);
                      if (result.data.status !== 200) {
                        this.$swal.fire({
                          icon: 'error',
                          html: result.data.message.join('<br />')
                        }).then((result) => {
                          location.reload()
                        })
                      } else {
                        this.$swal.fire({
                          icon: 'info',
                          text: 'シフトデータのインポートが完了しました。'
                        }).then((result) => {
                          location.reload()
                        })
                      }
                    }
                  }).catch((error) => {
                    this.updateLoading(false);
                    this.$swal.fire({
                      icon: 'error',
                      text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
                    }).then((result) => {
                          location.reload()
                    })
                  })
                });
                this.updateLoading(false);
              } else {
                formData.append('overWriteId', '[]');
                axios.post(
                  this.$route('shifts.schedule.importexec'),
                  formData
                ).then((result) => {
                  if (result.status !== 200) {
                    this.$swal.fire({
                      icon: 'error',
                      text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
                    }).then((result) => {
                        this.updateLoading(false);
                        location.reload()
                    })
                  } else {
                    if (result.data.status !== 200) {
                      this.$swal.fire({
                        icon: 'error',
                        html: result.data.message.join('<br />')
                      }).then((result) => {
                        this.updateLoading(false);
                        location.reload()
                      })
                    } else {
                      this.$swal.fire({
                        icon: 'info',
                        text: 'シフトデータのインポートが完了しました。'
                      }).then((result) => {
                        this.updateLoading(false);
                        location.reload()
                      })
                    }
                  }
                }).catch((error) => {
                  this.$swal.fire({
                    icon: 'error',
                    text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
                  }).then((result) => {
                        this.updateLoading(false);
                        location.reload()
                  })
                })
              }
            }
          }
        })
        this.updateLoading(false);
      });
    },

    // シフトデータインポート
    action() {
      const import_csv_api = async (result) => {
        let formData = new FormData();
        formData.append('shift_import_file', result.value)
        await axios.post(
          this.$route('shifts.schedule.import'),
          formData
        ).then((result) => {
          if (result.status !== 200) {
            this.$swal.fire({
              icon: 'error',
              text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
            }).then((result) => {
                location.reload()
            })
          } else {
            if (result.data.status !== 200) {
              this.$swal.fire({
                icon: 'error',
                html: result.data.message.join('<br />')
              }).then((result) => {
                location.reload()
              })
            } else {
              this.$swal.fire({
                icon: 'info',
                text: 'シフトデータのインポートが完了しました。'
              }).then((result) => {
                location.reload()
              })
            }
          }
        }).catch((error) => {
          this.$swal.fire({
            icon: 'error',
            text: 'シフトデータのインポートに失敗しました。しばらくたってから再試行してください。'
          }).then((result) => {
                location.reload()
          })
        })
      };
      this.$swal({
        html: 'シフトデータをインポートします。',
        icon: 'warning',
        showCancelButton: true,
        input: 'file',
        inputAttributes: {
          name: 'shift_import_file',
          id: 'shift_import_file',
          accept: 'application/vnd.csv'
        },
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        import_csv_api(result);
      });
    },
    htmlspecialchars(string) {
      if (typeof string !== 'string') {
        return string;
      };
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          };
          return escaped[match];
        });
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}

window.checkAllChange = function (value) {
  const isCheck = document.getElementById('checkAll').checked;
  const additionalCount = document.getElementsByName('idValue');

  for (let i = 0; i < additionalCount.length; i++) {
    additionalCount[i].checked = isCheck;
  }
}

window.checkChange = function (value) {
  const additionalCount = document.getElementsByName('idValue');

  document.getElementById('checkAll').checked = true;
  for (let i = 0; i < additionalCount.length; i++) {
    if (!additionalCount[i].checked) {
      document.getElementById('checkAll').checked = false;
      break;
    }
  }
}
</script>
