<template>
  <td class="fixed fixed_show table-fixed-row stripe_green show-all">
    <v-checkbox 
      v-show="is_open_all_row"
      v-model="unshow_button"
      name="unshow_button"
      hide-details
      @change="changeShow()"
    ></v-checkbox>
  </td>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ShiftsFlgColumn',
  components: {
  },
  props: {
    year: { type: String },
    month: { type: String },
    staff: { type: Object, default: function() { return {}; }},
    shift_monthly_display_rule: { type: Object, default: function() { return {}; }},
    is_open_all_row: { type: Boolean },
  },
  data () {
    return {
      unshow_button: false,
    }
  },
  methods: {
    // 非表示ボタンの変更
    changeShow() {
      const data = {
        office_staff_id: this.staff.office_staff_id,
        target_month: this.year + this.month,
        occupation_id: this.staff.occupation_id,
        team_id: this.staff.team_id,
        display_flg: !this.unshow_button,
      };
      // axiosにて非表示フラグ変更(shift_monthly_display_rulesテーブルの更新)
      axios.patch(this.$route('shifts.staff.display'), data)
        .then((response) => {
          // 失敗時
          if(response.data.status === 'failure') {
            this.$swal({
              icon: 'error',
              html: response.data.message,
            });
            // 非表示ボタン戻す
            this.unshow_button = !this.unshow_button;
            return
          }

          // 成功時のみShiftMonthlyDisplayRule情報だけ更新して再描画
          this.$emit('updateShiftMonthlyDisplayRule', data)
        })
        .catch((error) => {
          this.$swal({
              icon: 'error',
              html: error,
          });
          // 非表示ボタン戻す
          this.unshow_button = !this.unshow_button;
        });
    },
  },
  watch: {
    // 非表示ボタンの変更
    shift_monthly_display_rule: function(val) {
      this.unshow_button = Object.keys(val).length
      ? !val.display_flg
      : false;
    },
  },
  mounted() {
    // 非表示ボタンの初期値
    // - 表示フラグがtrue or レコードがない時はfalse=ボタンOFF
    // - 表示フラグがfalseの時はtrue=ボタンON
    this.unshow_button = Object.keys(this.shift_monthly_display_rule).length
      ? !this.shift_monthly_display_rule.display_flg
      : false;
  },
}
</script>
