<template>
  <b-overlay rounded="sm">
    <div style="font-size: 14px;">
      <h4 style="margin-bottom: 24px;">外部連携　一覧</h4>
      <b-row style="margin: 0px 0px 32px 0px;">
        <b-col cols="6" style="padding: 0px;">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="キーワード検索"
            outlined
            hide-details
            dense
            style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 8px;"
          ></v-text-field>
        </b-col>
        <b-col cols="6" style="display:flex; justify-content: flex-end;">
          <a :href="$route('external-kintais.bulk-add')">
            <b-button class="btn_white" style="margin-right: 20px!important;" @click="lodingScreen">
              一括登録
            </b-button>
          </a>
          <a :href="$route('external-kintais.create')">
            <b-button class="btn_white" @click="lodingScreen">
              <font-awesome-icon icon="plus" class="icon-style" />外部連携を追加
            </b-button>
          </a>
        </b-col>
      </b-row>
      <v-data-table
        :headers = "headers"
        :items = "items"
        :items-per-page = "apiinfos_list"
        :footer-props = "footerProps"
        :search="search"
        class="elevation-1"
        fixed-header
        height="calc(100vh - 310px)"
        no-data-text="外部連携が存在しません"
        no-results-text="外部連携が存在しません"
        @update:items-per-page="savePerPage"
      >
        <template v-slot:item.next_day_flg="{ item }">
          <v-checkbox
            v-model="item.next_day_flg"
            value="1"
            disabled
          ></v-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i>変更</b-button>
          <b-button class="btn_actions" @click="deleteItem(item)" style="padding-right: 0px;"><i class="pe-7s-trash"></i>削除</b-button>
        </template>
        <template v-slot:footer.page-text="props">
          {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
          {{ props.pageStop }} 件までを表示
        </template>
      </v-data-table>
    </div>
  </b-overlay>
</template>

<style scope>
.v-menu {
  display: none;
}
.btn_white {
  height: 40px!important;
}
</style>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  components: {
  },
  props: {
    kintai_data : {
        type: Array,
        required: true
    }
  },
  data: function() {
    return {
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '外部連携先名', value: 'name', width: '15%'},
        { text: '外部連携先 サービス名', value: 'external_kintai_text', width: '20%'},
        { text: '0時を跨ぐ勤務区分あり', value: 'next_day_flg', width: '15%' },
        { text: 'APIキーの有効期限', value: 'invalid_at', width: '30%' },
        { text: '操作', value: 'actions', sortable: false, width: '20%' },
      ],
      apiinfos_list: Number(this.$cookie.get('apiinfos_list')),
      // #4493 のバックエンドが実装されたら修正 [
      apiinfos: [],
      // #4493 のバックエンドが実装されたら修正 ]
    }
  },
  computed: {
    items () {
        this.apiinfos = this.kintai_data;
      return this.apiinfos;
    }
  },
  mounted() {
  },
  methods: {
    // ローディング制御
    lodingScreen() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    // ローディング表示
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // 1ページ表示件数変更
    savePerPage(val) {
      this.$cookie.set('apiinfos_list', val, 1);
    },
    // #4493 バックエンドが対応されたら修正 [
    // 一括登録ボタン
    all_add_url() {
    },
    // 変更ボタン
    editItem(item) {
      this.$inertia.get(route('external-kintais.edit', { external_kintai: item.external_kintai_id }));
    },
    // 削除ボタン
    deleteItem (item) {
      this.$swal({
        title: "本当に削除しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.$inertia.delete(route('external-kintais.destroy', item.external_kintai_id),
          {
            preserveScroll: true,
            onError: (p) => {
                this.$swal(
                  'error',
                  '削除に失敗しました。しばらくたってから再試行してください。',
                  'error')
            },
            onSuccess: async () => {
                this.updateLoading(false);
                this.$swal('削除しました', '', 'success');
            }
            });
        }
      });
    },
  }
}

</script>
