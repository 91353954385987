<template>
  <div class="m-3 p-3">
    <h4>API keyの設定</h4>
    <form class="pt-5" @submit.prevent="saveApiInfos">
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <label for="external_point_name" class="required">外部連携先 サービス名</label>
        </b-col>
        <b-col cols="8" md="4">
            <b-form-select :class="{'is-invalid': errors.external_point_name}" id="external_point_name" v-model="form.external_point_name">
              <option value="1">ハーモス勤怠</option>
            </b-form-select>
            <div v-if="errors.external_point_name" class="invalid-feedback">{{ errors.external_point_name }}</div>
        </b-col>
      </b-row>
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <label for="external_point_name">0時を跨ぐ勤務区分あり</label>
        </b-col>
        <b-col cols="8" md="4">
          <b-form-checkbox
            id="checkbox_next_day_flg"
            v-model="form.next_day_flg"
            class="checkbox"
          />
        </b-col>
      </b-row>
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <label for="company_code" class="required">企業名</label>
        </b-col>
        <b-col cols="8" md="4">
          <input
            id="company_code"
            v-model="form.company_code"
            type="text"
            class="form-control"
            :class="{'is-invalid': errors.company_code}"
            placeholder="企業名を入力してください。"
            @blur="check_company_code"
          >
          <div v-if="errors.company_code" class="invalid-feedback">{{ errors.company_code }}</div>
        </b-col>
      </b-row>
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <label for="api_secret_key" class="required">Secret key</label>
        </b-col>
        <b-col cols="8" md="4">
          <input
            id="api_secret_key"
            v-model="form.api_secret_key"
            type="text"
            class="form-control"
            :class="{'is-invalid': errors.api_secret_key}"
            placeholder="Secret keyを入力してください。"
            @blur="check_api_secret_key"
          >
          <div v-if="errors.api_secret_key" class="invalid-feedback">{{ errors.api_secret_key }}</div>
        </b-col>
      </b-row>
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <label for="invalid_at" class="required">APIキーの有効期限</label>
        </b-col>
        <b-col cols="8" md="4">
          <datepicker
            class="form-control"
            :class="{'is-invalid': errors.invalid_at, 'error--text': errors.invalid_at}"
            :default="form.invalid_at"
            :clearable="datepicker_clearable"
            :validation_check="false"
            @date="setInvalidAt"
            @focusout="check_invalid_at"
          />
          <div v-if="errors.invalid_at" class="invalid-feedback">{{ errors.invalid_at }}</div>
        </b-col>
      </b-row>
      <b-row class="pr-0 py-1">
        <b-col cols="4" md="2">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <label for="paid_holiday_id" class="required">休暇設定ID</label>
              <span
                class="pe-7s-help1 pe-lg"
                dark
                v-bind="attrs"
                v-on="on"
              ></span>
            </template>
            <span style="font-size: 0.8rem">
              HRMOS勤怠の休暇設定とシンクロシフトの休暇設定を紐づけます。<br />
              HRMOS勤怠で利用している休暇設定IDのうち、シンクロシフトで利用するもののIDを設定します。      
            </span>
          </v-tooltip>
        </b-col>
        <b-col cols="8" md="4">
          <input
            id="paid_holiday_id"
            v-model="form.paid_holiday_id"
            type="text"
            class="form-control"
            :class="{'is-invalid': errors.paid_holiday_id}"
            placeholder="休暇設定IDを入力してください。"
            @blur="check_paid_holiday_id"
          >
          <div v-if="errors.paid_holiday_id" class="invalid-feedback">{{ errors.paid_holiday_id }}</div>
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col class="py-1" cols="12" md="2">
          <b-button :disabled="!checkValidation" block variant="primary" size="lg" @click="saveApiInfos">保存</b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<style scope>
.v-menu {
  display: none;
}
</style>

<script>
import datepicker from "../Components/MyDatePicker";

export default {
  components: {
    datepicker
  },

  props: {
    external_point_name: Number,
    company_code: String,
    api_secret_key: String,
    invalid_at: String,
    paid_holiday_id: Number,
    next_day_flg: Boolean
  },

  data: function() {
    return {
      datepicker_clearable: false,
      form: {
        external_point_name: null,
        company_code: '',
        api_secret_key: '',
        invalid_at: '',
        paid_holiday_id: null,
        next_day_flg: false
      },
      errors: {},
      company_code_validationcheck: true,
      api_secret_key_validationcheck: true,
      invalid_at_validationcheck: true,
      paid_holiday_id_validationcheck: true,
    }
  },

  mounted() {
      document.getElementById('checkbox_next_day_flg').checked = this.form.next_day_flg == '1' ? true : false;
  },

  created: function() {
    this.form.external_point_name = this.external_point_name
    this.form.company_code = this.company_code
    this.form.api_secret_key = this.api_secret_key
    this.form.invalid_at = this.invalid_at
    this.form.paid_holiday_id = this.paid_holiday_id
    this.form.next_day_flg = this.next_day_flg
    
  },

  computed: {
    checkValidation() {
      return this.company_code_validationcheck
          && this.api_secret_key_validationcheck
          && this.invalid_at_validationcheck
          && this.paid_holiday_id_validationcheck;
    },
  },

  methods: {
    check_company_code() {
      if (this.form.company_code !== null && this.form.company_code !== '') {
        this.company_code_validationcheck = true;
        this.$delete(this.errors, 'company_code');
      } else {
        this.company_code_validationcheck = false;
        this.$set(this.errors, 'company_code', '企業名は必ず指定してください。');
      }
    },
    check_api_secret_key() {
      let str_api_secret_key = this.form.api_secret_key;
      this.api_secret_key_validationcheck = true;
      this.$delete(this.errors, 'api_secret_key');
      // 必須チェック
      if (str_api_secret_key == null || str_api_secret_key == '') {
        this.api_secret_key_validationcheck = false;
        this.$set(this.errors, 'api_secret_key', 'Secret keyは必ず指定してください。');
      } else {
        // 英数字チェック
        if (!str_api_secret_key.match(/^[A-Za-z0-9]*$/)) {
          this.api_secret_key_validationcheck = false;
          this.$set(this.errors, 'api_secret_key', 'Secret keyは半角英数字で入力してください。');
        }
      }
    },
    check_invalid_at( val ) {
      this.invalid_at_validationcheck = true;
      this.$delete(this.errors, 'invalid_at');
      if (val == '' || val == null || val == 'Invalid Date' || val == 'Invalid date') {
        this.invalid_at_validationcheck = false;
        this.$set(this.errors, 'invalid_at', 'APIキーの有効期限を入力してください。');
      } else {
        if (!val.match(/\d{4}\/\d{2}\/\d{2}/)) {
          this.invalid_at_validationcheck = false;
          this.$set(this.errors, 'invalid_at', 'APIキーの有効期限の形式が正しくありません。');
        }
      }
    },
    check_paid_holiday_id() {
      if (this.form.paid_holiday_id !== null && this.form.paid_holiday_id !== '') {
        this.paid_holiday_id_validationcheck = true;
        this.$delete(this.errors, 'paid_holiday_id');
      } else {
        this.paid_holiday_id_validationcheck = false;
        this.$set(this.errors, 'paid_holiday_id', '休暇設定IDは必ず指定してください。');
      }
    },
    setInvalidAt: function(date) {
      this.form.invalid_at = date
      this.invalid_at_validationcheck = true;
      this.$delete(this.errors, 'invalid_at');
    },
    saveApiInfos: function () {
      this.$swal
        .fire({
          html: '<span class="swal2-title">保存しますか？</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '保存',
          cancelButtonText: 'キャンセル',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            this.errors = {}
            this.updateLoading(true);
            const axios = require('axios')
            axios
              .post(this.$route('apiinfos.do-update'), this.form)
              .then(res => {
                this.updateLoading(false);
                if (res.status === 200) {
                  if (res.data.status !== 200) {
                    this.errors = res.data.errors;
                  } else {
                    this.$swal.fire({
                      icon: 'success',
                      html: '<span class="swal2-title">保存しました</span>'
                    })
                  }
                } else {
                  throw res.status
                }
              })
              .catch(err => {
                this.updateLoading(false);
                this.$swal.fire({
                  icon: 'error',
                  text: '設定に失敗しました。しばらくたってから再試行してください。'
                })
                location.reload()
              })
          }
        })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  }
}
</script>
