<template>
  <div style="font-size: 14px;">
    <h4 style="font-weight: 700; padding: 0px;">チームの追加</h4>
    <VuePerfectScrollbar class="teams-area" style="min-height: calc(95vh - 320px); max-height: calc(95vh - 320px); margin-top: 20px; margin-top: 20px;">
      <div class="input_row">
        <label for="office_id" class="required">事業所</label>
        <div>
          <v-autocomplete
            v-model="form.office_id"
            class="autocomplete-style"
            style="width: 600px; min-width: 600px;"
            :items="office_list"
            :class="{'is-invalid': errors.office_id}"
            @change="handleSelectChangedOffice"
            @blur="check_office_id"
            item-text="text"
            item-value="value"
            hide-details="true"
            placeholder="選択してください"
            no-data-text="事業所が見つかりません。"
            dense
            filled
          ></v-autocomplete>
          <div v-if="errors.office_id" class="invalid-feedback">{{ errors.office_id }}</div>
        </div>
      </div>
      <div class="input_row">
        <label for="team_name" class="required">チーム名</label>
        <div>
          <input
            v-model="form.name"
            type="text"
            class="form-control"
            style="width: 600px; min-width: 600px;"
            :class="{'is-invalid': errors.name}"
            @blur="check_team_name"
          />
          <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
        </div>
      </div>
      <div class="input_row">
        <label for="team_member" class="required">チームメンバー</label>
        <div style="min-width: 600px;">
          <v-autocomplete
            v-model="form.team_member"
            class="autocomplete-style"
            style="width: auto; min-width: 600px"
            :items="office_staff_list"
            :class="{'is-invalid': errors.team_member}"
            @blur="check_team_member"
            item-text="text"
            item-value="value"
            hide-details="true"
            placeholder="選択してください"
            no-data-text="チームメンバーが見つかりません。"
            multiple
            chips
            dense
            filled
          ></v-autocomplete>
          <!-- error message -->
          <div v-if="form_errors.team_member">
            <p class="invalid-feedback">{{ form_errors.team_member }}</p>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>
        <BtnSaveClose
          :disabled="!checkValidation"
          @click.native="btnSaveCloseClicked"
        />
      </div>
      <div>
        <BtnBackToList
          :disabled="!checkValidation"
          @click.native="btnBackToListClicked"
        />
        <BtnSave
          :disabled="!checkValidation"
          @click.native="btnSaveClicked"
        />
      </div>
    </div>
</div>
</template>

<style scoped>
.v-select__selections input {
  min-height: 40px;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BtnBackToList from '@/Pages/Shifts/Components/Button/BtnBackToList';
import BtnSave from '@/Pages/Shifts/Components/Button/BtnSave';
import BtnSaveClose from '@/Pages/Shifts/Components/Button/BtnSaveClose';
import axios from 'axios';

export default {
  components: {
    VuePerfectScrollbar,
    BtnBackToList,
    BtnSave,
    BtnSaveClose,
  },
  props : {
    errors: {
      type: Object,
      required: false,
    },
    offices :{
      type:Object,
      required :true
    },
    staffs : {
      type:Object,
      required :true
    }
  },
  data() {
    return {
      form: {
        office_id: null,
        name: null,
        team_member: [],
      },
      office_staff_list: [],
      office_id_validationCheck: false,
      name_validationCheck: false,
      team_member_validationCheck: false,
      team_member_focus: false,
    }
  },
  mounted() {
	  this.updateLoading(false);

      // 親画面にエラー情報セット
      let errorMessage = '事業所は必ず指定してください。<br>チーム名は必ず指定してください。<br>チームメンバーは必ず指定してください。<br>';
      // 親画面にエラー情報送信
      this.$emit('setchildError', true, errorMessage);
  },
  computed : {
    office_list () {
      return this.offices.data.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
    },
    // team_memberのエラーをform_errorsに追加
    form_errors () {
      let form_errors = {}
      // 複数の同じメッセージを表示させないために、1件でもあればエラーを表示する
      form_errors['team_member'] = "";
      if(!this.team_member_validationCheck && this.team_member_focus) {
        form_errors['team_member'] = "チームメンバーは必ず指定してください。";
      }
      return form_errors
    },
    checkValidation() {
        return this.office_id_validationCheck
            && this.name_validationCheck
            && this.team_member_validationCheck;
    },
  },
  methods: {
    handleSelectChangedOffice (val) {
      this.form.office_id = val
      if (val !== '' && val !== null && val !== undefined) {
        this.setOfficeStaffs(val)
      } else {
        this.office_staff_list = [];
      }

      // 事務所選択時にリセットさせる
      this.form.team_member = [];
    },
    check_all() {
      this.check_office_id();
      this.check_team_name();
      this.check_team_member();
    },
    check_office_id() {
      if (this.form.office_id !== null && this.form.office_id !== '') {
        this.office_id_validationCheck = true;
        this.$delete(this.errors, 'office_id');
      } else {
        this.office_id_validationCheck = false;
        this.$set(this.errors, 'office_id', '事業所は必ず指定してください。');
      }
    },
    check_team_name() {
      if (this.form.name !== null && this.form.name !== '') {
        this.name_validationCheck = true;
        this.$delete(this.errors, 'name');
      } else {
        this.name_validationCheck = false;
        this.$set(this.errors, 'name', 'チーム名は必ず指定してください。');
      }
    },
    check_team_member() {
      this.team_member_focus = true;
      if (this.form.team_member.length > 0) {
        this.team_member_validationCheck = true;
        this.$delete(this.errors, 'team_member');
      } else {
        this.team_member_validationCheck = false;
        this.$set(this.errors, 'team_member', 'チームメンバーは必ず指定してください。');
      }
    },
    setOfficeStaffs(office_id) {
      if(!this.staffs.data[office_id]) return
      // staff.firstname + staff.lastnameの配列
      this.office_staff_list = this.staffs.data[office_id].map(staff => {
        return {
          text: staff.lastname + staff.firstname,
          value: staff.office_staff_id
        }
      })
    },
    async showSuccessMessage() {
      return this.$swal(
        '保存しました',
        '',
        'success',
      )
    },
    async btnBackToListClicked() {
      this.backToList();
    },
    async btnSaveClicked() {
      try {
        await this.save();
        await this.showSuccessMessage();
        this.backToList();
      } catch (error) {
        console.error(error)
      }
    },
    async btnSaveCloseClicked() {
      try {
        await this.save();
        await this.showSuccessMessage();
        this.$emit('closeDialog');
      } catch (error) {
        console.error(error)
      }
    },
    backToList() {
      this.$emit('nextStep', 2);
    },
    async save() {
      this.updateLoading(true);
      return axios.post(this.$route('shifts.teams.store'), this.form)
        .catch(error => {
          if (error.response.status === 422) {
            for (const [key, messages] of Object.entries(error.response.data.errors)) {
              this.$set(this.errors, key, messages.join(', '));
            }
          }
          throw error;
        })
        .finally(() => {
          this.updateLoading(false);
        });
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    watch_exec() {
      // 全入力チェック
      this.check_all();
      let errorFlag = this.office_id_validationCheck && this.name_validationCheck && this.team_member_validationCheck;
      // エラーメッセージ作成
      let errorMessage = '';
      // 親画面にエラー情報送信
      this.$emit('setchildError', !errorFlag, errorMessage);
    },
  },
  watch: {
    form : {
      handler (value) {
          this.$emit('setChange', true);
      },
      deep: true
    },
    office_id_validationCheck: {
      handler: function (data) {
        this.watch_exec();
      },
    },
    name_validationCheck: {
      handler: function (data) {
        this.watch_exec();
      },
    },
    team_member_validationCheck: {
      handler: function (data) {
        this.watch_exec();
      },
    },
  }
}
</script>
