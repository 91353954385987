<template>
  <div class="m-3">
    <h4>従業者の勤務の体制及び勤務形態一覧表</h4>
    <form class="pt-5">
      <b-row>
        <b-col cols="12" md="1">
          <div class="py-2">{{ year_int }}年{{ month_int }}月</div>
        </b-col>
        <b-col class="px-0" cols="12" md="1" offset-md="3">
          <div class="py-2">サービス種別：</div>
          <div class="py-2">事業所名：</div>
        </b-col>
        <b-col class="px-0" cols="12" md="3">
          <div class="py-2">{{ service_type.name }}</div>
          <div class="py-2">{{ office.name }}</div>
        </b-col>
        <b-col class="px-1" cols="12" md="1">
          <b-button class="px-1" block variant="primary" size="lg" style="line-height: 3;" @click="">
            保存
          </b-button>
        </b-col>
        <b-col class="px-1" cols="12" md="1">
          <b-button class="px-1" block variant="primary" size="lg" style="line-height: 3;" @click="">
            戻る
          </b-button>
        </b-col>
        <b-col class="px-1" cols="12" md="1">
          <b-button class="px-1" block variant="primary" size="lg" @click="">
            PDF<br>ダウンロード
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pr-0 py-1" cols="12" md="5">
          <b-table-simple class="py-0">
            <b-thead>
              <b-tr>
                <b-th :colspan="business_days_columns" style="background: transparent; border: none !important;">（2）{{ business_days.title }}</b-th>
              </b-tr>
              <b-tr>
                <b-th style="width:10%; background: transparent; border: none !important;" v-if="business_days.patterns_title !== null"></b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">月</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">火</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">水</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">木</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">金</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">土</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">日</b-th>
                <b-th style="width:5%;" v-if="business_days.weekly_table_visible">祝</b-th>
                <b-th style="width:5%; background: transparent; border: none !important;" v-if="business_days.weekly_table_visible"></b-th>
                <b-th style="width:45%; background: transparent; border: none !important;">サービス提供時間</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td style="background: transparent; border: none !important;" v-if="business_days.patterns_title !== null">{{ business_days.patterns_title[0] }}</b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].sun">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].mon">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].tue">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].wed">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].thu">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].fri">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].sat">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[0].hol">〇</span><span v-else>－</span></b-td>
                <b-td style="background: transparent; border: none !important;" v-if="business_days.weekly_table_visible">⇒</b-td>
                <b-td style="background: transparent; border: none !important;">
                    <span>{{ business_days.patterns[0].start_time }}</span>
                    <span>～</span>
                    <span>{{ business_days.patterns[0].end_time }}</span>
                    <span v-if="business_days.weekly_table_visible"><span>（計</span>
                      <span>{{ business_days.patterns[0].total_service_time }}</span>
                    <span>時間）</span></span>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody>
              <b-tr>
                <b-td style="background: transparent; border: none !important;" v-if="business_days.patterns_title !== null">{{ business_days.patterns_title[1] }}</b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].sun">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].mon">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].tue">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].wed">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].thu">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].fri">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].sat">〇</span><span v-else>－</span></b-td>
                <b-td v-if="business_days.weekly_table_visible"><span v-if="business_days.patterns[1].hol">〇</span><span v-else>－</span></b-td>
                <b-td style="background: transparent; border: none !important;" v-if="business_days.weekly_table_visible">⇒</b-td>
                <b-td style="background: transparent; border: none !important;">
                    <span>{{ business_days.patterns[1].start_time }}</span>
                    <span>～</span>
                    <span>{{ business_days.patterns[1].end_time }}</span>
                    <span v-if="business_days.weekly_table_visible"><span>（計</span>
                      <span>{{ business_days.patterns[1].total_service_time }}</span>
                    <span>時間）</span></span>
                </b-td>
              </b-tr>
              <b-tr v-if="business_days.comment !== null"><b-td :colspan="business_days_columns" style="background: transparent; border: none !important;">{{ business_days.comment }}</b-td></b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col class="pr-0 py-1" cols="12" md="6" offset-md="1">
          <b-table-simple class="py-0">
            <b-tbody>
              <b-tr>
                <b-td style="width:50%; background: transparent; border: none !important;">事業所における常勤の従業者が勤務すべき時間数</b-td>
                <b-td style="width:5%; background: transparent; border: none !important;">{{ fulltime_conversion.workinghours_per_day }}</b-td>
                <b-td style="width:15%; background: transparent; border: none !important;">時間／日</b-td>
                <b-td style="width:10%; background: transparent; border: none !important;">{{ fulltime_conversion.workinghours_per_month }}</b-td>
                <b-td style="width:15%; background: transparent; border: none !important;">時間／月</b-td>
              </b-tr>
              <b-tr>
                <b-td style="background: transparent; border: none !important;">利用定員</b-td>
                <b-td style="background: transparent; border: none !important;">{{ office.capacity.resident_capacity }}</b-td>
                <b-td style="background: transparent; border: none !important;">人</b-td>
              </b-tr>
              <b-tr>
                <b-td style="background: transparent; border: none !important;">当月の1日あたり平均利用者数見込</b-td>
                <b-td style="background: transparent; border: none !important;">？</b-td>
                <b-td style="background: transparent; border: none !important;">人</b-td>
              </b-tr>
              <b-tr>
                <b-td style="background: transparent; border: none !important;">当月の日数</b-td>
                <b-td style="background: transparent; border: none !important;">{{ days_in_month }}</b-td>
                <b-td style="background: transparent; border: none !important;">日</b-td>
              </b-tr>
              <b-tr>
                <b-td style="background: transparent; border: none !important;">所定休日を抜いた業務日数</b-td>
                <b-td style="background: transparent; border: none !important;">{{ days_in_month - office.monthly_paid_holidays[month_int] }}</b-td>
                <b-td style="background: transparent; border: none !important;">日</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <table class="table" style="table-layout: fixed;">
          <thead>
            <tr>
              <th class="fixed fixed01" rowspan="4">No</th>
              <th class="fixed fixed02" rowspan="4">チーム(ユニット名)</th>
              <th class="fixed fixed03" rowspan="4">職種</th>
              <th class="fixed fixed04" rowspan="4">勤務形態</th>
              <th class="fixed fixed05" rowspan="4">資格</th>
              <th class="fixed fixed06" rowspan="4">氏名</th>
              <th class="fixed fixed07" rowspan="4"></th>
              <th style="width:calc(35px*31); min-width: calc(35px*31);" colspan="31">勤 務 時 間 数</th>
              <th style="width:70px; min-width: 70px;" rowspan="4">1ヶ月の勤務時間数合計</th>
              <th style="width:70px; min-width: 70px;" rowspan="4">週平均勤務時間数</th>
              <th style="width:100px; min-width: 100px;" rowspan="4">兼務状況（兼務先／兼務する職務の内容／兼務先の勤務時間数）</th>
              <th style="width:70px; min-width: 70px;" rowspan="4">1ヶ月の常勤換算人数</th>
              <th style="width:70px; min-width: 70px;" rowspan="4">業務割合</th>
            </tr>
            <tr>
              <th v-for="(days_in_week, idx) in calendar.days_in_weeks" :key="days_in_week" :colspan="days_in_week">{{ idx + 1 }}週目</th>
            </tr>
            <tr>
              <th v-for="date in calendar.dates" :key="date" style="width:35px; min-width: 35px;">{{ date.day }}</th>
            </tr>
            <tr>
              <th v-for="date in calendar.dates" :key="date">{{ date.weekday }}</th>
            </tr>
          </thead>
          <tbody v-for="(occupation_shift, idx) in occupation_shifts" :key="occupation_shift">
            <tr>
              <td class="fixed fixed01" rowspan="2">{{ idx + 1 }}</td>
              <td class="fixed fixed02" rowspan="2">{{ occupation_shift.team_name }}</td>
              <td class="fixed fixed03" rowspan="2">{{ occupation_shift.occupation_name }}</td>
              <td class="fixed fixed04" rowspan="2">{{ occupation_shift.work_style }}</td>
              <td class="fixed fixed05" rowspan="2">{{ occupation_shift.qualification }}</td>
              <td class="fixed fixed06" rowspan="2">{{ occupation_shift.staff_lastname }} {{ occupation_shift.staff_firstname }}</td>
              <td class="fixed fixed07">勤務区分</td>
              <td v-for="date in calendar.dates" :key="date">{{ typeof occupation_shift.days[date.day] === 'undefined' || occupation_shift.days[date.day].actual_working_hours === 0 ? '' : occupation_shift.days[date.day].workpattern_name }}</td>
              <td rowspan="2">160</td>
              <td rowspan="2">40</td>
              <td rowspan="2">計画作成責任者/介護職員</td>
              <td rowspan="2">0.5</td>
              <td rowspan="2">70％</td>
            </tr>
            <tr>
              <td class="fixed fixed07">勤務時間数</td>
              <td v-for="date in calendar.dates" :key="date">{{ typeof occupation_shift.days[date.day] === 'undefined' || occupation_shift.days[date.day].actual_working_hours === 0 ? '' : occupation_shift.days[date.day].actual_working_hours }}</td>
            </tr>
          </tbody>
        </table>
      </VuePerfectScrollbar>
      <div class="pt-5">常勤換算後の結果</div>
      <b-row>
        <b-col class="pr-0 py-1" cols="12" md="4">
          <b-table-simple class="py-0">
            <b-tbody>
              <b-tr>
                <b-td colspan="3" class="no-border-cell">職種ごとの常勤換算人数</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell" style="width:40%;"></b-td>
                <b-td class="no-border-cell" style="width:20%;">総時間</b-td>
                <b-td class="no-border-cell" style="width:40%;">常勤換算後の人数</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">管理者</b-td>
                <b-td class="no-border-cell">160</b-td>
                <b-td class="no-border-cell">0.9</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">計画作成責任者</b-td>
                <b-td class="no-border-cell">140</b-td>
                <b-td class="no-border-cell">0.8</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">オペレーター</b-td>
                <b-td class="no-border-cell">120</b-td>
                <b-td class="no-border-cell">0.7</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">訪問介護員_定期</b-td>
                <b-td class="no-border-cell">100</b-td>
                <b-td class="no-border-cell">0.6</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">訪問介護員_随時</b-td>
                <b-td class="no-border-cell">40</b-td>
                <b-td class="no-border-cell">0.2</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">訪問看護員</b-td>
                <b-td class="no-border-cell">0</b-td>
                <b-td class="no-border-cell">0.0</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">理学療法士</b-td>
                <b-td class="no-border-cell">0</b-td>
                <b-td class="no-border-cell">0.0</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">作業療法士</b-td>
                <b-td class="no-border-cell">0</b-td>
                <b-td class="no-border-cell">0.0</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">言語聴覚士</b-td>
                <b-td class="no-border-cell">0</b-td>
                <b-td class="no-border-cell">0.0</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col class="pr-0 py-1" cols="12" md="4">
          <b-table-simple class="py-0">
            <b-tbody>
              <b-tr>
                <b-td colspan="3" class="no-border-cell">勤務形態ごとの常勤換算人数</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell"></b-td>
                <b-td class="no-border-cell">総時間</b-td>
                <b-td class="no-border-cell">常勤換算後の人数</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">Ａ：　常勤専従</b-td>
                <b-td class="no-border-cell">200</b-td>
                <b-td class="no-border-cell">1.1</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">Ｂ：　常勤兼務</b-td>
                <b-td class="no-border-cell">140</b-td>
                <b-td class="no-border-cell">0.8</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">Ｃ：非常勤専従</b-td>
                <b-td class="no-border-cell">120</b-td>
                <b-td class="no-border-cell">0.7</b-td>
              </b-tr>
              <b-tr>
                <b-td class="no-border-cell">Ｄ：非常勤兼務</b-td>
                <b-td class="no-border-cell">100</b-td>
                <b-td class="no-border-cell">0.6</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <!-- Bページ -->
      <b-table-simple class="py-3 pt-5 w-70">
        <b-tbody>
          <b-tr>
            <b-td class="no-border-cell" colspan="3" style="font-size: 0.94rem !important;">■シフト記号表（勤務時間帯）</b-td>
            <b-td class="no-border-cell" colspan="1" style="font-size: 0.94rem !important;">※24時間表記</b-td>
            <b-td class="no-border-cell" colspan="6" style="font-size: 0.94rem !important;">休憩時間1時間は「1:00」、休憩時間45分は「00:45」と入力してください。</b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell" style="width:10%;">休：休暇</b-td>
            <b-td class="no-border-cell text-center" style="width:10%;"><p class="cell1 my-0 py-2">休</p></b-td>
            <b-td class="no-border-cell" style="width:5%;">：</b-td>
            <b-td class="no-border-cell" style="width:15%;"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell" style="width:5%;">～</b-td>
            <b-td class="no-border-cell" style="width:15%;"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell" style="width:5%;">（</b-td>
            <b-td class="no-border-cell" style="width:15%;"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell" style="width:5%;">）</b-td>
            <b-td class="no-border-cell" style="width:15%;"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell">出：出張</b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">出</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell">研：研修</b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">研</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">a</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">b</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">b</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">c</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">d</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">e</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">f</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">g</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">h</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">i</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">j</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">k</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">l</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">m</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">n</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">o</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">p</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">q</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">r</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">z</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">早退（1）</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">早退（2）</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
          <b-tr>
            <b-td class="no-border-cell"></b-td>
            <b-td class="no-border-cell text-center"><p class="cell1 my-0 py-2">az</p></b-td>
            <b-td class="no-border-cell">：</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">～</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">（</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
            <b-td class="no-border-cell">）</b-td>
            <b-td class="no-border-cell"><timepicker></timepicker></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </form>
  </div>
</template>

<style scoped>
.table-responsive {
  overflow-x: scroll;
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0;
}
.table th, .table td {
  background-color: #ffffff;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 0.75rem !important;
  word-break: break-all;
  border: solid 1px #000000 !important;
  background-clip: padding-box;
}
.table .fixed {
  position: sticky;
  left: 0;
  z-index: 2;
}
.table .fixed01 {
  width: 30px;
  left: 0;
}
.table .fixed02 {
  width: 60px;
  left: 30px;
}
.table .fixed03 {
  width: 60px;
  left: 90px;
}
.table .fixed04 {
  width: 60px;
  left: 150px;
}
.table .fixed05 {
  width: 60px;
  left: 210px;
}
.table .fixed06 {
  width: 100px;
  left: 270px;
}
.table .fixed07 {
  width: 100px;
  left: 370px;
}
table .no-border-cell {
  background: transparent;
  border: none !important;
  vertical-align: middle;
  padding: 1px;
}
table .cell1 {
  border: solid 1px black;
  background-color: white;
  min-height: 40px;
  vertical-align: middle;
}
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import timepicker from "../Components/MyTimePicker"

export default {
  components: {
    VuePerfectScrollbar,
    timepicker,
  },
  props: {
    'year_int': {
      type: Number,
      required: true
    },
    'month_int': {
      type: Number,
      required: true
    },
    'year': {
      type: String,
      required: true
    },
    month: {
      type: String,
      required: true
    },
    'days_in_month': {
      type: Number,
      required: true
    },
    'office': {
      type: Object,
      required: true
    },
    'service_type': {
      type: Object,
      required: true
    },
    'fulltime_conversion': {
      type: Object,
      require: true
    },
    'fulltime_conversion_details': {
      type: Object,
      require: true
    },
    'shifts': {
      type: Object,
      require: true
    },
    'occupations': {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      business_days: null,
      business_days_columns: 11,
      occupation_shifts: [],
      calendar: {
        days_in_weeks: [],
        dates: []
      }
    }
  },

  methods: {
  },

  created() {
    // 事業所の営業日
    switch (this.service_type.template_no) {
      case '06':
      case '07':
      case '12':
      case '13':
      case '14':
        // B
        this.business_days = {
          title: '事業所の営業日',
          weekly_table_visible: true,
          service_times_title: 'サービス提供時間',
          patterns_title: ['サービス提供時間', 'サービス提供時間'],
          comment: '（送迎時間を除く）'
        }
        break
      case '08':
      case '19-1':
      case '19-2':
      case '20-1':
      case '20-2':
      case '21-1':
      case '21-2':
      case '22-1':
      case '22-2':
        // C
        this.business_days = {
          title: '日中/夜勤の時間帯の区分',
          weekly_table_visible: false,
          service_times_title: null,
          patterns_title: ['日中（夜勤時間帯以外）の時間帯', '夜勤時間帯'],
          comment: null
        }
        break
      case '15':
      case '16':
      case '17':
        // D
        this.business_days = {
          title: '日中/夜間及び深夜の時間帯の区分',
          weekly_table_visible: false,
          service_times_title: null,
          patterns_title: ['利用者の生活時間帯（日中）', '夜間及び深夜の時間帯'],
          comment: null
        }
        break
      case '11':
        // E
        this.business_days = {
          title: '事業所の営業日',
          weekly_table_visible: true,
          service_times_title: '夜間対応型訪問介護の提供時間',
          patterns_title: null,
          comment: null
        }
        break
      default:
        // A
        this.business_days = {
          title: '事業所の営業日',
          weekly_table_visible: true,
          service_times_title: 'サービス提供時間',
          patterns_title: null,
          comment: null
        }
        break
    }
    let patterns = [
      {
        start_time: '',
        end_time: '',
        total_service_time: 0
      },
      {
        start_time: '',
        end_time: '',
        total_service_time: 0
      }
    ]
    let business_days = this.office.business_days.sort(function(a, b) {
      return (a.display_order < b.display_order) ? -1 : 1
    })
    business_days.forEach((business_day, idx) => {
      if ('start_time' in business_day) {
        patterns[idx].start_time = business_day.start_time
      }
      if ('end_time' in business_day) {
        patterns[idx].end_time = business_day.end_time
      }
      if (this.business_days.weekly_table_visible) {
          const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'hol']
          weekdays.forEach(weekday => {
            if (weekday in business_day && business_day[weekday] === '1') {
              patterns[idx][weekday] = true
              patterns[idx].total_service_time += business_day.service_time
            } else{
              patterns[idx][weekday] = false
            }
          })
      }
    })
    this.business_days.patterns = patterns
    this.business_days_columns = 1
    if (this.business_days.patterns_title !== null) {
      this.business_days_columns++
    }
    if (this.business_days.weekly_table_visible) {
      this.business_days_columns += 9
    }

    // this.shifts と this.fulltime_conversion_details を統合して表に合わせた構造に変換
    this.occupation_shifts = []
    Object.keys(this.shifts).forEach(fulltime_conversion_detail_id => {
      Object.keys(this.shifts[fulltime_conversion_detail_id]).forEach(occupation_id => {
        let days_data = []
        Object.keys(this.shifts[fulltime_conversion_detail_id][occupation_id]).forEach(day => {
          days_data[day] = {
            workpattern_name: this.shifts[fulltime_conversion_detail_id][occupation_id][day].workpattern_name,
            plan_working_hours: this.shifts[fulltime_conversion_detail_id][occupation_id][day].plan_working_hours,
            actual_working_hours: this.shifts[fulltime_conversion_detail_id][occupation_id][day].actual_working_hours
          }
        })
        this.occupation_shifts.push({
          team_name: this.fulltime_conversion_details[fulltime_conversion_detail_id].team_name,
          occupation_name: this.occupations[occupation_id].name,
          work_style: this.fulltime_conversion_details[fulltime_conversion_detail_id].work_style,
          qualification: '？',
          staff_lastname: this.fulltime_conversion_details[fulltime_conversion_detail_id].staff_lastname,
          staff_firstname: this.fulltime_conversion_details[fulltime_conversion_detail_id].staff_firstname,
          days: days_data
        })
      })
    })

    // カレンダー表示のための日情報
    let days_in_week = 0
    for (let day = 1; day <= this.days_in_month; day++) {
      days_in_week++
      const date = this.$dayjs(this.year + '-' + this.month + (day < 10 ? '-0' : '-') + day)
      this.calendar.dates.push({
        weekday: date.format('dd'),
        day: day
      })
      if (date.format('d') === '0') {
        // 週の終わり (日曜日)
        this.calendar.days_in_weeks.push(days_in_week)
        days_in_week = 0
      }
    }
    if (days_in_week !== 0) {
      this.calendar.days_in_weeks.push(days_in_week)
    }
  }
}
</script>
