<template>
  <div class="staffs_area">
    <h5>業務割合</h5>
    <p class="explain_label" style="padding: 0px;">
      常勤換算表の作成において、他社勤怠システムから取得する勤怠実績に対して入力された割合が適用されます。<br>
      職種全体で100%となるように業務割合を入力してください。<br>
      また、主務にチェックを入れた職種での業務は、他社勤怠管理システムへの連携対象となります。<br>
    </p>
    <table class="table_1 table_2">
      <tr class="table_hd_color">
        <th width="343px">事業所名</th>
        <th width="300px">職種</th>
        <th width="145px">業務割合</th>
        <th width="72px">主務</th>
      </tr>
      <!-- 事業所名、職種名、パーセンテージ、主務フラグ -->
      <template v-for="(office_staff) in office_staffs_occupation_ratio">
        <template v-for="(occupation) in office_staff.occupations" >
          <tr>
            <td width="343px">{{ getOfficeName(office_staff.office_id) }}</td>
            <td width="300px">{{ getOccupationName(occupation.id) }}</td>
            <td width="145px">
              <div class="input_row">
                <input
                  v-model="occupation.percentage"
                  type="number"
                  class="form-control"
                  min="0"
                  max="100"
                  step="5"
                  style="width:76px;"
                  @change="setTotalPercentage()"
                />
                <p style="margin: auto auto!important;">％</p>
              </div>
            </td>
            <td width="72px" style="text-align: center;">
              <input
                v-model="occupation.main_flg"
                type="radio"
                name="main_flg"
                value="1"
                style="width: 20px; height: 20px;"
                @change="changeOffOtherMainFlg(office_staff, occupation)"
              />
            </td>
          </tr>
        </template>
      </template>
    </table>
    <!-- error message -->
    <div v-if="form_errors['occupations'] && form_errors['occupations'].length">
      <p v-for="(error_occupation, i) in form_errors['occupations']" :key="i" class="invalid-feedback">
        {{ error_occupation }}
      </p>
    </div>
    <p v-if="!percentageCheck" class="invalid-feedback">職種全体で100%となるように業務割合を入力してください。</p>
    <p v-if="!mainFlgCheck" class="invalid-feedback">少なくとも一つの職種には主務フラグを設定してください。</p>
  </div>
</template>

<script>

export default {
  name: "OccupationRatio",
  props:{
    // 事業所リスト
    offices :{
      type : Object,
      required :false
    },
    // 事業所スタッフリスト
    office_staffs :{
      type : Object|Array,
      required :false
    },
    // 職種リスト
    occupations :{
      type : Object,
      required :true
    },
    errors: {
      type: Object,
      required: false,
    },
    form_errors: {
      type: Object,
      required: false,
    },
  },
  computed : {
    // 事業所リスト
    office_list () {
      return this.offices.data.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
    },
    // 職種全体の稼働率合計（office_staff.occupations.*.percentageの合計）
    totalOccupationPercentage () {
      let total = 0;
      this.office_staffs_occupation_ratio.forEach(office_staff => {
        office_staff.occupations.forEach(occupation => {
          total += Number(occupation.percentage);
        })
      })
      return total
    },
    // 職種全体の稼働率アラート
    percentageCheck () {
      return Number(this.totalOccupationPercentage) === 100 ? true : false
    },
    // 主務フラグアラート
    mainFlgCheck () {
      let mainFlg = false;
      this.office_staffs_occupation_ratio.forEach(office_staff => {
        office_staff.occupations.forEach(occupation => {
          // １つでも主務フラグがtrueの場合、mainFlgをtrueにする
          if(occupation.main_flg) {
            mainFlg = true;
          }
        })
      })
      return mainFlg
    },
    // 稼働率アラートと主務フラグアラートと稼働率アラートのチェック
    checkValidation () {
      // console.log('稼働率アラートと主務フラグアラートと稼働率アラート', this.percentageCheck, this.mainFlgCheck);
      return this.percentageCheck && this.mainFlgCheck
    },
  },
  data() {
    // console.log('【職種情報】', this.office_staffs);
    return {
      office_staffs_occupation_ratio : this.office_staffs,
    }
  },
  methods:{
    // 事業所名を取得
    getOfficeName(val) {
      return this.office_list.find(office => office.value === val)
        ? this.office_list.find(office => office.value === val).text
        : '事業所を選択してください。'
    },
    // 職種名を取得
    getOccupationName(val) {
      return this.occupations.data.find(occupation => occupation.id === val)
        ? this.occupations.data.find(occupation => occupation.id === val).name
        : '職種を選択してください。'
    },
    // 職種ごとの稼働率合計をoffice_staff.percentageにセット
    setTotalPercentage () {
      this.office_staffs_occupation_ratio.forEach(office_staff => {
        office_staff.percentage = office_staff.occupations.map(occupation => Number(occupation.percentage)).reduce((a, b) => { return a + b })
      })
    },
    // 主務フラグが選択された時に、他の事業所の他の職種の主務フラグを外す
    changeOffOtherMainFlg (office_staff, occupation) {
      this.office_staffs_occupation_ratio.forEach(office_staff_data => {
        office_staff_data.occupations.forEach(occupation_data => {
          // 他の事業所の他の職種の主務フラグを外す
          if(occupation_data.main_flg && office_staff_data.office_id !== office_staff.office_id) {
            occupation_data.main_flg = false
            return
          }

          // 同一事業所の他の職種の主務フラグを外す
          if(occupation_data.main_flg && occupation_data.id !== occupation.id) {
            occupation_data.main_flg = false
            return
          }
        })
      })
    },
  },
  watch : {
    office_staffs_occupation_ratio : {
      handler (past, value) {
        this.$emit("staff_occupations_ratio", value, this.checkValidation)
      },
      deep :true
    },
  },
  mounted () {
    // this.staff.data.office_staffからstaff_occupations_ratioへのセット
    this.office_staffs_occupation_ratio = this.office_staffs && this.office_staffs.length
      ? this.office_staffs
      : this.office_staffs_occupation_ratio

    // バリデーションをemitしておく
    this.$emit("staff_occupations_ratio", this.office_staffs_occupation_ratio, this.checkValidation)

  },
}
</script>

<style scoped>
.v-application p {
  margin-top: 0;
  margin-bottom: 0;
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #d92550;
}
</style>
