<template class="bg-white">
  <div class="bg-white w-100 h-100" style="padding: 40px;">
    <div class="app-logo" />
    <H1 style="font-size: 28px;">{{ title }}</H1>
    <div>{{ description }}</div>
    <div>必要な場合は<a href="https://synchroseries.jp/synchroshift/contact_form">こちら</a>までお問い合わせください。</div>
  </div>
</template>

<style scope>
  h1, div {
    font-size: 18px;
    line-height: 2;
  }
</style>

<script>
import Layout2 from '@/Pages/Layouts/Layout2'
export default {
  name: 'Error',
  layout: Layout2,
  props: {
    status: Number,
    message: String,
  },
  computed: {
    title() {
      return {
        503: '現在利用出来ません',
        500: 'サーバーでエラーが発生しました',
        401: 'ログインされていません',
        404: 'ページが見つかりません',
        403: '閲覧出来ないページです',
      }[this.status]
    },
    description() {
      return {
        503: '現在サーバーが利用できない状況です。しばらく経ってからアクセスしてください。',
        500: 'サーバーでエラーが発生しました。システム管理者にお問い合わせください。',
        401: '認証が必要なページです。ログインしてからアクセスしてください。',
        404: 'アクセスしようとしたページは削除されたかURLが変更されているため、見つけることが出来ませんでした。',
        403: 'アクセスしようとしたページは閲覧が許可されていません。',
      }[this.status]
    },
  },
}
</script>
