<template>
    <div>
        <button type="button" class="btn btn-secondary btn_white mr-1" @click="showAddOnCheck"
            style="padding: 0px !important; font-size: 14px; height: 34px !important; width: 120px !important;">
            <span>加算チェック</span>
        </button>
        <AddOnCheckDialog
            :add-on-check-data="addOnCheckData"
            ref="addOnCheckDialog"
        />
    </div>
</template>
<script>
import AddOnCheckDialog from '@/Pages/Shifts/Components/Dialog/AddOnCheckDialog';

export default {
    name: 'BtnAddOnCheck',
    components: {
        AddOnCheckDialog,
    },
    props: {
        addOnCheckData: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    methods: {
        showAddOnCheck() {
            this.$refs.addOnCheckDialog.openDialog();
        },
    },
    computed: {
    },
    mounted() {
    },
    created() {
    }
}
</script>
