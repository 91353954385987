<template>
  <td
    class="short-text"
    @click="showShiftChangeDialog()"
    style="text-align: center; vertical-align: middle; position: relative;"
    :style="'background-color: ' + bg_color + '; opacity:' + opacity"
    :workpattern_id="apply !== null ? apply.workpattern_id : ''"
    :holiday_workpattern_id="apply !== null ? apply.holiday_workpattern_id : ''"
    :replacement_workpattern_id="apply !== null ? apply.replacement_workpattern_id : ''"
  >
    <HopeReason 
      :hope_type="hope_type"
      :reason="apply !== null ? apply.reason : ''"
    />
    <div :class="hope_class" style="cursor: pointer;">
      <div class="workpattern-text" :title="apply_str">{{ workpattern_text }}</div>
      <div class="priority" :style="' background-color:' + priority_color">
        {{ priority }}
      </div>
    </div>

    <ShiftChangeDialog
      ref="shiftChangeDialog"
      modal_type="application"
      :work_categories="work_categories"
      :full_day="this.day.full"
      :week="this.day.w"
      @changeShift="changeShift"
    />
  </td>
</template>

<script>
import columnView from '@/Pages/Shifts/Components/Table/MainTable/Column/functions/column_view';
import HopeReason from '@/Pages/Shifts/Components/Table/MainTable/Column/HopeReason';
import ShiftChangeDialog from '@/Pages/Shifts/Components/Dialog/ShiftSelectDialog/ShiftChangeDialog';

export default {
  name: 'ShiftsApplicationStatusColumn',
  components: {
    HopeReason,
    ShiftChangeDialog,
  },
  props: {
    day: { type: Object },
    staff: {type: Object},
    holiday_count: { type: Object },
    holiday_limit: { type: Object },
    work_categories: {type: Object},
    initial_shift_apply: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      // このファイルで利用する関数
      functions: columnView,

      shift_apply: {},
    }
  },
  methods: {
    // シフト変更ダイアログ表示
    showShiftChangeDialog() {
      let main_id = null;
      let replacement_id = null;
      let entry_user_code = 'manager';
      if (this.apply !== null) {
        if ('workpattern_id' in this.apply && this.apply.workpattern_id !== null) {
          main_id = this.apply.workpattern_id;
        } else if ('holiday_workpattern_id' in this.apply && this.apply.holiday_workpattern_id !== null) {
          main_id = this.apply.holiday_workpattern_id;
        } else {
          main_id = null;
        }

        if ('replacement_workpattern_id' in this.apply) {
          replacement_id = this.apply.replacement_workpattern_id;
        } else {
          replacement_id = null;
        }

        if ('entry_user_code' in this.apply) {
          if (this.apply.entry_user_code === 'admin') {
            entry_user_code = 'manager';
          } else {
            entry_user_code = this.apply.entry_user_code;
          }
        } else {
          entry_user_code = 'manager';
        }
      }
      
      let data = {
        item: this.staff,
        main_id: main_id,
        replacement_id: replacement_id,
        entry_user_code: entry_user_code,
      };
      this.$refs.shiftChangeDialog.openDialog(data);
    },
    // シフト変更時の処理
    // user: staff情報
    // main_id, replacement_id, entry_user_code: 新しく設定した情報
    changeShift(is_changed, user, main_id, replacement_id, entry_user_code) {
      let new_apply = this.shift_apply;
      // データが変わっていないなら処理しない
      if (main_id === null && !('apply' in new_apply)) return;
      if ('apply' in new_apply && !is_changed) return;

      let apply = null;
      // もともとは未設定の場合
      if (!('apply' in new_apply)) {
        apply = {};
        apply.priorty = 0;
        apply.reason = '';
        apply.staff_id = user.staff_id;
        apply.status = 'apply';
        apply.target_date = this.day.full;
        apply.change = true;
      // もともと設定済みの場合
      } else {
        apply = new_apply.apply;
      }

      apply.change = true;
      apply.updated_by = -1;

      const working_workpatterns = user.workpatterns.work;
      const holiday_workpatterns = user.workpatterns.holiday;

      if (main_id in working_workpatterns) {
        apply.workpattern_id = main_id;
        apply.workpattern = working_workpatterns[main_id];
        apply.holiday_workpattern_id = null;
      } else if (main_id in holiday_workpatterns) {
        apply.holiday_workpattern_id = main_id;
        apply.workpattern = holiday_workpatterns[main_id];
        apply.workpattern_id = null;
      } else {
        apply.workpattern_id = null;
        apply.holiday_workpattern_id = null;
        apply.workpattern = null;
      }

      if (replacement_id !== null) {
        apply.replacement_workpattern_id = replacement_id;
        apply.replacement_workpattern = working_workpatterns[replacement_id];
      } else {
        apply.replacement_workpattern_id = null;
        apply.replacement_workpattern = null;
      }

      if (entry_user_code !== null && entry_user_code !== undefined) {
        apply.entry_user_code = entry_user_code;
      }

      this.$set(this.shift_apply, 'apply', apply);
      this.$emit('updateApplicationStatus', {
        is_changed: true,
        day: this.day,
        user: user,
        shift_apply: this.shift_apply,
      });
    },

    // シフトデータの最新化
    updateShiftApply(new_data) {
      // 初期データ設定
      if (new_data !== null) {
        Object.keys(this.shift_apply).forEach(key => {
          this.$delete(this.shift_apply, key)
        });
        for (let key in new_data) {
          this.$set(this.shift_apply, key, new_data[key]);
        }
      }
    },
  },
  computed: {
    // 現在の申請データ
    apply() {
      if (this.shift_apply === null || this.shift_apply === undefined || !('apply' in this.shift_apply)) {
        return null;
      } else {
        return this.shift_apply['apply'];
      }
    },
    // 申請中シフトの文字列
    apply_str() {
      // データが存在しない場合は空文字を返却
      if (this.apply === null) return '';

      let workpattern = this.apply.workpattern;
      if (workpattern === null) {
        return '';
      }
      if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) {
        return '削除';
      }

      return workpattern.name;
    },
    // シフト希望枠の背景色
    bg_color() {
      let bg = '';

      for (let day in this.holiday_count) {
        // day は yyyy-mm-dd の形式なのでday.slice(8)はdd
        if (this.holiday_count[day] > this.holiday_limit.holiday[day.slice(8)]) {
          bg = '#800080';
        } else {
          bg = '#ffffff';
        }
      }

      if (bg === '#ffffff') {
        if (this.apply !== null) {
          if ('change' in this.apply && this.apply.change) {
            return '#ff82b4';
          } else {
            return this.apply.color;
          }
        } else {
          return '#ffffff';
        }
        return '#ffffff';
      } else {
        return bg;
      }
    },
    // シフト希望枠の透過度
    opacity() {
      if ('all_approval' in this.shift_apply) {
        return this.shift_apply.all_approval ? '0.6' : '1';
      } else {
        return '1';
      }
    },
    // 希望バッジの有無判定に利用
    // 0:initial 1:希望休（公休） 2:希望休（有休） 3:勤務
    hope_type() {
      return this.functions.getHopeType(this.apply, 'application');
    },
    // 勤務区分の表示文字取得
    workpattern_text() {
      return this.functions.getWorkpatternText(this.apply_str);
    },
    // 申請データのclass
    hope_class() {
      let workpattern = null;
      let replacement_workpattern = null;
      let class_name = 'workpattern_shift_base';

      if (this.apply === null) return '';

      workpattern = this.apply.workpattern;
      if (workpattern === null) return '';
      if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return '';

      // 有休（全休）
      if (workpattern.type === 'paid_holidays') {
        class_name = class_name + ' workpattern_hope_paid';
      // 公休（全休）
      } else if (workpattern.type === 'public_holidays') {
        class_name = class_name + ' workpattern_hope_off';
      // 半休
      } else if (workpattern.category === 'holiday') {
        replacement_workpattern = this.apply.replacement_workpattern;
        // ワークパターン色（クラス）設定
        if (replacement_workpattern === null) {
          if (workpattern.type === 'paid_half_holiday') {
            class_name = class_name + ' workpattern_hope_paid';
          } else if(workpattern.type === 'public_half_holiday') {
            class_name = class_name + ' workpattern_hope_off';
          } else {
            class_name = class_name + ' workpattern_hope_off';
          }
        } else {
          class_name = class_name + ' workpattern_' + replacement_workpattern.category;
        }
      } else {
        class_name = class_name + ' workpattern_hope_off';
      }

      // 希望優先順位
      if (this.apply.priority !== null && this.apply.priority !== '' && this.apply.priority !== undefined) {
        class_name = class_name + ' priority_color';
      }

      return class_name;
    },
    // 優先順位の表示
    priority() {

      let workpattern = null;
      let priority = '';

      if (this.apply === null) return '';

      workpattern = this.apply.workpattern;
      if (workpattern === null) return '';
      if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return '';

      if (this.apply.priority !== null && this.apply.priority !== '' && this.apply.priority !== undefined) {
        priority = this.apply.priority;
      }

      return priority;
    },
    // 優先順位表示の色
    priority_color() {
      return this.functions.getPriorityBgColor(this.apply, 'application');
    },
  },
  watch: {
    initial_shift_apply: {
      handler(new_data, old_data) {
        this.updateShiftApply(structuredClone(new_data));
      },
      deep: true,
    }
  },
  created() {
  },
  mounted() {
    this.updateShiftApply(structuredClone(this.initial_shift_apply));
  },
  beforeDestroy() {
  },
  destroyed () {
  },
}
</script>
