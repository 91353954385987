<template>
  <div class="d-flex">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div style="display: flex;">
          <!-- 通知 -->
          <v-menu v-model="open" nudge-bottom="10" :offset-y="offset" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" class="alert-icon">
                <span class="alert-circle">
                  <v-icon large style="color: #FFFFFF;">pe-7s-date</v-icon>
                </span>
                <span v-if="new_count !== 0" class="alert-count-circle">
                  <!-- 通知数 -->
                   {{ new_count }}
                </span>
              </button>
            </template>
            <div class="alert-menu-header">
              <v-autocomplete
                v-model="selectedOffice"
                :items="office_list"
                class="select_style select_notice"
                style="min-width: 360px; max-width: 360px; margin: 0px; margin-bottom: 10px!important;; height: 42px; border-radius: 8px;"
                item-text="name"
                item-value="id"
                hide-details="true"
                placeholder="事業所を選択してください"
                no-data-text="事業所が見つかりません。"
                dense
                filled
                return-object
                clearable
              ></v-autocomplete>
              <div style="display: flex; justify-content: space-between;">
                <v-select
                  v-model="selectedYearMonth"
                  :items="year_month_list"
                  class="select_style select_notice"
                  style="min-width: 150px; max-width: 150px; margin: 0px; height: 42px; border-radius: 8px;"
                  item-text="name"
                  item-value="id"
                  hide-details="true"
                  placeholder="年月"
                  no-data-text="年月が見つかりません。"
                  dense
                  filled
                  return-object
                  clearable
                ></v-select>
                <v-select
                  v-model="selectedStatus"
                  :items="status_list"
                  class="select_style select_notice"
                  style="width: 205px; max-width: 205px; margin: 0px; height: 42px; border-radius: 8px;"
                  item-text="name"
                  item-value="id"
                  hide-details="true"
                  placeholder="ステータス"
                  no-data-text="ステータスが見つかりません。"
                  dense
                  filled
                  return-object
                  clearable
                ></v-select>
              </div>
            </div>
            <div class="scroll-area-xs alert-container">
              <VuePerfectScrollbar class="scrollbar-container" style="height:auto; max-height: calc(100vh - 500px);">
                <ul class="alert-list">
                  <li v-for="item in notice_list" :key="item.id" :class="[item.new ? 'alert-item alert-completion' : 'alert-item']">
                    <div class="alert-message">
                      <div>
                        <p v-if="item['status'] === 0" class="alert-item-title">シフトの自動作成を<span class="alert-item-status-ok">実行中</span>です</p>
                        <p v-if="item['status'] === 2" class="alert-item-title">シフトの自動作成が<span class="alert-item-status-ok">完了</span>しました（{{ item['update_at'] }}）</p>
                        <p v-if="item['status'] === 4" class="alert-item-title">シフトの自動作成を<span class="alert-item-status-error">中断</span>しました（{{ item['update_at'] }}）</p>
                        <p v-if="item['status'] === 10" class="alert-item-title">シフトの自動作成に<span class="alert-item-status-error">失敗</span>しました（{{ item['update_at'] }}）</p>
                        <div>{{ item['year_month'] + ' ' + item['office_name'] + ' ' + item['team_name']}}<font-awesome-icon class="alert-item-link" icon="fa-arrow-up-right-from-square" @click="moveToShift(item['office_id'], item['include_year_month'])" /></div>
                      </div>
                      <div v-if="item['status'] === 0">
                        <button class="alert-bottom-stop" @click="autoShiftCancel(item['office_id'], item['include_year_month'])">中断</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </VuePerfectScrollbar>
            </div>
          </v-menu>
          <!-- アカウント -->
          <v-menu nudge-bottom="10" :offset-y="offset">
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" style="font-size: 14px; color: #FFFFFF; margin: 0px 20px;">
                {{ username }}
              </button>
            </template>
            <div class="dropdown-menu-header" style="margin-bottom: 0px!important;">
              <div class="dropdown-menu-header-inner bg-info">
                <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                <div class="menu-header-content text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <img width="42" class="rounded-circle" :src="$url('/images/avatar.jpg')" alt />
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{ username }}</div>
                        <div class="widget-subheading opacity-8">{{ userrole }}</div>
                      </div>
                      <div class="widget-content-right mr-2">
                        <button class="btn-pill btn-shadow btn-shine btn btn-focus" @click="logout">ログアウト</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="scroll-area-xs" style="background: #FFFFFF; height: 75px;">
              <VuePerfectScrollbar class="scrollbar-container" v-once>
                <ul class="nav flex-column">
                  <li class="nav-item-header nav-item">アカウントの管理</li>
                  <li class="nav-item">
                    <a :href="$url('/profiles')" class="nav-link">設定</a>
                  </li>
                </ul>
              </VuePerfectScrollbar>
            </div>
          </v-menu>
          <!-- ログアウト -->
          <button class="btn_logout" @click="logout">ログアウト</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alert-icon {
  position: relative;
  color: #FFFFFF; 
}
.alert-circle {
  display: grid;
  color: #FFFFFF;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  background: #E5528C;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.alert-circle .v-icon {
  font-size: 30px!important;
}
.alert-count-circle {
    position: absolute;
    display: grid;
    color: #FFFFFF;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    background: #02B28A;
    border-radius: 50%;
    top: 0;
    right: -5px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
}
.alert-container {
  width: 380px;
  min-width: 380px;
  max-width: 380px;
  height: auto!important;
  max-height: 70vh!important;
  max-width: 580px;
  background: #FFFFFF;
}
.alert-list {
  margin: 0px;
}
.alert-list li+ li {
  border-top: 1px solid #D2D6DB;
}
.alert-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  list-style: none;
  line-height: 1.8;
  padding: 18px;
}
.alert-menu-header {
  background: #EFFAF4;
  padding: 10px;
}
.alert-item-title {
  color: #707070;
  font-size: 15px;
  margin-bottom: 0px!important;
}
.alert-item-status-ok {
  color: #02B28A;
  padding: 0px 5px;
}
.alert-item-status-error {
  color: #F04438;
  padding: 0px 5px;
}
.alert-item-link {
  color: #C9C9C9;
  padding: 0px 5px;
  cursor: pointer;
}
.alert-bottom-stop {
  color: #F04438;
  min-width: 56px;
  height: 40px;
  border: 1px solid #D2D6DB;
  border-radius: 5px;
  box-shadow: 0px 3px 0px #0000000F;
  margin-left: 12px;
}
.alert-completion {
  background: #FFFEEB;
}
.select_notice {
  padding-left: 0px!important;
  padding-right: 0px!important;
}
</style>

<style>
.select_notice .v-input__control .v-input__slot {
  padding-left: 5px!important;
  padding-right: 5px!important;
}
</style>

<script>
import axios from "axios"
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueCircle from "vue2-circle-progress";
import Trend from "vuetrend";
import { Inertia } from '@inertiajs/inertia';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon,
    VueCircle,
    trend: Trend,
    Inertia,
  },
  data: () => ({
    fill1: { gradient: ["#00b09b", "#96c93d"] },
    fill2: { gradient: ["#ff0844", "#ffb199"] },
    fill3: { gradient: ["#f6d365", "#fda085"] },
    showDrawerSection: false,
    offset: true,
    open: false,
    selectedOffice: '',
    selectedYearMonth: '',
    selectedStatus: '',
    notice_list: null
  }),
  props: {
    codes :{
      type : Object,
      required : false
    },
  },
  methods: {
    logout() {
      this.$cookie.delete("offices_list");
      this.$cookie.delete("staffs_list");
      this.$cookie.delete("teams_list");
      this.$cookie.delete("workpatterns_list");
      this.$cookie.delete("positions_list");
      this.$cookie.delete("shifts_list");
      this.$cookie.delete("fulltime_conversions_list");
      this.$cookie.delete("oura_list");
      this.$cookie.delete("bulk_add");
      this.$cookie.delete("sideber_open");
      Inertia.post(this.$url('/logout'));
    },

    changeSearchDropDown(is_loading) {

      if (!is_loading) {
        this.updateLoading(true);
      }
      axios.post(
        this.$route('header-notice.list', {
          office_id: this.selectedOffice === null ? '' : this.selectedOffice['id'],
          year_month: this.selectedYearMonth === null ? '' : this.selectedYearMonth['id'],
          status: this.selectedStatus === null ? '' : this.selectedStatus['id']
        })
      ).then((result) => {
        this.notice_list = result.data.data.notice_list;
        this.$page.props.new_count = result.data.data.new_count;
        this.notice_list.splice();
        this.$forceUpdate();
        this.updateLoading(false);
      });
    },

    autoShiftCancel(office_id, year_month) {
      this.updateLoading(true);
      axios.post(
        this.$route('header-notice.cancel', {
          office_id: office_id,
          year_month: year_month,
        })
      ).then((result) => {
        this.changeSearchDropDown(true);
        this.updateLoading(false);
        this.open = false;
        if (result.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'シフト自動作成を中断しました。',
          });
        } else {
          this.$swal({
            icon: 'error',
            title: 'シフト自動作成の中断に失敗しました。',
          });
        }
      }).catch(error => {
        this.updateLoading(false);
        this.open = false;
        this.$swal({
            icon: 'error',
            title: 'シフト自動作成の中断に失敗しました。',
          });
      });
    },

    moveToShift(office_id, year_month) {
      this.open = false;
      let year = year_month.substring(0, 4);
      let month = year_month.substring(4);
      this.$inertia.get(this.$route('shifts.schedule', {
        year: year,
        month: month,
        office: office_id
      }));
    },

    updateLoading(flg) {
        EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
    user() {
      return this.$page.props.auth.user;
    },
    roles() {
      return this.$page.props.roles;
    },
    username() {
      if (this.user.staff === null) {
        return this.user.name;
      } else {
        return this.user.staff.lastname + ' ' + this.user.staff.firstname;
      }
    },
    userrole() {
      if (this.user.role !== null) {
        return this.roles[this.user.role].ja;
      } else {
        return '';
      }
    },
    // 事業所リスト
    office_list () {
      let result = [];
      let list = [];
      if (Array.isArray(this.$page.props.offices)) {
        list = this.$page.props.offices;
      } else {
        list = this.$page.props.offices.data;
      }
      for (const item in list) {
        result.push({ id:list[item].id, name:list[item].name});
      }
      return result;
    },
    // 年月リスト
    year_month_list () {
      return this.$page.props.year_month_list;
    },
    // ステータスリスト
    status_list () {
      return this.$page.props.status_list;
    },
    // 新着件数
    new_count() {
      return this.$page.props.new_count;
    }

  },
  watch: {
    open:function(val) {
      if (!val) {
        return;
      }
      this.updateLoading(true);
      axios.post(
        this.$route('header-notice.list', {
          office_id: this.selectedOffice === null ? null : this.selectedOffice['id'],
          year_month: this.selectedYearMonth === null ? null : this.selectedYearMonth['id'],
          status: this.selectedStatus === null ? null : this.selectedStatus['id']
        })
      ).then((result) => {
        this.notice_list = result.data.data.notice_list;
        this.$page.props.new_count = result.data.data.new_count;
        this.notice_list.splice();
        this.$forceUpdate()
        this.updateLoading(false);
      });
    },

    selectedOffice:function(val) {
      this.changeSearchDropDown(false);
    },

    selectedYearMonth:function(val) {
      this.changeSearchDropDown(false);
    },

    selectedStatus:function(val) {
      this.changeSearchDropDown(false);
    },
  },
  mounted() {
  },
};
</script>
