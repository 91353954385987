<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div class="h-100">
        <b-row class="h-100 no-gutters">
          <MySlick />
          <b-col lg="8" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <b-col lg="9" md="10" sm="12" class="mx-auto app-login-box">
              <div class="app-logo" />
              <div class="divider" />
              <div>
                <form @submit.prevent="submit">
                  <div>
                    <b-form-group>
                      <Label for="exampleEmail">メールアドレスまたは社員番号</Label>
                      <b-form-input type="text" v-model="form.account" placeholder="メールアドレスまたは社員番号を入力してください。" />
                    </b-form-group>
                  </div>
                  <div>
                    <b-form-group>
                      <Label for="examplePassword">パスワード</Label>
                      <b-form-input type="password" v-model="form.password" placeholder="パスワードを入力してください。" />
                    </b-form-group>
                  </div>
                  <b-form-checkbox name="check" v-model="form.remember">ログイン情報保存</b-form-checkbox>
                  <div class="divider" />
                  <div class="d-flex align-items-center">
                    <div class="ml-auto">
                      <Link :href="$url('forgot-password')" class="btn-lg btn btn-link"> パスワードをお忘れですか？ </Link>
                      <b-button type="submit" variant="primary" size="lg" :disabled="form.processing">ログイン</b-button>
                    </div>
                  </div>
                </form>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue'
import Layout2 from '@/Pages/Layouts/Layout2'
import MySlick from "@/Pages/Components/MySlick"
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

export default {
  name: 'Login',
  layout: Layout2,
  components: {
    Link,
    MySlick
  },
  data: () => ({
    form: Inertia.form({
      account: null,
      password: null,
      remember: null,
    }),
  }),

  methods: {
    submit() {
      this.$cookie.set("offices_list", 50, 1);
      this.$cookie.set("staffs_list", 50, 1);
      this.$cookie.set("teams_list", 50, 1);
      this.$cookie.set("workpatterns_list", 50, 1);
      this.$cookie.set("positions_list", 50, 1);
      this.$cookie.set("shifts_list", 50, 1);
      this.$cookie.set("fulltime_conversions_list", 50, 1);
      this.$cookie.set("oura_list", 50, 1);
      this.$cookie.set("bulk_add", 50, 1);
      this.$cookie.set("sideber_open", -1, 1);

      this.form.transform(data => ({
          ...data,
          remember: data.remember ? 'on' : '',
      })).post(this.$url('/login'), {
          onFinish: () => this.form.reset('password'),
      });
    },
  },
};
</script>
