<template>
  <div style="display: flex;">
    <div v-if="configProgress > 0" style="color: #FFFFFF; font-size: 16px;">
      <font-awesome-icon icon="exclamation-circle" style="margin-right: 5px;" />まずは初期設定をしましょう！(あと {{ configProgress }} つ)
      <button class="btn_confirm" @click="openToDo">確認する</button>
    </div>
    <div v-if="checkFreeTrialEnd" style="color: #FFFFFF; font-size: 16px; padding-left: 10px;">
      <font-awesome-icon icon="exclamation-circle" style="margin-right: 5px;" />{{ freeTrialEndMessage }}
      <button class="btn_confirm" @click="openLodgments">変更する</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    status_str: { type: String },
  },
  data: function() {
    return {
      configProgress: 0,
      checkFreeTrialEnd: false,
      freeTrialEndMessage: '',
    }
  },
  mounted: function() {
    let self = this
    axios
      .get(this.$route('onboarding.status'))
      .then(function(response) {
        let count = 0;
        for(let key in response.data) {
          // TODO 半休対応（S3以降）
          key !== 'check_halfwork' && response.data[key] === false ? count += 1 : count +=0;
        }
        self.configProgress = count
      });

    // plan_notice_typeを取得
    let plan_notice_type = this.$page.props.plan_notice_type;

    if (plan_notice_type === 'trail_end_within_one_month') {
      // 無料体験終了日を取得してメッセージ表示
      this.checkFreeTrialEnd = true;
      let trial_end_date = this.$page.props.trial_end_date;
      this.freeTrialEndMessage = '無料体験の終了日（' + trial_end_date + '）が近づいています！';
    } else if (plan_notice_type === 'after_trial_end') {
      this.checkFreeTrialEnd = true;
      this.freeTrialEndMessage = '無料体験期間が終了しました';
    } else {
      this.checkFreeTrialEnd = false;
    }
  },
  methods: {
    // ページ遷移
    openToDo() {
      location.href= this.$url("/onboarding");
    },
    // 拠点管理画面に遷移
    openLodgments() {
      location.href= this.$url("/lodgments/edit");
    },
  },
};
</script>
