<template>
  <div style="font-size: 14px;">
    <h4 style="font-weight: 600; padding-bottom: 26px;">ご解約手続き</h4>
    <p>シンクロシフトの解約を行います。</p>
    <Form @submit.prevent="cancelAlert">
      <div class="notes">
        <p style="font-size: 18px; font-weight: 600; color:#D61515;"><font-awesome-icon icon="circle-exclamation" />利用料金個別規約 - スタンダードプラン（有料プラン）から解約（機能制限無料プランへ移行）する場合の注意事項</p>
        <p>　※日割計算等による料金の払い戻しは行っておりません。</p>
        <p>・解約後、一定期間ご利用実績がない場合はアカウントの削除を行います。</p>
        <p>　※アカウント削除後に再度契約される場合でも、データの復旧はできません。</p>
      </div>
      <div class="agreement">
        <v-checkbox v-model="agreement" label="規約に同意する" />
      </div>
      <div class="lodgments_area" :class="agreement ? '' : 'agreement_off'">
        <h5>ご解約フォーム</h5>
        <div class="input_row">
          <label for="zoho_name">法人名</label>
          <div>
            <input type="text" class="form-control long_control" v-model="form.zoho_name" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="zoho_zip">郵便番号</label>
          <div>
            <input type="text" v-model="form.zoho_zip" class="form-control long_control" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="pref">都道府県</label>
          <div>
            <b-form-select id="pref" v-model="form.pref" 
              :options="pref_code"
              value-field="code"
              text-field="ja"
              disabled>
            </b-form-select>
          </div>
        </div>
        <div class="input_row">
          <label for="full_address">市町村</label>
          <div>
            <input type="text" class="form-control long_control" v-model="form.Billing_City" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="block_number">番地</label>
          <div>
            <input type="text" class="form-control long_control" v-model="form.Billing_Street" disabled>
          </div>
        </div>
<!--
        <div class="input_row">
          <label for="building">建物名</label>
          <div>
            <input type="text" class="form-control long_control" disabled>
          </div>
        </div>
-->
        <div class="input_row">
          <label for="PIC" class="required">ご担当者様氏名</label>
          <div>
            <input
              type="text"
              v-model="form.PIC"
              maxlength="255"
              class="form-control"
              :class="{'is-invalid': errors.PIC}"
              :disabled="!agreement"
              @blur="checkPic"
            />
            <div v-if="errors.PIC" class="invalid-feedback">{{ errors.PIC }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="" class="required">メールアドレス</label>
          <div>
            <input
              type="text"
              v-model="form.contact_mail"
              class="form-control long_control"
              maxlength="125"
              :class="{'is-invalid': errors.contact_mail}"
              :disabled="!agreement"
              @blur="checkContactMail"
              required
            />
            <div v-if="errors.contact_mail" class="invalid-feedback">{{errors.contact_mail}}</div>
            <p>※解約完了メールの送付先メールアドレスです</p>
          </div>
        </div>
        <div class="input_row">
          <label for="contact_phone" class="required">電話番号</label>
          <div>
            <input type="text"
              v-model="form.contact_phone"
              class="form-control"
              maxlength="13"
              :class="{'is-invalid': errors.contact_phone}"
              :disabled="!agreement"
              @blur="checkContactPhone"
              required
            />
            <div v-if="errors.contact_phone" class="invalid-feedback">{{errors.contact_phone}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="Termination_Date" class="required">解約日</label>
          <div class="date_width" :class="{'input-error': errors.Termination_Date}">
            <datepicker
              v-model="form.Termination_Date"
              id="Termination_Date"
              name="Termination_Date"
              check_item_name="解約日"
              :validation_check="false"
              :disabled="!agreement"
              @date="setTermination_Date"
              @focusout="checkTerminationDate"
            />
            <div v-if="errors.Termination_Date" class="invalid-feedback">{{ errors.Termination_Date }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="">最終請求日</label>
          <div>
            <input type="text" class="form-control" style="font-size: 14px;" v-model="form.last_payday" disabled>
          </div>
        </div>
      </div>
      <div class="lodgments_area" style="margin-bottom: 12px!important;" :class="agreement ? '' : 'agreement_off'">
        <h5>アンケート</h5>
        <div class="input_row">
こちらよりアンケート（<a href="https://survey.synchroshift.jp/zs/DYzRvS" target="_blank">https://survey.synchroshift.jp/zs/DYzRvS</a>）にご協力ください。
<!--
          <label for="questionnaire" class="required">解約理由</label>
          <div>
            <div class="questionnaire">
              <v-checkbox v-model="questionnaire" label="機能が不足している" value="1" :disabled="!agreement" />
              <v-checkbox v-model="questionnaire" label="料金が高い" value="2" :disabled="!agreement" />
              <v-checkbox v-model="questionnaire" label="サポートが不十分" value="3" :disabled="!agreement" />
              <v-checkbox v-model="questionnaire" label="見た目が悪い" value="4" :disabled="!agreement" />
              <v-checkbox v-model="questionnaire" label="使い勝手が悪い" value="5" :disabled="!agreement" />
              <v-checkbox v-model="questionnaire" label="その他" value="6" :disabled="!agreement" />
            </div>
            <input type="text" class="form-control long_control" :disabled="!agreement" placeholder="具体的にご記入ください">
            <div v-if="errors.questionnaire" class="invalid-feedback">{{ errors.questionnaire }}</div>
            <p style="margin-top: 12px; margin-bottom: 2px;">その他シンクロシフトに関してのご意見をお聞かせください。</p>
            <textarea class="form-control long_control" :disabled="!agreement"></textarea>
          </div>
-->
        </div>
      </div>
      <div class="consent">
        <v-checkbox v-model="privacy" :disabled="!agreement" /><label for="privacy" :disabled="!agreement"><a href="https://hp.syncube.co.jp/privacypolicy.html" target="_blank" rel="noopener noreferrer">プライバシーポリシーに同意する</a></label>
      </div>
      <button type="button" class="btn btn-primary btn-lg" :disabled="!isDisabled" @click="cancelAlert">送信</button>
    </Form>
  </div>
</template>

<style scope>
.invalid-feedback {
  display: initial;
}
.date_width .v-input__control {
  width: 274px!important;
}
.date_width .v-input__slot {
  padding: 0px!important;
}
.input-error .v-input__control {
  border: solid 1px #d92550;
}
.notes {
  width: 100%;
  height: auto;
  background: #FFEDEB 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 18px 27px;
}
.agreement {
  display: flex;
  justify-content: center;
}
.agreement_off {
  opacity: 0.5;
}
.questionnaire .v-messages {
  display: none;
}
.questionnaire .v-input {
  margin: 0px;
}
.consent {
  display: flex;
}
.consent label {
  display: grid;
  align-items: center;
}
</style>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";
import moment from "moment";

export default {
  components: {
    datepicker
  },
  props: {
    base:Object,
    customer:Object,
    pref_code:Array,
    plan_code:Array,
    apikey:String,
  },
  data() {
    return {
      form: Inertia.form(this.base),
      errors: {},
      agreement: false,
      questionnaire: [],
      checkPicFlg: true,
      checkContactMailFlg: true,
      checkContactPhoneFlg: true,
      checkTerminationDateFlg: true,
      checkQuestionnaireFlg: false,
      privacy: false,
    };
  },
  computed: {
    isDisabled() {
      return this.checkPicFlg
          && this.checkContactMailFlg
          && this.checkContactPhoneFlg
          && this.checkTerminationDateFlg
          && this.privacy
    },
  }, 
  watch: {
    'form.Termination_Date': {
      handler(val, oldval) {
        this.$delete(this.errors, 'Termination_Date')
        console.log(val);
        if (val=='' || val==null || val=="Invalid Date" || val=="Invalid date") {
          console.log(val);
          var today = new Date();
          today.setHours(0);
          today.setMinutes(0);
          today.setSeconds(0);
          today.setMilliseconds(0);
          var changed = new Date(val);
          if (today.getTime() > changed.getTime()){
          }else if(this.form.last_payday!="無料プラン" && this.form.last_payday!="支払済み"){
            var payday = new Date(this.form.last_payday);
            var changedMonth = changed.getFullYear() * 12 + changed.getMonth();
            var paydayMonth = payday.getFullYear() * 12 + payday.getMonth();
            console.log(changedMonth - paydayMonth);
            var plusMonth = changedMonth - paydayMonth;
            payday.setMonth(payday.getMonth() + plusMonth);
            if(payday.getTime() < changed.getTime()){
                payday.setMonth(payday.getMonth() + 1);
            }
            this.form.last_payday = moment(payday).format('YYYY/MM/DD');
          }
        }
        // 解約日の翌月1日を最終請求日に設定する
        if (this.form.Termination_Date == '' || this.form.Termination_Date == null || this.form.Termination_Date == undefined || this.form.Termination_Date.toLowerCase() == 'invalid date') {
          this.form.last_payday = null;
        } else {
          // 翌月の1日を設定
          let getDate = this.form.Termination_Date.replace(/\//g, '-');
          let endDate = new Date(getDate);
          endDate.setDate(1);
          endDate.setMonth(endDate.getMonth() + 1);

          // yyyy/mm/dd形式にする
          let year = endDate.getFullYear();
          let month = String(endDate.getMonth() + 1).padStart(2, '0');
          let day = String(endDate.getDate()).padStart(2, '0');
          this.form.last_payday = `${year}/${month}/${day}`;
        }
      },
      immediate: true
    },
    'questionnaire'(questionnaire,questionnaire2) {
      if (questionnaire.length < 1) {
        this.checkQuestionnaireFlg = false;
        this.$set(this.errors, 'questionnaire', '解約理由を選択してください。');
      } else {
        this.checkQuestionnaireFlg = true;
        this.$delete(this.errors, 'questionnaire');
      }
    },
  },
  mounted() {
    this.checkNull(false);
    this.updateLoading(false);
  },
  updated() {
  },
  methods: {
    setTermination_Date(date) {
      this.form.Termination_Date = moment(date).format('YYYY/MM/DD')
      this.checkTerminationDate();
    },
    checkPic() {
      this.checkPicFlg = true;
      this.$delete(this.errors, 'PIC');
      if (this.form.PIC == '' || this.form.PIC == undefined || this.form.PIC == null) {
        this.checkPicFlg = false;
        this.$set(this.errors, 'PIC', 'ご担当者様氏名を入力してください。');
        console.log('%o',this.errors);
      }
    },
    checkContactMail() {
      this.checkContactMailFlg = true;
      this.$delete(this.errors, 'contact_mail');
      if (this.form.contact_mail != '' && this.form.contact_mail != undefined && this.form.contact_mail != null) {
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(this.form.contact_mail)) {
          this.checkContactMailFlg = false;
          this.$set(this.errors, 'contact_mail', 'メールアドレスの形式が正しくありません。');
        }
      } else {
        this.checkContactMailFlg = false;
        this.$set(this.errors, 'contact_mail', 'メールアドレスを入力してください。');
      }
    },
    checkContactPhone() {
      this.checkContactPhoneFlg = true;
      this.$delete(this.errors, 'contact_phone');
      if (this.form.contact_phone == '' || this.form.contact_phone == undefined || this.form.contact_phone == null) {
        this.checkContactPhoneFlg = false;
        this.$set(this.errors, 'contact_phone', '電話番号を入力してください。');
      } else {
        if (!/^[0-9-]*$/.test(this.form.contact_phone)) {
          this.checkContactPhoneFlg = false;
          this.$set(this.errors, 'contact_phone', '電話番号の形式が正しくありません。');
        }
      }
    },
    checkTerminationDate() {
      this.checkTerminationDateFlg = true;
      this.$delete(this.errors, 'Termination_Date');
      if (this.form.Termination_Date == '' || this.form.Termination_Date == null) {
        this.checkTerminationDateFlg = false;
        this.$set(this.errors, 'Termination_Date', '解約日を入力してください。');
      } else if (this.form.Termination_Date == "Invalid Date" || this.form.Termination_Date == "Invalid date") {
        this.checkTerminationDateFlg = false;
        this.$set(this.errors, 'Termination_Date', '解約日の形式が正しくありません。');
      } else {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        let changed = new Date(this.form.Termination_Date);
        if (today.getTime() > changed.getTime()) {
          this.checkTerminationDateFlg = false;
          this.$set(this.errors, 'Termination_Date', '解約日に過去日は設定できません。');
        } else if(today.getTime() == changed.getTime()) {
          this.checkTerminationDateFlg = false;
          this.$set(this.errors, 'Termination_Date', '解約日に当日は設定できません。');
        }
        // 翌月最終営業日以前のみ許可
        let CheckTermination_Date = new Date(this.base.CheckTermination_Date);
        if (CheckTermination_Date < changed.getTime()) {
          this.checkTerminationDateFlg = false;
          this.$set(this.errors, 'Termination_Date', '解約日は翌月最終営業日までの日付を設定してください。');
        }
      }
    },
    checkNull(output) {
      // 必須チェック
      // メールアドレス
      if (this.form.contact_mail == '' || this.form.contact_mail == undefined || this.form.contact_mail == null) {
        this.checkContactMailFlg = false;
        if (output) this.$set(this.errors, 'contact_mail', 'メールアドレスの形式が正しくありません。');
      }
      // 電話番号
      if (this.form.contact_mail == '' || this.form.contact_mail == undefined || this.form.contact_mail == null) {
        this.checkContactPhoneFlg = false;
        if (output) this.$set(this.errors, 'contact_phone', '電話番号を入力してください。');
      }
      // 解約日
      if (this.form.Termination_Date == '' || this.form.Termination_Date == null || this.form.Termination_Date == "Invalid Date" || this.form.Termination_Date == "Invalid date") {
        this.checkTerminationDateFlg = false;
        if (output) this.$set(this.errors, 'Termination_Date', '解約日を入力してください。');
      }
      // 解約理由
      if (this.questionnaire.length < 1) {
        this.checkQuestionnaireFlg = false;
        if (output) this.$set(this.errors, 'questionnaire', '解約理由を選択してください。');
      }
    },
    save_check() {
      this.checkNull(true);

      return this.checkContactMailFlg
          && this.checkContactPhoneFlg
          && this.checkTerminationDateFlg
          && this.privacy
    },
    cancelAlert() {
      if (this.save_check()) {
        this.$swal({
          text: "解約しますか？",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '解約',
          cancelButtonText: 'キャンセル',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateLoading(true);
            this.form.post(route('lodgments.plan-cancel'))
          }
        });
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
};
</script>
