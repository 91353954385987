<template>
  <div class="office_area">
    <div v-for="(external_kintai, index) in office_external_kintai" :key="index" style="width: 100%;">
      <div v-show="external_kintai.enable_flg !== false" class="office-apiinfo-area">
        <div class="btn_left_area">
          <b-button v-if="index > 0" class="btn_add" @click="removeExternalKintai(index)"><i class="pe-7s-trash"></i>外部連携の削除</b-button>
        </div>
        <div class="input_row">
          <label for="external_kintai_id">外部連携先名</label>
          <div>
            <v-autocomplete
              v-model="external_kintai.external_kintai_id"
              :items="external_kintai_name_list"
              class="office-apiinfo-area-autocomplete apiinfo-control"
              :class="{ 'is-invalid': errors.external_kintai_id && errors.external_kintai_id[index] }"
              item-value="id"
              item-text="name"
              hide-details="true"
              no-data-text="外部連携先が見つかりません。"
              dense
              filled
              @input="deleteExternalKintaiIdErrors()"
              @change="checkExternalKintaiId(index, external_kintai.external_kintai_id)"
            ></v-autocomplete>
            <div v-if="errors.external_kintai_id && errors.external_kintai_id[index]" class="invalid-feedback">
              {{ errors.external_kintai_id[index] }}
            </div>
            <div v-if="backend_errors['office_external_kintai.' + index + '.external_kintai_id']" class="invalid-feedback">
              {{ backend_errors['office_external_kintai.' + index + '.external_kintai_id'] }}
            </div>
          </div>
        </div>
        <div class="input_row">
          <label for="external_link_id">外部連携先事業所ID</label>
          <div>
            <input
              v-model="external_kintai.external_link_id"
              name="external_link_id"
              type="text"
              class="form-control apiinfo-control"
              :class="{ 'is-invalid': errors.external_link_id && errors.external_link_id[index] || errors.external_link_id_duplicate && errors.external_link_id_duplicate[index] }"
              placeholder=""
              @input="checkExternalLinkId(index, external_kintai.external_link_id)"
            />
            <div v-if="errors.external_link_id && errors.external_link_id[index]" class="invalid-feedback">
              {{ errors.external_link_id[index] }}
            </div>
            <div v-if="errors.external_link_id_duplicate && errors.external_link_id_duplicate[index]"
              class="invalid-feedback">
              {{ errors.external_link_id_duplicate[index] }}
            </div>
            <div v-if="backend_errors['office_external_kintai.' + index + '.external_link_id']" class="invalid-feedback">{{ backend_errors['office_external_kintai.' + index + '.external_link_id'] }}</div>
        </div>
      </div>
      </div>
    </div>
    <b-button class="btn_add" style="margin-bottom: 16px;" @click="addExternalKintai"><span>＋</span>外部連携先を追加</b-button>
  </div>
</template>

<style scoped>
.btn_add {
  color: #384250 !important;
  width: auto !important;
}

.apiinfo-control {
  width: 560px !important;
}
</style>

<script>
export default {
  name: "ExternalKintai",
  components: {},
  props: {
    external_kintai: {
      type: Array,
      required: false
    },
    external_kintai_name_list: {
      type: Array,
      required: true
    },
    backend_errors: {
      type: Object,
      required: false
    },
    used_office_kintais: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      // 外部連携先データ
      office_external_kintai: this.external_kintai,
      // 入力エラー
      errors: {
        external_link_id: {},
        external_link_id_duplicate: {},
        external_kintai_id: {}
      },
    };
  },
  watch: {
    office_external_kintai: {
      handler() {
        this.$emit('update_external_kintai', this.office_external_kintai, this.errors)
      },
      deep: true
    },
  },
  methods: {
    /**
    * 外部連携先事業所ID 入力チェック
    */
    checkExternalLinkId(index, value) {
      let error_message = ''

      // 半角英数字記号以外はエラー
      if (!/^[A-Za-z0-9!-/:-@[-`{-~]*$/.test(value)) {
        error_message = '外部連携先事業所IDは半角英数字記号で入力してください。'
      }
      this.$set(this.errors['external_link_id'], index, error_message);
      
      if(error_message === ''){
        this.checkDuplicateExternalKintai(index)
      }
    },
    checkExternalKintaiId(index, value) {
      let error_message = '';

      const workArray = [...this.office_external_kintai.slice(0, index), ...this.office_external_kintai.slice(index + 1)];
      if (workArray.find(external_kintai => external_kintai.external_kintai_id === value)) {
        error_message = '外部連携先名が重複しています。';
      }
      this.$delete(this.backend_errors, 'office_external_kintai.' + index + '.external_link_id');
      this.$set(this.errors['external_kintai_id'], index, error_message);

      this.checkDuplicateExternalKintai(index)
    },
    //外部連携先の事務所IDの重複チェック
    checkDuplicateExternalKintai(index){
      if(!this.external_kintai[index] || !this.used_office_kintais){
        return
      }

      let error_message = ''
      let duplicate_office_kintai = this.used_office_kintais.find(used_office_kintai =>
        used_office_kintai.external_kintai_id === this.external_kintai[index].external_kintai_id &&
        used_office_kintai.external_link_id === this.external_kintai[index].external_link_id &&
        this.external_kintai[index].enable_flg
      );
      if(duplicate_office_kintai){
        error_message = "外部連携先事業所IDは既に使用されています"
      }

      this.$set(this.errors['external_link_id'], index, error_message)
    },
    /**
     * 連携先削除ボタン
     */
    removeExternalKintai(index) {
      // エラーをクリア
      Object.entries(this.errors).forEach(([key, dummy]) => {
        this.$set(this.errors[key], index, '');
      });

      this.$delete(this.backend_errors, 'office_external_kintai.' + index + '.external_link_id');
      this.$delete(this.backend_errors, 'office_external_kintai.' + index + '.external_kintai_id');
      // データ削除
      this.office_external_kintai[index].enable_flg = false;
    },
    /**
     * 連携先追加ボタン
     */
    addExternalKintai() {
      this.office_external_kintai.push(
        {
          external_kintai_id: '',
          external_link_id: '',
          enable_flg: true
        }
      );
    },
    /**
     * 外部連携先サービス名エラー削除
     */
    deleteExternalKintaiIdErrors() {
      Object.keys(this.backend_errors).filter(key => {
        return key.startsWith('office_external_kintai.') && key.endsWith('.external_kintai_id');
      }).forEach(key => {
        this.$delete(this.backend_errors, key);
      });
    },
  }
}
</script>
