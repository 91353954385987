<template>
  <div class="m-3 p-3">
    <div class="pb-5" style="display: flex; justify-content: space-between;">
      <div>
        <h4>勤務形態一覧表　一覧</h4>
      </div>
      <div style="display: flex;">
        <div class="d-none">
          <b-button class="btn_white" @click="downloadFormat">
            <font-awesome-icon icon="download" class="icon-style" />フォーマットダウンロード
          </b-button>
        </div>
        <div :class="{'d-none': !is_free_plan}">
          <b-button class="btn_white" @click="downloadMhlwFormat" :disabled="!is_free_plan">
            <font-awesome-icon icon="download" class="icon-style" />厚労省フォーマットダウンロード
          </b-button>
        </div>
        <div v-if="false">
          <b-button class="btn_white" @click="downloadActualFormat" :class="[is_free_plan ? 'disable-style' : '']">
            <font-awesome-icon icon="download" class="icon-style" />実績フォーマットダウンロード
          </b-button>
        </div>
        <div><BtnActualDataImport
          :is_free_plan="is_free_plan"
          :external_kintais="external_kintais"
          label="実績インポート"
        /></div>
        <div class="d-none">
          <b-button class="btn_white">
            <font-awesome-icon icon="download" class="icon-style" />PDFダウンロード
          </b-button>
        </div>
      </div>
    </div>
    <b-row class="pt-5">
      <b-col cols="12" md="1">
        <p>検索</p>
      </b-col>
      <b-col cols="5" md="2">
        <label>期間（From）</label>
      </b-col>
      <b-col cols="5" md="2" offset-md="1">
        <label>期間（To）</label>
      </b-col>
      <b-col cols="12" md="2">
        <label for="servie_type_id">サービス種別</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5" md="2" offset-md="1" style="padding: 0px">
        <datepicker
          v-model="from_date"
          type="month"
          :default="from"
          :validation_check="false"
          @date="onUpdateFrom"
        ></datepicker>
        <div class="invalid-feedback">{{errors.from_date}}</div>
        <div class="invalid-feedback">{{errors.fromTo}}</div>
      </b-col>
      <b-col class="text-center" cols="1" md="1">
        <p>～</p>
      </b-col>
      <b-col cols="5" md="2"  style="padding: 0px">
        <datepicker
          v-model="to_date"
          type="month"
          :default="to"
          :validation_check="false"
          @date="onUpdateTo"
        ></datepicker>
        <div class="invalid-feedback">{{errors.to_date}}</div>
      </b-col>
      <b-col cols="12" md="4">
        <v-autocomplete
          v-model="service_type_id"
          id="service_type_id"
          :items="form.service_types"
          class="autocomplete-style"
          item-text="text"
          item-value="value"
          hide-details="true"
          placeholder="選択してください"
          no-data-text="サービス種別が見つかりません。"
          dense
          filled
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col cols="12" md="2">
        <label for="office_id">事業所</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="2">
        <v-autocomplete
          v-model="office_id"
          id="office_id"
          :items="form.offices"
          class="autocomplete-style"
          item-text="text"
          item-value="value"
          hide-details="true"
          placeholder="選択してください"
          no-data-text="事業所が見つかりません。"
          dense
          filled
        ></v-autocomplete>
      </b-col>
      <b-col cols="12" md="2">
        <b-form-group>
          <b-form-radio-group v-for="type in types" :key="type.id" v-model="type_id">
            <b-form-radio :value="type.id">{{ type.name }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pt-5" cols="12">
        <label for="servie">◆{{ list_title }}表</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="py-1" cols="12" md="3">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="キーワード検索"
          outlined
          hide-details
          dense
          style="border-radius: 8px; background: #FFFFFF;"
        ></v-text-field>
      </b-col>
    </b-row>
    <v-data-table
      :headers = "headers"
      :items = "desserts"
      :items-per-page = "fulltime_conversions_list"
      :footer-props = "footerProps"
      :search="search"
      fixed-header
      height="calc(100vh - 400px)"
      class="elevation-1"
      no-data-text="データが存在しません"
      @update:items-per-page="savePerPage"
    >
      <template v-slot:item.download="{ item }">
        <BtnDownload
          :is_free_plan="is_free_plan"
          button_type="dark"
          label="ダウンロード"
          :is_available="supported_template_no.indexOf(item.template_no) >= 0"
          :is_prepared="true"
          :office_id="item.office_id"
          :year="(item.ym + '').substring(0, 4)"
          :month="(item.ym + '').substring(4, 6)"
          :type="item.type"
        />
      </template>
      <template
        v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:footer.page-text="props">
        {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
        {{ props.pageStop }} 件までを表示
      </template>
    </v-data-table>
  </div>
</template>

<style>
.autocomplete-style .v-input__control {
  border: 1px solid #ced4da!important;
  border-radius: 8px!important;
}
.v-input__slot {
    background: #ffffff!important;
}
.disable-style {
  opacity: 0.65;
}
.v-text-field--outlined fieldset {
    border-width: 1px !important;
}
.btn_white {
  height: 37px!important;
  margin-left: 20px;
}
.icon-style {
  color: #0BB0BE;
  vertical-align: text-top;
  padding-right: 10px;
}
.invalid-feedback {
  display: block;
}
</style>

<script>
import axios from "axios"
import datepicker from "../Components/MyDatePicker"
import _ from 'lodash'
import moment from "moment"
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import BtnActualDataImport from '@/Pages/FulltimeConversions/Components/Button/BtnActualDataImport';
import BtnDownload from '@/Pages/FulltimeConversions/Components/Button/BtnDownload';
Vue.use(VueCookie);

export default {
  components: {
    datepicker,
    BtnActualDataImport,
    BtnDownload,
  },
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    service_types: {
      type: Array,
      required: false
    },
    offices: {
      type: Array,
      required: false
    },
    types: {
      type: Array,
      required: true
    },
    type_id_default: {
      type: Number,
      required: true
    },
    downloadable_service_types: {
      type: Array,
      required: true
    },
    is_free_plan: {
      type: Boolean,
      default:  false,
    },
    external_kintais: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      fulltime_conversions_list_api_url: this.$route('fulltime-conversions.do-update-list'),
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '事業所', align: 'start', sortable: true, value: 'office', width:'25%' },
        { text: 'サービス種別', value: 'service_type', width:'30%' },
        { text: '対象月', value: 'ym', width:'15%', formatter: this.formatYm },
        { text: '作成日', value: 'day', width:'20%', formatter: this.formatDay },
        { text: 'ダウンロード', sortable: false, value: 'download', width:'10%' },
      ],
      list_title: '',
      desserts: [],
      form: {
        service_types: [],
        offices: [],
      },
      from_date: null,
      to_date: null,
      service_type_id: null,
      office_id: null,
      type_id: null,
      ym_format: 'YYYY/MM',
      day_format: 'YYYY/MM/DD',
      supported_template_no: [],
      download_format: {
        service_types: {}
      },
      fulltime_conversion_download_format_url: route('fulltime-conversions.download-format'),
      fulltime_conversion_download_mhlw_format_url: route('fulltime-conversions.download-mhlw-format'),
      fulltime_conversion_download_actual_format_url: route('fulltime-conversions.download-actual-format'),
      fulltime_conversions_list: Number(this.$cookie.get('fulltime_conversions_list')),
      errors: {},
    }
  },
  created: function () {
    let service_types = this.service_types.map(service_type => {
      return {
        text: service_type.name,
        value: service_type.id
      }
    })
    this.form.service_types = [
      {
        text: 'すべて',
        value: 0
      },
      ...service_types
    ]
    this.service_type_id = 0

    let offices = this.offices.map(office => {
      return {
        text: office.name,
        value: office.id
      }
    })
    this.form.offices = [
      {
        text: 'すべて',
        value: 0
      },
      ...offices
    ]
    this.office_id = 0

    this.type_id = this.type_id_default

    this.debouncedUpdateList = _.debounce(this.updateList, 300)

    this.downloadable_service_types.forEach(downloadable_service_type => {
      this.supported_template_no.push(downloadable_service_type.template_no)
      this.download_format.service_types[downloadable_service_type.id] = downloadable_service_type.name
    })
  },
  watch: {
    from_date: function () {
      if (this.checkFromTo()) {
        this.debouncedUpdateList();
      }
    },
    to_date: function () {
      if (this.checkFromTo()) {
        this.debouncedUpdateList();
      }
    },
    service_type_id: function () {
      this.debouncedUpdateList()
    },
    office_id: function () {
      this.debouncedUpdateList()
    },
    type_id: function (value) {
      this.debouncedUpdateList()
      this.list_title = ''
      for (let type of this.types) {
        if (type.id === value) {
          this.list_title = type.name
          break
        }
      }
    }
  },
  methods: {
    savePerPage(val) {
      this.$cookie.set('fulltime_conversions_list', val, 1);
    },
    checkFromTo() {
      let result = true;
      let pattern = /^\d{4}\/(0[1-9]|1[0-2])$/;
      let fromDate = null;
      let toDate = null;

      // FromToエラークリア
      this.$delete(this.errors, 'from_date');
      this.$delete(this.errors, 'to_date');
      this.$delete(this.errors, 'fromTo');

      if (this.from_date != '' && this.from_date !=null && this.from_date != undefined) {
        // yyyy/mm形式かチェックする
        fromDate = this.from_date;
        if (!pattern.test(fromDate)) {
          this.$set(this.errors, 'from_date', '期間（From）の形式が正しくありません。')
          result = false;
        }
      }

      if (this.to_date != '' && this.to_date !=null && this.to_date != undefined) {
        // yyyy/mm形式かチェックする
        toDate = this.to_date;
        if (!pattern.test(toDate)) {
          this.$set(this.errors, 'to_date', '期間（To）の形式が正しくありません。')
          result = false;
        }
      }

      // From ＜ Toはエラー
      if (fromDate != null && toDate != null) {
        if (fromDate >= toDate) {
          this.$set(this.errors, 'fromTo', '期間 (To) は期間 (From) 以降で指定してください')
          result = false;
        }
      }

      return result;
    },
    onUpdateFrom: function (date) {
      this.from_date = date
    },
    onUpdateTo: function (date) {
      this.to_date = date
    },
    updateList: function () {
      let api_url = this.fulltime_conversions_list_api_url + '?type=' + this.type_id
      if (this.from_date !== null) {
        api_url += '&from=' + this.from_date
      }
      if (this.to_date !== null) {
        api_url += '&to=' + this.to_date
      }
      if (this.service_type_id !== 0 && this.service_type_id !== null) {
        api_url += '&service_type=' + this.service_type_id
      }
      if (this.office_id !== 0 && this.office_id !== null) {
        api_url += '&office=' + this.office_id
      }
      axios
        .get(api_url)
        .then(res => {
          if (typeof res.data !== 'undefined' && typeof res.data.success !== 'undefined') {
            if (res.data.success) {
              this.desserts = res.data.data
            } else {
              this.$swal.fire({
                icon: 'error',
                text: res.data.message
              })
            }
          } else {
            throw '返却値のデータ構造不正'
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            text: 'データ取得に失敗しました。しばらくたってから再試行してください。'
          })
        })
    },
    formatYm: function (value) {
      return value ? moment(value, 'YYYYMM').format(this.ym_format) : null
    },
    formatDay: function (value) {
      return value ? moment(value).format(this.day_format) : null
    },
    downloadFormat: function () {
      this.$swal
        .fire({
          text: 'ダウンロードしますか？',
          icon: 'info',
          showCancelButton: true,
          input: 'select',
          inputPlaceholder: 'サービス種別',
          inputOptions: this.download_format.service_types,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            const url = this.fulltime_conversion_download_format_url + '?service_type=' + result.value
            console.log(url)
            axios
              .get(url, { responseType: 'blob' })
              .then(res => {
                let elem = document.createElement('a')
                elem.href = window.URL.createObjectURL(new Blob([res.data]))
                let file_name = res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1')
                elem.setAttribute('download', file_name)
                document.body.appendChild(elem)
                elem.click()
                document.body.removeChild(elem)
              })
          }
        })
    },
    downloadMhlwFormat: function () {
      const url = this.fulltime_conversion_download_mhlw_format_url
      console.log(url)
      axios
        .get(url, { responseType: 'blob' })
        .then(res => {
          let elem = document.createElement('a')
          elem.href = window.URL.createObjectURL(new Blob([res.data]))
          let file_name = res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1')
          elem.setAttribute('download', file_name)
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        });
    },
    downloadActualFormat: function () {
      if(this.is_free_plan){
        const url = route('lodgments.plan-edit');
        const message = `機能制限無料プランでのご利用のため、実績フォーマットのダウンロードができません。ダウンロードする場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        })
        return;
      }

      const url = this.fulltime_conversion_download_actual_format_url
      console.log(url)
      axios
        .get(url, { responseType: 'blob' })
        .then(res => {
          let elem = document.createElement('a')
          elem.href = window.URL.createObjectURL(new Blob([res.data]))
          let file_name = res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1');
          elem.setAttribute('download', file_name)
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        });
    },
  }
}
</script>
