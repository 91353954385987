<template>
  <div>
    <b-button
      style="padding: 0px!important;"
      :class="[is_free_plan ? 'btn_white btn-auto-create disable-style' : 'btn_white btn-auto-create']"
      :style="'font-size: ' + ( edit_mode ? '12' : '14' ) + 'px; height: ' + (edit_mode ? '30px' : '34px' ) + '!important; width: '+ ( edit_mode ? '40px' : '170px' ) + '!important;'"
      @click="action"
    >
      <span v-if="edit_mode">シ</span>
      <span v-else>シフト自動作成</span>
    </b-button>
    <AutoCreateDialog
      ref="AutoCreateDialog"
      :office="office"
      :team_id="team_id"
      :workpatterns="workpatterns"
      :work_categories="work_categories"
      :staff_assignments="staff_assignments"
      :office_staff_workpatterns="office_staff_workpatterns"
      :basic_infos="basic_infos"
      :year="year"
      :month="month"
      :summary="summary"
      :past="past"
      :shift_data="shift_data"
      @updatePreparingFlg="updatePreparingFlg"
    />
  </div>
</template>

<style scoped>
.btn-auto-create {
  width: 170x!important;
  height: 34px!important;
  color: #FB82B4!important;
  border: solid 1px #FB82B4!important;
}
.disable-style {
  opacity: 0.65;
}
</style>

<script>
import AutoCreateDialog from '@/Pages/Shifts/Components/Dialog/AutoCreateDialog';

export default {
  name: 'BtnAutoCreate',
  components: {
    AutoCreateDialog,
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
    workpatterns: {
      type: Object,
      default: () => {
        return {};
      }
    },
    work_categories: {
      type: Object
    },
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    office_staff_workpatterns: {
      type: Array,
    },
    is_free_plan: {
        type: Boolean,
        default: false,
    },
    basic_infos: {
      type: Array,
      default: () => {
        return {};
      }
    },
    past: {
      type: Boolean,
      default: false,
    },
    // シフトデータ
    shift_data: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      interval_check_auto_creation: null,
    }
  },

  methods: {
    // loadingの制御
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    updatePreparingFlg(flg) {
      this.$emit('updatePreparingFlg', flg);
    },
    action() {
      if (this.is_free_plan) {
          const url = route('lodgments.plan-edit');
          const message = `機能制限無料プランでのご利用のため、過去のシフトの参照ができません。過去のシフトを参照いただく場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
          this.$swal({
              icon: 'info',
              html: message,
          });
          return;
      }
      let data = {
        office: this.office
      };
      this.$refs.AutoCreateDialog.openDialog(data);
    },
    checkAutoCreationStatus() {
      // AutoCreateDialogコンポーネントのメソッドを呼び出す
      this.$refs.AutoCreateDialog.checkAutoCreationStatus();
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
