<template>
  <div
    class="btn_white"
    style="
      display: flex;
      width: 100%!important;
      justify-content: space-between!important;
      padding: 0px!important;
      margin-bottom: 8px;
    "
    :style="'height: ' + ( edit_mode ? '30px' : '60px' ) + '!important;'"
  >
    <div v-if="!edit_mode" style="width: auto;">
      <b-button class="btn_shift_reset" :disabled="disabled(-1)" :style="btnStyle(-1)" @click="goMonth(-1)">
        <font-awesome-icon
          icon="chevron-left"
          style="
            font-size: 30px;
            color: #02B28A;
            vertical-align: middle;
            padding: 0px 13px;
          "
        />
        <span style="font-size: 14px; color: #02B28A;">先月</span>
      </b-button>
    </div>
    <div style="text-align:center; " :style="'font-size: ' + ( edit_mode ? '12' : '22' ) + 'px; width: ' + ( edit_mode ? '100' : '45' ) + '%;'">
      {{ year }}年{{ month }}月
    </div>
    <div v-if="!edit_mode" style="width: auto;">
      <b-button class="btn_shift_reset" :disabled="disabled(1)" :style="btnStyle(1)" @click="goMonth(1)">
        <span style="font-size: 14px; color: #02B28A;">次月</span>
        <font-awesome-icon
          icon="chevron-right"
          style="
            font-size: 30px;
            color: #02B28A;
            vertical-align: middle;
            padding: 0px 13px;
          "
        />
      </b-button>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import dayjs from 'dayjs';
export default {
  name: 'YearMonthView',
  components: {
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    current_year: {
      type: String,
      default: 0
    },
    current_month: {
      type: String,
      default: 0
    },
    office_id: {
      type: Number,
      default: 0
    },
    view_type: {
      type: String,
      default: 'application_status'
    },
    is_free_plan: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
    }
  },

  methods: {
    disabled(val) {
      let target = dayjs(this.year + '-' + this.month + '-01').add(val, 'M');
      return target.format('YYYY-MM-DD') > dayjs().set('date', 1).add(3, 'M').format('YYYY-MM-DD');
    },
    btnStyle(val) {
      if (this.disabled(val)) {
        return 'visibility: hidden;';
      } else {
        return '';
      }
    },
    // 月の変更
    goMonth(val) {
      let target = dayjs(this.year + '-' + this.month + '-01').add(val, 'M');

      let target_month = target.format('MM');
      let target_year = target.format('YYYY');

      if (this.view_type == 'application_status') {
        route = 'shifts.application-status';
      } else {
        route = 'shifts.schedule';
      }
      if(this.is_free_plan && (target_year < this.current_year || target_month < this.current_month)) {
        const url =  this.$url('/lodgments/plan-edit');
        const message = `機能制限無料プランでのご利用のため、過去のシフトの参照ができません。過去のシフトを参照いただく場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        })
        return;
      }
      this.updateLoading(true);
      this.$inertia.get(this.$route(route, { year: target_year, month: target_month, office: this.office_id }));
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
  }
}
</script>

