<template>
  <div class="footer-dots">
<!--
    <b-dropdown toggle-class="dot-btn-wrapper" menu-class="dropdown-menu-xl" dropup variant="link" no-caret>
      <span slot="button-content">
        <i class="dot-btn-icon lnr-bullhorn icon-gradient bg-mean-fruit"></i>
        <div class="badge badge-dot badge-abs badge-dot-sm badge-danger">Notifications</div>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-deep-blue">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-dark">
            <h5 class="menu-header-title">通知</h5>
            <h6 class="menu-header-subtitle">
              未読メッセージが <b>21</b>通 あります
            </h6>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <b-tabs class="card-header-tab-animation" card>
          <b-tab title="イベント" class="p-3" active>
            <div class="scroll-gradient">
              <div class="scroll-area-sm">
                <VuePerfectScrollbar class="scrollbar-container" v-once>
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">○○プロジェクトGMT</h4>
                          <p> 今日はありがとうございました<a href="javascript:void(0);">12:00 PM</a></p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">××プロジェクトGMT</h4>
                          <p> 今日はもう一つ打ち合わせがあります。<span class="text-success">15:00 PM</span></p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">本日期日となるプロジェクト</h4>
                          <p>期日が守れるように頑張りましょう。</p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-dropdown>
    <div class="dots-separator"></div>
-->
    <b-dropdown toggle-class="dot-btn-wrapper" variant="link" no-caret dropup>
      <span slot="button-content">
        <i class="dot-btn-icon lnr-file-empty icon-gradient bg-happy-itmeo"></i>
      </span>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
          <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
          <div class="menu-header-content text-center text-white">
            <h6 class="menu-header-subtitle mt-0">その他</h6>
          </div>
        </div>
      </div>
      <button type="button" tabindex="0" class="dropdown-item">
        <a href="https://synchroseries.jp/synchroshift/terms.html" target="_blank" rel="noopener noreferrer">利用規約</a>
      </button>
      <button type="button" tabindex="0" class="dropdown-item">
        <a href="https://hp.syncube.co.jp/privacypolicy.html" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>
      </button>
    </b-dropdown>
    <div class="dots-separator"></div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowUp, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faCog);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  data: () => ({}),
  methods: {}
};
</script>

