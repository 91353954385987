<template>
  <b-modal
    ref="my-modal"
    :hide-header="!title"
    :no-enforce-focus="true"
    centered
    @hidden='cancel'
    size="xl"
  >
    <template #modal-header="{ close }">
      <h5>連続勤務日数の対象外職員を選択</h5>
      <button class="btn_close" @click="cancel">✕</button>
    </template>

    <b-container fluid style="height: 60vh; overflow-y: auto;">
      <table class="table_1 table_2" style="width: auto;">
        <tr>
          <th style="max-width: 20px;"></th>
          <th style="width: 144px; padding-left: 12px!important;">社員番号</th>
          <th style="width: 312px;">氏名</th>
          <th style="width: 144px;">雇用形態</th>
          <th style="width: 144px;">役職</th>
          <th style="width: 312px;">職種</th>
        </tr>
        <tr v-for="(staff,index) in office_staffs" :key="index">
          <td style="max-width: 20px;">
            <input
              type="checkbox"
              v-model="office_rule.excluded_staff_list"
              :value="staff.office_staff_id"
              class="office-staff-checkbox"
            >
          </td>
          <td style="width: 144px; padding-left: 12px!important;">{{ staff.employee_number }}</td>
          <td style="width: 312px">{{ staff.name }}</td>
          <td style="width: 144px">{{ staff.employment_type }}</td>
          <td style="width: 144px">{{ staff.position_name }}</td>
          <td style="width: 312px">{{ staff.occupation_name }}</td>
        </tr>
      </table>
    </b-container>
    <template #modal-footer>
      <button @click="save" class="btn_blue" style="margin: 10px 16px!important;">完了</button>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {
  },
  props: {
    // 連続勤務日数のルール
    office_rule: {
      type: Object|Array,
      required :false,
    },
    // 連続勤務日数の対象職員
    office_staffs : {
      type: Object|Array,
      required :false,
    },
    title : {
      type: String,
      required :false,
    },
  },
  data() {
    return {
      excluded_staff_list: this.getDefaultStaffs(),
    }
  },
  computed: {
  },
  methods: {
    // モーダル内の職員の初期表示
    getDefaultStaffs() {
      return this.office_rule.excluded_staff_list ? this.office_rule.excluded_staff_list : []
    },
    // 保存
    async save () {
      this.excluded_staff_list = this.office_rule.excluded_staff_list;
      this.$emit('save');
      await this.hideModal();
    },
    // キャンセルボタン or ✕ボタン or 背景クリック
    cancel () {
      // 保存されたもの以外のデータに戻す
      this.office_rule.excluded_staff_list = this.excluded_staff_list;
      this.$refs['my-modal'].hide()
    },
    // モーダル開く
    showModal() {
      this.$refs['my-modal'].show()
    },
    // モーダル閉じる
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
}
</script>

<style>
</style>
