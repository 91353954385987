<template>
  <b-tr>
    <b-td style="border-top: 1px solid #DEE2E6 !important; max-height: 55px; padding: 5px;">
      <div>
        <span :title="info.name">{{ info.short_name }}</span>
      </div>
      <div>
        <span :title="info.time">{{ info.short_time }}</span>
      </div>
      <div>
        <span class="link_text" @click="openWpListDialog">編集</span>
        <span class="link_text" style="margin-left: 20px;" @click="deleteWpList">削除</span>
      </div>
    </b-td>
    <b-td
      v-for="(day_of_week, key, index) in day_of_weeks" :key="index" style="vertical-align: middle;">
      <div v-if="index > 0" style="display:flex; justify-content: center;">
        <div>
          <input
            type="number"
            class="form-control px-0 text-center"
            min="0"
            max="31"
            :value="GetData(key, 'min')"
            @change="updateNum($event, key, 'min')"
            style="width: 38px; margin: 0px 5px;"
            :style="(('wp_list_' + index) in border_color_style) ? border_color_style['wp_list_' + index] : ''"
          />
        </div>
        <div>
          <input
            type="number"
            class="form-control px-0 text-center"
            min="0"
            max="31"
            :value="GetData(key, 'max')"
            @change="updateNum($event, key, 'max')"
            style="width: 38px; padding-right: 5px;"
            :style="(('wp_list_' + index) in border_color_style) ? border_color_style['wp_list_' + index] : ''"
          />
        </div>
      </div>
      <div v-else style="height: 100%;">
        <div style="display: flex; white-space: nowrap; width: 50%; height: 100%; float: left; justify-content: center; border-right: solid 1px #DEE2E6;">
          <input
            type="number"
            class="form-control px-0 text-center"
            min="0"
            max="31"
            :value="GetData(key, 'min')"
            @change="updateNum($event, key, 'min')"
            style="width: 38px; padding-right: 5px; margin: auto 0;"
            :style="(('wp_list_' + index) in border_color_style) ? border_color_style['wp_list_' + index] : ''"
          />
          <span style="margin: auto 0;">人<br />以上</span>
        </div>
        <div style="display: flex; white-space: nowrap; width: 50%; height:100%; justify-content: center;">
          <input
            type="number"
            class="form-control px-0 text-center"
            min="0"
            max="31"
            :value="GetData(key, 'max')"
            @change="updateNum($event, key, 'max')"
            style="width: 38px; padding-right: 5px; margin: auto 0;"
            :style="(('wp_list_' + index) in border_color_style) ? border_color_style['wp_list_' + index] : ''"
          />
          <span style="margin: auto 0;">人<br />以下</span>
        </div>
      </div>
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'WpListRow',
  components: {
  },
  props: {
    index: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
    },
    assignment: {
      type: Object,
    },
    border_color_style: {
      type: Object,
    }
  },
  data () {
    return {
      day_of_weeks : {
        def: '一括指定',
        mon: '月',
        tue: '火',
        wed: '水',
        thu: '木',
        fri: '金',
        sat: '土',
        sun: '日',
        hol: '祝'
      },
    }
  },
  methods: {
    // 勤務区分選択ダイアログ表示
    openWpListDialog() {
      this.$emit("openWpListDialog", this.index);
    },
    // 勤務区分グループの削除
    deleteWpList() {
      this.$emit("deleteWpList", this.index);
    },
    // 値取得(min,max)
    GetData(key, name) {
      let result = 0;
      if (this.assignment && this.assignment[key]) {
        if (name === 'min') {
          result = this.assignment[key].min;
        } else if (name === 'max') {
          result = this.assignment[key].max;
        }
      }
      return result;
    },
    // 数値の更新
    updateNum(e, key, type) {
      this.assignment[key][type] = e.target.value;
      this.$emit('updateWpListAssignment', this.index, key, type, e.target.value)
      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
  },
  watch: {
  },
  computed: {
  },
  mounted() {
  },
}
</script>
