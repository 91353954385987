<template>
  <b-button
    class="btn_shift_reset"
    style="font-size: 12px!important; margin-right: 0px! important;"
    @click="action"
  >
    使い方を見る
    <font-awesome-icon
      icon="external-link-alt"
      style="font-size: 12px; font-weight: 600; color: #C9C9C9; vertical-align: text-top;"
    />
  </b-button>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'BtnHowToPage',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },

  methods: {
    action() {
      window.open('https://support.synchroshift.jp/portal/ja/kb/articles/%E3%82%B7%E3%83%95%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%9F%E3%81%84', '_blank');
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>

