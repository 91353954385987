<template>
  <div style="font-size: 10px; margin-top:0px; margin-right: -20px;">
    <div
      v-if="!edit_mode"
      class="shift-parts-wrap"
      style="display: flex;"
      :style="'margin-bottom: ' + (edit_mode ? '13' : '13') + 'px;'"
    >
      <h4 style="font-size: 18px; font-weight: 600; margin: 0px 20px 0px 0px;">
        シフト作成<span style="color: #F04438;">（{{ status_str }}）</span>
      </h4>
      <p style="margin: auto 0; padding-right: 19px;">
        希望締切日：
        <span style="color: #F04438;">
          {{ request_deadline_month }}月{{ ('0' + office.request_deadline).slice(-2) }}日
        </span>
      </p>
      <p style="margin: auto 0; padding-right: 19px;">
        希望申請状況：
        <span style="color: #F04438;">
          {{ change_current_request_count }}人/{{ change_total_members_count }}人中 提出済み
        </span>
      </p>
    </div>
    <div class="shift-parts-wrap" style="justify-content: space-between; margin-bottom: 13px;" :style="'display: ' + (edit_mode ? 'none' : 'flex') + ';'">
      <div style="display: flex;">
        <!-- 事業所選択 -->
        <OfficeSelect
          :offices="offices"
          :year="year"
          :month="month"
          :initial_office="office"
          :view_type="view_type"
        />
        <!-- チーム選択 -->
        <TeamSelect
          :teams="teams"
          :year="year"
          :month="month"
          :office_id="office.id"
          :initial_team_id="team_id"
          :view_type="view_type"
          @changeTeam="changeTeam"
        />
        <!-- 表示年月選択エリア -->
        <YearMonthSelect
          :view_type="view_type"
          :years="years"
          :months="months"
          :office_id="office.id"
          :initial_year="year"
          :initial_month="month"
          :current_year="current_year"
          :current_month="current_month"
          :is_free_plan="is_free_plan"
        />
      </div>
      <div class="d-flex">
        <BtnAddOnCheck
          :add-on-check-data="add_on_check_data"
        />
        <!-- シフト自動作成 -->
        <BtnAutoCreate
          v-if="view_type === 'schedule'"
          ref="autoCreate"
          :is_changed="is_changed"
          :summary="summary"
          :year="year"
          :month="month"
          :office="office"
          :team_id="team_id"
          :workpatterns="workpatterns_filtered"
          :work_categories="work_categories"
          :staff_assignments="staff_assignments"
          :office_staff_workpatterns="office_staff_workpatterns"
          :is_free_plan="is_free_plan"
          :basic_infos="basic_infos_filtered"
          :edit_mode="edit_mode"
          :past="past"
          :shift_data="new_shifts"
          @updatePreparingFlg="is_preparing_optimization = $event"
        />
        <BtnReflection
          v-if="view_type === 'application_status'"
          :is_changed="is_changed"
          :all_approval="all_approval"
          :year="year"
          :month="month"
          :office="office"
        />
      </div>
    </div>
    <div class="shift-parts-wrap" style="display: flex; justify-content: space-between; margin-bottom: 0px;" :style="'display: ' + (edit_mode ? 'none' : 'flex') + ';'">
      <div style="display: flex;">
        <!-- tabの切り替え -->
        <Tab
          :year="year"
          :month="month"
          :office="office"
          :view_type="view_type"
          :is_changed="is_changed"
        />
        <!-- 表示ボタン -->
        <SwitchColView
          :view_type="view_type"
          @changeOpenSort="changeOpenSort"
          @changeOpenTeam="changeOpenTeam"
          @changeOpenFlg="changeOpenFlg"
          @changeOpenOccupation="changeOpenOccupation"
          @changeOpenTable="changeOpenTable"
        />
        <!-- 行の表示設定 -->
        <SwitchRowView
          @changeshowAll="changeshowAll"
          @changeshowWorkpattern="changeshowWorkpattern"
        />
        <!-- 連続配置 -->
        <ConsecutiveWorkpattern
          ref="ConsecutiveWorkpattern"
          v-if="view_type === 'schedule'"
          @changeConsecutiveWorkpattern="changeConsecutiveWorkpattern"
        />
      </div>
      <div style="display: flex;">
        <!-- 検索フォーム -->
        <v-text-field v-show="false"
          append-icon="mdi-magnify"
          label="キーワード検索"
          outlined
          hide-details
          dense
          class="search_icon"
          style="width: 230px; border-radius: 8px; background: #FFFFFF;"
          @change="searchWord"
        ></v-text-field>
        <BtnAboutIcon />
        <BtnHowToPage />
      </div>
    </div>
    <div class="stripe_green" :style="'margin-top: ' + (edit_mode ? '-20px' : '0px') + '; margin-bottom: ' + (edit_mode ? '-15px' : '0px') + ';'">
      <!-- このエリアは開閉可能 -->
      <div :style="'display: ' + (is_open_table ? 'flex' : 'none') + '; width: calc(97% + 15px); margin-bottom: ' + (is_open_table ? '5px' : '20px') + ';'">
        <VuePerfectScrollbar
          id="sub_table"
          style="width: 100%!important; margin-left: 15px;"
          class="app-sidebar-scroll"
          :style="'height: ' + getSummaryTableHeight() + 'px!important;'"
          @scroll="handleScroll"
        >
          <HolidayLimitTable
            v-if="view_type === 'application_status'"
            :days="days"
            :org_work_categories="work_categories"
            :holiday_limit="new_holiday_limit"
            :is_available_change_holiday_limit="view_type === 'application_status' ? summary.status !== 'opened' : true"
            :basic_infos="basic_infos_filtered"
            :shift_applies="new_shift_applies"
            @updateHolidayLimit="updateHolidayLimit"
          />
          <!-- staff_assignments は全チームの情報のみを受け渡す -->
          <ScheduleSummaryTable
            v-else
            ref="summary"
            :basic_infos="basic_infos_filtered"
            :days="days"
            :initial_shift_summaries="shift_summaries"
            :is_available_change_holiday_limit="summary.status !== 'opened'"
            :monthly_alerts="monthly_alerts"
            :daily_alerts="daily_alerts"
            :shift_data="new_shifts"
            :workpatterns="workpatterns"
            :office_staff_workpatterns="staff_assignments[team_id]"
            :org_work_categories="work_categories"
            :time_list="'data' in time_list ? time_list.data : []"
            :office="office"
            :year="year"
            :month="month"
            :edit_mode="edit_mode"
            :work_category_identical_groups="work_category_identical_groups[team_id]"
            :one_team_office_wp_list_assignment="office.wp_list_assignment[team_id]"
            @updateShiftSummaries="updateShiftSummaries"
          />
        </VuePerfectScrollbar>
      </div>
      <!-- 集計行の表示／非表示でmainテーブルの高さ変更 -->
      <form class="mainTableHeight" style="width: 97%;">
        <!-- mainのテーブル 休暇申請状況とシフト表で一部項目が異なる -->
        <VuePerfectScrollbar id="main_table" class="app-sidebar-scroll" style="width: 100%; margin-left: 15px;" @scroll="handleScroll">
          <AlertHopeHoliday 
            :staff_assignments="staff_assignments"
            :days="days"
            :basic_infos="basic_infos_filtered"
            :team_id="team_id"
            :shift_applies="shift_applies"
            :change_total_members_count="change_total_members_count"
            ref="alertHopeHoliday"
          />

          <MainTable
            ref="mainTable"
            :view_type="view_type"
            :edit_mode="edit_mode"
            :days="days"
            :office="office"
            :year="year"
            :month="month"
            :summary="summary"
            :basic_infos="basic_infos_filtered"
            :is_changed="is_changed"
            :selected_team_id="team_id"
            :shift_day_memo="shift_day_memo"
            :office_staff_workpatterns="office_staff_workpatterns"
            :workpatterns="workpatterns"
            :filtered_office_staff_workpattern="filtered_office_staff_workpattern"
            :office_staffs="office_staffs"

            :shift_applies="new_shift_applies"
            :holiday_limit="new_holiday_limit"

            :shift_data="new_shifts"
            :org_work_categories="work_categories"

            :monthly_working_count_list="monthly_working_count_list"

            :shift_monthly_display_rules="shift_monthly_display_rules"

            :is_open_sort="is_open_sort"
            :is_open_team="is_open_team"
            :is_open_flg="is_open_flg"
            :is_open_occupation="is_open_occupation"
            :is_open_all_row="is_open_all_row"
            :is_show_workpattern_val="is_show_workpattern_val"

            :is_consecutive_workpattern="is_consecutive_workpattern"
            :consecutive_shift="consecutive_shift"

            @updateApplicationStatus="updateApplicationStatus"
            @updateAllApprovalFlg="all_approval = $event"

            @updateShift="updateShift"

            @sortStaffsBy="sortStaffsBy"
          />
        </VuePerfectScrollbar>
      </form>
    </div>
    <FooterArea
      :summary="summary"
      :year="year"
      :month="month"
      :office="office"
      :teams="teams"
      :team_id="team_id"
      :is_free_plan="is_free_plan"
      :external_kintais="external_kintais"
      :persons_belong_multiple_offices="persons_belong_multiple_offices"
      :view_type="view_type"
      :fulltime_conversion_available="fulltime_conversion_available"
      :has_fulltime_conversion_result="has_fulltime_conversion_result"
      @saveShift="saveShift"
      @saveApplicationStatus="saveApplicationStatus"
    />
    <ShiftChangeDialog
      ref="shiftChangeDialog"
      modal_type="consecutive"
      :work_categories="work_categories"
      @selectShift="selectShift"
      @selectClear="selectClear"
    />
  </div>
</template>

<style>
.swal2-container {
  z-index: 100000;
}
.search_icon i {
  color: #1059CC!important;
}
.app-main__inner {
  padding-bottom: 0px!important;
}
.alert_reset_team {
  position: absolute!important;
  color: #D61515!important;
  font-weight: 700;
  top: 70px!important;
  left: 50%!important;
  transform: translateY(-50%) translateX(-50%)!important;
  -webkit-transform: translateY(-50%) translateX(-50%)!important;
}
.alert_reset_icon {
  position: absolute!important;
  color: #D61515!important;
  border-color: #D61515!important;
  top: 120px!important;
  left: 50%!important;
  transform: translateY(-50%) translateX(-50%)!important;
  -webkit-transform: translateY(-50%) translateX(-50%)!important;
}
.alert_reset_title {
  position: absolute!important;
  color: #D61515!important;
  font-size: 21px!important;
  width: 100%;
  background: #F5F5F5!important;
  padding: 10px 0px!important;
}
.alert_reset_message {
  font-size: 14px!important;
  margin-top: 170px!important;
  text-align: left!important;
}
.alert_reset_button {
  width: 150px;
  padding: 5px 0px!important
}
.alert_reset_yes_button {
  color: #02B28A;
  margin-right: 10px!important
}
.alert_reset_cloase_button {
  margin: 5px!important
}
.zsiq_cnt {
  display: none!important;
}
.ps__rail-x {
  height: 15px;
}
.ps__thumb-x {
  height: 15px;
}
.ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-x:focus>.ps__thumb-x, .ps__rail-x:hover>.ps__thumb-x {
  height: 15px;
}
.ps__rail-y {
  width: 15px;
}
.ps__thumb-y {
  width: 15px;
}
.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y {
  width: 15px;
}
</style>

<script>
import axios from 'axios';

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Tab from '@/Pages/Shifts/Components/Tab';
import SwitchColView from '@/Pages/Shifts/Components/SwitchColView';
import SwitchRowView from '@/Pages/Shifts/Components/SwitchRowView';
import ConsecutiveWorkpattern from '@/Pages/Shifts/Components/ConsecutiveWorkpattern';
import OfficeSelect from '@/Pages/Shifts/Components/OfficeSelect';
import TeamSelect from '@/Pages/Shifts/Components/TeamSelect';
import YearMonthSelect from '@/Pages/Shifts/Components/YearMonthSelect';
import YearMonthView from '@/Pages/Shifts/Components/YearMonthView';
import BtnAboutIcon from '@/Pages/Shifts/Components/Button/BtnAboutIcon';
import BtnHowToPage from '@/Pages/Shifts/Components/Button/BtnHowToPage';
import BtnAboutFlg from '@/Pages/Shifts/Components/Button/BtnAboutFlg';
import FooterArea from '@/Pages/Shifts/Components/FooterArea';

import MainTable from '@/Pages/Shifts/Components/Table/MainTable/Table';

import HolidayLimitTable from '@/Pages/Shifts/Components/Table/HolidayLimitTable/Table';

import ScheduleSummaryTable from '@/Pages/Shifts/Components/Table/SummaryTable/Table';

import BtnReflection from '@/Pages/Shifts/Components/Button/BtnReflection';
import BtnAutoCreate from '@/Pages/Shifts/Components/Button/BtnAutoCreate';

import ShiftChangeDialog from '@/Pages/Shifts/Components/Dialog/ShiftSelectDialog/ShiftChangeDialog';

import BtnAddOnCheck from '@/Pages/Shifts/Components/Button/BtnAddOnCheck';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);
import AlertHopeHoliday from '@/Pages/Shifts/Components/AlertHopeHoliday.vue';

export default {
  name: 'ShiftsScheduleIndex',
  components: {
    VuePerfectScrollbar,
    Tab,
    SwitchColView,
    SwitchRowView,
    ConsecutiveWorkpattern,
    OfficeSelect,
    TeamSelect,
    YearMonthSelect,
    YearMonthView,

    BtnAboutFlg,
    BtnAddOnCheck,
    FooterArea,

    MainTable,

    BtnAboutIcon,
    BtnHowToPage,

    // application status
    BtnReflection,
    HolidayLimitTable,

    // schedule
    BtnAutoCreate,
    ScheduleSummaryTable,

    //希望休
    AlertHopeHoliday,
    
    // dialog
    ShiftChangeDialog,
  },
  props: {
    //////// application_status と shift に共通の値

    // view_type application_status or schedule
    view_type: { type: String, default: 'schedule' },
    // years 年のリスト
    years: { type: Array },
    // months 月のリスト
    months: { type: Array },
    // 日付データ
    days: { type: Array },
    // summary
    summary: { type: Object },
    // summary.statusの日本語表示
    status_str: { type: String },
    // Office一覧 事業所選択用
    offices: { type: Array },
    // Team一覧 チーム選択用
    teams: { type: Array },
    // 選択済みのOffice
    office: { type: Object },
    // 表示データの年月
    year: { type: String },
    month: { type: String },
    // 表示時の年月日
    current_year: { type: String },
    current_month: { type: String },
    current_day: { type: String },
    // 希望休締切月
    request_deadline_month: { type: String },
    // Officeに所属する数
    total_members_count: { type: Number },
    // シフト提出済みの人数
    current_request_count: { type: Number },
    // ユーザの基本情報
    basic_infos: { type: Array },

    //////// application_statusの場合のみ存在する値
    // 希望休取得可能枠数
    holiday_limit: { type: Object },

    office_staff_workpatterns: { type: Array },

    office_staffs: { type: [Object, Array] },

    // 月次でのイベントをメモ管理
    shift_day_memo: { type: Array },


    // 休暇申請状況データ
    shift_applies: {
      type: Object,
      default: function() {
        return {};
      }
    },

    //////// shiftの場合のみ存在する値
    // 自動作成のアラート情報
    monthly_alerts: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 自動作成のアラート情報
    daily_alerts: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 集計テーブル用のデータ
    time_list: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 勤務カテゴリのリスト
    work_categories: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // シフトデータ
    shift_data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 集計テーブルの並び順
    shift_summaries: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    // 勤務区分一覧
    workpatterns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // office_staff_workpattern.staff_assignmentの情報
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 所定休日チェック用のworking_count
    monthly_working_count_list: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 複数事業所に紐づくユーザと，その事業所リスト
    persons_belong_multiple_offices: {
      type: Object,
      default: () => {
        return {};
      },
    },
    is_free_plan: {
      type: Boolean,
      default: false,
    },
    external_kintais: {
      type: Array,
      required: true
    },
    is_redirect: {
      type: Boolean,
      default: false,
    },
    // 自動作成のエラー
    auto_create_error_response: {
      type: Object,
      default: () => {
        return {};
      }
    },
    office_staff_workpatterns: { type: Array },
    // シフト一覧の表示・非表示のルール
    shift_monthly_display_rules: { type: Object|Array },
    // 勤務形態一覧表のダウンロードが可能？
    fulltime_conversion_available: {
      type: Boolean,
      default: false,
    },
    // 勤務形態一覧表 (実績) が存在する？
    has_fulltime_conversion_result: {
      type: Boolean,
      default: false,
    },
    default_teams: { type: Array },
    // 同一カテゴリの勤務ID
    work_category_identical_groups: {
      type: Object|Array,
    },
    add_on_check_data: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      ////// application_status と shift で共通利用するデータ

      // stg環境かどうか
      is_stg: false,

      // 列の開閉
      is_open_sort: true,
      is_open_team: true,
      is_open_flg: true,
      is_open_occupation: true,

      // 表の開閉
      is_open_table: true,
      // ユーザ情報を各種条件でfilterしたデータ
      basic_infos_filtered: [],
      // データが変更されているかどうか
      is_changed: false,
      // workpatternのリストを各種条件でfilterしたデータ
      workpatterns_filtered: {},
      // 表示しているシフトの年月が，現在年月より過去かどうか
      past: false,
      team_id : 0,

      ////// application_statusで利用するデータ
      // 変更後の休暇申請状況のリスト
      new_shift_applies: {},
      // 変更後の有給取得可能数のリスト
      new_holiday_limit: {},
      // 全データ承認済みかどうか
      all_approval: false,

      ////// shiftで利用するデータ
      // 変更後の集計行の並び順のリスト
      new_shift_summaries: {},
      // 変更後のシフトのリスト
      new_shifts: {},

      // 自動作成の準備中かどうか
      is_preparing_optimization: false,

      contact_form_link: 'https://synchroseries.jp/synchroshift/contact_form.html',

      change_total_members_count: 0,
      change_current_request_count: 0,

      // 作成モード:true／通常モード:false
      edit_mode: false,

      // 全表示モード:true／通常モード:false
      is_open_all_row: true,

      is_show_workpattern_val: true,
      filtered_office_staff_workpattern: {},

      // 連続配置フラグ
      is_consecutive_workpattern: false,
      // 連続配置で選択されたシフトデータ
      consecutive_shift: {
        is_changed: false,
        user: null,
        main_id: null,
        main_name: null,
        replacement_id: null,
        replacement_name: null,
        entry_user_code: null,
      },
      workpatternsData: [],
    }
  },
  methods: {
    // 上表の高さ
    getSummaryTableHeight() {
      let resultHeight = 165;
      if (this.edit_mode) {
        resultHeight = 145;
      } else if (this.view_type !== 'schedule') {
        resultHeight = 145;
      }
      return resultHeight;
    },
    // 検索
    searchWord() {
      console.log('searchWord');
    },
    // 画面離脱時確認
    confirmSave (event) {
      if (this.is_changed) {
        event.returnValue = "保存していない変更があります。よろしいですか？";
      } else if (this.view_type === 'schedule' && this.is_preparing_optimization) {
        event.returnValue = "自動作成処理の準備中です。よろしいですか？";
      }
    },
    // loadingの制御
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // 初期化処理
    initialize(type) {
      if (this.view_type === 'application_status') {
        this.initialize1(structuredClone(this.basic_infos), type);
      } else {
        this.initialize2(structuredClone(this.basic_infos), type);
      }
    },
    // 休暇申請状況初期化処理
    initialize1(new_data, type) {

      this.change_total_members_count = this.total_members_count;
      this.change_current_request_count = this.current_request_count;

      // 表示すべきデータをfilterにかける
      for (let key in new_data) {
        if (!this.past || new_data[key].enable_flg) {
          this.basic_infos_filtered.push(new_data[key]);
        }
      }

      if (type === 'watch') return;

      // 現在の休暇申請状況データを更新用データにコピー
      this.updateShiftApplies(structuredClone(this.shift_applies));
      // 現在の休日取得可能枠数のデータを更新用データにコピー
      for (let key in this.holiday_limit) {
        this.$set(this.new_holiday_limit, key, this.holiday_limit[key]);
      }
    },
    // シフト表初期化処理
    initialize2(new_data, type) {
      let workpatterns_filtered_work = {};
      let workpatterns_filtered_holiday = {};

      for (let key in new_data) {
        let item = new_data[key];
        if(item.occupation_id === null) {
          item.occupation_id = '';
        }
        if (
          (this.team_id === -1 && item.team_id === null)
          || (this.team_id !== 0 && item.team_id === this.team_id)
          || this.team_id === 0
        ) {
          if (!this.past || item.enable_flg) {
          this.basic_infos_filtered.push(item);
          } else {
            let office_staff_id_occupation_id = item.office_staff_id + '_' + item.occupation_id;
            if (office_staff_id_occupation_id in this.shift_data) {
              let exist = false;
              for (let day in this.shift_data[office_staff_id_occupation_id]) {
                if ('shift' in this.shift_data[office_staff_id_occupation_id][day]) {
                  exist = true;
                  break;
                }
              }
              if (exist) {
                this.basic_infos_filtered.push(item);
              }
            }
          }
          let office_staff_id = item.office_staff_id;

          if (item.enable_flg) {
            for (let workpattern_id in item.workpatterns.work) {
              if (!(workpattern_id in workpatterns_filtered_work)) {
                workpatterns_filtered_work[workpattern_id] = item.workpatterns.work[workpattern_id];
              }
            }
            for (let workpattern_id in item.workpatterns.holiday) {
              if (!(workpattern_id in workpatterns_filtered_holiday)) {
                workpatterns_filtered_holiday[workpattern_id] = item.workpatterns.holiday[workpattern_id];
              }
            }
          }
        }
        for (let workpattern_id in workpatterns_filtered_work) {
          this.$set(this.workpatterns_filtered, workpattern_id, workpatterns_filtered_work[workpattern_id]);
        }
        for (let workpattern_id in workpatterns_filtered_holiday) {
          this.$set(this.workpatterns_filtered, workpattern_id, workpatterns_filtered_holiday[workpattern_id]);
        }
      }

      //希望休の再カウント
      this.recountTotalMemberHopeApplyMember()

      //希望休のアラートロジック実行
      this.$refs.alertHopeHoliday.alertHopeHoliday();

      if (type === 'watch') return;

      this.updateShiftData(this.shift_data);

      this.$nextTick(() => {
        // 最適化中なら操作させないようにする処理
        if (this.summary.is_optimizing) {
          this.$refs.autoCreate.checkAutoCreationStatus();
        } else {
          // 最適化ステータスがエラーならalert表示
          if (this.summary.optimization_result === 6) {
            this.$swal('予期せぬエラーが発生しました。画面右下の「お困りですか？」をクリックし、「担当者へ問い合わせしたい」からメールアドレスを入力し、お問い合わせください。', '', 'warning');
            this.$inertia.post(
              this.$route('shifts.schedule.auto-create-status', {
                year: this.year,
                month: this.month,
                office: this.office.id,
              }),
              {
                optimization_result: 0,
              },
              {
                onSuccess:()=>{
                  //
                }
              }
            );
          }
        }
      });
    },
    // 表示するTeamの変更
    // workpatternsDataもチームで絞り込みを行う
    async changeTeam(team_id) {
      // ローディング
      this.updateLoading(true);

      // チームが変更されたら連続配置の選択を解除
      if (this.view_type == 'schedule') {
        this.selectClear();
      }
      await axios.get(this.$route('shifts.workpatterns.index', {
        year: this.year,
        month: this.month,
        office_id: this.office.id,
        team_id: team_id,
      })).then(response => {
        this.workpatternsData = response.data;

        // チームID
        this.team_id = team_id;

        // 表示する職員情報をいったんリセット
        this.basic_infos_filtered.splice(0);
        // 表示する勤務区分をいったんリセット
        Object.keys(this.workpatterns_filtered).forEach(key => {
          this.$delete(this.workpatterns_filtered, key)
        });

        // 勤務カテゴリーの勤務区分
        let workpatterns_filtered_work = {};
        // 休暇カテゴリーの勤務区分
        let workpatterns_filtered_holiday = {};

        // 更新された職員で改めて表示用のデータを作成
        for (let key in this.basic_infos) {
          let item = this.basic_infos[key];
          // 職種
          if (item.occupation_id === null) {
            item.occupation_id = '';
          }
          // 選択チームが無所属かつチームIDがnull
          // or チームIDが一致する
          // or チームIDが0（全チーム）
          // の場合
          if (
            (team_id === -1 && item.team_id === null)
            || (team_id !== 0 && item.team_id === team_id)
            || team_id === 0
          ) {
            // 当月データ or 有効な職員であれば表示用のデータに追加
            if (!this.past || item.enable_flg) {
              this.basic_infos_filtered.push(item);
            } else {
              // 過去データの場合はシフトデータが存在するか確認
              let staff_id = item.office_staff_id + '_' + item.occupation_id;
              if (staff_id in this.shift_data) {
                let exist = false;
                for (let day in this.shift_data[staff_id]) {
                  if ('shift' in this.shift_data[staff_id][day]) {
                    exist = true;
                    break;
                  }
                }
                if (exist) {
                  this.basic_infos_filtered.push(item);
                }
              }
            }

            // 有効な職員の場合は勤務区分を表示用のデータに追加
            if (item.enable_flg) {
              // 勤務用データに追加
              for (let workpattern_id in item.workpatterns.work) {
                if (!(workpattern_id in workpatterns_filtered_work)) {
                  workpatterns_filtered_work[workpattern_id] = item.workpatterns.work[workpattern_id];
                }
              }
              // 休暇用データ追加
              for (let workpattern_id in item.workpatterns.holiday) {
                if (!(workpattern_id in workpatterns_filtered_holiday)) {
                  workpatterns_filtered_holiday[workpattern_id] = item.workpatterns.holiday[workpattern_id];
                }
              }
            }
          }
        }

        // 勤務区分の表示用データに入れ直す
        for (let workpattern_id in workpatterns_filtered_work) {
          this.$set(this.workpatterns_filtered, workpattern_id, workpatterns_filtered_work[workpattern_id]);
        }
        // 休暇区分の表示用データに入れ直す
        for (let workpattern_id in workpatterns_filtered_holiday) {
          this.$set(this.workpatterns_filtered, workpattern_id, workpatterns_filtered_holiday[workpattern_id]);
        }

        //希望休の再カウント
        this.recountTotalMemberHopeApplyMember()

        // データの表示後にテーブルの各列幅を再設定
        this.$nextTick(() => {
          this.setShowColLeft();
          this.updateLoading(false);
          this.$refs.alertHopeHoliday.alertHopeHoliday(); //希望休の計算ロジック実行
        });
      }).catch(error => {
        this.updateLoading(false);
        this.$swal({
          icon: 'error',
          html: 'システムエラー',
        });
      });
    },
    // チーム名取得
    getTeamName(team_id) {
      const result_team = this.teams.find( ({ id }) => id === team_id );
      return result_team.name;
    },
    checkAutoCreateError(data) {
      if ('status_code' in data && data.status_code >= 400) {
        return true;
      } else if ('error_code' in data && data.error_code !== null && data.error_code !== '' && data.error_code.slice(0, 1) === 'E') {
        return true;
      } else {
        return false;
      }
    },
    // 休暇取得可能枠の変更
    updateHolidayLimit(data) {
      let holiday = this.new_holiday_limit.holiday;
      this.$set(holiday, data.day, data.num);
      this.$set(this.new_holiday_limit, 'change', true);
      this.$set(this.new_holiday_limit, 'holiday', holiday);
    },
    // shift_summariesデータのアップデート
    // type_id: summaryを取るデータタイプ
    // data: add: 追加， delete: 削除， '': 変化なし
    updateShiftSummaries(type_id, data, is_changed) {
      if (data === 'delete') {
        this.$set(this.new_shift_summaries, type_id, 'delete');
      } else if (Object.keys(this.new_shift_summaries).length === 0) {
        this.$set(this.new_shift_summaries, type_id + '_1', data);
      } else {
        let max_order = 0;
        for (let key in this.new_shift_summaries) {
          let order = key.split('_')[2];
          if (max_order < order) max_order = order;
        }
        this.$set(this.new_shift_summaries, type_id + '_' + (Number(max_order) + 1), data);
      }
      if (Object.keys(this.new_shift_summaries).length === Object.keys(this.shift_summaries).length) {
        this.is_changed = is_changed;
      }
    },
    // 休暇申請状況の変更
    updateApplicationStatus(data) {
      // MainTableでの変更だけでは、リアクティブにholiday_workpatternが入らないための処理（件数カウント用）
      // 休暇の勤務区分ID取得
      let workpattern_id = data.shift_apply.apply.holiday_workpattern_id;
      // 勤務区分IDから勤務区分データ取得
      let workpattern = this.workpatterns.find( ({ id }) => id === workpattern_id );
      // 更新後のnew_shift_appliesにデータを追加準備
      data.shift_apply.apply.holiday_workpattern = workpattern;

      // 項目追加なので$set
      this.$set(this.new_shift_applies[data.user.staff_id]['data'][data.day.full], 'apply', data.shift_apply.apply );

      this.is_changed = true;
    },
    // 休暇申請状況の保存
    saveApplicationStatus() {
      this.updateLoading(true);
      this.$inertia.post(
        this.$route('shifts.application-status.save', {
          year: this.year,
          month: this.month,
          office: this.office.id,
        }),
        {
          shift_applies: this.new_shift_applies,
          holiday_limit: this.new_holiday_limit,
        },
        {
          onSuccess:()=>{
            this.updateLoading(false);
            this.$swal('保存しました', '', 'success');
            this.is_changed = false;
          },
          onError: () => {
            this.updateLoading(false);
          },
        }
      );
    },
    // 休暇申請状況の変更
    updateShift(data) {
      let office_staff_id = '';
      let occupation_id = '';
      if (data.user.office_staff_id !== null) {
        office_staff_id = data.user.office_staff_id;
      }
      if (data.user.occupation_id !== null) {
        occupation_id = data.user.occupation_id;
      }
      // 冗長だがリアクティブな処理にするために $set を使って値を設定する
      let key = office_staff_id + '_' + occupation_id;
      let shift_list = this.new_shifts[key];
      let shift = shift_list[data.day.full];
      this.$set(shift, 'shift', data.shift.shift);
      this.$set(shift_list, data.day.full, shift);
      this.$set(this.new_shifts, key, shift_list);

      this.is_changed = true;
    },
    // シフトの保存処理
    saveShift() {
      this.updateLoading(true);
      this.$inertia.post(this.$route('shifts.schedule.save', {
        year: this.year,
        month: this.month,
        office: this.office.id,
      }), {
        shift_summaries: this.new_shift_summaries,
        shifts: this.new_shifts,
      }, {
        onSuccess: () => {
          this.updateLoading(false);
          this.is_changed = false;
          this.$swal('保存しました', '', 'success');
        },
        onError: () => {
          this.updateLoading(false);
        },
      });
    },
    // シフトのロックしながら保存処理
    saveWithLockShift() {
      this.updateLoading(true);
      axios.post(this.$route('shifts.schedule.save-with-lock', {
        year: this.year,
        month: this.month,
        office: this.office.id,
      }), {
        shifts: this.new_shifts,
        team_id: this.team_id
      }).then(() => {
        this.is_changed = false;
        this.updateLoading(false);
        EventBus.$emit('shift-saved-with-lock');
      }).catch(() => {
        this.updateLoading(false);
      });
    },
    // shift_applies データの最新化
    updateShiftApplies(new_data) {
      console.log('updateShiftApplies');
      Object.keys(this.new_shift_applies).forEach(key => {
        this.$delete(this.new_shift_applies, key)
      });
      for (let key in new_data) {
        this.$set(this.new_shift_applies, key, new_data[key]);
      }
    },
    updateShiftData(new_data) {
      Object.keys(this.new_shifts).forEach(key => {
        this.$delete(this.new_shifts, key)
      });
      for (let key in new_data) {
        this.$set(this.new_shifts, key, new_data[key]);
      }
    },
    handleScroll(e) {
      // コントロール取得
      let mainTable = document.getElementById("main_table");
      let subTable = document.getElementById("sub_table");

      if (e.target.id === 'main_table') {
        // シフト／休暇申請状況のスクロール
        let mainTableLeft = e.currentTarget.scrollLeft;
        subTable.scrollLeft = mainTableLeft;
      } else {
        // 勤務状況／休日取得可能枠のスクロール

        // 右端に到達した場合それ以上スクロールしないように右端固定とする
        if (subTable.scrollWidth <= subTable.clientWidth + subTable.scrollLeft) {
          subTable.scrollLeft = subTable.scrollWidth - subTable.clientWidth;
        }

        let subTableLeft = e.currentTarget.scrollLeft;
        mainTable.scrollLeft = subTableLeft;
      }
    },
    goList() {
      this.updateLoading(true);
      this.$inertia.get(this.$route('shifts.list'));
    },
    changeOpenSort() {
      this.is_open_sort = !this.is_open_sort;
      this.setShowColLeft();
    },
    changeOpenTeam() {
      this.is_open_team = !this.is_open_team;
      this.setShowColLeft();
    },
    changeOpenFlg() {
      this.is_open_flg = !this.is_open_flg;
      this.setShowColLeft();
    },
    changeOpenOccupation() {
      this.is_open_occupation = !this.is_open_occupation;
      this.setShowColLeft();
    },
    changeshowAll() {
      this.is_open_all_row = !this.is_open_all_row;
      this.setShowColLeft();
    },
    changeshowWorkpattern() {
      this.is_show_workpattern_val = !this.is_show_workpattern_val;
    },
    setShowColLeft() {
      let leftPosition = 0;

      // 行表示チェックボックスのLeft設定
      let elTarget = document.getElementsByClassName('show-all');
      for (let i = 0; i < elTarget.length; i++) {
        elTarget[i].style.left = leftPosition + 'px';
      }
      leftPosition = leftPosition + 30;
      // 並替のLeft設定
      if (this.view_type === 'schedule' && this.is_open_sort) {
        let elTarget = document.getElementsByClassName('show-sort');
        for (let i = 0; i < elTarget.length; i++) {
          elTarget[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 50;
      }
      // チーム名のLeft設定
      if (this.is_open_team) {
        let elTarget = document.getElementsByClassName('show-team');
        for (let i = 0; i < elTarget.length; i++) {
          elTarget[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 80;
      }
      // フラグのLeft設定
      if (this.is_open_flg) {
        let elTarget = document.getElementsByClassName('show-flg');
        for (let i = 0; i < elTarget.length; i++) {
          elTarget[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 40;
      }
      // 役職／名前／資格のLeft設定
      let elName = document.getElementsByClassName('show-name');
      for (let i = 0; i < elName.length; i++) {
        elName[i].style.left = leftPosition + 'px';
      }
      leftPosition = leftPosition + 180;
      // 職種のLeft設定
      if (this.is_open_occupation) {
        let elTarget = document.getElementsByClassName('show-occupation');
        for (let i = 0; i < elTarget.length; i++) {
          elTarget[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 80;
      }

      // シフト（上表・集計表）
      if (this.view_type === 'schedule') {
        // シフト表のwidth変更
        let day_width = 42;
        let category_width= 28;
        let total_add_width = 12;
        let elScheduleTable = document.getElementById('schedule-table');
        let widthScheduleTable = leftPosition + (this.days.length * day_width) + (Object.keys(this.work_categories).length * category_width) + total_add_width;
        elScheduleTable.style.width = widthScheduleTable + 'px';

        // 集計表の勤務区分のwidth・Left設定
        let totalFiexdWidth = leftPosition - 30;
        let workpatternWidth = totalFiexdWidth * 0.7;
        let elWorkpattern = document.getElementsByClassName('show-workpattern');
        for (let i = 0; i < elWorkpattern.length; i++) {
          elWorkpattern[i].style.width = workpatternWidth + 'px';
          elWorkpattern[i].style.minWidth = workpatternWidth + 'px';
          elWorkpattern[i].style.maxWidth = workpatternWidth + 'px';
        }
        // 集計表の合計のwidth・Left設定
        let totalWidth = totalFiexdWidth - workpatternWidth;
        let elTotal = document.getElementsByClassName('show-total');
        for (let i = 0; i < elTotal.length; i++) {
          elTotal[i].style.width = totalWidth + 'px';
          elTotal[i].style.minWidth = totalWidth + 'px';
          elTotal[i].style.maxWidth = totalWidth + 'px';
          elTotal[i].style.left = (workpatternWidth + 30) + 'px';
        }
        // 集計表のwidth変更
        let elTotalTable = document.getElementById('total-table');
        elTotalTable.style.width = widthScheduleTable + 'px';

      // 休暇申請状況（集計表）
      } else {
        // 希望休申請日数のLeft設定
        let elRate = document.getElementsByClassName('show-rate');
        for (let i = 0; i < elRate.length; i++) {
          elRate[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 100;
        // 承認ボタンのLeft設定
        let elApproval = document.getElementsByClassName('show-approval');
        for (let i = 0; i < elApproval.length; i++) {
          elApproval[i].style.left = leftPosition + 'px';
        }
        leftPosition = leftPosition + 90;

        // シフト表のwidth変更
        let day_width = 42;
        let elScheduleTable = document.getElementById('schedule-table');
        let widthScheduleTable = leftPosition + (this.days.length * day_width);
        elScheduleTable.style.width = widthScheduleTable + 'px';

        // 集計表のwidth変更
        let elHolidayTable = document.getElementById('holiday-table');
        elHolidayTable.style.width = widthScheduleTable + 'px';

        // 集計表の希望申請状況タイトルのwidth・Left設定
        let totalFiexdWidth = leftPosition - 30;
        let holidayTitleWidth = totalFiexdWidth * 0.5;
        let elHolidayTitle = document.getElementsByClassName('show-holiday-count');
        for (let i = 0; i < elHolidayTitle.length; i++) {
          elHolidayTitle[i].style.width = holidayTitleWidth + 'px';
          elHolidayTitle[i].style.minWidth = holidayTitleWidth + 'px';
          elHolidayTitle[i].style.maxWidth = holidayTitleWidth + 'px';
        }
        // 集計表の勤務区分のwidth・Left設定
        let workpatternWidth = totalFiexdWidth - holidayTitleWidth;
        let elWorkpattern = document.getElementsByClassName('show-workpattern');

        for (let i = 0; i < elWorkpattern.length; i++) {
          elWorkpattern[i].style.width = workpatternWidth + 'px';
          elWorkpattern[i].style.minWidth = workpatternWidth + 'px';
          elWorkpattern[i].style.maxWidth = workpatternWidth + 'px';
          elWorkpattern[i].style.left = (holidayTitleWidth + 30) + 'px';
        }
      }
    },
    changeOpenTable() {
      this.is_open_table = !this.is_open_table;

      // MainTableの高さ設定
      this.getMainTableHeight();
    },
    // 連続配置ON／OFF
    changeConsecutiveWorkpattern(val) {
      this.is_consecutive_workpattern = val;

      // trueの時はシフト選択ダイアログを開く
      if (this.is_consecutive_workpattern) {
        this.showShiftChangeDialog();
      } else {
        this.selectClear();
      }
    },
    // シフト選択ダイアログopen
    showShiftChangeDialog() {
      /** #114 フロント側でワークパターンのテーブル作成するパターン **/
      let set_item = {
                        lastname: "",
                        firstname: "",
                        workpatterns: {
                          holiday: {},
                          work: {}
                        }
                     };

      for (let key in this.workpatternsData) {
        let workpattern = this.workpatternsData[key];
        if (workpattern.type === 'work' || workpattern.type === 'half_work') {
          set_item.workpatterns.work[key] = workpattern;
        } else {
          set_item.workpatterns.holiday[key] = workpattern;
        }
      }

      let data = {
        item: set_item,
        main_id: null,
        replacement_id: null,
      };

      this.$refs.shiftChangeDialog.openDialog(data);
    },
    // 連続配置用に選択されたシフトデータ保存
    selectShift(is_changed, workpattern_id, holiday_workpattern_id, entry_user_code) {
      this.consecutive_shift.is_changed = is_changed;
      this.consecutive_shift.main_id = workpattern_id;
      let main_workpattern_data = this.workpatterns.find(workpattern => workpattern.id === workpattern_id);
      this.consecutive_shift.main_name = main_workpattern_data ? main_workpattern_data.name : null;
      if (holiday_workpattern_id !== null && holiday_workpattern_id !== '') {
        this.consecutive_shift.replacement_id = holiday_workpattern_id;
        let replacement_workpattern_data = this.workpatterns.find(workpattern => workpattern.id === holiday_workpattern_id);
        this.consecutive_shift.replacement_name = replacement_workpattern_data ? replacement_workpattern_data.name : null;
      }
      this.consecutive_shift.entry_user_code = entry_user_code;
    },
    // 連続配置クリア
    selectClear() {
      this.consecutive_shift.is_changed = false;
      this.consecutive_shift.main_id = null;
      this.consecutive_shift.main_name = null;
      this.consecutive_shift.replacement_id = null;
      this.consecutive_shift.replacement_name = null;
      this.consecutive_shift.entry_user_code = null;
      // ConecutiveWorkpatternコンポーネントがロードされる前に実行される場合があるため
      if (this.$refs.ConsecutiveWorkpattern) {
        this.$refs.ConsecutiveWorkpattern.switch_consecutive_workpattern = false;
      }
      this.is_consecutive_workpattern = false;
    },
    // 作成モードの変更
    changeEditMode() {
      this.edit_mode = !this.edit_mode;

      // MainTableの高さ設定
      this.getMainTableHeight();
    },
    // getSubTable（上側表）の幅設定
    getSubTableWidth() {
      // work_categories数を算出
      let workCategoriesCount = 0;
      // 休暇以外のカテゴリ数をカウント
      for (let key in this.work_categories) {
        if (key !== 'holiday') {
          workCategoriesCount++;
        }
      }
      // 以下分をカウント数に追加　所定休日・有休・その他
      workCategoriesCount = workCategoriesCount + 3;

      // テーブル幅算出
      let minusWidth = 0;
      if (this.edit_mode) {
        minusWidth = 95 + (workCategoriesCount * 32) + 40;
      } else {
        minusWidth = 340;
      }

      let setStyle = 'width: calc(100% - ' + minusWidth + 'px);';

      return setStyle;
    },
    // MainTableの高さ設定
    getMainTableHeight() {
      let minusHeight = 0;

      // シフト
      if (this.view_type === 'schedule') {
        minusHeight = 415;
        // 差し引く高さを算出
        if (this.edit_mode) {
          minusHeight = minusHeight - 310;
          if (!this.is_open_table) {
            minusHeight = minusHeight - 150;
          }
        } else {
          if (!this.is_open_table) {
            minusHeight = minusHeight - 170;
          }
        }
      // 希望休申請状況
      } else {
        minusHeight = 395;
        // 差し引く高さを算出
        if (!this.is_open_table) {
          minusHeight = minusHeight - 150;
        }
      }

      // 新しい高さを設定
      let psContainer = document.querySelector('.mainTableHeight .ps-container');
      if (psContainer) {
        let newHeight = 'calc(100vh - ' + minusHeight + 'px)';
        psContainer.style.height = newHeight;
      }
    },
    // 表示の切り替え
    showAllRow(all_show_row) {
      this.is_open_all_row = all_show_row;
    },
    /**
     * スタッフ並び順のソート
     */
    sortStaffsBy(sort_item) {
      if (sort_item === '') return;
      const sort_key = sort_item.split('|')[0];
      // 昇順降順が指定されている場合には|で区切られている
      const sort_type = sort_item.split('|')[1] || 'asc';

      // 複数キーでソート
      let result = 0;
      this.basic_infos_filtered.sort((a, b) => {
        // null対策
        if (a[sort_key] === null || a[sort_key] === '') {
          return 1;
        }
        if (b[sort_key] === null || b[sort_key] === '') {
          return -1;
        }
        if (a[sort_key] === b[sort_key]) {
          return 0;
        }

        // null以外ソート
        if (a[sort_key] < b[sort_key]) {
          result = -1;
        } else if (a[sort_key] > b[sort_key]) {
          result = 1;
        } else {
          result = 0;
        }

        if (sort_type === 'desc') {
          result = result * -1;
        }
        return result;
      });
    },
    //ログインしたユーザーのデフォルトチームのteam_idの設定
    setLoginUserTeamId(){
      const office_match = this.default_teams.find(default_team => default_team.office_id === this.office.id);
      if (office_match) {
        const team_match = this.teams.find(team => team.id === office_match.team_id);
        if (team_match) {
          return team_match.id;
        }
      }
      return 0;
    },
    recountTotalMemberHopeApplyMember(){
      // 職員の総数を再カウント
      const total_count_map = Array.from(
        new Map(this.basic_infos_filtered.map((basic_infos) => [basic_infos.staff_id, basic_infos])).values()
      );

      // 希望休申請済み対象者を再カウント
      const current_request_map = total_count_map.filter((map) => map.is_submission_applies);
      this.change_total_members_count = total_count_map.length;
      this.change_current_request_count = current_request_map.length;
    },
    getFilteredOfficeStaffWorkpattern(){
      let office_staff_workpattern_flags = {};
      let settings_office_staff_workpatterns = {};
      this.office_staff_workpatterns.forEach(office_staff_workpattern => {
        const {
          office_staff_id,
          workpattern_id,
          min_monthly_working_count,
          max_monthly_working_count,
        } = office_staff_workpattern;

        // IDごとの設定が未定義の場合は初期化
        if (!office_staff_workpattern_flags[office_staff_id]) {
          office_staff_workpattern_flags[office_staff_id] = {};
          settings_office_staff_workpatterns[office_staff_id] = {};
        }
        office_staff_workpattern_flags[office_staff_id][workpattern_id] = true;

        const workpattern = this.workpatterns.find(pattern => pattern.id === workpattern_id);
        const category = workpattern ? workpattern.category : null;

        const basic_user = this.basic_infos.find(user => user.office_staff_id === office_staff_id);
        let user_workpattern = [];
        if (basic_user) {
            Object.values(basic_user.workpatterns).forEach(workpattern => {
                Object.values(workpattern).forEach(element => {
                    user_workpattern.push(element);
                });
            });
          }
        const compare_category = user_workpattern.find(workpattern => workpattern.id === workpattern_id);
        let workpattern_name = '';
        let workpattern_type = '';
        if (compare_category) {
          workpattern_name = compare_category.name;
          workpattern_type = compare_category.type;
        }
        settings_office_staff_workpatterns[office_staff_id][workpattern_id] = {
          min_monthly_working_count,
          max_monthly_working_count,
          category,
          workpattern_name,
          workpattern_type
        };
      });

      this.filtered_office_staff_workpattern = settings_office_staff_workpatterns;

    }
  },
  computed: {
    user() {
      return this.$page.props.auth.user
    },
    lodgment() {
      return this.$page.props.auth.lodgment
    },
    last_updated_at() {
      let date = '';
      if (this.view_type === 'application_status') {
        date = this.summary.last_updated_at_status;
      } else {
        date = this.summary.last_updated_at_shift;
      }
      if (date === null) {
        return '';
      } else {
        return date.slice(0, 16).replaceAll('-', '/');
      }
    },
  },
  watch: {
    basic_infos: {
      handler(new_data, old_data) {
        console.log('watcher basic_infos');
        this.initialize('watch');

        this.changeTeam(this.team_id);
      },
      deep: true,
    },
    shift_applies: {
      handler(new_data, old_data) {
        console.log('watcher shift_applies');
        this.updateShiftApplies(structuredClone(new_data));
      },
      deep: true,
    },
    // ステータスを監視
    summary: {
      handler(new_data, old_data) {
        console.log('watcher summary');
        if (this.view_type === 'schedule' && !new_data.is_optimizing) {
          this.$nextTick(() => {
            // 最適化ステータスがエラーならalert表示
            if (new_data.optimization_result === 6) {
              this.$swal('予期せぬエラーが発生しました。画面右下の「お困りですか？」をクリックし、「担当者へ問い合わせしたい」からメールアドレスを入力し、お問い合わせください。', '', 'warning');
              this.$inertia.post(
                this.$route('shifts.schedule.auto-create-status', {
                  year: this.year,
                  month: this.month,
                  office: this.office.id,
                }),
                {
                  optimization_result: 0,
                },
                {
                  onSuccess:()=>{
                    //
                  }
                }
              );
            }
          });
        }
      },
      deep: true,
    },
    // シフトデータの変更を監視
    shift_data: {
      handler(new_data, old_data) {
        console.log('watcher shift_data');
        this.updateShiftData(structuredClone(new_data));
      },
      deep: true,
    },
    // 自動再生処理の結果を監視
    auto_create_error_response: {
      handler(new_data, old_data) {
        console.log('watcher auto_create_error_response');
        console.log(new_data);
        if (this.checkAutoCreateError(new_data)) {
          this.$swal({
            html: '自動作成中にエラーが発生しました。しばらくたってから再度自動作成いただくか、解消しない場合は<a href="' + this. contact_form_link + '" target="_blank" rel="noopener noreferrer">サポート</a> までご連絡ください。',
            icon: 'warning',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          });
        }
      },
      deep: true,
    },
  },
  created() {
    let target_url = window.location.href;
    if ( target_url.indexOf('dev') !== -1 || target_url.indexOf('stg') !== -1 ) {
      this.is_stg = true;
    } else {
      this.is_stg = false;
    }

    // 画面を閉じる際に発火
    window.addEventListener("beforeunload", this.confirmSave);

    EventBus.$on('shift-save-with-lock', this.saveWithLockShift);

    this.team_id = this.setLoginUserTeamId();
  },
  mounted() {
    this.updateLoading(false);
    // 表示年月が現在年月より過去かどうか
    this.past =
      parseInt(this.year.toString() + this.month.toString())
        < parseInt(this.current_year.toString() + this.current_month.toString());

    if (this.is_redirect) {
        const url = route('lodgments.plan-edit');
        const message = `機能制限無料プランでのご利用のため、過去のシフトの参照ができません。過去のシフトを参照いただく場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        });
    }

    // Footerのダウンロードボタンエリア制御
    if (document.getElementById('shiftFooterArea') !== null) {
      let elShiftFooter = document.getElementById('shiftFooterArea');
      let isOpen = parseInt(this.$cookie.get('sideber_open'));

      if (isOpen === 0) {
        elShiftFooter.classList.remove('closed-shift_footer');
      } else {
        elShiftFooter.classList.add('closed-shift_footer');
      }
    }

    // MainTableの高さ設定
    this.getMainTableHeight();
    this.getFilteredOfficeStaffWorkpattern();

    this.initialize('mounted');
    this.workpatternsData = this.workpatterns;
  },
  beforeDestroy() {
    // イベントリスナー削除
    window.removeEventListener("beforeunload", this.confirmSave);
    this.updateLoading(false);
    if (this.view_type === 'schedule') {
      //this.$refs.autoCreate.destroy();
    }

    EventBus.$off('shift-save-with-lock', this.saveWithLockShift);
  },
  destroyed () {
  },
}
</script>
