<template>
  <div>
    <div style="display:flex; width: auto; margin-right: 25px;">
      <p>表示年月</p>
      <v-select
        :items="years"
        item-text="text"
        item-value="value"
        item-disabled="disabled"
        no-data-text="データが存在しません"
        @change="changeYear"
        return-object
        v-model="target_year"
        hide-details="auto"
        class="small-control"
        style="min-width: 90px; max-width: 90px;"
      >
      </v-select>
      <p style="margin: auto 8px; vertical-align: middle;">年</p>
      <v-select
        :items="month_list"
        item-text="text"
        item-value="value"
        no-data-text="データが存在しません"
        @change="changeMonth"
        return-object
        v-model="target_month"
        hide-details="auto"
        class="small-control"
        style="min-width: 75px; max-width: 75px;"
      >
      </v-select>
      <p style="margin: auto 8px; vertical-align: middle;">月</p>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import dayjs from 'dayjs';
export default {
  name: 'YearMonthSelect',
  components: {
  },
  props: {
    view_type: {
      type: String,
      default: 'application_status'
    },
    // years
    years: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // months
    months: {
      type: Array,
      default: () => {
        return [];
      }
    },
    initial_year: {
      type: String,
      default: 0
    },
    initial_month: {
      type: String,
      default: 0
    },
    office_id: {
      type: Number,
      default: 0
    },
    current_year: {
      type: String,
      default: 0
    },
    current_month: {
      type: String,
      default: 0
    },
    is_free_plan: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      year_list: [],
      month_list: [],
      target_year: '',
      target_month: '',
    }
  },
  methods: {
    // 選択可能な「年」を制御
    updateYearList(month) {
      this.year_list.splice(0);
      const current = dayjs();
      for (let row of this.years) {
        if (row.value <= current.format('YYYY')) {
          row.disabled = false;
        } else if (current.format('MM') < '10') {
          row.disabled = false;
        } else if (current.format('MM') === '10' && month <= '01') {
          row.disabled = false;
        } else if (current.format('MM') === '11' && month <= '02') {
          row.disabled = false;
        } else if (current.format('MM') === '12' && month <= '03') {
          row.disabled = false;
        } else {
          row.disabled = true;
        }
        this.month_list.push(month);
      }
    },
    // 選択可能な「月」を制御
    updateMonthList(year) {
      this.month_list.splice(0);
      for (let month of this.months) {
        month.disabled =
          (year + '-' + month.value + '-01') > dayjs().set('date', 1).add(3, 'M').format('YYYY-MM-DD');
        this.month_list.push(month);
      }
    },
    // 年変更処理
    changeYear(e) {
      let route = '';
      if (this.view_type === 'application_status') {
        route = 'shifts.application-status';
      } else {
        route = 'shifts.schedule';
      }

      let year_month = '' + e.value + this.target_month;
      let current_ym = '' + this.current_year + this.current_month;
      if(this.is_free_plan && year_month < current_ym){
        const url = this.$url('/lodgments/plan-edit');
        const message = `機能制限無料プランでのご利用のため、過去のシフトの参照ができません。過去のシフトを参照いただく場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        });
        this.target_year = e.value;
        return;
      }
      this.updateLoading(true);
      this.$inertia.get(this.$route(route, {
        year: e.value,
        month: this.target_month,
        office: this.office_id
      }));
    },
    changeMonth(e) {
      let route = '';
      if (this.view_type === 'application_status') {
        route = 'shifts.application-status';
      } else {
        route = 'shifts.schedule';
      }

      let year_month = '' + this.target_year + e.value;
      let current_ym = '' + this.current_year + this.current_month;

      if(this.is_free_plan && year_month < current_ym){
        const url =this.$url('/lodgments/plan-edit');
        const message = `機能制限無料プランでのご利用のため、過去のシフトの参照ができません。過去のシフトを参照いただく場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`;
        this.$swal({
            icon: 'info',
            html: message,
        });
        this.target_month = e.value;
        return;
      }
      this.updateLoading(true);
      this.$inertia.get(this.$route(route, {
        year: this.target_year,
        month: e.value,
        office: this.office_id
      }));
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
    this.target_year = this.initial_year;
    this.target_month = this.initial_month;

    this.updateYearList(this.target_month);
    this.updateMonthList(this.target_year);
  }
}
</script>
