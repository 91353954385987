<template>
    <div style="font-size: 14px;">
        <div style="margin-bottom: 24px;">
            <a href="list" @click="pageSend">勤務区分マスタ</a><span style="color: #D2D6DB; margin: 0px 10px;">></span><span style="color: #6C737F">勤務区分の追加</span>
        </div>
        <h4 style="margin-bottom: 16px;">勤務区分の追加</h4>
        <p style="color: #384250; margin-bottom: 32px; padding: 0px;">勤務区分は「早番」「日勤」「遅番」「夜勤」「明番」を設定することができ、シフト自動作成で職員に割り当てることができます。</p>
        <WorkpatternForm
            :errors="errors"
            :worktypes="worktypes"
            :activatable="activatable"
            :workcategories_work="workcategories_work"
            :workcategories_rest="workcategories_rest"
            :workpatterncolors="workpatterncolors"
            :external_kintai_list="external_kintai_list"
            :form="form"
            :dawn_work="dawn_work"
            @submit="save"
        ></WorkpatternForm>
    </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import WorkpatternForm from '@/Pages/Workpatterns/WorkpatternForm.vue'

export default {
    components: {
        WorkpatternForm,
    },
    props: {
        errors: {
            type: Object,
            required: false,
        },
        errorBags: {
            required: false,
        },
        worktypes: {
            type: Object,
            required: true
        },
        workcategories_work: {
            type: Array,
            required: true
        },
        workcategories_rest: {
            type: Array,
            required: true
        },
        workpatterncolors: {
            type: Object,
            required: true
        },
        activatable: {
            type: Boolean,
            required: true
        },
        dawn_work: {
            type: Array,
            required: true
        },
        next_day_flg: {
            type: Boolean,
        },
        external_kintai_list :{
            type : Array,
            required :true
        },
        default_external_kintai_id :{
            type : Number,
            required :true
        },
    },
    data() {
        return {
            form: Inertia.form({
                name: null,
                external_link_id: null,
                work_type: 'work',
                work_category: 'early',
                color: null,
                working_start_time: null,
                working_end_time: null,
                working_hours: null,
                active: true,
                rest_start_time: null,
                rest_end_time: null,
                rest_time: null,
                next_workpattern_id: null,
                workpattern_kintais: [{
                    external_kintai_id: this.default_external_kintai_id,
                    external_link_id: '',
                }],
            }),
        };
    },
    mounted: function() {
        this.updateLoading(false);
        this.form.color = "#FFFFFF";
        this.form.active = this.activatable;
    },
    methods: {
        pageSend() {
            // Ctrl+クリックでメニューを開いたときはローディングはしない
            if (event && !event.ctrlKey) {
                this.updateLoading(true);
            }
        },
        updateLoading(flg) {
            EventBus.$emit('updateLoading', flg);
        },
        save() {
            this.updateLoading(true);
            this.form.post(route('workpatterns.store'), {
                onSuccess:(results)=>{
                    var warnings_list = '';
                    Object.keys(results.props.warnings).forEach(function (key) {
                        var warning_row = results.props.warnings[key];
                        warnings_list = '<br />' + warnings_list + warning_row;
                    });
                    if(warnings_list !== ""){
                        warnings_list = warnings_list;
                    }
                    this.updateLoading(false);
                    this.$swal({
                        title: '保存しました',
                        html: warnings_list,
                        icon: 'success',
                    });
                },
                onError: () => {
                    this.updateLoading(false);
                },
            });
        },
    },
};
</script>
