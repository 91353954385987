import { render, staticRenderFns } from "./OfficeStaff.vue?vue&type=template&id=63f523c1&scoped=true&"
import script from "./OfficeStaff.vue?vue&type=script&lang=js&"
export * from "./OfficeStaff.vue?vue&type=script&lang=js&"
import style0 from "./OfficeStaff.vue?vue&type=style&index=0&id=63f523c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f523c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VAutocomplete,VCombobox})
