<template>
  <div class="m-3 p-3">
    <h4>資格　編集</h4>
    <form class="pt-5">
      <b-row>
        <b-col class="pr-0 py-1" cols="4" md="2">
          <label for="obtained_date">資格取得日</label>
        </b-col>
        <b-col class="py-1" cols="8" md="4">
          <datepicker
            class="form-control"
            id="obtained_date"
            @date="setObtainedDate"
            :default="form.obtained_date"
            :para_err="errors.obtained_date"
            :max="max"
            :class="{'is-invalid': errors.obtained_date, 'error--text': errors.obtained_date}"
          />
          <div v-if="errors.obtained_date" class="invalid-feedback">{{ errors.obtained_date }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pr-0 py-1" cols="4" md="2">
          <label for="position_name">資格名</label>
        </b-col>
        <b-col class="py-1" cols="8" md="4">
          <b-form-select
            v-model="form.qualification_id"
            placeholder="資格を選択してください"
          >
            <option value="" disabled selected style="display:none;">資格を選択してください。</option>
            <option
              v-for="item in qualification_options.data"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
          <div v-if="front_errors.qualification_id" class="invalid-feedback">資格は必ず指定してください。</div>
        </b-col>
      </b-row>
      <!-- error message -->
      <div v-if="errors.profile" class="invalid-feedback">
        {{ errors.profile }}
      </div>
      <b-row class="pt-5">
        <b-col class="py-1" cols="12" md="2">
          <b-button block variant="primary" size="lg" :disabled="isDisabled" @click="editQualification">保存</b-button>
        </b-col>
        <b-col class="py-1" cols="12" md="2">
          <b-button block variant="primary" size="lg" @click="gotoList">キャンセル</b-button>
        </b-col>
      </b-row>
    </Form>
  </div>
</template>

<script>
import datepicker from "../Components/MyDatePicker";
import moment from "moment"

export default {
  components: {
    datepicker,
  },
  props: {
    qualification_staff_id: {
      type: Number,
      required: true
    },
    qualification_id: {
      type: Number,
      required: true
    },
    qualification_name: {
      type: String,
      required: true
    },
    qualification_obtained_date: {
      type: String,
      required: true
    },
    qualification_options: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
    },
  },

  data: function() {
    return {
      form: this.$inertia.form({
        qualification_id: '',
        obtained_date: '',
      }),
      front_errors: {},
      max: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    isDisabled() {
      return Object.keys(this.front_errors).length > 0 ? true : false
    }
  },
  watch: {
    'form.qualification_id': function(newValue ,oldValue) {
      if (newValue!='' & newValue != null) {
        this.$delete(this.front_errors, 'qualification_id')
      } else {
        this.$set(this.front_errors, 'qualification_id', '資格を入力してください。')
      }
    },
    'form.obtained_date': function(newValue,oldValue) {
      if (newValue!='' & newValue != null & this.isDate(newValue)) {
        this.$delete(this.front_errors, 'obtained_date')
      } else if(!this.isDate(newValue)) {
        this.$set(this.front_errors, 'obtained_date', '形式が正しくありません。')
      } else {
        this.$set(this.front_errors, 'obtained_date', '資格取得日を入力してください。')
      }
    },
  },
  created: function() {
    this.form.qualification_id = this.qualification_id;
    this.form.obtained_date = this.qualification_obtained_date;
  },

  methods: {
    gotoList: function () {
      this.$inertia.get(route('profiles.index'))
    },
    editQualification: function () {
      this.form.put(route('profiles.update', this.qualification_staff_id),
        {
          preserveScroll: true,
          onError: (p) => {
            this.$swal(
              'error',
              '資格の変更に失敗しました。しばらくたってから再試行してください。',
              'error')
          },
          onSuccess:()=>{
            this.$swal(
              '資格を変更しました',
              '',
              'success')
          }
        })
    },
    isDate(strDate){
      if(!strDate.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/)){
          return false;
      }
      var y = strDate.split("/")[0];
      var m = strDate.split("/")[1] - 1;
      var d = strDate.split("/")[2];
      var date = new Date(y,m,d);
      if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
          return false;
      }
      return true;
    },
    setObtainedDate(date) {
      this.form.obtained_date = date;
    },
  }
}
</script>

<style>
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #d92550;
}
</style>
