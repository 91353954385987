<template>
  <sidebar-menu
    v-if="role == 'admin'"
    showOneChild
    :menu="menu_admin"
    :link-component-name="'custom-link'"
    style="padding: 10px;"
    @item-click="onItemClick"
  />
  <sidebar-menu
    v-else
    showOneChild
    :menu="menu_manager"
    :link-component-name="'custom-link'"
    style="padding: 10px;"
    @item-click="onItemClick"
  />
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: 'SidebarList',
  components: {
    SidebarMenu,
  },
  data() {
    return {
      // テナント管理者
      menu_administrator: [
        {
          title: "事業者管理",
          icon: "pe-7s-network",
          child: [
            {
              href: this.$url("/"),
              title: "アカウント"
            },
            {
              href: this.$url("/"),
              title: "プラン設定"
            },
            {
              href: this.$url("/"),
              title: "利用者情報"
            },
            {
              href: this.$url("/"),
              title: "ヘルスケア統計"
            }
          ]
        },
      ],
      // システム管理者 admin
      menu_admin: [
        {
          title: "シフト",
          icon: "pe-7s-date",
          child: [
            {
              href: this.$url("/shifts/application-status"),
              title: "希望申請状況"
            },
            {
              href: this.$url("/shifts/schedule"),
              title: "シフト"
            }
          ]
        },
        // {
        //   title: "勤務形態一覧表",
        //   icon: "pe-7s-calculator",
        //   child: [
        //     {
        //       href: this.$url("/fulltime-conversions/list"),
        //       title: "一覧"
        //     },
        //     //{
        //     //  href: this.$url("/fulltime-conversions/result"),
        //     //  title: "常勤換算表"
        //     //}
        //   ]
        // },
        {
          title: "設定",
          icon: "pe-7s-settings",
          child: [
            {
              href: this.$url("/lodgments/edit"),
              title: "拠点管理"
            },
            {
              href: this.$url("/offices/list"),
              title: "事業所管理"
            },
            {
              href: this.$url("/staffs/list"),
              title: "職員管理"
            },
            {
              href: this.$url("/teams/list"),
              title: "チーム管理"
            }
          ]
        },
        {
          title: "マスタ管理",
          icon: "pe-7s-server",
          child: [
            {
              href: this.$url("/workpatterns/list"),
              title: "勤務区分マスタ"
            },
            {
              href: this.$url("/positions/list"),
              title: "役職マスタ"
            }
          ]
        },
        {
          title: "外部連携",
          icon: "pe-7s-refresh-cloud",
          child: [
            {
              href: this.$url("/external-kintais"),
              title: "勤怠システム"
            },
          ]
        },
        {
          title: "Oura連携",
          icon: "pe-7s-server",
          disabled: !this.lodgment.oura_enable,
          child: [
            {
              href: this.$url("/oura/activeusers"),
              title: "連携済ユーザ"
            },
            {
              href: this.$url("/oura/analysis"),
              title: "解析データ"
            }
          ]
        },
        {
          title: "ヘルプ",
          icon: "pe-7s-help1",
          href: "https://support.synchroshift.jp/portal/ja/kb/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F",
          external: true
        },
        {
          title: "マニュアル",
          icon: "pe-7s-note",
          href: "https://support.synchroshift.jp/portal/ja/kb/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F/%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB-%E5%90%84%E7%A8%AE%E8%B3%87%E6%96%99",
          external: true
        }
      ],
      // シフト管理者 manager
      menu_manager: [
        {
          title: "シフト",
          icon: "pe-7s-date",
          child: [
            {
              href: this.$url("/shifts/application-status"),
              title: "希望申請状況"
            },
            {
              href: this.$url("/shifts/schedule"),
              title: "シフト"
            }
          ]
        },
        {
          title: "勤務形態一覧表",
          icon: "pe-7s-calculator",
          child: [
            {
              href: this.$url("/fulltime-conversions/list"),
              title: "一覧"
            },
            //{
            //  href: this.$url("/fulltime-conversions/result"),
            //  title: "常勤換算表"
            //}
          ]
        },
        {
          title: "設定",
          icon: "pe-7s-settings",
          child: [
            {
              href: this.$url("/offices/list"),
              title: "事業所管理"
            },
            {
              href: this.$url("/staffs/list"),
              title: "職員管理"
            },
            {
              href: this.$url("/teams/list"),
              title: "チーム管理"
            }
          ]
        },
        {
          title: "マスタ管理",
          icon: "pe-7s-server",
          child: [
            {
              href: this.$url("/positions/list"),
              title: "役職マスタ"
            }
          ]
        },
        {
          title: "外部連携",
          icon: "pe-7s-refresh-cloud",
          child: [
            {
              href: this.$url("/external-kintais"),
              title: "勤怠システム"
            },
          ]
        },
        {
          title: "ヘルプ",
          icon: "pe-7s-help1",
          href: "https://support.synchroshift.jp/portal/ja/kb/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F",
          external: true
        },
        {
          title: "マニュアル",
          icon: "pe-7s-note",
          href: "https://support.synchroshift.jp/portal/ja/kb/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F/%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB-%E5%90%84%E7%A8%AE%E8%B3%87%E6%96%99",
          external: true
        }
      ],
    };
  },
  props: {
    role: String,
    lodgment: Object,
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    onItemClick(event, item) {
      if (item.external) {
        window.open(item.href, '_blank');
        event.preventDefault();
        return;
      }
      if (!item.icon) {
        this.updateLoading(true);
      }
      console.log(event);
      console.log(item);
      this.$emit('openSideber');
    },
  },
  computed: {
  },
  mounted() {
  },
  beforeDestroy() {
  }
};
</script>
