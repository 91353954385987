<template>
  <div>
    <div style="font-size: 14px; width: 100%;">
      <b-row style="margin: 0px 0px 32px 0px;">
        <b-col cols="6" style="padding: 0px;">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="キーワード検索"
            outlined
            hide-details
            dense
            style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 8px;"
          ></v-text-field>
        </b-col>
        <b-col cols="6" style="display:flex; justify-content: flex-end;">
          <b-button class="btn_white" style="height: 40px!important;" @click="addItem()">
            <font-awesome-icon icon="plus" class="icon-style" />チームを追加
          </b-button>
          <b-button class="btn_white" style="height: 40px!important;" @click="csvimport()">
            <font-awesome-icon icon="upload" class="icon-style" />CSVインポート
          </b-button>
        </b-col>
      </b-row>
      <div class="table-teams-wrap">
        <v-data-table
          :headers = "headers"
          :items = "items"
          :items-per-page = "teams_list"
          :footer-props = "footerProps"
          :search="search"
          class="elevation-1 table-teams-list"
          fixed-header
          no-data-text="チームが存在しません"
          no-results-text="データが存在しません"
          @update:items-per-page="savePerPage"
        >
          <template v-slot:item.actions="{ item }">
            <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i>変更</b-button>
            <b-button class="btn_actions" @click="deleteItem(item)" style="padding-right: 0px;"><i class="pe-7s-trash"></i>削除</b-button>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
            {{ props.pageStop }} 件までを表示
          </template>
        </v-data-table>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
      <b-button class="btn_white btn_autocreatefooter" @click="back">戻る</b-button>
      <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="next">次へ</b-button>
    </div>
  </div>
</template>

<style scoped>
.table-teams-wrap {
  min-height: calc(95vh - 350px);
  max-height: calc(95vh - 350px);
}
.btn_white {
  height: 37px!important;
  margin-left: 20px;
}
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import axios from 'axios';
Vue.use(VueCookie);

export default {
  components :{
    VuePerfectScrollbar,
  },
  props : {
    teams :{
      type:Object,
      required :true
    }
  },
  computed : {
    items () {
      return this.teams.data
    }
  },
  data () {
    // console.log('【チームデータ】', this.teams.data)
    return {
      search: '',
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text' : '表示件数： ',
        'items-per-page-all-text' : 'すべて',
      },
      headers: [
        { text: '事業所名', value: 'office_name' },
        { text: 'チーム名', value: 'name', width: '500px'},
        { text: '職員数', value: 'num_of_staff', width: '200px'},
        { text: '操作', value: 'actions',  width: '300px', sortable: false },
      ],
      teams_list: Number(this.$cookie.get('teams_list')),
    }
  },
  methods: {
    // 戻る
    back() {
      this.$emit('nextStep', 1);
    },
    // 次へ
    next() {
      this.$emit('nextStep', 3);
    },
    savePerPage(val) {
      this.$cookie.set('teams_list', val, 1);
    },
    addItem() {
      this.$emit('nextStep', 2.1);
    },
    editItem (item) {
      this.updateLoading(true);
      axios.get(this.$route('shifts.teams.edit', item.id))
      .then(result => {
        this.$emit('updateTeamData', result.data);
        this.$emit('nextStep', 2.2);
        this.updateLoading(false);
      })
    },
    deleteItem (item) {
      this.$swal({
        title: "本当に削除しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          axios.delete(this.$route('shifts.teams.destroy', { id: item.id }))
            .then(result => {
              this.updateLoading(false);
              this.$swal(
                'チームを削除しました',
                '',
                'success')
              this.$emit('getTeamsData');
            });
        }
      })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    /**
     * CSVアップロード機能
     */
     csvimport() {
        this.$swal({
            text: "チーム（CSV）をインポートします。",
            icon: 'warning',
            showCancelButton: true,
            input: 'file',
            inputAttributes: {
                name: "csvupload",
                id: "csvupload",
                accept: "text/csv",
                multiple: "multiple"
            },
            confirmButtonText: 'アップロード',
            cancelButtonText: 'キャンセル',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                this.updateLoading(true);
                let formData = new FormData();
                formData.append('csv', result.value[0]);

                axios.post(this.$route('shifts.teams.csvupload'), formData)
                .then(response => {
                    this.updateLoading(false);
                    this.$swal('success', 'CSV登録が完了しました。', 'success');

                    this.$emit('getTeamsData');
                })
                .catch(error => {
                    this.updateLoading(false);
                    let errorMessages = '';
                    let parsed_message = {};

                    try {
                        // エラーレスポンスのデータをパース
                        parsed_message = error.response.data.errors;
                    } catch (e) {
                        parsed_message = {};
                        if (error.response && error.response.data && error.response.data.csv_upload) {
                            errorMessages = error.response.data.csv_upload;
                        } else {
                            errorMessages = 'エラーが発生しました。';
                        }
                    }
                    
                    try{
                      //ファイルの形式エラー
                      if(error.response.data.message){
                        errorMessages = error.response.data.message
                      } else {
                        //csvアップロード時のエラー
                        const parsed_error = JSON.parse(parsed_message["csv_upload"][0]);
                        Object.keys(parsed_error).forEach(index => {
                            let errorMessage = [];
                            const message = parsed_error[index].message;

                            try {
                                Object.keys(JSON.parse(message)).forEach(field => {
                                    JSON.parse(message)[field].forEach(msg => {
                                        errorMessage.push(msg);
                                    });
                                });
                                errorMessages += `${parseInt(index, 10) + 1}行目エラー：${errorMessage.join(' ')}<BR>`;
                            } catch (e) {
                                errorMessages += `${parseInt(index, 10) + 1}行目エラー：データ不正です。<BR>`;
                            }
                        });
                      }
                    }catch(e){
                        errorMessages += `エラーの詳細は取得できませんでした。<BR>`;
                    }
                    this.$swal({
                        title: 'error',
                        html: 'CSV登録に失敗しました。<BR>再度やり直してください。<BR><BR>【エラー詳細】<BR>--------------------------------------<div style="text-align:left;padding-right:15%;padding-left:15%;font-size: 0.8em;">' + errorMessages + '</div>',
                        icon: 'error'
                    });
                })
                .finally(() => {
                    this.updateLoading(false);
                    this.show = false;
                });
            } else {
                this.updateLoading(false);
                this.show = false;
            }
        });
    },
  },
  mounted() {
	  this.updateLoading(false);
  },
};
</script>
