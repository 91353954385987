<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="650">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div></div>
          <div>希望休エラー</div>
          <div><button class="btn_close" @click="dialog = false">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <p v-for="(val, key) in filterd_days_hope_holiday_error">
            {{ val.format_month_day }} 希望休の重複により日ごとに必要な職員を配置できません <br>
          </p>
          <!-- 〇/〇 希望休の重複により日ごとに必要な職員を配置できません<br>〇/〇 希望休の重複により日ごとに必要な職員を配置できません -->
        </v-card-text>

        <v-card-text style="padding: 30px 70px;">
          <p class="title_green_line">
            ■ 解消方法
          </p>
          <p>
            休暇の調整または、日ごとに必要な職員数（常時配置人数の下限値）を変更してください。
          </p>
        </v-card-text>

        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
            <div style="display: flex; justify-content: center; width: 100%; padding: 0px 70px 70px 70px;">
            <button class="btn_gray_shadow" style="width:150px; padding: 5px 0;" @click="dialog = false">閉じる</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>

</template>

<style scoped>
a {
  color: #1059CC!important;
  text-decoration: underline!important;
}
a:hover{
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'AlertHopeHolidayDialog',
  components: {
  },
  props: {
    filterd_days_hope_holiday_error:{
      type: Array
    }
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
  },
  computed: {
  },
  mounted() {
  }
}
</script>

