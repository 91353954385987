<template>
  <div class="m-3 p-3">
    <Loading :loading="loading"></Loading>
    <b-row class="pb-5">
      <b-col cols="12">
        <h4>解析データ</h4>
      </b-col>
    </b-row>

    <b-row class="pr-0 py-1">
      <b-col cols="12" md="2">
        <label for="office_id">事業所</label>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-select id="office_id" v-model="office_id" :options="office_list"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="pr-0 py-1">
      <b-col cols="4" md="2">
        <label for="target_year" class="required">対象年</label>
      </b-col>
      <b-col cols="8" md="4">
          <b-form-select id="target_year" v-model="target_year">
            <option v-for="n in year_list" :value="n">{{ n }}年</option>
          </b-form-select>
      </b-col>
      <b-col cols="4" md="2">
        <label for="target_month" class="required">対象月</label>
      </b-col>
      <b-col cols="8" md="4">
          <b-form-select id="target_month" v-model="target_month">
            <option v-for="n in month_list" :value="n">{{ n }}月</option>
          </b-form-select>
      </b-col>
    </b-row>
    <b-row class="pr-0 py-1">
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <b-row class="pr-0 py-1">
      <b-col cols="6" md="2">
        <b-button class="px-1" block variant="primary" size="lg" @click="onClickDownloadAnalysis('analysis', $event)">解析データダウンロード</b-button>
      </b-col>
      <b-col cols="6" md="2">
        <b-button class="px-1" block variant="primary" size="lg" @click="onClickDownloadAnalysis('raw', $event)">データダウンロード</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from "dayjs"
import axios from "axios"
import Loading from "@/Pages/Components/MyLoading"

export default {
  components: { Loading },
  props: {
    offices: {
      type : Object,
      required: true
    }
  },
  mounted: function() {
    this.updateLoading(false);
  },
  computed: {
    office_list () {
      let officeList = this.offices.data.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
      officeList = [
        {
          text: 'すべて',
          value: 0
        },
        ...officeList
      ]
      return officeList
    },
    // 選択された年が今年だったら、今年の月までのリストを返す
    month_list () {
      const monthList = []
      const currentYear = Number(dayjs().format('YYYY'))
      const currentMonth = Number(dayjs().format('MM'))
      if (this.target_year === currentYear) {
        for (let i = 1; i <= currentMonth; i++) {
          monthList.push(i)
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          monthList.push(i)
        }
      }
      console.log('monthList: ', monthList)
      return monthList
    }
  },
  data() {
    return {
      year_list: [],
      target_year: Number(dayjs().format('YYYY')),
      target_month: Number(dayjs().format('MM')),
      loading: false,
      office_id: 0,
    }
  },
  methods: {
    getOfficeName (office_id) {
      if(office_id === 0) return ('すべて')

      const office = this.offices.data.find(office => office.id === office_id)
      return office.name
    },
    async onClickDownloadAnalysis (type = 'analysis', event) {
      event.preventDefault()
      const result = await this.$swal
        .fire({
          icon: 'info',
          html: '<p>'
            + (type === 'analysis' ? '解析データ' : 'データ')
            + 'ダウンロードしますか？<br><small>ダウンロードには時間がかかる場合があります。</small></p>'
            + '<div class="w-100">'
            + '<div class="row mb-0">'
            + '<div class="col-12 col-md-3 text-left">'
            + '事業所：'
            + '</div>'
            + '<div class="col-12 col-md-9 pl-0 pr-3" style="display: inherit;">'
            + '<p id="download-form_office" name="office">' + this.getOfficeName(this.office_id) + '</p>'
            + '</div>'
            + '</div>'
            + '<div class="row mb-0">'
            + '<div class="col-12 col-md-4 text-left">'
            + '抽出年月：'
            + '</div>'
            + '<div class="col-6 col-md-4 pl-0 pr-3" style="display: inherit;">'
            + '<p id="download-form_year" name="year">' + this.target_year + '</p>年'
            + '</div>'
            + '<div class="col-6 col-md-4" style="display: inherit;">'
            + '<p id="download-form_month" name="month">' + this.target_month + '</p>月'
            + '</div>'
            + '</div>'
            + '<div class="row mb-0">'
            + '<div class="col-12 col-md-3 text-left">'
            ,
          preConfirm: () => {
            return [
              document.getElementById('download-form_year').value,
              document.getElementById('download-form_month').value,
              document.getElementById('download-form_office').value,
            ]
          },
          showConfirmButton: true,
          confirmButtonText: 'はい',
          showCancelButton: true,
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          this.updateLoading(true);

          // ダウンロード処理のURL発行
          let oura_download_analysis_url = await this.$route('oura.download-analysis', {
              year: this.target_year,
              month: this.target_month,
              type: type || 'analysis',
              office : this.office_id || null
            })

          // ダウンロード処理
          try {
            const res = await axios.get(oura_download_analysis_url, { responseType: 'blob' })

            if(res) {
              // aタグを生成してクリックイベントを発火させる
              let elem = await document.createElement('a')
              // ダウンロードするファイルの内容をBlobで生成する
              elem.href = await window.URL.createObjectURL(new Blob([res.data]))
              // ダウンロードするファイルの名前を指定する
              let file_name = await res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1')
              // aタグによるダウンロードファイルの名前を指定する
              await elem.setAttribute('download', file_name)
              // ダウンロードするaタグの要素を一時的にbodyに追加する
              await document.body.appendChild(elem)
              // aタグをクリックさせる
              await elem.click()
              // aタグの要素をbodyから削除する
              await document.body.removeChild(elem)
            }
          } catch (err) {
            console.log('err:', err);
            if (err.response['status'] >= 500) {
              this.$swal.fire({
                icon: 'error',
                text: 'サーバーエラーが発生しました。しばらくたってから再試行してください。'
              })
            }
          }
          this.updateLoading(false);
        }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  created() {
    // 基準の年
    const year = 2022
    const this_year = new Date().getFullYear()
    // list 配列に指定回数年を追加する
    for (let i = year; i <= this_year; i++) {
      this.year_list.push(i)
    }
  }
}
</script>
