<template>
  <div id="shiftFooterArea" class="footer_btn_area">
    <div style="display: flex!important; align-items: center;">
      <!-- 休暇申請状況でも表示する？ -->
      <BtnReset
        v-if="view_type === 'schedule'"
        :summary="summary"
        :year="year"
        :month="month"
        :office="office"
        :team_id="team_id"
        :team_name="team_name"
      />
      <BtnSave1
        v-if="view_type === 'application_status'"
        @saveApplicationStatus="saveApplicationStatus"
      />
      <BtnSave2
        v-else
        :summary="summary"
        @saveShift="saveShift"
      />
      <b-button class="btn-shift-data footer-btn-position" style="margin-left: 10px;" @click="print">
        <font-awesome-icon icon="download" style="color: #0BB0BE; vertical-align: text-top; padding-right: 5px;" />シフト表（PDF）
      </b-button>
      <b-button class="btn-shift-data footer-btn-position" style="margin-left: 10px;" @click="downloadShift">
        <font-awesome-icon icon="download"  style="color: #0BB0BE; vertical-align: text-top; padding-right: 5px;" />シフト表（CSV）
      </b-button>
      <BtnFormatDownload
        :office="office.id"
        :year="year"
        :month="month"
      />
      <!-- シフトインポートボタン -->
      <BtnShiftDataImport/>
      <!-- 勤怠実績データインポートボタン -->
      <BtnAttendanceRecordDataImport
        :is_free_plan="is_free_plan"
        :external_kintais="external_kintais"
        :office_id="office.id"
        label="勤怠実績データインポート"
        additional_style="margin-left: 10px;"
      />
      <!-- 勤務形態一覧表（予定）ボタン -->
      <BtnFulltimeConversionsSchedule
        class="btn-shift-data footer-btn-position"
        :is_free_plan="is_free_plan"
        label="勤務形態一覧表（予定）"
        :is_available="fulltime_conversion_available"
        :is_prepared="summary.status === 'opened'"
        :office_id="office.id"
        :team_id="team_id"
        :year="year"
        :month="month"
        :type="1"
        display_rule="shift_monthly"
      />
      <!-- 勤務形態一覧表（実績）ボタン -->
      <BtnFulltimeConversionsAchievements
        class="btn-shift-data footer-btn-position"
        :is_free_plan="is_free_plan"
        label="勤務形態一覧表（実績）"
        :is_available="fulltime_conversion_available"
        :is_prepared="has_fulltime_conversion_result"
        :office_id="office.id"
        :team_id="team_id"
        :year="year"
        :month="month"
        :type="2"
        display_rule="shift_monthly"
      />
    </div>
    <b-button class="evolvement-btn" style="margin-right: 70px;" :disabled="deployment_ng()" @click="deployment">シフトを職員へ展開</b-button>
    <SelectOfficeDialog
      ref="selectOfficeDialog"
      @execDeployment="execDeployment"
      :persons_belong_multiple_offices="persons_belong_multiple_offices"
    />
    <SelectTeamsDialog
      ref="SelectTeamsDialog"
      @selectTeams="showSelectOfficeDialog"
      :teams="teams"
    />
  </div>
</template>

<style scoped>
.disable-style {
  opacity: 0.65;
}
</style>

<script>
import BtnFormatDownload from '@/Pages/Shifts/Components/Button/BtnFormatDownload';
import BtnShiftDataImport from '@/Pages/Shifts/Components/Button/BtnShiftDataImport';
import BtnAttendanceRecordDataImport from '@/Pages/FulltimeConversions/Components/Button/BtnActualDataImport';
import BtnFulltimeConversionsSchedule from '@/Pages/FulltimeConversions/Components/Button/BtnDownload';
import BtnFulltimeConversionsAchievements from '@/Pages/FulltimeConversions/Components/Button/BtnDownload';
import SelectOfficeDialog from '@/Pages/Shifts/Components/Dialog/SelectOfficeDialog';
import SelectTeamsDialog from '@/Pages/Shifts/Components/Dialog/SelectTeamsDialog';
import BtnReset from '@/Pages/Shifts/Components/Button/BtnReset';
import BtnSave1 from '@/Pages/Shifts/Components/Button/BtnSaveApplicationStatus';
import BtnSave2 from '@/Pages/Shifts/Components/Button/BtnSaveSchedule';
import axios from "axios"

export default {
  name: 'FooterArea',
  components: {
    BtnFormatDownload,
    BtnShiftDataImport,
    BtnAttendanceRecordDataImport,
    BtnFulltimeConversionsSchedule,
    BtnFulltimeConversionsAchievements,
    SelectOfficeDialog,
    SelectTeamsDialog,

    // application status
    BtnSave1,

    // schedule
    BtnReset,
    BtnSave2,
  },
  props: {
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
    year: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      default: ''
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    teams: {
      type: Array,
      default: function() {
        return [];
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
    team_name: {
      type: String,
      default: ''
    },
    is_free_plan: {
      type: Boolean,
      default: false,
    },
    external_kintais: {
      type: Array,
      required: true
    },
    persons_belong_multiple_offices: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // view_type application_status or schedule
    view_type: {
      type: String,
      default: 'schedule'
    },
    fulltime_conversion_available: {
      type: Boolean,
      default: false
    },
    has_fulltime_conversion_result: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      team_list: [],
      select_val: 0,
    };
  },

  methods: {
    print() {
      this.$swal({
        text: '勤務(予定)表をダウンロードします。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        this.updateLoading(true);
        if (this.team_id === 0) {
          location.href = this.$route('shifts.pdf', {
            year: this.year,
            month: this.month,
            office: this.office.id,
          });
        } else {
          location.href = this.$route('shifts.team-pdf', {
            year: this.year,
            month: this.month,
            office: this.office.id,
            team: this.team_id
          });
        }
        this.updateLoading(false);
      });
    },
    // シフトデータダウンロード
    downloadShift() {
      this.$swal({
        text: 'ダウンロードします。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        this.updateLoading(true);
        this.select_val = 1;
        if (this.teams.length > 2) {
          this.$refs.SelectTeamsDialog.openDialog(this.select_val);
        }
        else {
          this.download_api();
        }

        this.updateLoading(false);
      });
    },
    download_api () {
        axios.get(
          this.$route('shifts.do-prepare-download', {
            ids: encodeURIComponent(this.summary.id),
            only_opened: 0,
            team_list: this.team_list
          })
        ).then((result) => {
          if (result.status !== 200) {
            this.$swal.fire({
              icon: 'error',
              text: 'シフトデータの生成に失敗しました。しばらくたってから再試行してください。'
            })
          } else {
            this.$swal.fire({
              icon: 'info',
              html: '登録されているメールアドレス宛に、シフトのダウンロードリンクを送信しました。<br />このリンクは3日間の有効期限がありますので、お早めにダウンロードしてください。'
            })
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.$swal.fire({
              icon: 'error',
              text: error.response.data.message
            })
          } else {
            this.$swal.fire({
              icon: 'error',
              text: 'シフトデータの生成に失敗しました。しばらくたってから再試行してください。'
            })
          }
        })
      },

    deployment() {
      this.$swal({
        html: '現在のシフト表が職員アプリへ通知されます。',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        this.select_val = 0;
        // "全チーム"、"所属なし"以外のチームがある場合はチーム選択ダイアログを表示
        if (this.teams.length > 2) {
          this.$refs.SelectTeamsDialog.openDialog(this.select_val);
        // チーム選択が不要なら複数事業所のダイアログ表示の有無を確認
        } else {
          this.showSelectOfficeDialog();
        }
      });
    },
    showSelectOfficeDialog(data) {
      // チーム選択から遷移した場合は、dataに選択されたチームが返却されている
      this.team_list = data;
      if (this.select_val === 1) {
        this.download_api();
      } else {
        // 複数事業所に所属している場合は事業所選択ダイアログを表示
        if (Object.keys(this.persons_belong_multiple_offices).length !== 0) {
          this.$refs.selectOfficeDialog.openDialog();
        // 複数事業所に所属していない場合は職員へ展開
        } else {
          this.execDeployment({});
        }
      }
    },
    execDeployment(target_list) {
      this.updateLoading(true);
      this.$inertia.post(
        this.$route('shifts.schedule.deployment', {
          year: this.year,
          month: this.month,
          office: this.office.id,
        }),
        {
          target_list: target_list,
          team_list: this.team_list,
        },
        {
          onFinish: () => {
            this.updateLoading(false);
          }
        }
      );
      this.$swal({
        icon: 'success',
        html: '今月のシフト自動作成結果に満足いただけましたか？よろしければ<a href="https://survey.synchroshift.jp/zs/3TB3Dw" target="_blank" rel="noopener noreferrer">こちら</a>からシフトに関する満足度調査にご協力ください。',
      });
    },
    deployment_ng() {
      // 「希望休受付中」と「シフト未作成」はDisable
      if (this.summary.status === 'aggregating' || this.summary.status === 'draft') {
        return true;
      } else {
        let today = new Date();
        let deadline = new Date(this.year, this.month , this.office.request_deadline);
        deadline.setMonth(deadline.getMonth() - 2);
        if (today < deadline) {
          return true;
        } else {
          return false;
        }
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    saveApplicationStatus() {
      this.$emit('saveApplicationStatus');
    },
    saveShift() {
      this.$emit('saveShift');
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
