import Vue from 'vue'
import vuetify from './plugins/vuetify'
import BootstrapVue from 'bootstrap-vue'
import { createInertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress';
import { Inertia } from '@inertiajs/inertia'
import link from '@inertiajs/inertia-vue/src/link'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import dayjs from "dayjs";
import ja from 'dayjs/locale/ja';
import VueCookie from 'vue-cookie'
import * as Sentry from '@sentry/vue';
Vue.use(VueSweetalert2);
Vue.use(VueCookie)

// dayjsの設定
dayjs.locale(ja);
Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false;
Vue.prototype.$route = route;
Vue.prototype.$url = function (url) {
    if (url.slice(0, 1) == '/') {
        url = url.substr(1);
    }
    const protocol = process.env.MIX_PROTOCOL;
    const prefix = process.env.MIX_PREFIX;
    if(process.env.MIX_ENV === 'local') {
        const port = process.env.MIX_PORT;
        return protocol + '://' + document.domain + ':' + port + prefix + url;
    }
    return protocol + '://' + document.domain + prefix + url;
}

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, far, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue);

// Sentryの設定
Sentry.init({
    Vue,
    dsn: process.env.MIX_SENTRY_VUE_DSN,
    whitelistUrls: [
        /https:\/\/.*\.synchroshift\.jp/
    ],
    environment: process.env.MIX_APP_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracePropagationTargets: [/^(https:\/\/)([a-zA-Z0-9]+)\.synchroshift\.jp/],
    trackComponents: true,
    logErrors: true,
    tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
    replaysSessionSampleRate: process.env.MIX_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: 1.0,
});

// Vueのグローバルミックスインを追加
Vue.mixin({
    created() {
        if (this.$page && this.$page.props?.auth?.user) {
            // SentryにユーザーIDをコンテキストとしてセット
            const scope = Sentry.getCurrentScope();
            scope.setUser({ id: this.$page.props.auth.user.id });
        }
    }
});

import Layout from './Pages/Layouts/Layout'

window.EventBus = new Vue();

const custom_link = {
    name: 'CustomLink',
    props: ['item'],
    render() {
        return h(link, this.$slots.default)
    },
    watch: {
        '$page.url' () {
            this.onRouteChange()
        }
    },
    inject: ['onRouteChange']
}

const app = createInertiaApp({
    resolve: name => {
        const page = require(`./Pages/${name}`).default
        // デフォルトのLayoutを定義
        page.layout = page.layout || Layout
        return page
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin)

        new Vue({
            vuetify,
            render: h => h(App, props),
        }).$mount(el)
    },
    component: {
        'custom-link': custom_link
    }
});

InertiaProgress.init({
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: '#29d',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
});

