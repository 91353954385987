<template>
  <div>
    <b-button
      style="padding: 0px!important;"
      class="btn_white btn-combination-setting"
      :style="'font-size: ' + ( edit_mode ? '12' : '16' ) + 'px; height: ' + (edit_mode ? '30px' : '60px' ) + '!important; width: '+ ( edit_mode ? '40px' : '144px' ) + '!important;'"
      @click="combinations"
    >
      <span v-if="edit_mode">詳</span>
      <span v-else>詳細設定</span>
    </b-button>
  </div>
</template>

<style scoped>
.btn-combination-setting {
  color: #707070 !important;
  padding: 0px !important;
}
</style>

<script>
export default {
  name: 'BtnCombination',
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    year: {
      type: String,
      // 当年
      default: new Date().getFullYear()
    },
    month: {
      type: String,
      // 当月
      default: new Date().getMonth() + 1
    },
  },
  methods: {
    combinations() {
      this.updateLoading(true);
      this.$inertia.get(this.$route('shifts.combinations.setting', this.office.id), {
        // URLより取得した年月を渡す
        year: this.year,
        month: this.month,
      })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
