<template>
  <td
    :class="colorClass"
    style="padding: 0px !important"
    :style="getStyle()"
  >
    <div
      :class="setFlexCellLine()"
      :style="getFontSize()"
    >
      {{ calc }}
    </div>
    <div
      v-show="is_show_workpattern_val && work_category !== '6' && work_category !== '7'"
      style="font-size: 12px"
    >
      <v-tooltip
        top
        content-class="custom-tooltip"
        v-if="calc_min_max.detail && calc_min_max.detail.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            ({{ calc_min_max.min }}-{{ calc_min_max.max }})
          </span>
        </template>
        <span
          v-for="(item, index) in calc_min_max.detail"
          :key="index"
        >
          {{ item.workpattern_name }}　{{ item.min }} - {{ item.max }}
          <br />
        </span>
      </v-tooltip>
      <span v-else> ({{ calc_min_max.min }}-{{ calc_min_max.max }}) </span>
    </div>
  </td>
</template>

<style scoped>
.lower {
  background: #ffffff !important;
  color: red !important;
}
.upper {
  background: #ffffff !important;
  color: blue !important;
}
.same {
  background: #ffffff !important;
  color: black !important;
}
.td-sticky-border {
  display: grid;
  justify-content: center;
  align-items: center;
  min-width: 103%;
  max-width: 103%;
  min-height: 103%;
  max-height: 103%;
  border: solid 1px rgba(210, 214, 219, 0.5) !important;
  background: #ffffff;
}
.custom-tooltip {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
}
</style>

<script>
// 列の種類を定義
const COL = {
  EARLY: '0', // 早番
  DAY: '1', // 日勤
  LATE: '2', // 遅番
  NIGHT: '3', // 夜勤
  DAWN: '4', // 明番
  PUBLIC_HOLIDAY: '5', // 所定休日
  PAID_HOLIDAY: '6', // 有給
  OTHER: '7', // その他
  TOAL_WORK_HOURS: '8', // 総労働時間
};

export default {
  name: 'SummaryColumn',
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: { type: Boolean, default: false },
    shifts: { type: Object },
    work_category: { type: String },
    monthly_working_count: { type: Object },
    count: { type: Number, default: 0 },
    no: { type: Number, default: 0 },
    is_show_workpattern_val: { type: Boolean, require: true },
    office_staff_workpattern: { type: Object },
    office_staffs: { type: Object },
    shiftKey: { type: String },
  },
  data() {
    return {
      new_shifts: {},
    };
  },
  methods: {
    /**
     * getStyle
     * 作成モードのとき、右側を固定にするスクリプトを出力
     * 作成モードのとき、右側を固定にするためz-indexを設定
     */
    getStyle() {
      let setStyle = '';

      if (this.edit_mode) {
        let setRight = 0;
        let right_count = this.count - this.no - 1;
        if (right_count > 0) {
          setRight = (right_count - 1) * 32 + 40;
        }
        setStyle = 'position: sticky; right:' + setRight + 'px; z-index: 999;';
      }

      return setStyle;
    },
    /**
     * getFontSize
     * 通常モードと作成モードで集計エリアのフォントサイズを変更する
     */
    getFontSize() {
      let setStyle = 'font-size: ';

      if (this.edit_mode) {
        setStyle = setStyle + '11px!important;';
      } else {
        setStyle = setStyle + '15px!important;';
      }
      return setStyle;
    },
    /**
     * setFlexCellLine
     * 作成モードの場合、固定列はDIV領域の罫線をメインとする
     */
    setFlexCellLine() {
      if (this.edit_mode) {
        return 'td-sticky-border';
      } else {
        return '';
      }
    },
    isColumnIn(colList) {
      return colList.includes(this.work_category);
    },
  },
  computed: {
    colorClass() {
      if (this.isColumnIn([COL.EARLY, COL.DAY, COL.LATE, COL.NIGHT, COL.DAWN, COL.PUBLIC_HOLIDAY])) {
        if (this.calc_min_max.min === 0 && this.calc_min_max.max === 0) {
          return '';
        }
        if (this.calc < this.calc_min_max.min) {
          return 'lower';
        }
        if (this.calc > this.calc_min_max.max) {
          return 'upper';
        }
      }

      // 総労働時間
      if (this.isColumnIn([COL.TOAL_WORK_HOURS])) {
        if (
          this.office_staffs &&
          this.office_staffs.min_worktime_minutes !== null &&
          this.office_staffs.max_worktime_minutes !== null
        ) {
          const minWorkTimeHours = Number(this.office_staffs.min_worktime_minutes / 60);
          const maxWorkTimeHours = Number(this.office_staffs.max_worktime_minutes / 60);

          if (minWorkTimeHours === 0 && maxWorkTimeHours === 0) {
            return 'same';
          }

          if (minWorkTimeHours > Number(this.calc)) {
            return 'lower';
          }

          if (Number(this.calc) > maxWorkTimeHours) {
            return 'upper';
          }
        }
      }
    },
    calc() {
      let num = 0;
      // 日毎のシフトデータをループ
      for (let day in this.new_shifts) {
        if (this.shiftKey in this.shifts[day]) {
          let shift = this.shifts[day][this.shiftKey];
          let workpattern_type = shift.workpattern ? shift.workpattern.type : '';
          let workpattern_category = shift.workpattern ? shift.workpattern.category : '';

          // カウント対象のカテゴリーによって処理を分岐
          switch (true) {
            // 勤務をカウント
            case this.work_category === '0' && workpattern_category === 'early':
            case this.work_category === '1' && workpattern_category === 'day':
            case this.work_category === '2' && workpattern_category === 'late':
            case this.work_category === '3' && workpattern_category === 'night':
            case this.work_category === '4' && workpattern_category === 'dawn':
              num += workpattern_type === 'half_work' ? 0.5 : 1;
              break;
            // 所定休日カウント
            case this.work_category === '5':
              // 公休(全休)
              if (workpattern_type === 'public_holidays') {
                num += 1;
              }
              // 公休(半休)
              if (
                shift.holiday_workpattern &&
                shift.holiday_workpattern.category === 'holiday' &&
                ['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1 &&
                workpattern_type === 'half_work' &&
                shift.holiday_workpattern.type === 'public_half_holiday'
              ) {
                num += 0.5;
              }
              break;
            // 有休をカウント
            case this.work_category === '6':
              // 休日の場合
              if (shift.holiday_workpattern === null) {
                if (workpattern_category === 'holiday') {
                  if (workpattern_type === 'paid_holidays') {
                    // 休日なので１を加算
                    num += 1;
                  }
                  // 半休は必ず半勤務とセットなのでここではカウントしない
                }
              } else if (shift.holiday_workpattern && shift.holiday_workpattern.category === 'holiday') {
                // 半休の場合
                if (
                  ['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1 &&
                  workpattern_type === 'half_work' &&
                  shift.holiday_workpattern.type === 'paid_half_holiday'
                ) {
                  // 半休なので0.5を加算
                  num += 0.5;
                }
              }
              break;

            // その他の休日をカウント
            case this.work_category === '7':
              // 休日の場合
              if (shift.holiday_workpattern === null) {
                if (workpattern_category === 'holiday') {
                  if (workpattern_type === 'other_holiday') {
                    // 休日なので１を加算
                    num += 1;
                  }
                  // 半休は必ず半勤務とセットなのでここではカウントしない
                }
              } else if (shift.holiday_workpattern && shift.holiday_workpattern.category === 'holiday') {
                // 半休の場合
                if (
                  ['early', 'day', 'late', 'night', 'dawn'].indexOf(workpattern_category) !== -1 &&
                  workpattern_type === 'half_work' &&
                  shift.holiday_workpattern.type === 'other_half_holiday'
                ) {
                  // 半休なので0.5を加算
                  num += 0.5;
                }
              }
              break;

            // 総労働時間
            case this.work_category === '8':
              // 稼働時間を加算（勤務 または 半勤務）
              if (workpattern_type === 'work' || workpattern_type === 'half_work') {
                let parts = shift.workpattern.working_hours.split(':');
                let hours = parseInt(parts[0]);
                let minutes = parseInt(parts[1]);
                let seconds = parseInt(parts[2]);
                let addTime = hours + minutes / 60 + seconds / 3600;
                num += addTime;
              }
              break;

            default:
              break;
          }
        }
      }

      // 総労働時間の場合は小数点2桁（切り捨て）で返却
      if (this.work_category === '8') {
        num = Math.floor(num * 100) / 100;
        return num.toFixed(1);
      } else {
        return num;
      }
    },

    calc_min_max() {
      let compare_category;
      const result = { min: 0, max: 0, detail: [] };
      switch (this.work_category) {
        case '0':
          compare_category = 'early';
          break;
        case '1':
          compare_category = 'day';
          break;
        case '2':
          compare_category = 'late';
          break;
        case '3':
          compare_category = 'night';
          break;
        case '4':
          compare_category = 'dawn';
          break;
        case '5':
          compare_category = 'holiday';
          break;
        case '8':
          if (
            this.office_staffs &&
            this.office_staffs.min_worktime_minutes !== null &&
            this.office_staffs.max_worktime_minutes !== null
          ) {
            result.min = (this.office_staffs.min_worktime_minutes / 60).toFixed(1);
            result.max = (this.office_staffs.max_worktime_minutes / 60).toFixed(1);
          }
          return result;
        default:
          break;
      }

      if (this.office_staff_workpattern !== null && this.office_staff_workpattern !== undefined) {
        Object.values(this.office_staff_workpattern).forEach(
          ({
            min_monthly_working_count,
            max_monthly_working_count,
            category,
            workpattern_name,
            workpattern_type,
          }) => {
            if (category !== compare_category) {
              return;
            }

            // 休日の場合は公休（全休）のみカウント
            if (category !== 'holiday' || workpattern_type === 'public_holidays') {
              result.min += min_monthly_working_count;
              result.max += max_monthly_working_count;
              result.detail.push({
                min: min_monthly_working_count,
                max: max_monthly_working_count,
                workpattern_name,
                workpattern_type,
              });
              return;
            }
          }
        );
      }
      return result;
    },
  },
  watch: {
    shift_data: {
      handler(new_data, old_data) {
        Object.keys(this.new_shifts).forEach((key) => {
          this.$delete(this.new_shifts, key);
        });
        for (let key in new_data) {
          this.$set(this.new_shifts, key, new_data[key]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (let key in this.shifts) {
      this.$set(this.new_shifts, key, this.shifts[key]);
    }
  },
};
</script>
