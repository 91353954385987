<template>
  <v-dialog v-model="dialog" @keydown.esc="closeDialog()" @click:outside="onClickOutside()" persistent content-class="auto-create-class">
    <div id="modalform" tabindex="-1" ref="modalform" style="color: #000000; font-size: 16px;">
      <v-card>
        <v-card-title style="display: flex; width: 100%; font-weight: 700; justify-content: space-between; background: #FDF2F7;">
          <div style="color:#FF8CB9; width: calc(100% - 40px); padding-left: 30px;">シフトを自動作成します</div>
          <div style="width: fit-content;"><button class="btn_close" @click="close_dialog">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 50px 0px 50px;">
          <div>
            <p v-html="getHeaderButtomMessage()" style="width: fit-content; margin:10px 0px;"></p>
          </div>
          <div>
            <ul class="step">
              <li :class="{ 'current': step === 1 }" @click="nextStep(1)">事業所の設定</li>
              <li :class="{ 'current': parseInt(step) === 2 }" @click="nextStep(2)">チームの設定</li>
              <li :class="{ 'current': parseInt(step) === 3 }" @click="nextStep(3)">職員の設定</li>
              <li :class="{ 'current': step === 4 }" @click="nextStep(4)">勤務区分の設定</li>
              <li :class="{ 'current': step === 5 }" @click="nextStep(5)">常時配置人数の設定</li>
              <li :class="{ 'current': step === 6 }" @click="nextStep(6)">勤務回数の設定</li>
              <li :class="{ 'current': step === 7 }" @click="nextStep(7)">シフト<br />パターンの設定</li>
              <li :class="{ 'current': step === 8 }" @click="nextStep(8)">組合せ設定</li>
              <li :class="{ 'current': step === 9 }" @click="nextStep(9)">回避したいシフト<br />パターン設定</li>
              <li :class="{ 'current': step === 10 }" @click="nextStep(10)">シフト自動作成</li>
            </ul>
          </div>
          <div v-if="step === 1">
            <Office
              :office="office_office"
              :rules="office_rules"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 2">
            <TeamsList
              :teams="teams_teams"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @getTeamsData="getTeamsData"
              @updateTeamData="updateTeamData"
            />
          </div>
          <div v-if="step === 2.1">
            <TeamsAdd
              :errors="teams_errors"
              :offices="teams_offices"
              :staffs="teams_staffs"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 2.2">
            <TeamsEdit
              :team="teams_team"
              :errors="teams_errors"
              :offices="teams_offices"
              :staffs="teams_staffs"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @updateTeamData="updateTeamData"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 2.3">
            <TeamsCopy
              :team="teams_team"
              :errors="teams_errors"
              :offices="teams_offices"
              :staffs="teams_staffs"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 3">
            <StaffsList
              :staffs="staffs_staffs"
              :offices="teams_offices"
              :teams="staffs_teams"
              :codes="staffs_codes"
              :work_statuses="staffs_work_statuses"
              :flags="staffs_flags"
              :initial_office_id="initial_office_id"
              :initial_team_id="initial_team_id"
              :initial_work_status="initial_work_status"
              :initial_flag="initial_flag"
              :num_of_available_staffs="num_of_available_staffs"
              :num_of_active_staffs="num_of_active_staffs"
              :user_role="user_role"
              :plan_name="plan_name"
              @getStaffsData="getStaffsData"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @updateStaffData="updateStaffData"
            />
          </div>
          <div v-if="step === 3.1">
            <StaffsForm
              :errors="staffs_errors"
              type="add"
              :staff="staffs_staff"
              :qualifications="staffs_qualifications"
              :occupations="staffs_occupations"
              :codes="staffs_codes"
              :positions="staffs_positions"
              :offices="teams_offices"
              :teams="staffs_teams"
              :team_staffs="staffs_team_staffs"
              :workpatterns="staffs_workpatterns"
              initial_office_id="3"
              initial_team_id="0"
              :is_oldest="false"
              :default_monthly_working_counts="staffs_default_monthly_working_counts"
              :office_staff_kintais_at_one_office="office_staff_kintais_at_one_office"
              :office_kintai="office_kintai"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 3.2">
            <StaffsForm
              :errors="staffs_errors"
              type="edit"
              :staff="staffs_staff"
              :qualifications="staffs_qualifications"
              :occupations="staffs_occupations"
              :codes="staffs_codes"
              :positions="staffs_positions"
              :offices="teams_offices"
              :teams="staffs_teams"
              :team_staffs="staffs_team_staffs"
              :workpatterns="staffs_workpatterns"
              initial_office_id="3"
              initial_team_id="0"
              :is_oldest="false"
              :default_monthly_working_counts="staffs_default_monthly_working_counts"
              :office_staff_kintais_at_one_office="office_staff_kintais_at_one_office"
              :office_kintai="office_kintai"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 4">
            <BulkAddWorkpattern
              :sort_type="Number(sort_type)"
              :workpatterns="office_workpatterns"
              :office_staffs="office_staffs"
              :staffs="staffs"
              :office_staff_workpatterns="office_staff_workpatterns_data"
              :default_monthly_working_counts="default_monthly_working_counts"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @getBulkAddData="getBulkAddData"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 5">
            <PermanentPlacement
              :office="permanent_placement_office"
              :team_id="team_id"
              :workpatterns="permanent_placement_workpatterns"
              :work_categories="work_categories"
              :staff_assignments="permanent_placement_staff_assignments"
              :office_staff_workpatterns="permanent_placement_office_staff_workpatterns"
              :basic_infos="permanent_placement_basic_infos"
              :year="year"
              :month="month"
              :summary="summary"
              :count_work_category_summary="count_work_category_summary"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @updatePreparingFlg="updatePreparingFlg"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 6">
            <BulkAddWorkCount
              :sort_type="Number(sort_type)"
              :workpatterns="office_workpatterns"
              :office_staffs="office_staffs"
              :staffs="staffs"
              :office_staff_workpatterns="office_staff_workpatterns_data"
              :default_monthly_working_counts="default_monthly_working_counts"
              :count_work_category_summary="count_work_category_summary"
              :office="office"
              :team_id="team_id"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @getBulkAddData="getBulkAddData"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 7">
            <BulkAddShiftPattern
              :sort_type="Number(sort_type)"
              :workpatterns="office_workpatterns"
              :office_staffs="office_staffs"
              :staffs="staffs"
              :office_staff_workpatterns="office_staff_workpatterns_data"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @getBulkAddData="getBulkAddData"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 8">
            <Combinations
              :workpatterns="combinations_workpatterns"
              :staffs="combinations_staffs"
              :office="combinations_office"
              :combination_list="combinations_list"
              :codes="combinations_codes"
              :year="year"
              :month="month"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @getCombinationData="getCombinationData"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 9">
            <AvoidPattern
              :office="office_office"
              :work_categories="work_categories_data"
              :combo_staffs="officeStaffsForExcluded"
              :avoid_pattern="avoidPattern"
              @getOfficeData="getOfficeData"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @setChange="setChange"
            />
          </div>
          <div v-if="step === 10">
            <WorkCategory
              :office="permanent_placement_office"
              :team_id="team_id"
              :workpatterns="permanent_placement_workpatterns"
              :work_categories="work_categories"
              :staff_assignments="permanent_placement_staff_assignments"
              :office_staff_workpatterns="permanent_placement_office_staff_workpatterns"
              :basic_infos="permanent_placement_basic_infos"
              :year="year"
              :month="month"
              :summary="summary"
              :count_work_category_summary="count_work_category_summary"
              :workpatterns_by_team_and_category="workpatterns_by_team_and_category"
              @closeDialog="closeDialog"
              @nextStep="nextStep"
              @setchildError="setchildError"
              @updatePreparingFlg="updatePreparingFlg"
              @setChange="setChange"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
/* step */
.step {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.step li {
  position: relative;
  display: flex;
  width: calc(100% / 3);
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background: #D1D1D1;
  cursor: pointer;
}
/* 最初と最後以外は左に余白 */
.step li:not(:first-child):not(:last-child) {
  text-align: center;
  padding: 0 0 0 10px;
}
.step li::before, .step li::after {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0;
}
.step li::before {
  top: -7px;
  left: 0;
  border: solid 32px transparent;
  border-right: solid 13px transparent;
  border-left: solid 13px #FFFFFF;
  z-index: 1;
}
.step li::after {
  top: 0;
  right: -19px;
  border: solid 25px transparent;
  border-left: solid 10px #D1D1D1;
  border-right: solid 10px transparent;
  z-index: 2;
}
/* 最初のliの左と最後 */
.step li:first-child::before,
.step li:last-child::after {
  content: none;
}
.step li.current {
  color: #FFFFFF;
  background: #FF82B4;
}
.step li.current::after {
  border-left: solid 10px #FF82B4;
}
</style>

<script>
import Office from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Office';
import TeamsList from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Teams/List';
import TeamsAdd from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Teams/Add';
import TeamsEdit from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Teams/Edit';
import TeamsCopy from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Teams/Copy';
import StaffsList from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Staffs/List';
import StaffsForm from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Staffs/Form';
import BulkAddWorkpattern from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/BulkAdd/BulkAddWorkpattern';
import BulkAddWorkCount from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/BulkAdd/BulkAddWorkCount';
import BulkAddShiftPattern from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/BulkAdd/BulkAddShiftPattern';
import Combinations from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Combinations/Combinations';
import AvoidPattern from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/Combinations/AvoidPattern';
import PermanentPlacement from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/PermanentPlacement';
import WorkCategory from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/PermanentPlacement/WorkCategory';
import axios from 'axios';

export default {
  name: 'AutoCreateDialog',
  components: {
    Office,
    TeamsList,
    TeamsAdd,
    TeamsEdit,
    TeamsCopy,
    StaffsList,
    StaffsForm,
    BulkAddWorkpattern,
    BulkAddWorkCount,
    BulkAddShiftPattern,
    Combinations,
    AvoidPattern,
    PermanentPlacement,
    WorkCategory,
  },
  props: {
    year: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      default: ''
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
    workpatterns: {
      type: Object,
      default: () => {
        return {};
      }
    },
    work_categories: {
      type: Object
    },
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    office_staff_workpatterns: {
      type: Array,
    },
    basic_infos: {
      type: Array,
      default: () => {
        return {};
      }
    },
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
    past: {
      type: Boolean,
      default: false,
    },
    // シフトデータ
    shift_data: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      dialog: false,
      childError: false,
      childErrorMessage: '',

      // 変更フラグ
      isChange: false,

      // Navigationの位置
      step: 1,

      // 事務所の設定
      office_office: {},
      office_rules: [],
      // チームの設定 編集 props
      teams_teams: {data:[]},
      teams_team: { data: {} },
      teams_offices: { data: [] },
      teams_staffs: { data: [] },
      initial_office_id: 3,
      initial_team_id: null,
      initial_work_status: 0,
      initial_flag: 0,
      num_of_available_staffs: 0,
      num_of_active_staffs: 0,
      user_role: 'admin',
      plan_name: 'trial',
      staffs_codes: {data:[]},
      teams_errors: {},
      staffs_teams: {},
      // 職員の設定 一覧 props
      staffs_staffs: {data:[]},
      staffs_work_statuses: [],
      staffs_flags: [],
      // 職員の設定 編集・追加 props
      staffs_errors: {},
      staffs_staff: {data:{}},
      staffs_qualifications: {data:[]},
      staffs_occupations: {data:[]},
      staffs_positions: {data:[]},
      staffs_team_staffs: {},
      staffs_workpatterns: {data:[]},
      staffs_default_monthly_working_counts: {},
      office_staff_kintais_at_one_office: {},
      office_kintai: {},
      // 組合せ設定
      combinations_workpatterns: [],
      combinations_staffs: [],
      combinations_list: [],
      // 回避したいシフトパターン設定
      work_categories_data: [],
      // BulkAddの設定
      sort_type: 1,
      office_workpatterns: [],
      office_staffs: [],
      staffs: [],
      office_staff_workpatterns_data: [],
      default_monthly_working_counts: {},
      shiftTableOfficeStaffOrder: [],
      // PermanentPlacementの設定
      permanent_placement_office: {},
      permanent_placement_office_staff_workpatterns: [],
      permanent_placement_staff_assignments: {},
      permanent_placement_basic_infos: [],
      permanent_placement_workpatterns: {},
      count_work_category_summary: [],
      workpatterns_by_team_and_category: [],
    }
  },
  methods: {
    // 入力変更フラグ制御
    setChange(val) {
      this.isChange = val;
    },
    // モーダル画面を開く
    async openDialog() {
      this.updateLoading(true);
      try {
        await this.getOfficeData();
        this.dialog = true;
      } catch (error) {
        console.error(error);
      }
      this.updateLoading(false);
    },
    // モーダル画面の外側をクリックした時にフォーカスを当てる
    onClickOutside() {
      this.$refs.modalform.focus();
    },
    // モーダル画面を閉じる
    closeDialog() {
      this.isChange = false;
      this.initChildError();
    },
    // エラー情報初期化
    initChildError() {
      this.step = 1;
      this.childError = false;
      this.dialog = false;
    },
    // ナビゲーションバーのクリック
    async nextStep(next_step) {
      try {
        this.updateLoading(true);
        if(next_step === 1) {
          await this.getOfficeData();
        } else if(next_step === 2) {
          await this.getTeamsData();
        } else if(next_step === 3) {
          const params = {
            office_id: this.office.id,
            team_id: this.team_id,
          }
          await this.getStaffsData(params);
        // 今のstepが4,5,6ではなく、次のstepが4,5,6の場合はBulkAddDataを取得
        } else if(next_step === 4 || next_step === 6 || next_step === 7) {
          await this.getBulkAddData();
        } else if(next_step === 5 || next_step === 10) {
          await this.getPermanentPlacementData();
        } else if(next_step === 8) {
          await this.getCombinationData();
        } else if(next_step === 9) {
          await this.getOfficeData();
        }
        this.updateLoading(false);
        // 変更フラグ初期化
        this.isChange = false;
        // 次のステップへ
        this.step = next_step;
      } catch(error) {
        this.updateLoading(false);
        this.$swal({
					icon: 'error',
					html: 'システムエラー',
				});
        console.error(error);
      }
    },
    // タイトル下部のメッセージ
    getHeaderButtomMessage() {
      if (this.step === 1) {
        return '事業所全体に関わる設定に変更がある場合はこちらで設定してください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 2) {
        return 'シフトを作成する単位や所属の変更がある場合は、チームの追加変更を行ってください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 3) {
        return '入退職や休職、職員情報が変更となる場合は、職員の追加変更を行ってください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 4) {
        return '追加した職員、または変更した職員について必要に応じて勤務区分の割り当てを行ってください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 5) {
        return '勤務区分ごとに、出勤する人数を設定してください。問題がなければ、右下の次へをクリックしてください。<br /><span style="font-size: 12px;">たとえば、2人以上、5人以下を設定した場合、常に2～5人が事業所に出勤しているシフトが作成されます。<br />※一括指定を入力すると、選択した勤務区分の行 すべてに反映することができます。</span>';
      } else if(this.step === 6) {
        return '追加した職員、または変更した職員について必要に応じて割り当てた勤務区分の勤務回数を指定してください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 7) {
        return '追加した職員、または変更した職員について必要に応じてシフトパターンを設定してください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 8) {
        return '追加した職員、または変更した職員について必要に応じて組合せを設定してください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 9) {
        return '必要に応じて回避したいシフトパターン設定を行ってください。問題がなければ、右下の次へをクリックしてください。';
      } else if(this.step === 10) {
        return 'シフト作成後、リセットを押すと希望休（希望勤務） 反映後に戻すことができます。';
      }
    },
    close_dialog() {
      // 変更がなければ終了
      if (!this.isChange) {
        this.initChildError();
        return;
      }

      // 入力エラーがある場合はエラー内容を表示して閉じるか確認
      if (this.childError) {
        this.$swal({
          icon: 'warning',
          title: '変更内容が保存されていませんがこの画面を閉じますか？',
          html: this.childErrorMessage,
          showCancelButton: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.initChildError();
          }
        });
      } else {
        this.$swal({
          icon: 'warning',
          title: '変更内容が保存されていませんがこの画面を閉じますか？',
          showCancelButton: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.initChildError();
          }
        });
      }
    },
    setchildError(errorFlg, errMessage = '') {
      // 各Stepの画面からエラー内容を取得
      this.childError = errorFlg;
      this.childErrorMessage = errMessage;
    },
    updatePreparingFlg(flg) {
      this.$emit('updatePreparingFlg', flg);
    },
    updateStaffData(result) {
      console.log(result)
      this.type = result.data.data.type;
      this.staffs_staff = result.data.data.staff ? { data : result.data.data.staff } : null;
      this.staffs_qualifications.data = result.data.data.qualifications;
      this.staffs_occupations.data = result.data.data.occupations;
      this.staffs_codes.data = result.data.data.codes;
      this.staffs_positions.data = result.data.data.positions;
      this.teams_offices.data = result.data.data.offices;
      this.staffs_teams = result.data.data.teams;
      this.staffs_team_staffs = result.data.data.team_staffs;
      this.staffs_workpatterns.data = result.data.data.workpatterns;
      this.initial_office_id = result.data.data.initial_office_id;
      this.initial_team_id = result.data.data.initial_team_id;
      this.is_oldest = result.data.data.is_oldest
      this.staffs_default_monthly_working_counts = result.data.data.default_monthly_working_counts;
      this.office_staff_kintais_at_one_office = result.data.data.office_staff_kintais_at_one_office;
      this.office_kintai = result.data.data.office_kintai;
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // 「事務所の設定」データ取得
    async getOfficeData() {
      return axios.get(this.$route('shifts.offices.edit', this.office.id))
        .then(result => {
          this.office_office = result.data.office;
          this.work_categories_data = result.data.work_categories;
          this.office_rules = result.data.rules;
        })
        .catch(error => {
          throw error;
        })
    },
    // 「チームの設定」データ取得
    async getTeamsData() {
      await axios.get(this.$route('shifts.offices.teams.index', {
        office: this.office.id,
      }))
      .then(result => {
        this.teams_teams.data = result.data.teams;
        this.teams_offices.data = result.data.offices;
        this.teams_staffs.data = result.data.staffs;
      });
    },
    // 「職員の設定」データ取得
    async getStaffsData(params = {}) {
      this.updateLoading(true);
      return axios.get(this.$route('shifts.staffs.list', params))
        .then(result => {
          this.staffs_staffs.data = result.data.data.staffs;
          this.teams_offices.data = result.data.data.offices;
          this.staffs_teams = result.data.data.teams;
          this.staffs_codes.data = result.data.data.codes;
          this.staffs_work_statuses = result.data.data.work_statuses;
          this.staffs_flags = result.data.data.flags;
          this.initial_office_id = result.data.data.initial_office_id;
          this.initial_team_id = result.data.data.initial_team_id;
          this.initial_work_status = result.data.data.initial_work_status;
          this.initial_flag = result.data.data.initial_flag;
          this.num_of_available_staffs = result.data.data.num_of_available_staffs;
          this.num_of_active_staffs = result.data.data.num_of_active_staffs;
          this.user_role = result.data.data.user_role;
          this.plan_name = result.data.data.plan_name;
          this.updateLoading(false);
        })
    },
    // 「勤務区分の設定、「勤務回数の設定」、「シフトパターンの設定」(BulkAdd)データ取得
    async getBulkAddData(sortType = null) {
      return axios.get(this.$route('shifts.bulk-add.show'), {
          params: {
            team_id: this.team_id,
            sort_type: sortType ?? 1,
            office_id: this.office.id,
            year: this.year,
            month: this.month,
          },
        })
        .then((result) => {
          const sortedOfficeStaffs = (sortType === null)
            ? result.data.office_staffs.sort(this.sortByShiftTableOfficeStaffOrder)
            : result.data.office_staffs;

          const sortedStaffs = (sortType === null)
            ? result.data.staffs.sort(this.sortByShiftTableOfficeStaffOrder)
            : result.data.staffs;

          this.sort_type = result.data.sort_type;
          this.office_workpatterns = result.data.workpatterns;
          this.office_staffs = sortedOfficeStaffs;
          this.staffs = sortedStaffs;
          this.office_staff_workpatterns_data = result.data.office_staff_workpatterns;
          this.default_monthly_working_counts = result.data.default_monthly_working_counts;
          this.count_work_category_summary = result.data.count_work_category_summary;
        });
    },
    sortByShiftTableOfficeStaffOrder(a, b) {
      return this.shiftTableOfficeStaffOrder.indexOf(a.office_staff_id) - this.shiftTableOfficeStaffOrder.indexOf(b.office_staff_id);
    },
    // 「組合せ設定」データ取得
    async getCombinationData() {
      await axios.get(this.$route('shifts.combinations.show', this.office.id))
        .then(result => {
          this.combinations_workpatterns = result.data.workpatterns;
          this.combinations_staffs = result.data.staffs;
          this.combinations_office = result.data.office;
          this.combinations_list = result.data.combination_list;
          this.combinations_codes = result.data.codes;
        });
    },
    async getPermanentPlacementData() {
      await axios.get(this.$route('shifts.permanent-placement.show', {
        year: this.year,
        month: this.month,
        office : this.office.id,
      }), {
          params: {
            team_id: this.team_id,
          },
      })
        .then(result => {
          this.permanent_placement_office = result.data.office;
          this.permanent_placement_staff_assignments = result.data.staff_assignments;
          this.permanent_placement_office_staff_workpatterns = result.data.office_staff_workpatterns;
          this.count_work_category_summary = result.data.count_work_category_summary;
          this.workpatterns_by_team_and_category = result.data.workpatterns_by_team_and_category;
          const basic_infos = result.data.basic_infos;
          this.createPermanentPlacementDatas(basic_infos);
        });
    },
    // 「チームの設定」のvueで保持するチームデータ更新
    updateTeamData(data) {
      this.teams_team.data = data.team;
    },
    createPermanentPlacementDatas(basicInfos) {
      // 表示する職員情報をいったんリセット
      this.permanent_placement_basic_infos.splice(0);
      // 表示する勤務区分をいったんリセット
      Object.keys(this.permanent_placement_workpatterns).forEach(key => {
        this.$delete(this.permanent_placement_workpatterns, key)
      });

      // 勤務カテゴリーの勤務区分
      let workpatterns_filtered_work = {};
      // 休暇カテゴリーの勤務区分
      let workpatterns_filtered_holiday = {};

      // 更新された職員で改めて表示用のデータを作成
      for (let key in basicInfos) {
        let item = basicInfos[key];
        // 職種
        if (item.occupation_id === null) {
          item.occupation_id = '';
        }
        // 選択チームが無所属かつチームIDがnull
        // or チームIDが一致する
        // or チームIDが0（全チーム）
        // の場合
        if (
          (this.team_id === -1 && item.team_id === null)
          || (this.team_id !== 0 && item.team_id === this.team_id)
          || this.team_id === 0
        ) {
          // 当月データ or 有効な職員であれば表示用のデータに追加
          if (!this.past || item.enable_flg) {
            this.permanent_placement_basic_infos.push(item);
          } else {
            // 過去データの場合はシフトデータが存在するか確認
            let staff_id = item.office_staff_id + '_' + item.occupation_id;
            if (staff_id in this.shift_data) {
              let exist = false;
              for (let day in this.shift_data[staff_id]) {
                if ('shift' in this.shift_data[staff_id][day]) {
                  exist = true;
                  break;
                }
              }
              if (exist) {
                this.permanent_placement_basic_infos.push(item);
              }
            }
          }

          // 有効な職員の場合は勤務区分を表示用のデータに追加
          if (item.enable_flg) {
            // 勤務用データに追加
            for (let workpattern_id in item.workpatterns.work) {
              if (!(workpattern_id in workpatterns_filtered_work)) {
                workpatterns_filtered_work[workpattern_id] = item.workpatterns.work[workpattern_id];
              }
            }
            // 休暇用データ追加
            for (let workpattern_id in item.workpatterns.holiday) {
              if (!(workpattern_id in workpatterns_filtered_holiday)) {
                workpatterns_filtered_holiday[workpattern_id] = item.workpatterns.holiday[workpattern_id];
              }
            }
          }
        }
      }

      // 勤務区分の表示用データに入れ直す
      for (let workpattern_id in workpatterns_filtered_work) {
        this.$set(this.permanent_placement_workpatterns, workpattern_id, workpatterns_filtered_work[workpattern_id]);
      }
      // 休暇区分の表示用データに入れ直す
      for (let workpattern_id in workpatterns_filtered_holiday) {
        this.$set(this.permanent_placement_workpatterns, workpattern_id, workpatterns_filtered_holiday[workpattern_id]);
      }
    },
    checkAutoCreationStatus() {
      // PermamentPlacementコンポーネントのメソッドを呼び出す
      this.$refs.permanentPlacement.checkAutoCreationStatus();
    }
  },
  computed: {
    // 連続勤務対象外職員指定用のスタッフリスト作成
    officeStaffsForExcluded() {
      return this.office_office ? this.office_office.staffs_for_excluded : [];
    },
    avoidPattern () {
      // 回避カテゴリーのrule.id
      const ruleId = this.office_rules.filter(office_rule => office_rule.constraint_no === 1004)[0].id;

      return this.office_office.rules?.[ruleId]?.avoid_pattern ?? [];
    },
  },
  watch: {
    basic_infos: {
      handler(new_basic_infos) {
        this.shiftTableOfficeStaffOrder = Array.from(new Set(new_basic_infos.map(({ office_staff_id: id }) => id)));
      },
      deep: true,
    },
    office_staffs: {
      handler(new_office_staffs) {
        this.shiftTableOfficeStaffOrder = new_office_staffs.map(({ office_staff_id: id }) => id);
      },
      deep: true,
    },
  }
}
</script>
