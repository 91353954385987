<template>
  <div>
    <table id="schedule-table" class="table" :class="getMainTableClass()" :style="'table-layout: fixed; border: none!important; width: ' + getWidth() + 'px;'">
      <thead>
        <tr>
          <th class="fixed fixed00 fixed_show table-header stripe_green show-all"></th>
          <!-- シフトの場合のみ表示 -->
          <template v-show="view_type === 'schedule' && !edit_mode">
            <th v-show="view_type === 'schedule' && is_open_sort" class="fixed fixed00 fixed_sort table-header show-sort" style="text-align: center;">並替</th>
          </template>
          <th v-show="is_open_team" class="fixed fixed00 fixed01 table-header show-team">チーム名</th>
          <th v-show="!edit_mode && is_open_flg" class="fixed fixed00 fixed02 table-header show-flg">
            フラグ
            <b-button
              class="btn_shift_reset"
              style="font-size: 10px!important; margin-right: 0px! important;"
              @click="showAboutFlag"
            >
              <font-awesome-icon :icon="['far', 'circle-question']" style="color: #1059CC" />
            </b-button>
          </th>
          <th class="fixed fixed00 fixed03 table-header show-name">
            <div style="display: flex; justify-content: space-between;">
              <div style="display: flex; padding: 0; margin: auto 0;">
                役職／名前／資格
              </div>
              <div class="sort_box" style="top: -3px;">
                <v-select
                  :items="sort_item"
                  item-text="text"
                  item-value="id"
                  @change="sortStaffsBy($event)"
                  return-object
                >
                </v-select>
                <font-awesome-icon icon="sort-amount-down" />
              </div>
            </div>
          </th>
          <th v-show="!edit_mode && is_open_occupation" class="fixed fixed00 fixed04 table-header show-occupation">職種</th>
          <template v-if="view_type === 'application_status'">
            <th class="fixed fixed00 fixed05 table-header show-rate">
              希望休申請日数<br>当月 / 累積 / 取得率
            </th>
            <th class="fixed fixed00 fixed06 table-header show-approval">
              <b-button
                class="btn_red_shadow btn_approval"
                style="font-size: 12px; text-align: center; width: 70px; min-width: 70px; max-width: 70px; padding: 7px 11px;"
                :disabled="all_approval"
                @click="approvalAll()"
              >
                一括承認
              </b-button>
            </th>
          </template>
          <th
            v-for="(day, index) in days"
            class="fixed fixed00 table-days"
            :class="( edit_mode ? 'td-day-edit' : 'td-day' )"
            :key="index"
          >
            <p>{{ day.day }}<span :style="{ color: getDateColor(day) }">{{ day.w }}</span></p>
            <b-button
              class="btn_shift_reset"
              style="font-size: 10px!important; margin-right: 0px! important;"
              @click="showMemoDialog(day.day)"
            >
              <font-awesome-icon
                icon="pen"
                style="font-size: 14px;"
                :style="{
                  color:
                    shift_day_memo_root.find(
                      (item) =>
                        item.target_date.substring(8, 10) === day.day &&
                        ((selected_team_id === 0 && item.assignment === 'office') ||
                          (selected_team_id === -1 && item.assignment === 'noteam') ||
                          (item.team_id === selected_team_id)) && item.event !== null  && item.event !== ''
                    )
                      ? '#297ADB'
                      : '#9EA9B3'
                }"
              />
            </b-button>
          </th>
          <template>
            <th
              v-for="(category, index) in work_categories"
              :key="category"
              :style="getRight(index)"
              :class="getTotal(category)"
              class="fixed fixed00 summary table-header"
            >
              <div style="white-space: pre-wrap;" :title="category" v-text="headerText(category)"></div>
            </th>
          </template>
        </tr>
      </thead>
      <!-- options https://github.com/SortableJS/Sortable#options -->
      <draggable
        v-model="new_basic_infos"
        tag="tbody"
        class="dragArea"
        handle=".handle"
        ghost-class="sortable-ghost"
        chosen-class="sortable-chosen"
        animation:300,
        @update="onUpdate"
        :disabled="disabled"
      >
        <tr
          v-for="(item, index) in new_basic_infos"
          :key="index"
          v-show="is_open_all_row || (!is_open_all_row && getDisplayFlg(item))"
        >
          <!-- 非表示フラグ -->
          <CheckShowFlg
            :staff="item"
            :year="year"
            :month="month"
            :shift_monthly_display_rule="getShiftMonthlyDisplayRule(item)"
            :is_open_all_row="is_open_all_row"
            @updateStaff="updateStaffData"
            @updateShiftMonthlyDisplayRule="updateShiftMonthlyDisplayRule"
          />
          <template v-if="view_type === 'schedule' && !edit_mode">
            <td
              v-show="view_type === 'schedule' && is_open_sort"
              class="fixed fixed_sort table-fixed-row show-sort"
              style="vertical-align: middle;"
            >
              <div style="display: flex; align-items: center;">
                <div
                  :class="!disabled ? `handle` : ``"
                >
                <font-awesome-icon icon="grip-vertical" style="color: #C6C8CC; font-size: 15px;" />
              </div>
                <input
                  v-model="item.display_order"
                  type="number"
                  min="1"
                  class="form-control"
                  style="font-size: 11px; text-align:center; height: 20px; padding: 0px; margin-left:2px;"
                  @blur="updateDisplayOrder(index, item)"
                  :disabled="disabled"
                  >
              </div>
            </td>
          </template>
          <!-- チーム名 -->
          <td v-show="is_open_team" class="fixed fixed01 table-fixed-row show-team" style="color: #1059CC; vertical-align: middle;" :style="setFlexTdPadding()">
            <div v-if="item.team_name !== null && item.team_name !== ''" class="link_area" :class="setFlexCellLine()" @click="goTeam(item)">{{ item.team_name }}</div>
            <div v-else :class="setFlexCellLine()">所属なし</div>
          </td>
          <!-- 主務フラグ，新人フラグの表示/切り替え -->
          <FlgColumn
            v-show="!edit_mode && is_open_flg"
            @updateStaff="updateStaffData"
            @updateShiftMonthlyDisplayRule="updateShiftMonthlyDisplayRule"
            :view_type="view_type"
            :staff="item"
            :year="year"
            :month="month"
            :shift_monthly_display_rule="getShiftMonthlyDisplayRule(item)"
          />
          <!-- 役職/名前/資格 -->
          <td class="fixed fixed03 table-fixed-row show-name" :style="setFlexTdPadding()">
            <div :class="setFlexCellLine()">
              <!-- 役職 -->
              <div style="display: flex; align-items:center;">
                {{ item.position }}
              </div>
              <!-- 資格の表示ボタン・名前 -->
              <div>
                <div class='contenedor' :class='setCheckboxCursor(item.qualification)'>
                  <!-- 資格の表示ボタン -->
                  <label class="view_qualification_button">
                    <input v-model="is_show_qualification" type="checkbox" @change="show_qualification(index)">
                    <span
                      :id="'angle-' + index"
                      :style="'font-size: ' + (edit_mode ? '9px' : '14px') + ';'"
                      style="display: flex; align-items: center; justify-content: center;"
                    >
                      <font-awesome-icon icon="angle-down" />
                    </span>
                  </label>
                  <!-- 名前ボタン -->
                  <div
                    class="link_area staff_name"
                    :style="'font-size: ' + (edit_mode ? '12px' : '12px') + ';'"
                    @click="goStaff(item)"
                  >
                    {{ item.lastname + ' ' + item.firstname }}
                  </div>
                </div>
              </div>
              <!-- 資格 -->
              <div :id="'qualification-' + index" class="expand" style="height: auto; text-align: left; display: none;">
                <span class="content">{{ item.qualification }}</span>
              </div>
            </div>
          </td>
          <!-- 職種 -->
          <td v-show="!edit_mode && is_open_occupation" class="fixed fixed04 table-fixed-row show-occupation" style="vertical-align: middle;">
            <p>{{ item.occupation_name }}</p>
          </td>
          <!-- 休暇申請状況の場合のみ表示 -->
          <template v-if="view_type === 'application_status'">
            <!-- 希望休申請日数 当月/累積/取得率 -->
            <td class="fixed fixed05 table-fixed-row show-rate" style="text-align: center; vertical-align: middle;">
              <div>{{ getRequestCount(item) }} / {{ getRequestTotal(item) }} / {{ getRate(item) }}％</div>
              <div style="color: #D61515;">残有休　{{ item.remaining_annual_holidays }}</div>
            </td>
            <!-- 承認ボタン -->
            <td class="fixed fixed06 table-fixed-row show-approval" style="text-align: center; vertical-align: middle;">
              <b-button
                class="btn_approval"
                style="font-size: 14px; width: 70px; min-width: 70px; max-width: 70px; height: 40px;"
                :disabled="new_shift_applies[item.staff_id]['all_approval']"
                @click="showApprovalDialog(item)"
              >
                {{ new_shift_applies[item.staff_id]['all_approval'] ? '完了' : '承認' }}
              </b-button>
            </td>
          </template>
          <!-- 各日の休暇/シフト状況 -->
          <template v-for="(day, dayIndex) in days">
            <ApplicationStatusColumn
              v-if="view_type === 'application_status'"
              :key="`application_status` + dayIndex"
              :day="day"
              :staff="item"
              :initial_shift_apply="new_shift_applies[item.staff_id].data[day.full]"
              :holiday_limit="holiday_limit"
              :work_categories="org_work_categories"
              @updateApplicationStatus="updateApplicationStatus"
            />
            <ScheduleColumn
              v-else
              :key="`schedule` + dayIndex"
              :day="day"
              :staff="item"
              :initial_shift="new_shifts[item.office_staff_id + '_' + item.occupation_id][day.full]"
              :work_categories="org_work_categories"
              :edit_mode="edit_mode"
              :is_consecutive_workpattern="is_consecutive_workpattern"
              :consecutive_shift="consecutive_shift"
              :class="{ assignable: consecutiveWorkpatternMatchList[index] }"
              @updateShift="updateShift"
            />
          </template>
          <!-- シフト表の場合のみ，勤務カテゴリごとの集計列 -->
          <template v-if="view_type === 'schedule'">
            <template v-for="(category, index) in work_categories">
            <SummaryColumn
              shift-key='shift'
              :shifts="new_shifts[item.office_staff_id + '_' + item.occupation_id]"
              :monthly_working_count="monthly_working_count(item.office_staff_id, category)"
              :work_category="index"
              :edit_mode="edit_mode"
              :is_show_workpattern_val="is_show_workpattern_val"
              :office_staff_workpattern="filtered_office_staff_workpattern[item.office_staff_id]"
              :office_staffs="office_staffs[item.office_staff_id]"
              :count="Object.keys(work_categories).length"
              :no="parseInt(index)"
            />
            </template>
          </template>
          <template v-if="view_type === 'application_status'">
            <template v-for="(category, index) in work_categories">
              <SummaryColumn
                shift-key='apply'
                :shifts="new_shift_applies[item.staff_id].data"
                :monthly_working_count="monthly_working_count(item.office_staff_id, category)"
                :work_category="index"
                :edit_mode="edit_mode"
                :is_show_workpattern_val="is_show_workpattern_val"
                :office_staff_workpattern="filtered_office_staff_workpattern[item.office_staff_id]"
                :office_staffs="office_staffs[item.office_staff_id]"
                :count="Object.keys(work_categories).length"
                :no="parseInt(index)"
              />
            </template>
          </template>
        </tr>
      </draggable>
    </table>
    <AboutFlagDialog ref="AboutFlagDialog" />

    <!-- 承認/否認ダイアログ -->
    <template v-if="view_type === 'application_status'">
      <ApprovalDialog
        ref="approvalDialog"
        :year="year"
        :month="month"
        :office="office"
        @approval="approval"
      />
    </template>

    <!-- メモダイアログ -->
    <MemoDialog
      ref="memoDialog"
      :year="year"
      :month="month"
      :office_id="office.id"
      :selected_team_id="selected_team_id"
      :shift_day_memo="shift_day_memo_root"
      @memo-updated="onMemoUpdated"
      @memo-added="onMemoAdded"
    />
  </div>
</template>

<style scoped>
.assignable {
  background-color: #FCF16E!important;
}
.table-responsive {
  overflow-x: scroll;
}
.table-application-status,
.table-schedule {
  border-collapse: separate;
  border-spacing: 0;
  background: #ffffff;
  margin-bottom: 0;
}
.staff_name {
  display: flex;
  color: #1059CC;
  font-weight: 600;
  align-items: center;
}
.shortText {
  font-size:  0.9rem!important;
  font-weight: bold;
}
.cursor_grab {
  cursor: grab;
}
.chk_pointer label:hover {
  cursor: pointer;
}
.dragArea{
    min-height: 10px;
}
/** テーブル固定共通 **/
.table-header {
  z-index: 9999;
}
.table-days {
  z-index: 9998;
}
.table-fixed-row {
  z-index: 999;
}
.td-sticky-border {
  display: grid;
  align-items: center;
  min-width: 101%;
  max-width: 101%;
  min-height: 101%;
  max-height: 101%;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  background: #FFFFFF;
}
/** 通常モード **/
.table-application-status th,
.table-schedule th {
  height: 40px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle!important;
  background-color: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  word-break: break-all;
  padding: 0px!important;
}
.table-application-status td,
.table-schedule td {
  height: 40px;
  font-size: 10px!important;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  word-break: break-all;
  padding: 1px!important;
}
.table-schedule td p {
  margin: 0;
}
.table-application-status .fixed,
.table-schedule .fixed {
  position: sticky;
  left: 0;
}
.table-schedule .fixed {
  /* 全枠共通の高さ */
  height: 40px;
}
.table-application-status .fixed00,
.table-schedule .fixed00 {
  top: 0;
}
.table-application-status .fixed_show,
.table-schedule .fixed_show {
  width: 30px;
  left: 0px;
  border: none!important;
}
.table-schedule .fixed_sort {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  left: 30px;
}
.table-application-status .fixed01,
.table-schedule .fixed01 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  left: 80px;
}
.table-application-status .fixed02,
.table-schedule .fixed02 {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  left: 160px;
}
.table-schedule .fixed02 {
  font-size: 15px;
}
.table-application-status .fixed03,
.table-schedule .fixed03 {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  left: 200px;
  padding: 2px!important;
}
.table-application-status .fixed04,
.table-schedule .fixed04 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  left: 380px;
}
.table-schedule .summary {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}
.table-application-status .fixed01 {
  left: 30px;
}
.table-application-status .fixed02 {
  left: 110px;
}
.table-application-status .fixed03 {
  left: 150px;
}
.table-application-status .fixed04 {
  left: 330px;
}
.table-application-status .fixed05 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 410px;
}
.table-application-status .fixed06 {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  left: 510px;
}
.td-day {
  width: 42px;
  min-width: 42px;
  max-width: 42px;
}
.td-worktime-total {
  width: 80px!important;
  min-width: 80px!important;
  max-width: 80px!important;
}

/** 作成モード **/
.table-schedule-edit th {
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle!important;
  background-color: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  word-break: break-all;
  padding: 5px 2px!important;
}
.table-schedule-edit td {
  font-size: 9px!important;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  word-break: break-all;
  left: 0px;
  padding:2px 2px!important;
}
.table-schedule-edit td p {
  margin: 0;
}
.table-schedule-edit .fixed {
  position: sticky;
  left: 0;
}
.table-schedule-edit .fixed {
  /* 全枠共通の高さ */
  height: 40px;
}
.table-schedule-edit .fixed00 {
  top: 0;
}
.table-schedule-edit .fixed01 {
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  left: 0px;
}
.table-schedule-edit .fixed03 {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  left: 45px;
  padding: 1px!important;
}
.table-schedule-edit .summary {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
}
.td-day-edit {
  width: 39px;
  min-width: 39px;
  max-width: 39px;
}
.td-day-edit .workpattern_shift_td {
  height: 40px!important;
  color: red;
}
.td-worktime-total-edit {
  width: 50px!important;
  min-width: 50px!important;
  max-width: 50px!important;
}

/* 選択中の行のCSS */
.sortable-chosen {
    opacity: 0.7;
    background-color:#dcdcdc;
}
/* 選択されてない行のCSS */
.sortable-ghost {
    background-color:#dcdcdc;
}
/* ドラッグ用のポイントCSS */
.handle {
  cursor: move;
}
</style>

<script>
import CheckShowFlg from '@/Pages/Shifts/Components/Table/MainTable/Column/ShowColumn';
import FlgColumn from '@/Pages/Shifts/Components/Table/MainTable/Column/FlgColumn';
import ApplicationStatusColumn from '@/Pages/Shifts/Components/Table/MainTable/Column/ApplicationStatusColumn';
import ScheduleColumn from '@/Pages/Shifts/Components/Table/MainTable/Column/ScheduleColumn';
import SummaryColumn from '@/Pages/Shifts/Components/Table/MainTable/Column/SummaryColumn';
import draggable from 'vuedraggable'

import AboutFlagDialog from '@/Pages/Shifts/Components/Dialog/AboutFlagDialog';
import MemoDialog from '@/Pages/Shifts/Components/Dialog/MemoDialog';
import ApprovalDialog from '@/Pages/Shifts/Components/Dialog/ApprovalDialog';

export default {
  name: 'ShiftsMainTable',
  components: {
    CheckShowFlg,
    FlgColumn,
    ApplicationStatusColumn,
    ScheduleColumn,
    SummaryColumn,

    AboutFlagDialog,
    MemoDialog,
    ApprovalDialog,

    draggable,
  },
  props: {
    //////// application_status と shift に共通の値

    // view_type application_status or schedule
    view_type: { type: String, default: 'schedule' },
    // edit_mode true:edit false:nomal
    edit_mode: { type: Boolean, default: false },
    // 日付データ
    days: { type: Array },
    // Office
    office: { type: Object },
    // 表示データの年月
    year: { type: String },
    month: { type: String },
    summary: { type: Object },
    // ユーザの基本情報
    basic_infos: { type: Array },

    // 変更されたデータがあるかどうか
    is_changed: {type: Boolean },

    // チーム選択がされているかどうか
    selected_team_id: { type: Number },

    //////// application_statusの場合のみ存在する値
    // 希望休取得可能枠数
    holiday_limit: { type: Object },
    // 休暇申請状況データ
    shift_applies: {
      type: Object,
      default: function() {
        return {};
      }
    },

    // 勤務区分一覧
    workpatterns: {
      type: Array,
      default: () => {
        return [];
      }
    },

    filtered_office_staff_workpattern: { type: Object },
    office_staffs: { type: [Object, Array] },
    //////// shiftの場合のみ存在する値
    // 勤務カテゴリのリスト
    org_work_categories: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // シフトデータ
    shift_data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    monthly_working_count_list: {
      type: Object,
      default: function() {
        return {};
      }
    },

    // 非表示ボタン用データ
    shift_monthly_display_rules: { type: Object|Array },
    // 月次でのイベントをメモ管理
    shift_day_memo: { type: Array },

    // 固定列の表示状態
    is_open_sort: { type: Boolean },
    is_open_team: { type: Boolean },
    is_open_flg: { type: Boolean },
    is_open_occupation: { type: Boolean },

    // 全表示ボタンの状態
    is_open_all_row: { type: Boolean },


    is_show_workpattern_val: {type: Boolean, require: true},

    // 連続配置の状態
    is_consecutive_workpattern: { type: Boolean },
    consecutive_shift: { type: Object }
  },
  data () {
    return {
      ////// application_status と shift で共通利用するデータ
      work_categories: {},

      // ソート用のデータ
      sort_item: ["役職順", "名前順", "職種順"],

      ////// application_statusで利用するデータ
      // 全データ承認済みかどうか
      all_approval: false,
      // holiday_count: {},
      new_shift_applies: {},

      ////// shiftで利用するデータ
      new_shifts: {},

      new_basic_infos: [],

      // マウス動作
      onMouse: false,
      onCursor: false,

      // 行の表示・非表示状態
      unshow_button: true,

      // 資格の表示・非表示状態
      is_show_qualification: false,

      shift_day_memo_root: this.shift_day_memo,
    }
  },
  methods: {
    // tableの幅取得
    getWidth() {
      let return_width = 0;
      let add_width =  460;
      let day_width = 42;
      let category_width= 28;
      let total_add_width = 22;

      if (this.view_type != 'schedule') {
        add_width = 600;
      }

      if (this.edit_mode) {
        add_width = 155;
        day_width = 39;
        category_width = 32;
        total_add_width = 8;
      }

      if (this.view_type === 'schedule') {
        return_width = add_width + (this.days.length * day_width) + (Object.keys(this.work_categories).length * category_width) + total_add_width;
      } else {
        return_width = add_width + (this.days.length * day_width);
      }
      return return_width;
    },
    showAboutFlag() {
      this.$refs.AboutFlagDialog.openDialog();
    },
    /**
     * draggable系
     */
    // Changed sorting within list
    onUpdate: function (event) {
      // new_basic_infos.display_orderを書き換える
      let new_basic_infos = this.new_basic_infos;
      new_basic_infos.reduce((acc, cur, index) => {
        cur.display_order = index + 1;
      }, 0);

      this.saveDiplayOrder(structuredClone(new_basic_infos))
    },
    // カレンダーの曜日色取得
    getDateColor(day) {
      let result_color = day.color;
      if (day.holiday === true) {
        result_color = '#D61515';
      }
      return result_color;
    },
    // staff画面に遷移
    goStaff(item) {
      let data = {
        staff: item.staff_id,
        office_id: this.office.id
      };
      if (item.team_id !== null) {
        data.team_id = item.team_id;
      } else {
        data.team_id = 0;
      }
      this.updateLoading(true);
      this.$inertia.get(this.$route('staffs.edit', data));
    },
    // team画面に遷移 teamに所属していない場合は遷移させない
    goTeam(item) {
      if (item.team_id !== null) {
        this.updateLoading(true);
        this.$inertia.get(this.$route('teams.edit', { team: item.team_id }));
      }
    },
    // loading制御
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // 希望休申請日数 当月
    getRequestCount(item) {
      if (item.monthly_shift_apply.length !== 0) {
        return item.monthly_shift_apply.request_holidays;
      } else {
        return 0;
      }
    },
    // 希望休申請日数 累計
    getRequestTotal(item) {
      if (item.requested_shift_applies.length !== 0) {
        return item.requested_shift_applies;
      } else {
        return 0;
      }
    },
    // 希望休取得率
    getRate(item) {
      if (item.monthly_shift_apply.length !== 0) {
        return item.monthly_shift_apply.satisfaction_percentage;
      } else {
        return 0.0;
      }
    },
    // 全申請承認済みフラグをアップデート
    updateAllApprovalFlg() {
      // console.log('Table updateAllApprovalFlg');
      let all_approval = true;
      for (let staff_id in this.new_shift_applies) {
        if (!this.new_shift_applies[staff_id].all_approval) {
          all_approval = false;
          break;
        }
      }

      // 全ユーザが承認済み または ユーザが0人の場合 all_approval = true
      this.all_approval = all_approval || this.basic_infos.length === 0;
      this.$emit('updateAllApprovalFlg', all_approval);
    },
    // 全承認
    approvalAll() {
      if (this.is_changed) {
        this.$swal({
          text: '保存していない変更があります。保存したあとで承認を行ってください。',
          icon: "notice",
        });
        return;
      }
      this.$swal({
        text: '一括で承認します。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateLoading(true);
          this.$inertia.post(
            this.$route('shifts.application-status.approve.all', {
              year: this.year,
              month: this.month,
              office: this.office.id
            }),
            {},
            {
              onError:()=>{},
              onSuccess:()=>{
                this.$swal({
                  title: '承認しました',
                  icon: "success",
                });
              },
            }
          )
        }
      });
    },
    // メモダイアログ表示処理
    showMemoDialog(key) {
      this.$refs.memoDialog.openDialog(key);
    },
    // メモの更新
    onMemoUpdated(newMemo, day) {
       this.shift_day_memo_root = this.shift_day_memo_root.map(item => {
        if (item.target_date.substring(8, 10) === day &&
                ((this.selected_team_id === 0 && item.assignment === 'office') ||
                    (this.selected_team_id === -1 && item.assignment === 'noteam') ||
                    (item.team_id === this.selected_team_id))) {
          return { ...item, event: newMemo };
        }
        return item;
      });
    },
    // メモ新規追加
    onMemoAdded(newMemo, day) {
      this.shift_day_memo_root.push({
        target_date: `${this.year}-${this.month}-${day}`,
        event: newMemo,
        assignment: this.getAssignment(),
        team_id: this.selected_team_id
      });
    },

    getAssignment() {
      if (this.selected_team_id === 0) {
        return 'office';
      } else if (this.selected_team_id === -1) {
        return 'noteam';
      } else {
        return 'team';
      }
    },
    // 各行ごとの承認ダイアログ表示処理
    showApprovalDialog(user) {
      if (this.is_changed) {
        this.$swal({
          text: '保存していない変更があります。保存したあとで承認を行ってください。',
          icon: "notice",
        });
        return;
      }
      let shift_apply = this.new_shift_applies[user.staff_id]['data'];
      this.$refs.approvalDialog.openDialog(user, shift_apply);
    },
    // 承認/否認処理
    approval(data, action_flg) {
      this.updateLoading(true);
      this.$inertia.post(
        this.$route('shifts.application-status.approve', {
          year: this.year,
          month: this.month,
          office: this.office.id
        }),
        data,
        {
          onError:()=>{},
          onSuccess:()=>{
            if (action_flg === 1) {
              this.$swal({
                title: '承認しました',
                icon: "success",
              });
            } else if(action_flg === 2) {
              this.$swal({
                title: '否認しました',
                icon: "success",
              });
            }
          },
          onFinish:()=>{}
        }
      );
      this.$refs.approvalDialog.action3();
    },
    // 休暇申請状況の変更
    updateApplicationStatus(data) {
      // console.log('Table updateApplicationStatus');
      this.$set(this.new_shift_applies[data.user.staff_id]['data'][data.day.full], 'apply', data.shift_apply.apply);
      this.new_shift_applies[data.user.staff_id].all_approval = false;
      this.updateShiftApplies(structuredClone(this.new_shift_applies));
      this.$emit('updateApplicationStatus', data);
    },
    // シフトの変更
    updateShift(data) {
      this.$emit('updateShift', data);
    },
    // 当月分のスタッフごとのmonthly_working_countを取得する
    // 所定休日のみがチェック対象
    monthly_working_count(office_staff_id, category) {
      if (category === '所定休日') {
        return {
          min : this.monthly_working_count_list[office_staff_id] ? this.monthly_working_count_list[office_staff_id].min_monthly_working_count : 0,
          max : this.monthly_working_count_list[office_staff_id] ? this.monthly_working_count_list[office_staff_id].max_monthly_working_count : 0,
        }
      }
    },
    // シフトの集計部は2桁で改行、4桁表示
    headerText( text ) {
      let result = text;

      if (text === '総労働時間') {
        result = '総労働\n時間';
      } else {
        if (text.length > 4) {
          // 4文字以上の場合は、4文字まで表示（総労働時間は除く）
          result = text.substr(0, 4);
        }

        // 2文字以上の場合は、2文字で改行
        if (result.length > 2) {
          result = result.substr(0, 2) + '\n' + result.substr(2);
        }
      }

      return result;
    },
    // 総労働時間の場合は横幅を変更する
    getTotal(category) {
      if (category === '総労働時間') {
        if (this.edit_mode) {
          return 'td-worktime-total-edit';
        } else {
          return 'td-worktime-total';
        }
      }
      else {
          return 'td-worktime-total-edit';
        }
    },
    // シフト希望最新化
    updateShiftApplies(new_data) {
      // console.log('Table updateShiftApplies');
      Object.keys(this.new_shift_applies).forEach(key => {
        this.$delete(this.new_shift_applies, key)
      });
      for (let key in new_data) {
        this.$set(this.new_shift_applies, key, new_data[key]);
      }
      this.updateAllApprovalFlg();
    },
    // シフトデータ最新化
    updateShiftData(new_data) {
      Object.keys(this.new_shifts).forEach(key => {
        this.$delete(this.new_shifts, key)
      });
      for (let key in new_data) {
        this.$set(this.new_shifts, key, new_data[key]);
      }
    },
    // 基本データの最新化
    updateBasicInfoData(new_data) {
      this.new_basic_infos.splice(0);
      for (let key in new_data) {
        this.new_basic_infos.push(new_data[key]);
      }
    },
    // テーブル行の表示・非表示
    changeShow() {
      const data = {
        office_staff_id: this.staff.office_staff_id,
        target_month: this.year + this.month,
        occupation_id: this.staff.occupation_id,
        team_id: this.staff.team_id,
        display_flg: this.unshow_button,
      };
      // axiosにて非表示フラグ変更(shift_monthly_display_rulesテーブルの更新)
      axios.patch(this.$route('shifts.staff.display'), data)
        .then((response) => {
          // 失敗時
          if(response.data.status === 'failure') {
            this.$swal({
              icon: 'error',
              html: response.data.message,
            });
            // 非表示ボタン戻す
            this.unshow_button = this.unshow_button;
            return
          }

          // 成功時のみShiftMonthlyDisplayRule情報だけ更新して再描画
          this.$emit('updateShiftMonthlyDisplayRule', data)
        })
        .catch((error) => {
          this.$swal({
              icon: 'error',
              html: error,
          });
          // 非表示ボタン戻す
          this.unshow_button = this.unshow_button;
        });
    },
    // 役職／名前／資格の開閉ボタン
    show_qualification( index ) {
      let elQualification = document.getElementById('qualification-' + index);
      let elAngle = document.getElementById('angle-' + index);

      if (elQualification !== null) {
        if (this.is_show_qualification) {
          // 開く場合の処理
          document.getElementById('qualification-' + index).style.display='';
          elAngle.innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-angle-up"><path fill="currentColor" d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" class=""></path></svg>';
        } else {
          // 閉じる場合の処理
          document.getElementById('qualification-' + index).style.display='none';
          elAngle.innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-angle-down"><path fill="currentColor" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" class=""></path></svg>';
        }
      }
    },
    /**
     * setCheckboxCursor
     * 資格がある場合のみ、[ｖ]ボタンのカーソルを変更する
     */
    setCheckboxCursor( val ) {
      let setClass = '';
      if (val !== null && val !== '') {
        setClass = 'chk_pointer';
      }

      return setClass;
    },
    /**
     * updateStaffData
     * 新人フラグ、リーダーフラグのリアクティブ更新
     */
    updateStaffData(updateStaffData) {
      // basic_infos内のstaff_idとoffice_staff_idが同一のleader_flg, rookie_flgを更新
      this.basic_infos.forEach((basic_info) => {
        if (basic_info.staff_id === updateStaffData.staff_id
          && basic_info.office_staff_id === updateStaffData.office_staff_id)
        {
          basic_info.leader = updateStaffData.leader;
          basic_info.rookie = updateStaffData.rookie;
        }
      });
    },
    /**
     * 非表示ボタンの表示制御
    */
    // shift_monthly_display_rule取得
    getShiftMonthlyDisplayRule(data) {
      return this.shift_monthly_display_rules.find((rule) => {
        return rule.office_staff_id === data.office_staff_id
          && rule.occupation_id === data.occupation_id
          && rule.team_id === data.team_id
          && rule.target_month === this.year + this.month;
      });
    },
    // shift_monthly_display_rulesのdisplay_flgを取得
    getDisplayFlg(data) {
      let rule = this.getShiftMonthlyDisplayRule(data);
      if (rule) {
        return rule.display_flg;
      } else {
        return true;
      }
    },
    // 非常時フラグの更新時の処理（FlgColumnから戻り）
    updateShiftMonthlyDisplayRule(updateShiftMonthlyDisplayRule) {
      const foundRule = this.shift_monthly_display_rules.find((rule) =>
        rule.office_staff_id === updateShiftMonthlyDisplayRule.office_staff_id &&
        rule.occupation_id === updateShiftMonthlyDisplayRule.occupation_id &&
        rule.team_id === updateShiftMonthlyDisplayRule.team_id &&
        rule.target_month === updateShiftMonthlyDisplayRule.target_month
      );

      if (foundRule) {
        // ルールが見つかった場合は更新
        Object.assign(foundRule, { display_flg: updateShiftMonthlyDisplayRule.display_flg });
      } else {
        // 見つからなかった場合は新しいルールを追加
        this.shift_monthly_display_rules.push({ ...updateShiftMonthlyDisplayRule });
      }
    },
    /**
     * updateDisplayOrder
     * 表示順の更新
     */
    updateDisplayOrder(index, item) {
      // item.display_orderの変更がない時は何もしない
      if (index === Number(item.display_order) - 1) return;

      // 画面上の表示順を更新
      let deleteList = this.basic_infos.splice(index, 1);
      this.basic_infos.splice(Number(item.display_order) - 1, 0, deleteList[0])

      // データベース更新
      deleteList = this.new_basic_infos.splice(index, 1);
      this.new_basic_infos.splice(Number(item.display_order) - 1, 0, deleteList[0])
      this.saveDiplayOrder(structuredClone(this.new_basic_infos))
    },
    /**
     * saveDiplayOrder
     * 表示順の保存
     */
    async saveDiplayOrder(newBasicInfoData) {
      this.updateLoading(true);

      await this.$inertia.post(
        this.$route('shifts.schedule.display-order.save', {
          year: this.year,
          month: this.month,
          office: this.office.id
        }),
        newBasicInfoData,
        {
          preserveScroll: true,
          onError: (error_message) => {
            this.$swal(
              'error',
              error_message,
              'error')
          },
          onSuccess:()=>{
            // this.$swal(
            //   'success',
            //   '表示順を保存しました',
            //   'success')
          },
          onFinish:()=>{}
        }
      )
      this.updateLoading(false);
    },
    /**
     * updateLoading
     * ローディング表示
     */
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    /**
     * getMainTableClass
     * view_type と edit_mode でクラス切り替え
     */
    getMainTableClass() {
      let className = '';
      if (this.view_type === 'application_status') {
          className = 'table-application-status';
      } else {
        if (this.edit_mode) {
          className = 'table-schedule-edit';
        } else{
          className = 'table-schedule';
        }
      }
      return className;
    },
    /**
     * getRight
     * 作成モードのとき、右側を固定にするスクリプトを出力
     */
     getRight(no) {
      let setStyle = '';

      if (this.edit_mode) {
        let setRight = 0;
        let right_count = Object.keys(this.work_categories).length - no - 1;
        if (right_count > 0) {
          setRight = ((right_count - 1) * 32) + 40;
        }

        setStyle = 'position: sticky; right:' + setRight + 'px;'
      }

      return setStyle;
    },
    /**
     * setFlexTdPadding
     * 作成モードの場合、固定列のTDのpaddingを0に設定する
     */
     setFlexTdPadding() {
      if (this.edit_mode) {
        return 'padding: 0px!important;';
      } else {
        return '';
      }
     },
    /**
     * setFlexCellLine
     * 作成モードの場合、固定列はDIV領域の罫線をメインとする
     */
     setFlexCellLine() {
      if (this.edit_mode) {
        return 'td-sticky-border';
      } else {
        return '';
      }
    },
    /**
     * sortStaffsBy
     * ソート順の変更
     * 役職：positionsをlodgment_id=該当の拠点IDとdeleted_at is null で絞ったうえでdisplay_orderの昇順
     * 職種：occupationsをdeleted_at is null で絞ったうえでdisplay_orderの昇順
     * 名前：lastname_firstname_rubyの昇順
     */
    sortStaffsBy($event) {
      let sort_item = ''
      switch ($event) {
        case '役職順':
          sort_item = 'position_display_order|asc';
          break;
        case '名前順':
          sort_item = 'lastname_firstname_ruby|asc';
          break;
        case '職種順':
          sort_item = 'occupation_display_order|asc';
          break;
      }
      this.$emit('sortStaffsBy', sort_item);
    },
  },
  computed: {
    // ユーザ情報
    user() {
      return this.$page.props.auth.user
    },
    // 拠点情報
    lodgment() {
      return this.$page.props.auth.lodgment
    },
    disabled() {
      return this.selected_team_id !== 0 ? true : false;
    },
    /**
     * 連続配置で選択された勤務区分がnew_basic_infosのそれに含まれる
     * かどうかの確認結果を、new_basic_infosの順に格納した配列を返す
     * @return {Array<boolean>} 連続配置の勤務区分が含まれるかどうかの結果
    */ 
    consecutiveWorkpatternMatchList() {
      const mainId = this.consecutive_shift.main_id; // 連続配置のメインID
      const replacementId = this.consecutive_shift.replacement_id; // 連続配置の半休ID(あれば)

      // 連続配置が設定されていない場合、全てfalseの配列を返す
      if (! mainId) {
        return this.new_basic_infos.map(() => false);
      }

      // 連続配置が設定されている場合
      return this.new_basic_infos.reduce((acc, basicInfo) => {
        const workpatternList = [
          ...Object.keys(basicInfo.workpatterns.holiday),
          ...Object.keys(basicInfo.workpatterns.work)
        ];

        let result = workpatternList.includes(String(mainId));

        //　半休IDが設定されている場合、半休IDもチェックする
        if (replacementId) {
          result = workpatternList.includes(String(replacementId));
        }

        return [...acc, result];
      }, []);
    }
  },
  watch: {
    shift_applies: {
      handler(new_data, old_data) {
        // console.log('Table watcher shift_applies');
        this.updateShiftApplies(structuredClone(new_data));
      },
      deep: true,
    },
    shift_data: {
      handler(new_data, old_data) {
        // console.log('Table watcher shift_data');
        this.updateShiftData(structuredClone(new_data));
      },
      deep: true,
    },
    basic_infos: {
      handler(new_data, old_data) {
        // console.log('Table watcher basic_infos');
        this.updateBasicInfoData(structuredClone(new_data));
        this.updateLoading(false);
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    let i = 0;
    for (let key in this.org_work_categories) {
      if (key === 'holiday') continue;
      this.$set(this.work_categories, i, this.org_work_categories[key]);
      i++;
    }
    this.$set(this.work_categories, i, '所定休日');
    this.$set(this.work_categories, i + 1, '有休');
    this.$set(this.work_categories, i + 2, 'その他');
    this.$set(this.work_categories, i + 3, '総労働時間');
  },
  beforeDestroy() {
  },
  destroyed () {
    Object.keys(this.work_categories).forEach(key => {
      this.$delete(this.work_categories, key)
    });
  },
}
</script>
