<template>
    <b-button class="btn_white btn_autocreatefooter btn_green_outline">
        一覧に戻る
    </b-button>
</template>
<script>
export default {
    name: 'BtnBackToList',
};
</script>
