<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" persistent max-width="650">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div v-if="type === 1">
            シフトをダウンロードする対象のチームを選択
          </div>
          <div v-else>
            シフトを展開する対象のチームを選択
          </div>
          <div>
            <button class="btn_close" @click="dialog = false">✕</button>
          </div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <p v-if="type === 1"> シフトをダウンロードする対象のチームを選択してください。</p>
          <p v-else> シフトを展開する対象のチームを選択してください。</p>

          <p v-if="type === 0" style="color: #ff0000">今後は、チーム毎に展開できるようになります。</p>
          <v-combobox
            v-model="select_teams"
            :items="item_teams"
            item-text="name"
            item-value="id"
            style="background: #FFFFFF; border: 1px solid #ced4da; width: 100%; min-width: 100%; margin-top: 20px;"
            dense
            outlined
            hide-selected
            hide-details
            multiple
            small-chips
            placeholder=""
            @change="onChangeTeam($event)"
          ></v-combobox>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
          <div style="display: flex; justify-content: space-between; width: 100%; padding: 0px 70px 70px 70px;">
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="submit"
            >OK</button>
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="dialog = false"
            >キャンセル</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'SelectTeamsDialog',
  components: {
  },
  props: {
    teams: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data () {
    return {
      dialog: false,
      select_teams: [],
      item_teams: [],
      type: 0,
    }
  },
  methods: {
    openDialog(select_data) {
      this.type = select_data;
      if (select_data === 1) {
        this.item_teams = this.teams;
      }
      else
      {
        this.item_teams = this.teams.filter(team => team.name === "全チーム");
      }
      this.select_teams = [];
      this.dialog = true;
    },
    submit() {
      this.dialog = false;
      this.$emit('selectTeams', this.select_teams);
    },
    onClickOutside() {
      this.$refs.modalform.focus();
      this.dialog = false;
    },
    onChangeTeam($event) {
      if (this.select_teams == null || this.select_teams.length == 0) {
        // 設定値が空である場合
        return;
      }
      if (this.select_teams[0].id == 0 && this.select_teams.length > 1) {
        // 先頭に全選択が選択されており、後から別値が選択された場合
        this.select_teams = this.select_teams.filter(team => team.name !== "全チーム");
      } else if (this.select_teams.length > 1 && this.select_teams[this.select_teams.length - 1].id == 0) {
        // 後から全選択が選択された場合
        this.select_teams = this.select_teams.filter(team => team.name == "全チーム");
      }
    },
  },
}
</script>

