<template>
  <b-button class="btn_shift_reset" :disabled="reset_ng()" @click="reset">
    リセット
    <font-awesome-icon
      icon="rotate-right"
      style="font-weight: 600; color: #6C737F; vertical-align: text-top; padding-right: 14px;"
    />
  </b-button>
</template>

<style scoped>
.alert_reset_message {
  font-size: 14px!important;
  margin-top: 150px!important;
}
.alert_reset_icon {
  position: absolute!important;
  color: #D61515!important;
  border-color: #D61515!important;
  top: 100px!important;
  left: 50%!important;
  transform: translateY(-50%) translateX(-50%)!important;
  -webkit-transform: translateY(-50%) translateX(-50%)!important;
}
.alert_reset_title {
  position: absolute!important;
  color: #D61515!important;
  font-size: 21px!important;
  width: 100%;
  background: #F5F5F5!important;
  padding: 10px 0px!important;
}
.alert_reset_button {
  width: 150px;
  padding: 5px 0px!important
}
.alert_reset_yes_button {
  color: #02B28A;
  margin-right: 10px!important
}
.alert_reset_cloase_button {
  margin: 5px!important
}
</style>

<script>
export default {
  name: 'BtnReset',
  components: {
  },
  props: {
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
    team_name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    reset() {
      this.$swal({
        titleText: 'シフトをリセットしようとしています',
        html: '<span class="alert_reset_team">' + this.team_name + '</span><p class="alert_reset_message">シフトをリセットします。すべてのデータがリセットされ、シフトパターンで指定した勤務区分と職員の希望休（希望勤務）のみが残ります。よろしいですか？</p>',
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          icon: 'alert_reset_icon',
          header: 'alert_reset_header',
          title: 'alert_reset_title',
          closeButton: 'btn_close alert_reset_cloase_button',
          confirmButton: 'btn_green_shadow alert_reset_button alert_reset_yes_button',
          cancelButton: 'btn_gray_shadow alert_reset_button',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateLoading(true);
          this.$inertia.post(
            this.$route('shifts.schedule.reset', {
              year: this.year,
              month: this.month,
              office: this.office.id,
            }),
            {
              team_id: this.team_id,
            }
          );
        }
      });
    },
    reset_ng() {
      if (this.summary.status === 'opened') {
        return true;
      } else {
        return false;
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  watch: {
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
