<script>
import wf from '@/Pages/Shifts/functions/workpatterns';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: 'ShiftHalfWorkChangeDialog',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    // application か schedule か
    modal_type: {
      type: String,
      default: 'application'
    },
    staff_name: {
      type: String,
      defult: null,
      required: false
    },
    full_day: {
      type: String,
      defult: null,
      required: false
    },
  },
  data () {
    return {
      dialog: false,
      workpattern_id: null,
      workpatterns: {},

      // workpattern functions
      wf: wf,
    }
  },
  methods: {
    initialize(workpatterns) {
      // 既存データを一旦削除
      Object.keys(this.workpatterns).forEach(key => {
        this.$delete(this.workpatterns, key)
      })

      // 勤務区分を設定
      for (let id in workpatterns) {
        this.$set(this.workpatterns, id, workpatterns[id]);
      }
    },
    // 時間の加工
    setTimeString(from, to) {
      let fromString = ''
      let toString = ''

      if (from !== null && from !== '') {
        fromString = from.slice(0, 5)
      }
      if (to !== null && to !== '') {
        toString = to.slice(0, 5)
      }

      if (fromString !== '' || toString !== '') {
        return fromString + '-' + toString;
      } else {
        return fromString + toString;
      }
    },
    // modal open
    openDialog(data) {
      if (this.modal_type == 'application') {
        console.log('半勤務変更modal open');
      } else {
        console.log('半休変更modal open');
      }
      console.log(data);

      this.initialize(data.workpatterns);

      // 現在の選択
      this.workpattern_id = data.workpattern_id;

      this.dialog = true;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    // 再選択
    reselect() {
      this.dialog = false;
      this.$emit('reselect');
    },
    // 決定
    submit() {
      if (this.workpattern_id == null) {
        return false;
      }
      this.dialog = false;
      this.$emit('submit', this.workpattern_id);
    },
    // 勤務categoryがworkかholidayか
    categoryWork(workpattern) {
      // workpattern.categoryがholidayの場合にはfalseを返す
      if (workpattern.category === 'holiday') {
        return false;
      }
      // workpattern.categoryがholiday以外の場合にはtrueを返す
      return true;
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" persistent max-width="500">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: center; background: #EFFAF4;">
          <div
            v-if="modal_type === 'application' || modal_type === 'schedule'"
            style="text-align: center;"
          >
            {{ staff_name }}さん<br />
            {{ full_day }}の勤務区分を選択
          </div>
          <div
            v-else
            style="text-align: center;"
          >
            勤務区分を選択
          </div>
        </v-card-title>
        <v-card-text style="padding: 10px;">
          <div>
            <p>
              <span style="font-weight: bold;">勤務区分：{{ modal_type == 'application' ? '半休' : '半勤務' }}</span>が選択されています。</br>
              {{ modal_type == 'application' ? '半勤務' : '半休' }}の勤務区分を選択してください。（必須）
            </p>
            <p style="margin-top: 10px;">
              <font-awesome-icon icon="pen" style="color:red; margin-right: 5px;" />半休
            </p>
            <VuePerfectScrollbar style="max-height: 20vh; margin-top: 10px;">
              <div v-for="(item, index) in workpatterns">
                <label class="workpattern_change_button workpattern_change_data">
                  <input
                    v-model="workpattern_id"
                    type="radio"
                    :key="index"
                    :value="item.id"
                    @change=""
                    name="workpatterns"
                  >
                  <span style="display: flex; justify-content: space-between; width: 100%">
                    <div :class="'workpattern_change_color ' + wf.getWorkpatternColor(item)" style="width: 30px !important">●</div>
                    <div style="width: 100%;">{{ item.name }}</div>
                    <div v-if="categoryWork(item)" style="width: 150px;">
                      {{ setTimeString(item.working_start_time, item.working_end_time) }}
                    </div>
                    <div v-else style="width: 150px;">
                      {{ setTimeString(item.rest_start_time, item.rest_end_time) }}
                    </div>
                  </span>
                </label>
              </div>
            </VuePerfectScrollbar>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
          <div style="display: flex; justify-content: space-between; width: 100%; padding: 0px 10px 10px 10px;">
            <button
              class="btn_gray_shadow"
              style="color: #B4B4B4; width:150px; padding: 5px 0;"
              @click="reselect"
            >勤務区分を選び直す</button>
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              :disabled="workpattern_id == null"
              @click="submit"
            >確定</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
