<template>
  <div>
    <form>
      <WorkSummary
        :work_category_group_label="count_work_category_summary.work_category_group_label"
        :week_cnt="count_work_category_summary.week_cnt"
        :one_team_workpatterns_by_category="count_work_category_summary.workpatterns_by_team_and_category"
        :assignment="count_work_category_summary.assignment"
        :settings_office_staff_workpatterns="settings.office_staff_workpatterns"
        :settings_office_staffs="settings.office_staffs"
        :team_id="team_id"
        :office_wp_list_assignment="office.wp_list_assignment"
        style="margin-bottom: 10px;" />
      <TableColumnFilters
        v-model="table_column_filters"
        :step=1
      />

      <VuePerfectScrollbar id="scrollableDiv" class="table-bulkadd"
        style="max-height: calc(95vh - 470px); min-height: calc(95vh - 470px); padding: 0;">
        <table style="table-layout: fixed;">
          <tr>
            <th class="head01" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>社員番号</div>
                <button type="button" class="sort_btn" @click="set_sort_by(1)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column1_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column1_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th v-if="table_column_filters.has_team || step === 2" class="head02" :style="table_column_left_positions.team" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>チーム</div>
                <button type="button" class="sort_btn" @click="set_sort_by(2)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column2_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column2_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head03" :style="table_column_left_positions.name" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>名前</div>
                <button type="button" class="sort_btn" @click="set_sort_by(3)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column3_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column3_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th v-if="table_column_filters.has_occupation || step === 2" class="head04" :style="table_column_left_positions.occupation" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>職種</div>
                <button type="button" class="sort_btn" @click="set_sort_by(4)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column4_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column4_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th v-if="table_column_filters.has_work_hours" class="head05" rowspan="2" colspan="2" :style="table_column_left_positions.work_hours_min">
              <div class="text-center" style="display: flex; height: 100%; width: 100%; padding: 0px 5px; align-items: center;">
                労働時間
              </div>
            </th>
            <th class="head06" :style="table_column_left_positions.workcount_sum_per_office_staff" rowspan="2">合計</th>

            <th :colspan="workpatterns_data.length">勤務回数</th>
          </tr>
          <tr>
            <th v-for="(workpattern, index) in workpatterns_data" :key="'header-workcount-' + workpattern.id" class="copy_pointer" style="width: 120px; min-width:120px;"  @click="show_col_copy_menu(index, workpattern.id)">
              {{ workpattern.name }}
            </th>
          </tr>
          <!-- 勤務回数の設定-->
          <tr v-for="(office_staff, index) in form.office_staffs" :key="'workcount-' + office_staff.id" :class="class_disp_workcount_item(office_staff.employee_number)" @mouseover="showError($event, office_staff.employee_number)" @mouseout="hideError">
            <td class="copy_pointer fixed01" style="white-space: normal; background: #F3F4F6; padding: 0px 5px;">
              <div class="text-center" style="display: flex; align-items: center; height: 65px; width: 120px;" @click="show_row_copy_menu(index, office_staff.id)">
                {{ office_staff.employee_number }}
              </div>
            </td>
            <td v-if="table_column_filters.has_team" class="fixed02" :style="table_column_left_positions.team" style="white-space: normal;">{{ office_staff.team_names.join(',') }}</td>
            <td class="fixed03" :style="table_column_left_positions.name" style="white-space: normal;">{{ office_staff.staff_lastname }}{{ office_staff.staff_firstname }}</td>
            <td v-if="table_column_filters.has_occupation"  class="fixed04" :style="table_column_left_positions.occupation" style="white-space: normal;">{{ office_staff.occupation_names.join(',') }}</td>
            <td v-if="table_column_filters.has_work_hours" :id="'tab_1_row_select_' + index + '_tab_1_col_select_T0_'" class="fixed05" :style="table_column_left_positions.work_hours_min" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].min" type="number" min="0" class="form-control" @change="worktime_hours_min_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;"><span>時間<br />以上</span></td>
            <td v-if="table_column_filters.has_work_hours" :id="'tab_1_row_select_' + index + '_tab_1_col_select_T1_'" class="fixed06" :style="table_column_left_positions.work_hours_max" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].max" type="number" min="0" class="form-control" @change="worktime_hours_max_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;">時間<br />以下</td>
            <!-- 職員ごとの勤務回数列 -->
            <td class="fixed07 d-flex w-100 justify-content-around align-center" :style="table_column_left_positions.workcount_sum_per_office_staff">
              <div class="h5 mb-0">
                <span v-if="office_staff.rookie">(</span>
                  {{ workcount_sums_per_office_staff[office_staff.id].min }}
                <span v-if="office_staff.rookie">)</span>
              </div>
              <div class="h5 mb-0">
                <span v-if="office_staff.rookie">(</span>
                  {{ workcount_sums_per_office_staff[office_staff.id].max }}
                <span v-if="office_staff.rookie">)</span>
              </div>
            </td>
            <td v-for="workpattern in form.workpatterns" :key="'workcount-' + office_staff.id + '-' + workpattern.id" :id="'tab_1_row_select_' + index + '_tab_1_col_select_' + workpattern.id + '_'">
              <div style="display: flex" :class="active_workcounts[office_staff.id][workpattern.id]">
                <input type="number" v-model.number="settings.office_staff_workpatterns[office_staff.id][workpattern.id].min_monthly_working_count" class="form-control" min="0" max="31" style="width:60px;" :disabled="active_workcounts[office_staff.id][workpattern.id] === 'disabled'" @change="office_staff_workpattern_min_monthly_working_count_changed(office_staff.id, workpattern.id)">
                <input type="number" v-model.number="settings.office_staff_workpatterns[office_staff.id][workpattern.id].max_monthly_working_count" class="form-control" min="0" max="31" style="width:60px;" :disabled="active_workcounts[office_staff.id][workpattern.id] === 'disabled'" @change="office_staff_workpattern_max_monthly_working_count_changed(office_staff.id, workpattern.id)">
              </div>
            </td>
          </tr>
          <!-- 勤務回数の設定:フッター合計行 -->
          <tr>
            <td :colspan="tfoot_header_colspan_num.workcount" class="tfoot01 pr-4" style="white-space: normal; ">
              <div class="d-flex justify-content-end">
                <div>合計</div>
              </div>
            </td>
            <!-- 勤務区分ごとの勤務回数の合計 -->
            <td v-for="{ id } in form.workpatterns" :key="id" class="tfoot02">
              <div class="d-flex w-100 justify-content-around">
                <div class="d-flex justify-content-center flex-column align-center">
                  <p class="h5">{{ workcount_sums_per_workpattern[id].min }}</p>
                  <template v-if="workcount_sums_per_workpattern[id].rookie_min">
                    <p class="h5">(+{{ workcount_sums_per_workpattern[id].rookie_min }})</p>
                  </template>
                </div>
                <div class="d-flex justify-content-center flex-column align-center">
                    <p class="h5">{{ workcount_sums_per_workpattern[id].max }}</p>
                  <template v-if="workcount_sums_per_workpattern[id].rookie_max">
                    <p class="h5">(+{{ workcount_sums_per_workpattern[id].rookie_max }})</p>
                  </template>
                </div>

              </div>
            </td>
          </tr>
        </table>
        <!-- 表下のエラーメッセージ -->
        <div v-if="errors.length > 0" style="margin: 20px 0px;">
          ※ 以下の内容を確認してください。
        </div>
        <div v-for="(error_item, index) in errors" :key="index">
          <AutoCreateError :error_item="error_item" />
        </div>
        <!-- 行コピーメニュー -->
        <ul class="copy_menu" :style="copy_menu_position" v-show="row_copy_menu_show">
          <li @click="row_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この行をコピー
          </li>
          <li :class="row_copy_select ? '' : 'copy_menu_disabled'" @click="row_paste"><font-awesome-icon icon="clone"
              style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
          <li :class="row_copy_select ? '' : 'copy_menu_disabled'" @click="clear_row_copy"><font-awesome-icon
              icon="times" style="color: #1059CC; padding-right: 20px;" disabled />選択を削除</li>
        </ul>
        <!-- 列コピーメニュー -->
        <ul class="copy_menu" :style="copy_menu_position" v-show="col_copy_menu_show">
          <li @click="col_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この列をコピー
          </li>
          <li :class="col_copy_select ? '' : 'copy_menu_disabled'" @click="col_paste"><font-awesome-icon icon="clone"
              style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
          <li :class="col_copy_select ? '' : 'copy_menu_disabled'" @click="clear_col_copy"><font-awesome-icon
              icon="times" style="color: #1059CC; padding-right: 20px;" disabled />選択を削除</li>
        </ul>
        <!-- マウスオーバー時のエラーメッセージ -->
        <div class="error_box" :style="error_box_position" v-show="row_error_show">
          <div v-for="(error_item, index) in error_staff" :key="index">
            <AutoCreateError :error_item="error_item" :bottom_message="true" />
          </div>
        </div>
      </VuePerfectScrollbar>
      <div style="display: flex; justify-content: space-between; margin-top: 20px;">
        <div>
          <b-button class="btn_white btn_autocreatefooter" @click="save_close"
            :disabled="setDisabled()">保存して閉じる</b-button>
        </div>
        <div>
          <b-button class="btn_white btn_autocreatefooter" @click="save_back" :disabled="setDisabled()">戻る</b-button>
          <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_next"
            :disabled="setDisabled()">次へ</b-button>
        </div>
      </div>
    </Form>
    <div v-click-outside="copy_menu_control"></div>
  </div>
</template>

<style scoped>
.btn_autocreatefooter {
  width: 150px!important;
  height: 40px!important;
}
.table-bulkadd {
  width: 100%;
  margin: 0px;
  overflow: auto;
}
.table-bulkadd table {
  margin: 0;
  border-spacing: 0;
  border-collapse: separate!important;
}
.table-bulkadd th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: inherit;
  background: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  z-index: 1;
}
.table-bulkadd tr:nth-child(1) th {
  top: 0px;
}
.table-bulkadd tr:nth-child(2) th {
  top: 35px;
}
.table-bulkadd td {
  position: sticky;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  background: #FFFFFF;
  border: solid 1px rgba(210, 214, 219, 0.5);
  padding: 10px 5px;
}
.table-bulkadd .head01 {
  top: 0px;
  left: 0px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  z-index: 3000;
}
.table-bulkadd .head02 {
  top: 0px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 3000;
}
.table-bulkadd .head03 {
  top: 0px;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  z-index: 3000;
}
.table-bulkadd .head04 {
  top: 0px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  z-index: 3000;
}
.table-bulkadd .head05 {
  top: 0px;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 3000;
}
.table-bulkadd .head06 {
  top: 0px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  z-index: 3000;
}
.table-bulkadd .fixed01 {
  left: 0px;
  min-width: 120px;
  max-width: 120px;
  z-index: 2000;
}
.table-bulkadd .fixed02 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 2000;
}
.table-bulkadd .fixed03 {
  width: 160px;
  min-width: 160px;
  z-index: 2000;
}
.table-bulkadd .fixed04 {
  width: 200px;
  min-width: 200px;
  z-index: 2000;
}
.table-bulkadd .fixed05 {
  width: 140px;
  min-width: 140px;
  z-index: 2000;
}
.table-bulkadd .fixed06 {
  width: 140px;
  min-width:140px;
  z-index: 2000;
}
.table-bulkadd .fixed07 {
  width: 120px;
  min-width:120px;
  z-index: 2000;
}
.table-bulkadd .tfoot01 {
  left: 0px;
  bottom: 0px;
  z-index: 2000;
}
.table-bulkadd .tfoot02 {
  bottom: 0px;
}
.disabled input[type="number"] {
  background-color: #e9ecef;
}
.no_display {
  display: none;
}
.v-select {
  width: 100%;
  min-width: 100%!important;
  max-width: 100%!important;
}
.v-select__selections {
  width: 0px;
}
.select option[selected] {
  background: red;
}
.btn-secondary {
  margin-left: 0.25rem !important;
}
.tab {
  cursor: pointer;
}
.copy_menu {
  position: absolute;
  z-index: 2147483647;
  background: #F8F9F8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  list-style: none;
  line-height: 2;
  padding: 20px 0px;
}
.copy_menu li {
  padding-left: 20px;
  min-width: 200px;
  max-width: 200px;
}
.copy_menu li:hover {
  background: #D4D5D4;
}
.copy_menu_disabled {
    pointer-events: none;
    opacity:0.6;
}
.row_copyselect_first {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_between {
  border-top: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_last {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_last {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_min {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between_min {
  border-left: dashed 2px blue!important;
}
.col_copyselect_last_min {
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_max {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
}
.col_copyselect_between_max {
  border-right: dashed 2px blue!important;
}
.col_copyselect_last_max {
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.copy_pointer {
  cursor: pointer;
}
.error_box {
  position: absolute;
  z-index: 2147483647;
  height: auto;
  width: 600px;
  border: solid 1px #c4c5c7;
  background: #FFFFFF;
  padding: 0px 0px 10px 0px;
}
.error-row td {
  background: #EBF4FF!important;
}
/* table scroll design */
form {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
    &:hover {
        ::-webkit-scrollbar-track {
        background: #eee;
        }
        ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 6px;
        }
    }
}
</style>

<script>
import axios from 'axios'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AutoCreateError from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/AutoCreateError';
import TableColumnFilters from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/BulkAdd/TableColumnFilters';
import WorkSummary from '@/Pages/Workpatterns/Components/WorkSummary.vue';

export default {
  components: {
    VuePerfectScrollbar,
    AutoCreateError,
    TableColumnFilters,
    WorkSummary,
  },
  props: {
    sort_type: {
      type: Number,
      required: true
    },
    workpatterns: {
      type: Array,
      required: true
    },
    office_staffs: {
      type: Array,
      required: true
    },
    staffs: {
      type: Array,
      required: true
    },
    office_staff_workpatterns: {
      type: Array,
      required: true
    },
    default_monthly_working_counts: {
      type: Object,
      required: true
    },
    count_work_category_summary: {
      type: Object,
      required: true
    },
    office: {
      type: Object,
      required: true
    },
    team_id: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load_data();

      // エラー取得
      this.allCheckError();

      // 初期表示フラグ
      this.initial_change = true;
    });

    // ローディング終了
    this.updateLoading(false);
  },
  computed: {
    column1_sort_asc() {
      if (this.sort_type === 1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column1_sort_desc() {
      if (this.sort_type === -1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_asc() {
      if (this.sort_type === 2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_desc() {
      if (this.sort_type === -2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_asc() {
      if (this.sort_type === 3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_desc() {
      if (this.sort_type === -3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_asc() {
      if (this.sort_type === 4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_desc() {
      if (this.sort_type === -4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    table_column_left_positions() {
      // テーブルの左側からの各列の幅を定義
      const default_column_widths_from_left_side = {
        employee_number: 120,
        team: 150,
        name: 160,
        occupation: 200,
        work_hours_min: 140,
        work_hours_max: 140,

        workcount_sum_per_office_staff: 120,
      };
      // テーブルの列の表示フィルターで非表示になった列の幅を0にする
      const { has_team, has_occupation, has_work_hours } = this.table_column_filters;
      const new_column_widths_from_left_side = {
        employee_number: default_column_widths_from_left_side.employee_number,
        team: has_team ? default_column_widths_from_left_side.team : 0,
        name: default_column_widths_from_left_side.name,
        occupation: has_occupation ? default_column_widths_from_left_side.occupation : 0,
        work_hours_min: has_work_hours ? default_column_widths_from_left_side.work_hours_min : 0,
        work_hours_max: has_work_hours ? default_column_widths_from_left_side.work_hours_max : 0,
        workcount_sum_per_office_staff: default_column_widths_from_left_side.workcount_sum_per_office_staff,
      }

      // 左隣の列幅から各列の左位置を計算（ただし、シフトパターンの設定タブは列フィルタがないのでデフォルト幅を使用する）
      const left_positions = {};
      Object.entries(new_column_widths_from_left_side).reduce((acc, [column, width]) => {
        left_positions[column] = { left: acc + 'px' };
        return acc += width;
      }, 0);

      return left_positions;
    },
    // 勤務区分の集計行の見出し列のcolspanの数
    tfoot_header_colspan_num() {
      const { has_team, has_occupation, has_work_hours } = this.table_column_filters;
      const displayed_filterable_cols_cnt = (has_team ? 1 : 0) + (has_occupation ? 1 : 0) + (has_work_hours ? 2 : 0);

      return {
        workpattern : 2 + displayed_filterable_cols_cnt,
        workcount : 3 + displayed_filterable_cols_cnt,
      };
    },
  },
  watch: {
    form : {
      handler () {
        if (this.filters_change) {
          this.filters_change = false;
        } else if (this.initial_change) {
        } else {
          this.$emit('setChange', true);
        }
      },
      deep: true
    },
    settings : {
      handler () {
        if (this.filters_change) {
          this.filters_change = false;
        } else if (this.initial_change) {
          this.initial_change = false;
        } else {
          this.$emit('setChange', true);
        }
      },
      deep: true
    },
    errors: {
      handler: function () {
        // エラー情報を親画面へ送る
        let send_error = this.errors.filter(item => item.fix === false);
        if (send_error.length > 0) {
          this.getErrorMessage();
          this.$emit('setchildError', true, this.error_message);
        } else {
          this.$emit('setchildError', false);
        }
      }
    },
    sort_type: {
      handler: function () {
        this.load_data();
      }
    },
    'table_column_filters.has_limited_work_types': {
      handler: function () {
        // フラグの変更は変更として扱わない
        this.filters_change = true;
        this.apply_table_columns_filters();
      },
    },
    'table_column_filters.has_only_enabled_workpatterns': {
      handler: function () {
        // フラグの変更は変更として扱わない
        this.filters_change = true;
        this.apply_table_columns_filters();
      },
    },
  },
  data() {
    return {
      table_column_filters: {
        has_team: true,
        has_occupation: true,
        has_work_hours: true,
        has_limited_work_types: false,
        has_only_enabled_workpatterns: true,
      },
      workpatterns_data: this.workpatterns,
      active_workcounts: {},
      office_staff_workpattern_items: {},
      office_staff_to_staff: {},
      public_holiday_workpatterns: {},
      public_half_holiday_workpatterns: {},
      unchangable_workpatterns: {},
      // 「勤務回数」が固定値の勤務種別
      unchangable_workpattern_types: [
        'paid_holidays',
        'paid_half_holiday',
        'other_holiday',
        'half_work',
      ],
      defaultMonthlyWorkingCounts: this.default_monthly_working_counts,
      form: {
        office_staffs: [],
        workpatterns: [],
        worktime_hours: [],
        staffs: [],
      },
      initial_values: {
        office_staff_workpattern_flags: {},
        office_staff_workpatterns: {},
      },
      settings: {
        office_staffs: {},
        office_staff_workpattern_flags: {},
        office_staff_workpatterns: {},
        office_staff_workpatterns_changed: {},
      },
      // 行列コピー
      copy_menu_position: {
        left: 0,
        top: 0
      },
      row_menu_show: false,
      row_copy_menu_show: false,
      row_copied_index: -1,
      row_copied_id: -1,
      row_copy_select: false,
      col_menu_show: false,
      col_copy_menu_show: false,
      col_copied_id: -1,
      col_copy_select: false,
      // エラーメッセージ
      errors: [],
      error_staff: [],
      row_error_show: false,
      error_box_position: {
        left: 0,
        top: 0
      },
      error_message: '',
      sort_type_data: this.sort_type,
      disabled_office_staff_workpattern_id_set: new Set(),
      enabled_workpattern_cnts_per_workpattern: {},
      workcount_sums_per_workpattern: {},
      workcount_sums_per_office_staff: {},
      filters_change: false,
      initial_change: false,
    };
  },
  methods: {
    apply_table_columns_filters() {
      const work_type_targets = ['work', 'public_holidays'];
      const workpattern_id_targets = this.office_staff_workpatterns
        .filter(({ office_staff_id }) => this.office_staffs.some(({ id }) => id === office_staff_id))
        .map(({ workpattern_id }) => workpattern_id);

      // 全てのworkpatternsを代入して初期化
      this.workpatterns_data = this.workpatterns;
      this.form.workpatterns = this.$set(this.form, 'workpatterns', this.workpatterns);

      // 「勤務種別：勤務と公休(全休)のみを表示」のチェックがされている場合
      if (this.table_column_filters.has_limited_work_types) {
        this.workpatterns_data = this.workpatterns_data.filter(({ type }) => work_type_targets.includes(type));
        this.form.workpatterns = this.form.workpatterns.filter(({ type }) => work_type_targets.includes(type));
      }

      // 「勤務回数の設定」タブが選択されている && 「〇の勤務区分のみ表示」のチェックがされている場合
      if (this.table_column_filters.has_only_enabled_workpatterns) {
        this.workpatterns_data = this.workpatterns_data.filter(({ id }) => workpattern_id_targets.includes(id));
        this.form.workpatterns = this.form.workpatterns.filter(({ id }) => workpattern_id_targets.includes(id));
      }
    },
    calc_work_hours_cnts() {
      // 0で初期化
      this.workpatterns.forEach(({ id }) => {
        this.workcount_sums_per_workpattern[id] = { 'min': 0, 'max': 0 , 'rookie_min': 0, 'rookie_max': 0, 'category':''}
      });

      this.office_staffs.forEach(({ id }) => {
        this.workcount_sums_per_office_staff[id] = { 'min': 0, 'max': 0 }
      });

      //ワークパターンからデータを取得しやすくするmap生成
      const workpattern_map = Object.fromEntries(
        Object.values(this.workpatterns).map(workpattern => [workpattern.id, workpattern])
      );

      // office_staff_workpatternsを数え上げ
      Object.entries(this.settings.office_staff_workpatterns).forEach(([office_staff_id, workpatterns]) => {
        const is_rookie = this.office_staffs.find(({ id }) => id === Number(office_staff_id))?.rookie;
        if (is_rookie === undefined) return;
        Object.entries(workpatterns).forEach(([workpattern_id, { min_monthly_working_count, max_monthly_working_count }]) => {
          if (this.workcount_sums_per_workpattern[workpattern_id]) {

            let workpattern_category = workpattern_map[parseInt(workpattern_id)]?.category
            this.workcount_sums_per_workpattern[workpattern_id].category = workpattern_category

            if (is_rookie) {
              this.workcount_sums_per_workpattern[workpattern_id].rookie_min += Number(min_monthly_working_count);
              this.workcount_sums_per_workpattern[workpattern_id].rookie_max += Number(max_monthly_working_count);
            } else {
              this.workcount_sums_per_workpattern[workpattern_id].min += Number(min_monthly_working_count);
              this.workcount_sums_per_workpattern[workpattern_id].max += Number(max_monthly_working_count);
            }

            if (this.workcount_sums_per_office_staff[office_staff_id]) {
              if (this.active_workcounts[office_staff_id][workpattern_id] !== 'disabled') {
                this.workcount_sums_per_office_staff[office_staff_id].min += Number(min_monthly_working_count);
                this.workcount_sums_per_office_staff[office_staff_id].max += Number(max_monthly_working_count);
              }
            }
          }
        });
      });
    },
    load_data() {
      // office_staff_workpatterns を office_staff_workpattern_flags と settings_office_staff_workpatterns の初期値に反映
      let office_staff_workpattern_flags = {};
      let settings_office_staff_workpatterns = {};
      this.office_staff_workpatterns.forEach(office_staff_workpattern => {
        const {
          office_staff_id,
          workpattern_id,
          min_monthly_working_count,
          max_monthly_working_count
        } = office_staff_workpattern;

        // IDごとの設定が未定義の場合は初期化
        if (!office_staff_workpattern_flags[office_staff_id]) {
          office_staff_workpattern_flags[office_staff_id] = {};
          settings_office_staff_workpatterns[office_staff_id] = {};
        }
        office_staff_workpattern_flags[office_staff_id][workpattern_id] = true;
        settings_office_staff_workpatterns[office_staff_id][workpattern_id] = {
          min_monthly_working_count,
          max_monthly_working_count,
        };
      });

      this.public_holiday_workpatterns = {};
      this.unchangable_workpatterns = {};
      this.workpatterns.forEach(workpattern => {
        const { id, type } = workpattern;

        if (type === 'public_holidays') {
          this.public_holiday_workpatterns[id] = workpattern;
        }

        if (type === 'public_half_holiday') {
          this.public_half_holiday_workpatterns[id] = workpattern;
        }

        if (this.unchangable_workpattern_types.includes(type)) {
          this.unchangable_workpatterns[id] = workpattern;
        }
      });

      this.active_workcounts = {};
      let settings_office_staffs = {};
      let form_worktime_hours = {};
      this.office_staffs.forEach(office_staff => {
        if (typeof office_staff_workpattern_flags[office_staff.id] === 'undefined') {
          office_staff_workpattern_flags[office_staff.id] = {};
        }

        this.active_workcounts[office_staff.id] = {};

        if (typeof settings_office_staff_workpatterns[office_staff.id] === 'undefined') {
          settings_office_staff_workpatterns[office_staff.id] = {};
        }

        this.workpatterns.forEach(workpattern => {
          if (typeof office_staff_workpattern_flags[office_staff.id][workpattern.id] === 'undefined') {
            office_staff_workpattern_flags[office_staff.id][workpattern.id] = false;
            this.active_workcounts[office_staff.id][workpattern.id] = 'disabled';
            settings_office_staff_workpatterns[office_staff.id][workpattern.id] = {
              min_monthly_working_count: 0,
              max_monthly_working_count: 0,
            };
          } else if (this.check_unchangable_workpattern(workpattern.id)) {
            this.active_workcounts[office_staff.id][workpattern.id] = 'disabled';

            const defaultMonthlyWorkingCounts =  this.defaultMonthlyWorkingCounts[office_staff.employment_type][workpattern.type][workpattern.category];

            settings_office_staff_workpatterns[office_staff.id][workpattern.id] = {
              min_monthly_working_count: defaultMonthlyWorkingCounts.min,
              max_monthly_working_count: defaultMonthlyWorkingCounts.max,
            };
          } else if (this.check_public_half_holiday_workpattern(workpattern.id)) {
            this.active_workcounts[office_staff.id][workpattern.id] = 'disabled';
          } else {
            this.active_workcounts[office_staff.id][workpattern.id] = office_staff_workpattern_flags[office_staff.id][workpattern.id] ? '' : 'disabled';
          }
          if(this.active_workcounts[office_staff.id][workpattern.id] == ''){
            settings_office_staff_workpatterns[office_staff.id][workpattern.id].category = workpattern.category;
          }
        });

        settings_office_staffs[office_staff.id] = {
          min_worktime_minutes: office_staff.min_worktime_minutes,
          max_worktime_minutes: office_staff.max_worktime_minutes
        };
        form_worktime_hours[office_staff.id] = {
          min: office_staff.min_worktime_minutes / 60,
          max: office_staff.max_worktime_minutes / 60
        };
      });

      this.form.worktime_hours = form_worktime_hours;
      this.settings.office_staffs = settings_office_staffs;
      this.settings.office_staff_workpattern_flags = office_staff_workpattern_flags;
      this.initial_values.office_staff_workpattern_flags = structuredClone(this.settings.office_staff_workpattern_flags);
      this.settings.office_staff_workpatterns = settings_office_staff_workpatterns;
      this.initial_values.office_staff_workpatterns = structuredClone(this.settings.office_staff_workpatterns);

      this.office_staff_to_staff = {};
      this.staffs.forEach(staff => {
        this.office_staff_to_staff[staff.office_staff_id] = staff;
      });

      this.calc_work_hours_cnts();
      this.form.office_staffs = this.office_staffs;
      this.form.workpatterns = this.workpatterns;
      this.form.staffs = this.staffs;

      this.apply_table_columns_filters();
    },
    // 勤務回数の設定のエラー行表示
    class_disp_workcount_item(employee_number) {
      let is_error = this.errors.filter(item => item.step === 5 && item.employee_number === employee_number && item.fix === false);
      if (is_error.length > 0) {
        return 'error-row';
      } else {
        return '';
      }
    },
    // 各ボタン制御
    setDisabled() {
      let is_error = this.errors.filter(item => item.step === (this.step + 4) && item.fix === false);
      if (is_error.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    update_disabled_office_staff_work_patterns_ids(office_staff_id, workpattern_id) {
      const get_office_staff_workpattern = (office_staff_id, workpattern_id) => {
        return this.office_staff_workpatterns.find(
          office_staff_workpattern =>
            office_staff_workpattern.office_staff_id === office_staff_id && office_staff_workpattern.workpattern_id === workpattern_id
        );
      };
      const office_staff_workpattern = get_office_staff_workpattern(office_staff_id, workpattern_id)
      if (office_staff_workpattern === undefined) return;

      const is_disabled = !this.settings.office_staff_workpattern_flags[office_staff_id][workpattern_id];
      if (is_disabled) {
        this.disabled_office_staff_workpattern_id_set.add(office_staff_workpattern.id)
      } else {
        this.disabled_office_staff_workpattern_id_set.delete(office_staff_workpattern.id);
      }
    },
    // 勤務区分の設定（勤務区分のON/OFFチェック）
    office_staff_workpattern_flag_changed(office_staff_id, workpattern_id) {
      // 入力チェック
      this.allCheckError();

      if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern_id]) {
        // 「公休(全休)」の複数割り当てチェック
        let staff = this.office_staff_to_staff[office_staff_id];
        let has_public_holidays_duplicated = false;
        if (this.check_public_holiday_workpattern(workpattern_id)) {
          // 勤務種別「公休(全休)」の勤務区分が割り当てられた--->画面上ですでに「公休(全休)」が割り当てられているか確認
          let public_holidays_duplicated = [];
          this.workpatterns.forEach(workpattern => {
            if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern.id]
              && this.check_public_holiday_workpattern(workpattern.id)
              && workpattern_id !== workpattern.id) {
                public_holidays_duplicated.push(workpattern);
            }
          });
          has_public_holidays_duplicated = public_holidays_duplicated.length > 0;
          let message = '';
          if (has_public_holidays_duplicated) {
            // 割り当て済みなのでエラー
            message += '<p style="text-align: left">勤務種別：公休（全休）の勤務区分は1人の職員に対し1つまでしか割り当てできません。<br>この職員 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname) + ' にはすでに以下の勤務区分が割り当てられています：</p>';
            let duplicate_workpattern_names = [];
            public_holidays_duplicated.forEach(workpattern => {
              duplicate_workpattern_names.push(workpattern.name);
            })
            message += '<p>勤務区分：' + this.htmlspecialchars(duplicate_workpattern_names.join('、')) + '</p>';
          }
          if (message !== '') {
            this.$swal({
              icon: 'error',
              html: message,
              confirmButtonText: '閉じる',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            .then(result => {
              this.settings.office_staff_workpattern_flags[office_staff_id][workpattern_id] = false;
            })
          }
        }

        if (!has_public_holidays_duplicated) {
          // 勤務回数を有効化
          if (this.check_unchangable_workpattern(workpattern_id)) {
            this.active_workcounts[office_staff_id][workpattern_id] = 'disabled';
          } else {
            this.active_workcounts[office_staff_id][workpattern_id] = '';
          }
          // 該当ユーザ・勤務区分があれば、労働時間を転記
          const office_staff = this.office_staffs.find(({ id }) => id === office_staff_id);
          const workpattern = this.workpatterns.find(({ id }) => id === workpattern_id);
          if (this.defaultMonthlyWorkingCounts.hasOwnProperty(office_staff.employment_type) &&
              this.defaultMonthlyWorkingCounts[office_staff.employment_type].hasOwnProperty(workpattern.type) &&
              this.defaultMonthlyWorkingCounts[office_staff.employment_type][workpattern.type].hasOwnProperty(workpattern.category)) {
            const defaultMonthlyWorkingCounts = this.defaultMonthlyWorkingCounts[office_staff.employment_type][workpattern.type][workpattern.category];
            this.settings.office_staff_workpatterns[office_staff_id][workpattern_id] = {
              min_monthly_working_count: defaultMonthlyWorkingCounts.min,
              max_monthly_working_count: defaultMonthlyWorkingCounts.max
            };
          }
        }
      } else {
        // 勤務回数を無効化
        this.active_workcounts[office_staff_id][workpattern_id] = 'disabled';
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id] = {
          min_monthly_working_count: 0,
          max_monthly_working_count: 0
        };
      }
      this.update_disabled_office_staff_work_patterns_ids(office_staff_id, workpattern_id);
    },
    // 労働時間（下限）の変更時
    worktime_hours_min_changed(office_staff_id) {
      let message = '';
      let type = 0;

      // エラーチェック
      let staff = this.office_staff_to_staff[office_staff_id];
      if (parseInt(this.form.worktime_hours[office_staff_id].min) < 0) {
        message = '労働時間は0以上の値を指定してください。';
        type = 3;
      } else if (parseInt(this.form.worktime_hours[office_staff_id].min) > parseInt(this.form.worktime_hours[office_staff_id].max)) {
        message = '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう修正してください。';
        type = 5;
      }

      // エラーメッセージ出力
      if (message !== '') {
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, type, message.replace(/<br>/g, ""), false);

        this.$swal({
          icon: 'error',
          html: message + '<br>'
              + '職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname),
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else {
        // 職員情報を取得してエラー情報設定
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 3, message.replace(/<br>/g, ""), true);
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 5, message.replace(/<br>/g, ""), true);

        this.settings.office_staffs[office_staff_id].min_worktime_minutes = this.form.worktime_hours[office_staff_id].min * 60;
      }
    },
    // 労働時間（上限）の変更時
    worktime_hours_max_changed(office_staff_id) {
      let message = '';
      let type = 0;

      // エラーチェック
      let staff = this.office_staff_to_staff[office_staff_id];
      if (parseInt(this.form.worktime_hours[office_staff_id].max) < 0) {
        message = '労働時間は0以上の値を指定してください。';
        type = 4;
      } else if (parseInt(this.form.worktime_hours[office_staff_id].min) > parseInt(this.form.worktime_hours[office_staff_id].max)) {
        message = '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう修正してください。';
        type = 5;
      }

      // エラーメッセージ出力
      if (message !== '') {
        // 職員情報を取得してエラー設定
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, type, message.replace(/<br>/g, ""), false);

        this.$swal({
          icon: 'error',
          html: message + '<br>'
              + '職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname),
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        // 職員情報を取得してエラー設定
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 4, message.replace(/<br>/g, ""), true);
        this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 5, message.replace(/<br>/g, ""), true);

        this.settings.office_staffs[office_staff_id].max_worktime_minutes = this.form.worktime_hours[office_staff_id].max * 60;
      }
    },
    // 勤務回数（下限）変更時
    office_staff_workpattern_min_monthly_working_count_changed(office_staff_id, workpattern_id) {
      const min_monthly_working_count = parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count);
      if (min_monthly_working_count < 0) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = 0;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、0以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (min_monthly_working_count > 31) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = 31;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、31以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (min_monthly_working_count > parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count)) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、最大値以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.calc_work_hours_cnts();
    },
    // 勤務回数（上限）変更時
    office_staff_workpattern_max_monthly_working_count_changed(office_staff_id, workpattern_id) {
      const max_monthly_working_count = parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count);
      if (max_monthly_working_count < 0) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = 0;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、0以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (max_monthly_working_count > 31) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = 31;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、31以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (max_monthly_working_count < parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count)) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、最小値以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.calc_work_hours_cnts();
    },
    clear_office_staff_workpatterns_changed() {
      this.settings.office_staff_workpatterns_changed = {};
    },
    set_office_staff_workpatterns_changed(office_staff_id, workpattern_id) {
      if (typeof this.settings.office_staff_workpatterns_changed[office_staff_id] === 'undefined') {
        this.settings.office_staff_workpatterns_changed[office_staff_id] = {};
      }
      this.settings.office_staff_workpatterns_changed[office_staff_id][workpattern_id]
        = structuredClone(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id]);
    },
    // 勤務回数の変更箇所を office_staff_workpatterns_changed に設定し、事業所またぎ兼務している職員の勤務区分または勤務回数を変更しているかどうかを返却
    pickupWorkpatternChanges() {
      let workpatternConcurrentChangesList = [];
      this.settings.office_staff_workpatterns_changed = {};
      this.staffs.forEach(staff => {
        this.form.workpatterns.forEach(workpattern => {
          if (
            this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count
              !== this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count
            || this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count
              !== this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count
          ) {
            // 勤務回数の最小値もしくは最大値が変更されている
            this.set_office_staff_workpatterns_changed(staff.office_staff_id, workpattern.id);
            if (staff.office_concurrent_flag) {
              workpatternConcurrentChangesList.push(staff.lastname + '　' + staff.firstname + '：' + workpattern.name + '（変更）');
            }
          }
        });
      });

      return workpatternConcurrentChangesList;
    },
    // 公休(全休) の勤務区分か判定
    check_public_holiday_workpattern(workpattern_id) {
      return Object.keys(this.public_holiday_workpatterns).includes(String(workpattern_id));
    },
    check_unchangable_workpattern(workpattern_id) {
      return Object.keys(this.unchangable_workpatterns).includes(String(workpattern_id));
    },
    check_public_half_holiday_workpattern(workpattern_id) {
      return Object.keys(this.public_half_holiday_workpatterns).includes(String(workpattern_id));
    },
    htmlspecialchars(string) {
      if (typeof string !== 'string') {
        return string;
      };
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          };
          return escaped[match];
        });
    },
    set_sort_by(sort_by) {
      if (Math.abs(this.sort_type) === sort_by) {
        this.sort_type_data = -this.sort_type;
      } else {
        this.sort_type_data = sort_by;
      }
      this.$emit('getBulkAddData', this.sort_type_data);
    },
    // 保存して終了
    save_close() {
      // 保存処理
      this.save(true, true)
    },
    // 戻る
    save_back() {
      // 保存処理
      this.save(false, false, 5)
    },
    // 次へ
    save_next() {
      // 保存処理
      this.save(false, false, 7)
    },
    // errorsに配列設定
    setCheckError(employee_number, staff_lastname, staff_firstname, error_type, error_message, fix) {
      // 該当エラーの存在チェック
      let errorIndex = this.errors.findIndex(error => {
        return error.employee_number === employee_number && error.error_type === error_type;
      });

      // 指定された条件に合致するエラー情報が見つかった場合、fixプロパティを再設定
      if (errorIndex !== -1) {
        this.errors[errorIndex].fix = fix;
      // 指定された条件に合致するエラー情報が見つからなかった場合、新しいエラー情報を作成してerrorsに追加
      } else {
        if (!fix) {
          this.errors.push({
            employee_number: employee_number,
            staff_lastname: staff_lastname,
            staff_firstname: staff_firstname,
            error_type: error_type,
            error_message: error_message,
            fix: fix
          });
        }
      }
    },
    allCheckError() {
      // ワークパターンが1つも選択されていない
      this.form.office_staffs.forEach(office_staff => {
        let has_workpatterns = false;
        let staff = this.office_staff_to_staff[office_staff.id];
        Object.values(this.settings.office_staff_workpattern_flags[office_staff.id]).forEach(office_staff_workpattern_flag => {
          if (office_staff_workpattern_flag) {
            has_workpatterns = true;
          }
        });
        if (!has_workpatterns) {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 1, '勤務区分が1つも割り当てられていません。', false);
        } else {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 1, '勤務区分が1つも割り当てられていません。', true);
        }

        if (parseInt(this.form.worktime_hours[office_staff.id].min) < 0) {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 3, '労働時間は0以上の値を指定してください。', false);
        } else if (parseInt(this.form.worktime_hours[office_staff.id].min) > parseInt(this.form.worktime_hours[office_staff.id].max)) {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 5, '労働時間の下限が上限を上回っています。下限が上限を上回らないよう修正してください。', false);
        } else {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 3, '労働時間は0以上の値を指定してください。', true);
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 5, '労働時間の下限が上限を上回っています。下限が上限を上回らないよう修正してください。', true);
        }

        // 公休(全休) は複数指定不可
        let public_holiday_workpatterns_enabled = [];
        this.form.workpatterns.forEach(workpattern => {
          if (this.settings.office_staff_workpattern_flags[office_staff.id][workpattern.id]) {
            if (this.check_public_holiday_workpattern(workpattern.id)) {
              // 公休(全休) の割り当て
              public_holiday_workpatterns_enabled.push(workpattern.name);
            }
          }
        });
        if (public_holiday_workpatterns_enabled.length > 1) {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 2, '勤務種別：公休（全休）の勤務区分が複数割り当てられています。勤務種別：公休（全休）の勤務区分は1人の職員に対し1つしか割り当てできません。勤務区分名：' + public_holiday_workpatterns_enabled.join('、'), false);
        } else {
          this.setCheckError(staff.employee_number, staff.lastname, staff.firstname, 2, '勤務種別：公休（全休）の勤務区分が複数割り当てられています。勤務種別：公休（全休）の勤務区分は1人の職員に対し1つしか割り当てできません。勤務区分名：' + public_holiday_workpatterns_enabled.join('、'), true);
        }
      });
    },
    // 出力するエラーメッセージ生成
    getErrorMessage() {
      let staff_info = '';
      this.error_message = '';

      // 勤務区分の設定 or 勤務回数の設定時のエラーチェック
      // 労働時間が0以下の場合エラー
      let errors_min = this.errors.filter(item => item.error_type === 3 && item.fix === false);
      errors_min.forEach(error => {
        staff_info += '職員名:' + error.staff_lastname + error.staff_firstname + '<br>';
      });
      if (staff_info !== '') {
        this.error_message = '労働時間は0以上の値を指定してください。<br>' + staff_info;
        return;
      }

      // 労働時間が上限＞下限の場合エラー
      let errors_max = this.errors.filter(item => item.error_type === 5 && item.fix === false);
      errors_max.forEach(error => {
        staff_info += '職員名:' + error.staff_lastname + error.staff_firstname + '<br>';
      });
      if (staff_info !== '') {
        this.error_message = '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう以下の職員を修正してください。<br>' + staff_info;
        return;
      }
    },
    // 保存処理
    save(message_flg = true, close_flg = true, nextstep = -1) {
      // 勤務区分の兼務先反映確認ダイアログ
      const workpattern_dialog = {
        icon: 'info',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '反映する',
        denyButtonText: '反映しない',
        cancelButtonText: '閉じる',
        customClass: {
          confirmButton: 'btn btn-primary',
          denyButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      };

      // シフトパターンの兼務先反映確認ダイアログ
      const shift_pattern_dialog = {
        icon: 'info',
        title: '兼務先にもシフトパターンを反映しますか？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '反映する',
        denyButtonText: '反映しない',
        cancelButtonText: '閉じる',
        customClass: {
          confirmButton: 'btn btn-primary',
          denyButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      };

      // 登録確認ダイアログ
      const confirm_dialog = {
        icon: 'info',
        title: '設定内容を登録しますか？',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      };

      let concurrently_workpatterns = false;
      const workpattern_concurrent_changes_list = this.pickupWorkpatternChanges();
      if (workpattern_concurrent_changes_list.length > 0) {
        // 勤務区分の確認ダイアログ表示
        let message = '<p style="text-align: left; margin: 0 auto 2ex;">以下の勤務区分について、兼務先にも同様の勤務区分の割り当てを反映しますか？<ul style="text-align: left; margin: 0 2ex 2ex; list-style: none;">';
        workpattern_concurrent_changes_list.forEach(entry => {
          message += '<li>' + this.htmlspecialchars(entry) + '</li>';
        });
        message += '</ul></p>';
        workpattern_dialog.html = message;
        this.$swal(workpattern_dialog)
          .then(result => {
            if (!result.isConfirmed && !result.isDenied) return;
            concurrently_workpatterns = result.isConfirmed;
            this.doBulkAdd(concurrently_workpatterns, message_flg, close_flg, nextstep);
          });
      } else {
        // 登録確認ダイアログ表示
        this.$swal(confirm_dialog)
          .then(result => {
            if (!result.isConfirmed && !result.isDenied) return;
            this.doBulkAdd(concurrently_workpatterns, message_flg, close_flg, nextstep);
          });
      }
    },
    // 一括登録の実行
    doBulkAdd(concurrently_workpatterns, message_flg, close_flg, nextstep) {
      let errors = {};
      this.updateLoading(true);
      axios.post(this.$route('staffs.workcons.do-bulk-add-work-count'), {
        office_staffs: this.settings.office_staffs,
        office_staff_workpatterns: this.settings.office_staff_workpatterns_changed,
        concurrently_workpatterns: concurrently_workpatterns,
        disabled_office_staff_workpattern_ids: Array.from(this.disabled_office_staff_workpattern_id_set),
      }).then(result => {
        this.updateLoading(false);
        if (result.status === 200) {
          if (result.data.status === 400) {
            errors = result.data.errors;
            let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
            this.$swal({
              icon: 'error',
              html: '入力値が正しくありません。' + error
            });
          } else if (result.data.status !== 200) {
            errors = result.data.errors;
            throw result.status;
          } else {
            // 保存が成功したら変更フラグクリア
            this.$emit('setChange', false);
            this.$emit('getBulkAddData');
            if (message_flg) {
              this.$swal({
                icon: 'success',
                title: '保存しました'
              }).then(result => {
                if (close_flg) {
                  this.$emit('closeDialog');
                }
              });
            } else {
              if (nextstep > -1) {
                this.$emit('nextStep', nextstep);
              }else if (close_flg) {
                this.$emit('closeDialog');
              }
            }
          }
        } else {
          throw result.status;
        }
      }).catch(e => {
        this.updateLoading(false);
        let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
        this.$swal({
          icon: 'error',
          html: '勤務条件一括登録に失敗しました。しばらくたってから再試行してください。' + error
        }).then(result => {
          location.reload()
        })
      });
    },
    reset() {
      this.load_data();
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },

    // 行コピーのコンテンツメニュー表示
    show_row_copy_menu(index, id) {
      this.row_menu_index = index;
      this.row_menu_id = id;
      this.row_menu_show = true;
    },
    // 「この行をコピー」実行
    row_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;

      // 行選択フラグ=ON
      this.row_copy_select = true;

      // 対象設定
      this.row_copied_index = this.row_menu_index;
      this.row_copied_id = this.row_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id^="tab_${this.step}_row_select_${this.row_copied_index}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i === 0) {
            cellOther[i].classList.add('row_copyselect_first');
          } else if (i === (cellOther.length - 1)) {
            cellOther[i].classList.add('row_copyselect_last');
          } else {
            cellOther[i].classList.add('row_copyselect_between');
          }
        }
      }
    },
    // 行の「貼り付け」実行
    row_paste() {
      if (this.row_menu_id !== this.row_copied_id) {
        // 労働時間
        this.form.worktime_hours[this.row_menu_id].min
          = this.form.worktime_hours[this.row_copied_id].min
        this.form.worktime_hours[this.row_menu_id].max
          = this.form.worktime_hours[this.row_copied_id].max

        this.form.workpatterns.forEach(workpattern => {
          // コピー元データが 0, 0 なら対応する勤務区分を無効に、そうでなければ有効にする。
          if (this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].min_monthly_working_count === 0
            && this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].max_monthly_working_count === 0) {
            if (this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]) {
              this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id] = false;
              this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern.id);
            }
          } else {
            if (!this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]) {
              this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id] = true;
              this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern.id);
            }
          }

          this.settings.office_staff_workpatterns[this.row_menu_id][workpattern.id].min_monthly_working_count
            = this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].min_monthly_working_count;
          this.settings.office_staff_workpatterns[this.row_menu_id][workpattern.id].max_monthly_working_count
            = this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].max_monthly_working_count;
        });
      }
      // 選択の解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 選択行の「選択を削除」実行
    clear_row_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 行選択フラグ= OFF
      this.row_copy_select = false;

      //コピー行削除
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 列コピーのコンテンツメニュー表示
    show_col_copy_menu(index, id) {
      this.col_menu_index = index;
      this.col_menu_id = id;
      this.col_menu_show = true;
    },
    // 「この列をコピー」実行
    col_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;

      // 列選択フラグ=ON
      this.col_copy_select = true;

      // 対象設定
      this.col_copied_index = this.col_menu_index;
      this.col_copied_id = this.col_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id*="tab_${this.step}_col_select_${this.col_copied_id}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i === 0) {
            cellOther[i].classList.add('col_copyselect_first');
          } else if(i === (cellOther.length - 1)) {
            cellOther[i].classList.add('col_copyselect_last');
          } else {
            cellOther[i].classList.add('col_copyselect_between');
          }
        }
      }
    },
    // 列の「貼り付け」実行
    col_paste() {
      if (this.col_menu_id !== this.col_copied_id) {
        this.form.office_staffs.forEach(office_staff => {
          // コピー元データが 0, 0 なら対応する勤務区分を無効に、そうでなければ有効にする。
          if (this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].min_monthly_working_count === 0
            && this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].max_monthly_working_count === 0) {
            if (this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]) {
              this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id] = false;
              this.office_staff_workpattern_flag_changed(office_staff.id, this.col_menu_id);
            }
          } else {
            if (!this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]) {
              this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id] = true;
              this.office_staff_workpattern_flag_changed(office_staff.id, this.col_menu_id);
            }
          }

          this.settings.office_staff_workpatterns[office_staff.id][this.col_menu_id].min_monthly_working_count
            = this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].min_monthly_working_count;
          this.settings.office_staff_workpatterns[office_staff.id][this.col_menu_id].max_monthly_working_count
            = this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].max_monthly_working_count;
        });
      }
      // 選択の解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択列の「選択を削除」実行
    clear_col_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 列選択フラグ= OFF
      this.col_copy_select = false;

      //コピー列削除
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択枠線を全て削除
    copy_remove_class() {
      let delClass = ['row_copyselect_first', 'row_copyselect_between', 'row_copyselect_last' , 'col_copyselect_first', 'col_copyselect_between', 'col_copyselect_last', 'col_copyselect_first_min', 'col_copyselect_between_min', 'col_copyselect_last_min', 'col_copyselect_first_max', 'col_copyselect_between_max', 'col_copyselect_last_max']
      for (let name_index in delClass) {
        let cellClass = document.querySelectorAll(`.${delClass[name_index]}`);
        for (let i in cellClass) {
          if (cellClass.hasOwnProperty(i)) {
            cellClass[i].classList.remove(delClass[name_index]);
          }
        }
      }
    },
    copy_menu_control: function (e) {
      // 表示サイズ取得
      let screen_width = window.innerWidth;

      // Divの横スクロール位置を取得
      let scrollableDiv = document.getElementById('scrollableDiv');
      let scrollDistanceX = 0;
      if (scrollableDiv) {
        scrollDistanceX = scrollableDiv.scrollLeft;
      }

      // コピーメニューの横位置取得
      let width_pos = e.pageX;
      if (screen_width < width_pos + 300) {
        width_pos = width_pos - 250 + scrollDistanceX;
      } else {
        width_pos = width_pos - 20 + scrollDistanceX;
      }

      // Divの縦スクロール位置を取得
      let scrollDistanceY = 0;
      if (scrollableDiv) {
        scrollDistanceY = scrollableDiv.scrollTop;
      }

      // コピーメニューの縦位置取得
      let height_pos = e.pageY - 230 + scrollDistanceY;

      // メニューの表示位置設定
      this.copy_menu_position = {
        top: `${height_pos}px`,
        left: `${width_pos}px`
      };

      // メニュー表示
      if (this.row_menu_show) {
        // 行コピー
        this.row_menu_show = false;
        this.row_copy_menu_show = true;
        this.col_copied_index = -1;
        this.col_copied_id = -1;
      } else if (this.col_menu_show) {
        // 列コピー
        this.col_menu_show = false;
        this.col_copy_menu_show = true;
        this.row_copied_index = -1;
        this.row_copied_id = -1;
      } else {
        this.row_copy_menu_show = false;
        this.col_copy_menu_show = false;
      }
    },
    scroll_click() {
      this.row_copy_menu_show = false;
      this.col_copy_menu_show = false;
    },
    /* 行のマウスオーバー時のエラー取得とボックス表示 */
    showError(e, staff_id) {
      // マウスカーソルが社員番号の場合は表示しない
      if (e.clientX < 160) {
        return;
      }

      // 表示サイズ取得
      let screen_width = window.innerWidth;

      // Divの横スクロール位置を取得
      let scrollableDiv = document.getElementById('scrollableDiv');
      let scrollDistanceX = 0;
      if (scrollableDiv) {
        scrollDistanceX = scrollableDiv.scrollLeft;
      }

      // エラーボックスの横位置決定
      let width_pos = e.pageX;
      if (screen_width < width_pos + 600) {
        width_pos = width_pos - 350 + scrollDistanceX;
      } else {
        width_pos = width_pos -20 + scrollDistanceX;
      }

      // Divの縦スクロール位置を取得
      let scrollDistanceY = 0;
      if (scrollableDiv) {
        scrollDistanceY = scrollableDiv.scrollTop;
      }

      // エラーボックスの縦位置取得
      let height_pos = e.pageY - 200 + scrollDistanceY;

      // エラーメッセージの表示位置決定
      this.error_box_position = {
        top: `${height_pos}px`,
        left: `${width_pos}px`
      };

      // 該当スタッフのエラーを取得
      this.error_staff = this.errors.filter(item => item.employee_number === staff_id);
      if (this.error_staff.length > 0) {
        this.row_error_show = true;
      } else {
        this.row_error_show = false;
      }
    },
    /* 行のマウスオーバー時のボックス非表示 */
    hideError() {
      this.row_error_show = false;
    },
  },
}
</script>
