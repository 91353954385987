<template>
  <div style="font-size: 16px;">
    <h4 style="font-weight: 700; padding: 25px 24px 5px 0px;">
      勤怠システム一括割り当て
    </h4>
    <div style="display: flex; margin: 0px 0px 16px;">
      <div style="width:20%; margin: 0px 12px 0px 0px;">
        <p style="margin-bottom: 5px;">拠点名</p>
        <div class="box_1">{{ lodgment.name }}</div>
      </div>
      <div style="margin: 0px 12px 0px 0px; width:20%;">
        <p style="margin-bottom: 5px;">事業所</p>
        <v-autocomplete v-model="selectedOffice" :items="office_list" class="apiinfo-autocomplete"
          style="width: 100%; min-width: 100%;" item-text="name" item-value="id" hide-details="true"
          no-data-text="事業所が見つかりません。" dense filled @change="handleSelectChangedOffice(selectedOffice)"></v-autocomplete>
      </div>
    </div>
    <form>
      <div class="table-bulkadd" style="height: calc(100vh - 350px);">
        <table style="table-layout: fixed;">
          <tr>
            <th class="head01">
              <div style="display: flex; justify-content: space-between;">
                <div>社員番号</div>
                <button type="button" class="sort_btn" @click="set_sort_by(1)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column1_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column1_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head02">
              <div style="display: flex; justify-content: space-between;">
                <div>事業所</div>
                <button type="button" class="sort_btn" @click="set_sort_by(2)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column2_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column2_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head03">
              <div style="display: flex; justify-content: space-between;">
                <div>名前</div>
                <button type="button" class="sort_btn" @click="set_sort_by(3)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column3_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column3_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head04">
              <div>外部連携先名</div>
            </th>
            <th class="head05">
              <div>外部連携先ID</div>
            </th>
          </tr>
          <tr v-for="(kintai_data_, index) in kintai_data" :key="kintai_data_.office_staff_id">
            <td style="background: #F3F4F6;">{{ kintai_data_.actual_staff_id }}</td>
            <td>{{ kintai_data_.office_name }}</td>
            <td>{{ kintai_data_.staff_lastname }}{{ kintai_data_.staff_firstname }}</td>
            <td>
              <v-autocomplete v-model="kintai_data_.id" :items="get_external_kintai_name_array(kintai_data_.office_id)"
                class="apiinfo-autocomplete" style="width: 100%; min-width: 100%;" item-value="id" item-text="name"
                hide-details="true" no-data-text="外部連携先が見つかりません。" dense filled
                @change="handleChangeKintaidata(kintai_data_)"></v-autocomplete>
            </td>
            <td style="display: flex; justify-content: center;">
              <input
                v-model="kintai_data_.external_link_id"
                @change="handleChangeKintaidata(kintai_data_)"
                :disabled="checkDisabled(kintai_data_)"
                class="form-control"
                type="text"
              />
            </td>
            <td v-if="duplicate_error[kintai_data_.office_staff_id]" class="duplicate-error">外部連携先IDが重複しています</td>
          </tr>
        </table>
      </div>
    </form>
    <b-button class="btn_save" style="margin-top: 20px;" :disabled="!can_save" @click="save">保存</b-button>
  </div>
</template>

<style scoped>
.table-bulkadd {
  width: 100%;
  overflow: auto;
}

.table-bulkadd table {
  margin: 0;
  border-spacing: 0;
  border-collapse: separate !important;
}

.table-bulkadd th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: inherit;
  background: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5) !important;
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  z-index: 1;
}

.table-bulkadd tr:nth-child(1) th {
  top: 0px;
}

.table-bulkadd td {
  position: sticky;
  white-space: nowrap;
  height: fit-content;
  word-wrap: break-word;
  font-size: 14px;
  background: #FFFFFF;
  border: solid 1px rgba(210, 214, 219, 0.5);
  padding: 0px 5px;
}

.table-bulkadd .head01 {
  top: 0px;
  left: 0px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 3000;
}

.table-bulkadd .head02 {
  top: 0px;
  left: 150px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  z-index: 3000;
}

.table-bulkadd .head03 {
  top: 0px;
  left: 450px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  z-index: 3000;
}

.table-bulkadd .head04 {
  top: 0px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  z-index: 2000;
}

.table-bulkadd .head05 {
  top: 0px;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  z-index: 2000;
}

.table-bulkadd .duplicate-error {
  color: red;
  border: none;
  background: transparent;
}

.table-bulkadd tr td:nth-child(1) {
  left: 0px;
  min-width: 150px;
  max-width: 150px;
  z-index: 1000;
}

.table-bulkadd tr td:nth-child(2) {
  left: 150px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  z-index: 1000;
}

.table-bulkadd tr td:nth-child(3) {
  left: 450px;
  width: 300px;
  min-width: 300px;
  z-index: 1000;
}

.table-bulkadd tr td:nth-child(4) {
  width: 300px;
  min-width: 300px;
  z-index: 999;
}

.table-bulkadd tr td:nth-child(5) {
  width: 180px;
  min-width: 180px;
  z-index: 999;
}

/* table scroll design */
form {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover {
    ::-webkit-scrollbar-track {
      background: #eee;
    }

    ::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 6px;
    }
  }
}
</style>

<script>
import axios from "axios";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ClickOutside from 'vue-click-outside';

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    kintai_data: {
      type: Array,
    },
    // 事業所一覧のデータ
    offices: {
      type: Array,
      required: true,
    },
    sel_office: {
      type: Number
    },
    external_kintai_name_list: {
        type: Array,
        required: true,
    },
    role: {
      type: String,
      required: true,
    },
    sort_type: {
      type: Number,
      required: true
    },

  },
  mounted() {
    // ローディング終了
    this.updateLoading(false);
    window.addEventListener("beforeunload", this.checkValue);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.checkValue);
    this.updateLoading(false);
  },
  computed: {
    // 拠点
    lodgment() {
      return this.$page.props.auth.lodgment
    },
    // 事業所リスト（「全事業所」を追加)
    office_list() {
      if (this.user_role == 'admin') {
        this.offices.push({ id: 0, name: '全事業所' });
      }
      return this.offices;
    },
    office_selection() {
      return this.localOffice;
    },
    // ソート用のクラス設定
    column1_sort_asc() {
      if (this.sort_type == 1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column1_sort_desc() {
      if (this.sort_type == -1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_asc() {
      if (this.sort_type == 2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_desc() {
      if (this.sort_type == -2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_asc() {
      if (this.sort_type == 3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_desc() {
      if (this.sort_type == -3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_asc() {
      if (this.sort_type == 4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_desc() {
      if (this.sort_type == -4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    can_save() {
      return this.changed_kintai_data.length > 0;
    },
  },
  data() {
    return {
      user_role: this.role,
      selectedOffice: this.sel_office,
      changed_kintai_data: [],

      // 選択事業所ID
      target_office_id: null,
      clickedSaved: false,
      duplicate_error: [],
    };
  },
  methods: {
    // ローディング制御
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    // ソート並び替え
    set_sort_by(sort_by) {
      let new_sort_type;
      if (Math.abs(this.sort_type) == sort_by) {
        new_sort_type = -this.sort_type;
      } else {
        new_sort_type = sort_by;
      }
      this.$inertia.get(
        this.$route('external-kintais.bulk-add', {
          office: this.sel_office,
          sort: new_sort_type
        })
      );
    },
    get_external_kintai_name_array(office_id) {
      let new_array = [];
      this.external_kintai_name_list.forEach(officeKintai => {
          if (officeKintai.external_kintai && officeKintai.office_id === office_id) {
              new_array.push(officeKintai.external_kintai);
          }
      });
      return new_array;
    },
    // 保存
    async save() {
      this.updateLoading(true);

      Object.keys(this.duplicate_error).forEach(key => {
        this.$delete(this.duplicate_error, key);
      });

      const duplicate_data = this.changed_kintai_data.filter(item => item.external_link_id !== null).map(item => ({
          key: item.external_link_id + '-' + item.id,
          item: item
      }));
      const duplicates = duplicate_data.reduce((acc, current, idx, arr) => {
          if (arr.findIndex(item => item.key === current.key) !== idx && !acc.some(item => item.key === current.key)) {
              acc.push(current.item);
          }
          return acc;
      }, []);
      if (duplicates.length > 0) {
        duplicates.forEach(duplicate => {
          this.$set(this.duplicate_error, duplicate.office_staff_id, true);
        });
        this.updateLoading(false);
        return;
      }

      try {
        // 重複チェック
        const duplicateResponse = await axios.post(this.$route('external-kintais.check-duplicatedBulk'), this.changed_kintai_data);
        if (duplicateResponse.data.duplicate_data.length > 0) {
          duplicateResponse.data.duplicate_data.forEach(duplicate => {
            this.$set(this.duplicate_error, duplicate, true);
          });
          this.updateLoading(false);
          return;
        }

        // 保存
        const updateResponse = await axios.post(this.$route('external-kintais.do-bulkUpdate'), this.changed_kintai_data);
        this.updateLoading(false);
        if (updateResponse.status === 200) {
            this.$swal.fire({
              icon: 'success',
              html: '<span class="swal2-title">保存しました</span>'
            });
            this.changed_kintai_data = [];
        }
      } catch (err) {
        this.updateLoading(false);
        this.$swal.fire({
          icon: 'error',
          text: '設定に失敗しました。しばらくたってから再試行してください。'
        });
      }

      this.clickedSaved = true;
    },
    // #27（旧#4493）外部連携一覧画面にもどるボタン(一覧の実装後に対応)
    cancel() {
    },
    handleChangeKintaidata(kintaiData) {
      const existingIndex = this.changed_kintai_data.findIndex(item => item.id === kintaiData.id && item.office_staff_id === kintaiData.office_staff_id);
      if (existingIndex !== -1) {
        this.changed_kintai_data[existingIndex] = kintaiData;
      } else {
        this.changed_kintai_data.push(kintaiData);
      }
    },
    handleSelectChangedOffice(office_id) {
      this.$inertia.get(
        this.$route('external-kintais.bulk-add', {
          office: office_id
        })
      );
    },
    checkValue(event) {
      if (this.changed_kintai_data.length > 0) {
        event.preventDefault();
        event.returnValue = ""; // 通常のブラウザ動作を維持するために設定
        this.updateLoading(false);
        return "";
      }
    },
    checkDisabled(kintaiData) {
      if (kintaiData.id !== null) {
        return false;
      }
      else {
        return true;
      }
    }
  },
}
</script>
