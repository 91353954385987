<template>
    <div style="font-size: 14px;">
        <h4 style="margin-bottom: 16px;">勤務区分　一覧</h4>
        <p class="page-explanation">勤務区分は「早番」「日勤」「遅番」「夜勤」「明番」を設定することができ、シフト自動作成で職員に割り当てることができます。</p>
        <div class="input_row">
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="キーワード検索"
                outlined
                hide-details
                dense
                style="width: 320px; border-radius: 8px; background: #FFFFFF; margin-bottom: 32px;"
            ></v-text-field>
            <div style="display: flex; height: 40px; margin-left: 24px;">
                <b-form-checkbox
                    v-model="is_valid"
                    class="checkbox"
                    @change="changeCheckBox"
                />
                <p style="display: flex; margin: 0; align-items: center;">有効</p>
            </div>
            <div class="btn_left_area" style="width: calc(100% - 400px); padding: 0;">
                <button class="btn_white" @click="csvimport">
                    <font-awesome-icon icon="upload" class="icon-style" />CSVインポート
                </button>
                <button class="btn_white" @click="addItem">
                    <font-awesome-icon icon="plus" class="icon-style" />勤務区分を追加
                </button>
            </div>
        </div>
        <div class="input_row">

        </div>
        <v-data-table
            :headers="headers"
            :items="workpatterns"
            :items-per-page="workpatterns_list"
            :footer-props="footerProps"
            :search="search"
            fixed-header
            height="calc(100vh - 310px)"
            class="elevation-1 table_workpatterns"
            no-data-text="データが存在しません"
            no-results-text="データが存在しません"
            @update:items-per-page="savePerPage"
        >
            <template v-slot:item.category_ja="props">
                {{ props.item.category_ja }}
            </template>
            <template v-slot:item.working_start_time="props">
                {{ props.item.working_start_time ? props.item.working_start_time.substr(0,5) : ''}}
            </template>
            <template v-slot:item.working_end_time="props">
                {{ props.item.working_end_time ? props.item.working_end_time.substr(0,5) : ''}}
            </template>
            <template v-slot:item.working_hours="props">
                {{ props.item.working_hours ? props.item.working_hours.substr(0,5) : ''}}
            </template>
            <template v-slot:item.rest_time="props">
                {{ props.item.rest_time ? props.item.rest_time.substr(0,5) : ''}}
            </template>
            <template v-slot:item.active="props">
                <div :class="printDisableClass(props.item.active)">
                    <i :class="printDisableIcon(props.item.active)"></i>
                    {{ props.item.active ? '有効' : '無効' }}
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i> 変更</b-button>
                <b-button class="btn_actions" @click="deleteItem(item)"><i class="pe-7s-trash"></i> 削除</b-button>
            </template>
            <template v-slot:footer.page-text="props">
                {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
                {{ props.pageStop }} 件までを表示
            </template>
        </v-data-table>
    </div>
</template>

<style scoped>
.btn_white {
  height: 37px!important;
  margin-left: 20px;
}
</style>

<script>
import axios from 'axios';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);
export default {
    props: {
        workpatterns: {
            type: Array,
            required: true
        },
        initial_valid: {
            type: Boolean|String
        }
    },
    data() {
        return {
            search: '',
            csvupload: '',
            items: this.workpatterns.data,
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100, -1],
                'items-per-page-text': '表示件数： ',
                'items-per-page-all-text' : 'すべて',
            },
            headers: [
                {text: '勤務区分名',value: 'name', width: '230'},
                {text: '勤務種別', value: 'type_ja', width: '90'},
                {text: '勤務カテゴリ', value: 'category_ja', width: '110'},
                {text: '勤務開始', value: 'working_start_time', width: '80'},
                {text: '勤務終了', value: 'working_end_time', width: '80'},
                {text: '勤務時間', value: 'working_hours', width: '80'},
                {text: '休憩時間', value: 'rest_time', width: '80'},
                {text: '有効状態', value: 'active', width: '80' },
                {text: '', value: 'actions', sortable: false, width: '220'},
            ],
            workpatterns_list: Number(this.$cookie.get('workpatterns_list')),

            is_valid: true
        }
    },
    methods: {
        // 表のフィルタの各種チェックボックス
        changeCheckBox() {
            this.updateLoading(true);

            let url = '/workpatterns/list?valid=' + (this.is_valid ? '1' : '0')
            this.$inertia.get(this.$url(url, { preserveState: true }));
        },
        savePerPage(val) {
            this.$cookie.set('workpatterns_list', val, 1);
        },
        printDisableClass(val) {
            if (val == 1) {
                return 'item-active'
            } else {
                return 'item-passive'
            }
        },
        printDisableIcon(val) {
            if (val == 1) {
                return 'circle-active'
            } else {
                return 'circle-passive'
            }
        },
        editItem (item) {
            this.updateLoading(true);
            this.$inertia.get(route('workpatterns.edit', item.id))
        },
        addItem () {
            this.updateLoading(true);
            this.$inertia.get(route('workpatterns.add'))
        },
        deleteItem (item) {
            this.$swal({
                title: "本当に削除しますか？",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '削除',
                cancelButtonText: 'キャンセル',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.updateLoading(true);
                    this.$inertia.delete(route('workpatterns.delete', item.id), {
                        preserveScroll: true,
                        onError: (p) => {
                            this.updateLoading(false);
                            this.$swal(
                                'error',
                                '削除しようとしている勤務区分は、他の情報と紐づいているため削除ができません。削除方法については<a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E5%8B%A4%E5%8B%99%E5%8C%BA%E5%88%86%E3%81%8C%E5%89%8A%E9%99%A4%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84" target="_blank" rel="noopener noreferrer">勤務マスタが削除できない</a>をご確認ください。',
                                'error')
                        },
                        onSuccess:() => {
                            this.updateLoading(false);
                            this.$swal(
                                '勤務区分を削除しました',
                                '',
                                'success')
                        }
                    })
                }
            })
        },
        mounted() {
            // lodeing終了
            this.updateLoading(false);
        },
        reload() {
           this.$inertia.get(route('workpatterns.list'))
        },
        csvimport(){
            this.$swal({
                text: "勤務区分データ（CSV）をインポートします。",
                icon: 'warning',
                showCancelButton: true,
                input: 'file',
                inputAttributes: {
                    name:"csvupload",
                    id:"csvupload",
                    accept: "application/vnd.csv",
                    multiple:"multiple"
                },
                confirmButtonText: 'アップロード',
                cancelButtonText: 'キャンセル',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                this.updateLoading(true);
                    var formData = new FormData();
                    formData.append('csvupload',result.value[0])
                    let url = this.$route('workpatterns.import');
                    axios.post(url,formData)
                    .then(response => {
                        let results = response.data.results;
                        this.updateLoading(false);
                        var warnings_list = '';
                        Object.keys(results.warnings).forEach(function (key) {
                            var warning_row = results.warnings[key];
                            warnings_list = warnings_list + warning_row + '<br />';
                        });
                        if(results.failure > 0){
                            var errors_list = '';
                            Object.keys(results.errors).forEach(function (key) {
                                var error_row = results.errors[key];

                                if (error_row["col_name"] == "DB") {
                                    errors_list = errors_list + error_row["line"] + '行目は登録に失敗しました。<br />';
                                } else if (error_row['type'] === 'IMPORT_ERR_REQUIRED') {
                                    errors_list = errors_list + error_row["line"] + '行目の' + error_row["col_name"] + 'は必須項目です。<br />';
                                } else if (error_row['type'] === 'IMPORT_ERR_WORKTIME') {
                                    errors_list = errors_list + error_row["line"] + '行目の' + error_row["col_name"] + 'が日跨ぎになっています。<br />';
                                } else if (error_row['type'] === 'IMPORT_ERR_DUPLICATE') {
                                    errors_list = errors_list + error_row["line"] + '行目の' + error_row["col_name"] + 'が正しくありません。（同名の勤務区分がすでに登録されています。）<br />';
                                } else if (error_row['type'] === 'IMPORT_ERR_SKIP') {
                                    errors_list += error_row["line"] + '行目の勤務区分の外部連携先が4つ以上登録されています。<br />';
                                } else {
                                    errors_list = errors_list + error_row["line"] + '行目の' + error_row["col_name"] + 'が正しくありません。<br />';
                                }
                            });
                            this.$swal({
                                html: "取り込みに失敗しました。<br />"+"総件数:"+results.total+"件<br />"+"成功件数:"+results.success+"件<br />"+"失敗件数:"+results.failure+"件<br />"+warnings_list+"<br />"+errors_list,
                                icon: 'error',
                            });
                            this.reload();
                        }else{
                            this.updateLoading(false);
                            this.$swal({
                                html: "取り込みが完了しました。<br />"+"総件数:"+results.total+"件<br />"+"成功件数:"+results.success+"件<br />"+"失敗件数:"+results.failure+"件<br />"+warnings_list+"<br />",
                                icon: 'success',
                            });
                            this.reload();
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            let message = '';
                            for(const [key, messages] of Object.entries(error.response.data.errors)) {
                                message += messages + '</br>';
                            }
                            this.$swal({
                                html: message,
                                icon: 'error',
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                html: 'システムエラー',
                            })
                        }
                    }).finally(() => {
                        this.updateLoading(false);
                    })
                }
            })
        },
        updateLoading(flg) {
            EventBus.$emit('updateLoading', flg);
        },
    },
    mounted() {
        this.is_valid = this.initial_valid;
        // ローディング終了
        this.updateLoading(false);
    },
}
</script>
