<template>
  <b-button class="btn-shift-data" style="margin-left: 10px;" @click="onClickDownloadFormat">
    <font-awesome-icon icon="download" style="color: #0BB0BE; vertical-align: text-top; padding-right: 5px;" />フォーマットダウンロード
  </b-button>
</template>

<script>
import axios from "axios"
export default {
  name: 'BtnFormatDownload',
  components: {
  },
  props: {
    office: {
      type: Number,
      default: 0
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
  },
  data () {
    return {
    }
  },

  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    onClickDownloadFormat: function () {
      event.preventDefault()
      this.$swal
        .fire({
          icon: 'warning',
          text: 'ダウンロードしますか？',
          showConfirmButton: true,
          confirmButtonText: 'はい',
          showCancelButton: true,
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            this.updateLoading(true);
            let shifts_download_format_url = this.$route('shifts.download-format', {
              office: this.office,
              year: this.year,
              month: this.month
            })
            axios
              .get(shifts_download_format_url, { responseType: 'blob' })
              .then(res => {
                let elem = document.createElement('a')
                elem.href = window.URL.createObjectURL(new Blob([res.data]))
                let file_name = res.headers['content-disposition'].replace((/.*attachment; filename=["']?(.+)["']?.*/u), '$1')
                elem.setAttribute('download', file_name)
                document.body.appendChild(elem)
                elem.click()
                document.body.removeChild(elem)
              })
            this.updateLoading(false);
          }
        })
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
