<template>
    <form style="width: 100%; margin: 0px; padding: 0px;" @submit.prevent="save_checkTime()">
        <div class="workpattern_area">
            <div class="input_row" style="margin-bottom: 8px;">
                <label for="work_patterm_name" class="required">勤務区分名</label>
                <div>
                    <input
                        v-model.trim="form.name"
                        type="text"
                        class="form-control"
                        :class="{'is-invalid': errors.name}"
                        id="work_patterm_name1" 
                        @input="checkName(form.name)"
                        style="width:560px;"
                        placeholder=""
                    >
                    <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
                    <p class="explain_label" style="padding: 6px 0px!important;">勤務区分はシフトに表示されます。職員が見てわかりやすい勤務区分名にしましょう。</p>
                </div>
            </div>
            <div class="input_row" style="margin-bottom: 16px;">
                <label for="work_type" class="required">勤務種別</label>
                <div>
                    <b-form-select
                        v-model="form.work_type"
                        :options="worktypes.data"
                        :class="{'is-invalid': errors.work_type}"
                        value-field="code"
                        text-field="ja"
                    />
                    <div v-if="errors.work_type" class="invalid-feedback">{{ errors.work_type }}</div>
                </div>
            </div>
            <div class="input_row" style="margin-bottom: 16px;">
                <label for="plan" class="required">勤務カテゴリ</label>
                <div v-if="isWorkTypeWork">
                    <b-form-select
                        v-model="form.work_category"
                        :options="workcategories_work"
                        :class="{'is-invalid': errors.work_category}"
                        value-field="code"
                        text-field="ja"
                        style="width: 272px;"
                    />
                    <div v-if="errors.work_category" class="invalid-feedback">{{ errors.work_category }}</div>
                </div>
                <div v-else>
                    <b-form-select
                        v-model="form.work_category"
                        :options="workcategories_rest"
                        value-field="code"
                        text-field="ja"
                        :disabled='true'
                        :class="{'is-invalid': errors.work_category}"
                    />
                    <div v-if="errors.work_category" class="invalid-feedback">{{ errors.work_category }}</div>
                </div>
            </div>
            <template v-if="isWorkTypeWork">
                <div class="input_row" style="margin-bottom: 16px;">
                    <label for="work_start" class="required">勤務時間</label>
                    <div>
                        <div class="input_row">
                            <v-menu ref="menu1" v-model="form.working_start_menu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="form.working_start_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.working_start_time"
                                        background-color="white"
                                        hide-details="false"
                                        v-on="on"
                                        dense
                                        outlined
                                        placeholder=""
                                        style="width: 120px; min-width: 120px;"
                                        @click="setFocuseTime(form.working_start_time, 0);"
                                        @keydown="form.working_start_menu = false;"
                                        @blur="outFocuseTime(form.working_start_time, 0);"
                                        :disabled="! isWorkTypeWork"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    ref="working_start_picker"
                                    v-model="form.working_start_time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menu1.save(form.working_start_time)"
                                    :disabled="! isWorkTypeWork"
                                ></v-time-picker>
                            </v-menu>
                            <p class="after_label" style="padding: 9px 4px;">～</p>
                            <v-menu
                                ref="menu3"
                                v-model="form.working_end_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="form.working_end_time"
                                transition="scale-transition"
                                offset-y max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.working_end_time"
                                        background-color="white"
                                        hide-details="false"
                                        v-on="on"
                                        dense
                                        outlined
                                        placeholder=""
                                        style="width: 120px; min-width: 120px;"
                                        @click="setFocuseTime(form.working_end_time, 2);"
                                        @keydown="form.working_end_menu = false;"
                                        @blur="outFocuseTime(form.working_end_time, 2);"
                                        :disabled="! isWorkTypeWork"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    ref="working_end_picker"
                                    v-model="form.working_end_time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menu3.save(form.working_end_time)"
                                    :disabled="! isWorkTypeWork"
                                ></v-time-picker>
                            </v-menu>
                            <div v-if="isWorkTypeWork" class="input_row">
                                <label for="worktime" style="width: auto; padding: 9px 8px 9px 16px;">合計時間</label>
                                <input v-model="form.working_hours" type="text" class="form-control" id="working_hours" style="width: 80px;" disabled>
                            </div>
                        </div>
                        <div v-if="errors.working_start_time" class="invalid-feedback d-block">{{ errors.working_start_time }}</div>
                        <div v-if="working_start_error" class="invalid-feedback d-block">{{ working_start_error }}</div>
                        <div v-if="errors.working_end_time"><span class="invalid-feedback d-block">{{ errors.working_end_time }}</span></div>
                        <div v-if="working_end_error" class="invalid-feedback d-block">{{ working_end_error }}</div>
                    </div>
                </div>
                <div class="input_row" style="margin-bottom: 16px;">
                    <label for="break_start">休憩時間</label>
                    <div>
                        <div class="input_row">
                            <v-menu ref="menu2" v-model="form.rest_start_menu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="form.rest_start_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.rest_start_time"
                                        background-color="white"
                                        hide-details="false"
                                        v-on="on"
                                        dense
                                        outlined
                                        placeholder=""
                                        style="width: 120px; min-width: 120px;"
                                        @click="setFocuseTime(form.rest_start_time, 1);"
                                        @keydown="form.rest_start_menu = false;"
                                        @blur="outFocuseTime(form.rest_start_time, 1);"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    ref="rest_start_picker"
                                    v-model="form.rest_start_time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menu2.save(form.rest_start_time); form.rest_start_menu = false;"
                                ></v-time-picker>
                            </v-menu>
                            <div v-if="isWorkTypeWork">
                                <p class="after_label" style="padding: 9px 4px;">～</p>
                            </div>
                            <div v-if="isWorkTypeWork">
                                <v-menu ref="menu4" v-model="form.rest_end_menu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="form.rest_end_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="form.rest_end_time"
                                            background-color="white"
                                            hide-details="false"
                                            v-on="on"
                                            dense
                                            outlined
                                            placeholder=""
                                            style="width: 120px; min-width: 120px;"
                                            @click="setFocuseTime(form.rest_end_time, 3);"
                                            @keydown="form.rest_end_menu = false;"
                                            @blur="outFocuseTime(form.rest_end_time, 3);"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        ref="rest_end_picker"
                                        v-model="form.rest_end_time"
                                        format="24hr"
                                        full-width
                                        @click:minute="$refs.menu4.save(form.rest_end_time); form.rest_end_menu = false;"
                                    ></v-time-picker>
                                </v-menu>
                            </div>
                            <div v-if="isWorkTypeWork" class="input_row">
                                <label for="breaktime" style="width: auto; padding: 9px 8px 9px 16px;">合計時間</label>
                                <input v-model="form.rest_time" type="text" class="form-control" id="rest_time" style="width: 80px;" disabled :class="{'is-invalid': errors.rest_time}">
                            </div>
                        </div>
                        <div v-if="errors.rest_start_time" class="invalid-feedback d-block">{{ errors.rest_start_time }}</div>
                        <div v-if="rest_start_error" class="invalid-feedback d-block">{{ rest_start_error }}</div>
                        <div v-if="errors.rest_end_time" class="invalid-feedback d-block">{{ errors.rest_end_time }}</div>
                        <div v-if="rest_end_error" class="invalid-feedback d-block">{{ rest_end_error }}</div>
                        <div v-if="errors.rest_time" class="invalid-feedback d-block">{{ errors.rest_time }}</div>
                    </div>
                </div>
            </template>
            <div  v-if="this.form.work_category === 'night'" class="input_row" style="margin-bottom: 8px;">
                <label for="next_workpattern_id">後続させたい勤務区分を選択</label>
                <div>
                    <v-autocomplete
                        v-model="form.next_workpattern_id"
                        class="select_style"
                        style="width: 274px; min-width: 274px; padding: 0px!important;"
                        :items="dawn_work"
                        item-value="id"
                        item-text="name"
                        hide-details="true"
                        no-data-text="勤務区分が見つかりません。"
                        dense
                        filled
                    ></v-autocomplete>
                    <div v-if="errors.next_workpattern_id" class="invalid-feedback">{{ errors.next_workpattern_id }}</div>
                </div>
            </div>
            <div class="input_row" style="margin-bottom: 16px;">
                <label for="worktime">有効状態</label>
                <div v-if="activatable" class="switchArea input_row">
                    <input type="checkbox" v-model="form.active" id="active" />
                    <label for="active"></label>
                    <div id="swImg"></div>
                    <p class="after_label" style="padding: 0px 8px!important;">この勤務区分を有効にする</p>
                </div>
                <div v-else>有効にできる勤務区分数の上限に達しています。</div>
            </div>
            <div v-if="errors.active" class="invalid-feedback d-block">{{ errors.active }}</div>
        </div>
        <div class="workpattern_area">
            <h5>外部連携</h5>
            <!-- 外部連携コンポーネント -->
            <WorkpatternKintaiForm
                :workpattern-kintais="form.workpattern_kintais"
                :external_kintai_list="external_kintai_list"
                :errors="errors"
                style="width: 100%;"
                @workpatternKintaisChanged="handleWorkpatternKintaisChanged"
            />
        </div>
        <b-button :disabled="!checkValidation" type="submit" variant="primary" style="font-size:14px; width: auto; padding:10px 16px;">保存</b-button>
    </form>
</template>

<script>
import { Compact } from 'vue-color';
import timepicker from "@/Pages/Components/MyTimePicker";
import WorkpatternKintaiForm from '@/Pages/Workpatterns/WorkpatternKintaiForm.vue'

export default {
    components: {
        "compact-picker": Compact,
        timepicker,
        WorkpatternKintaiForm,
    },
    data () {
        return {
            icon: "pe-7s-drawer icon-gradient bg-happy-itmeo",
            colors: this.workpatterns?.data?.color ?? "#FFFFFF",
            working_start_menu: true,
            working_end_menu: true,
            rest_start_menu: true,
            rest_end_menu: true,
            rest_end_label:false,
            working_start_error: '',
            working_end_error: '',
            rest_start_error: '',
            rest_end_error: '',
            nameValidationCheck: true,
            workingStartTimeValidationCheck: true,
            workingEndTimeValidationCheck: true,
            workingTimeValidationCheck: true,
            restStartTimeValidationCheck: true,
            restEndTimeValidationCheck: true,
            restTimeValidationCheck: true,
            restValidationCheck: true,
            restTimeValidationCheck: true,
            apiinfoValidationCheck: true,
        }
    },
    props: {
        errors: {
            type: Object,
            required: false,
        },
        worktypes: {
            type: Object,
            required: true
        },
        workcategories_work: {
            type: Array,
            required: true
        },
        workcategories_rest: {
            type: Array,
            required: true
        },
        workpatterncolors: {
            type: Object,
            required: true
        },
        activatable: {
            type: Boolean,
            required: true
        },
        dawn_work: {
            type: Array,
            required: true
        },
        next_day_flg: {
            type: Boolean,
        },
        external_kintai_list :{
            type : Array,
            required :true
        },
        form: {
            type: Object,
            required: true
        },
    },
    computed : {
        checkValidation() {
            return this.nameValidationCheck
                && this.workingStartTimeValidationCheck
                && this.workingEndTimeValidationCheck
                && this.workingTimeValidationCheck
                && this.restStartTimeValidationCheck
                && this.restEndTimeValidationCheck
                && this.restTimeValidationCheck
                && this.restValidationCheck
                && this.restTimeValidationCheck
                && this.apiinfoValidationCheck;
        },
        isWorkTypeWork() {
            return this.isWorkTypeIn(['work', 'half_work']);
        },
    },
    watch: {
        'form.work_type': {
            handler(val, oldVal) {
                // 選択された勤務種別が「休み」（勤務・半勤務以外）の場合
                if (! this.isWorkTypeIn(['work', 'half_work'], val)) {
                    this.form.work_category = 'holiday';
                    this.form.working_start_time = '00:00';
                    this.form.working_end_time = '00:00';
                    this.form.working_hours = '00:00';
                    this.form.rest_start_time = '00:00';
                    this.form.rest_end_time = '23:59';
                    this.form.rest_time = '23:59';

                    this.workingStartTimeValidationCheck = true;
                    this.workingEndTimeValidationCheck = true;
                    this.workingTimeValidationCheck = true;
                }
                // 選択された勤務種別が「勤務・半勤務」で、選択前が「休み」（勤務・半勤務以外）の場合
                if (this.isWorkTypeIn(['work', 'half_work'], val) && ! this.isWorkTypeIn(['work', 'half_work'], oldVal)) {
                    this.form.work_category = 'early';
                    this.form.rest_start_time = '';
                    this.form.rest_end_time = '';
                    this.form.rest_time = '';
                }
            },
            immediate: true
        },
        'form.work_category': function (val) {
            if (! val) {
                return;
            }

            if (val !== 'night') {
                this.form.next_workpattern_id = null;
            }
            
            this.checkForm();
            this.checkRestDateTime();
        },
        'form.working_start_time':function (val) {
            this.working_start_error = '';
            this.form.working_hours = '';
            this.workingStartTimeValidationCheck = true;
            this.workingTimeValidationCheck = true;

            // 勤務開始時間必須チェック
            if (! this.hasValue(val)) {
                this.form.working_start_menu = false;
                this.workingStartTimeValidationCheck = false;
                this.$set(this.errors, 'working_start_time', '勤務開始は必ず入力してください。')

                return;
            }

            // 時間以外が入力されていたらエラー
            if (! this.isTimeFormat(val)) {
                this.form.working_start_menu = false;
                this.workingStartTimeValidationCheck = false;
                this.$set(this.errors, 'working_start_time', '勤務開始は正しい時刻で入力してください。')

                return;
            }

            this.$set(this.errors, 'working_start_time', null)

            this.checkForm();
            this.checkRestDateTime();
            this.calculateWorkTime();
        },
        'form.working_end_time':function (val) {
            this.working_end_error = '';
            this.form.working_hours = '';
            this.workingEndTimeValidationCheck = true;
            this.workingTimeValidationCheck = true;

            // 時間以外が入力されていたらエラー
            if (! this.hasValue(val)) {
                this.form.working_end_menu = false;
                this.workingEndTimeValidationCheck = false;
                this.$set(this.errors, 'working_end_time', '勤務終了は必ず入力してください。')
                
                return;
            }
            if (!this.isTimeFormat(val)) {
                this.form.working_end_menu = false;
                this.workingEndTimeValidationCheck = false;
                this.$set(this.errors, 'working_end_time', '勤務終了は正しい時刻で入力してください。')
                
                return;
            }

            this.$set(this.errors, 'working_end_time', null)

            this.checkForm();
            this.checkRestDateTime();
            this.calculateWorkTime();
        },
        'form.rest_start_time': function (val) {
            this.restStartTimeValidationCheck = true;
            this.restTimeValidationCheck = true;

            if (! this.isWorkTypeWork) {
                this.$set(this.errors, 'rest_start_time', '');
                this.$set(this.errors, 'rest_end_time', '');
                return;
            }

            // 休憩時間（開始・入力）必須チェック
            this.checkRestRequired();

            this.rest_start_error = '';
            this.form.rest_time = '';

            // 時間以外が入力されていたらエラー
            if (this.hasValue(val) && !this.isTimeFormat(val)) {
                this.form.rest_start_menu = false;
                this.restStartTimeValidationCheck = false;
                this.$set(this.errors, 'rest_start_time', '休憩開始は正しい時刻で入力してください。');

                return;
            }
        
            this.$set(this.errors, 'rest_start_time', null);
            
            if (this.restEndTimeValidationCheck) {
                this.$set(this.errors, 'rest_end_time', null);
            }

            this.checkRestDateTime();
    
            this.calculateRestTime();
            this.calculateWorkTime();
        },
        'form.rest_end_time':function (val) {
            this.restEndTimeValidationCheck = true;
            this.restTimeValidationCheck = true;

            // 公休（半休）、有休（半休）の場合はエラーをクリアして処理終了
            if (this.isWorkTypeIn(['public_half_holiday', 'paid_half_holiday'])) {
                this.$set(this.errors, 'rest_start_time', '');
                this.$set(this.errors, 'rest_end_time', '');
                return;
            }

            this.rest_end_error = '';
            this.form.rest_time = '';

            // 休憩時間（開始・入力）必須チェック
            this.checkRestRequired();

            // 時間以外が入力されていたらエラー
            if (this.hasValue(val) && !this.isTimeFormat(val)) {
                this.form.rest_end_menu = false;
                this.restEndTimeValidationCheck = false;
                this.$set(this.errors, 'rest_end_time', '休憩終了は正しい時刻で入力してください。');
            }

            this.$set(this.errors, 'rest_end_time', null)
            this.checkRestDateTime();
        
            this.calculateRestTime();
            this.calculateWorkTime();
        },
        'form.workpattern_kintais': {
            handler() {
                this.checkForm();
            },
            deep: true,
        },
    },
    methods: {
        isWorkTypeIn(workTypeList, workType = this.form.work_type) {
            return workTypeList.includes(workType); 
        },
        checkForm() {
            // 勤務開始終了時間チェック
            if (! this.validWorkingTime()) {
                this.workingTimeValidationCheck = false;
                this.$set(this.errors, 'working_end_time', '勤務終了が勤務開始時刻と同じになっています。')
            } else if (! this.validWorkingTimeNextDay()) { 
               this.workingTimeValidationCheck = false;
                this.$set(this.errors, 'working_end_time', '日跨ぎ不可の外部連携先が含まれるため、日跨ぎしないようにしてください。')
            } else {
                this.workingTimeValidationCheck = true;
                this.$set(this.errors, 'working_end_time', null)
            }
        },
        canAcrossNextDay() {
            return this.form.workpattern_kintais.every((workpatternKintai) => {
                return this.external_kintai_list.find((externalKintai) => {
                    return externalKintai.id === workpatternKintai.external_kintai_id
                })?.next_day_flg;
            });
        },
        isTimeFormat(str) {
            const regex = RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)
            
            return regex.test(str);
        },
        isTimeAcrossNextDay(startTime, endTime) {
            const regex = RegExp(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)

            const start_result = regex.exec(startTime);
            const start = parseInt(start_result[1] + start_result[2]);

            const end_result = regex.exec(endTime);
            const end = parseInt(end_result[1] + end_result[2]);

            return start > end
        },
        checkName(val) {
            // 必須チェック
            if (! this.hasValue(val)) {
                this.nameValidationCheck = false;
                this.$set(this.errors, 'name', '勤務区分名は必ず指定してください。');
                return;
            }

            this.nameValidationCheck = true;
            this.$delete(this.errors, 'name');
        },
        hasValue(value) {
            return value !== null && value !== '' && value !== undefined;
        },
        setFocuseTime(value, num) {
            // 時間未入力の場合、タイムピッカーの時にカーソルを合わせる
            if (value === null) {
                if (num === 0) {
                    if (this.form.working_start_menu) {
                    }
                } else if (num === 1) {
                    if (this.form.rest_start_menu) {
                        this.$refs.rest_start_picker.selectingHour = true;
                    }
                } else if (num === 2) {
                    if (this.form.working_end_menu) {
                        this.$refs.working_end_picker.selectingHour = true;
                    }
                } else if (num === 3) {
                    if (this.form.rest_end_menu) {
                        this.$refs.rest_end_picker.selectingHour = true;
                    }
                }
            }
        },
        outFocuseTime(value, num) {
            // 時間以外が入力されていたらnullをセットする
            if (this.hasValue(value) && !this.isTimeFormat(value)) {
                if (num === 0) {
                    this.form.working_start_time = null;
                } else if (num === 1) {
                    this.form.rest_start_time = null;
                } else if (num === 2) {
                    this.form.working_end_time = null;
                } else if (num === 3) {
                    this.form.rest_end_time = null;
                }
            }
        },
        save_checkTime() {
            let result = true
            let get_name = this.isWorkTypeWork ? '勤務' : '休暇';

            if (this.isWorkTypeWork) {
                // 勤務開始妥当性チェック
                this.working_start_error = ''
                this.errors.working_start_time = ''

                const working_start_time = this.form.working_start_time;

                if (this.hasValue(working_start_time) && !this.isTimeFormat(working_start_time)) {
                    this.working_start_error = get_name + '開始は正しい時刻で入力してください。'
                    result = false
                } else if (! this.hasValue(working_start_time)) {
                    this.working_start_error = get_name + '開始は必ず指定してください。'
                    result = false
                }

                // 勤務終了妥当性チェック
                this.working_end_error = ''
                this.errors.working_end_time = ''
                const working_end_time = this.form.working_end_time;
                if (this.hasValue(working_end_time) && !this.isTimeFormat(working_end_time)) {
                    this.working_end_error = get_name + '終了は正しい時刻で入力してください。'
                    result = false
                } else if (!this.hasValue(working_end_time)) {
                    this.working_end_error = get_name + '終了は必ず指定してください。'
                    result = false
                }
            }

            // 休憩開始妥当性チェック
            this.rest_start_error = ''
            this.errors.rest_start_time = ''
            
            const rest_start_time = this.form.rest_start_time;
            if (this.hasValue(rest_start_time) && !this.isTimeFormat(rest_start_time)) {
                this.rest_start_error = '休憩開始は正しい時刻で入力してください。'
                result = false
            }

            // 休憩終了妥当性チェック
            this.rest_end_error = '';
            this.errors.rest_end_time = '';
            
            const rest_end_time = this.form.rest_end_time; 
            if (this.hasValue(rest_end_time) && !this.isTimeFormat(rest_end_time)) {
                this.rest_end_error = '休憩終了は正しい時刻で入力してください。'
                result = false
            }

            if (result) {
                this.$emit('submit');
            }
        },
        checkRestDateTime() {
            let time_check = true;
            this.restValidationCheck = true;

            if ((this.form.working_start_time && this.form.working_end_time) && (this.form.rest_start_time && this.form.rest_end_time)) {
                let work_start_time = this.getDateTime(this.form.working_start_time, this.form.working_start_time);
                let work_end_time = this.getDateTime(this.form.working_end_time, this.form.working_start_time);
                let set_start_time = this.getDateTime(this.form.rest_start_time, this.form.working_start_time);
                let set_end_time = this.getDateTime(this.form.rest_end_time, this.form.working_start_time);

                // 休憩開始と休憩終了が同値である場合は範囲内チェックを行わない
                if (set_start_time !== set_end_time) {
                    if ((work_start_time >= set_start_time || work_end_time <= set_start_time) && (this.form.work_category !== 'holiday')) {
                        this.$set(this.errors, 'rest_start_time', '休憩開始は勤務時間内に設定してください。');
                        time_check = false;
                        this.restValidationCheck = false;
                    }
                    if ((work_start_time >= set_end_time || work_end_time <= set_end_time) && (this.form.work_category !== 'holiday')) {
                        this.$set(this.errors, 'rest_end_time', '休憩終了は勤務時間内に設定してください。');
                        time_check = false;
                        this.restValidationCheck = false;
                    }
                }
                if (time_check) {
                    if (this.hasValue(this.form.rest_start_time) && this.isTimeFormat(this.form.rest_start_time)) {
                        this.$set(this.errors, 'rest_start_time', null);
                    }
                    if (this.hasValue(this.form.rest_end_time) && this.isTimeFormat(this.form.rest_end_time)) {
                        this.$set(this.errors, 'rest_end_time', null);
                    }
                }
            } else if (this.form.rest_start_time && this.form.rest_end_time){
                if (this.hasValue(this.form.rest_start_time) && this.isTimeFormat(this.form.rest_start_time)) {
                    this.$set(this.errors, 'rest_start_time', null);
                }
                if (this.hasValue(this.form.rest_end_time) && this.isTimeFormat(this.form.rest_end_time)) {
                    this.$set(this.errors, 'rest_end_time', null);
                }
            }
            return time_check;
        },
        // 休憩時間の開始・終了入力チェック
        checkRestRequired() {
            // 休憩時間開始、旧姓時間終了がともに未入力であればOK
            if ((! this.hasValue(this.form.rest_start_time)) && (! this.hasValue(this.form.rest_end_time))) {
                this.restStartTimeValidationCheck = true;
                this.restEndTimeValidationCheck = true;
                this.restValidationCheck = true;
                this.restTimeValidationCheck = true;
                this.$set(this.errors, 'rest_start_time', '');
                this.$set(this.errors, 'rest_end_time', '');
                this.$set(this.errors, 'rest_time', '');

                return;
            }
            
            // 休憩時間は開始・終了どちらかしか入力されていなければNG
            if ( this.hasValue(this.form.rest_start_time) !==  this.hasValue(this.form.rest_end_time)) {
                this.restTimeValidationCheck = false;
                this.$set(this.errors, 'rest_time', '休憩時間は開始時間と終了時間の両方を入力してください。');

                return;
            }

            // 休憩時間（開始・終了）必須チェックOK
            this.restTimeValidationCheck = true;
            this.$set(this.errors, 'rest_time', '');
        },
        getDateTime(val, start) {
            let start_time_min = this.convertTimeToMinutes(start);
            let time_min = this.convertTimeToMinutes(val);

            if (time_min < start_time_min) {
                time_min = this.addOneDayByMinutes(time_min);
            }

            return time_min;
        },
        addOneDayByMinutes(minute) {
            return minute + 24 * 60;
        },
        calculateTimeDiff(start, end, rest = '0:00') {
            if (! this.isTimeFormat(start) || ! this.isTimeFormat(end)) {
                return '';
            }

            if (! this.hasValue(rest) ||! this.isTimeFormat(rest)) {
                rest = '0:00';
            }

            let startMin = this.convertTimeToMinutes(start);
            let endMin = this.convertTimeToMinutes(end);
            let restMin = this.convertTimeToMinutes(rest);

            if (endMin < startMin) {
                endMin = this.addOneDayByMinutes(endMin);
            }

            let min_diff =  endMin - startMin - restMin;
            if (min_diff < 0) {
                min_diff = 0;
            }

            let diff_hour = ~~(min_diff / 60);
            let diff_min = min_diff - diff_hour * 60;

            if (isNaN(diff_hour) || isNaN(diff_min)) {
                return ''
            }

            return ('00' + String(diff_hour)).slice(-2) + ':' + ('00' + String(diff_min)).slice(-2);
        },
        calculateWorkTime() {
            this.form.working_hours = this.calculateTimeDiff(
                this.form.working_start_time,
                this.form.working_end_time,
                this.form.rest_time
            );
        },
        calculateRestTime() {
            this.form.rest_time = this.calculateTimeDiff(
                this.form.rest_start_time,
                this.form.rest_end_time
            );
        },
        convertTimeToMinutes(timeStr) {
            const timeArray = timeStr.split(':');

            return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
        },
        handleWorkpatternKintaisChanged(data, hasErrors) {
            this.form.workpattern_kintais = data;
            // エラーがあればフラグをfalseにする
            this.apiinfoValidationCheck = !hasErrors;
        },
        // バリデーション
        validWorkingTimeNextDay() {
            if (! this.isTimeFormat(this.form.working_start_time)) {
                return true;
            }

            if (! this.isTimeFormat(this.form.working_end_time)) {
                return true;
            }

            if (this.canAcrossNextDay()) {
                return true;
            }

            return ! this.isTimeAcrossNextDay(this.form.working_start_time, this.form.working_end_time);            
        },
        validWorkingTime() {
            if (this.form.work_category === 'holiday') {
                return true;
            }

            if (this.form.work_category === 'dawn' && this.form.working_start_time === '00:00') {
                return true;
            }

            if (! this.hasValue(this.form.working_start_time)) {
                return true;
            }

            if (! this.hasValue(this.form.working_end_time)) {
                return true;
            }

            if (this.form.working_start_time !== this.form.working_end_time) {
                return true;
            }

            return false;
        },
    }
};
</script>
