<template>
  <div class="table_add_box">
    <v-select
      :items="select_box"
      item-text="text"
      item-value="id"
      height="0px"
      @change="selectTarget"
      return-object
      v-model="target"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'ShiftsScheduleListAddCalcRowButton',
  components: {
  },
  props: {
    // 日付データ
    days: {
      type: Array,
      default: function() {
        return [];
      }
    },
    workpatterns: {
      type: Array,
      default: function() {
        return {};
      }
    },
    time_list: {
      type: Object,
      default: function() {
        return {};
      }
    },
    shift_summaries: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      select_box: [],
      target: null,
      add_workpattern_visible: 'display: none;',
    }
  },
  methods: {
    addWorkpattern() {
      if (this.add_workpattern_visible === 'display: block;') {
        this.add_workpattern_visible = 'display: none;';
      } else {
        this.add_workpattern_visible = 'display: block;';
      }
    },
    setSelectBox() {
      this.select_box = [];
      for (let key in this.time_list) {
        this.select_box.push({ id: 'time_' + key, text: this.time_list[key].ja })
      }

      // select boxに追加する
      this.workpatterns.forEach((workpattern) => {
        if ('workpattern_' + workpattern.id in this.shift_summaries) return;
        this.select_box.push({ id: 'workpattern_' + workpattern.id, text: workpattern.name })
      });

      for (let key in this.shift_summaries) {
        let tmp = [];
        if (key.match(/time/)) {
          tmp = key.split('_');
        } else {
          tmp = key.split('_');
        }
        let id = tmp[0] + '_' + tmp[1];

        for (let key2 in this.select_box) {
          if (this.select_box[key2].id === id) {
            delete this.select_box[key2];
            break;
          }
        }
      }
    },
    selectTarget(e) {
      this.add_workpattern_visible = 'display: none;';

      if (e.id === '') return;

      this.target = null;
      this.$emit('selectCalcTarget', e.id);
    },
  },
  computed: {
  },
  watch: {
    shift_summaries: {
      handler(new_data, old_data) {
        this.setSelectBox();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setSelectBox();
    })
  }
}
</script>
