<template>
	<div class="staffs_area">
	<h5>事業所ごとのシフト設定</h5>
		<div v-for="(office_staff, i) in office_staffs" :key="i" class="office_shift_area" style="margin-bottom: 0px;">
			<div class="input_row">
				<p style="font-weight: 700; margin: 0px;">{{ getOfficeName(office_staff.office_id) || '事業所を選択してください'}}</p>
				<div
					v-if="dropdownMenuButton"
					style="margin:0 0 0 auto;"
					>
					<div class="dropdown" @click="open(office_staff.office_id)">
						<button
							class="btn_add dropdown-toggle"
							type="button"
							:id="`dropdownMenuButton` + office_staff.office_id"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							style="width: 210px!important;"
							:disabled="activeShiftSetting(office_staff)"
							>
							<i class="pe-7s-pen"></i>他の事業所の設定を反映
						</button>
						<div class="dropdown-menu" :ref="`dropdown` + office_staff.office_id" v-show="false" aria-labelledby="dropdownMenuButton">
							<p
								v-for="(office_id, i) in getOfficeListExcept(office_staff.office_id)"
								class="dropdown-item"
								@click="copyOtherOfficeWorkpatterns(office_id, office_staff.office_id)"
								:key="i">
								  {{ getOfficeName(office_id) }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="input_row"  style="margin-top: 16px;">
				<label for="">労働時間</label>
				<input
					v-model="office_staff.min_worktime_minutes"
					type="number"
					class="form-control"
					min="1"
					step="1"
					style="width: 76px;"
					placeholder=""
					:disabled="activeShiftSetting(office_staff)"
				/>
				<p class="after_label">時間以上</p>
				<input
				  v-model="office_staff.max_worktime_minutes"
					type="number"
					class="form-control"
					min="1"
					step="1"
					style="width: 76px;"
					:disabled="activeShiftSetting(office_staff)"
				/>
				<p class="after_label">時間以下</p>
			</div>
			<!-- error message -->
			<div v-if="form_errors['office_staffs'] && form_errors['office_staffs'][i]">
		    <div v-if="form_errors['office_staffs'][i]['min_worktime_minutes']">
		      <p class="invalid-feedback">
		        {{ form_errors['office_staffs'][i]['min_worktime_minutes'] }}
		      </p>
				</div>
				<div v-if="form_errors['office_staffs'][i]['max_worktime_minutes']">
		      <p class="invalid-feedback">
		        {{ form_errors['office_staffs'][i]['max_worktime_minutes'] }}
		      </p>
				</div>
			</div>
			<div class="input_row" style="margin-top: 22px;">
				<label for="">勤務区分の割り当て</label>
				<div style="width: calc(100% - 200px)">
					<v-data-table
							:headers="list_headers"
							:items="workpattern_list[office_staff.office_id]"
							:items-per-page="staff_shift_list"
							:footer-props="footerProps"
							no-data-text="データが存在しません"
							no-results-text="データが存在しません"
							class="elevation-1 table_1 table_2"
							style="width: 100%!important;"
						>
						<template v-slot:item.working_count="{ item }">
							<div class="input_row">
								<input
									v-model="item.working_count.min_monthly_working_count"
									type="number"
									class="form-control"
									min="0"
									max="31"
									step="1"
									style="width:70px;"
									@change="changeWorkingCount(office_staff, item)"
									:disabled="notSetMothlyWorkingCount(item)"
								/>
								<p style="display: flex; align-items: center; margin: 0px!important; padding: 0px 20px 0px 10px;">回以上</p>
								<input
								v-model="item.working_count.max_monthly_working_count"
									type="number"
									class="form-control"
									min="0"
									max="31"
									step="1"
									style="width:70px;"
									@change="changeWorkingCount(office_staff, item)"
									:disabled="notSetMothlyWorkingCount(item)"
								/>
								<p style="display: flex; align-items: center; margin: 0px!important; padding: 0px 0px 0px 10px;">回以下</p>
							</div>
						</template>
						<template v-slot:item.actions="{ item }">
							<button class="btn_actions" style="margin: 0px;" @click="deleteItem(office_staff, item)">
								<i class="pe-7s-trash" style="margin-right: 10.5px;"></i>削除</button>
						</template>
						<template v-slot:no-data>
							データが存在しません
						</template>
						<template v-slot:footer.page-text="props">
							{{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
							{{ props.pageStop }} 件までを表示
						</template>
					</v-data-table>
				</div>
			</div>
			<!-- error message -->
			<div v-if="form_errors['office_staffs']
				&& form_errors['office_staffs'][i]
				&& form_errors['office_staffs'][i]['workpatterns']"
				class="error-message"
				>
				<div v-for="(workpattern_error, index) in form_errors['office_staffs'][i]['workpatterns']" :key="index">
					<div v-if="workpattern_error['working_count']['min_monthly_working_count']">
			      <p class="invalid-feedback">
			        {{ workpattern_list[office_staff.office_id][index].name + 'の' + workpattern_error['working_count']['min_monthly_working_count'] }}
			      </p>
					</div>
					<div v-if="workpattern_error['working_count']['max_monthly_working_count']">
			      <p class="invalid-feedback">
			        {{ workpattern_list[office_staff.office_id][index].name + 'の' + workpattern_error['working_count']['max_monthly_working_count'] }}
			      </p>
					</div>
				</div>
			</div>

			<div
				class="input_row"
				style="margin-top: 10px;">
				<span class="dummy_label"></span>
				<button
					class="btn_add"
					@click="showModalWorkpattern(i)"
					:disabled="activeShiftSetting(office_staff)"
					style="width: auto!important;"
					>
					<span>＋</span>勤務区分を選択
				</button>
			</div>
			<div class="input_row">
				<label for="">シフトパターン</label>
				<div style="display: flex; width: calc(100% - 200px);">
					<span v-for="(weekday, i) in weekdays" :key="i" class="office_shift_table" style="margin-top: 9px;">{{ weekday }}</span>
				</div>
			</div>
			<div class="input_row">
				<span class="dummy_label"></span>
				<div style="display: flex; width: calc(100% - 200px);">
					<div v-for="(weekday, i) in weekdays" class="office_shift_table" :key="i">
						<b-form-select
							:options="workpattern_for_shift_pattern_list[office_staff.office_id]"
							v-model="office_staff.shift_pattern[i]"
							value-field="workpattern"
							text-field="workpattern_name"
							style="width: 100%!important"
							@change="changeShiftPattern(office_staff, i, office_staff.shift_pattern[i])"
							:disabled="activeShiftPatternSelect(office_staff)"
						>
						</b-form-select>
					</div>
				</div>
			</div>
			<ModalWorkpattern
				@workpatterns="setShiftPatternWorkpatternList"
				:office_staff="office_staff"
				:offices="offices"
				:workpatterns="workpatterns"
				:codes="codes"
				:ref="`workpatternModal` + i"
			/>
		</div>
	</div>
</template>
<script>
import ModalWorkpattern from "./ModalWorkpattern";

export default {
  name: "ShiftSetting",
  components: {
    ModalWorkpattern,
	},
  props: {
		// 事業所スタッフ
    office_staffs: {
      type: Object|Array,
      required: false
    },
		// 事業所
    offices : {
      type : Object,
      required :true
    },
		// 勤務区分
    workpatterns : {
      type : Object,
      required :true
    },
		// コード
		codes : {
			type : Object,
			required :true
		},
		// 正規 / 非正規職員ごとの勤務カテゴリの勤務回数デフォルト値
		defaultMonthlyWorkingCounts: {
      type: Object,
      required: true
    },
		// 職員の雇用形態
		staffEmploymentType: {
			type: String|null,
			required: false
		},
		errors : {
			type : Object,
			required :true
		},
		form_errors : {
			type : Object,
			required :true
		},
  },
	computed: {
		// 他の事業所のコピーボタンの表示・非常時
		dropdownMenuButton () {
			return this.office_staffs.length >= 2
		},
    // シフト設定のバリデーション
    checkValidation () {
			// このコンポーネント内のフロント側のバリデーション
      return true
    },
	},
	methods: {
		/**
		 * データ取得
		 */
		// 事業所名取得
		getOfficeName (value) {
      return value
        ? this.offices.data.find(office => Number(office.id) === Number(value))
        ? this.offices.data.find(office => Number(office.id) === Number(value)).name
        : null
        : null
    },
    // 勤務区分名取得
    getWorkpatternName (value) {
      return value
        ? this.workpatterns.data.find(workpattern => Number(workpattern.id) === Number(value))
        ? this.workpatterns.data.find(workpattern => Number(workpattern.id) === Number(value)).name
        : null
        : null
    },
		// 勤務区分リスト取得
		getWorkpatterns() {
      let workpatternList = {}
      this.office_staffs.forEach(office_staff => {
				if(!office_staff.office_id) return

        workpatternList[office_staff.office_id] = []

    		// workpatternsが登録済みなら
        if (office_staff.workpatterns.length) {
          office_staff.workpatterns.forEach(workpattern => {
						workpatternList[office_staff.office_id].push({
							id: workpattern.id,
							name: workpattern.name,
							type: this.getWorkpatternTypeJa(workpattern.type),
							category: this.getWorkpatternCategoryJa(workpattern.category),
							working_start_time: workpattern.working_start_time || '-',
							working_end_time: workpattern.working_end_time || '-',
							rest_start_time: workpattern.rest_start_time || '-',
							rest_end_time: workpattern.rest_end_time || '-',
							working_count : {
								min_monthly_working_count : workpattern.working_count.min_monthly_working_count,
								max_monthly_working_count :	workpattern.working_count.max_monthly_working_count,
							},
            })
          })
        }
      })
      return workpatternList
    },
		// シフトパターン表示用勤務区分リスト取得
		getShiftpatternWorkpatterns() {
      let shiftpatternworkpatternList = {}
      this.office_staffs.forEach(office_staff => {
				if(!office_staff.office_id) return

				shiftpatternworkpatternList[office_staff.office_id] = []

				// workpatternsが登録済みなら
        if (office_staff.workpatterns.length) {
          office_staff.workpatterns.forEach(workpattern => {
            // 勤務カテゴリがholiday（公休／全休を除く）と半勤務（half_work）は除外
            if (['half_work'].includes(workpattern.type) ||
              (['holiday'].includes(workpattern.category) && workpattern.type !== 'public_holidays')) return

						shiftpatternworkpatternList[office_staff.office_id].push({
							workpattern : {
								workpattern_id: workpattern.id,
								workpattern_name: workpattern.name
							},
							workpattern_name: workpattern.name
            })
          })
        }

				// 勤務区分の先頭に空のオブジェクトを追加
				shiftpatternworkpatternList[office_staff.office_id].unshift({
					workpattern: {
						workpattern_id :'',
						workpattern_name :''
					},
					workpattern_name: '--',
				})
      })
      return shiftpatternworkpatternList
    },
		// 勤務区分名取得
		getWorkpatternCategoryJa (value) {
      return value
        ? this.codes.data.find(code => code.code === value)
        ? this.codes.data.find(code => code.code === value).ja
        : null
        : null
    },
		// 勤務種別名取得
		getWorkpatternTypeJa (value) {
      return value
        ? this.codes.data.find(code => code.code === value)
        ? this.codes.data.find(code => code.code === value).ja
        : null
        : null
    },
		// 該当の事業所以外の事業所リストを取得
		getOfficeListExcept(office_id) {
			return this.office_staffs.map(office_staff => {
				return office_staff.office_id !== office_id ? office_staff.office_id : null
			}).filter(Boolean);
		},
		/**
		 * 画面操作後のデータセット
		 */
		// 勤務区分リスト選択後（modalからの操作後）
    setShiftPatternWorkpatternList (...args) {
      const [value, office_staff] = args

			// 選択された勤務区分の取得（シフトパターンのセレクトボックス表示用）
			const workpattern_for_shift_pattern_list = this.setWorkpatternForShiftPatternList(value, office_staff)
			this.$set(this.workpattern_for_shift_pattern_list, office_staff.office_id, workpattern_for_shift_pattern_list)

      // 選択された勤務区分の取得（勤務区分一覧表用）
			const workpattern_list  = this.setWorkpatternList(value, office_staff)
			this.$set(this.workpattern_list, office_staff.office_id, workpattern_list)

			// office_staffsにworkpattern_listをセット
			this.office_staffs.forEach((before_office_staff, index) => {
				if (before_office_staff.office_id === office_staff.office_id) {
					this.$set(this.office_staffs[index], 'workpatterns', this.workpattern_list[office_staff.office_id])
				}
			})
		},
		// 選択された勤務区分の取得（シフトパターンのセレクトボックス表示用）
		setWorkpatternForShiftPatternList (value, office_staff) {
			const workpattern_for_shift_pattern_list =  this.workpatterns.data.map(workpattern => {
        if(!value.includes(workpattern.id)) {
          return
        }
        // 勤務カテゴリがholiday（公休／全休を除く）と半勤務（half_work）は除外
        if (['half_work'].includes(workpattern.type) ||
            (['holiday'].includes(workpattern.category) && workpattern.type !== 'public_holidays')) return

        return {
					workpattern: {
						workpattern_id :workpattern.id,
						workpattern_name :workpattern.name
					},
					workpattern_name: workpattern.name,
        }
      }).filter(Boolean)

			// 勤務区分の先頭に空のオブジェクトを追加
			workpattern_for_shift_pattern_list.unshift({
				workpattern: {
					workpattern_id :'',
					workpattern_name :''
				},
				workpattern_name: '--',
			})
			return workpattern_for_shift_pattern_list;
		},
		// 選択された勤務区分の取得（勤務区分一覧表用）
		setWorkpatternList(value, office_staff) {
			return this.workpatterns.data.map(workpattern => {
        if(!value.includes(workpattern.id)) {
          return
        }

				const defaultMonthlyWorkingCounts =  this.defaultMonthlyWorkingCounts[this.staffEmploymentType][workpattern.type][workpattern.category];

				const min_monthly_working_count = office_staff.workpatterns.find(({ id }) => id === workpattern.id)
					? office_staff.workpatterns.find(({ id }) => id === workpattern.id).working_count.min_monthly_working_count
					: defaultMonthlyWorkingCounts.min;

				const max_monthly_working_count = office_staff.workpatterns.find(({ id }) => id === workpattern.id)
					? office_staff.workpatterns.find(({ id }) => id === workpattern.id).working_count.max_monthly_working_count
					: defaultMonthlyWorkingCounts.max;

        return {
					id: workpattern.id,
					name: workpattern.name,
					type: this.getWorkpatternTypeJa(workpattern.type),
					category: this.getWorkpatternCategoryJa(workpattern.category),
					working_start_time: workpattern.working_start_time || '-',
					working_end_time: workpattern.working_end_time || '-',
					rest_start_time: workpattern.rest_start_time || '-',
					rest_end_time: workpattern.rest_end_time || '-',
					working_count : {
						min_monthly_working_count : min_monthly_working_count,
						max_monthly_working_count :	max_monthly_working_count,
					},
        }
      }).filter(Boolean)
		},
		// 他の事業所の勤務区分を該当の事業所にコピー
		copyOtherOfficeWorkpatterns (from_office_id, to_office_id) {
			// コピー先のoffice_staffオブジェクト
			let to_office_staff = this.office_staffs.find(office_staff => office_staff.office_id === to_office_id)
			// コピー元のoffice_staffオブジェクト
			let from_office_staff = this.office_staffs.find(office_staff => office_staff.office_id === from_office_id)

			// 該当の事業所に勤務区分をセット（objectのコピーのため、JSON.parse(JSON.stringify())でコピー）
			this.$set(to_office_staff, 'workpatterns', JSON.parse(JSON.stringify(from_office_staff.workpatterns)))

			// 該当の事業所にシフトパターンをセット（objectのコピーのため、JSON.parse(JSON.stringify())でコピー）
			this.$set(to_office_staff, 'shift_pattern', JSON.parse(JSON.stringify(from_office_staff.shift_pattern)))

			// 該当の事業所に最大労働時間をセット
			this.$set(to_office_staff, 'max_worktime_minutes', from_office_staff.max_worktime_minutes)

			// 該当の事業所に最小労働時間をセット
			this.$set(to_office_staff,'min_worktime_minutes',from_office_staff.min_worktime_minutes)

			// workpattern_listの更新
			this.workpattern_list = this.getWorkpatterns()
			this.workpattern_for_shift_pattern_list = this.getShiftpatternWorkpatterns()
		},
		// シフトパターンの勤務区分を変更
		changeShiftPattern (office_staff, day, workpattern) {
			this.$set(
				this.office_staffs.find(office_staff_data => office_staff_data.office_id === office_staff.office_id).shift_pattern,
				day,
				workpattern
			)
		},
		// 勤務区分の最小（最大）労働時間を変更
		changeWorkingCount (office_staff, workpattern) {
			office_staff.workpatterns.find(workpattern_data => workpattern_data.id === workpattern.id).working_count = workpattern.working_count
		},
		// 選択した勤務区分を削除
		deleteItem(office_staff, workpattern) {
			// 削除する勤務区分のインデックス
			const workpattern_index = office_staff.workpatterns.findIndex(workpattern_data => workpattern_data.id === workpattern.id)

			// 削除する勤務区分のインデックスが0以上の場合
			if (workpattern_index >= 0) {
				// 削除する勤務区分を削除
				office_staff.workpatterns.splice(workpattern_index, 1)
			}

			// workpattern_listの更新
			this.workpattern_list = this.getWorkpatterns()
			this.workpattern_for_shift_pattern_list = this.getShiftpatternWorkpatterns()
		},
		/**
		 * 表示・制御
		 */
		// 勤務区分モーダル表示
    showModalWorkpattern(i) {
      this.$refs['workpatternModal' + i][0].showModal();
    },
		// 事業所ごとのシフト設定制御
		activeShiftSetting (office_staff) {
			return (office_staff.office_id && (this.staffEmploymentType != null && this.staffEmploymentType != '')) ? false : true
		},
		// シフトパターン選択制御
		activeShiftPatternSelect (office_staff) {
			return this.activeShiftSetting(office_staff) && (office_staff.workpatterns.length ? false : true)
		},
		// 事業所選択ドロップダウン表示
		open (office_id) {
			this.office_staffs.forEach(office_staff => {
				if(office_staff.office_id !== office_id) {
					this.$refs['dropdown' + office_staff.office_id][0].style.display = 'none'
				}
			})

			if (this.$refs['dropdown' + office_id][0].style.display === 'block') {
				this.$refs['dropdown' + office_id][0].style.display = 'none';
			} else {
				this.$refs['dropdown' + office_id][0].style.display = 'block';
			}
		},
		// 半勤務、その他、有休（全休）、有休（半休）の時にはtrueを返す
		notSetMothlyWorkingCount (workpattern) {
			return ['半勤務', 'その他', '有休(全休)', '有休(半休)', '公休(半休)'].includes(workpattern.type)
		},
	},
	data() {
		return {
      staff_shift_list: Number(this.$cookie.get('staff_shift_list')),
			weekdays: { mon : '月', tue :'火', wed :'水', thu :'木', fri :'金', sat :'土', sun :'日', hol: '祝'},
			// 表データ
			footerProps: {
				'items-per-page-options': [10, 25, 50, 100, -1],
				'items-per-page-text' : '表示件数： ',
				'items-per-page-all-text' : 'すべて',
			},
			// 表ヘッダー（ユーザー情報列）
			list_headers: [
				{ text: '勤務区分名', value: 'name', align: 'start', sortable: false,},
				{ text: '勤務種別', value: 'type', width: '150px', sortable: false},
				{ text: '勤務カテゴリ', value: 'category', width: '150px', sortable: false},
				{ text: '勤務開始', value: 'working_start_time', width: '150px', sortable: false},
				{ text: '勤務終了', value: 'working_end_time', width: '150px', sortable: false},
				{ text: '休憩開始', value: 'rest_start_time', width: '150px', sortable: false},
				{ text: '休憩終了', value: 'rest_end_time', width: '150px', sortable: false},
				{ text: '勤務回数', value: 'working_count', width: '350px', sortable: false},
				{ text: '', value: 'actions', width: '130px', sortable: false},
			],
			workpattern_list : this.getWorkpatterns(),
			workpattern_for_shift_pattern_list : this.getShiftpatternWorkpatterns(),
			min_monthly_working_count: 0, // 月の最小労働日数初期値
			max_monthly_working_count: 31, // 月の最大労働日数初期値
		}
	},
	watch : {
    office_staffs : {
      handler (past, value) {
        // 事業所変更に伴う、workpatten_listとworkpattern_for_shift_pattern_listの更新
        this.workpattern_list = this.getWorkpatterns()
        this.workpattern_for_shift_pattern_list = this.getShiftpatternWorkpatterns()

        this.$emit("shift_setting", value, this.checkValidation)
      },
      deep :true
    },
		// workpattern_for_shift_pattern_listの変更を検知して
		// workpattern_for_shift_pattern_listに存在しない勤務区分をoffice_staffs.*.shift_patternから削除
		workpattern_for_shift_pattern_list: {
			handler (past, value) {
				this.office_staffs.forEach(office_staff => {
					Object.keys(office_staff.shift_pattern).forEach(weekday => {
						// 存在しない勤務区分をoffice_staffs.*.shift_patternから削除
						if(value[office_staff.office_id] && !value[office_staff.office_id].find(workpattern => workpattern.workpattern.workpattern_id === office_staff.shift_pattern[weekday].workpattern_id)) {
							this.$set(office_staff.shift_pattern[weekday], 'workpattern_id', '')
							this.$set(office_staff.shift_pattern[weekday], 'workpattern_name', '')
						}
					})
				})
			},
			deep :true
		},
	},
	mounted() {
		// office_staffsのshift_patternを初期化
		this.office_staffs.forEach(office_staff => {
			if(!office_staff.shift_pattern || !Object.keys(office_staff.shift_pattern).length) {
				this.$set(office_staff, 'shift_pattern' , {
					mon : { workpattern_id: '', workpattern_name: '' },
					tue : { workpattern_id: '', workpattern_name: '' },
					wed : { workpattern_id: '', workpattern_name: '' },
					thu : { workpattern_id: '', workpattern_name: '' },
					fri : { workpattern_id: '', workpattern_name: '' },
					sat : { workpattern_id: '', workpattern_name: '' },
					sun : { workpattern_id: '', workpattern_name: '' },
					hol : { workpattern_id: '', workpattern_name: '' }
				})
			} else {
				// office_staff.shift_patternの曜日データがない場合にはその曜日には初期値を入れる
				Object.keys(this.weekdays).map(weekday => {
					if(!office_staff.shift_pattern[weekday]) {
						this.$set(office_staff.shift_pattern, weekday, { workpattern_id: '', workpattern_name: '' })
					}
				})

				// 事業所ごとの勤務区分を取得
				const workpattern_for_shift_pattern_list = this.workpattern_for_shift_pattern_list

				// workpattern_for_shift_pattern_listに存在しない勤務区分をoffice_staffs.*.shift_patternから削除
				Object.keys(office_staff.shift_pattern).forEach(weekday => {
					// ある曜日のshift_patternが空の場合は初期値を入れる
					if(!office_staff.shift_pattern[weekday].workpattern_id) {
						this.$set(office_staff.shift_pattern[weekday], 'workpattern_id', '')
						this.$set(office_staff.shift_pattern[weekday], 'workpattern_name', '')
						return
					}

					// もしくは、すでに無効になったshift_patternの勤務区分IDをoffice_staffs.*.shift_patternから削除
					if (workpattern_for_shift_pattern_list[office_staff.office_id]
						&& !workpattern_for_shift_pattern_list[office_staff.office_id].find(
							workpattern => workpattern.workpattern.workpattern_id === office_staff.shift_pattern[weekday].workpattern_id
							)
						) {
						this.$set(office_staff.shift_pattern[weekday], 'workpattern_id', '')
						this.$set(office_staff.shift_pattern[weekday], 'workpattern_name', '')
						return
					}
				})
			}
		})
		this.$emit("shift_setting", this.office_staffs, this.checkValidation)
	},
}
</script>

<style>
.dropdown-menu {
	display :block;
}
.error-message {
	text-align: right;
  margin: 10px;
}
</style>
