<template>
  <div>
    <VuePerfectScrollbar style="font-size: 14px; min-height: calc(95vh - 270px); max-height: calc(95vh - 270px); width: 100%; overflow: auto;">
      <div style="font-size: 18px; font-weight: 600; border-left: solid 5px #02B28A; padding-left: 10px;">
        組合せ設定
        <b-button
          class="btn_shift_reset"
          style="color: #1059CC!important;"
          @click="help_combination"
        >
          <font-awesome-icon
            icon="circle-question"
            style="vertical-align: text-top; padding-left: 12px;"
          />
          ヘルプ
        </b-button>
      </div>
      <div style="padding: 30px 0px;">
        組合せ設定では、シフトに入る職員の組合せを指定することができます。<br />
        この設定を行うことで、より希望に近いシフトを自動作成することができます。<br />
        <span style="color: red;">組合せOKを設定するときは、職員①にベテラン職員、職員②に新人（OJTが必要な）職員を設定してください。</span><br />
        組合せNGを設定するときは、職員①、職員②のどちらにベテラン職員、新人（OJTが必要な）職員を設定しても構いません。
              <b-button
                class="btn_shift_reset"
                style="color: #1059CC!important;"
                @click="help_rookie"
              >
                <font-awesome-icon
                  icon="circle-question"
                  style="vertical-align: text-top; padding-left: 12px;"
                />
                新人とは
              </b-button>
      </div>
      <form>
        <table style="width: auto; border: none!important;">
          <tr>
            <td style="width: 200px; border-right: solid 1px #D2D6DB;"><span class="required">組合せる職員</span></td>
            <td>
              <div style="display: flex; padding-left: 20px;">
                <div>
                  <v-combobox
                    v-model="form.staff_1"
                    :items="combo_staffs"
                    item-value="office_staff_id"
                    item-text="name"
                    solo
                    hide-selected
                    hide-details
                    multiple
                    label="職員①（複数選択可）"
                    style="border: 1px solid #ced4da!important; width: 600px;"
                    :class="{'is-invalid': errors.staff_1}"
                    @blur="staff_change()"
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        color="#297ADB"
                        text-color="#FFFFFF"
                        :input-value="selected"
                      >
                        <span class="pr-2">
                          {{ item.name }}
                        </span>
                        <v-icon
                          color="#FFFFFF"
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                  <div v-if="errors.staff_1" class="text-danger">{{ errors.staff_1 }}</div>
                  <div v-if="errors.staffs" class="text-danger">{{ errors.staffs }}</div>
                </div>
                <div class="text-center" style="display: grid; color: #02B28A; align-items: center; padding: 0px 10px;"><font-awesome-icon icon="plus" /></div>
                <div>
                  <v-combobox
                    v-model="form.staff_2"
                    :items="combo_staffs"
                    item-value="office_staff_id"
                    item-text="name"
                    solo
                    hide-selected
                    hide-details
                    multiple
                    label="職員②（複数選択可）"
                    style="border: 1px solid #ced4da!important; width: 600px;"
                    :class="{'is-invalid': errors.staff_2}"
                    @blur="staff_change()"
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        color="#297ADB"
                        text-color="#FFFFFF"
                        :input-value="selected"
                      >
                        <span class="pr-2">
                          {{ item.name }}
                        </span>
                        <v-icon
                          color="#FFFFFF"
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                  <div v-if="errors.staff_2" class="text-danger">{{ errors.staff_2 }}</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="border-right: solid 1px #D2D6DB;"><span class="required">組合せ</span></td>
            <td style="padding: 10px 0px 10px 20px;">
              <b-form-radio-group class="conbinations-radio" v-model="form.is_valid" :class="{'is-invalid': errors.is_valid}">
                <b-form-radio :value="1" :class="{'is-invalid': errors.is_valid}" @change="is_valid_change()">OK</b-form-radio>
                <b-form-radio :value="0" style="margin-left:10px;" :class="{'is-invalid': errors.is_valid}" @change="is_valid_change()">NG</b-form-radio>
                <div v-if="errors.is_valid" class="text-danger"><small>{{ errors.is_valid }}</small></div>
              </b-form-radio-group>
            </td>
          </tr>
          <tr>
            <td style="border-right: solid 1px #D2D6DB;"><span class="required">対象勤務区分</span></td>
            <td style="padding: 10px 0px 10px 20px;">
              <v-combobox
                v-model="form.workpattern_list"
                :items="workpatterns"
                item-value="id"
                item-text="name"
                solo
                hide-selected
                hide-details
                multiple
                label="選択してください（複数選択可）"
                style="border: 1px solid #ced4da!important; width: 600px;"
                :class="{'is-invalid': errors.workpattern_list}"
                @blur="workpattern_list_change"
              >
              <template v-slot:no-data>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
              </v-combobox>
              <div v-if="errors.workpattern_list" class="text-danger"><small>{{ errors.workpattern_list }}</small></div>
            </td>
          </tr>
        </table>
        <div v-if="open_add === true">
          <b-button class="btn_shift_save" style="margin: 20px 0px;" @click="save(true, false)">保存</b-button>
          <span style="font-size: 12px; color: #707070; margin-left: 35px;" id="save_message" v-text="smessage">{{ smessage }}</span>
        </div>
        <div v-else>
          <input type="hidden" v-model="form.update_id" />
          <b-button class="btn_shift_save" style="margin: 20px 0px;" @click="edit(true, false)">編集</b-button>
          <span style="font-size: 12px; color: #707070; margin-left: 35px;" id="update_message"></span>
        </div>
        <div v-if="Object.keys(modified_combination_list).length > 0" style="margin:30px 0px 20px 0px;">組合せ一覧</div>
        <div
          style="background: #FAFAFA; padding: 20px 30px;"
          v-for='(combination, index) in modified_combination_list'
          :key="index"
        >
          <table class="setting_table">
            <tr>
              <th style="width: 200px;">
                <div style="display: flex; justify-content: space-between;">
                  <div>職員①</div>
                  <button class="sort_btn" style="display: none;">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" class="sort_active" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" /></span>
                  </button>
                </div>
              </th>
              <th style="width: 200px;">
                <div style="display: flex; justify-content: space-between;">
                  <div>職員②</div>
                  <button class="sort_btn" style="display: none;">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" class="sort_active" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" /></span>
                  </button>
                </div>
              </th>
              <th style="width: 200px;">組合せ</th>
              <th>対象勤務区分</th>
            </tr>
            <tr>
              <td>{{ getStaffName(combination.staff_1) }}</td>
              <td>{{ getStaffName(combination.staff_2) }}</td>
              <td>{{ combination.okng ? `OK` : `NG` }}</td>
              <td>{{ getWorkpatternNames(combination.workpatterns) }}</td>
            </tr>
          </table>
          <b-button class="edit_button" @click="editItem(combination)">編集</b-button>
          <b-button class="del_button" style="margin-top: 20px; margin-left: 10px;" @click="deleteItem(combination)">削除</b-button>
        </div>
      </form>
    </VuePerfectScrollbar>
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>
        <b-button class="btn_white btn_autocreatefooter" @click="save_close" :disabled="!is_disabled() || is_all_notentered">保存して閉じる</b-button>
      </div>
      <div>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_back()" :disabled="!is_disabled() || (!is_disabled() && !is_all_notentered)">戻る</b-button>
        <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_next()" :disabled="!is_disabled() || (!is_disabled() && !is_all_notentered)">次へ</b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-control-label::before {
  border-radius: 50px!important;
}
.v-select__selections {
  width: auto!important;
  min-width: 400px!important;
}
.setting_table {
  width: 100%;
}
.setting_table th {
  height: 45px;
  background: #F3F4F6;
  border: solid 1px #D2D6DB;
  padding: 0px 5px;
}
.setting_table td {
  height: 45px;
  background: #FFFFFF;
  border: solid 1px #D2D6DB;
  padding: 0px 5px;
}
.edit_button {
  color: #02B28A;
  background: #FFFFFF;
  text-align: center;
  border: 1px solid #D2D6DB;
  border-radius: 4px;
  padding: 6px 16px;
  margin-top: 20px;
}
.del_button {
  color: #707070;
  background: #FFFFFF;
  text-align: center;
  border: 1px solid #D2D6DB;
  border-radius: 4px;
  padding: 6px 16px;
}
</style>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios"

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    // 勤務区分一覧
    workpatterns: {
      type: Array,
      required: true
    },
    // 職員一覧（休職、退職除く）
    staffs: {
      type: Array,
      required: true
    },
    office: {
      type: Object,
      required: true
    },
    // 組合せ一覧
    combination_list: {
        type: Array,
        required: true
    },
    codes :{
      type : Array|Object,
      required :true
    },
  },
  computed: {
  },
  data() {
    return {
      modified_combination_list: this.combination_list,
      form: {
          staff_1: [],
          staff_2: [],
          workpattern_list: [],
          is_valid: '',
      },
      combo_staffs: [],
      open_add: true,
      smessage: '',
      now: '',
      errors: {},
      is_error_staff_1: false,
      is_error_staff_2: false,
      is_error_staffs: true,
      is_error_valid: false,
      is_error_workpattern: false,
      is_error_required: true,
      is_all_notentered: true,
      isSave: false,
    }
  },
  methods: {
    // 組合せ設定ヘルプ
    help_combination() {
      window.open('https://support.synchroshift.jp/portal/ja/kb/articles/1070', '_blank');
    },
    // 新人とはヘルプ
    help_rookie() {
      window.open('https://support.synchroshift.jp/portal/ja/kb/articles/1023', '_blank');
    },
    // 職員名取得
    getStaffName(office_staff_ids) {
      let staffs = this.staffs.filter(staff => office_staff_ids.includes(staff.office_staff_id));
      return staffs.map(staff => staff.lastname + ' ' + staff.firstname).join(', ');
    },
    // 勤務区分名取得
    getWorkpatternNames(workpattern_ids) {
      let workpatterns = this.workpatterns.filter(workpattern => workpattern_ids.includes(workpattern.id));
      return workpatterns.map(workpattern => workpattern.name).join(', ');
    },
    // エラーの初期処理
    initError() {
      this.is_error_staff_1 = true;
      this.is_error_staff_2 = true;
      this.is_error_staffs = true;
      this.is_error_valid = true;
      this.is_error_workpattern = true;
      this.is_error_required = true;
      this.is_all_notentered = true;
      this.$emit('setchildError', false);
    },
    // すべての項目の必須チェック
    checkRequired() {
      if (this.form.staff_1.length === 0 && this.form.staff_2.length === 0 && this.form.is_valid !== 0 && this.form.is_valid !== 1 && this.form.workpattern_list.length === 0) {
        this.is_error_required = true;
        this.is_all_notentered = true;
      } else if (this.form.staff_1.length > 0 && this.form.staff_2.length > 0 && (this.form.is_valid === 0 || this.form.is_valid === 1) && this.form.workpattern_list.length > 0) {
        this.is_error_required = true;
        this.is_all_notentered = false;
      } else {
        // ひとつでも入力ある、かつ他の未入力項目があればエラー
        this.is_error_required = false;
        this.is_all_notentered = false;
        this.$emit('setchildError', true);
      }

      // ナビゲーションボタン制御
      if (this.is_disabled() || (!this.is_disabled() && this.is_all_notentered)) {
        this.$emit('setchildError', false);
      }
    },
    // 職員の変更時
    staff_change() {
      // 組み合わせエラークリア
      this.s_error_staffs = true;
      this.$delete(this.errors, 'staffs');

      // 職員1 必須チェック
      if (this.form.staff_1.length > 0) {
        this.is_error_staff_1 = true;
        this.$delete(this.errors, 'staff_1');
      } else {
        this.is_error_staff_1 = false;
        this.$set(this.errors, 'staff_1', '職員1を選択してください。');
      }
      // 職員2 必須チェック
      if (this.form.staff_2.length > 0) {
        this.is_error_staff_2 = true;
        this.$delete(this.errors, 'staff_2');
      } else {
        this.is_error_staff_2 = false;
        this.$set(this.errors, 'staff_2', '職員2を選択してください。');
      }
      // 職員2との重複はNG
      if (this.is_error_staff_1 && this.is_error_staff_2) {
        let duplicates = this.form.staff_1.filter(id => this.form.staff_2.includes(id));
        if (duplicates.length === 0) {
          this.is_error_staffs = true;
          this.$delete(this.errors, 'staffs');
        } else {
          this.is_error_staffs = false;
          this.$set(this.errors, 'staffs', '同じ職員を選択することはできません。');
        }
      }
      // 他項目の入力チェック
      this.checkRequired();
    },
    // ON・NGの変更時
    is_valid_change() {
      if (this.form.is_valid === 0 || this.form.is_valid === 1) {
        this.is_error_valid = true;
        this.$delete(this.errors, 'is_valid');
      } else {
        this.is_error_valid = false;
        this.$set(this.errors, 'is_valid', '組合せは必ず指定してください。');
      }
      // 他項目の入力チェック
      this.checkRequired();
    },
    // 対象勤務区分変更時
    workpattern_list_change() {
      if (this.form.workpattern_list.length > 0) {
        this.is_error_workpattern = true;
        this.$delete(this.errors, 'workpattern_list');
      } else {
        this.is_error_workpattern = false;
        this.$set(this.errors, 'workpattern_list', '対象勤務区分を選択してください。');
      }
      // 他項目の入力チェック
      this.checkRequired();
    },
    // ボタンのDisabeld制御
    is_disabled() {
      let result = this.is_error_staff_1 && this.is_error_staff_2 && this.is_error_staffs && this.is_error_valid && this.is_error_workpattern && this.is_error_required;
      if (result) {
        if (Object.keys(this.errors).length > 0) {
          result = false;
        }
      }
      return result;
    },
    // すべての項目をチェック
    checkData() {
      this.staff_change();
      this.is_valid_change();
      this.workpattern_list_change();
    },
    // 保存して終了
    save_close() {
      // 保存処理
      if (this.open_add === true) {
        this.save(true, true);
      } else {
        this.edit(true, true);
      }
    },
    // 戻る
    save_back() {
      // 保存処理
      if (this.is_all_notentered) {
        this.$emit('nextStep', 7);
      } else {
        if (this.open_add === true) {
          this.save(false, false, 7);
        } else {
          this.edit(false, false, 7);
        }
      }
    },
    // 次へ
    save_next() {
      // 保存処理
      if (this.is_all_notentered) {
        this.$emit('nextStep', 9);
      } else {
        if (this.open_add === true) {
          this.save(false, false, 9);
        } else {
          this.edit(false, false, 9);
        }
      }
    },
    /**
     * 組み合わせの新規（追加）保存
     */
    save(message_flg = true, close_flg = true, nextstep = -1) {
      // 入力チェック
      this.checkData();
      if (Object.keys(this.errors).length > 0) {
        return;
      } else {
        this.$emit('setchildError', false);
      }

      // ローディング開始
      this.updateLoading(true);

      // フォームのデータ取得
      var formData = new FormData();
      formData.append('staff_1',JSON.stringify(this.form.staff_1));
      formData.append('staff_2',JSON.stringify(this.form.staff_2));
      formData.append('workpattern_list', JSON.stringify(this.form.workpattern_list));
      formData.append('is_valid',this.form.is_valid);
      formData.append('office_id',this.office.id);

      // エラークリア
      this.s_error_staffs = true;
      this.$delete(this.errors, 'staffs');

      // 更新処理
      let url = this.$route('shifts.combinations.save');
      axios.post(url,formData).then(response =>{
        let results = response.data.results;
        this.updateLoading(false);
        if(results.status !== "success"){
            this.$swal({
              title: "保存に失敗しました。",
              icon: 'error',
            });
        }else{
          if(message_flg) {
            this.$swal({
              title: "保存しました",
              icon: 'success',
            });
          }
          this.modified_combination_list = results.combination_list;
          this.$emit('getCombinationData');
          this.form.staff_1 = '';
          this.form.staff_2 = '';
          this.form.is_valid = '';
          this.form.workpattern_list = '';
          this.form.update_id = '';
          this.errors = {};
          this.now = new Date().toLocaleString();
          this.smessage = "保存しました。 "+ this.now;

          // 変更フラグクリア
          this.isSave = true;
          this.$emit('setChange', false);

          if (nextstep > -1) {
            // 画面遷移
            this.$emit('nextStep', nextstep);
          } else if (close_flg) {
            // モーダル画面終了
            this.$emit('closeDialog');
          } else {
            // エラー初期化
            this.initError();
          }
        }
      })
      .catch(error => {
        this.updateLoading(false);
        // エラーフラグをON
        this.is_error_staffs = false;
        // 出力メッセージ作成
        let errorMessages = new Set();
        let errors = error.response.data.errors;
        for (let key in errors) {
          if (errors.hasOwnProperty(key)) {
            let messages = errors[key];
            messages.forEach(message => errorMessages.add(message));
          }
        }
        let errorMessage = [...errorMessages].join(' ');
        // エラー設定
        this.$set(this.errors, 'staffs', errorMessage);
        this.updateLoading(false);
      });
    },
    /**
     * 表の操作(保存)
     */
    edit(message_flg = true, close_flg = true, nextstep = -1) {
      this.updateLoading(true);
      var formData = new FormData();
      formData.append('staff_1',JSON.stringify(this.form.staff_1));
      formData.append('staff_2',JSON.stringify(this.form.staff_2));
      formData.append('workpattern_list', JSON.stringify(this.form.workpattern_list));
      formData.append('is_valid',this.form.is_valid);
      formData.append('update_id',this.form.update_id);
      formData.append('office_id',this.office.id);

      let url = this.$route('shifts.combinations.save');

      axios.post(url,formData).then(response =>{
        let results = response.data.results;
        this.updateLoading(false);
        if(results.status !== "success"){
          this.$swal({
            title: "編集に失敗しました。",
            icon: 'error',
          });
        }else{
          if(message_flg) {
            this.$swal({
              title: "編集しました",
              icon: 'success',
            });
          }
          this.modified_combination_list = results.combination_list;
          this.$emit('getCombinationData');
          this.open_add = true;
          this.form.staff_1 = '';
          this.form.staff_2 = '';
          this.form.is_valid = '';
          this.form.workpattern_list = '';
          this.form.update_id = '';
          this.now = new Date().toLocaleString();
          this.smessage = "編集しました。 "+ this.now;
          this.errors = {};

          // 変更フラグクリア
          this.isSave = true;
          this.$emit('setChange', false);

          if (nextstep > -1) {
            // 画面遷移
            this.$emit('nextStep', nextstep);
          } else if (close_flg) {
            // モーダル画面終了
            this.$emit('closeDialog');
          } else {
            // エラー初期化
            this.initError();
          }
        }
      })
      .catch(error => {
        this.updateLoading(false);
        let errors = error.response.data.errors;
        this.errors = errors;
      });
    },

    /**
     * 表の操作(編集)
     */
    editItem (combination) {
      this.open_add = false;
      this.updateLoading(true);
      let url = this.$route('shifts.combinations.edit', combination.id);
      axios.get(url).then(response =>{
        let results = response.data.results;
        if(results.status === "success"){
          this.open_add = false;
          this.form.is_valid = results.combinations.is_valid;
          this.form.workpattern_list = results.work_list;
          this.form.update_id = results.combinations.id;

          let staffs_1 = this.staffs.filter(staff => results.combinations.office_staff_a_ids.includes(staff.office_staff_id));
          const staff_1_list = [];
          for (let staff_index in staffs_1) {
            staff_1_list.push(
              {
                id: staffs_1[staff_index].id,
                office_staff_id: staffs_1[staff_index].office_staff_id,
                name: staffs_1[staff_index].lastname + ' ' + staffs_1[staff_index].firstname
              }
            );
          }
          this.form.staff_1 = staff_1_list;

          let staffs_2 = this.staffs.filter(staff => results.combinations.office_staff_b_ids.includes(staff.office_staff_id));
          const staff_2_list = [];
          for (let staff_index in staffs_2) {
            staff_2_list.push(
              {
                id: staffs_2[staff_index].id,
                office_staff_id: staffs_2[staff_index].office_staff_id,
                name: staffs_2[staff_index].lastname + ' ' + staffs_2[staff_index].firstname
              }
            );
          }
          this.form.staff_2 = staff_2_list;
        }

        // 入力チェック
        this.checkData();
        if (Object.keys(this.errors).length > 0) {
          return;
        } else {
          this.$emit('setchildError', false);
        }
      });

      this.updateLoading(false);
    },
    /**
     * 表の操作(削除)
     */
    deleteItem (item) {
      this.$swal({
          title: "本当に削除しますか？",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
      }).then(result => {
          if (result.value) {
            this.updateLoading(true);
            let url = this.$route('shifts.combinations.delete', {office_id:this.office.id,combination_id:item.id});
            axios.delete(url).then(response =>{
              let results = response.data.results;
              this.updateLoading(false);
              if(results.status !== "success"){
                this.$swal({
                  title: "削除に失敗しました。",
                  icon: 'error',
                });
              }else{
                this.$swal({
                  title: "削除しました",
                  icon: 'success',
                });
                this.modified_combination_list = results.combination_list;
                this.$emit('getCombinationData');
                this.errors = {};
                this.open_add = true;
                this.form.staff_1 = '';
                this.form.staff_2 = '';
                this.form.is_valid = '';
                this.form.workpattern_list = '';
                this.form.update_id = '';
                this.now = new Date().toLocaleString();
                this.smessage = "削除しました。 "+ this.now;

                // エラー初期化
                this.initError();

                // 変更フラグクリア
                this.isSave = true;
                this.$emit('setChange', false); 
            }
          });
        }
      })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  mounted() {
    this.updateLoading(false);
    // 職員リスト用データ作成
    for (let staff_index in this.staffs) {
      this.combo_staffs.push(
        {
          id: this.staffs[staff_index].id,
          office_staff_id: this.staffs[staff_index].office_staff_id,
          name: this.staffs[staff_index].lastname + ' ' + this.staffs[staff_index].firstname
        }
      );

      // エラー初期化
      this.initError();
    }
    window.onload = ()=>{
      this.form.staff_1 = '';
      this.form.staff_2 = '';
      this.form.is_valid = '';
      this.form.workpattern_list = '';
      this.form.update_id = '';
      this.errors = {};
    }
  },
  watch: {
    form : {
      handler (value) {
        if (this.isSave) {
          this.isSave = false;
        } else {
          this.$emit('setChange', true);
        }
      },
      deep: true
    },
    errors: {
      handler: function () {
        if (Object.keys(this.errors).length > 0) {
          let error_message = Object.values(this.errors).join('<br>');;
          this.$emit('setchildError', !this.is_disabled(), error_message);
        } else {
          this.$emit('setchildError', !this.is_disabled());
        }
      },
    },
    combination_list (newVal) {
      this.modified_combination_list = newVal;
    },
  }
}
</script>
