<template>
  <div id="occupation_area">
    <div v-for="(occupation, index) in staff_occupations" :key="index" id="occupation_row_1" class="input_row" style="padding-bottom: 16px;">
      <label for="name">職種{{ index + 1 }}</label>
      <div>
        <div class="input_row">
          <v-autocomplete
              v-model="occupation.id"
              :items="occupation_list"
              @input="handleSelectChangedOccupation()"
              class="officeControl autocomplete-style"
              style="width: 270px; min-width: 270px;"
              item-text="text"
              item-value="value"
              hide-details="true"
              placeholder="選択してください"
              no-data-text="職種が見つかりません。"
              dense
              filled
            ></v-autocomplete>
          <button v-if="index !== 0" class="btn_add" @click="removeOccupation(index)" style="width: 84px; margin: 2px 0px 2px 12px;"><i class="pe-7s-trash"></i>削除</button>
        </div>
        <!-- error message -->
        <div v-if="office_staff.office_id || (form_errors['occupations'] && form_errors['occupations'].length)">
          <div v-if="!occupation.id" class="invalid-feedback">職種の選択は必ず指定してください。</div>
          <div v-else class="invalid-feedback"></div>
        </div>
      </div>
    </div>
    <div class="input_row">
      <button class="btn_add" @click="addOccupation"><span>＋</span>職種を追加</button>
    </div>
    <p v-if="!duplicateOccupationCheck" class="text-danger">同じ職種が選択されています。</p>
  </div>
</template>

<script>
export default {
  name: "Occupation",
  props:{
    // 事業所ごとのスタッフ情報
    office_staff :{
      type : Object,
      required :false
    },
    // 職種リスト
    occupations :{
      type : Object,
      required :true
    },
    // 職種タブ番号
    tabNo : {
      type: Number,
    },
    errors: {
      type: Object,
      required: false,
    },
    // エラーメッセージ
    form_errors: {
      type: Object,
      required: false,
    },
  },
  computed : {
    // 残りの職種リスト
    remainingOccupations () {
      // 職種リスト
      return this.occupations.data.map(occupation => {
        // 残りの職種
        let remain = null
        for(const staff_occupation of this.staff_occupations) {
          if(staff_occupation.id === occupation.id) {
            remain = null;
            break;
          }
          remain = occupation
        }
        return remain
      }).filter(Boolean) // nullになってるものを削除
    },
    // 職種が一つでも未選択であったらfalse
    notSelectedOccpationCheck () {
      return this.staff_occupations.every(staff_occupation => staff_occupation.id)
    },
    // 職種の重複選択チェック
    checkValidation () {
      return this.duplicateOccupationCheck && this.notSelectedOccpationCheck
    },
  },
  data() {
    return {
      // 送信用職種情報
      staff_occupations: this.office_staff.occupations.length ?
      this.office_staff.occupations
      : [
        {
          percentage:100,
          main_flg:true,
          id:'',
        }
      ],
      // 全職種リスト
      occupation_list : this.occupations.data.map(occupation => {
        return {
          text: occupation.name,
          value: occupation.id
        }
      }),
      // 重複職種選択用フラグ
      duplicateOccupationCheck: false,
    }
  },
  methods:{
    /**
     * セレクトボックス選択時
     */
    // 職種追加ボタン
    addOccupation() {
      this.staff_occupations.push({
        percentage:0,
        main_flg:false,
        id:''
      })
    },
    // 職種削除ボタン
    removeOccupation(index) {
      this.staff_occupations.splice(index, 1);
      this.handleSelectChangedOccupation();
      this.$emit("occupations", this.staff_occupations, this.tabNo, this.checkValidation)
    },
    // 職種選択時
    handleSelectChangedOccupation () {
      const staff_occupation_ids = this.staff_occupations.map(staff_occupation => staff_occupation.id)

      for(const staff_occupation_id of staff_occupation_ids) {
        const firstIndex = staff_occupation_ids.indexOf(staff_occupation_id);
        const lastIndex = staff_occupation_ids.lastIndexOf(staff_occupation_id);

        // 重複している場合
        if(firstIndex !== lastIndex){
          this.duplicateOccupationCheck = false
          break
        }
        // 重複していない場合
        this.duplicateOccupationCheck = true
      }
    },
  },
  watch : {
    staff_occupations :  {
      handler (past, value) {
        this.$emit("occupations", value, this.tabNo, this.checkValidation)
      },
      deep :true
    },
  },
  mounted () {
    this.staff_occupations = this.office_staff.occupations.length
      ? this.office_staff.occupations
      : this.staff_occupations

      // 表示時にバリデーションチェック
      this.handleSelectChangedOccupation();
      this.$emit("occupations", this.staff_occupations, this.tabNo, this.checkValidation)
  },
}
</script>
