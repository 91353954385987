<template>
  <div v-if="lock_type > 0" :class="lock_mark">
    <!-- lock_modeでフォントカラー変更 -->
    <font-awesome-icon v-if="lock_type === 1" icon="fa-lock" style="color: #6c757d;" />
    <font-awesome-icon v-else icon="fa-lock" style="color: #9ea9b3;" />
  </div>
  <div v-else>
    <v-tooltip top v-if="hope_type > 0" content-class="hope-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" :class="hope_mark">希</div>
      </template>
      <span>{{ reason }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ShiftsHopeReason',
  components: {
  },
  props: {
    // 0:鍵なし 1:鍵：リセット不可, 2:鍵：リセット可
    lock_type: { type: Number, default: 0 },
    // 0:initial 1:希望休（公休） 2:希望休（有休） 3:勤務
    hope_type: { type: Number, default: 0 },
    // true:編集モード, false:通常モード
    edit_mode: { type: Boolean, default: false },
    reason: { type: String, default: '' },
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    // 希のマーククラス取得
    hope_mark() {
      let edit_class = this.edit_mode ? '-edit' : '';

      if (this.hope_type === 1 || this.hope_type === 3) {
        return 'day-off-mark' + edit_class;
      } else if (this.hope_type === 2) {
        return 'paid-holiday-mark' + edit_class;
      } else {
        return '';
      }
    },
    // 鍵のマーククラス取得
    lock_mark() {
      let edit_class = this.edit_mode ? '-edit' : '';

      return 'lock-mark' + edit_class;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed () {
  },
}
</script>
