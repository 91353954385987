<template>
  <div style="font-size: 14px;">
    <div>
      <a class="link_text" @click="gotoList">役職マスタ</a>
      <span style="color: #D2D6DB; margin: 0px 10px;">></span>
      <span style="color: #6C737F">役職の追加</span>
    </div>
    <h4 style="font-weight: 700; padding-top: 24px;">役職の追加</h4>
    <p style="color: #384250; padding-top: 16px;">職員に対して設定する役職を登録してください。<br />役職は所属する事業所ごとに異なる設定（事業所1が管理者、事業所2がホーム長）が可能です。</p>
    <form style="margin: 0px; padding: 0px">
      <div class="positions-area">
        <div class="input_row">
          <label for="office_id" class="required">役職名</label>
          <div>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              style="min-width: 540px; max-width: 540px;"
              maxlength="255"
              id="position_name"
              placeholder="役職名を入力してください。"
              @keydown.enter.stop="addPosition"
              @blur="check_name"
            >
            <div v-if="position_error" class="invalid-feedback" style="display: block;">{{ position_error }}</div>
          </div>
        </div>
      </div>
      <b-button :class='[checkValidation ? "btn_save" : "btn_save_disabled"]' :disabled="!checkValidation" @click="addPosition">保存</b-button>
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    position_name: String
  },

  data: function() {
    return {
      form: {
        name: ''
      },
      position_error: null,
      name_validationcheck: false,
    }
  },

  created: function() {
    this.form.name = this.position_name
  },

  mounted: function() {
    this.updateLoading(false);
  },

  computed: {
    checkValidation() {
      return this.name_validationcheck;
    },
  },

  methods: {
    check_name() {
      if (this.form.name !== null && this.form.name !== '' && this.form.name != undefined) {
        this.name_validationcheck = true;
        this.position_error = "";
      } else {
        this.name_validationcheck = false;
        this.position_error = '役職名は必ず指定してください。';
      }
    },
    gotoList: function () {
      this.updateLoading(true);
      this.$inertia.get(this.$route('positions.list'));
    },
    addPosition: function () {
      event.preventDefault()
      this.updateLoading(true);
      const axios = require('axios')
      axios
        .post(this.$route('positions.do-add'), this.form)
        .then((res) => {
          this.updateLoading(false);
          if (res.status === 200) {
            if (res.data.status === 200) {
              this.$inertia.get(route('positions.list'))
              this.$swal.fire({
                icon: 'success',
                title: '保存しました'
              })
            } else {
              this.position_error = res.data.message
            }
          } else {
            throw res.status 
          }
        })
        .catch((err) => {
          this.updateLoading(false);
          this.$swal.fire({
            icon: 'error',
            text: '役職の新規追加に失敗しました。しばらくたってから再試行してください。'
          })
          .then(res => {
            location.reload()
          })
        })
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  }
}
</script>
