<template>
  <div style="font-size: 14px;">
    <div style="margin-bottom: 24px;">
      <span class="link_text" @click="cancel">事業所管理</span>
      <span style="color: #D2D6DB; margin: 0px 10px;">></span>
      <span style="color: #6C737F">事業所の複製</span>
    </div>
    <h4 style="margin-bottom: 16px;">事業所の複製</h4>
    <p style="color: #384250; margin-bottom: 32px; padding: 0px;">
      シフトを利用する事業所の情報を登録してください。<br>
      事業所ごとのシフト設定も確認しておきましょう。
    </p>
    <form @submit.prevent="save">
      <div class="office_area">
        <h5>概要</h5>
        <div class="input_row">
          <label for="office_name" class="required">事業所名</label>
          <div>
            <input
              v-model="form.name"
              name="office_name"
              type="text"
              class="form-control"
              style="width: 560px;"
              placeholder=""
              :class="{'is-invalid': errors.name}"
              @input="checkName"
           />
            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="office_id" class="required">介護保険事業所番号</label>
          <div>
            <input
              v-model="form.number"
              type="text"
              class="form-control"
              style="width: 560px;"
              placeholder=""
              :class="{'is-invalid': errors.number}"
              @input="checkNumber"
            />
            <div v-if="errors.number" class="invalid-feedback">{{ errors.number }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="service" class="required">サービス種別</label>
          <div>
            <v-autocomplete
              v-model="form.service_type_id"
              :items="service_types_list"
              :class="{'is-invalid': errors.service_type_id}"
              class="autocomplete-style"
              style="width: 560px;"
              item-text="text"
              item-value="value"
              hide-details="true"
              no-data-text="サービス種別が見つかりません。"
              dense
              filled
              @change="handleSelectChangedSerciceType"
              @blur="checkServiceTypeId"
            ></v-autocomplete>
            <div v-if="errors.service_type_id" class="invalid-feedback">{{ errors.service_type_id }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="start_nendo" class="required">年度開始日</label>
          <div>
            <div class="input_row">
              <b-form-select
                v-model="form.year_start_month"
                name="year_start_month"
                class="form-control"
                style="width: 90px;"
                :options="year_start_month_placeholder"
                :class="{'is-invalid': errors.year_start_month}"
              >
                <option
                  v-for="item in year_start_month_options"
                  :value="item"
                  :key="item"
                >
                  {{ item }}
                </option>
              </b-form-select>
              <p class="after_label">月</p>
              <b-form-select
                v-model="form.year_start_day"
                name="year_start_day"
                class="form-control"
                style="width: 90px;"
                :options="year_start_day_placeholder"
                :class="{'is-invalid': errors.year_start_day}"
              >
                <option
                  v-for="item in year_start_day_options"
                  :value="item"
                  :key="item"
                >
                  {{ item }}
                </option>
              </b-form-select>
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.year_start_month" class="invalid-feedback">{{ errors.year_start_month }}</div>
            <div v-if="errors.year_start_day" class="invalid-feedback">{{ errors.year_start_day }}</div>
          </div>
        </div>
        <!-- 公休数 -->
        <PaidHolidays
          v-model="form"
          :errors="errors"
        />
      </div>
      <div class="office_area">
        <h5>外部連携</h5>
        <!-- 外部連携コンポーネント -->
        <ExternalKintai
          :external_kintai="office.data.office_external_kintai"
          :external_kintai_name_list="external_kintai_name_list"
          :backend_errors="errors"
          style="width: 100%;"
          @update_external_kintai="update_external_kintai"
        />
      </div>
      <div class="office_area">
        <h5>希望申請の設定</h5>
        <div class="input_row">
          <label for="deadline" class="required">希望の締切り</label>
          <div>
            <div class="input_row">
              <b-form-select
                v-model="form.request_deadline"
                :options="request_deadlines"
                @change="handleSelectChangedHolidayRequestDeadline"
                :class="{'is-invalid': errors.request_deadline}"
                style="width: 90px;"
              />
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.request_deadline" class="invalid-feedback">{{ errors.request_deadline }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="form.holiday_staff_limit">希望休取得可能数</label>
          <div style="width: calc(100% - 200px)!important;">
            <table class="office_table">
              <tr>
                <th>基準</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
                <th>日</th>
                <th>祝</th>
              </tr>
              <tr>
                <td v-for="(name, day, i) in day_of_weeks" :key="i" style="width: calc(100% / 12);">
                  <input
                    type="number"
                    v-model="form.holiday_staff_limit[day]"
                    class="form-control"
                    min="0"
                    max="31"
                    style="margin-right: 8px;"
                    @input="holidayStaffLimitFormStrToNum(day)"
                  />
                </td>
              </tr>
            </table>
            <p class="explain_label" style="padding: 0px!important; margin: 8px 0px 0px 0px!important;">
              曜日あたりの休暇人数の上限を設定します。<br>
              「基準」の値を設定すると、「月〜祝」の値に同じ設定が反映されます。<br>
              「月〜祝」に値が設定されている場合は、「基準」の値が設定されていても「月〜祝」の値が優先されます。<br>
            </p>
          </div>
        </div>
        <div class="input_row">
          <label for="receptions" class="required">希望休の受付日数</label>
          <div>
            <div class="input_row">
              <p class="after_label" style="padding-left: 0px!important">毎月</p>
              <b-form-select
                v-model="form.holiday_request_max"
                :options="holiday_request_max_list"
                :class="{'is-invalid': errors.holiday_request_max}"
                :disabled="form.request_unlimited"
                style="width: 90px;"
                @change="handleSelectChangedHolidayRequestMax"
                @blur.native="checkHolidayRequestMax"
              />
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.holiday_request_max" class="invalid-feedback">{{ errors.holiday_request_max }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="receptions" class="required">希望勤務の受付日数</label>
          <div>
            <div class="input_row">
              <p class="after_label" style="padding-left: 0px!important">毎月</p>
              <b-form-select
                v-model="form.workpattern_request_max"
                :options="workpattern_request_max_list"
                :class="{'is-invalid': errors.workpattern_request_max}"
                :disabled="form.request_unlimited"
                style="width: 90px;"
                @change="handleSelectChangedWorkpatternRequestMax"
                @blur.native="checkWorkpatternRequestMax"
              />
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.workpattern_request_max" class="invalid-feedback">{{ errors.workpattern_request_max }}</div>
          </div>
        </div>
        <div class="input_row">
          <label class="dummy_label"></label>
          <div>
            <div class="input_row">
              <b-form-checkbox
                v-model="form.request_unlimited"
                class="checkbox"
                @change="request_unlimited_change"
              />
              <p class="radio_label">無制限にする</p>
            </div>
          </div>
        </div>
      </div>
      <div class="office_area">
        <h5>シフト作成に関するオプション設定</h5>
        <!-- 設定項目 -->
        <Rule
          @office_rules="setRules"
          @shift_work_type="setShiftWorkType"
          :rules="rules.data"
          :office="office.data"
          :codes="codes.data"
        />
      </div>
      <WorkConditions
        :form="form"
        :errors="errors"
      />
      <!-- error message -->
      <div v-if="errors.office" class="text-danger">
        保存時エラー：{{ errors.office }}
      </div>
      <b-button :disabled="!checkValidation" type="submit" variant="primary" class="btn_save" block>保存</b-button>
    </form>
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";
import timepicker from "../Components/MyTimePicker";
import Rule from './Rule/Rule.vue';
import ExternalKintai from "./ExternalKintai/ExternalKintai.vue";
import PaidHolidays from '@/Pages/Offices/PaidHolidays.vue';
import WorkConditions from '@/Pages/Offices/WorkConditions.vue';

export default {
  components: {
    datepicker,
    timepicker,
    Rule,
    ExternalKintai,
    WorkConditions,
    PaidHolidays,
  },
  props: {
    office_latest_number : {
      type : Number,
      required :true
    },
    office: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    service_types :{
      type : Object,
      required :true
    },
    codes :{
      type : Object,
      required :true
    },
    lodgment : {
      type : Object,
      required :true
    },
    rules :{
      type : Object,
      required :true
    },
    external_kintai_name_list :{
      type : Array,
      required :true
    },
  },
  computed: {
    // 希望休 締切り（毎月）
    request_deadlines() {
      return this.codes.data.filter(function (code) {
        return code.type === "request_deadline";
      }).map(request_deadlines_code => {
        return {
          text: request_deadlines_code.code,
          value: request_deadlines_code.code
        }
      });
    },
    // 希望休受付日数
    holiday_request_max_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "holiday_request_max";
      }).map(holiday_request_max_code => {
        return {
          text: holiday_request_max_code.code,
          value: holiday_request_max_code.code
        }
      });
    },
    /**
     * 希望勤務の受付日数回数のセレクトボックスのリスト
     */
     workpattern_request_max_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "workpattern_request_max";
      }).map(workpattern_request_max_code => {
        return {
          text: workpattern_request_max_code.code,
          value: workpattern_request_max_code.code
        }
      });
    },
    // 勤務カテゴリー（祝日以外）
    work_category_list() {
      return this.codes.data.filter(function (code) {
        return (code.type === "work_category" && code.code !== "holiday");
      });
    },
    // サービス種別
    service_types_list () {
      return this.service_types.data.map(service_type => {
        return {
          text: service_type.name,
          value: service_type.id
        }
      })
    },
    // 年間所定休日数
    yearly_paid_holidays() {
      let sum = 0;

      if(!this.form.monthly_paid_holidays) {
        this.form.monthly_paid_holidays = this.monthly_paid_holidays
      }
      Object.keys(this.form.monthly_paid_holidays).map((key) => {
        sum = sum + Number(this.form.monthly_paid_holidays[key]);
      });
      return sum;
    },
    // 夜勤回数アラート or 明番回数アラートの回数
    work_category_alert_count_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "work_category_alert_count";
      }).map(work_category_alert_count_code => {
        return {
          text: work_category_alert_count_code.ja,
          value: work_category_alert_count_code.code
        }
      });
    },
    /**
     * ルールIDを取得（保存時の並び替え用）
     */
    // 回避カテゴリーのrule.id
    ruleIdOfAvoidPattern () {
      return this.rules.data.filter(rule => rule.constraint_no === 1004)[0].id;
    },
    /**
     * バリデーションエラーの有無
     */
     checkValidation() {
      return this.nameValidationCheck
        && this.numberValidationCheck
        && this.externalKintaiValidationCheck
        && this.form.service_type_id !== null
        && this.form.year_start_month
        && this.form.year_start_day
        && this.form.request_deadline
        && this.holiday_request_validationcheck
        && this.workpattern_request_validationcheck
    },
  },
  data() {
    return {
      // form要素
      form: Inertia.form(this.office.data),
      day_of_weeks : { def : '基準', mon : '月', tue : '火', wed : '水', thu : '木', fri : '金', sat : '土', sun : '日', hol : '祝' },
      holiday_staff_limit: { def : 3, sun : 0, mon : 0, tue : 0, wed : 0, thu : 0, fri : 0 ,sat : 0, hol : 0 },
      year_start_month_placeholder: [{value: null, text: "月", disabled: true}],
      year_start_month_options: [...Array(12)].map((_, i) => i + 1),
      year_start_day_placeholder: [{value: null, text: "日", disabled: true}],
      year_start_day_options: [...Array(31)].map((_, i) => i + 1),
      nameValidationCheck: false,
      numberValidationCheck: false,
      externalKintaiValidationCheck: true,
      holiday_request_validationcheck: true,
      workpattern_request_validationcheck: true,
    };
  },
  methods: {
    /** 事業所名 入力チェック **/
    checkName() {
      let check_string = this.form.name;
      if (check_string !== '' && check_string !== null) {
        this.nameValidationCheck = true;
        this.$delete(this.errors, 'name');
      } else {
        this.nameValidationCheck = false;
        this.$set(this.errors, 'name', '事業所名は必ず指定してください。');
      }
    },
    /** 介護保険事業所番号 入力チェック **/
    checkNumber() {
      let check_string = this.form.number;
      if (check_string === '' || check_string === null) {
        this.numberValidationCheck = false;
        this.$set(this.errors, 'number', '介護保険事業所番号は必ず指定してください。');
        return;
      }
      if (/^[A-Za-z0-9]*$/.test(check_string)) {
        this.numberValidationCheck = true;
        this.$delete(this.errors, 'number');
      } else {
        this.numberValidationCheck = false;
        this.$set(this.errors, 'number', '介護保険事業所番号は半角英数字で入力してください。');
      }
    },
    /** サービス種別 入力チェック **/
    checkServiceTypeId() {
      let check_string = this.form.service_type_id;
      if (check_string === '' || check_string === null) {
        this.$set(this.errors, 'service_type_id', 'サービス種別は必ず指定してください。');
      } else {
        this.$delete(this.errors, 'service_type_id');
      }
    },
    /** 希望休の受付日数 入力チェック **/
    checkHolidayRequestMax() {
      if (this.form.holiday_request_max === null) {
        if (this.form.request_unlimited === false || this.form.request_unlimited === undefined) {
          this.holiday_request_validationcheck = false;
          this.$set(this.errors, 'holiday_request_max', '希望休の受付日数は必ず指定してください。');
        } else {
          this.holiday_request_validationcheck = true;
          this.$delete(this.errors, 'holiday_request_max');
        }
      } else {
        this.holiday_request_validationcheck = true;
          this.$delete(this.errors, 'holiday_request_max');
      }
    },
    /** 希望勤務の受付日数 入力チェック **/
    checkWorkpatternRequestMax() {
      if (this.form.workpattern_request_max === null) {
        if (this.form.request_unlimited === false || this.form.request_unlimited === undefined) {
          this.workpattern_request_validationcheck = false;
          this.$set(this.errors, 'workpattern_request_max', '希望勤務の受付日数は必ず指定してください。');
        } else {
          this.workpattern_request_validationcheck = true;
          this.$delete(this.errors, 'workpattern_request_max');
        }
      } else {
        this.workpattern_request_validationcheck = true;
          this.$delete(this.errors, 'workpattern_request_max');
      }
    },
    // セレクトボックス選択時にセット（希望休 締切り（毎月））
    handleSelectChangedHolidayRequestDeadline (val) {
      this.form.request_deadline = val
    },
    // セレクトボックス選択時にセット（希望休受付回数）
    handleSelectChangedHolidayRequestMax (val) {
      this.form.holiday_request_max = val
    },
    // セレクトボックス選択時にセット（希望勤務受付回数）
    handleSelectChangedWorkpatternRequestMax (val) {
      this.form.workpattern_request_max = val
    },
    // 希望休受付回数 無制限
    request_unlimited_change() {
      this.holiday_request_validationcheck = true;
      this.workpattern_request_validationcheck = true;
      this.$delete(this.errors, 'holiday_request_max');
      this.$delete(this.errors, 'workpattern_request_max');
      if (!this.form.request_unlimited) {
        if (this.form.holiday_request_max === null) {
          this.holiday_request_validationcheck = false;
          this.$set(this.errors, 'holiday_request_max', '希望休の受付日数は必ず指定してください。');
        }
        if (this.form.workpattern_request_max === null) {
          this.workpattern_request_validationcheck = false;
          this.$set(this.errors, 'workpattern_request_max', '希望勤務の受付日数は必ず指定してください。');
        }
      }
    },
    // セレクトボックス選択時にセット（サービスタイプ）
    handleSelectChangedSerciceType (val) {
      this.form.service_type_id = val
    },
    // Ruleコンポーネントからの戻りobject
    setRules (value) {
      this.form.rules = value;
    },
    setShiftWorkType (value) {
      this.form.shift_work_type = value;
    },
    // 希望休取得可能数を文字列から数値に変換
    monthlyHolidayLimitFormStrToNum(key) {
      this.form.holiday_staff_limit[key] = Number(this.form.holiday_staff_limit[key]);
    },
    // 回避したいシフトパターンの空もしくは空行の場合は削除して詰める
    sortAvoidPattern (formData) {
      return formData.rules[this.ruleIdOfAvoidPattern].avoid_pattern.filter((pattern) => {
				// 空を削除
				pattern.category_list = pattern.category_list.filter(Boolean);
        // excluded_staff_listをID順に並び替え
        pattern.excluded_staff_list = pattern.excluded_staff_list.sort((a, b) => {
          return a - b;
        });
				// 空行を削除
				return pattern.category_list.length > 0;
			});
    },
    save() {
      this.updateLoading(true);

      // 回避したいシフトパターンの空もしくは空行の場合は削除して詰める
      this.form.rules[this.ruleIdOfAvoidPattern].avoid_pattern = this.sortAvoidPattern(this.form);

      // 配列データをjson化してPUT
      this.form.post(route('offices.store'), {
          preserveScroll: true,
          onSuccess:()=>{
            this.updateLoading(false);
            this.$swal(
              '保存しました',
              '',
              'success').then((result) => {location.reload()})
          },
          onError: () => {
            this.updateLoading(false);
          },
        })
    },
    cancel() {
      this.updateLoading(true);
      this.$inertia.get(route('offices.list'), {})
    },
    update_external_kintai(data, error) {
      // エラーがあればフラグをfalseにする
      this.externalKintaiValidationCheck = error.every(item => item === null || item === '');
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  mounted : function() {
    this.updateLoading(false);
    this.form.number = null;
    // 介護保険事業所番号のバリデーションチェック
    this.checkNumber();
    // 事業所名のバリデーションチェック
    this.checkName();
    // 希望休の受付日数のバリデーションチェック
    this.checkHolidayRequestMax();
    // 希望勤務の受付日数のバリデーションチェック
    this.checkWorkpatternRequestMax();
  }
}
</script>

<style>
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d92550;
}
.autocomplete-style .v-input__control {
  border: 1px solid #ced4da!important;
  border-radius: 8px!important;
}
.v-input__slot {
    background: initial!important;
}
</style>
