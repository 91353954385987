<template>
  <div class="m-3 p-3">
    <h3 class="pb-3">拠点管理</h3>

    <p>{{ message }}</p>
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";

export default {
  props: {
    message:String,
  },
  data() {
    console.log('【lodgment_data】',this.message)
    return {
      errors: {}
    };
  },
};
</script>