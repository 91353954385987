<template>
  <div style="font-size: 16px;">

    <div style="display: flex;">
      <div>
        <b-button class="btn_white" style="font-size: 16px; padding: 12px 20px!important; margin: 0px!important;" @click="cancel">
          <font-awesome-icon icon="chevron-left" style="padding-right: 10px;" />{{ return_page_type == 1 ? '職員管理一覧' : 'シフト作成' }}画面にもどる
        </b-button>
        <h4 style="font-weight: 700; padding: 25px 24px 25px 0px;">
          勤務条件一括割り当て
          <!-- UI改善で追加された項目 -->
          <span style="color: #33A272; font-weight: 500; padding-left: 30px;">事業所名：{{ office.name }}</span>
        </h4>
      </div>
      
      <div style="margin-left: auto;">
        <WorkSummary 
          v-if="count_work_category_summary"
          :work_category_group_label="count_work_category_summary.work_category_group_label"
          :week_cnt="count_work_category_summary.week_cnt"
          :one_team_workpatterns_by_category="count_work_category_summary.workpatterns_by_team_and_category"
          :settings_office_staffs="settings.office_staffs"
          :settings_office_staff_workpatterns="settings.office_staff_workpatterns"
          :office_wp_list_assignment="office.wp_list_assignment"
          :assignment="count_work_category_summary.assignment"
          :team_id="team_id"
        />
      </div>
    </div>

    <!-- UI改善で追加された項目 -->
    <div style="display: flex; justify-content: space-between; margin-bottom: 0px;">
      <div style="display: flex; padding-right: 15px;">
        <div :class="tab1_class" style="width: 148px; height: 46px; text-align: center; float: left;" @click="tab_click(0)">
          <div :class="tab1_caption">勤務区分</div>
        </div>
        <div :class="tab2_class" style="width: 148px; height: 46px; text-align: center; float: left; margin-left: 1px;" @click="tab_click(1)">
          <div :class="tab2_caption">勤務回数</div>
        </div>
        <div :class="tab3_class" style="width: 148px; height: 46px; text-align: center; float: left; margin-left: 1px;" @click="tab_click(2)">
          <div :class="tab3_caption">シフトパターン</div>
        </div>
        <v-text-field
          append-icon="mdi-magnify"
          label="キーワード検索"
          outlined
          hide-details
          dense
          class="search_icon"
          style="max-width: 230px; border-radius: 8px; background: #FFFFFF; margin-left: 20px; display: none;"
        ></v-text-field>
      </div>

      <div style="display: flex;">
        <b-button class="btn_shift_reset" @click="reset">
          リセット
          <font-awesome-icon icon="rotate-right" style="font-weight: 600; color: #6C737F; vertical-align: text-top; padding-right: 14px;" />
        </b-button>
        <b-button class="btn_shift_save" @click="save">保存</b-button>
      </div>
    </div>
    <div class="stripe_green">
      <form>
        <div class="table-bulkadd" style="height: calc(100vh - 322px);">
          <table style="table-layout: fixed;">
            <tr>
              <th class="head01" rowspan="2">
                <div style="display: flex; justify-content: space-between;">
                  <div>社員番号</div>
                  <button type="button" class="sort_btn" @click="set_sort_by(1)">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" :class="column1_sort_asc" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" :class="column1_sort_desc" /></span>
                  </button>
                </div>
              </th>
              <th class="head02" rowspan="2">
                <div style="display: flex; justify-content: space-between;">
                  <div>チーム</div>
                  <button type="button" class="sort_btn" @click="set_sort_by(2)">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" :class="column2_sort_asc" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" :class="column2_sort_desc" /></span>
                  </button>
                </div>
              </th>
              <th class="head03" rowspan="2">
                <div style="display: flex; justify-content: space-between;">
                  <div>名前</div>
                  <button type="button" class="sort_btn" @click="set_sort_by(3)">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" :class="column3_sort_asc" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" :class="column3_sort_desc" /></span>
                  </button>
                </div>
              </th>
              <th class="head04" rowspan="2">
                <div style="display: flex; justify-content: space-between;">
                  <div>職種</div>
                  <button type="button" class="sort_btn" @click="set_sort_by(4)">
                    <span ><font-awesome-icon icon="long-arrow-alt-up" :class="column4_sort_asc" /></span>
                    <span ><font-awesome-icon icon="long-arrow-alt-down" :class="column4_sort_desc" /></span>
                  </button>
                </div>
              </th>
              <th class="head05" rowspan="2" colspan="2" :class="class_disp_worktimes">
                <div class="text-center" style="display: flex; height: 100%; width: 100%; padding: 0px 5px; align-items: center;">
                  労働時間
                </div>
              </th>
              <th :class="class_disp_workpattern" :colspan="workpatterns.length">勤務区分の割り当て</th>
              <th :class="class_disp_workcount" :colspan="workpatterns.length">勤務回数</th>
              <th :class="class_disp_shift" :colspan="form.weekdays.length">シフトパターン</th>
            </tr>
            <tr>
              <th v-for="(workpattern, index) in workpatterns" :key="'header-workpattern-' + workpattern.id" :class="class_disp_workpattern" class="copy_pointer" style="text-align: center; width: 80px; min-width: 80px; padding: 0px;">
                <div class="text-center" style="display: flex; height: 100%; width: 100%; padding: 0px 5px; align-items: center;" @click="show_col_copy_menu(index, workpattern.id)">
                  {{ workpattern.name }}
                </div>
              </th>
              <th v-for="(workpattern, index) in workpatterns" :key="'header-workcount-' + workpattern.id" :class="class_disp_workcount" class="copy_pointer" style="width: 120px; min-width:120px;"  @click="show_col_copy_menu(index, workpattern.id)">
                {{ workpattern.name }}
              </th>
              <th v-for="(weekday, index) in form.weekdays" :key="'header-shift-' + weekday.id" :class="class_disp_shift" class="copy_pointer" style="width: 60px; min-width:60px;" @click="show_col_copy_menu(index, weekday.id)">
                {{ weekday.name }}
              </th>
            </tr>
            <tr v-for="(office_staff, index) in form.office_staffs" :key="'workpattern-' + office_staff.id" :class="class_disp_workpattern">
              <td class="copy_pointer" style="white-space: normal; background: #F3F4F6; padding: 0px 5px;">
                <div class="text-center" style="display: flex; align-items: center; height: 65px; width: 120px;" @click="show_row_copy_menu(index, office_staff.id)">
                 {{ office_staff.employee_number }}
                </div>
              </td>
              <td style="white-space: normal;">{{ office_staff.team_names.join(',') }}</td>
              <td style="white-space: normal;">{{ office_staff.staff_lastname }}{{ office_staff.staff_firstname }}</td>
              <td style="white-space: normal;">{{ office_staff.occupation_names.join(',') }}</td>
              <td :id="'tab_0_row_select_' + index + '_tab_0_col_select_T0_'" class="fixed05" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].min" type="number" min="0" class="form-control" @change="worktime_hours_min_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;"><span>時間<br />以上</span></td>
              <td :id="'tab_0_row_select_' + index + '_tab_0_col_select_T1_'" class="fixed06" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].max" type="number" min="0" class="form-control" @change="worktime_hours_max_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;">時間<br />以下</td>
              <td v-for="workpattern in form.workpatterns" :key="'workpattern-' + office_staff.id + '-' + workpattern.id" :id="'tab_0_row_select_' + index + '_tab_0_col_select_' + workpattern.id + '_'" style="text-align: center;">
                <label class="workpattern_flag_button">
                  <input
                    type="checkbox"
                    v-model="settings.office_staff_workpattern_flags[office_staff.id][workpattern.id]"
                    @change="office_staff_workpattern_flag_changed(office_staff.id, workpattern.id)"
                  >
                  <span></span>
                </label>
              </td>
            </tr>
            <tr v-for="(office_staff, index) in form.office_staffs" :key="'workcount-' + office_staff.id" :class="class_disp_workcount">
              <td class="copy_pointer" style="white-space: normal; background: #F3F4F6; padding: 0px 5px;">
                <div class="text-center" style="display: flex; align-items: center; height: 65px; width: 120px;" @click="show_row_copy_menu(index, office_staff.id)">
                 {{ office_staff.employee_number }}
                </div>
              </td>
              <td style="white-space: normal;">{{ office_staff.team_names.join(',') }}</td>
              <td style="white-space: normal;">{{ office_staff.staff_lastname }}{{ office_staff.staff_firstname }}</td>
              <td style="white-space: normal;">{{ office_staff.occupation_names.join(',') }}</td>
              <td :id="'tab_1_row_select_' + index + '_tab_1_col_select_T0_'" class="fixed05" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].min" type="number" min="0" class="form-control" @change="worktime_hours_min_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;"><span>時間<br />以上</span></td>
              <td :id="'tab_1_row_select_' + index + '_tab_1_col_select_T1_'" class="fixed06" style="width: 150px;"><input v-model="form.worktime_hours[office_staff.id].max" type="number" min="0" class="form-control" @change="worktime_hours_max_changed(office_staff.id)" style="width: 80px; float: left; margin-right: 10px;">時間<br />以下</td>
              <td v-for="workpattern in form.workpatterns" :key="'workcount-' + office_staff.id + '-' + workpattern.id" :class="class_disp_workcount" :id="'tab_1_row_select_' + index + '_tab_1_col_select_' + workpattern.id + '_'">
                <div style="display: flex" :class="active_workcounts[office_staff.id][workpattern.id]">
                  <input type="number" v-model="settings.office_staff_workpatterns[office_staff.id][workpattern.id].min_monthly_working_count" class="form-control" min="0" max="31" style="width:60px;" :disabled="active_workcounts[office_staff.id][workpattern.id] == 'disabled'" @change="office_staff_workpattern_min_monthly_working_count_changed(office_staff.id, workpattern.id)">
                  <input type="number" v-model="settings.office_staff_workpatterns[office_staff.id][workpattern.id].max_monthly_working_count" class="form-control" min="0" max="31" style="width:60px;" :disabled="active_workcounts[office_staff.id][workpattern.id] == 'disabled'" @change="office_staff_workpattern_max_monthly_working_count_changed(office_staff.id, workpattern.id)">
                </div>
              </td>
            </tr>
            <tr v-for="(staff, index) in form.staffs" :key="'shift-' + staff.id" :class="class_disp_shift">
              <td class="copy_pointer" style="white-space: normal; background: #F3F4F6; padding: 0px 5px;">
                <div class="text-center" style="display: flex; align-items: center; height: 65px; width: 120px;" @click="show_row_copy_menu(index, staff.office_staff_id)">
                  {{ staff.employee_number }}
                </div>
              </td>
              <td style="white-space: normal;">{{ staff.team_names.join(',') }}</td>
              <td style="white-space: normal;">{{ staff.lastname }}{{ staff.firstname }}</td>
              <td style="white-space: normal;">{{ staff.main_occupation_name }}</td>
              <td v-for="weekday in form.weekdays" :key="'shift-' + staff.id + '-' + weekday.id" :id="'tab_2_row_select_' + index + '_tab_2_col_select_' + weekday.id + '_'">
                <v-select v-model="settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id]" :items="office_staff_workpattern_items[staff.office_staff_id]" dense outlined hide-details />
              </td>
            </tr>
          </table>
          <ul class="copy_menu" :style="copy_menu_position" v-show="row_copy_menu_show">
            <li @click="row_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この行をコピー</li>
            <li :class="row_copy_select ? '' : 'copy_menu_disabled'" @click="row_paste"><font-awesome-icon icon="clone" style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
            <li :class="row_copy_select ? '' : 'copy_menu_disabled'"  @click="clear_row_copy"><font-awesome-icon icon="times" style="color: #1059CC; padding-right: 20px;" disabled/>選択を削除</li>
          </ul>
          <ul class="copy_menu" :style="copy_menu_position" v-show="col_copy_menu_show">
            <li @click="col_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この列をコピー</li>
            <li :class="col_copy_select ? '' : 'copy_menu_disabled'" @click="col_paste"><font-awesome-icon icon="clone" style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
            <li :class="col_copy_select ? '' : 'copy_menu_disabled'"  @click="clear_col_copy"><font-awesome-icon icon="times" style="color: #1059CC; padding-right: 20px;" disabled/>選択を削除</li>
          </ul>
        </div>
      </Form>
      <div v-click-outside="copy_menu_control"></div>
    </div>



  </div>
</template>

<style scope>
.search_icon i {
  color: #1059CC!important;
}
.zsiq_cnt {
  display: none!important;
}
.table-bulkadd {
  width: 100%;
  margin: 20px;
  overflow: auto;
}
.table-bulkadd table {
  margin: 0;
  border-spacing: 0;
  border-collapse: separate!important;
}
.table-bulkadd th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: inherit;
  background: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  z-index: 1;
}
.table-bulkadd tr:nth-child(1) th {
  top: 0px;
}
.table-bulkadd tr:nth-child(2) th {
  top: 35px;
}
.table-bulkadd td {
  position: sticky;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  background: #FFFFFF;
  border: solid 1px rgba(210, 214, 219, 0.5);
  padding: 10px 5px;
}
.table-bulkadd .head01 {
  top: 0px;
  left: 0px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  z-index: 3000;
}
.table-bulkadd .head02 {
  top: 0px;
  left: 120px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 3000;
}
.table-bulkadd .head03 {
  top: 0px;
  left: 270px;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  z-index: 3000;
}
.table-bulkadd .head04 {
  top: 0px;
  left: 430px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  z-index: 3000;
}
.table-bulkadd .head05 {
  top: 0px;
  left: 630px;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 3000;
}
.table-bulkadd tr td:nth-child(1) {
  left: 0px;
  min-width: 120px;
  max-width: 120px;
  z-index: 2000;
}
.table-bulkadd .fixed02,.table-bulkadd tr td:nth-child(2) {
  left: 120px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 2000;
}
.table-bulkadd .fixed03,.table-bulkadd tr td:nth-child(3) {
  left: 270px;
  width: 160px;
  min-width: 160px;
  z-index: 2000;
}
.table-bulkadd .fixed04,.table-bulkadd tr td:nth-child(4) {
  left: 430px;
  width: 200px;
  min-width: 200px;
  z-index: 2000;
}
.table-bulkadd .fixed05 {
  left: 630px;
  width: 140px;
  min-width: 140px;
  z-index: 2000;
}
.table-bulkadd .fixed06 {
  left: 770px;
  width: 140px;
  min-width:140px;
  z-index: 2000;
}
.disabled input[type="number"] {
  background-color: #e9ecef;
}
.no_display {
  display: none;
}
.v-select {
  width: 100px;
  max-width: 100px!important;
}
select option[selected] {
  background: red;
}
.btn-secondary {
  margin-left: 0.25rem !important;
}
.tab {
  cursor: pointer;
}
.copy_menu {
  position: absolute;
  z-index: 2147483647;
  background: #F8F9F8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  list-style: none;
  line-height: 2;
  padding: 20px 0px;
}
.copy_menu li {
  padding-left: 20px;
  padding-right: 100px;
}
.copy_menu li:hover {
  background: #D4D5D4;
}
.copy_menu_disabled {
    pointer-events: none;
    opacity:0.6;
}
.row_copyselect_first {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_between {
  border-top: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_last {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_last {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_min {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between_min {
  border-left: dashed 2px blue!important;
}
.col_copyselect_last_min {
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_max {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
}
.col_copyselect_between_max {
  border-right: dashed 2px blue!important;
}
.col_copyselect_last_max {
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.copy_pointer {
  cursor: pointer;
}
/* table scroll design */
form {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
    &:hover {
        ::-webkit-scrollbar-track {
        background: #eee;
        }
        ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 6px;
        }
    }
}
</style>

<script>
import axios from 'axios'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ClickOutside from 'vue-click-outside';
import WorkSummary from '@/Pages/Workpatterns/Components/WorkSummary.vue'

export default {
  components: {
    WorkSummary,
    VuePerfectScrollbar,
  },
  props: {
    sort_type: {
      type: Number,
      required: true
    },
    tab: {
      type: Number,
      required: false,
      default: 1
    },
    workpatterns: {
      type: Array,
      required: true
    },
    office_staffs: {
      type: Array,
      required: true
    },
    staffs: {
      type: Array,
      required: true
    },
    office_staff_workpatterns: {
      type: Array,
      required: true
    },
    default_monthly_working_counts: {
      type: Object,
      required: true
    },
    return_page_type: {
      type: Number,
      default: 1,
    },
    office: {
      type: Object,
      required: true,
    },
    year: {
      type: String,
      default: '',
    },
    month: {
      type: String,
      default: '',
    },
    count_work_category_summary: {
      type: Object,
      required: true
    },
    team_id:{
      type: Number
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.load_data();
    });

    // ローディング終了
    this.updateLoading(false);
  },
  computed: {
    class_disp_worktimes() {
      if (this.step < 2) {
        return '';
      } else {
        return 'no_display';
      }
    },
    class_disp_workpattern() {
      if (this.step == 0) {
        return '';
      } else {
        return 'no_display';
      }
    },
    class_disp_workcount() {
      if (this.step == 1) {
        return '';
      } else {
        return 'no_display';
      }
    },
    class_disp_shift() {
      if (this.step == 2) {
        return '';
      } else {
        return 'no_display';
      }
    },
    tab1_class() {
      if (this.step == 0) {
        return 'tab tab_style tab-active';
      } else {
        return 'tab tab_style';
      }
    },
    tab2_class() {
      if (this.step == 1) {
        return 'tab tab_style tab-active';
      } else {
        return 'tab tab_style';
      }
    },
    tab3_class() {
      if (this.step == 2) {
        return 'tab tab_style tab-active';
      } else {
        return 'tab tab_style';
      }
    },
    tab1_caption() {
      if (this.step == 0) {
        return 'tab-active-caption';
      } else {
        return 'tab-passive-caption';
      }
    },
    tab2_caption() {
      if (this.step == 1) {
        return 'tab-active-caption';
      } else {
        return 'tab-passive-caption';
      }
    },
    tab3_caption() {
      if (this.step == 3) {
        return 'tab-active-caption';
      } else {
        return 'tab-passive-caption';
      }
    },
    column1_sort_asc() {
      if (this.sort_type == 1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column1_sort_desc() {
      if (this.sort_type == -1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_asc() {
      if (this.sort_type == 2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_desc() {
      if (this.sort_type == -2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_asc() {
      if (this.sort_type == 3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_desc() {
      if (this.sort_type == -3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_asc() {
      if (this.sort_type == 4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_desc() {
      if (this.sort_type == -4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
  },
  watch: {
    step: {
      handler: function (new_step) {
        if (new_step == 2) {
          this.office_staffs.forEach(office_staff => {
            this.update_office_staff_workpattern_items(office_staff.id);
          })
        }
      }
    }
  },
  data() {
    return {
      step: this.tab - 1,
      active_workcounts: {},
      office_staff_workpattern_items: {},
      office_staff_to_staff: {},
      public_holiday_workpatterns: {},
      unchangable_workpatterns: {},
      // 「勤務回数」が固定値の勤務種別
      unchangable_workpattern_types: [
        'paid_holidays',
        'paid_half_holiday',
        'other_holiday',
        'half_work',
      ],
      // 「シフトパターン」で選択肢から除外される勤務種別
      no_shift_workpattern_types: [
        'paid_holidays',
        'public_half_holiday',
        'paid_half_holiday',
        'other_holiday',
        'half_work',
      ],
      defaultMonthlyWorkingCounts: this.default_monthly_working_counts,
      form: {
        office_staffs: [],
        workpatterns: [],
        worktime_hours: [],
        staffs: [],
        weekdays: []
      },
      initial_values: {
        office_staff_workpattern_flags: {},
        office_staff_workpatterns: {},
      },
      settings: {
        office_staffs: {},
        office_staff_workpattern_flags: {},
        office_staff_workpatterns: {},
        office_staff_workpatterns_changed: {},
        office_staff_shift_patterns: {}
      },
      copy_menu_position: {
        left: 0,
        top: 0
      },
      row_menu_show: false,
      row_copy_menu_show: false,
      row_copied_index: -1,
      row_copied_id: -1,
      row_copy_select: false,
      col_menu_show: false,
      col_copy_menu_show: false,
      col_copied_id: -1,
      col_copy_select: false,
      disabled_office_staff_work_pattern_ids: [],
      wp_list_assignment: {},
      assignment: {}
    };
  },
  methods: {
    load_data() {
      // office_staff_workpatterns を office_staff_workpattern_flags と settings_office_staff_workpatterns の初期値に反映
      let office_staff_workpattern_flags = {};
      let settings_office_staff_workpatterns = {};
      this.office_staff_workpatterns.forEach(office_staff_workpattern => {
        let office_staff_id = office_staff_workpattern.office_staff_id;
        let workpattern_id = office_staff_workpattern.workpattern_id;
        let min_monthly_working_count = office_staff_workpattern.min_monthly_working_count;
        let max_monthly_working_count = office_staff_workpattern.max_monthly_working_count;

        if (typeof office_staff_workpattern_flags[office_staff_id] == 'undefined') {
          office_staff_workpattern_flags[office_staff_id] = {};
          settings_office_staff_workpatterns[office_staff_id] = {};
        }
        office_staff_workpattern_flags[office_staff_id][workpattern_id] = true;
        settings_office_staff_workpatterns[office_staff_id][workpattern_id] = {
          min_monthly_working_count: min_monthly_working_count,
          max_monthly_working_count: max_monthly_working_count,
        };
      });

      let public_holiday_workpatterns = {};
      let unchangable_workpatterns = {};
      let no_shift_workpatterns = {};
      this.workpatterns.forEach(workpattern => {
        if (workpattern.type == 'public_holidays') {
          public_holiday_workpatterns[workpattern.id] = workpattern;
        }
        if (this.unchangable_workpattern_types.includes(workpattern.type)) {
          unchangable_workpatterns[workpattern.id] = workpattern;
        }
        if (this.no_shift_workpattern_types.includes(workpattern.type)) {
          no_shift_workpatterns[workpattern.id] = workpattern;
        }
      });
      this.public_holiday_workpatterns = public_holiday_workpatterns;
      this.unchangable_workpatterns = unchangable_workpatterns;
      this.no_shift_workpatterns = no_shift_workpatterns;

      let active_workcounts = {};
      let settings_office_staffs = {};
      let settings_office_staff_shift_patterns = {};
      let form_worktime_hours = {};
      this.office_staffs.forEach(office_staff => {
        if (typeof office_staff_workpattern_flags[office_staff.id] == 'undefined') {
          office_staff_workpattern_flags[office_staff.id] = {};
        }

        active_workcounts[office_staff.id] = {};

        if (typeof settings_office_staff_workpatterns[office_staff.id] == 'undefined') {
          settings_office_staff_workpatterns[office_staff.id] = {};
        }

        this.workpatterns.forEach(workpattern => {
          if (typeof office_staff_workpattern_flags[office_staff.id][workpattern.id] == 'undefined') {
            office_staff_workpattern_flags[office_staff.id][workpattern.id] = false;
            active_workcounts[office_staff.id][workpattern.id] = 'disabled';
            settings_office_staff_workpatterns[office_staff.id][workpattern.id] = {
              min_monthly_working_count: 0,
              max_monthly_working_count: 0,
            };
          } else if (this.check_unchangable_workpattern(workpattern.id)) {
            active_workcounts[office_staff.id][workpattern.id] = 'disabled';
            const defaultMonthlyWorkingCounts =  this.defaultMonthlyWorkingCounts[office_staff.employment_type][workpattern.type][workpattern.category];
            settings_office_staff_workpatterns[office_staff.id][workpattern.id] = {
              min_monthly_working_count: defaultMonthlyWorkingCounts.min,
              max_monthly_working_count: defaultMonthlyWorkingCounts.max,
            };
          } else {
            active_workcounts[office_staff.id][workpattern.id] = office_staff_workpattern_flags[office_staff.id][workpattern.id] ? '' : 'disabled';
          }
          if(active_workcounts[office_staff.id][workpattern.id] == ''){
            settings_office_staff_workpatterns[office_staff.id][workpattern.id].category = workpattern.category;
          }
        });

        settings_office_staffs[office_staff.id] = {
          min_worktime_minutes: office_staff.min_worktime_minutes,
          max_worktime_minutes: office_staff.max_worktime_minutes
        };
        form_worktime_hours[office_staff.id] = {
          min: office_staff.min_worktime_minutes / 60,
          max: office_staff.max_worktime_minutes / 60
        };
      });

      const weekdays = [
        { id: 'mon', name: "月"},
        { id: 'tue', name: "火"},
        { id: 'wed', name: "水"},
        { id: 'thu', name: "木"},
        { id: 'fri', name: "金"},
        { id: 'sat', name: "土"},
        { id: 'sun', name: "日"},
        { id: 'hol', name: "祝"}
      ];

      let office_staff_to_staff = {};
      this.staffs.forEach(staff => {
        office_staff_to_staff[staff.office_staff_id] = staff;
        settings_office_staff_shift_patterns[staff.office_staff_id] = {};
        weekdays.forEach(weekday => {
          if (staff.shift_pattern == null || typeof staff.shift_pattern[weekday.id] == 'undefined' || this.check_no_shift_workpattern(staff.shift_pattern[weekday.id].workpattern_id)) {
            settings_office_staff_shift_patterns[staff.office_staff_id][weekday.id] = null;
          } else {
            settings_office_staff_shift_patterns[staff.office_staff_id][weekday.id] = staff.shift_pattern[weekday.id].workpattern_id;
          }
        });
      });

      this.settings.office_staffs = settings_office_staffs;
      this.settings.office_staff_workpattern_flags = office_staff_workpattern_flags;
      this.initial_values.office_staff_workpattern_flags = structuredClone(this.settings.office_staff_workpattern_flags);
      this.settings.office_staff_workpatterns = settings_office_staff_workpatterns;
      this.initial_values.office_staff_workpatterns = structuredClone(this.settings.office_staff_workpatterns);
      this.settings.office_staff_shift_patterns = settings_office_staff_shift_patterns;

      // this.settings.office_staff_workpattern_flags 設定後に実施
      if (this.step == 2) {
        this.office_staffs.forEach(office_staff => {
          this.update_office_staff_workpattern_items(office_staff.id);
        });
      }

      this.form.office_staffs = this.office_staffs;
      this.form.workpatterns = this.workpatterns;
      this.form.worktime_hours = form_worktime_hours;
      this.form.staffs = this.staffs;
      this.form.weekdays = weekdays;

      this.active_workcounts = active_workcounts;

      this.office_staff_to_staff = office_staff_to_staff;
    },
    worktime_hours_min_changed(office_staff_id) {
      let message = '';
      if (parseInt(this.form.worktime_hours[office_staff_id].min) < 0) {
        message = '労働時間は0以上の値を指定してください。';
      } else if (parseInt(this.form.worktime_hours[office_staff_id].min) > parseInt(this.form.worktime_hours[office_staff_id].max)) {
        message = '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう以下の職員を修正してください。';
      }

      if (message != '') {
        let staff = this.office_staff_to_staff[office_staff_id];
        this.$swal({
          icon: 'error',
          html: message + '<br>'
              + '職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname),
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.settings.office_staffs[office_staff_id].min_worktime_minutes = this.form.worktime_hours[office_staff_id].min * 60;
      }
    },
    worktime_hours_max_changed(office_staff_id) {
      let message = '';
      if (parseInt(this.form.worktime_hours[office_staff_id].min) < 0) {
        message = '労働時間は0以上の値を指定してください。';
      } else if (parseInt(this.form.worktime_hours[office_staff_id].min) > parseInt(this.form.worktime_hours[office_staff_id].max)) {
        message = '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう以下の職員を修正してください。';
      }

      if (message != '') {
        let staff = this.office_staff_to_staff[office_staff_id];
        this.$swal({
          icon: 'error',
          html: message + '<br>'
              + '職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname),
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.settings.office_staffs[office_staff_id].max_worktime_minutes = this.form.worktime_hours[office_staff_id].max * 60;
      }
    },
    update_disabled_office_staff_work_patterns_ids(office_staff_id, workpattern_id) {
      const office_staff_workpattern_id = this.office_staff_workpatterns.find(
        office_staff_workpattern =>
          office_staff_workpattern.office_staff_id === office_staff_id && office_staff_workpattern.workpattern_id === workpattern_id
      )?.id;

      if (typeof office_staff_workpattern_id === 'undefined') return;

      if (this.disabled_office_staff_work_pattern_ids.includes(office_staff_workpattern_id)) {
        this.disabled_office_staff_work_pattern_ids.pop(office_staff_workpattern_id);
      } else {
        this.disabled_office_staff_work_pattern_ids.push(office_staff_workpattern_id)
      };
    },
    office_staff_workpattern_flag_changed(office_staff_id, workpattern_id) {
      this.update_disabled_office_staff_work_patterns_ids(office_staff_id, workpattern_id);
      if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern_id]) {
        let staff = this.office_staff_to_staff[office_staff_id];

        // 「公休(全休)」の複数割り当てチェック
        let has_public_holidays_duplicated = false;
        if (this.check_public_holiday_workpattern(workpattern_id)) {  // 勤務種別「公休(全休)」の勤務区分が割り当てられた
          // 画面上ですでに「公休(全休)」が割り当てられているか確認
          let public_holidays_duplicated = [];
          this.workpatterns.forEach(workpattern => {
            if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern.id]
              && this.check_public_holiday_workpattern(workpattern.id)
              && workpattern_id != workpattern.id) {
                public_holidays_duplicated.push(workpattern);
            }
          });
          has_public_holidays_duplicated = public_holidays_duplicated.length > 0;

          let message = '';
          if (has_public_holidays_duplicated) {
            // 割り当て済みなのでエラー
            message += '<p style="text-align: left">勤務種別：公休（全休）の勤務区分は1人の職員に対し1つまでしか割り当てできません。<br>この職員 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname) + ' にはすでに以下の勤務区分が割り当てられています：</p>';
            let duplicate_workpattern_names = [];
            public_holidays_duplicated.forEach(workpattern => {
              duplicate_workpattern_names.push(workpattern.name);
            })
            message += '<p>勤務区分：' + this.htmlspecialchars(duplicate_workpattern_names.join('、')) + '</p>';
          }
          if (message != '') {
            this.$swal({
              icon: 'error',
              html: message,
              confirmButtonText: '閉じる',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            .then(result => {
              this.settings.office_staff_workpattern_flags[office_staff_id][workpattern_id] = false;
            })
          }
        }

        if (!has_public_holidays_duplicated) {
          // 勤務回数を有効化
          if (this.check_unchangable_workpattern(workpattern_id)) {
            this.active_workcounts[office_staff_id][workpattern_id] = 'disabled';
          } else {
            this.active_workcounts[office_staff_id][workpattern_id] = '';
          }

          const office_staff = this.office_staffs.find(({ id }) => id === office_staff_id);
          const workpattern = this.workpatterns.find(({ id }) => id === workpattern_id);
          const defaultMonthlyWorkingCounts =  this.defaultMonthlyWorkingCounts[office_staff.employment_type][workpattern.type][workpattern.category];
          this.settings.office_staff_workpatterns[office_staff_id][workpattern_id] = {
            min_monthly_working_count: defaultMonthlyWorkingCounts.min,
            max_monthly_working_count: defaultMonthlyWorkingCounts.max
          };
        }
      } else {
        // 勤務回数を無効化
        this.active_workcounts[office_staff_id][workpattern_id] = 'disabled';
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id] = {
          min_monthly_working_count: 0,
          max_monthly_working_count: 0
        };
        // シフトパターンから削除
        this.form.weekdays.forEach(weekday => {
          if (this.settings.office_staff_shift_patterns[office_staff_id][weekday.id] == workpattern_id) {
            this.settings.office_staff_shift_patterns[office_staff_id][weekday.id] = null;
          }
        });
      }
    },
    office_staff_workpattern_min_monthly_working_count_changed(office_staff_id, workpattern_id) {
      const min_monthly_working_count = parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count);
      if (min_monthly_working_count < 0) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = 0;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、0以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (min_monthly_working_count > 31) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = 31;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、31以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (min_monthly_working_count > parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count)) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count = this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最小値には、最大値以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    office_staff_workpattern_max_monthly_working_count_changed(office_staff_id, workpattern_id) {
      const max_monthly_working_count = parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count);
      if (max_monthly_working_count < 0) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = 1;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、0以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (max_monthly_working_count > 31) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = 31;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、31以下の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (max_monthly_working_count < parseInt(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count)) {
        this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].max_monthly_working_count = this.settings.office_staff_workpatterns[office_staff_id][workpattern_id].min_monthly_working_count;
        this.$swal({
          icon: 'error',
          text: '月当たりの勤務回数の最大値には、最小値以上の値を指定してください。',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    clear_office_staff_workpatterns_changed() {
      this.settings.office_staff_workpatterns_changed = {};
    },
    set_office_staff_workpatterns_changed(office_staff_id, workpattern_id) {
      if (typeof this.settings.office_staff_workpatterns_changed[office_staff_id] == 'undefined') {
        this.settings.office_staff_workpatterns_changed[office_staff_id] = {};
      }
      this.settings.office_staff_workpatterns_changed[office_staff_id][workpattern_id]
        = structuredClone(this.settings.office_staff_workpatterns[office_staff_id][workpattern_id]);
    },
    // 勤務回数の変更箇所を office_staff_workpatterns_changed に設定し、事業所またぎ兼務している職員の勤務区分または勤務回数を変更しているかどうかを返却
    pickup_workpattern_changes() {
      let workpattern_concurrent_changes_list = [];
      this.clear_office_staff_workpatterns_changed();
      for (let i = 0; i < this.staffs.length; i++) {
        let staff = this.staffs[i];
        for (let j = 0; j < this.form.workpatterns.length; j++) {
          let workpattern = this.form.workpatterns[j];
          if ((!this.initial_values.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id]
               && this.settings.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id])
            || (this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count == 0
                && this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count == 0
                && (this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count > 0
                    || this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count > 0))) {
            // 勤務区分の割り当てが追加されている
            this.set_office_staff_workpatterns_changed(staff.office_staff_id, workpattern.id);
            if (staff.office_concurrent_flag) {
              workpattern_concurrent_changes_list.push(staff.lastname + '　' + staff.firstname + '：' + workpattern.name + '（追加）');
            }
          } else if ((!this.settings.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id]
               && this.initial_values.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id])
            || (this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count == 0
                && this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count == 0
                && (this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count > 0
                    || this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count > 0))) {
            // 勤務区分の割り当てが解除されている
            this.set_office_staff_workpatterns_changed(staff.office_staff_id, workpattern.id);
            if (staff.office_concurrent_flag) {
              workpattern_concurrent_changes_list.push(staff.lastname + '　' + staff.firstname + '：' + workpattern.name + '（削除）');
            }
          } else if (this.initial_values.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id]
               && this.settings.office_staff_workpattern_flags[staff.office_staff_id][workpattern.id]
               && (this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count
                     != this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].min_monthly_working_count
                   || this.initial_values.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count
                     != this.settings.office_staff_workpatterns[staff.office_staff_id][workpattern.id].max_monthly_working_count)) {
            // 勤務回数の最小値もしくは最大値が変更されている
            this.set_office_staff_workpatterns_changed(staff.office_staff_id, workpattern.id);
            if (staff.office_concurrent_flag) {
              workpattern_concurrent_changes_list.push(staff.lastname + '　' + staff.firstname + '：' + workpattern.name + '（変更）');
            }
          }
        }
      }

      return workpattern_concurrent_changes_list;
    },
    update_office_staff_workpattern_items(office_staff_id) {
      this.office_staff_workpattern_items[office_staff_id] = [{
        text: '--',
        value: null
      }];
      this.workpatterns.forEach(workpattern => {
        if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern.id] == true && !this.check_no_shift_workpattern(workpattern.id)) {
          this.office_staff_workpattern_items[office_staff_id].push({
            text: workpattern.name,
            value: workpattern.id
          });
        }
      });
    },
    // 事業所またぎ兼務している職員のシフトパターンを変更しているかどうか
    check_shift_pattern_concurrency() {
      for (let i = 0; i < this.staffs.length; i++) {
        let staff = this.staffs[i];
        if (staff.office_concurrent_flag) {
          for (let j = 0; j < this.form.weekdays.length; j++) {
            let weekday = this.form.weekdays[j];
            if (staff.shift_pattern == null || typeof staff.shift_pattern[weekday.id] == 'undefined') {
              if (this.settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id] != null) {
                return true;
              }
            } else if (this.settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id] != staff.shift_pattern[weekday.id].workpattern_id) {
              return true;
            }
          }
        }
      }
      return false;
    },
    // 公休(全休) の勤務区分か判定
    check_public_holiday_workpattern(workpattern_id) {
      return Object.keys(this.public_holiday_workpatterns).includes(String(workpattern_id));
    },
    check_unchangable_workpattern(workpattern_id) {
      return Object.keys(this.unchangable_workpatterns).includes(String(workpattern_id));
    },
    check_no_shift_workpattern(workpattern_id) {
      return Object.keys(this.no_shift_workpatterns).includes(String(workpattern_id));
    },
    htmlspecialchars(string) {
      if (typeof string != 'string') {
        return string;
      };
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          };
          return escaped[match];
        });
    },
    set_sort_by(sort_by) {
      let new_sort_type;
      if (Math.abs(this.sort_type) == sort_by) {
        new_sort_type = -this.sort_type;
      } else {
        new_sort_type = sort_by;
      }

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sort', new_sort_type);
      searchParams.set('tab', this.step + 1)
      window.location.href = this.$route('staffs.workcons.do-bulk-add') + '?' + searchParams.toString();
    },
    save() {
      let no_workpattern_errors = [];
      let min_errors = [];
      let min_max_errors = [];
      let public_holiday_errors = [];

      this.form.office_staffs.forEach(office_staff => {
        let has_workpatterns = false;
        Object.values(this.settings.office_staff_workpattern_flags[office_staff.id]).forEach(office_staff_workpattern_flag => {
          if (office_staff_workpattern_flag) {
            has_workpatterns = true;
          }
        });
        if (!has_workpatterns) {
          let staff = this.office_staff_to_staff[office_staff.id];
          no_workpattern_errors.push('職員名: ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname));
        }

        if (parseInt(this.form.worktime_hours[office_staff.id].min) < 0) {
          let staff = this.office_staff_to_staff[office_staff.id];
          min_errors.push('職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname));
        } else if (parseInt(this.form.worktime_hours[office_staff.id].min) > parseInt(this.form.worktime_hours[office_staff.id].max)) {
          let staff = this.office_staff_to_staff[office_staff.id];
          min_max_errors.push('職員名 ' + this.htmlspecialchars(staff.lastname) + this.htmlspecialchars(staff.firstname));
        }

        // 公休(全休) は複数指定不可
        let public_holiday_workpatterns_enabled = [];
        this.form.workpatterns.forEach(workpattern => {
          if (this.settings.office_staff_workpattern_flags[office_staff.id][workpattern.id]) {
            if (this.check_public_holiday_workpattern(workpattern.id)) {
              // 公休(全休) の割り当て
              public_holiday_workpatterns_enabled.push(workpattern.name);
            }
          }
        });
        if (public_holiday_workpatterns_enabled.length > 1) {
          let staff = this.office_staff_to_staff[office_staff.id];
          public_holiday_errors.push(this.htmlspecialchars('職員名：　' + staff.lastname + staff.firstname + '　　勤務区分名：　' + public_holiday_workpatterns_enabled.join('、')));
        }
      });

      if (no_workpattern_errors.length > 0) {
        this.$swal({
          icon: 'error',
          html: '<p style="text-align: left; margin: 0 auto 2ex;">1人の職員に対し勤務区分が1つも割り当てられていません。<br>'
            + '以下の職員の勤務区分を最低1つ選択して保存してください。</p>'
            + '<p style="text-align: left; width: 90%; margin: auto;">' + no_workpattern_errors.join('<br>') + '</p>',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else if (min_errors.length > 0 || min_max_errors.length > 0) {
        this.$swal({
          icon: 'error',
          html: min_errors.length > 0
            ? '労働時間は0以上の値を指定してください。<br>' + min_errors.join('<br>')
            : '労働時間の下限が上限を上回っています。<br> 下限が上限を上回らないよう以下の職員を修正してください。<br>' + min_max_errors.join('<br>'),
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else if (public_holiday_errors.length > 0) {
        this.$swal({
          icon: 'error',
          width: 700,
          html: '<p style="text-align: left; margin: 0 auto 2ex;">勤務種別：公休（全休）の勤務区分が複数割り当てられています。<br>'
            + '勤務種別：公休（全休）の勤務区分は1人の職員に対し1つしか割り当てできません。</p>'
            + '<p style="text-align: left; margin: 2ex auto;">以下の職員の勤務種別：公休（全休）の勤務区分を1つ選択して保存してください。</p>'
            + '<p style="text-align: left; width: 90%; margin: auto;">' + public_holiday_errors.join('<br>') + '</p>',
          confirmButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else {
        // 勤務区分の兼務先反映確認ダイアログ
        const workpattern_dialog = {
          icon: 'info',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: '反映する',
          denyButtonText: '反映しない',
          cancelButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
            denyButton: 'btn btn-secondary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        };

        // シフトパターンの兼務先反映確認ダイアログ
        const shift_pattern_dialog = {
          icon: 'info',
          title: '兼務先にもシフトパターンを反映しますか？',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: '反映する',
          denyButtonText: '反映しない',
          cancelButtonText: '閉じる',
          customClass: {
            confirmButton: 'btn btn-primary',
            denyButton: 'btn btn-secondary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        };

        // 登録確認ダイアログ
        const confirm_dialog = {
          icon: 'info',
          title: '設定内容を登録しますか？',
          showCancelButton: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        };

        let concurrently_workpatterns = false;
        const workpattern_concurrent_changes_list = this.pickup_workpattern_changes();
        let concurrently_shift_patterns = false;
        const shift_pattern_concurrent_flag = this.check_shift_pattern_concurrency();
        if (workpattern_concurrent_changes_list.length > 0) {
          // 勤務区分の確認ダイアログ表示
          let message = '<p style="text-align: left; margin: 0 auto 2ex;">以下の勤務区分について、兼務先にも同様の勤務区分の割り当てを反映しますか？<ul style="text-align: left; margin: 0 2ex 2ex; list-style: none;">';
          workpattern_concurrent_changes_list.forEach(entry => {
            message += '<li>' + this.htmlspecialchars(entry) + '</li>';
          });
          message += '</ul></p>';
          workpattern_dialog.html = message;
          this.$swal(workpattern_dialog)
            .then(result => {
              if (!result.isConfirmed && !result.isDenied) return;
              concurrently_workpatterns = result.isConfirmed;

              if (shift_pattern_concurrent_flag) {
                // シフトパターンの確認ダイアログ表示
                this.$swal(shift_pattern_dialog)
                  .then(result => {
                    if (!result.isConfirmed && !result.isDenied) return;
                    concurrently_shift_patterns = result.isConfirmed;
                    this.doBulkAdd(concurrently_workpatterns, concurrently_shift_patterns);
                  });
              } else {
                this.doBulkAdd(concurrently_workpatterns, concurrently_shift_patterns);
              }
            });
        } else {
          if (shift_pattern_concurrent_flag) {
            // シフトパターンの確認ダイアログ表示
            this.$swal(shift_pattern_dialog)
              .then(result => {
                if (!result.isConfirmed && !result.isDenied) return;
                concurrently_shift_patterns = result.isConfirmed;
                this.doBulkAdd(concurrently_workpatterns, concurrently_shift_patterns);
              });
          } else {
            // 登録確認ダイアログ表示
            this.$swal(confirm_dialog)
              .then(result => {
                if (!result.isConfirmed && !result.isDenied) return;
                this.doBulkAdd(concurrently_workpatterns, concurrently_shift_patterns);
              });
          }
        }
      }
    },
    // 一括登録の実行
    doBulkAdd(concurrently_workpatterns, concurrently_shift_patterns) {
      let errors = {};
      this.updateLoading(true);
      axios.post(this.$route('staffs.workcons.do-bulk-add'), {
        office_staffs: this.settings.office_staffs,
        office_staff_workpatterns: this.settings.office_staff_workpatterns_changed,
        concurrently_workpatterns: concurrently_workpatterns,
        office_staff_shift_patterns: this.settings.office_staff_shift_patterns,
        concurrently_shift_patterns: concurrently_shift_patterns,
        disabled_office_staff_work_pattern_ids: this.disabled_office_staff_work_pattern_ids,
      }).then(result => {
        this.updateLoading(false);
        if (result.status === 200) {
          if (result.data.status === 400) {
            errors = result.data.errors;
            let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
            this.$swal({
              icon: 'error',
              html: '入力値が正しくありません。' + error
            });
          } else if (result.data.status !== 200) {
            errors = result.data.errors;
            throw result.status;
          } else {
            this.$swal({
              icon: 'success',
              title: '保存しました'
            }).then(result => {
              if (this.return_page_type == 1) {
                let url = this.$route('staffs.list', {
                  office_id: this.office.id,
                });
                this.$inertia.get(url);
              } else {
                let url = this.$route('shifts.schedule', {
                  year: this.year,
                  month: this.month,
                  office: this.office.id,
                });
                this.$inertia.get(url);
              }
            });
          }
        } else {
          throw result.status;
        }
      }).catch(e => {
        this.updateLoading(false);
        let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
        this.$swal({
          icon: 'error',
          html: '勤務条件一括登録に失敗しました。しばらくたってから再試行してください。' + error
        }).then(result => {
          location.reload()
        })
      });
    },
    reset() {
      this.load_data();
    },
    cancel() {
      this.updateLoading(true);
      if (this.return_page_type == 1) {
        let url = this.$route('staffs.list', {
          office_id: this.office.id,
        });
        this.$inertia.get(url);
      } else {
        let url = this.$route('shifts.schedule', {
          year: this.year,
          month: this.month,
          office: this.office.id,
        });
        this.$inertia.get(url);
      }
    },

    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },

    // 行コピーのコンテンツメニュー表示
    show_row_copy_menu(index, id) {
      this.row_menu_index = index;
      this.row_menu_id = id;
      this.row_menu_show = true;
    },
    // 「この行をコピー」実行
    row_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;

      // 行選択フラグ=ON
      this.row_copy_select = true;

      // 対象設定
      this.row_copied_index = this.row_menu_index;
      this.row_copied_id = this.row_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id^="tab_${this.step}_row_select_${this.row_copied_index}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i == 0) {
            cellOther[i].classList.add('row_copyselect_first');
          } else if (i == (cellOther.length - 1)) {
            cellOther[i].classList.add('row_copyselect_last');
          } else {
            cellOther[i].classList.add('row_copyselect_between');
          }
        }
      }
    },
    // 行の「貼り付け」実行
    row_paste() {
      if (this.row_menu_id !== this.row_copied_id) {
        if (this.step == 0) {
          // 労働時間
          this.form.worktime_hours[this.row_menu_id].min
            = this.form.worktime_hours[this.row_copied_id].min
          this.form.worktime_hours[this.row_menu_id].max
            = this.form.worktime_hours[this.row_copied_id].max

          this.form.workpatterns.forEach(workpattern => {
            if (this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]
              != this.settings.office_staff_workpattern_flags[this.row_copied_id][workpattern.id]) {
              this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]
                = this.settings.office_staff_workpattern_flags[this.row_copied_id][workpattern.id];
              this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern.id);
            }
          });
        } else if (this.step == 1) {
          // 労働時間
          this.form.worktime_hours[this.row_menu_id].min
            = this.form.worktime_hours[this.row_copied_id].min
          this.form.worktime_hours[this.row_menu_id].max
            = this.form.worktime_hours[this.row_copied_id].max

          this.form.workpatterns.forEach(workpattern => {
            // コピー元データが 0, 0 なら対応する勤務区分を無効に、そうでなければ有効にする。
            if (this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].min_monthly_working_count == 0
              && this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].max_monthly_working_count == 0) {
              if (this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]) {
                this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id] = false;
                this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern.id);
              }
            } else {
              if (!this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id]) {
                this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern.id] = true;
                this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern.id);
              }
            }

            this.settings.office_staff_workpatterns[this.row_menu_id][workpattern.id].min_monthly_working_count
              = this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].min_monthly_working_count;
            this.settings.office_staff_workpatterns[this.row_menu_id][workpattern.id].max_monthly_working_count
              = this.settings.office_staff_workpatterns[this.row_copied_id][workpattern.id].max_monthly_working_count;
          });
        } else {  // this.step == 2
          this.form.weekdays.forEach(weekday => {
            let workpattern_id = this.settings.office_staff_shift_patterns[this.row_copied_id][weekday.id];
            // コピー元の勤務区分に入力があり、コピー先でその勤務区分が有効でない (=選択肢に存在しない) 場合は有効にする
            if (workpattern_id !== null && !this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern_id]) {
              this.settings.office_staff_workpattern_flags[this.row_menu_id][workpattern_id] = true;
              this.office_staff_workpattern_flag_changed(this.row_menu_id, workpattern_id);
            }
            this.settings.office_staff_shift_patterns[this.row_menu_id][weekday.id] = workpattern_id;
          });
          // 選択肢更新のため
          this.update_office_staff_workpattern_items(this.row_menu_id);
        }
      }
      // 選択の解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 選択行の「選択を削除」実行
    clear_row_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 行選択フラグ= OFF
      this.row_copy_select = false;

      //コピー行削除
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 列コピーのコンテンツメニュー表示
    show_col_copy_menu(index, id) {
      this.col_menu_index = index;
      this.col_menu_id = id;
      this.col_menu_show = true;
    },
    // 「この列をコピー」実行
    col_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;

      // 列選択フラグ=ON
      this.col_copy_select = true;

      // 対象設定
      this.col_copied_index = this.col_menu_index;
      this.col_copied_id = this.col_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id*="tab_${this.step}_col_select_${this.col_copied_id}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i == 0) {
            cellOther[i].classList.add('col_copyselect_first');
          } else if(i == (cellOther.length - 1)) {
            cellOther[i].classList.add('col_copyselect_last');
          } else {
            cellOther[i].classList.add('col_copyselect_between');
          }
        }
      }
    },
    // 列の「貼り付け」実行
    col_paste() {
      if (this.col_menu_id !== this.col_copied_id) {
        if (this.step == 0) {
          this.form.office_staffs.forEach(office_staff => {
            if (this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]
              != this.settings.office_staff_workpattern_flags[office_staff.id][this.col_copied_id]) {
              this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]
                = this.settings.office_staff_workpattern_flags[office_staff.id][this.col_copied_id];
              this.office_staff_workpattern_flag_changed(office_staff.id, this.col_menu_id);
            }
          })
        } else if (this.step == 1) {
          this.form.office_staffs.forEach(office_staff => {
            // コピー元データが 0, 0 なら対応する勤務区分を無効に、そうでなければ有効にする。
            if (this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].min_monthly_working_count == 0
              && this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].max_monthly_working_count == 0) {
              if (this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]) {
                this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id] = false;
                this.office_staff_workpattern_flag_changed(office_staff.id, this.col_menu_id);
              }
            } else {
              if (!this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id]) {
                this.settings.office_staff_workpattern_flags[office_staff.id][this.col_menu_id] = true;
                this.office_staff_workpattern_flag_changed(office_staff.id, this.col_menu_id);
              }
            }

            this.settings.office_staff_workpatterns[office_staff.id][this.col_menu_id].min_monthly_working_count
              = this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].min_monthly_working_count;
            this.settings.office_staff_workpatterns[office_staff.id][this.col_menu_id].max_monthly_working_count
              = this.settings.office_staff_workpatterns[office_staff.id][this.col_copied_id].max_monthly_working_count;
          });
        } else {  // this.step == 2
          this.form.staffs.forEach(staff => {
            let workpattern_id = this.settings.office_staff_shift_patterns[staff.office_staff_id][this.col_copied_id];
            this.settings.office_staff_shift_patterns[staff.office_staff_id][this.col_menu_id] = workpattern_id;
          });
        }
      }
      // 選択の解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択列の「選択を削除」実行
    clear_col_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 列選択フラグ= OFF
      this.col_copy_select = false;

      //コピー列削除
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択枠線を全て削除
    copy_remove_class() {
      let delClass = ['row_copyselect_first', 'row_copyselect_between', 'row_copyselect_last' , 'col_copyselect_first', 'col_copyselect_between', 'col_copyselect_last', 'col_copyselect_first_min', 'col_copyselect_between_min', 'col_copyselect_last_min', 'col_copyselect_first_max', 'col_copyselect_between_max', 'col_copyselect_last_max']
      for (let name_index in delClass) {
        let cellClass = document.querySelectorAll(`.${delClass[name_index]}`);
        for (let i in cellClass) {
          if (cellClass.hasOwnProperty(i)) {
            cellClass[i].classList.remove(delClass[name_index]);
          }
        }
      }
    },
    copy_menu_control: function (e) {

      // 表示サイズ取得
      let screen_width = window.innerWidth;
      let screen_height = window.innerHeight;

      // コピーメニューが横にはみ出る場合
      let width_pos = e.pageX;
      if (screen_width < width_pos + 300) {
        width_pos = width_pos - 250;
      }

      // コピーメニューが縦にはみ出る場合
      let height_pos = e.pageY;

      // メニューの表示位置設定
      this.copy_menu_position = {
        top: `${height_pos}px`,
        left: `${width_pos}px`
      };

      // メニュー表示
      if (this.row_menu_show) {
        // 行コピー
        this.row_menu_show = false;
        this.row_copy_menu_show = true;
        this.col_copied_index = -1;
        this.col_copied_id = -1;
      } else if (this.col_menu_show) {
        // 列コピー
        this.col_menu_show = false;
        this.col_copy_menu_show = true;
        this.row_copied_index = -1;
        this.row_copied_id = -1;
      } else {
        this.row_copy_menu_show = false;
        this.col_copy_menu_show = false;
      }
    },
    tab_click(number) {
      // コピー情報クリア
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;

      // 枠線クリア
      this.copy_remove_class();

      // タブ切り替え
      this.step = number;
    },
    scroll_click() {
      this.row_copy_menu_show = false;
      this.col_copy_menu_show = false;
    }
  },
}
</script>
