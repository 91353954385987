<template>
  <div style="font-size: 14px; width: 100%;">
    <div class="input_row">
      <label for="office_rules" class="required">連続勤務日数</label>
      <div>
        <div class="input_row">
          <div style="margin: auto 0;">
            <input
              v-model="office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays].day_count"
              type="radio"
              value=6
              @change="handleChangeAvoidConsecutiveNecessaryWorkdaysSet"
            >
          </div>
          <p class="radio_label">7連勤を避け、6連勤もなるべく避ける</p>
        </div>
        <p class="explain_label" style="padding: 0px!important; margin: 0px 0px 20px 30px!important;">
          6日勤務した後は必ず1日休みを割り当てます。また、5日勤務した後には、なるべく1日休みを割り当てます。
        </p>
      </div>
    </div>
    <div class="input_row">
      <label class="dummy_label"></label>
      <div>
        <div class="input_row">
          <div style="margin: auto 0;">
            <input
              v-model="office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays].day_count"
              type="radio"
              value=5
              @change="handleChangeAvoidConsecutiveNecessaryWorkdaysSet"
            >
          </div>
          <p class="radio_label">6連勤を避け、5連勤もなるべく避ける</p>
        </div>
        <p class="explain_label" style="padding: 0px!important; margin: 0px 0px 20px 30px!important;">
          5日勤務した後は必ず1日休みを割り当てます。また、4日勤務した後には、なるべく1日休みを割り当てます。
        </p>
      </div>
    </div>
    <div class="input_row">
      <label class="dummy_label"></label>
      <div>
        <div class="input_row">
          <div style="margin: auto 0;">
            <input
              v-model="office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays].day_count"
              type="radio"
              value=4
              @change="handleChangeAvoidConsecutiveNecessaryWorkdaysSet"
            >
          </div>
          <p class="radio_label">5連勤を避け、4連勤もなるべく避ける</p>
        </div>
        <p class="explain_label" style="padding: 0px!important; margin: 0px 0px 20px 30px!important;">
          4日勤務した後は必ず1日休みを割り当てます。また、3日勤務した後には、なるべく1日休みを割り当てます。
        </p>
      </div>
    </div>
    <div class="input_row">
      <label for="office_rules">連続勤務日数の対象外職員</label>

      <div style="width: calc(100% - 200px);">
        <div class="add_staff_wrap">
          <template
            v-for="(staff, index) in targetStaffList(office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays].excluded_staff_list)"
            >
            <div class="add_staff" v-if="staff" :key="index">
              <button
                class="del_staff_btn"
                @click.prevent="removeExcludedStaff(office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays], index)"
              >
                <div>
                  <span style="color: #384250;">{{ staff.name }}</span><font-awesome-icon icon="times" style="margin-left: 10px;"/>
                </div>
              </button>
            </div>
          </template>
        </div>
        <div class="input_row">
          <button id="show_modal_staffs" class="btn_add" @click.prevent="showModalStaffs($event)" style="width: 126px!important;"><span>＋</span>職員を選択</button>
          <ModalStaffs
            @save="handleChangeAvoidConsecutiveExcludedStaffsSet"
            :office_rule="office_rules[ruleIdOfAvoidConsecutiveNecessaryWorkdays]"
            :office_staffs="officeStaffsForExcluded"
            :ref="`staffModal`"
            title="職員"
          />
        </div>
        <p class="explain_label" style="padding: 6px 0px 20px 0px!important;">
          職員を登録すると設定できるようになります。
        </p>
      </div>
    </div>
    <avoid-pattern-staffs
      @avoid_pattern="setAvoidPattern"
      :avoid_pattern="office_rules[ruleIdOfAvoidPattern].avoid_pattern"
      :combo_staffs="officeStaffsForExcluded"
      :codes="codes"
      :ref="`avoidPatternStaffs`"
      title="職員"
    />
    <div class="input_row" style="display: none;">
      <label for="office_rules">連休</label>
      <!-- <input
        type="checkbox"
        v-model="office_rules[ruleIdOfAvoidConsecutiveHolidays]"
        unchecked-value=""
        :value="avoidConsecutiveHolidays"
      > -->
      <b-form-checkbox
        id="checkbox_ruleIdOfAvoidConsecutiveHolidays"
        class="checkbox"
        v-model="office_rules[ruleIdOfAvoidConsecutiveHolidays]"
        unchecked-value=""
        :value="avoidConsecutiveHolidays"
      />
      <p class="radio_label">3連休以上の休みを回避</p>
    </div>

    <div class="input_row">
      <label for="office_rules">連休</label>
      <div>
        <div class="input_row">
          <b-form-checkbox
            id="checkbox_avoiding_off_and_on_holidays"
            class="checkbox"
            v-model="office_rules[ruleIdOfAvoidingOffAndOnHolidays].on_off_flg"
            unchecked-value="0"
            value="1"
          />
          <p class="radio_label">飛び石連休の回避</p>
        </div>
        <p class="explain_label" style="padding: 6px 0px 20px 30px!important;">
          「休み→勤務→休み」の並びを回避します。
        </p>
      </div>
    </div>
    <div class="input_row">
      <label for="request_shift_work">交替制勤務の種類</label>
      <div>
        <div class="input_row">
          <b-form-select
            v-model="shift_work_type"
            :options="[{value: null, text: ''}, ...request_shift_work]"
            style="min-width: 200px; max-width: 200px;"
            @change="OnChangeShiftWork"
          />
        </div>
      </div>
    </div>
    <div class="input_row">
      <label for="office_rules">夜勤区分の配置</label>
      <div>
        <div class="input_row">
          <div style="margin: auto 0;">
            <b-form-checkbox
              id="checkbox_set_night_and_dawn_shift"
              class="checkbox"
              v-model="ruleIdOfSetNightAndDawnShiftStatus"
              unchecked-value=""
              @change="OnChangeSetNightAndDawnShift('checkbox_set_night_and_dawn_shift')"
              :disabled="is_disabled_night_and_dawn_shift"
            />
          </div>
          <p class="radio_label">「夜勤」と「明番」をセットで配置</p>
        </div>
        <p class="explain_label" style="padding: 0px!important; margin: 0px 0px 20px 30px!important;">
          勤務カテゴリが「夜勤」と「明番」に設定された勤務区分をセットで割り当てます。
        </p>
      </div>
    </div>
    <div class="input_row">
      <label class="dummy_label"></label>
      <div>
        <div class="input_row">
          <b-form-checkbox
            id="checkbox_positive_circulation_work"
            class="checkbox"
            v-model="office_rules[ruleIdOfPositiveCirculationWork].on_off_flg"
            unchecked-value="0"
            value="1"
          />
          <p class="radio_label">勤務区分を正循環で配置</p>
        </div>
        <p class="explain_label" style="padding: 6px 0px 26px 30px!important;">
          シフトの開始時間を後ろにずらしていくことをルールとするもので、シンクロシフトではこの正循環を推奨しています。<br>
          例）早番→日勤→遅番→夜勤→明番の順で配置します。
        </p>
        <div style="display: flex; width: 100%; padding: 6px 0px 26px 30px!important;">
          <div style="width: 130px; min-width: 130px;">対象外の職員</div>
          <div style="padding: 0px;">
            <v-combobox
              v-if="officeStaffsForExcluded.length === 0"
              v-model="selectedExcluedStaffs"
              :items="officeStaffsForExcluded"
              item-value="office_staff_id"
              item-text="name"
              solo
              hide-selected
              hide-details
              multiple
              label="職員の登録がないため選択できません"
              style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px;"
              disabled
            >
            </v-combobox>
            <v-combobox
              v-else
              :items="officeStaffsForExcluded"
              v-model="selectedExcluedStaffs"
              item-value="office_staff_id"
              item-text="name"
              solo
              hide-selected
              hide-details
              multiple
              label="選択してください（複数選択可）"
              style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px;"
              @change="handleChangeSelectedExcludedStaff($event)"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  color="#297ADB"
                  text-color="#FFFFFF"
                  :input-value="selected"
                >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                  <v-icon
                    color="#FFFFFF"
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalStaffs from "./ModalStaffs.vue"
import AvoidPatternStaffs from "./AvoidPatternStaffs.vue"

export default {
  components: {
    ModalStaffs,
    AvoidPatternStaffs
  },
  props: {
    office :{
      type : Object,
      required :false
    },
    rules :{
      type : Array,
      required :true
    },
    codes :{
      type : Array|Object,
      required :true
    },
  },
  watch : {
    office_rules : {
      handler (value) {
        this.$emit('office_rules', value)
      },
      deep:true
    },
    shift_work_type : {
      handler (value) {
        this.$emit('shift_work_type', value)
      },
      deep:true
    },
  },
  computed : {
    // ** v-modelとvalueの突合用に必要
    // ** office_rulesのカラム追加の場合にはここも必ず増やす！！ **
    // 指定回数以上連続で休暇にしていない オブジェクト
    avoidConsecutiveHolidays () {
      const rule = this.rules.filter(rule => rule.constraint_no === 13)[0]
      return {
        id : rule.id,
        max_count : null,
        day_count : 3,
        excluded_staff_list: [],
        avoid_pattern:[],
      }
    },
    // 飛び石連休が発生していない オブジェクト
    avoidingOffAndOnHolidays () {
      const rule = this.rules.filter(rule => rule.constraint_no === 14)[0]
      return {
        id : rule.id,
        max_count : null,
        day_count : null,
        excluded_staff_list: [],
        avoid_pattern:[],
      }
    },
    // 割り当てられた勤務区分の勤務カテゴリが正循環となっている オブジェクト
    positiveCirculationWork () {
      const rule = this.rules.filter(rule => rule.constraint_no === 1001)[0]
      return {
        id : rule.id,
        max_count : null,
        day_count : null,
        excluded_staff_list: this.selectedExcluedStaffs.map(staff => staff.office_staff_id),
        avoid_pattern:[],
      }
    },
    // 「夜勤」と「明番」をセットで配置 オブジェクト
    setNightAndDawnShift () {
      const rule = this.rules.filter(rule => rule.constraint_no === 107)[0]
      return {
        id : rule.id,
        max_count : null,
        day_count : null,
        excluded_staff_list: [],
        avoid_pattern:[],
      }
    },
    /**
     * ルールIDを取得
     */
    // 指定回数以上連続で勤務していないのrule.id(必ず)
    ruleIdOfAvoidConsecutiveNecessaryWorkdays () {
      return this.rules.filter(rule => rule.constraint_no === 21)[0].id;
    },
    // 指定回数以上連続で勤務していないのrule.id（少なくとも）
    ruleIdOfAvoidConsecutiveAtLeastWorkdays () {
      return this.rules.filter(rule => rule.constraint_no === 22)[0].id;
    },
    // 指定回数以上、休暇が連続していないのrule.id
    ruleIdOfAvoidConsecutiveHolidays () {
      return this.rules.filter(rule => rule.constraint_no === 13)[0].id;
    },
    // 飛び石連休が発生していないのrule.id
    ruleIdOfAvoidingOffAndOnHolidays () {
      return this.rules.filter(rule => rule.constraint_no === 14)[0].id;
    },
    // 勤務区分の常時配置人数の最小・最大人数を満たしているのrule.id
    ruleIdOfAlertStaffAssignment () {
      return this.rules.filter(rule => rule.constraint_no === 101)[0].id;
    },
    // 夜勤回数アラートのrule.id
    ruleIdOfAlertNightShiftCount () {
      return this.rules.filter(rule => (rule.constraint_no === 102 && rule.workpattern_category === 'night'))[0].id;
    },
    // 明番回数アラートのrule.id
    ruleIdOfAlertDawnShiftCount () {
      return this.rules.filter(rule => (rule.constraint_no === 102 && rule.workpattern_category === 'dawn'))[0].id;
    },
    // 新人のみを割り当てない
    ruleIdOfAlertNotAssignRookie () {
      return this.rules.filter(rule => rule.constraint_no === 103)[0].id;
    },
    // 割り当てられた休暇と指定した休暇数が一致している
    ruleIdOfAlertMatchHolidayCount () {
      return this.rules.filter(rule => rule.constraint_no === 104)[0].id;
    },
    // 割り当てられた勤務区分の勤務カテゴリが正循環となっているのrule.id
    ruleIdOfPositiveCirculationWork () {
      return this.rules.filter(rule => rule.constraint_no === 1001)[0].id;
    },
    // 「夜勤」と「明番」をセットで配置のrule.id
    ruleIdOfSetNightAndDawnShift () {
      return this.rules.filter(rule => rule.constraint_no === 107)[0].id;
    },
    // 回避カテゴリーのrule.id
    ruleIdOfAvoidPattern () {
      return this.rules.filter(rule => rule.constraint_no === 1004)[0].id;
    },
    /**
     * その他
     */
    // 連続勤務対象外職員指定用のスタッフリスト作成
    officeStaffsForExcluded() {
      return this.office ? this.office.staffs_for_excluded : [];
    },
      /**
     * 交替制勤務の種類のセレクトボックスのリスト
     */

     // #3882 codesの使用か、直打ちかでコードを切り替える必要あり
     request_shift_work() {
      return [
        { value: 1, text: '二交替制' },
        { value: 2, text: '三交替制' },
        { value: 3, text: 'その他' },
      ];

      // codesから取得する場合、shift_workを該当typeに変更してこちらを正とする
/*
      return this.codes.filter(function (code) {
        return code.type === "shift_work";
      }).map(request_data => {
        return {
          text: request_data.ja,
          value: request_data.code
        }
      });
*/
    },
  },
  methods : {
    // 指定回数以上の勤務でラジオボタンが選択された時の処理
    handleChangeAvoidConsecutiveNecessaryWorkdaysSet () {
      this.office_rules[this.ruleIdOfAvoidConsecutiveAtLeastWorkdays].day_count = Number(this.office_rules[this.ruleIdOfAvoidConsecutiveNecessaryWorkdays].day_count) - 1
    },
    // 指定回数以上の勤務制限に対象外職員をセットした時の処理
    handleChangeAvoidConsecutiveExcludedStaffsSet () {
      this.office_rules[this.ruleIdOfAvoidConsecutiveAtLeastWorkdays].excluded_staff_list = this.office_rules[this.ruleIdOfAvoidConsecutiveNecessaryWorkdays].excluded_staff_list
    },
    /**
     * 初期データセット
     * office_rulesのレコード作成・更新対象はここで指定
     * ここで指定しないとチェックされない限りレコードが作成されない
     *
     */
    officeRuleList () {
      let office_rules = {}
      this.rules.forEach(rule => {
        // 指定回数以上、休暇が連続していない
        if(rule.constraint_no === 13) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : 3,
            'max_count' : null,
          }
        // 飛び石連休が発生していない
        } else if(rule.constraint_no === 14) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
            'day_count' : null,
            'max_count' : null,
          }
        // 合計勤務時間
        } else if (rule.constraint_no === 16) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
          }
        // 連続勤務日数（必ず）
        } else if(rule.constraint_no === 21) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : 5,
            'max_count' : null,
          }
        // 連続勤務日数（少なくとも）
        } else if(rule.constraint_no === 22) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : 4,
            'max_count' : null,
          }
        // 勤務区分の常時配置人数の最小・最大人数を満たしている
        } else if(rule.constraint_no === 101) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : null,
            'max_count' : null,
          }
        // 夜勤回数アラート or 明番回数アラート
        } else if(rule.constraint_no === 102) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : null,
            'max_count' : 5,
          }
        // 新人のみを割り当てない
        } else if(rule.constraint_no === 103) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : null,
            'max_count' : null,
          }
        // 割り当てられた休暇と指定した休暇数が一致している
        } else if(rule.constraint_no === 104) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : null,
            'max_count' : null,
          }
        // 配置必要な勤務区分リストの最小数・最大数を満たしている
        } else if(rule.constraint_no === 107) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
            'day_count' : null,
            'max_count' : null,
          }
        // 割り当てられた勤務区分の勤務カテゴリが正循環となっている
        } else if(rule.constraint_no === 1001) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
            'day_count' : null,
            'max_count' : null,
            'excluded_staff_list' : [],
          }
        // 回避ルール
        // 配置必要な勤務区分リストの最小数・最大数を満たしている
        } else if(rule.constraint_no === 107) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
            'day_count' : null,
            'max_count' : null,
          }
        // 割り当てられた勤務区分の勤務カテゴリが正循環となっている
        } else if(rule.constraint_no === 1001) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'on_off_flg': 1,
            'day_count' : null,
            'max_count' : null,
            'excluded_staff_list' : [],
          }
        } else if(rule.constraint_no === 1004) {
          office_rules[rule.id] = {
            'id' : rule.id,
            'day_count' : null,
            'max_count' : null,
            'avoid_pattern' : [],
          }
        // 優先勤務区分の常時配置人数の最小・最大人数を満たしている
        } else if (rule.constraint_no === 301) {
          office_rules[rule.id] = {
            'id': rule.id,
            'on_off_flg': 1,
          }
        }
        // 上記以外は初期はレコード作成しない
      })
      return office_rules
    },
    /**
     * 編集時のデータセット
     * laravel側で添字0からの配列にされてしまうので配列入れ直している
     */
    officeRuleListEdit (rules) {
      let office_rules = {}
      Object.keys(rules).forEach(index => {
        // 画面上で変更できるチェックボックス項目はon_off_flgも保持する
        if (rules[index].id == this.ruleIdOfPositiveCirculationWork
          || rules[index].id == this.ruleIdOfAvoidingOffAndOnHolidays
          || rules[index].id == this.ruleIdOfSetNightAndDawnShift) {
          office_rules[rules[index].id] = {
            id : rules[index].id,
            on_off_flg : rules[index].on_off_flg,
            day_count: rules[index].day_count,
            max_count: rules[index].max_count,
            excluded_staff_list: rules[index].excluded_staff_list || [],
            avoid_pattern: rules[index].avoid_pattern || [],
          }
        } else {
          office_rules[rules[index].id] = {
            id : rules[index].id,
            day_count: rules[index].day_count,
            max_count: rules[index].max_count,
            excluded_staff_list: rules[index].excluded_staff_list || [],
            avoid_pattern: rules[index].avoid_pattern || [],
          }
        }
      })
      return office_rules
    },
    /**
     * モーダル関連
     */
    // 表示用の対象外職員リスト：共通
    targetStaffList (targetList) {
      if(!targetList) return []
      return targetList.map(office_staff_id => {
        return this.officeStaffsForExcluded.filter(staff => staff.office_staff_id === office_staff_id)[0]
      })
    },
    // 連続勤務日数の対象外職員モーダル表示
    showModalStaffs(e) {
      if(!e.pointerType) return
      if(!this.office || this.office.staffs.length < 1) {
        this.$swal({
          text: "職員が登録されていません。",
          icon: "warning",
          button: "OK",
        });
      } else {
        this.$refs['staffModal'].showModal()
      }
    },
    // 連続勤務日数の対象外職員を削除
    removeExcludedStaff(Rules, removeStaffIndex) {
      Rules.excluded_staff_list.splice(removeStaffIndex, 1);
      // 連続勤務日数の対象外職員が変更された際には、連続勤務日数（少なくとも）にも反映させる
      this.handleChangeAvoidConsecutiveExcludedStaffsSet()
    },
    // 回避ルールの対象外職員をセット
    setAvoidPattern(avoid_pattern) {
      this.office_rules[this.ruleIdOfAvoidPattern].avoid_pattern = avoid_pattern
    },
    // // コンボボックスのルール除外スタッフ選択時
    handleChangeSelectedExcludedStaff () {
      if (this.office_rules[this.ruleIdOfPositiveCirculationWork] == null || this.office_rules[this.ruleIdOfPositiveCirculationWork] == undefined) {
        this.office_rules[this.ruleIdOfPositiveCirculationWork] = {
                                                                    id : this.ruleIdOfPositiveCirculationWork,
                                                                    max_count : null,
                                                                    day_count : null,
                                                                    excluded_staff_list: [],
                                                                    avoid_pattern:[],
                                                                  }
      }
      this.office_rules[this.ruleIdOfPositiveCirculationWork].excluded_staff_list = this.selectedExcluedStaffs.map(staff => staff.office_staff_id)
    },
		// 選択済みの除外スタッフリストの初期値
		defaultSelectedExcludedStaffs () {
      if (this.office_rules[this.ruleIdOfPositiveCirculationWork] == null || this.office_rules[this.ruleIdOfPositiveCirculationWork] == undefined) {
        return [];
      } else {
			  return this.office_rules[this.ruleIdOfPositiveCirculationWork].excluded_staff_list.map(office_staff_id => {
					  return this.officeStaffsForExcluded.find(officeStaffsForExcluded => officeStaffsForExcluded.office_staff_id === office_staff_id)
				  }).filter(Boolean);
      }
		},

    // 飛び石連休を回避チェックボックス変更時
    OnChangeAvoidingOffAndOnHolidays(id) {
      let check = document.getElementById(id).checked;
      if (check || this.ruleIdOfAvoidingOffAndOnHolidaysStatus) {
        this.office_rules[this.ruleIdOfAvoidingOffAndOnHolidays] = {
                                                                    id : this.ruleIdOfAvoidingOffAndOnHolidays,
                                                                    on_off_flg : 1,
                                                                    max_count : null,
                                                                    day_count : null,
                                                                    excluded_staff_list: [],
                                                                    avoid_pattern:[],
                                                                  }
      } else {
        this.office_rules[this.ruleIdOfAvoidingOffAndOnHolidays] = {
                                                                    id : this.ruleIdOfAvoidingOffAndOnHolidays,
                                                                    on_off_flg : 0,
                                                                    max_count : null,
                                                                    day_count : null,
                                                                    excluded_staff_list: [],
                                                                    avoid_pattern:[],
                                                                  }
      }
    },

    //「夜勤」と「明番」をセットで配置チェックボックス変更時
    OnChangeSetNightAndDawnShift(id) {
      let check = document.getElementById(id).checked;
      if (check || this.ruleIdOfSetNightAndDawnShiftStatus) {
        this.office_rules[this.ruleIdOfSetNightAndDawnShift] = {
                                                                  id : this.ruleIdOfSetNightAndDawnShift,
                                                                  on_off_flg : 1,
                                                                  max_count : null,
                                                                  day_count : null,
                                                                  excluded_staff_list: [],
                                                                  avoid_pattern:[],
                                                                }
    } else {
        this.office_rules[this.ruleIdOfSetNightAndDawnShift] = {
                                                                  id : this.ruleIdOfSetNightAndDawnShift,
                                                                  on_off_flg : 0,
                                                                  max_count : null,
                                                                  day_count : null,
                                                                  excluded_staff_list: [],
                                                                  avoid_pattern:[],
                                                                }
      }
    },

    // 勤務区分を正循環で配置チェックボックス変更時
    OnChangePositiveCirculationWork(id) {
      let check = document.getElementById(id).checked;
      if (check || this.ruleIdOfPositiveCirculationWorkStatus) {
        this.office_rules[this.ruleIdOfPositiveCirculationWork] = {
                                                                    id : this.ruleIdOfPositiveCirculationWork,
                                                                    on_off_flg : 1,
                                                                    max_count : null,
                                                                    day_count : null,
                                                                    excluded_staff_list: this.selectedExcluedStaffs.map(staff => staff.office_staff_id),
                                                                    avoid_pattern:[],
                                                                  }
      } else {
        this.office_rules[this.ruleIdOfPositiveCirculationWork] = {
                                                                    id : this.ruleIdOfPositiveCirculationWork,
                                                                    on_off_flg : 0,
                                                                    max_count : null,
                                                                    day_count : null,
                                                                    excluded_staff_list: [],
                                                                    avoid_pattern:[],
                                                                  }
      }
    },
    // #3882 交替制勤務の種類変更時
    OnChangeShiftWork() {
      //「三交替制」を選択した場合のみ、「「夜勤」と「明番」をセットで配置」をオフ
      let el = document.getElementById('checkbox_set_night_and_dawn_shift');
      if (this.shift_work_type === 2) {
        this.is_disabled_night_and_dawn_shift = true;
        this.ruleIdOfSetNightAndDawnShiftStatus = false;
        el.checked = false;
      } else {
        this.is_disabled_night_and_dawn_shift = false;
      }

      // 状態の同期のためイベントを発火させる
      this.OnChangeSetNightAndDawnShift('checkbox_set_night_and_dawn_shift');
    },
  },
  data () {
    return {
      office_rules : this.office && Object.keys(this.office.rules).length
        ? this.officeRuleListEdit(this.office.rules)
        : this.officeRuleList(),
      // 選択済みの除外スタッフリスト
      selectedExcluedStaffs : [],

      // 飛び石連休回避
      ruleIdOfAvoidingOffAndOnHolidaysStatus: false,
      //「夜勤」と「明番」をセットで配置
      ruleIdOfSetNightAndDawnShiftStatus: false,
      // 勤務区分を正循環で配置
      ruleIdOfPositiveCirculationWorkStatus: false,
      //「夜勤」と「明番」をセットで配置のDisalbe制御
      is_disabled_night_and_dawn_shift: false,
      // 交替制勤務の種類
      shift_work_type: null,
    }
  },
  mounted : function() {
    this.office_rules = this.office && Object.keys(this.office.rules).length
      ? this.officeRuleListEdit(this.office.rules)
      : this.officeRuleList()
    this.$emit('office_rules', this.office_rules)

    this.shift_work_type = this.office ? this.office.shift_work_type : null;

    // 3連休以上の休みを回避のチェックボックスをONにする（v-modalの場合、checkdが効かない）
    let target_check = document.getElementById('checkbox_ruleIdOfAvoidConsecutiveHolidays');
    target_check.checked = true;

    this.selectedExcluedStaffs = this.defaultSelectedExcludedStaffs();
    this.office_rules[this.ruleIdOfPositiveCirculationWork].excluded_staff_list = this.selectedExcluedStaffs.map(staff => staff.office_staff_id);

    // 飛び石連休回避チェックボックス
    if (this.office_rules[this.ruleIdOfAvoidingOffAndOnHolidays] == null || this.office_rules[this.ruleIdOfAvoidingOffAndOnHolidays] == undefined) {
      this.ruleIdOfAvoidingOffAndOnHolidaysStatus = false;
    } else {
      this.ruleIdOfAvoidingOffAndOnHolidaysStatus = this.office_rules[this.ruleIdOfAvoidingOffAndOnHolidays].on_off_flg == 1 ? true : false;
    }
    // 状態の同期のためイベントを発火させる
    this.OnChangeAvoidingOffAndOnHolidays('checkbox_avoiding_off_and_on_holidays');

    //「夜勤」と「明番」をセットで配置チェックボックス
    if (this.office_rules[this.ruleIdOfSetNightAndDawnShift] == null || this.office_rules[this.ruleIdOfSetNightAndDawnShift] == undefined) {
      this.ruleIdOfSetNightAndDawnShiftStatus = false;
    } else {
      this.ruleIdOfSetNightAndDawnShiftStatus = this.office_rules[this.ruleIdOfSetNightAndDawnShift].on_off_flg == 1 ? true : false;
    }

    //「三交替制」を選択した場合のみ、「「夜勤」と「明番」をセットで配置」をオフ
    if (this.shift_work_type === 2) {
      this.is_disabled_night_and_dawn_shift = true;
      this.ruleIdOfSetNightAndDawnShiftStatus = false;
    } else {
      this.is_disabled_night_and_dawn_shift = false;
    }

    // 状態の同期のためイベントを発火させる
    this.OnChangeSetNightAndDawnShift('checkbox_set_night_and_dawn_shift');

    // 勤務区分を正循環で配置チェックボックス
    if (this.office_rules[this.ruleIdOfPositiveCirculationWork] == null || this.office_rules[this.ruleIdOfPositiveCirculationWork] == undefined) {
      this.ruleIdOfPositiveCirculationWorkStatus = false;
    } else {
      this.ruleIdOfPositiveCirculationWorkStatus = this.office_rules[this.ruleIdOfPositiveCirculationWork].on_off_flg == 1 ? true : false;
    }
    // 状態の同期のためイベントを発火させる
    this.OnChangePositiveCirculationWork('checkbox_positive_circulation_work');
  },
}
</script>

<style>
.table-border th, .table-border td {
   border: 1px solid darkgray !important;
}
.table td {
    vertical-align: middle;
}
.add_staff {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #D2D6DB;
    border-radius: 6px;
    width: fit-content;
    height: 28px;
    padding: 4px 4px 4px 10px;
}
.del_staff_btn {
    color: #9DA4AE;
    background: none;
    border: none;
    padding: 0 9px;
}
.add_staff_wrap {
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.custom-control-label::before {
  width: 20px!important;
  height: 20px!important;
  border-radius: 0px!important;
}
.custom-control-label::after {
  width: 20px!important;
  height: 20px!important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  width: 20px!important;
  height: 20px!important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-size: 15px!important;
}
</style>
