<template>
  <div style="display:flex;margin-left:460px;">
    <div v-for="(day, index) in days" v-if="day.hope_holiday_error" :key="index">
      <Exclamation-Triangle 
        :filterd_days_hope_holiday_error="filterd_days_hope_holiday_error"
      />
    </div>
    <div v-else="day.hope_holiday_error" style="margin-left:42px;">
      <!-- 調整のための空DIV -->
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import ExclamationTriangle from '@/Pages/Components/Svg/ExclamationTriangle.vue'

export default {
  name: 'AlertHopeHoliday',
  components: {
    ExclamationTriangle
  },
  props: {
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    shift_applies: {
      type: Object,
      default: function() {
        return {};
      }
    },
    days: { type: Array },
    basic_infos: { type: Array },
    team_id: { type: Number },
    change_total_members_count: { type: Number }
  },
  data () {
    return {
      total_min_for_day_sorted: {}, //常時配置人数の下限値の合計
      hope_holiday_count_per_date: {}, //希望休の日ごとの数
      filterd_days_hope_holiday_error: {}, //days配列を希望休エラーが起きているものでfilterしたもの
    }
  },
  methods: {
    // 常時配置人数のminの合計
    minCountListForDay() {
      // 勤務区分合計のmin
      let total_min_for_day = {}
      Object.keys(this.staff_assignments[this.team_id]).map(workpattern_id => {
        // dayごと（縦列）の合計
        Object.keys(this.staff_assignments[this.team_id][workpattern_id]).map(day => {
          // dayがdefなら飛ばす
          if (day === 'def') return
          // 初期化
          if (total_min_for_day[day] === undefined) {
            total_min_for_day[day] = 0
          }

          total_min_for_day[day] += Number(this.staff_assignments[this.team_id][workpattern_id][day].min) || Number(this.staff_assignments[this.team_id][workpattern_id]['def'].min)
        })
      })

      // mon,tue,wed,thu,fri,sat,sun,holの順に並び替え
      const day_of_weeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'hol']
      const total_min_for_day_sorted = {}
      day_of_weeks.forEach(day => {
        total_min_for_day_sorted[day] = total_min_for_day[day]
      })
      
      this.total_min_for_day_sorted = total_min_for_day_sorted
    },

    //日ごとの希望休、希望勤務を集計する処理
    calculateHopeHolidayCountPerDay(){
      let hope_holiday_count_per_date = {}
      const basic_infos_unique_staff_ids = new Set();
      this.basic_infos.forEach(item => {
        if (!basic_infos_unique_staff_ids.has(item.staff_id)) {
          basic_infos_unique_staff_ids.add(item.staff_id);
        }
      });

      basic_infos_unique_staff_ids.forEach(staff_id => {
        if(staff_id in this.shift_applies){
          let one_staff_applies = this.shift_applies[staff_id].data
          Object.entries(one_staff_applies).forEach(([date, apply_values]) => {
            if('apply' in apply_values){
              if (!hope_holiday_count_per_date[date]) {
                hope_holiday_count_per_date[date] = { holiday: 0 }
              }

              if(apply_values['apply'].workpattern && apply_values['apply'].workpattern.category === 'holiday'){
                hope_holiday_count_per_date[date].holiday += 1;
              }
            }
          })
        }
      });

      this.hope_holiday_count_per_date = hope_holiday_count_per_date
    },
    alertHopeHoliday(){
      // 常時配置人数の下限値の集計
      this.minCountListForDay()

      // 日ごとの希望休の数
      this.calculateHopeHolidayCountPerDay()

      // 希望休違反しているかどうかをdaysに入れる
      this.days.forEach((day, index) => {
        //職員数-常時配置人数の下限値の合計 < 希望休の数
        if(
          day['full'] in this.hope_holiday_count_per_date &&
          this.change_total_members_count - this.total_min_for_day_sorted[day.week_short_name] < this.hope_holiday_count_per_date[day['full']].holiday
        ){
          this.$set(this.days[index], 'hope_holiday_error', true)
        } else {
          this.$set(this.days[index], 'hope_holiday_error', false)
        }
      })

      //希望休違反している配列だけをfilter
      this.filterd_days_hope_holiday_error = this.days.filter(day =>{
        return day.hope_holiday_error
      });
    },
  },
  created(){
    this.$nextTick(() => {
      this.alertHopeHoliday()
    });
  },
  mounted() {
  }
}
</script>

