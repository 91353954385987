export default {
    /**
     * 申請理由表示の背景色
     *
     * TODO ややこしいから共通化を諦めた．今後シフト表と申請状況の統一化の際に
     * なんとかする
     *
     */
    getPriorityBgColor(shift, type) {
        if (shift === null) return '';

        const workpattern = shift.workpattern;
        if (workpattern === null) return '';
        if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return '';
        if (shift.priority === null || shift.priority === '' || shift.priority === undefined) return '';

        if (type === 'application') {
            if (workpattern.type === 'paid_holidays') { // 有給（全休）
                return '#F04438';
            } else if (workpattern.type === 'public_holidays') { // 公休（全休）
                return '#1498CC';
            } else if (workpattern.category === 'holiday') { // それ以外の休み（半休）
                replacement_workpattern = shift.replacement_workpattern;
                if (replacement_workpattern !== null) {
                    if (replacement_workpattern.category === 'early') {
                        return '#EB7209';
                    } else if (replacement_workpattern.category === 'day') {
                        return '#33A272';
                    } else if (replacement_workpattern.category === 'late') {
                        return '#808080';
                    } else if (replacement_workpattern.category === 'night') {
                        return '#684CDD';
                    } else if (replacement_workpattern.category === 'dawn') {
                        return '#684CDD';
                    } else if (replacement_workpattern.category === 'holiday') {
                        return '#B4B4B4';
                    }
                }
            } else { // 勤務
                if (workpattern.category === 'early') {
                    return '#EB7209';
                } else if (workpattern.category === 'day') {
                    return '#33A272';
                } else if (workpattern.category === 'late') {
                    return '#808080';
                } else if (workpattern.category === 'night') {
                    return '#297ADB';
                } else if (workpattern.category === 'dawn') {
                    return '#297ADB';
                } else if (workpattern.category === 'holiday') {
                    return '#B4B4B4';
                }
            }
        } else { // シフト表の場合
            if (workpattern.category === 'holiday') {
                if (shift.hoped_flg) {
                    if (workpattern.type === 'paid_holidays' || workpattern.type === 'paid_half_holiday') {
                        return '#F04438';
                    } else {
                        return '#1498CC';
                    }
                } else {
                    if (workpattern.type === 'paid_holidays' || workpattern.type === 'paid_half_holiday') {
                        return '#F04438';
                    } else if (workpattern.type === 'public_holidays' || workpattern.type === 'public_half_holiday') {
                        return '#1498CC';
                    }
                }
            } else {
                if (workpattern.category === 'early') {
                    return '#EB7209';
                } else if (workpattern.category === 'day') {
                    return '#33A272';
                } else if (workpattern.category === 'late') {
                    return '#808080';
                } else if (workpattern.category === 'night') {
                    return '#684CDD';
                } else if (workpattern.category === 'dawn') {
                    return '#684CDD';
                } else if (workpattern.category === 'holiday') {
                    return '#B4B4B4';
                }
            }
        }

        return '';
    },

    /**
     * 「希」バッジ表示の種類判定
     *
     * 0:initial 1:希望休（公休） 2:希望休（有休） 3:勤務
     *
     * TODO 申請画面とシフト表画面のロジックを統一したい
     */
    getHopeType(shift, view_type) {
        let hope_type = 0;

        // データが存在しない場合は空文字を返却
        if (shift === null) return hope_type;

        const workpattern = shift.workpattern;
        if (workpattern === null) return hope_type;
        if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return hope_type;

        if (view_type === 'application') {
            // 有休（全休）
            if (workpattern.type === 'paid_holidays') {
                hope_type = 2;
                // 公休（全休）
            } else if (workpattern.type === 'public_holidays') {
                hope_type = 1;
                // 半休
            } else if (workpattern.category === 'holiday') {
                // 有休のときは赤、それ以外のときは青のバッチで表示
                if (workpattern.type === 'paid_half_holiday') {
                    hope_type = 2;
                } else {
                    hope_type = 1;
                }
            }

            if (hope_type === 0) hope_type = 3;
        } else {
            // 勤務区分によってクラスを設定
            if (workpattern.category === 'holiday') {
                if (shift.hoped_flg) {
                    if (workpattern.type === 'paid_holidays' || workpattern.type === 'paid_half_holiday') {
                        hope_type = 2;
                    } else {
                        hope_type = 1;
                    }
                }
            } else if (workpattern.type === 'half_work' && shift.hoped_flg) {
                // 半休の情報を取得
                let holiday_type = shift.holiday_workpattern.type;
                // 半休が有休のときは赤、それ以外のときは青のバッチで表示
                if (holiday_type === 'paid_half_holiday') {
                    hope_type = 2;
                } else {
                    hope_type = 1;
                }
            }

            if (shift.hoped_flg && hope_type === 0) hope_type = 3;
        }

        return hope_type;
    },
    /**
     * 「鍵」バッジ表示の種類判定
     *
     * 0:鍵なし 1:鍵：リセット不可, 2:鍵：リセット可
     */
    getLockType(shift, view_type) {
        // 「鍵」バッチはシフトのみ表示
        if (view_type !== 'shift') return 0;

        // データが存在しない場合は終了
        if (shift === null) return 0;

        // 勤務情報がない、かつ削除していれば終了
        const workpattern = shift.workpattern;
        if (workpattern === null) return 0;
        if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return 0;

        // シフトパターン経由で保存された場合は「1:鍵：リセット不可」
        if (shift.is_locked && shift.shift_pattern_flg) {
            return 1;
        }
        
        // 希望休申請状況経由で保存され「鍵」指定があった場合は「1:鍵：リセット不可」
        if (shift.is_locked && shift.hoped_flg) {
            if (shift.entry_user_code === 'manager' || shift.entry_user_code === 'admin') {
                return 1;
            }
        }

        // シフト画面で手入力された場合は「2:鍵：リセット可」or シフト画面でシフトインポートしたとき（現状条件一緒）
        if (shift.is_locked && !shift.shift_pattern_flg && !shift.hoped_flg) return 2;

        return 0;
    },
    /**
     * 勤務区分の表示文字取得
     *
     * TODO 勤務区分名は2桁で改行、4桁表示
     */
    getWorkpatternText(text) {
        let result = text;

        // 4文字以上の場合は、4文字まで表示
        if (text.length > 4) {
            result = text.substr(0, 4);
        }

        // 2文字以上の場合は、2文字で改行
        if (result.length > 2) {
            result = result.substr(0, 2) + '\n' + result.substr(2);
        }

        return result;
    },
};
