<template>
    <b-button class="btn_white btn_autocreatefooter btn_green_outline">
        保存する
    </b-button>
</template>
<script>
export default {
    name: 'BtnSave',
};
</script>
