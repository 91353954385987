<template>
  <v-dialog v-model="dialog" persistent max-width="650" @keydown.esc="dialog = false" @click:outside="onClickOutside();">
    <div id="modalform" tabindex="-1" ref="modalform"></div>
    <v-card>
      <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
        <div></div>
        <div>{{ user.lastname + ' ' + user.firstname + '  (' + (user.team_name != null ? user.team_name : '所属チームなし') + ' ' + user.occupation_name + ')' }}</div>
        <div><button class="btn_close" @click="dialog = false">✕</button></div>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="all"
          :label="'すべて' + str"
          value="all"
          @change="changeAll"
        ></v-checkbox>

        <div style="margin-top: 10px;">
          <label v-for="(item, index) in list" class="workpattern_change_button workpattern_change_data">
            <input v-model="selected" type="checkbox" :value="item.apply.id" @change="changeSelectedList">
            <span style="display: flex; justify-content: space-between; width: 100%">
              <div style="display: flex; width: 100%;">
                <div style="width: 200px;">{{ getLabel_date(item) }}</div>
                <div>{{ getLabel_reason(item) }}</div>
              </div>
            </span>
          </label>
        </div>
      </v-card-text>
      <v-card-actions style="padding: 0;">
        <v-spacer></v-spacer>
          <div style="display: flex; justify-content: space-between; width: 100%; padding: 0px 24px 70px 24px;">
          <button class="btn_green_shadow" style="width:150px; padding: 5px 0;"@click="action1">承認</button>
          <button class="btn_green_shadow" style="width:150px; padding: 5px 0;"@click="action2">否認</button>
          <button class="btn_green_shadow" style="width:150px; padding: 5px 0;"@click="action3">キャンセル</button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'ApprovalDialog',
  components: {
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      user: {},
      list: {},
      all: null,
      selected: [],
      str: '選択',
      system_role: {
        admin: 'システム管理者',
        manager: 'シフト管理者',
        staff: 'スタッフ',
      }
    }
  },
  methods: {
    // modal open
    openDialog(user_info, shift_apply) {
      this.all = null;
      this.selected = [];
      Object.keys(this.list).forEach(key => {
        this.$delete(this.list, key);
      });
      this.user = user_info;
      for (let date in shift_apply) {
        if (
          'apply' in shift_apply[date]
          && shift_apply[date].apply.status != 'approve'
          && shift_apply[date].apply.status != 'reject'
        ) {
          this.$set(this.list, date, shift_apply[date]);
        }
      }

      this.dialog = true;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    // label設定（mm/dd text）
    getLabel_date(item) {
      const apply = item.apply;

      let text = '';
      let workpattern = null;

      if (apply == undefined || apply == null) return '';

      workpattern = apply.workpattern;
      if (workpattern == null) {
        text = '申請削除';
      } else {
        // 優先順位
        if (apply.priority != null) {
          text = text + apply.priority + ":";
        }
        // 勤務種別
        if (workpattern.type == 'work') {
          text = text + "勤務:";
        } else if (workpattern.type == 'half_work') {
          text = text + "半勤務:";
        } else if (workpattern.type == 'public_holidays') {
          text = text + "公休(全休):";
        } else if (workpattern.type == 'paid_holidays') {
          text = text + "有休(全休):";
        } else if (workpattern.type == 'paid_half_holiday') {
          text = text + "有休(半休):";
        } else if (workpattern.type == 'public_half_holiday') {
          text = text + "公休(半休):";
        } else if (workpattern.type == 'other_holiday') {
          text = text + "その他:";
        }
        // 勤務区分名
        if (workpattern.type == 'public_holidays' && apply.priority != null) {
          text = text + '希';
        } else if (workpattern.type == 'public_holidays' && apply.updated_by != null) {
          text = text + '希';
        } else if (workpattern.type == 'paid_holidays') {
          text = text + '有';
        } else if (workpattern.type == 'paid_half_holiday' || workpattern.type == 'public_half_holiday') {
          text = text + '半';
        } else {
          text = text + workpattern.name;
        }
      }

      // mm/dd text を返却
      return item.day.full.slice(5).replace('-', '/') + ' : ' + text;
    },
    // label設定（reason）
    getLabel_reason(item) {
      const apply = item.apply;

      let text = '';

      if (apply == undefined || apply == null) return '';

      let reason = '';
      let workpattern = apply.workpattern;

      if (apply.updater != null) {
        let updater = apply.updater;
        let lastname = '';
        let firstname = '';
        if (apply.updater.staff != null) {
          lastname = apply.updater.staff.lastname;
          firstname = apply.updater.staff.firstname;
        }
        reason = this.system_role[apply.updater.role] + '(' + lastname + firstname + ')による変更';
      } else if (apply.reason == null || workpattern == null) {
        reason = '';
      } else {
        reason = apply.reason;
      }

      // reason を返却
      return reason;
    },
    // 一括選択/解除
    changeAll() {
      if (this.all == 'all') {
        for (let date in this.list) {
          if (this.selected.indexOf(this.list[date].apply.id) == -1) {
            this.selected.push(this.list[date].apply.id);
          }
        }
      } else {
        this.selected.splice(0);
      }
    },
    // 選択を変更
    changeSelectedList() {
      if (Object.keys(this.list).length == this.selected.length && this.all == null) {
        this.all = 'all';
      } else if (Object.keys(this.list).length != this.selected.length && this.all == 'all') {
        this.all = null;
      }
    },
    action1() {
      let data = {};
      data.selected = this.selected;
      data.type = 'approve';
      this.$emit('approval', data, 1);
    },
    action2() {
      let data = {};
      data.selected = this.selected;
      data.type = 'reject';
      this.$emit('approval', data, 2);
    },
    action3() {
      this.dialog = false;
      this.selected = [];
    },
    onClickOutside() {
      this.$refs.modalform.focus();
      this.dialog = false;
      this.selected = [];
    },
  },
  computed: {
  },
  mounted() {
  },
}
</script>

