<template>
  <div style="font-size: 14px;">
    <h4 style="font-weight: 700; padding: 0px;">職員の{{ type === "add" ? "追加" : "編集" }}</h4>
    <p style="color: #384250; padding-top: 16px;">シフトを割り当てる職員の情報を登録してください。</p>
    <VuePerfectScrollbar style="max-height: calc(100vh - 380px); margin-top: 20px;">
      <div class="staffs_area" style="padding-bottom: 40px;">
        <h5>基本情報</h5>
        <div class="input_row">
          <label for="staff_lastname_kanji" class="required">氏名</label>
          <div>
            <input
              type="text"
              class="form-control"
              :class="{'is-invalid': errors.lastname}"
              id="staff_lastname_kanji"
              placeholder="姓"
              v-model="form.lastname"
              @blur="checkLastname"
            />
            <div v-if="errors.lastname" class="invalid-feedback">{{ errors.lastname }}</div>
          </div>
          <div>
            <input
              type="text"
              class="form-control"
              :class="{'is-invalid': errors.firstname}"
              id="staff_firstname_kanji"
              placeholder="名"
              v-model="form.firstname"
              style="margin-left: 12px;"
              @blur="checkFirstname"
            />
            <div v-if="errors.firstname" class="invalid-feedback" style="margin-left: 12px;">{{ errors.firstname }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="staff_lastname_kana">フリガナ</label>
          <div>
            <input
              type="text"
              class="form-control"
              :class="{'is-invalid': errors.lastname_ruby}"
              id="staff_lastname_kana"
              placeholder="セイ"
              v-model="form.lastname_ruby"
              @blur="checkLastname_ruby"
            />
            <div v-if="errors.lastname_ruby" class="invalid-feedback">{{ errors.lastname_ruby }}</div>
          </div>
          <div>
            <input
              type="text"
              class="form-control"
              :class="{'is-invalid': errors.firstname_ruby}"
              id="staff_firstname_kana"
              placeholder="メイ"
              v-model="form.firstname_ruby"
              style="margin-left: 12px;"
              @blur="checkFirstname_ruby"
            />
            <div v-if="errors.firstname_ruby" class="invalid-feedback" style="margin-left: 12px;">{{ errors.firstname_ruby }}</div>
          </div>
        </div>

        <div class="input_row">
          <label for="employmen_type" class="required">雇用形態</label>
          <div>
            <b-form-select
              v-model="form.employment_type"
              :class="{'is-invalid': errors.employment_type}"
              @change="handleSelectChangedEmploymentType"
              @blur.native="check_employmen_type"
            >
              <option value="" disabled selected style="display:none;">選択してください</option>
              <option
                v-for="(item, i) in employment_type_list"
                :value="item.value"
                :key="item.i"
              >
                {{ item.text }}
              </option>
            </b-form-select>
            <div v-if="errors.employment_type" class="invalid-feedback">
              {{ errors.employment_type }}
            </div>
          </div>
        </div>
        <div class="input_row">
          <label for="start_date">入社日</label>
          <div class="input_row">
            <b-form-select
              id="hire_date"
              style="width: 96px;"
              v-model="option_year['hire_date']"
              @change="setOptionYear('hire_date')"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="year in option_year_list_hire_date"
                :key="year"
                style="color: #000000;"
              >
                {{ year }}
              </option>
            </b-form-select>
            <p class="after_label">年</p>

            <b-form-select
              style="width: 76px;"
              v-model="option_month['hire_date']"
              @change="setOptionMonth('hire_date')"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="month in option_month_list_hire_date"
                :key="month"
              >
                {{ month }}
              </option>
            </b-form-select>
            <p class="after_label">月</p>
            <b-form-select
              style="width: 76px;"
              v-model="option_date['hire_date']"
              @change="setOptionDate('hire_date')"
            >
              <option value="" disabled selected style="display:none;"></option>
              <option
                v-for="date in option_date_list_hire_date"
                :key="date"
              >
                {{ date }}
              </option>
            </b-form-select>
            <p class="after_label">日</p>
          </div>
          <div v-if="errors.hire_date" class="invalid-feedback">{{ errors.hire_date }}</div>
        </div>
        <div class="input_row">
          <label for="work_length">勤続年数</label>
          <input v-model="work_length_year" type="text" class="form-control" id="work_length_year" style="width: 76px;" disabled />
          <p class="after_label">年</p>
          <input v-model="work_length_month" type="text" class="form-control" id="work_length_month" style="width: 76px;" disabled />
          <p class="after_label">ヵ月</p>
        </div>
        <div class="input_row">
          <span class="dummy_label"></span>
          <div class="custom-checkbox custom-control">
            <input
              v-model="retirement_flg"
              type="checkbox"
              id="hire_flag"
              class="custom-control-input"
              @change="setChange"
            />
            <label class="custom-control-label checkbox_label" for="hire_flag">退職済み</label>
          </div>
        </div>
        <div class="input_row" :style="'display: ' + (retirement_flg ? '' : 'none;')">
          <label for="RetirementDate"></label>
          <div>
            <div class="input_row">
              <b-form-select
                id="retirement_date"
                style="width: 96px;"
                v-model="option_year['retirement_date']"
                @change="setOptionYear('retirement_date')"
              >
                <option value="" disabled selected style="display:none;"></option>
                <option
                  v-for="year in option_year_list_retirement_date"
                  :key="year"
                  style="color: #000000;"
                >
                  {{ year }}
                </option>
              </b-form-select>
              <p class="after_label">年</p>

              <b-form-select
                style="width: 76px;"
                v-model="option_month['retirement_date']"
                @change="setOptionMonth('retirement_date')"
              >
                <option value="" disabled selected style="display:none;"></option>
                <option
                  v-for="month in option_month_list_retirement_date"
                  :key="month"
                >
                  {{ month }}
                </option>
              </b-form-select>
              <p class="after_label">月</p>
              <b-form-select
                style="width: 76px;"
                v-model="option_date['retirement_date']"
                @change="setOptionDate('retirement_date')"
              >
                <option value="" disabled selected style="display:none;"></option>
                <option
                  v-for="date in option_date_list_retirement_date"
                  :key="date"
                >
                  {{ date }}
                </option>
              </b-form-select>
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.retirement_date || !retirementDateCheck" class="input_row">
              <div v-if="errors.retirement_date" class="invalid-feedback">{{ errors.retirement_date }}</div>
            </div>
          </div>
        </div>
        <div class="input_row">
          <label>常勤換算</label>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="equivalent"
              v-model="form.non_fulltime_conversion_flg"
            />
            <label class="custom-control-label checkbox_label" for="equivalent">常勤換算の対象外にする</label>
            <p class="explain_label" style="padding: 0px 5px;">常勤換算の対象外にするがオンの場合、この職員は常勤換算表作成の対象外になります。</p>
          </div>
        </div>
      </div>
      <div class="staffs_area" style="padding-bottom: 0px;">
        <h5>資格</h5>
        <!-- 資格コンポーネント -->
        <Qualification
          @qualifications="setQualifications"
          :qualifications="qualifications"
          :codes="codes"
          :staff="staff"
          :errors="errors"
          :form_errors="form_errors"
          style="width: 100%;"
        />
      </div>
      <div class="staffs_area">
        <h5>ログイン情報（シフト申請アプリ/管理画面）</h5>
        <div class="input_row">
          <label for="office_id">拠点情報</label>
          <input
            type="text"
            class="form-control"
            style="width: 560px;"
            :value="lodgment.name"
            disabled
          />
        </div>
        <div class="input_row">
          <label for="staff_id" class="required">社員番号</label>
          <div>
            <input
              v-model="form.employee_number"
              type="text"
              class="form-control"
              style="width: 560px;"
              placeholder=""
              :class="{'is-invalid': errors.employee_number}"
              @blur="checkEmployee_number"
            />
            <div v-if="errors.employee_number" class="invalid-feedback">{{ errors.employee_number }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="mail">メールアドレス</label>
          <div>
            <input
              v-model="form.email"
              id="mail"
              type="text"
              class="form-control"
              style="width: 560px;"
              placeholder=""
              :class="{'is-invalid': errors.email}"
              @blur="checkEmail"
            />
            <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
            <p class="explain_label">職員登録後、メールアドレス宛にシフト申請アプリのログイン情報が送信されます。</p>
          </div>
        </div>
        <div class="input_row">
          <label for="password" class="required">パスワード</label>
          <div v-if="type === 'edit'">
            <b-button
              @click="showModalPasswordReset"
              btn-pink
              block
              style="width: 560px;"
              >
              パスワードを変更する
            </b-button>
            <ModalPasswordReset
              @password="setPassword"
              :open="open"
              :staff="staff"
              :ref="`passwordResetModal`"
              title="パスワード"
            />
          </div>
          <div v-else>
            <input
              v-model="form.password"
              id="password"
              type="text"
              class="form-control"
              style="width: 680px;"
              placeholder="パスワードを入力してください。※8文字以上、かつ半角英数字記号となる必要があります。"
              :class="{'is-invalid': errors.password}"
              @blur="checkPassword"
            />
            <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
          </div>
        </div>
        <div v-if="type !== 'edit'" class="input_row">
          <span class="dummy_label"></span>
          <button for="auto_password" class="btn_add" style="width: auto!important; height: 36px!important;" @click="regeneratePassword">パスワード自動生成</button>
        </div>
      </div>

      <div class="staffs_area">
        <h5>システム権限</h5>
        <div class="input_row">
          <label for="authority" class="required">システム権限</label>
          <div>
            <b-form-select
              v-model="form.role"
              :class="{'is-invalid': errors.role}"
              :disabled="!is_editable_role"
              @blur.native="checkRole"
            >
              <option value="" disabled selected style="display:none;">選択してください</option>
              <option
                v-for="(item, i) in role_list"
                :value="item.value"
                :key="item.i"
              >
                {{ item.text }}
              </option>
            </b-form-select>
            <div v-if="errors.role" class="invalid-feedback">{{ errors.role }}</div>
          </div>
        </div>
      </div>
      <div class="staffs_area" style="padding-bottom: 40px;">
        <h5>シフトに関する設定</h5>
        <div class="input_row">
          <label for="paid_holidays">有休取得可能日数</label>
          <div>
            <div class="input_row">
              <input
                v-model="form.remaining_annual_holidays"
                id="paid_holidays"
                type="number"
                class="form-control"
                style="width: 76px;"
                min="0"
                max="40"
                @wheel="number_spin_control()"
              />
              <p class="after_label">日</p>
            </div>
            <div v-if="errors.remaining_annual_holidays" class="invalid-feedback">{{ errors.remaining_annual_holidays }}</div>
          </div>
        </div>
        <div id="flg_section" class="input_row">
          <label>フラグ機能</label>
          <div>
            <div class="custom-checkbox custom-control">
              <input
                v-model="form.leader"
                id="leader"
                type="checkbox"
                class="custom-control-input"
                :class="{'is-invalid': errors.leader}"
              />
              <label class="custom-control-label checkbox_label" for="leader">リーダー</label>
            </div>
            <div v-if="errors.leader" class="invalid-feedback">{{ errors.leader }}</div>
          </div>
        </div>
        <div style="gap: 0!important; width: 100%;">
          <div class="input_row flag_line">
            <span class="dummy_label"></span>
            <div>
              <div class="custom-checkbox custom-control">
                <input v-model="form.rookie" type="checkbox" class="custom-control-input" id="rookie" />
                <label class="custom-control-label checkbox_label" for="rookie">新人フラグ</label>
              </div>
            </div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <label>期間①</label>
            <div>
              <datepicker
                v-model="form.first_rookie_end_date"
                id="first_rookie_end_date"
                check_item_name="期間①"
                placeholder="選択してください"
                :validation_check="false"
                :required_check="false"
                :check_priority="false"
                :default="form.first_rookie_end_date"
                @date="setFirstRookieEndDate"
              />
            </div>
            <div class="after_label">までは次の勤務カテゴリを選択した勤務区分は一人で勤務させない</div>
          </div>
          <div v-if="form.rookie && errors.first_rookie_end_date" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.first_rookie_end_date }}</div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <div class="rookie-detail">
              <label>対象カテゴリ</label>
              <div class="target-categories">
                <label
                  v-for="(category, key) in categories_list" :key="key"
                  class="rookie-workpattern_button"
                >
                  <input
                    type="checkbox"
                    v-model="displayCategoriesForFirstEnd[key]"
                    :id="'categories_1_' + key"
                    :value="key"
                  ><span>{{ category }}</span>
                </label>
              </div>
              <div class="after_label" style="color: #6C737F; white-space: nowrap; margin-top: 9px!important;">勤務カテゴリを選択してください。</div>
            </div>
          </div>
          <div v-if="form.rookie && errors.categories_for_first_end" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.categories_for_first_end }}</div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <div class="rookie-detail">
              <label>期間中に配置しない勤務区分</label>
              <div class="target-categories">
                <label
                  v-for="not_workpattern in not_workpattern_list" :key="not_workpattern.id"
                  class="rookie-workpattern_button"
                >
                  <input
                    v-model="displayAvoidWorkpatternsForFirstEnd[not_workpattern.id]"
                    type="checkbox"
                    :id="'workpatterns_1_' + not_workpattern.id"
                    :value="not_workpattern.id"
                  ><span>{{ not_workpattern.name }}</span>
                </label>
              </div>
              <div class="after_label" style="color: #6C737F; white-space: nowrap; margin-top: 9px!important;">勤務区分を選択してください。</div>
            </div>
          </div>
          <div v-if="form.rookie && errors.avoid_workpatterns_for_first_end" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.avoid_workpatterns_for_first_end }}</div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <label>期間②</label>
            <div>
              <datepicker
                v-model="form.second_rookie_end_date"
                id="second_rookie_end_date"
                check_item_name="期間②"
                placeholder="選択してください"
                :validation_check="false"
                :required_check="false"
                :check_priority="false"
                :default="form.second_rookie_end_date"
                @date="setRookieDate2"
              />
            </div>
            <div class="after_label">までは次の勤務カテゴリを選択した勤務区分は一人で勤務させない</div>
          </div>
          <div v-if="form.rookie && errors.second_rookie_end_date" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.second_rookie_end_date }}</div>
          </div>
          <div v-if="form.rookie && errors.rookieDateError" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.rookieDateError }}</div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <div class="rookie-detail">
              <label>対象カテゴリ</label>
              <div class="target-categories">
                <label
                  v-for="(category, key) in categories_list" :key="key"
                  class="rookie-workpattern_button"
                >
                  <input
                    type="checkbox"
                    v-model="displayCategoriesForSecondEnd[key]"
                    :id="'categories_2_' + key"
                    :value="key"
                  ><span>{{ category }}</span>
                </label>
              </div>
              <div class="after_label" style="color: #6C737F; white-space: nowrap; margin-top: 9px!important;">勤務カテゴリを選択してください。</div>
            </div>
          </div>
          <div v-if="form.rookie && errors.categories_for_second_end" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.categories_for_second_end }}</div>
          </div>
          <div v-show="form.rookie" class="rookie-detail">
            <span class="dummy_label"></span>
            <div class="rookie-detail">
              <label>期間中に配置しない勤務区分</label>
              <div class="target-categories">
                <label
                  v-for="not_workpattern in not_workpattern_list" :key="not_workpattern.id"
                  class="rookie-workpattern_button"
                >
                  <input
                    v-model="displayAvoidWorkpatternsForSecondEnd[not_workpattern.id]"
                    type="checkbox"
                    :id="'workpatterns_2_' + not_workpattern.id"
                    :value="not_workpattern.id"
                  ><span>{{ not_workpattern.name }}</span>
                </label>
              </div>
              <div class="after_label" style="color: #6C737F; white-space: nowrap; margin-top: 9px!important;">勤務区分を選択してください。</div>
            </div>
          </div>
          <div v-if="form.rookie && errors.avoid_workpatterns_for_second_end" class="rookie-detail">
            <span class="dummy_label"></span>
            <label></label>
            <div class="invalid-feedback">{{ errors.avoid_workpatterns_for_second_end }}</div>
          </div>
          <div class="input_row">
            <span class="dummy_label"></span>
            <div>
              <p class="flag_comment" style="margin-top: 10px !important;">
                新人フラグがオンの場合、新人のみでの勤務を回避します。<br />
                詳細は「
                <a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E6%96%B0%E4%BA%BA%E3%83%95%E3%83%A9%E3%82%B0%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%A7%E3%81%99%E3%81%8B" target="_blank" rel="noopener noreferrer">
                  新人フラグとは何ですか？
                  <font-awesome-icon icon="external-link-alt" style="font-size: 12px; font-weight: 600; color: #1059CC; vertical-align: text-top;" />
                </a>
                」をご覧ください。
              </p>
            </div>
          </div>
        </div>
        <div class="input_row flag_line">
          <span class="dummy_label"></span>
          <div>
            <div class="custom-checkbox custom-control">
              <input
                v-model="form.night_only_flg"
                id="night"
                type="checkbox"
                class="custom-control-input"
              />
              <label class="custom-control-label checkbox_label" for="night">夜勤専従フラグ</label>
            </div>
          </div>
        </div>
        <div class="input_row">
          <span class="dummy_label"></span>
          <div>
            <p class="flag_comment">
              夜勤専従フラグがオンの場合、この職員は
              <a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E4%BA%8B%E6%A5%AD%E6%89%80%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%9F%E3%81%84" target="_blank" rel="noopener noreferrer">
                連続勤務日数<font-awesome-icon icon="external-link-alt" style="font-size: 12px; font-weight: 600; color: #1059CC; vertical-align: text-top; padding-left: 3px;" />
              </a>
              や
              <a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E6%AD%A3%E5%BE%AA%E7%92%B0%E3%81%AE%E3%82%B7%E3%83%95%E3%83%88%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%A7%E3%81%99%E3%81%8B" target="_blank" rel="noopener noreferrer">
                正循環シフト<font-awesome-icon icon="external-link-alt" style="font-size: 12px; font-weight: 600; color: #1059CC; vertical-align: text-top; padding-left: 3px;" />
              </a>
              の配置対象外となります。
            </p>
          </div>
        </div>
        <div class="input_row flag_line">
          <span class="dummy_label"></span>
          <div class="custom-checkbox custom-control">
            <input
              v-model="form.suspension_flg"
              id="absence"
              type="checkbox"
              class="custom-control-input"
              @change="handleCheckSuspensionFlg(form.suspension_flg)"
            />
            <label class="custom-control-label checkbox_label" for="absence">休職フラグ</label>
          </div>
        </div>
        <div class="input_row">
          <span class="dummy_label"></span>
          <div>
            <p class="flag_comment">休職フラグがオンの場合、この職員はシフト作成の対象外になります。</p>
          </div>
        </div>
      </div>
      <OfficeStaff
        @office_staffs="setOfficeStaffs"
        @setChildError="setChildError"
        :offices="offices"
        :teams="teams"
        :codes="codes"
        :positions="positions"
        :occupations="occupations"
        :staff="staff"
        :team_staffs="team_staffs"
        :workpatterns="workpatterns"
        :defaultMonthlyWorkingCounts="default_monthly_working_counts"
        :staffEmploymentType="form.employment_type"
        :office_staff_kintais_at_one_office="office_staff_kintais_at_one_office"
        :office_kintai="office_kintai"
        :errors="errors"
        :form_errors="form_errors"
        style="width: 100%"
      />
    </VuePerfectScrollbar>
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>
        <BtnSaveClose
          :disabled="!checkValidation"
          @click.native="btnSaveCloseClicked"
        />
      </div>
      <div>
        <BtnBackToList
          :disabled="!checkValidation"
          @click.native="btnBackToListClicked"
        />
        <BtnSave
          :disabled="!checkValidation"
          @click.native="btnSaveClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import datepicker from "@/Pages/Components/MyDatePicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import OfficeStaff from "./OfficeStaff/OfficeStaff";
import Qualification from "./Qualification/Qualification";
import ModalPasswordReset from "./ModalPasswordReset";
import BtnBackToList from '@/Pages/Shifts/Components/Button/BtnBackToList';
import BtnSave from '@/Pages/Shifts/Components/Button/BtnSave';
import BtnSaveClose from '@/Pages/Shifts/Components/Button/BtnSaveClose';
import axios from 'axios';

export default {
  name: "Form",
  components: {
    BtnBackToList,
    BtnSave,
    BtnSaveClose,
    datepicker,
    VuePerfectScrollbar,
    OfficeStaff,
    Qualification,
    ModalPasswordReset,
  },
  props: {
    errors: {
      type: Object,
      required: false,
    },
    errorBags: {
      type: Object|Array,
      required: false,
    },
    // add or edit
    type: String,
    // 職員情報
    staff: {
      type: Object,
      required: false
    },
    // 資格
    qualifications :{
      type : Object,
      required :true
    },
    // 職種
    occupations :{
      type : Object,
      required :true
    },
    // コード
    codes :{
      type : Object,
      required :true
    },
    // 役職
    positions :{
      type : Object,
      required :true
    },
    // 事業所
    offices : {
      type : Object,
      required :true
    },
    // チーム
    teams : {
      type : Array|Object,
      required :true
    },
    // チーム職員
    team_staffs : {
      type : Array|Object,
      required :true
    },
    // 勤務区分
    workpatterns : {
      type : Object,
      required :true
    },
    // 初期表示の事業所ID
    initial_office_id : {
      type: String|null,
      required :false,
    },
    // 初期表示の事業所ID
    initial_team_id : {
      type: String|null,
      required :false,
    },
    // 最古のシステム管理者であるか
    is_oldest : {
      type: Boolean,
      required :false,
    },
    // 正規 / 非正規職員ごとの勤務カテゴリの勤務回数デフォルト値
    default_monthly_working_counts: {
      type: Object,
      required: true
    },
    office_kintai: {
      type: Array,
      required: true,
    },
    //1オフィスのoffice_staff_kintais情報 重複チェックに使いたい
    office_staff_kintais_at_one_office: {
      type: Array,
      required: true
    }
  },
  computed: {
    // 拠点
    lodgment() {
      return this.$page.props.auth.lodgment;
    },
    // 雇用形態
    employment_type_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "employment_type";
      }).map(employment_type => {
        return {
          text: employment_type.ja,
          value: employment_type.code
        }
      });
    },
    // システム権限リスト
    role_list() {
      const role = this.form.role;
      return this.codes.data.filter(function (code) {
        if(code.type !== "system_role"){
            return false;
        }
        if(code.code === "admin" && this !== "admin" && role !== "admin"){
            return false;
        }
        return true;
      }, this.user.role).map(system_role => {
        return {
          text: system_role.ja,
          value: system_role.code
        }
      });
    },
    // システム権限の変更可否の判定
    is_editable_role() {
      // システム管理者でなく、変更対象がシステム管理者である場合は変更不可
      if (this.user.role !== "admin" && this.form.role === "admin") {
        return false;
      }
      return true;
    },
    // 勤続年数
    work_length_year() {
      // 退職日（retirementDate）が未入力の場合には本日までの計算
      const retirement_date = this.form.retirement_date ? moment(this.form.retirement_date) : moment()
      if(this.form.hire_date) {
        let calcYear = Math.floor(retirement_date.diff(moment(this.form.hire_date), 'months') / 12)

        if (isNaN(calcYear)) {
          return null
        } else {
          return calcYear < 0 ? 0 : calcYear
        }
      } else {
        return null
      }
    },
    // 勤続月数
    work_length_month() {
      // 退職日（retirementDate）が未入力の場合には本日までの計算
      const retirement_date = this.form.retirement_date ? moment(this.form.retirement_date) : moment()
      if(this.form.hire_date) {
        let calcMonth = Math.floor(retirement_date.diff(moment(this.form.hire_date), 'months') % 12)

        if (isNaN(calcMonth)) {
          return null
        } else {
          return calcMonth < 0 ? 0 : calcMonth
        }
      } else {
        return null
      }
    },
    // office_staffのバックエンド側のバリデーション用
    form_errors () {
      let form_errors = {}
      if(Object.keys(this.errors).length) {
        form_errors['office_staffs'] = {}
        form_errors['offices'] = []
        form_errors['teams'] = []
        form_errors['occupations'] = []
        form_errors['qualifications'] = []

        // office_staffsのエラーを整形
        Object.keys(this.errors).forEach(error => {
          const errorArray = error.split('.');
          let target = form_errors.office_staffs;

          errorArray.forEach((key, index) => {
            if (key === 'office_staffs') return;
            target[key] = index === errorArray.length - 1 ? this.errors[error] : target[key] || {};
            target = target[key];
          });
        });

        // office_staffs以外のエラーを整形
        Object.keys(this.errors).forEach(error => {
          const [target] = this.getTargetAndField(error);
          if (target) {
            form_errors[target].push(this.errors[error]);
            return;
          }
        });
      }
      return form_errors
    },
    // 年ボックスの開始値
    option_year_start() {
      return new Date().getFullYear() - 100;
    },
    // 退職フラグの有効化
    disable_hire() {
      return this.form.hire_flag;
    },
    // 年リスト（入社日）
    option_year_list_hire_date () {
       const num = new Date().getMonth() > 5 ? 101 : 100;
       const today_year = new Date().getFullYear()
       return [...Array(num)].map((_, i) => today_year - 99 + i)
    },
    // 月リスト（入社日）
    option_month_list_hire_date () {
      // 年が選択済み
      if(this.option_year['hire_date']) {
        // 本日より6ヶ月先の年月を取得
        const sixMonthsLater = new Date();
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
        // 選択された年が本日より6ヶ月先の年と同じ場合は、本日より6ヶ月先の月までをリストにする
        if(Number(this.option_year['hire_date']) === sixMonthsLater.getFullYear()) {
          return [...Array(sixMonthsLater.getMonth() + 1)].map((_, i) => i + 1)
        }
      }
      return [...Array(12)].map((_, i) => i + 1)
    },
    // 日リスト（入社日）
    option_date_list_hire_date () {
      // 選択された年月の最終日を取得
      if(this.option_year['hire_date'] && this.option_month['hire_date']) {
        const lastDay = new Date(this.option_year['hire_date'], this.option_month['hire_date'], 0);
        return [...Array(lastDay.getDate())].map((_, i) => i + 1)
      }
      return [...Array(31)].map((_, i) => i + 1)
    },
    // 年リスト（退職日）
    option_year_list_retirement_date () {
      // 入社年が選択されていたら、入社年以降かつ、入社年月の６ヶ月後の年リストを返す
      if(this.option_year['hire_date'] && this.option_month['hire_date']) {
        const sixMonthsLater = new Date();
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
        if(Number(this.option_year['hire_date']) <= sixMonthsLater.getFullYear()) {
          return Array.from({ length: sixMonthsLater.getFullYear() - Number(this.option_year['hire_date']) + 1 }, (_, i) => Number(this.option_year['hire_date']) + i)
        }
      }
      return [...Array(100)].map((_, i) => new Date(new Date().setMonth(new Date().getMonth() + 6)).getFullYear() - 99 + i)
    },
    // 月リスト（退職日）
    option_month_list_retirement_date () {
      // 入社年月が選択されていて、かつ、退社年が選択されている
      // かつ、入社年＝退社年の場合には、入社月以降のリストを返す
      if(this.option_year['hire_date'] && this.option_month['hire_date'] && this.option_year['retirement_date']) {
        if(Number(this.option_year['hire_date']) === Number(this.option_year['retirement_date'])) {
          return [...Array(12 - Number(this.option_month['hire_date']) + 1)].map((_, i) => i + Number(this.option_month['hire_date']))
        }
      }
      return [...Array(12)].map((_, i) => i + 1)
    },
    // 日リスト（退職日）
    option_date_list_retirement_date() {
      // 選択された年月の最終日を取得
      if(this.option_year['retirement_date'] && this.option_month['retirement_date']) {
        const lastDay = new Date(this.option_year['retirement_date'], this.option_month['retirement_date'], 0);
        return [...Array(lastDay.getDate())].map((_, i) => i + 1)
      }
      return [...Array(31)].map((_, i) => i + 1)
    },
    // retirement_flgがonの時には正しい退職日が入っているかチェック
    retirementDateCheck () {
      this.$delete(this.errors, 'retirement_date');
      if (this.retirement_flg) {
        if(new Date(this.form.retirement_date) === 'Invalid Date' || this.option_year['retirement_date'] === null || this.option_month['retirement_date'] === null || this.option_date['retirement_date'] === null) {
          this.$set(this.errors, 'retirement_date', '退職日を入力してください。');
          this.createErrorInfo();
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // バリデーションエラーの有無
    checkValidation() {
      // console.log("【FORMバリデーションチェック】", this.qualificationsValidationCheck, this.officeStaffsValidationCheck, this.retirementDateCheck)
      // console.log("【FORMバリデーションチェック】, " + this.lastnameValidationCheck + "," + this.firstnameValidationCheck + "," + this.lastname_rubyValidationCheck + "," + this.firstname_rubyValidationCheck + "," + this.external_link_idValidationCheck + "," + this.employee_numberValidationCheck + "," + this.emailValidationCheck)
      return this.qualificationsValidationCheck
        && this.officeStaffsValidationCheck
        && this.retirementDateCheck
        && this.lastnameValidationCheck
        && this.firstnameValidationCheck
        && this.lastname_rubyValidationCheck
        && this.firstname_rubyValidationCheck
        && this.external_link_idValidationCheck
        && this.employment_typeValidationCheck
        && this.employee_numberValidationCheck
        && this.emailValidationCheck
        && this.passwordValidationCheck
        && this.roleValidationCheck
        && this.rookieDateValidationCheck
        && this.rookieDate1ValidationCheck
        && this.rookieDate2ValidationCheck
    },
    user() {
        return this.$page.props.auth.user;
    }
  },
  data() {
    // console.log("【スタッフ情報】", this.staff ? this.staff.data : null)
    return {
      password_length: 8,
      letters : 'abcdefghijklmnopqrstuvwxyz0123456789',
      hireDate : null,
      retirementDate : null,
      licenceAcquisitionDate :null,
      disabled : this.staff && this.staff.data && this.staff.data.retirement_date ? false : true,  // v-data-pickerのデフォルト日付入れない場合利用
      // モーダル表示用
      open : false,
      zoho_link : 'https://support.synchroshift.jp/portal/en/kb/articles/%E4%BA%8B%E6%A5%AD%E6%89%80%E3%81%8C%E5%89%8A%E9%99%A4%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84',
      form: this.staff
        ? this.staff.data
        : {
          // userテーブル
          user_id :"",
          employee_number: "",
          role: "",
          // staffテーブル
          external_link_id: "",
          email: "",
          password: "",
          lastname: "",
          firstname: "",
          lastname_ruby: "",
          firstname_ruby: "",
          employment_type: "",
          non_fulltime_conversion_flg:0,
          remaining_annual_holidays:0,
          hire_date :null,
          retirement_date:null,
          rookie:0,
          first_rookie_end_date: null,
          categories_for_first_end: [],
          avoid_workpatterns_for_first_end: [],
          second_rookie_end_date: null,
          categories_for_second_end: [],
          avoid_workpatterns_for_second_end: [],
          leader:0,
          suspension_flg:0,
          night_only_flg:0,
          suspension_type:'',
          // qualificationコンポーネント用
          qualifications:[],
          // office_staffコンポーネント用
          office_staffs : [],
      },
      option_year : {},  // 入社年 and 退職年
      option_month : {}, // 入社月 and 退職月
      option_date : {},  // 入社日 and 退職日
      retirement_flg : false, // 退職済みフラグ
      open_date_count: 0, // 入社日・退職日のホバー制御
      open_date_flg: false, // 入社日・退職日のホバー制御
      qualificationsValidationCheck : true, // 資格情報のバリデーションチェック
      officeStaffsValidationCheck : false, // 事業所・チーム・職種・勤務区分・シフトパターン等のバリデーションチェック
      lastnameValidationCheck : true,  // 氏名（姓）のバリデーションチェック
      firstnameValidationCheck : true,  // 氏名（名）のバリデーションチェック
      lastname_rubyValidationCheck : true,  // フリガナ（姓）のバリデーションチェック
      firstname_rubyValidationCheck : true,  // フリガナ（名）のバリデーションチェック
      external_link_idValidationCheck : true,  // 外部連携先社員IDのバリデーションチェック
      employment_typeValidationCheck : true, // 雇用形態のバリデーションチェック
      employee_numberValidationCheck : true,  // 社員番号のバリデーションチェック
      emailValidationCheck : true,  // Emailのバリデーションチェック
      passwordValidationCheck : true, // パスワードのバリデーションチェック
      roleValidationCheck: true, // システム権限のバリデーションチェック
      childError: {}, // 子コーポネントのエラー
      rookieDateValidationCheck: true, // 新人フラグ期間大小バリデーションチェック
      rookieDate1ValidationCheck: true, // 新人フラグ期間①バリデーションチェック
      rookieDate2ValidationCheck: true, // 新人フラグ期間①バリデーションチェック
      categories_list: {},
      not_workpattern_list: [],
      displayCategoriesForFirstEnd: {},
      displayCategoriesForSecondEnd: {},
      displayAvoidWorkpatternsForFirstEnd: {},
      displayAvoidWorkpatternsForSecondEnd: {},
      categories: { early: "早番", day: "日勤", late: "遅番", night: "夜勤", dawn: "明番", holiday: "休暇" },
    }
  },
  created: function() {
    if (this.type === 'add') {
      this.employment_typeValidationCheck = false;
      this.employee_numberValidationCheck = false;
      this.passwordValidationCheck = false;
      this.roleValidationCheck = false;
    }
  },
  watch: {
    form : {
      handler (value) {
          this.$emit('setChange', true);
      },
      deep: true
    },
    'form.remaining_annual_holidays':{
      handler(val, oldval){
        this.form.remaining_annual_holidays = val ? val : 0;
      },
      immediate: true
    },
    // 退職日を設定するかどうかのチェックボックスの状態を監視
    retirement_flg : {
      handler(val, oldval){
        if (val) {
          // this.form.retirement_date = new Date();
        } else {
          this.option_year['retirement_date'] = null;
          this.option_month['retirement_date'] = null;
          this.option_date['retirement_date'] = null;
          this.form.retirement_date = null;
        }
      },
      immediate: true
    },
    'form.categories_for_first_end': {
      handler() {
        this.updateDisplayCategoriesForFirstEnd();
      },
      immediate: true,
    },
    'form.categories_for_second_end': {
      handler() {
        this.updateDisplayCategoriesForSecondEnd();
      },
      immediate: true,
    },
    'form.avoid_workpatterns_for_first_end': {
      handler() {
        this.updateDisplayAvoidWorkpatternsForFirstEnd();
      },
      immediate: true,
    },
    'form.avoid_workpatterns_for_second_end': {
      handler() {
        this.updateDisplayAvoidWorkpatternsForSecondEnd();
      },
      immediate: true,
    },
    displayCategoriesForFirstEnd: {
      handler(val) {
        this.form.categories_for_first_end = Object.keys(val).filter(key => val[key]);
      },
      deep: true
    },
    displayCategoriesForSecondEnd: {
      handler(val) {
        this.form.categories_for_second_end = Object.keys(val).filter(key => val[key]);
      },
      deep: true
    },
    displayAvoidWorkpatternsForFirstEnd: {
      handler(val) {
        this.form.avoid_workpatterns_for_first_end = Object.keys(val)
          .filter(key => val[key]).map(key => parseInt(key));
      },
      deep: true
    },
    displayAvoidWorkpatternsForSecondEnd: {
      handler(val) {
        this.form.avoid_workpatterns_for_second_end = Object.keys(val)
          .filter(key => val[key]).map(key => parseInt(key));
      },
      deep: true
    },
  },
  methods: {
    // 新人 期間① の日付設定と妥当性チェック
    setFirstRookieEndDate(date) {
      this.rookieDate1ValidationCheck = true;
      this.$delete(this.errors, 'first_rookie_end_date');
      this.rookieDateValidationCheck = true;
      this.$delete(this.errors, 'rookieDateError');

      if (date === null || date === '' || date === undefined) {
        this.form.first_rookie_end_date = null;
      } else {
        let formatCheckDate = this.checkFormatDate(date);
        if (formatCheckDate === null) {
          this.form.first_rookie_end_date = null;
          this.rookieDate1ValidationCheck = false;
          this.$set(this.errors, 'first_rookie_end_date', '期間①の日付が正しくありません。');
        } else {
          this.form.first_rookie_end_date = formatCheckDate;
          this.checkRookieDate();
        }
      }
    },
    // 新人 期間② の日付設定と妥当性チェック
    setRookieDate2(date) {
      this.rookieDate2ValidationCheck = true;
      this.$delete(this.errors, 'second_rookie_end_date');
      this.rookieDateValidationCheck = true;
      this.$delete(this.errors, 'rookieDateError');

      if (date === null || date === '' || date === undefined) {
        this.form.second_rookie_end_date = null;
      } else {
        let formatCheckDate = this.checkFormatDate(date);
        if (formatCheckDate === null) {
          this.form.second_rookie_end_date = null;
          this.rookieDate2ValidationCheck = false;
          this.$set(this.errors, 'second_rookie_end_date', '期間②の日付が正しくありません。');
        } else {
          this.form.second_rookie_end_date = formatCheckDate;
          this.checkRookieDate();
        }
      }
    },
    /* 日付フォーマットチェックをして、日付ならフォーマットして返却 */
    checkFormatDate(value) {
      // フォーマットチェック
      value = value.replace(/-/g, '/');
      if (!value.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
        return null;
      }

      // 存在する日付かチェック
      let dateObject = new Date(value);
      if (isNaN(dateObject.getTime())) {
        return null;
      }

      // 正しい日付
      return value;
    },
    /**
     * 変更フラグ初期化
     */
    initializeChange() {
      this.$nextTick(() => {
        this.$emit('setChange', false);
      });
    },
    /**
     * 変更したことを親画面に通知
     */
    setChange() {
      this.$emit('setChange', true);
    },
    /**
     * バリデーション
     */
    /** 親画面に渡すエラー情報生成 **/
    createErrorInfo() {
      // エラーメッセージ作成
      let errorMessage = '';
      let formError1 = false;
      for (let key in this.errors) {
        errorMessage += this.errors[key] + '<br> ';
        formError1 = true;
      }
      let formError2 = false;
      for (let key in this.childError) {
        errorMessage += this.childError[key] + '<br> ';
        formError2 = true;
      }

      // 親画面にエラー情報送信
      this.$emit('setchildError', formError1 || formError2, errorMessage);
    },
    /** 親画面に渡す子コーポネントのエラー情報生成 **/
    setChildError(errors) {
      this.childError =  Object.assign({}, errors);
      this.createErrorInfo();
/*
      // エラーメッセージ作成
      let errorMessage = '';
      for (let key in errors) {
        errorMessage += errors[key] + '<br> ';
      }
      let allValidationCheck = Object.keys(errors).length > 0 ? true : false;

      // 親画面にエラー情報送信
      this.$emit('setchildError', allValidationCheck, errorMessage);
*/
    },
    /** すべてのエラーチェックをする **/
    check_all() {
      this.checkLastname();
      this.checkFirstname();
      this.checkLastname_ruby();
      this.checkFirstname_ruby();
      this.checkLastname_ruby();
      this.checkExternal_link_id();
      this.check_employmen_type();
      this.checkEmployee_number();
      this.checkEmail();
      this.checkPassword();
      this.checkRole();
      /*
      return this.qualificationsValidationCheck
        && this.officeStaffsValidationCheck
        && this.retirementDateCheck
        && this.lastnameValidationCheck
        && this.firstnameValidationCheck
        && this.lastname_rubyValidationCheck
        && this.firstname_rubyValidationCheck
        && this.external_link_idValidationCheck
        && this.employment_typeValidationCheck
        && this.employee_numberValidationCheck
        && this.emailValidationCheck
        && this.passwordValidationCheck
        && this.roleValidationCheck
        */


    },
     /** 氏名（姓） 入力チェック **/
    checkLastname () {
      let check_string = this.form.lastname;
      if (check_string === null || check_string === '') {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'lastname', '氏名（姓）は必ず入力してください。');
      } else if (check_string.match(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/) || check_string.match(/[！”＃＄％＆’（）￥＊＋－．、／：；＜＝＞？＠［］＾＿‘｛｜｝～]/)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'lastname', '氏名（姓）に記号は入力できません。');
      } else if (/^[0-9]*$/.test(check_string)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'lastname', '氏名（姓）に数値は入力できません。');
      } else if (/^[Ａ-Ｚ]*$/.test(check_string)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'lastname', '氏名（姓）に全角英字は入力できません。');
      } else {
        this.lastnameValidationCheck = true;
        this.$delete(this.errors, 'lastname');
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** 氏名（名） 入力チェック **/
    checkFirstname () {
      let check_string = this.form.firstname;
      if (check_string === null || check_string === '') {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'firstname', '氏名（名）は必ず入力してください。')
      } else if (check_string.match(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/) || check_string.match(/[！”＃＄％＆’（）￥＊＋－．、／：；＜＝＞？＠［］＾＿‘｛｜｝～]/)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'firstname', '氏名（名）に記号は入力できません。')
      } else if (/^[0-9]*$/.test(check_string) || /^[０-９]*$/.test(check_string)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'firstname', '氏名（名）に数値は入力できません。')
      } else if (/^[Ａ-Ｚ]*$/.test(check_string)) {
        this.lastnameValidationCheck = false;
        this.$set(this.errors, 'firstname', '氏名（名）に全角英字は入力できません。')
      } else {
        this.lastnameValidationCheck = true;
        this.$delete(this.errors, 'firstname')
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** フリガナ（姓） 入力チェック **/
    checkLastname_ruby () {
      let check_string = this.form.lastname_ruby;
      if (check_string === '' || check_string.match(/^[ァ-ヶー　]+$/)) {
        this.lastname_rubyValidationCheck = true;
        this.$delete(this.errors, 'lastname_ruby')
      } else {
        this.lastname_rubyValidationCheck = false;
        this.$set(this.errors, 'lastname_ruby', 'フリガナ（姓）は全角カタカナで入力してください。')
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** フリガナ（名） 入力チェック **/
    checkFirstname_ruby () {
      let check_string = this.form.firstname_ruby;
      if (check_string === '' || check_string.match(/^[ァ-ヶー　]+$/)) {
        this.firstname_rubyValidationCheck = true;
        this.$delete(this.errors, 'firstname_ruby')
      } else {
        this.firstname_rubyValidationCheck = false;
        this.$set(this.errors, 'firstname_ruby', 'フリガナ（名）は全角カタカナで入力してください。')
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** 外部連携先社員ID 入力チェック **/
    checkExternal_link_id () {
      let check_string = this.form.external_link_id;
      if (/^[A-Za-z0-9]*$/.test(check_string)) {
        this.external_link_idValidationCheck = true;
        this.$delete(this.errors, 'external_link_id')
      } else {
        this.external_link_idValidationCheck = false;
        this.$set(this.errors, 'external_link_id', '外部連携先社員IDは半角英数字で入力してください。')
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** 雇用形態 入力チェック **/
    check_employmen_type() {
      let check_string = this.form.employment_type;
      if (check_string === null || check_string === '') {
        this.employment_typeValidationCheck = false;
        this.$set(this.errors, 'employment_type', '雇用形態は必ず入力してください。');
      } else {
        this.employment_typeValidationCheck = true;
        this.$delete(this.errors, 'employment_type')
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** 社員番号 入力チェック **/
    checkEmployee_number () {
      let check_string = this.form.employee_number;
      this.employee_numberValidationCheck = true;
      this.$delete(this.errors, 'employee_number');
      if (check_string === null || check_string === '') {
        this.employee_numberValidationCheck = false;
        this.$set(this.errors, 'employee_number', '社員番号は必ず入力してください。');
      } else {
        if (!/^[A-Za-z0-9]*$/.test(check_string)) {
          this.employee_numberValidationCheck = false;
          this.$set(this.errors, 'employee_number', '社員番号は半角英数字で入力してください。');
        }
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** email 入力チェック **/
    checkEmail () {
      let check_string = this.form.email;

      // エラークリア
      this.emailValidationCheck = true;
      this.$delete(this.errors, 'email');

      if(check_string === '' || check_string === null || check_string === undefined) {
        // 管理者の場合はメールアドレス必須
        if (this.form.role === 'admin') {
          this.emailValidationCheck = false;
          this.$set(this.errors, 'email', 'システム権限が「システム管理者」の場合、メールアドレスも指定してください。');
        }
      } else {
        // 入力がある場合は形式チェック
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(check_string)) {
          this.emailValidationCheck = false;
          this.$set(this.errors, 'email', 'メールアドレスの形式が正しくありません。');
        }
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** Password 入力チェック **/
    checkPassword () {
      let check_string = this.form.password;

      // エラークリア
      this.passwordValidationCheck = true;
      this.$delete(this.errors, 'password');

      if (check_string === null || check_string === '') {
        this.passwordValidationCheck = false;
        this.$set(this.errors, 'password', 'パスワードは必ず入力してください。');
      } else {
        if (check_string.match(/[^\x01-\x7E]/) || !check_string.match(/[^\uFF65-\uFF9F]/)) {
          this.passwordValidationCheck = false;
          this.$set(this.errors, 'password', 'パスワードは半角英数字記号で入力してください。');
        } else if (check_string.length < 8) {
          this.passwordValidationCheck = false;
          this.$set(this.errors, 'password', 'パスワードは8文字以上で入力してください。');
        }
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** システム権限 入力チェック **/
    checkRole() {
      // エラークリア
      this.roleValidationCheck = true;
      this.$delete(this.errors, 'role');

      let check_string = this.form.role;
      if (check_string === null || check_string === '') {
        this.roleValidationCheck = false;
        this.$set(this.errors, 'role', 'システム権限は必ず入力してください。');
      } else {
        // 管理者の場合はメールアドレス必須
        let check_mail = this.form.email;
        if(check_mail === '' || check_mail === null || check_mail === undefined) {
          if (this.form.role === 'admin') {
            this.emailValidationCheck = false;
            this.$set(this.errors, 'email', 'システム権限が「システム管理者」の場合、メールアドレスも指定してください。');
          } else{
            this.emailValidationCheck = true;
            this.$delete(this.errors, 'email');
          }
        }
      }
      // 親画面へ返却用のエラーチェック
      this.createErrorInfo();
    },
    /** 新人期間の妥当性チェック **/
    checkRookieDate() {
      this.rookieDateValidationCheck = true;
      this.$delete(this.errors, 'rookieDateError');

      // 日付が入力されて日付の妥当性OKなら期間①＜期間②であるかチェック
      if (this.form.first_rookie_end_date !== null && this.form.first_rookie_end_date !== '' && this.form.first_rookie_end_date !== undefined &&
          this.form.second_rookie_end_date !== null && this.form.second_rookie_end_date !== '' && this.form.second_rookie_end_date !== undefined) {
        if (!(this.form.second_rookie_end_date > this.form.first_rookie_end_date)) {
          this.rookieDateValidationCheck = false;
          this.$set(this.errors, 'rookieDateError', '期間②は期間①より後の日付を設定してください。');
        }
      }
    },
    /**
     * フォーム送信用
     */
    // 事業所・チーム・職種・勤務区分・シフトパターン等のコンポーネントより
    setOfficeStaffs (...args) {
      const [value, check] = args;
      this.officeStaffsValidationCheck = check;
      this.form.office_staffs = value;

      // 配置しない勤務区分のリスト作成
      this.not_workpattern_list = [];
      this.form.office_staffs.forEach(staff => {
        staff.workpatterns.forEach(workpattern => {
          // not_workpattern_listに重複しないように追加する
          if (!this.not_workpattern_list.some(item => item.id === workpattern.id)) {
            this.not_workpattern_list.push({
                id: workpattern.id,
                name: workpattern.name,
            });
          }
        });
      });

      this.not_workpattern_list.forEach(item => {
        if (this.displayAvoidWorkpatternsForFirstEnd.hasOwnProperty(item.id)) {
          this.displayAvoidWorkpatternsForFirstEnd[item.id] = false;
        }
        if (this.displayAvoidWorkpatternsForSecondEnd.hasOwnProperty(item.id)) {
          this.displayAvoidWorkpatternsForSecondEnd[item.id] = false;
        }
      });

      Object.keys(this.displayAvoidWorkpatternsForFirstEnd).forEach(key => {
        if (!this.not_workpattern_list.some(item => item.id === Number(key))) {
          this.$delete(this.displayAvoidWorkpatternsForFirstEnd, key);
        }
      });
      Object.keys(this.displayAvoidWorkpatternsForSecondEnd).forEach(key => {
        if (!this.not_workpattern_list.some(item => item.id === Number(key))) {
          this.$delete(this.displayAvoidWorkpatternsForSecondEnd, key);
        }
      });
      this.updateDisplayAvoidWorkpatternsForFirstEnd();
      this.updateDisplayAvoidWorkpatternsForSecondEnd()
    },
    // 資格選択コンポーネントより
    setQualifications (...args) {
      const [value, check] = args;

      this.qualificationsValidationCheck = check;
      this.form.qualifications = value;

      // 親へ渡すエラー情報生成
      if (this.qualificationsValidationCheck) {
        this.$delete(this.errors, 'qualifications');
      } else {
        this.$set(this.errors, 'qualifications', '同じ資格が選択されています');
      }
      this.createErrorInfo();
    },
    // パスワード変更モーダルから
    setPassword(value) {
      this.form.password = value
    },
    /**
     * セレクトボックス選択時
     */
    // 雇用形態
    handleSelectChangedEmploymentType (val) {
      this.form.employment_type = val
    },
    // 休職フラグ選択時の休職種別リセット
    handleCheckSuspensionFlg (val) {
      if(!val) {
        this.form.suspension_type = ''
      }
    },
    /**
     * 制御
     */
    btnSaveCloseClicked() {
      this.save(true, true)
    },
    btnBackToListClicked() {
      this.$emit('nextStep', 3);
    },
    btnSaveClicked() {
      this.save(false, false, 3)
    },
    // 保存処理
    // 「保存して終了」は「保存しました」を表示する
    // 「保存して終了」、右上の「✕」の時は保存後に画面を閉じる
    // 「次へ」の場合は、保存後に「チームの設定」へ遷移
    async save(message_flg = true, close_flg = true, nextstep = -1) {
      Object.keys(this.errors).forEach(key => {
        this.$delete(this.errors, key)
      })
      // チーム選択データの形式修正
      let office_staffs = []
      this.form.office_staffs.forEach((office_staff, index) => {
        office_staffs[index] = JSON.parse(JSON.stringify(office_staff))
        office_staffs[index].team_ids = []
        Object.keys(office_staff.team_ids).forEach(i => {
          if(typeof office_staff.team_ids[i] === 'object' && office_staff.team_ids[i] !== undefined) {
            // 既存のチームリストからの選択
            office_staffs[index].team_ids.push(Number(office_staff.team_ids[i].value))
          } else {
            // 新規入力チーム
            // console.log('新規チーム作成', office_staffs[index].new_team_names)
            office_staffs[index].new_team_names.push(office_staff.team_ids[i])
          }
        })
      })

      // office_staffs.*.occupations.*.percetagesの合計をoffice_staffs.*.percentageにセット
      office_staffs.forEach((office_staff, index) => {
        let percentage = 0
        office_staff.occupations.forEach(occupation => {
          percentage += Number(occupation.percentage)
        })
        office_staffs[index].percentage = percentage
      })

      if(this.type === 'edit') {
        // 変更対象が最古のシステム管理者である場合は警告メッセージを表示しない
        if(!this.is_oldest && (this.staff.data.role === 'manager' || this.staff.data.role === 'admin') && this.form.role === 'staff'){
          let result = await this.$swal({
              text: 'システム権限をスタッフに変更すると、シンクロシフトにログインできなくなりますがよろしいですか？',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'はい',
              cancelButtonText: 'いいえ',
              customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
          })
          if(!result.value) {
            return
          }
        }
        // 変更対象が最古のシステム管理者であり、システム管理者以外のロールに変更されようとした場合
        if (this.is_oldest && this.staff.data.role === 'admin' && this.form.role !== 'admin') {
          let errorResult = await this.$swal({
            icon: 'error',
            title: "登録エラー",
        html: "シンクロシフト利用登録時に作成された管理者の権限は変更できません。変更が必要な場合は<a href='https://synchroseries.jp/synchroshift/contact_form.html' target='_blank' rel='noopener noreferrer'>サポート</a>までご連絡ください。",
          });
          if(errorResult.value) {
            return
          }
        }
        // console.log("【編集／PUTデータ】", this.form)
        this.updateLoading(true);
        this.form = {
          ...this.form,
          qualifications: this.form.qualifications.length ? this.form.qualifications : [],
          office_staffs : office_staffs,
          office_id : this.initial_office_id,
          team_id : this.initial_team_id,
          categories_for_first_end: (this.form.categories_for_first_end.length > 1) ? this.form.categories_for_first_end : null,
          categories_for_second_end: (this.form.categories_for_second_end.length > 1) ? this.form.categories_for_second_end : null,
          avoid_workpatterns_for_first_end: (this.form.avoid_workpatterns_for_first_end.length > 1) ? this.form.avoid_workpatterns_for_first_end : null,
          avoid_workpatterns_for_second_end: (this.form.avoid_workpatterns_for_second_end.length > 1) ? this.form.avoid_workpatterns_for_second_end : null,
        };
        axios.put(this.$route('shifts.staffs.update', this.staff.data.id), this.form)
          .then(result => {
            this.updateLoading(false);
            if (nextstep > -1) {
              this.$swal(
                '保存しました',
                '',
                'success')
              .then(() => {
                this.$emit('nextStep', nextstep);
              })
            } else if (close_flg) {
              this.$swal(
                '保存しました',
                '',
                'success')
                .then(() => {
                  this.$emit('closeDialog');
                })
            }
          })
          .catch(error => {
            this.updateLoading(false);
            if (error.response.status === 422) {
              for(const [key, messages] of Object.entries(error.response.data.errors)) {
                this.$set(this.errors, key, messages.join(', '));
              }
              if(document.getElementsByClassName('is-invalid')[0]) {
                document.getElementsByClassName('is-invalid')[0].focus();
              }
            }
          });
          return
        }

        // console.log("【新規作成／POSTデータ】", this.form)
        this.updateLoading(true);
        // 配列データをjson化してPOST
        this.form = {
          ...this.form,
          qualifications: this.form.qualifications.length ? this.form.qualifications : [],
          office_staffs : office_staffs,
          office_id : this.initial_office_id,
          team_id : this.initial_team_id,
          categories_for_first_end: (this.form.categories_for_first_end.length > 1) ? this.form.categories_for_first_end : null,
          categories_for_second_end: (this.form.categories_for_second_end.length > 1) ? this.form.categories_for_second_end : null,
          avoid_workpatterns_for_first_end: (this.form.avoid_workpatterns_for_first_end.length > 1) ? this.form.avoid_workpatterns_for_first_end : null,
          avoid_workpatterns_for_second_end: (this.form.avoid_workpatterns_for_second_end.length > 1) ? this.form.avoid_workpatterns_for_second_end : null,
        };
        axios.post(this.$route('shifts.staffs.store'), this.form)
          .then(result => {
            this.updateLoading(false);
            if (nextstep > -1) {
              this.$swal(
                '保存しました',
                '',
                'success')
                .then(() => {
                  this.$emit('nextStep', nextstep);
                })
            } else if (close_flg) {
              this.$swal(
                '保存しました',
                '',
                'success')
              .then(() => {
                this.$emit('closeDialog');
              })
            }
          })
          .catch(error => {
              this.updateLoading(false);
              if (error.response.status === 422) {
                for(const [key, messages] of Object.entries(error.response.data.errors)) {
                  this.$set(this.errors, key, messages.join(', '));
                }
                if(document.getElementsByClassName('is-invalid')[0]) {
                  document.getElementsByClassName('is-invalid')[0].focus();
                }
              }
          });
    },

    pageSend() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    // エラーの対象となるフィールドを返す
    getTargetAndField(error) {
      if (error.indexOf('office_staffs') !== -1) {
        if (error.indexOf('team_ids') !== -1) return ['teams'];
        if (error.indexOf('occupations') !== -1) {
          if (error.endsWith('id') || error.endsWith('percentage') || error.endsWith('main_flg')) {
            return ['occupations'];
          }
        }
        // 事業所全体の割合のエラーは、職種のエラーとして扱う
        if(error.endsWith('percentage')) {
          return ['occupations'];
        }
        if (error.endsWith('office_id') || error.endsWith('position')) {
          return ['offices'];
        }
      } else if (error.indexOf('qualifications') !== -1) {
        return [ 'qualifications'];
      }
      return [];
    },
    /** 有休取得可能日数にカーソルがあるときは画面スクロールしないようにする **/
    number_spin_control () {
      let scrollOffsetY = window.pageYOffset;
      window.scrollTo(0, scrollOffsetY);
    },
    /**
     * その他
     */
    // パスワード生成
    regeneratePassword() {
      let result = '';
      this.passwordValidationCheck = true;
      this.$delete(this.errors, 'password');
      for (let i = 0; i < this.password_length; i++) {
        result +=  this.letters[Math.floor(Math.random() * this.letters.length)];
      }
      this.form.password = result
    },
    // パスワードリセットモーダル
    showModalPasswordReset() {
      this.$refs['passwordResetModal'].showModal()
    },
    /**
     * 年のプルダウン選択時（入社日・退職日共通）
     */
    setOptionYear (name) {
      if(this.option_year[name] && this.option_month[name] && this.option_date[name]) {
        this.form[name] = this.option_year[name] + '-' + ("00" + this.option_month[name]).slice( -2 ) + '-' + ("00" + this.option_date[name]).slice( -2 )
      } else {
        this.form[name] = null
      }

      // ホバー制御
      let date_select = document.getElementById(name);
      date_select.style.color = "#000000";
      this.open_date_flg = false;

      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
    /**
     * 月のプルダウン選択時（入社日・退職日共通）
     */
    setOptionMonth (name) {
      if(this.option_year[name] && this.option_month[name] && this.option_date[name]) {
        // 存在しない日付の場合には、月末日をセット
        const last_day = new Date(this.option_year[name], this.option_month[name], 0).getDate()
        if (this.option_date[name] > last_day) {
          this.form[name] = this.option_year[name]
            + '-'
            + ("00" + this.option_month[name]).slice( -2 )
            + '-'
            + last_day

          // 日のプルダウンを更新
          this.option_date[name] = last_day

          // 日のリスト(option_date_list_hire_date, option_date_list_retirement_date)を更新


        } else {
          this.form[name] = this.option_year[name] + '-' + ("00" + this.option_month[name]).slice( -2 ) + '-' + ("00" + this.option_date[name]).slice( -2 )
        }
      } else {
        this.form[name] = null
      }

      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
    /**
     * 日のプルダウン選択時（入社日・退職日共通）
     */
    setOptionDate (name) {
      if(this.option_year[name] && this.option_month[name] && this.option_date[name]) {
        this.form[name] = this.option_year[name] + '-' + ("00" + this.option_month[name]).slice( -2 ) + '-' + ("00" + this.option_date[name]).slice( -2 )
      } else {
        this.form[name] = null
      }

      // 親画面に変更を通知
      this.$emit('setChange', true);
    },
    // ローディング表示
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    /**
     * 年の未選択時はシステム年を選択 ホバー制御
     */
    setYearDefult(elem) {
      let setYear = new Date().getFullYear();
      for (let i=0; elem.length>i; i++) {
        if(elem.options[i].value === setYear) {
          elem.options[i].selected = true;
          elem.style.color = '#FFFFFF';
          this.open_date_flg = true;
          break;
        }
      }
    },
    /**
     * 年のフォーカス取得時（入社日・退職日共通） ホバー制御
     */
    focusYear(e) {
      if (e.target.selectedIndex < 0) {
        this.setYearDefult(e.target);
      }
    },
    /**
     * 年のクリック時（入社日・退職日共通） ホバー制御
     */
    clickYear(e) {
      // 奇数:open／偶数:close
      this.open_date_count++;
      if (this.open_date_count % 2 === 0) {
        if (e.target.selectedIndex < 0) {
          this.setYearDefult(e.target);
        }
      } else {
        if (this.open_date_flg) {
          e.target.selectedIndex = -1;
        }
      }
    },
    /**
     * 年のフォーカスアウト時（入社日・退職日共通） ホバー制御
     */
    focusoutYear(e) {
      this.open_date_count = 0;
      e.target.style.color = '#000000';
      if (this.open_date_flg) {
        e.target.selectedIndex = -1;
        this.open_date_flg = false;
      }
    },
    updateDisplayCategoriesForFirstEnd() {
      if (!this.form.categories_for_first_end) {
        return;
      }

      this.form.categories_for_first_end.forEach((category) => {
        this.$set(this.displayCategoriesForFirstEnd, category, true);
      });
    },
    updateDisplayCategoriesForSecondEnd() {
      if (!this.form.categories_for_second_end) {
        return;
      }

      this.form.categories_for_second_end.forEach((category) => {
        this.$set(this.displayCategoriesForSecondEnd, category, true);
      });
    },
    updateDisplayAvoidWorkpatternsForFirstEnd() {
      if (!this.form.avoid_workpatterns_for_first_end) {
        return;
      }

      this.form.avoid_workpatterns_for_first_end.forEach((workpattern) => {
        this.$set(this.displayAvoidWorkpatternsForFirstEnd, workpattern, true);
      });
    },
    updateDisplayAvoidWorkpatternsForSecondEnd() {
      if (!this.form.avoid_workpatterns_for_second_end) {
        return;
      }

      this.form.avoid_workpatterns_for_second_end.forEach((workpattern) => {
        this.$set(this.displayAvoidWorkpatternsForSecondEnd, workpattern, true);
      });
    },
  },
  mounted() {
    this.updateLoading(false);
    // 編集の場合のみ
    if(this.type === 'edit') {
      const hire_date = this.staff.data.hire_date
      const retirement_date = this.staff.data.retirement_date

      // 入社年 or 退職年
      this.option_year = {
        'hire_date' : hire_date ? new Date(hire_date).getFullYear() : null,
        'retirement_date' : retirement_date ? new Date(retirement_date).getFullYear() : null
      }
      // 入社月 or 退職月
      this.option_month = {
        'hire_date' : hire_date ? new Date(hire_date).getMonth() + 1 : null,
        'retirement_date' : retirement_date ? new Date(retirement_date).getMonth() + 1 : null
      }
      // 入社日 or 退職日
      this.option_date = {
        'hire_date' : hire_date ? new Date(hire_date).getDate() : null,
        'retirement_date' : retirement_date ? new Date(retirement_date).getDate() : null
      }
      // 退職フラグ
      this.retirement_flg = retirement_date ? true : false
    }
    // holidayを除外したカテゴリーリストを作成
    this.categories_list = { ...this.categories };
    if (this.categories_list.holiday) {
      delete this.categories_list.holiday;
    }

    // 新規作成の場合は各カテゴリーの初期値を false で設定
    if (!this.form.categories_for_first_end) {
      this.categories_list.forEach((category) => {
        this.displayCategoriesForFirstEnd[category] = false;
      });
    }
    if (!this.form.categories_for_second_end) {
      this.categories_list.forEach((category) => {
        this.displayCategoriesForSecondEnd[category] = false;
      });
    }

    // セレクトボックスのクリックイベント
    let hire_date_select = document.getElementById('hire_date');
    hire_date_select.addEventListener("focus", this.focusYear);
    hire_date_select.addEventListener("click", this.clickYear);
    hire_date_select.addEventListener("focusout", this.focusoutYear);
    let retirement_date_select = document.getElementById('retirement_date');
    retirement_date_select.addEventListener("focus", this.focusYear);
    retirement_date_select.addEventListener("click", this.clickYear);
    retirement_date_select.addEventListener("focusout", this.focusoutYear);

    // 変更フラグ初期化
    this.initializeChange();
  },
  beforeDestroy() {
    // イベントリスナー削除
    window.removeEventListener("focus", this.focusYear);
    window.removeEventListener("click", this.clickYear);
    window.removeEventListener("focusout", this.focusoutYear);
  },
}
</script>

<style scoped>
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #d92550;
}
.btn_save_disabled {
  border-color: #ccc;
  cursor: not-allowed;
  color: #FFFFFF!important;
  font-size: 14px!important;
  width: 60px!important;
  background: #ccc!important;
  border-radius: 8px!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 10px!important;
}
.flag_line {
  margin-top: -15px!important;
}
.flag_comment {
  color: #6C737F;
  padding: 0px 0px!important;
  margin-top: -15px!important;
}
.rookie-detail {
  display: flex;
}
.rookie-detail label {
  min-width: 200px;
  max-width: 200px;
}
.target-categories label {
  min-width: 60px;
  max-width: max-content;
}
.target-categories input[type="checkbox"] {
    display: none;
}
.rookie-workpattern_button span {
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    border: solid 1px #D2D6DB;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0px 3px 0px #0000000F;
    padding: 6px 8px;
    margin-right: 8px;
}
.rookie-workpattern_button input:checked + span {
    color: #FFFFFF;
    background: #3f6ad8ff;
}
</style>
