<template>
  <div class="m-3 p-3">
    <h3 class="pb-3">利用料金個別規約　-プラン変更・解約されるお客様へ（よくお読みください）-</h3>

    <p class="font-weight-bold">◆無料体験期間、または、機能制限フリープランから有料のプランへ変更する場合の注意事項</p>
    <p>・無料体験期間、または、機能制限フリープランから有料のプランへ変更する場合は、プラン変更画面にて申請願います。</p>
    <p>・無料体験期間から有料プランへ申請する場合は、無料体験期間終了後より有料プランが適用されます。</p>
    <p>・機能制限フリープランから有料プランへ変更する場合は、申請日より有料プランが適用されます。</p>
    <p>・支払い方法は、月払いと年払いの2種類から選択可能です。</p>
    <p class="pb-3">　※月払い契約：クレジットカードのみ対象となります。　年払い契約：クレジットカード及び、請求書を選択可能です。</p>

    <p class="font-weight-bold">◆月払い契約から年払い契約へ変更する場合の注意事項</p>
    <p>・月払い契約から年払い契約へ変更する場合は以下の申請日により、プラン変更日が異なります。</p>
    <p>Ｌ申請月の1日～14日の期間で申請した場合は、翌月1日より支払い方式に変更されます。</p>
    <p>Ｌ申請月の15日～末日の期間で申請した場合は、翌々月1日より支払い方式に変更されます。</p>
    <p class="pb-3">・お支払済みの費用は払い戻しできませんのでご注意ください。</p>

    <p class="font-weight-bold">◆プラン変更される場合の注意事項</p>
    <p>・年払い契約から月払い契約に変更した場合は、年払い契約の期間終了後から、月払い契約に変更されます。</p>
    <p class="pb-3">・お支払済みの費用は払い戻しできませんのでご注意ください。</p>

    <p class="font-weight-bold">◆有料プランからご解約（機能制限プランへ移行）する場合の注意事項</p>
    <p>・月払い契約からご解約（機能制限フリープランへ移行）の申請する場合は以下の申請日により、解約日が異なります。</p>
    <p>Ｌ申請月の1日～14日の期間で申請した場合は、翌月1日より機能制限フリープランに変更されます。</p>
    <p>Ｌ申請月の15日～末日の期間で申請した場合は、翌々月1日より機能制限フリープランに変更されます。</p>
    <p>・有料プランからご解約（機能制限フリープランへ移行）された後も引き続き、本サービスを利用できますが、機能に制限が発生するためご注意ください。</p>
    <p>　※シフト表の自動作成、勤怠管理システムとの連携機能、過去に作成したシフト表や勤務形態一覧表の閲覧が制限されます。</p>
    <p>・年払い契約のお客様がご解約（機能制限フリープランへ移行）される場合は、年払い契約の期間終了後に解約となります。</p>
    <p>・本サービスの運営上、解約（機能制限フリープランへ移行）されたお客様のうち一定期間ご利用実績がないと判断された場合はアカウントの削除を行う場合があります。</p>
    <p>　※アカウントの削除を行う場合は定期配信メールにて通知します。</p>
    <p>　　（解約後もメールアドレスの配信許可設定をお願いします。）</p>
    <p class="pb-3">・アカウント削除後に再度契約される場合においてデータの復旧はできかねるためご注意ください。</p>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-lg" style="width: 200px;" @click="planeditItem(form.id)">同意して次へ</button>
    </div>
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";

export default {
  data() {
    console.log('【lodgment_data】',this.base)
    return {
      form: Inertia.form(this.base),
      errors: {}
    };
  },
  mounted() {
      this.updateLoading(false);
  },
  methods: {
    planeditItem (id) {
      this.updateLoading(true);
      this.$inertia.get(route('lodgments.plan-edit', id))
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
};
</script>