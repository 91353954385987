<template>
  <div class="m-3 p-3">
    <div class="card-shadow-primary card-border card" style="height: 80vh !important;">
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-primary">
          <div class="menu-header-content">
            <div class="avatar-icon-wrapper avatar-icon-lg">
              <div class="avatar-icon rounded btn-hover-shine">
                <img :src="$url('/images/avatar.jpg')" alt="山田太郎" />
              </div>
            </div>
            <div>
              <h5 class="menu-header-title">
                <ruby>
                  {{ staff.lastname }}<rt>{{ staff.lastname_ruby }}</rt>
                </ruby>
                &nbsp;
                <ruby>
                  {{ staff.firstname }}<rt>{{ staff.firstname_ruby }}</rt>
                </ruby>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-area-lg">
        <VuePerfectScrollbar class="scrollbar-container" v-once>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">拠点</div>
                        <div class="col-9">{{ lodgment.lodgment_name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">所属事業所</div>
                        <div class="col-9"><span v-for="(item, index) in offices.office_list"><span v-if="index > 0">, </span>{{ !item.name ? "" : item.name }}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">社員番号</div>
                        <div class="col-9">{{ user.employee_number }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">メールアドレス</div>
                        <div class="col-9">{{ user.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">雇用形態</div>
                        <div class="col-9">{{ getEmploymentTypeName(staff.employment_type) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">入社日</div>
                        <div class="col-9">{{ str_to_date(staff.hire_date) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper d-block">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      <div class="row">
                        <div class="col-3">勤続年数</div>
                        <div class="col-9">{{ work_length }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper justify-content-center">
                  <div class="widget-content-left">
                    <div class="widget-heading">資格</div>
                  </div>
                  <div class="" style="position: absolute; right: 0;">
                    <b-button size="sm" @click="addItem()">新規追加</b-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-for="(item, index) in qualifications" :key="index">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading mr-4">{{ item.name }}</div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">{{ str_to_date(item.pivot.obtained_date) }}</div>
                  </div>
                  <div class="ml-auto">
                    <b-button size="sm" @click="editItem(item)">変更</b-button>
                    <b-button size="sm" @click="deleteQualification(index, item)">削除</b-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </VuePerfectScrollbar>
      </div>
      <!-- error message -->
      <div v-if="errors.profile" class="invalid-feedback">
        {{ errors.profile }}
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faArrowLeft,
  faAngleUp,
  faDotCircle,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    VuePerfectScrollbar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    staff: {
      type: Object,
      required: true,
    },
    lodgment: {
      type: Object,
      required: true,
    },
    offices: {
      type: Object,
      required: true,
    },
    qualifications: {
      type: Array,
      required: true,
    },
    codes: {
      type: Object,
      required :true,
    },
    errors: {
      type: Object,
      required :false,
    }
  },

  data() {
    return {
      form: this.staff
        ? this.$inertia.form(this.staff.data)
        : this.$inertia.form({
          name: '',
          obtained_date: ''
        }),
    }
  },
  computed: {
    // 雇用タイプリスト
    employment_type_list () {
      return this.codes.data.filter(function (code) {
        return code.type === "employment_type";
      });
    },
    // 勤続年数
    work_length() {
      // 退職日（retirementDate）が未入力の場合には本日までの計算
      const retirement_date = this.staff.retirement_date ? moment(this.staff.retirement_date) : moment();
      if(this.staff.hire_date) {
          return Math.floor(retirement_date.diff(moment(this.staff.hire_date), 'months') / 12)
            + '年'
            + Math.floor(retirement_date.diff(moment(this.staff.hire_date), 'months') % 12)
            + 'ヶ月'
      } else {
        return null
      }
    },
  },

  methods: {
    // 変更ボタン
    editItem(item) {
      this.$inertia.get(route('profiles.edit', item.pivot.id), {})
    },
    // 追加ボタン
    addItem() {
      this.$inertia.get(route('profiles.add'), {})
    },
    // 削除ボタン
    deleteQualification: function (index, item) {
      this.$swal
        .fire({
          icon: 'warning',
          text: '本当に削除しますか？',
          showConfirmButton: true,
          confirmButtonText: '削除',
          showCancelButton: true,
          cancelButtonText: 'キャンセル',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$inertia.delete(route('profiles.remove', item.pivot.id),
            {
              preserveScroll: true,
              onError: (p) => {
                this.$swal(
                  'error',
                  '資格の削除に失敗しました。しばらくたってから再試行してください。',
                  'error')
              },
              onSuccess: async ()=>{
                await this.qualifications.splice(index, 1)
                await this.$swal(
                  '資格を削除しました',
                  '',
                  'success')
                await location.reload()
              }
            })
          }
        })
    },
    // 雇用形態名取得
    getEmploymentTypeName (value) {
      return value ? this.employment_type_list.find(employment_type => employment_type.code === value).ja : null
    },
    // 日付の文字列変換
    str_to_date(date_str) {
      if(!date_str) return null

      const date_object = new Date(date_str);
      const year = date_object.getFullYear();
      const month = date_object.getMonth() + 1;
      const date = date_object.getDate();
      return year + "年" + month + "月" + date + "日";
    },
  },

};
</script>
<style>
.scroll-area-lg {
  height: 100vh !important
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #d92550;
}
</style>
