<template>
  <div style="font-size: 14px;">
    <h4 style="padding-bottom: 26px;">拠点管理</h4>
    <p>拠点情報、ご契約プラン変更、解約はこちら</p>
    <Form @submit.prevent="saveAlert">
      <div class="lodgments_area">
        <h5>基本情報</h5>
        <div class="input_row">
          <label for="office_id" class="required">拠点番号</label>
          <div>
            <input type="text" class="form-control"  id="number" v-model="form.number" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="office_name" class="required">拠点名</label>
          <div>
            <input type="text" class="form-control long_control" :class="{'is-invalid': errors.name}" id="name" v-model="form.name" placeholder="拠点名を入力してください。" maxlength="64" required>
            <div class="invalid-feedback">{{errors.name}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="corporation_name" class="required">法人名</label>
          <div>
            <input type="text" class="form-control long_control" :class="{'is-invalid': errors.corporate_name}" id="corporate_name" v-model="form.corporate_name" maxlength="64" placeholder="法人名を入力してください。">
            <div class="invalid-feedback">{{errors.corporate_name}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="Invoice_name">請求書先名</label>
          <div>
            <input type="input" class="form-control long_control" id="Billing_Name"  v-model="form.Billing_Name" maxlength="64" placeholder="請求書先名を入力してください。">
          </div>
        </div>
        <div class="input_row">
          <label for="zipcode" class="required">郵便番号</label>
          <div>
            <input type="text" class="form-control" :class="{'is-invalid': errors.zip_code}" id="zip_code" v-model="form.zip_code" maxlength="7" placeholder="郵便番号を入力してください。">
            <div class="invalid-feedback">{{errors.zip_code}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="state">都道府県</label>
          <div>
            <b-form-select id="pref" v-model="form.pref" 
              :options="pref_code"
              value-field="code"
              text-field="ja">
            </b-form-select>
          </div>
        </div>
        <div class="input_row">
          <label for="address">市区町村</label>
          <div>
            <input type="text" class="form-control long_control" :class="{'is-invalid': errors.Billing_City}" id="Billing_City" v-model="form.Billing_City" maxlength="255" placeholder="住所を入力してください。">
            <div class="invalid-feedback">{{errors.Billing_City}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="block_number">番地</label>
          <div>
            <input type="text" class="form-control long_control" :class="{'is-invalid': errors.Billing_Street}" id="Billing_Street" v-model="form.Billing_Street" maxlength="255">
            <div class="invalid-feedback">{{errors.Billing_Street}}</div>
          </div>
        </div>
<!--
        <div class="input_row">
          <label for="building">建物名</label>
          <div>
            <input type="text" class="form-control long_control">
          </div>
        </div>
-->
      </div>
      <div class="lodgments_area">
        <h5>ご利用プラン</h5>
        <div class="input_row">
          <label for="plan">現在のプラン</label>
          <div>
            <b-form-select
              id="plan_name"
              v-model="form.plan_name"
              :options="plan_code"
              class="long_control"
              value-field="code"
              text-field="ja"
              disabled>
            </b-form-select>
          </div>
        </div>
        <div class="input_row">
          <label for="price_1">契約人数</label>
          <div>
            <input type="text" class="form-control" id="price_1" v-model="form.ContractUserCount" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="end_date">支払い方法</label>
          <div>
            <input type="text" class="form-control" id="next_plan_start_date" v-model="form.Payment_Type" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="start_date">利用開始日</label>
          <div>
            <input type="text" class="form-control" id="plan_start_date" v-model="form.plan_start_date" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="end_date" :class="set_end_date_color()">無料体験終了日</label>
          <div>
            <input type="text" :class="set_end_date_color()" class="form-control" id="next_plan_start_date" v-model="form.Freetrial_End_Date" disabled>
          </div>
        </div>
        <!-- プラン変更をしたら表示、変更しない場合は非表示 -->
        <div class="input_row">
          <label for="end_date">プラン変更日</label>
          <div>
            <input type="text" class="form-control" id="next_plan_start_date" v-model="form.Planchange_Date" disabled>
          </div>
        </div>
        <!-- 有料化日は無料体験中にスタンダードプランへの切り替えた場合、利用開始日から最大3か月後の日付が入るため、スタンダードプランの場合に表示する必要があります。 -->
        <div class="input_row">
          <label for="end_date">有料化日</label>
          <div>
            <input type="text" class="form-control" id="next_plan_start_date" v-model="form.Charge_Date" disabled>
          </div>
        </div>
        <!-- プラン変更をしたら表示、変更しない場合は非表示？-->
        <div class="input_row">
          <label for="billing" :class="set_cancel_color()">解約日</label>
          <div>
            <input type="text" :class="set_cancel_color()" class="form-control" id="billing" v-model="form.Cancel_Date" disabled>
          </div>
        </div>
        <div class="input_row">
          <label for="price_2" :class="set_cancel_color()">契約終了日</label>
          <div>
            <input type="text" :class="set_cancel_color()" class="form-control" id="price_2" v-model="form.Expire_Date" disabled>
          </div>
        </div>
        <div v-if="form.plan_name == 'trial'" class="input_row">
          <div class="dummy_label"></div>
          <div>
            無料体験期間中にスタンダードプラン（有料）のご契約をいただけない場合は、シフトの自動作成等の機能が制限されます。<br />
            スタンダードプラン（有料）のご契約については、下記のボタンからお手続きをお願いします。機能制限については<a href="https://support.synchroshift.jp/portal/ja/kb/articles/%E3%83%97%E3%83%A9%E3%83%B3%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
          </div>
        </div>
        <div class="input_row">
          <div class="dummy_label"></div>
          <!-- 新規プラン契約
          <div>
            <b-button class="btn_blue_line" style="width: 350px;" @click="planeditItem(form.id)">シンクロシフトご契約はこちら</b-button>
          </div>
          -->
          <div>
            <b-button class="btn_blue_line" style="width: 350px;" @click="planeditItem(form.id)">プランの変更方法はこちら</b-button>
          </div>
        </div>
      </div>
      <b-button class="btn_blue" :disabled="isDisabled" @click="saveAlert">保存</b-button>
      <p v-if="form.plan_name != 'free'" class="link_gray" style="padding-top: 37px;">解約は<span @click="plancancel(form.id)">こちら</span></p>
    </Form>
  </div>
</template>

<style scoped>
.color-red {
  color: #D61515;
}
</style>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";

export default {
  components: {
    datepicker
  },
  props: {
    base:Object,
    pref_code:Array,
    plan_code:Array,
  },
  data() {
    console.log('【lodgment_data】',this.base)
    return {
      form: Inertia.form(this.base),
      errors: {}
    };
  },
  computed: {
    isDisabled() {
      return Object.keys(this.errors).length > 0 ? true : false
    }
  },
  watch: {
    'form.name'(name,name2) {
      if (name!='') {
        this.$delete(this.errors, 'name')
      } else {
        this.$set(this.errors, 'name', '拠点名を入力してください。')
      }
    },
    'form.corporate_name'(corporate_name,corporate_name2) {
      if (corporate_name!='') {
        this.$delete(this.errors, 'corporate_name')
      } else {
        this.$set(this.errors, 'corporate_name', '法人名を入力してください。')
      }
    },
    'form.external_link_id'(external_link_id,external_link_id2) {
      if (external_link_id.match(/^[0-9]+$/) || external_link_id=='') {
        this.$delete(this.errors, 'external_link_id');
      } else {
        this.$set(this.errors, 'external_link_id', '外部連携先拠点IDは半角数字で入力してください。')
      }
    },
    'form.zip_code':{
      handler(zip_code,zip_code2){
        if (zip_code.match(/^\d{7}$/)) {
          this.$delete(this.errors, 'zip_code')
        } else {
          this.$set(this.errors, 'zip_code', '郵便番号はハイフン抜きの7桁の半角数字で入力してください。')
        }
      },
      immediate: true
    },
    'form.Billing_City':{
      handler() {
        if (this.check_maxlength_address()) {
          this.$delete(this.errors, 'Billing_City')
          this.$delete(this.errors, 'Billing_Street')
        } else {
          this.$set(this.errors, 'Billing_City', '市区町村と番地は合わせて255文字以内で入力してください。')
        }
      },
      immediate: true
    },
    'form.Billing_Street':{
      handler() {
        if (this.check_maxlength_address()) {
          this.$delete(this.errors, 'Billing_City')
          this.$delete(this.errors, 'Billing_Street')
        } else {
          this.$set(this.errors, 'Billing_Street', '市区町村と番地は合わせて255文字以内で入力してください。')
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.updateLoading(false);
    this.setFormatDate()
  },
  updated() {
    this.setFormatDate()
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    saveAlert() {
      this.$swal({
        text: "保存しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '保存',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.form.put(route('lodgments.update'), {
            onSuccess:()=>{
              this.updateLoading(false);
              this.$swal(
                '保存しました',
                '',
                'success')
            },
            onError: () => {
              this.updateLoading(false);
            },
          })
          this.setFormatDate()
        }
      });
    },
    planeditItem (id) {
      this.updateLoading(true);
        this.$inertia.get(route('lodgments.plan-edit'),{})
    },
    setFormatDate() {
      // YYYY/MM/DDにフォーマット
      let date = plan_start_date.value
      if (date === '') return
      plan_start_date.value = date.replace(/-/g, '/')
    },
    plancancel (id) {
      this.updateLoading(true);
      this.$inertia.get(route('lodgments.plan-cancel-2'),{});
    },
    set_end_date_color() {
      if (this.form.Freetrial_End_Date != '' && this.form.Freetrial_End_Date != null && this.form.Freetrial_End_Date != undefined) {
        // システム日付 >= 無料体験終了日 の場合は赤表示 
        let systemDate = new Date();
        systemDate.setHours(0, 0, 0, 0);
        let getDate = this.form.Freetrial_End_Date.replace(/\//g, '-');
        let endDate = new Date(getDate);

        // 日付を比較
        if (endDate >= systemDate) {
          return 'color-red';
        }
      }
    },
    set_cancel_color() {
      // 解約日に日付が入っていれば、解約日、契約終了日を赤表示
      if (this.form.Cancel_Date != '' && this.form.Cancel_Date != null && this.form.Cancel_Date != undefined) {
        return 'color-red';
      }
    },
    check_maxlength_address() {
      let check_string = this.form.Billing_City + this.form.Billing_Street;

      if (check_string.length > 255) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
