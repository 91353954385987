<template>
  <div style="font-size: 14px;">
    <h4 style="padding-bottom: 26px;">ご利用プラン変更</h4>
    <p>ご契約更新、ご利用プランはこちら</p>
    <Form id="Form1" @submit.prevent="changeAlert">
      <input
        type="hidden"
        v-model="form.Pre_Payment_Method"
      />
      <input
        type="hidden"
        v-model="form.Pre_Payment_Type"
      />
      <input
        type="hidden"
        v-model="form.Pre_ContractUserCount_year"
      />
      <input
        type="hidden"
        v-model="form.Pre_ContractUserCount_month"
      />
      <input
        type="hidden"
        v-model="form.Freetrial_End_Date"
      />

      <!-- クレジットカード支払いを一時凍結するのhiddenで必要な値を渡す -->
      <input type="hidden" name="Payment_Type" value="年払い">
      <input type="hidden" name="Payment_Method" value="請求書">

      <div class="lodgments_area">
        <h5>ご利用プラン</h5>
        <div class="input_row">
          <label for="plan_name">現在のプラン</label>
          <div>
            <b-form-select id="plan_name"
              v-model="form.plan_name"
              :options="plan_code"
              class="long_control"
              value-field="ja"
              text-field="ja"
              disabled
            />
          </div>
        </div>
        <div class="input_row">
          <label for="change_plan_name">変更後のプラン</label>
          <div>
            <b-form-select id="change_plan_name" v-model="form.change_plan_name"
              :options="change_plan_list"
              :class="{'is-invalid': errors.change_plan_name}"
              class="long_control"
              value-field="ja"
              text-field="ja"
              placeholder="選択してください"
            />
            <div class="invalid-feedback">{{errors.change_plan_name}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="ContractUserCount">契約人数</label>
          <div>
            <b-form-select id="ContractUserCount_month" v-model="form.ContractUserCount_month" :style="'display: ' + (isYearDisabled ? 'none' : '') + ';'">
              <option v-for="n in 301" :disabled="n-1 < base.ContractUserCount_month" v-bind:value="n-1">{{ n-1 }}名</option>
            </b-form-select>
            <b-form-select id="ContractUserCount_year" v-model="form.ContractUserCount_year" :style="'display: ' + (isMonthDisabled ? 'none' : '') + ';'">
              <option v-for="n in 61" :disabled="(n-1) * 5 < base.ContractUserCount_year" v-bind:value="(n-1) * 5">{{ (n-1) * 5 }}名</option>
            </b-form-select>
          </div>
        </div>
        <div class="input_row">
          <label for="change_plan_date" class="required">プラン変更日</label>
          <div class="date_width change_plan_date">
            <datepicker
              v-model="form.change_plan_date"
              id="change_plan_date"
              @date="setChangeDate"
              @check_result="setChangeDateErr"
              check_item_name="プラン変更日"
              placeholder="選択してください"
              :validation_check="false"
              :required_check="false"
              :check_priority="true"
              :class="{'is-invalid': errors.change_plan_date}"
              :default="form.change_plan_date"
            />
            <div class="invalid-feedback">{{errors.change_plan_date}}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="plan_change_date">プラン変更申請日</label>
          <div>
            <input type="text" class="form-control" v-model="form.plan_change_date" disabled>
          </div>
        </div>
      </div>

      <VuePerfectScrollbar class="terms">
        <table class="table-terms">
          <tr>
            <td class="table-header">契約に関する個別規約</td>
          </tr>
          <tr>
            <td>
              <p class="font-weight-bold">無料プラン、または機能制限無料プランからスタンダードプラン（有料プラン）へ変更する場合の注意事項</p>
              <p>・無料プラン、または機能制限無料プランからスタンダードプランへ変更する場合は、こちらのご利用プラン変更画面での申請が必要です。</p>
              <p>・無料プランからスタンダードプランへ変更する場合は、無料体験終了日の翌月よりスタンダードプランが適用されます。</p>
              <p>・機能制限無料プランからスタンダードプランへ変更する場合は、プラン変更日からスタンダードプランが適用されます。</p>
              <p class="font-weight-bold">スタンダードプランから解約（機能制限無料プランへ移行）する場合の注意事項</p>
              <p>・解約後、一定期間ご利用実績がない場合はアカウントの削除を行います。</p>
              <p>※アカウント削除後に再度契約される場合でも、データの復旧はできません。</p>
            </td>
          </tr>
        </table>
      </VuePerfectScrollbar>
      <div class="consent">
        <v-checkbox id="consent" v-model="consent" /><label for="consent" style="margin-right: 37px;">規約に同意する</label>
        <v-checkbox id="privacy" v-model="privacy" /><label for="privacy"><a href="https://hp.syncube.co.jp/privacypolicy.html" target="_blank" rel="noopener noreferrer">プライバシーポリシーに同意する</a></label>
      </div>
      <button type="button" class="btn btn-primary btn-lg" style="width: 200px;" :disabled="!isDisabled" @click="changeAlert">申請</button>
    </Form>

    <b-modal id="stripe-modal" @shown="stripesetting" hide-footer>
      <form class="has-text-justified" id="cardform" @submit.prevent="createCard" >
        <div class="modal-card">
          <header>
            <p>カード情報追加</p>
          </header>
          <section>
            <div class="form-group">
              <label for="name">カード番号</label>
              <div id="cardNumber" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">セキュリティコード</label>
              <div id="securityCode" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">有効期限</label>
              <div id="expiration" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">カード名義</label>
              <input type="text" name="cardName" id="cardName" class="form-control" value="" placeholder="カード名義を入力">
            </div>
            <div class="form-group">
              <button type="submit" id="create_token" class="btn btn-primary">カードを登録する</button>
            </div>
          </section>
        </div>
      </form>
    </b-modal>

    <b-modal id="stripe-Change-modal" @shown="stripesetting" hide-footer>
      <form class="has-text-justified" id="cardchangeform" @submit.prevent="changeCard" >
        <div class="modal-card">
          <header>
            <p>カード情報変更</p>
          </header>
          <section>
            <div class="form-group">
              <label for="name">カード番号</label>
              <div id="cardNumber" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">セキュリティコード</label>
              <div id="securityCode" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">有効期限</label>
              <div id="expiration" class="form-control"></div>
            </div>
            <div class="form-group">
              <label for="name">カード名義</label>
              <input type="text" name="cardName" id="cardName" class="form-control" value="" placeholder="カード名義を入力">
            </div>
            <div class="form-group">
                <button type="submit" id="create_token" class="btn btn-primary">カードを変更する</button>
            </div>
          </section>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scope>
.invalid-feedback {
  display: initial;
}
.date_width .v-input__control {
  width: 274px!important;
}
.date_width .v-input__slot {
  padding: 0px!important;
}
.pay_card {
  width: 305px;
  height: 98px;
  color: #707070;
  line-height: 15px;
  background: #FFFFFF;
  border: solid 1px #D2D6DB;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  margin-right: 20px;
}
.card_active {
  color: #FFFFFF;
  background: #2E61CE;
}
.pay_card_del {
  color: #707070;
  margin: 10px 0px 0px 0px!important;
}
.pay_card_del:hover {
  cursor: pointer;
}
.terms {
  height: 250px;
  overflow-y: auto;
  background: #FFFFFF;
  border: solid 1px #E5E7EB;
  box-shadow: 0px 1px 3px rgba(17, 25, 39, 0.1), 0px 1px 2px rgba(17, 25, 39, 0.06);
  border-radius: 4px;
}
.table-terms {
  width: 100%;
}
.table-terms td {
  padding: 12px 25px;
}
.table-header {
  position: sticky;
  top: 0;
  left: 0;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  background: #EDF2FC;
  z-index: 2;
}
.consent {
  display: flex;
}
.consent label {
  display: grid;
  align-items: center;
}
</style>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";
import moment from "moment";
import { loadStripe } from '@stripe/stripe-js';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    datepicker,
    VuePerfectScrollbar,
  },
  props: {
    base:Object,
    customer:Object,
    plan_code:Array,
    change_plan_code:Array,
    apikey:String,
    errors_message: String,
    success_message: String,
  },
  data() {
    console.log(Inertia.form(this.base));
    return {
      change_plan_date : null,
      form: Inertia.form(this.base),
      errors: {},
      stripe: "",
      cardNumber:null,
      cardName:null,
      securityCode:null,
      expiration:null,
      token: "",
      no_card: true,
      isYearDisabled: false,
      isMonthDisabled: false,
      isCardDisabled: false,
      isInvoiceDisabled: false,
      oldPayment_Type: Inertia.form(this.base).Payment_Type,
      oldPayment_Method: Inertia.form(this.base).Payment_Method,
      oldContractUserCount_month: Inertia.form(this.base).ContractUserCount_month,
      oldContractUserCount_year: Inertia.form(this.base).ContractUserCount_year,
      oldBilling_Mail: Inertia.form(this.base).Billing_Mail,
      isCardChange: false,
      isChangeDateErr: false,
      oldChangeplan: Inertia.form(this.base).change_plan_name,
      freePlanNumOfAvailableStaff: 20,
      consent: false,
      privacy: false,
      change_plan_list: {},
    };
  },
  computed: {
    isDisabled() {
      // エラー配列による判断
      if (Object.keys(this.errors).length > 0) {
        return false;
      }

      // 日付未入力の場合
      if (this.form.change_plan_date == null || this.form.change_plan_date == undefined || this.form.change_plan_date == '') {
        return false;
      }

      // datepickerのエラー
      if (this.isChangeDateErr) {
        return false;
      }

      // 同意しないとボタン押下不可
      return this.consent && this.privacy;
    }
  },
  watch: {
      'form.Payment_Type': {
          handler(val, oldval) {
              if (val == "月払い") {
                  this.isYearDisabled = false;
                  this.isMonthDisabled = true;
                  this.form.Payment_Method = "クレジット";
              } else {
                  this.isYearDisabled = true;
                  this.isMonthDisabled = false;
              }
          },
          immediate: true
      },
      'form.Payment_Method': {
          handler(val, oldval) {
              if (val == "クレジット") {
                  this.isCardDisabled = false;
                  this.isInvoiceDisabled = true;
              } else {
                  this.isCardDisabled = true;
                  this.isInvoiceDisabled = false;
              }
          },
          immediate: true
      },
      'form.change_plan_name': {
          handler(val, oldval) {
              this.$delete(this.errors, 'change_plan_date')
              console.log(this.form.change_plan_name);
              if (val!='' && val!=null) {
                this.checkPlanDate();
              }
              if(this.oldChangeplan=='スタンダードプラン' && val=='機能制限無料プラン'){
                  this.$set(this.errors, 'change_plan_name', '一度スタンダードプランに切り替わった後にしか機能制限無料プランに戻せません。');
              }
          },
          immediate: true
      },
      'form.change_plan_date': {
          handler(val, oldval) {
              if (this.form.change_plan_name!='' && this.form.change_plan_name!=null) {
                this.checkPlanDate();
              }
          },
          immediate: true
      },
  },
  mounted() {
      this.no_card = this.customer ? false : true;
      this.updateLoading(false);
      if(this.customer){
          this.form.token = this.customer;
      }

      // 無料プランから機能制限無料プランに変更は不可
      if (this.form.plan_name == '無料プラン') {
        this.change_plan_list = this.change_plan_code.filter(item => item.code !== 'free');
      } else {
          this.change_plan_list = this.change_plan_code;
      }
  },
  updated() {
  },
  methods: {
    plancancel (id) {
        this.updateLoading(true);
        this.$inertia.get(route('lodgments.plan-cancel-1'),{})
    },
    async stripesetting(){
      console.log("stripe");
      this.stripe = await loadStripe(this.apikey);
      console.log('%o',this.stripe);
      const elements = this.stripe.elements();
      this.cardNumber = elements.create('cardNumber');
      this.cardNumber.mount('#cardNumber');
      this.securityCode = elements.create('cardCvc');
      this.securityCode.mount('#securityCode');
      this.expiration = elements.create('cardExpiry');
      this.expiration.mount('#expiration');
    },
    setChangeDate: function(date) {
      this.form.change_plan_date = moment(date).format('YYYY/MM/DD')
      this.isChangeDateErr = false;
      this.checkPlanDate();
    },
    setChangeDateErr: function(text, err) {
      this.form.change_plan_date = moment(text).format('YYYY/MM/DD');
      this.isChangeDateErr = err;
      this.checkPlanDate();
    },
    checkPlanDate() {
      // プラン変更日のエラークリア
      this.$delete(this.errors, 'change_plan_date');
      // datepickerのエラーを優先表示
      if (!this.isChangeDateErr) {
        // プラン変更日の必須チェック
        if (this.form.change_plan_date=='' || this.form.change_plan_date==null || this.form.change_plan_date=="Invalid Date" || this.form.change_plan_date=="Invalid date") {
          this.$set(this.errors, 'change_plan_date', 'プラン変更日を入力してください。');
        } else {
          if (this.form.change_plan_date.length < 10) {
            this.$set(this.errors, 'change_plan_date', 'プラン変更日を入力してください。');
          } else {
            let today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);

            // 過去日チェック
            let changed = new Date(this.form.change_plan_date);
            if (today.getTime() > changed.getTime()){
                this.$set(this.errors, 'change_plan_date', 'プラン変更日に過去日は設定できません。')
            }

            //当日は選択不可
            if (today.getTime() == changed.getTime()){
              this.$set(this.errors, 'change_plan_date', 'プラン変更日に当日は選択できません。')
            }

            // 翌月1日までを許可する
            let endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            if (endDate.getTime() < changed.getTime()) {
              this.$set(this.errors, 'change_plan_date', 'プラン変更日は翌月1日までを選択してください。')
            }

            //当日が１日の場合のみ、1日は選択不可
            if (today.getDate() == 1 && today.getTime() == changed.getTime()) {
              this.$set(this.errors, 'change_plan_date', today.getFullYear() + '年' +  (today.getMonth() + 1) + '月2日以降で選択してください。')
            }
          }
        }
      }
    },
    checkBillingMail() {
      this.$delete(this.errors, 'Billing_Mail');
      if (this.form.Billing_Mail != '' && this.form.Billing_Mail != undefined && this.form.Billing_Mail != null) {
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(this.form.Billing_Mail)) {
          this.$set(this.errors, 'Billing_Mail', '請求書送付メールアドレスの形式が正しくありません。');
        }
      }
    },
    changeAlert() {
      // 変更チェック
      let isChangeData = false;
      // 決済情報未登録フラグ
      let isCreditInfoNoEntry = false;

      // プラン変更日の必須チェック
      if (this.change_plan_date == '' || this.form.change_plan_date == null || this.form.change_plan_date == 'Invalid Date' || this.form.change_plan_date == 'Invalid date') {
        this.$set(this.errors, 'change_plan_date', 'プラン変更日を入力してください。');
        return;
      }

      // 変更後のプラン、支払方法、請求方法のいずれかが、変更されていなければエラー
      if ((this.form.change_plan_name !='' && this.form.change_plan_name != null) || 
          (this.oldPayment_Type != this.form.Payment_Type) || 
          (this.oldPayment_Method != this.form.Payment_Method) || 
          (this.oldPayment_Type == '月払い' && this.oldContractUserCount_month != this.form.ContractUserCount_month) || 
          (this.oldPayment_Type == '年払い' && this.oldContractUserCount_year != this.form.ContractUserCount_year) || 
          (this.oldPayment_Method == '請求書' && this.oldBilling_Mail != this.form.Billing_Mail) || 
          this.isCardChange) {
        isChangeData = true;
      }
      // スタンダードプランに変更時、カード情報を登録していない場合はエラー
      if (this.form.change_plan_name == 'スタンダードプラン' && this.form.Payment_Method == "クレジット" && this.form.token == '') {
        isCreditInfoNoEntry = true;
      }
      // 請求方法が請求書からクレジットに変更された場合もチェック
      if (this.form.Payment_Method == "クレジット" && this.oldPayment_Method != this.form.Payment_Method && this.form.token == '') {
        isCreditInfoNoEntry = true;
      }
      if (!isChangeData) {
        if (Object.keys(this.errors).length <= 0) {
          this.$swal({
            text: "変更後のプラン、支払方法、請求方法のいずれかを変更する必要があります。",
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      } else if (isCreditInfoNoEntry) {
        if (Object.keys(this.errors).length <= 0) {
          this.$swal({
            text: "決済情報が登録されていないため変更できません。",
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      } else {
        // 変更されていた場合は確認メッセージ
        let massage = "";
        if (this.form.change_plan_name === '機能制限無料プラン'){
          const url =  route('staffs.list');
          const freePlanNumOfAvailableStaff = this.freePlanNumOfAvailableStaff;
          massage = `機能制限無料プランでの職員登録の上限は${freePlanNumOfAvailableStaff}名までとなります。超過している場合、<a href='${url}'>職員</a>を削除する必要があります。`;
        }
        else {
          massage = `変更しますか？`;
        }
        this.$swal({
          html: massage,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '変更',
          cancelButtonText: 'キャンセル',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateLoading(true);
            this.form.put(route('lodgments.plan-update'), {
              preserveScroll: true,
              onSuccess:()=>{
                this.updateLoading(false);
                this.$swal(
                  '保存しました',
                  '',
                  'success').then(function(){
                      location.reload()
                  })
              },
              onError: () => {
                this.updateLoading(false);
              },
            })
          }
        });
      }
    },
    async createCard() {
      this.updateLoading(true);
      this.stripe.createToken(this.cardNumber,{name: document.getElementById('cardName').value}).then((result) => {
        console.log(result);
        if (result.error) {
          this.updateLoading(false);
          alert("カード登録処理時にエラーが発生しました。カード番号が正しいものかどうかをご確認いただくか、別のクレジットカードで登録してみてください。");
        } else {
          this.updateLoading(false);
          this.form.token = result.token;
          this.form.put(route('lodgments.card-create'));
          this.$bvModal.hide('stripe-modal');
          alert("カード登録に成功しました。");
          location.reload()
          isCardChange = true;
        }
      });
    },
    async changeCard() {
      this.updateLoading(true);
      this.stripe.createToken(this.cardNumber,{name: document.getElementById('cardName').value}).then((result) => {
        console.log(result);
        if (result.error) {
          this.updateLoading(false);
          alert("カード登録処理時にエラーが発生しました。カード番号が正しいものかどうかをご確認いただくか、別のクレジットカードで登録してみてください。");
        } else {
          this.updateLoading(false);
          this.form.token = result.token;
          this.form.put(route('lodgments.card-change'));
          this.$bvModal.hide('stripe-Change-modal');
          alert("カード登録に成功しました。");
          location.reload()
          isCardChange = true;
        }
      });
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
};
</script>