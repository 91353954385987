<template>
  <div>
    <form>
      <VuePerfectScrollbar id="scrollableDiv" class="table-bulkadd"
        style="max-height: calc(95vh - 280px); min-height: calc(95vh - 280px); padding: 0;">
        <table class="table-shiftpattern" style="table-layout: fixed;">
          <tr>
            <th class="head01" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>社員番号</div>
                <button type="button" class="sort_btn" @click="set_sort_by(1)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column1_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column1_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head02" :style="table_column_left_positions.team" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>チーム</div>
                <button type="button" class="sort_btn" @click="set_sort_by(2)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column2_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column2_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head03" :style="table_column_left_positions.name" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>名前</div>
                <button type="button" class="sort_btn" @click="set_sort_by(3)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column3_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column3_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th class="head04" :style="table_column_left_positions.occupation" rowspan="2">
              <div style="display: flex; justify-content: space-between;">
                <div>職種</div>
                <button type="button" class="sort_btn" @click="set_sort_by(4)">
                  <span><font-awesome-icon icon="long-arrow-alt-up" :class="column4_sort_asc" /></span>
                  <span><font-awesome-icon icon="long-arrow-alt-down" :class="column4_sort_desc" /></span>
                </button>
              </div>
            </th>
            <th :colspan="form.weekdays.length">シフトパターン</th>
          </tr>
          <tr>
            <th v-for="(weekday, index) in form.weekdays" :key="'header-shift-' + weekday.id"
              class="copy_pointer" style="min-width: calc((100% - 730px) / 8); max-width: calc((100% - 730px) / 8);"
              @click="show_col_copy_menu(index, weekday.id)">
              {{ weekday.name }}
            </th>
          </tr>
          <!-- シフトパターンの設定-->
          <tr v-for="(staff, index) in form.staffs" :key="'shift-' + staff.id">
            <td class="copy_pointer fixed01" :style="table_column_left_positions.employee_number" style="white-space: normal; background: #F3F4F6; padding: 0px 5px;">
              <div class="text-center" style="display: flex; align-items: center; height: 65px; width: 120px;" @click="show_row_copy_menu(index, staff.office_staff_id)">
                {{ staff.employee_number }}
              </div>
            </td>
            <td class="fixed02" :style="table_column_left_positions.team" style="white-space: normal;">{{ staff.team_names.join(',') }}</td>
            <td class="fixed03" :style="table_column_left_positions.name" style="white-space: normal;">{{ staff.lastname }}{{ staff.firstname }}</td>
            <td class="fixed04" :style="table_column_left_positions.occupation" style="white-space: normal;">{{ staff.main_occupation_name }}</td>
            <td v-for="weekday in form.weekdays" :key="'shift-' + staff.id + '-' + weekday.id" :id="'tab_2_row_select_' + index + '_tab_2_col_select_' + weekday.id + '_'">
              <v-select v-model="settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id]" :items="office_staff_workpattern_items[staff.office_staff_id]" dense outlined hide-details />
            </td>
          </tr>
        </table>
        <!-- 表下のエラーメッセージ -->
        <div v-if="errors.length > 0" style="margin: 20px 0px;">
          ※ 以下の内容を確認してください。
        </div>
        <div v-for="(error_item, index) in errors" :key="index">
          <AutoCreateError :error_item="error_item" />
        </div>
        <!-- 行コピーメニュー -->
        <ul class="copy_menu" :style="copy_menu_position" v-show="row_copy_menu_show">
          <li @click="row_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この行をコピー
          </li>
          <li :class="row_copy_select ? '' : 'copy_menu_disabled'" @click="row_paste"><font-awesome-icon icon="clone"
              style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
          <li :class="row_copy_select ? '' : 'copy_menu_disabled'" @click="clear_row_copy"><font-awesome-icon
              icon="times" style="color: #1059CC; padding-right: 20px;" disabled />選択を削除</li>
        </ul>
        <!-- 列コピーメニュー -->
        <ul class="copy_menu" :style="copy_menu_position" v-show="col_copy_menu_show">
          <li @click="col_copy"><font-awesome-icon icon="expand" style="color: #1059CC; padding-right: 20px;" />この列をコピー
          </li>
          <li :class="col_copy_select ? '' : 'copy_menu_disabled'" @click="col_paste"><font-awesome-icon icon="clone"
              style="color: #1059CC; padding-right: 20px;" />貼り付け</li>
          <li :class="col_copy_select ? '' : 'copy_menu_disabled'" @click="clear_col_copy"><font-awesome-icon
              icon="times" style="color: #1059CC; padding-right: 20px;" disabled />選択を削除</li>
        </ul>
      </VuePerfectScrollbar>
      <div style="display: flex; justify-content: space-between; margin-top: 20px;">
        <div>
          <b-button class="btn_white btn_autocreatefooter" @click="save_close"
            :disabled="setDisabled()">保存して閉じる</b-button>
        </div>
        <div>
          <b-button class="btn_white btn_autocreatefooter" @click="save_back" :disabled="setDisabled()">戻る</b-button>
          <b-button class="btn_white btn_autocreatefooter btn_green_outline" @click="save_next"
            :disabled="setDisabled()">次へ</b-button>
        </div>
      </div>
    </Form>
    <div v-click-outside="copy_menu_control"></div>
  </div>
</template>

<style scoped>
.btn_autocreatefooter {
  width: 150px!important;
  height: 40px!important;
}
.table-bulkadd {
  width: 100%;
  margin: 0px;
  overflow: auto;
}
.table-bulkadd table {
  margin: 0;
  border-spacing: 0;
  border-collapse: separate!important;
}
.table-bulkadd th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: inherit;
  background: #F3F4F6;
  border: solid 1px rgba(210, 214, 219, 0.5)!important;
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  z-index: 1;
}
.table-bulkadd tr:nth-child(1) th {
  top: 0px;
}
.table-bulkadd tr:nth-child(2) th {
  top: 35px;
}
.table-bulkadd td {
  position: sticky;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 14px;
  background: #FFFFFF;
  border: solid 1px rgba(210, 214, 219, 0.5);
  padding: 10px 5px;
}
.table-bulkadd .head01 {
  top: 0px;
  left: 0px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  z-index: 3000;
}
.table-bulkadd .head02 {
  top: 0px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 3000;
}
.table-bulkadd .head03 {
  top: 0px;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  z-index: 3000;
}
.table-bulkadd .head04 {
  top: 0px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  z-index: 3000;
}
.table-bulkadd .head05 {
  top: 0px;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 3000;
}
.table-bulkadd .head06 {
  top: 0px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  z-index: 3000;
}
.table-bulkadd .fixed01 {
  left: 0px;
  min-width: 120px;
  max-width: 120px;
  z-index: 2000;
}
.table-bulkadd .fixed02 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  z-index: 2000;
}
.table-bulkadd .fixed03 {
  width: 160px;
  min-width: 160px;
  z-index: 2000;
}
.table-bulkadd .fixed04 {
  width: 200px;
  min-width: 200px;
  z-index: 2000;
}
.table-bulkadd .fixed05 {
  width: 140px;
  min-width: 140px;
  z-index: 2000;
}
.table-bulkadd .fixed06 {
  width: 140px;
  min-width:140px;
  z-index: 2000;
}
.table-bulkadd .fixed07 {
  width: 120px;
  min-width:120px;
  z-index: 2000;
}
.table-bulkadd .tfoot01 {
  left: 0px;
  bottom: 0px;
  z-index: 2000;
}
.table-bulkadd .tfoot02 {
  bottom: 0px;
}
.table-shiftpattern {
  max-width: 100%;
  min-width: 100%;
}
.disabled input[type="number"] {
  background-color: #e9ecef;
}
.no_display {
  display: none;
}
.v-select {
  width: 100%;
  min-width: 100%!important;
  max-width: 100%!important;
}
.v-select__selections {
  width: 0px;
}
.select option[selected] {
  background: red;
}
.btn-secondary {
  margin-left: 0.25rem !important;
}
.tab {
  cursor: pointer;
}
.copy_menu {
  position: absolute;
  z-index: 2147483647;
  background: #F8F9F8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  list-style: none;
  line-height: 2;
  padding: 20px 0px;
}
.copy_menu li {
  padding-left: 20px;
  min-width: 200px;
  max-width: 200px;
}
.copy_menu li:hover {
  background: #D4D5D4;
}
.copy_menu_disabled {
    pointer-events: none;
    opacity:0.6;
}
.row_copyselect_first {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_between {
  border-top: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.row_copyselect_last {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_last {
  border-right: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_min {
  border-top: dashed 2px blue!important;
  border-left: dashed 2px blue!important;
}
.col_copyselect_between_min {
  border-left: dashed 2px blue!important;
}
.col_copyselect_last_min {
  border-left: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.col_copyselect_first_max {
  border-top: dashed 2px blue!important;
  border-right: dashed 2px blue!important;
}
.col_copyselect_between_max {
  border-right: dashed 2px blue!important;
}
.col_copyselect_last_max {
  border-right: dashed 2px blue!important;
  border-bottom: dashed 2px blue!important;
}
.copy_pointer {
  cursor: pointer;
}
.error_box {
  position: absolute;
  z-index: 2147483647;
  height: auto;
  width: 600px;
  border: solid 1px #c4c5c7;
  background: #FFFFFF;
  padding: 0px 0px 10px 0px;
}
.error-row td {
  background: #EBF4FF!important;
}
/* table scroll design */
form {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
    &:hover {
        ::-webkit-scrollbar-track {
        background: #eee;
        }
        ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 6px;
        }
    }
}
</style>

<script>
import axios from 'axios'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AutoCreateError from '@/Pages/Shifts/Components/Dialog/AutoCreateParts/AutoCreateError';

export default {
  components: {
    VuePerfectScrollbar,
    AutoCreateError,
  },
  props: {
    sort_type: {
      type: Number,
      required: true
    },
    workpatterns: {
      type: Array,
      required: true
    },
    office_staffs: {
      type: Array,
      required: true
    },
    staffs: {
      type: Array,
      required: true
    },
    office_staff_workpatterns: {
      type: Array,
      required: true
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load_data();

      // 初期表示フラグ
      this.initial_change = true;
    });

    // ローディング終了
    this.updateLoading(false);
  },
  computed: {
    column1_sort_asc() {
      if (this.sort_type === 1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column1_sort_desc() {
      if (this.sort_type === -1) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_asc() {
      if (this.sort_type === 2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column2_sort_desc() {
      if (this.sort_type === -2) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_asc() {
      if (this.sort_type === 3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column3_sort_desc() {
      if (this.sort_type === -3) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_asc() {
      if (this.sort_type === 4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    column4_sort_desc() {
      if (this.sort_type === -4) {
        return 'sort_active';
      } else {
        return '';
      }
    },
    table_column_left_positions() {
      // テーブルの左側からの各列の幅を定義
      const default_column_widths_from_left_side = {
        employee_number: 120,
        team: 150,
        name: 160,
        occupation: 200,
        work_hours_min: 140,
        work_hours_max: 140,

        workcount_sum_per_office_staff: 120,
      };

      // 左隣の列幅から各列の左位置を計算（ただし、シフトパターンの設定タブは列フィルタがないのでデフォルト幅を使用する）
      const left_positions = {};
      Object.entries(default_column_widths_from_left_side).reduce((acc, [column, width]) => {
        left_positions[column] = { left: acc + 'px' };
        return acc += width;
      }, 0);

      return left_positions;
    },
  },
  watch: {
    form : {
      handler () {
        if (!this.initial_change) {
          this.$emit('setChange', true);
        }
      },
      deep: true
    },
    settings : {
      handler () {
        if (this.initial_change) {
          this.initial_change = false;
        } else {
          this.$emit('setChange', true);
        }
      },
      deep: true
    },
    errors: {
      handler: function () {
        // エラー情報を親画面へ送る
        let send_error = this.errors.filter(item => item.fix === false);
        if (send_error.length > 0) {
          this.$emit('setchildError', true, this.error_message);
        } else {
          this.$emit('setchildError', false);
        }
      }
    },
    sort_type: {
      handler: function () {
        this.load_data();
      }
    },
  },
  data() {
    return {
      office_staff_workpattern_items: {},
      // 「シフトパターン」で選択肢から除外される勤務種別
      no_shift_workpattern_types: [
        'paid_holidays',
        'public_half_holiday',
        'paid_half_holiday',
        'other_holiday',
        'half_work',
      ],
      form: {
        office_staffs: [],
        workpatterns: [],
        staffs: [],
        weekdays: []
      },
      settings: {
        office_staff_workpattern_flags: {},
        office_staff_shift_patterns: {}
      },
      // 行列コピー
      copy_menu_position: {
        left: 0,
        top: 0
      },
      row_menu_show: false,
      row_copy_menu_show: false,
      row_copied_index: -1,
      row_copied_id: -1,
      row_copy_select: false,
      col_menu_show: false,
      col_copy_menu_show: false,
      col_copied_id: -1,
      col_copy_select: false,
      // エラーメッセージ
      errors: [],
      error_message: '',
      sort_type_data: this.sort_type,
      initial_change: false,
    };
  },
  methods: {
    load_data() {
      // office_staff_workpatterns を office_staff_workpattern_flags の初期値に反映
      let office_staff_workpattern_flags = {};
      this.office_staff_workpatterns.forEach(office_staff_workpattern => {
        const {
          office_staff_id,
          workpattern_id,
        } = office_staff_workpattern;

        // IDごとの設定が未定義の場合は初期化
        if (!office_staff_workpattern_flags[office_staff_id]) {
          office_staff_workpattern_flags[office_staff_id] = {};
        }
        office_staff_workpattern_flags[office_staff_id][workpattern_id] = true;
      });

      this.no_shift_workpatterns = {};
      this.workpatterns.forEach(workpattern => {
        const { id, type } = workpattern;

        if (this.no_shift_workpattern_types.includes(type)) {
          this.no_shift_workpatterns[id] = workpattern;
        }
      });

      this.office_staffs.forEach(office_staff => {
        if (typeof office_staff_workpattern_flags[office_staff.id] === 'undefined') {
          office_staff_workpattern_flags[office_staff.id] = {};
        }

        this.workpatterns.forEach(workpattern => {
          if (typeof office_staff_workpattern_flags[office_staff.id][workpattern.id] === 'undefined') {
            office_staff_workpattern_flags[office_staff.id][workpattern.id] = false;
          }
        });
      });
      this.settings.office_staff_workpattern_flags = office_staff_workpattern_flags;

      const weekdays = [
        { id: 'mon', name: "月"},
        { id: 'tue', name: "火"},
        { id: 'wed', name: "水"},
        { id: 'thu', name: "木"},
        { id: 'fri', name: "金"},
        { id: 'sat', name: "土"},
        { id: 'sun', name: "日"},
        { id: 'hol', name: "祝"}
      ];

      let settings_office_staff_shift_patterns = {};
      this.staffs.forEach(staff => {
        settings_office_staff_shift_patterns[staff.office_staff_id] = {};
        weekdays.forEach(weekday => {
          if (staff.shift_pattern === null || typeof staff.shift_pattern[weekday.id] === 'undefined' || this.check_no_shift_workpattern(staff.shift_pattern[weekday.id].workpattern_id)) {
            settings_office_staff_shift_patterns[staff.office_staff_id][weekday.id] = null;
          } else {
            settings_office_staff_shift_patterns[staff.office_staff_id][weekday.id] = staff.shift_pattern[weekday.id].workpattern_id;
          }
        });
      });
      this.settings.office_staff_shift_patterns = settings_office_staff_shift_patterns;

      // this.settings.office_staff_workpattern_flags 設定後に実施
      this.office_staffs.forEach(office_staff => {
        this.update_office_staff_workpattern_items(office_staff.id);
      });

      this.form.office_staffs = this.office_staffs;
      this.form.workpatterns = this.workpatterns;
      this.form.staffs = this.staffs;
      this.form.weekdays = weekdays;
    },
    // 各ボタン制御
    setDisabled() {
      let is_error = this.errors.filter(item => item.fix === false);
      if (is_error.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    update_office_staff_workpattern_items(office_staff_id) {
      this.office_staff_workpattern_items[office_staff_id] = [{
        text: '--',
        value: null
      }];
      this.workpatterns.forEach(workpattern => {
        if (this.settings.office_staff_workpattern_flags[office_staff_id][workpattern.id] === true && !this.check_no_shift_workpattern(workpattern.id)) {
          this.office_staff_workpattern_items[office_staff_id].push({
            text: workpattern.name,
            value: workpattern.id
          });
        }
      });
    },
    // 事業所またぎ兼務している職員のシフトパターンを変更しているかどうか
    check_shift_pattern_concurrency() {
      this.staffs.forEach(staff => {
        if (staff.office_concurrent_flag) {
          this.form.weekdays.forEach(weekday => {
            if (staff.shift_pattern === null || typeof staff.shift_pattern[weekday.id] === 'undefined') {
              if (this.settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id] !== null) {
                return true;
              }
            } else if (this.settings.office_staff_shift_patterns[staff.office_staff_id][weekday.id] !== staff.shift_pattern[weekday.id].workpattern_id) {
              return true;
            }
          });
        }
      });
      return false;
    },
    check_no_shift_workpattern(workpattern_id) {
      return Object.keys(this.no_shift_workpatterns).includes(String(workpattern_id));
    },
    htmlspecialchars(string) {
      if (typeof string !== 'string') {
        return string;
      };
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          };
          return escaped[match];
        });
    },
    set_sort_by(sort_by) {
      if (Math.abs(this.sort_type) === sort_by) {
        this.sort_type_data = -this.sort_type;
      } else {
        this.sort_type_data = sort_by;
      }
      this.$emit('getBulkAddData', this.sort_type_data);
    },
    // 保存して終了
    save_close() {
      // 保存処理
      this.save(true, true)
    },
    // 戻る
    save_back() {
      // 保存処理
      this.save(false, false, 6)
    },
    // 次へ
    save_next() {
      // 保存処理
      this.save(false, false, 8)
    },
    // 保存処理
    save(message_flg = true, close_flg = true, nextstep = -1) {
      // シフトパターンの兼務先反映確認ダイアログ
      const shift_pattern_dialog = {
        icon: 'info',
        title: '兼務先にもシフトパターンを反映しますか？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '反映する',
        denyButtonText: '反映しない',
        cancelButtonText: '閉じる',
        customClass: {
          confirmButton: 'btn btn-primary',
          denyButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      };

      // 登録確認ダイアログ
      const confirm_dialog = {
        icon: 'info',
        title: '設定内容を登録しますか？',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      };

      let concurrently_shift_patterns = false;
      const shift_pattern_concurrent_flag = this.check_shift_pattern_concurrency();
      if (shift_pattern_concurrent_flag) {
        // シフトパターンの確認ダイアログ表示
        this.$swal(shift_pattern_dialog)
          .then(result => {
            if (!result.isConfirmed && !result.isDenied) return;
            concurrently_shift_patterns = result.isConfirmed;
            this.doBulkAdd(concurrently_shift_patterns, message_flg, close_flg, nextstep);
          });
      } else {
        // 登録確認ダイアログ表示
        this.$swal(confirm_dialog)
          .then(result => {
            if (!result.isConfirmed && !result.isDenied) return;
            this.doBulkAdd(concurrently_shift_patterns, message_flg, close_flg, nextstep);
          });
      }
    },
    // 一括登録の実行
    doBulkAdd(concurrently_shift_patterns, message_flg, close_flg, nextstep) {
      let errors = {};
      this.updateLoading(true);
      axios.post(this.$route('staffs.workcons.do-bulk-add-shift-pattern'), {
        office_staff_shift_patterns: this.settings.office_staff_shift_patterns,
        concurrently_shift_patterns: concurrently_shift_patterns,
      }).then(result => {
        this.updateLoading(false);
        if (result.status === 200) {
          if (result.data.status === 400) {
            errors = result.data.errors;
            let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
            this.$swal({
              icon: 'error',
              html: '入力値が正しくありません。' + error
            });
          } else if (result.data.status !== 200) {
            errors = result.data.errors;
            throw result.status;
          } else {
            // 保存が成功したら変更フラグクリア
            this.$emit('setChange', false);
            this.$emit('getBulkAddData');
            if (message_flg) {
              this.$swal({
                icon: 'success',
                title: '保存しました'
              }).then(result => {
                if (close_flg) {
                  this.$emit('closeDialog');
                }
              });
            } else {
              if (nextstep > -1) {
                this.$emit('nextStep', nextstep);
              }else if (close_flg) {
                this.$emit('closeDialog');
              }
            }
          }
        } else {
          throw result.status;
        }
      }).catch(e => {
        this.updateLoading(false);
        let error = Object.keys(errors).length > 0 ? '<br>' + Object.values(errors).join('<br>') : '';
        this.$swal({
          icon: 'error',
          html: '勤務条件一括登録に失敗しました。しばらくたってから再試行してください。' + error
        }).then(result => {
          location.reload()
        })
      });
    },
    reset() {
      this.load_data();
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },

    // 行コピーのコンテンツメニュー表示
    show_row_copy_menu(index, id) {
      this.row_menu_index = index;
      this.row_menu_id = id;
      this.row_menu_show = true;
    },
    // 「この行をコピー」実行
    row_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;

      // 行選択フラグ=ON
      this.row_copy_select = true;

      // 対象設定
      this.row_copied_index = this.row_menu_index;
      this.row_copied_id = this.row_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id^="tab_2_row_select_${this.row_copied_index}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i === 0) {
            cellOther[i].classList.add('row_copyselect_first');
          } else if (i === (cellOther.length - 1)) {
            cellOther[i].classList.add('row_copyselect_last');
          } else {
            cellOther[i].classList.add('row_copyselect_between');
          }
        }
      }
    },
    // 行の「貼り付け」実行
    row_paste() {
      if (this.row_menu_id !== this.row_copied_id) {
          this.form.weekdays.forEach(weekday => {
            const workpattern_id = this.settings.office_staff_shift_patterns[this.row_copied_id][weekday.id];
            this.settings.office_staff_shift_patterns[this.row_menu_id][weekday.id] = workpattern_id;
          });
          // 選択肢更新のため
          this.update_office_staff_workpattern_items(this.row_menu_id);
      }
      // 選択の解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 選択行の「選択を削除」実行
    clear_row_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 行選択フラグ= OFF
      this.row_copy_select = false;

      //コピー行削除
      this.row_copied_index = -1;
      this.row_copied_id = -1;
    },
    // 列コピーのコンテンツメニュー表示
    show_col_copy_menu(index, id) {
      this.col_menu_index = index;
      this.col_menu_id = id;
      this.col_menu_show = true;
    },
    // 「この列をコピー」実行
    col_copy() {
      // 現在の選択解除
      this.copy_remove_class();
      this.row_copy_select = false;
      this.row_copied_index = -1;
      this.row_copied_id = -1;

      // 列選択フラグ=ON
      this.col_copy_select = true;

      // 対象設定
      this.col_copied_index = this.col_menu_index;
      this.col_copied_id = this.col_menu_id;

      // 外枠設定
      let cellOther = document.querySelectorAll(`[id*="tab_2_col_select_${this.col_copied_id}_"]`);
      for (let i in cellOther) {
        if (cellOther.hasOwnProperty(i)) {
          if (i === 0) {
            cellOther[i].classList.add('col_copyselect_first');
          } else if(i === (cellOther.length - 1)) {
            cellOther[i].classList.add('col_copyselect_last');
          } else {
            cellOther[i].classList.add('col_copyselect_between');
          }
        }
      }
    },
    // 列の「貼り付け」実行
    col_paste() {
      if (this.col_menu_id !== this.col_copied_id) {
        this.form.staffs.forEach(staff => {
          let workpattern_id = this.settings.office_staff_shift_patterns[staff.office_staff_id][this.col_copied_id];
          this.settings.office_staff_shift_patterns[staff.office_staff_id][this.col_menu_id] = workpattern_id;
        });
      }
      // 選択の解除
      this.copy_remove_class();
      this.col_copy_select = false;
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択列の「選択を削除」実行
    clear_col_copy() {
      // 選択の外枠削除
      this.copy_remove_class();

      // 列選択フラグ= OFF
      this.col_copy_select = false;

      //コピー列削除
      this.col_copied_index = -1;
      this.col_copied_id = -1;
    },
    // 選択枠線を全て削除
    copy_remove_class() {
      let delClass = ['row_copyselect_first', 'row_copyselect_between', 'row_copyselect_last' , 'col_copyselect_first', 'col_copyselect_between', 'col_copyselect_last', 'col_copyselect_first_min', 'col_copyselect_between_min', 'col_copyselect_last_min', 'col_copyselect_first_max', 'col_copyselect_between_max', 'col_copyselect_last_max']
      for (let name_index in delClass) {
        let cellClass = document.querySelectorAll(`.${delClass[name_index]}`);
        for (let i in cellClass) {
          if (cellClass.hasOwnProperty(i)) {
            cellClass[i].classList.remove(delClass[name_index]);
          }
        }
      }
    },
    copy_menu_control: function (e) {
      // 表示サイズ取得
      let screen_width = window.innerWidth;

      // Divの横スクロール位置を取得
      let scrollableDiv = document.getElementById('scrollableDiv');
      let scrollDistanceX = 0;
      if (scrollableDiv) {
        scrollDistanceX = scrollableDiv.scrollLeft;
      }

      // コピーメニューの横位置取得
      let width_pos = e.pageX;
      if (screen_width < width_pos + 300) {
        width_pos = width_pos - 250 + scrollDistanceX;
      } else {
        width_pos = width_pos - 20 + scrollDistanceX;
      }

      // Divの縦スクロール位置を取得
      let scrollDistanceY = 0;
      if (scrollableDiv) {
        scrollDistanceY = scrollableDiv.scrollTop;
      }

      // コピーメニューの縦位置取得
      let height_pos = e.pageY - 230 + scrollDistanceY;

      // メニューの表示位置設定
      this.copy_menu_position = {
        top: `${height_pos}px`,
        left: `${width_pos}px`
      };

      // メニュー表示
      if (this.row_menu_show) {
        // 行コピー
        this.row_menu_show = false;
        this.row_copy_menu_show = true;
        this.col_copied_index = -1;
        this.col_copied_id = -1;
      } else if (this.col_menu_show) {
        // 列コピー
        this.col_menu_show = false;
        this.col_copy_menu_show = true;
        this.row_copied_index = -1;
        this.row_copied_id = -1;
      } else {
        this.row_copy_menu_show = false;
        this.col_copy_menu_show = false;
      }
    },
    scroll_click() {
      this.row_copy_menu_show = false;
      this.col_copy_menu_show = false;
    },
  },
}
</script>
