export default {
    /**
     * 勤務区分リストのソート 以下の4つの順でソートする
     *
     * 1) categoryが holiday, early, day, late, night, dawn の順番
     * 2) typeが public_holidays, paid_holidays, public_half_holiday, paid_half_holiday, other_holiday, work, half_work の順番
     * 3) category が holiday の時は rest_start_time の順番
     *  category が early, day, late, night, dawn の時は working_start_time の順番
     * 4) id の順番
     *
     */
    sortWorkpatterns(workpatterns) {
        return Object.values(workpatterns).sort((a, b) => {
            if (a.category !== b.category) {
                const category_list = ['holiday', 'early', 'day', 'late', 'night', 'dawn'];
                return category_list.indexOf(a.category) - category_list.indexOf(b.category);
            }

            if (a.type !== b.type) {
                const type_list = [
                    'public_holidays',
                    'paid_holidays',
                    'public_half_holiday',
                    'paid_half_holiday',
                    'other_holiday',
                    'work',
                    'half_work'
                ];
                return type_list.indexOf(a.type) - type_list.indexOf(b.type);
            }

            if (a.category === 'holiday') {
                if (a.rest_start_time !== b.rest_start_time) {
                    return a.rest_start_time > b.rest_start_time ? 1 : -1;
                }
            } else {
                if (a.working_start_time !== b.working_start_time) {
                    return a.working_start_time > b.working_start_time ? 1 : -1;
                }
            }

            if (a.id !== b.id) {
                return a.id > b.id ? 1 : -1;
            }

            // どの条件にも合致しない場合は0を返す
            return 0;
        });
    },

    /**
     * workpatternの色を設定
     *
     */
    getWorkpatternColor: (workpattern) => {
        if (workpattern.category == 'holiday') {
            if (workpattern.hoped_flg) {
                if (workpattern.type == 'paid_holidays' || workpattern.type == 'paid_half_holiday') {
                    return 'workpattern_hope_paid_noafter';
                } else {
                    return 'workpattern_hope_off_noafter';
                }
            } else {
                if (workpattern.type == 'paid_holidays' || workpattern.type == 'paid_half_holiday') {
                    return 'workpattern_nothope_paid';
                } else if (workpattern.type == 'public_holidays' || workpattern.type == 'public_half_holiday') {
                    return 'workpattern_nothope_off';
                } else {
                    return 'workpattern_other';
                }
            }
        } else {
            return 'workpattern_' + workpattern.category;
        }
    },
};

