<template>
  <div>
    <div class="h-100">
      <b-row class="h-100 no-gutters">
        <MySlick />
        <b-col lg="8" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
          <b-col lg="6" md="8" sm="12" class="mx-auto app-login-box">
            <div class="app-logo" />
            <h4>
              <span>パスワードの設定を完了しました。</span>
            </h4>
            <p>新しいパスワードを使ってシステムにログインしてください。</p>
            <div>
              <h6 class="mb-0">
                <Link :href="$url('/login')" class="text-primary">ログイン画面に戻る</Link>
              </h6>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Layout2 from '@/Pages/Layouts/Layout2'
import { Link } from '@inertiajs/inertia-vue'
import MySlick from "@/Pages/Components/MySlick"

export default {
  name: 'ResetPasswordComplete',
  layout: Layout2,
  components: {
    MySlick
  },
  data: () => ({
  }),

  methods: {
  }
};
</script>

