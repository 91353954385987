<template>
  <div>
    <div class="input_row">
      <label class="required">月当たり公休数</label>
      <div style="width: calc(100% - 200px)!important;">
        <table :class="{ 'is-invalid': errors.monthly_paid_holidays }" class="office_table">
          <tr>
            <th v-for="(n, i) in 12" :key="i">{{ n }}月</th>
          </tr>
          <tr>
            <td v-for="(n, i) in 12" :key="i" style="width: calc(100% / 12);">
              <input
                v-model.number="value.monthly_paid_holidays[n]"
                type="number"
                class="form-control"
                style="margin-right: 8px;"
                min="1"
                max="31"
                @input="calcYearlyPaidHolidays"
                />
            </td>
          </tr>
        </table>
        <p class="explain_label" style="padding: 0px!important; margin: 8px 0px!important;">
          事業所で指定している月間の公休数を設定してください。<br>
          この数値は、各月のシフト作成の休暇日数の目安になります。
        </p>
        <div v-if="errors.monthly_paid_holidays" class="invalid-feedback">{{ errors.monthly_paid_holidays }}</div>
        <div v-for="n in 12" v-if="errors['monthly_paid_holidays.' + n]" class="invalid-feedback">
          {{ errors['monthly_paid_holidays.' + n] }}
        </div>
      </div>
    </div>
    <div class="input_row">
      <label for="yearly_paid_holidays">年間公休数</label>
      <div>
        <div class="input_row">
          <input
            type="text"
            v-model.number="value.yearly_paid_holidays"
            name="yearly_paid_holidays"
            class="form-control"
            style="width: 65px; margin-right: 8px;"
            readonly
          />
          <p class="after_label">日</p>
        </div>
        <p class="explain_label" style="padding: 0px!important; margin: 8px 0px!important;">
          月当たり公休数の合計値が表示されます。
        </p>
        <div v-if="errors.yearly_paid_holidays" class="invalid-feedback">{{ errors.yearly_paid_holidays }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.setDefaultMonthlyPaidHolidays();
  },
  data() {
    return {
    }
  },
  methods: {
    calcYearlyPaidHolidays() {
      this.value.yearly_paid_holidays = Object.values(this.value.monthly_paid_holidays).reduce((acc, value) => {
         return acc += Number(value);
      }, 0);
    },
    setDefaultMonthlyPaidHolidays() {
      if (Object.keys(this.value.monthly_paid_holidays).length === 0) {
        this.value.monthly_paid_holidays = {};
        for (let i = 1; i <= 12; i++) {
          this.$set(this.value.monthly_paid_holidays, i, 9);
        }
      }
      this.calcYearlyPaidHolidays();
    },
  },
}
</script>
