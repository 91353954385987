<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div class="h-100">
        <b-row class="h-100 no-gutters">
          <MySlick />
          <b-col lg="8" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <b-col lg="9" md="10" sm="12" class="mx-auto app-login-box">
              <div class="app-logo" />
              <h4>
                <span>
                  新しいパスワードを入力してください。
                </span>
                <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
              </h4>
              <div>
                <form @submit.prevent="submit">
                  <div>
                    <b-form-group>
                      <Label for="examplePassword">
                        パスワード
                      </Label>
                      <b-form-input type="password" name="password" v-model="form.password" placeholder="パスワードを入力してください。" />
                    </b-form-group>
                  </div>
                  <div>
                    <b-form-group>
                      <Label for="examplePasswordRep">
                        パスワード（再入力）
                      </Label>
                      <b-form-input type="password" name="passwordrep" v-model="form.password_confirmation" placeholder="もう一度パスワードを入力してください。" />
                    </b-form-group>
                  </div>
                  <div class="ml-auto">
                    <b-button type="submit" variant="primary" size="lg" :disabled="form.processing">パスワードリセット</b-button>
                    <Link :href="$url('/login')" class="pt-3 btn-lg btn btn-link">ログイン画面に戻る</Link>
                  </div>
                </form>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import Layout2 from '@/Pages/Layouts/Layout2'
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue'
import MySlick from "@/Pages/Components/MySlick"

export default {
  name: 'ResetPassword',
  layout: Layout2,
  components: {
    MySlick,
    Link
  },
  props : {
    token : {
      type: String,
      required :true
    },
    email : {
      type: String,
      required :true
    },
    errors: {
      type: Object,
      required: false,
    },
    role: {
        type: String,
        required: false,
    }
  },

  data: () => ({
    form: Inertia.form({
      password: null,
      password_confirmation: null,
    }),
  }),

  methods: {
    submit() {
      if (this.role == 'staff') {
          const message = `パスワードを忘れた方は管理者の方にご連絡ください。`;
          this.$swal({
              icon: 'info',
              html: message,
          });
          return;
      }
      this.form.transform(data => ({
          ...data,
          email: this.email,
          token: this.token
      })).post(this.$url('/reset-password'), {
        preserveScroll: true,
        onSuccess:()=>{
            const message = (this.role == 'staff') ? 'パスワードを変更いたしました。アプリにてログインしてください。' :'パスワードを変更いたしました。ログイン画面に遷移します。'
          this.$swal(
            '',
            message,
            'success')
        },
        onFinish: () => this.form.reset('password', 'password_confirmation'),
      })
    }
  }
};
</script>

<style scoped>
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d92550;
}
</style>
