<template>
  <div id="footerArea" class="app-footer closed-footer_inner">
    <div>
      <a href="https://synchroseries.jp/synchroshift/terms.html" target="_blank" rel="noopener noreferrer" style="margin-right: 16px;">利用規約</a>
      <a href="https://hp.syncube.co.jp/privacypolicy.html" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>
    </div>
    <div class="app-copylight">
      © SynCube Co., Ltd.
    </div>
  </div>
</template>

<script>
import FooterDots from "./Footer/FooterDots";
import FooterMenu from "./Footer/FooterMenu";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

library.add(faEllipsisV);
export default {
  name: "Footer",
  components: {
    FooterDots,
    FooterMenu
  }
};
</script>
