<template>
  <div style="font-size: 14px;">
    <div>
      <p style="font-size: 24px; font-weight: 700; margin-top: 24px;">まずは4つの設定を完了しましょう</p>
      <p style="color: #384250; margin-top: 16x;">4つの設定を完了することで、シフト作成をすることができるようになります。</p>
    </div>
    <div class="stepbar">
      <div v-if="configStatus >= 10000">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;">1</div>
          <div class="step_textarea">
            <p class="title">勤務区分を確認しましょう</p>
            <p class="txt">勤務区分は「早番」「日勤」「遅番」「夜勤」「明番」を登録することができ、シフト自動作成で職員に割り当てることができます。</p>
            <button class="btn_blue" @click="openWorkPattern">勤務区分を確認する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else>
        <div class="stepbarwrap" style="padding-top: 24px;">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">勤務区分を確認しましょう</p>
            <p class="txt">勤務区分は「早番」「日勤」「遅番」「夜勤」「明番」を登録することができ、シフト自動作成で職員に割り当てることができます。</p>
            <button v-bind:disabled="configStatus < 10000 && configProgress.check_workpattern === false" class="btn_white" @click="openWorkPattern">勤務区分を確認する</button>
          </div>
          <span class="stepline setting"></span>
        </div>
      </div>

      <div v-if="configStatus < 1000 && configProgress.check_office_and_shift === false">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;">2</div>
          <div class="step_textarea">
            <p class="title">事業所の登録とシフト設定をしましょう</p>
            <p class="txt">シフトを利用する事業所の情報を登録してください。<br />事業所ごとのシフト設定も確認しておきましょう。</p>
            <button class="btn_blue" @click="openOfficeList">事業所を登録する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else-if="configStatus > 1000 && configProgress.check_office_and_shift === true">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">事業所の登録とシフト設定をしましょう</p>
            <p class="txt">シフトを利用する事業所の情報を登録してください。<br />事業所ごとのシフト設定も確認しておきましょう。</p>
            <button disabled class="btn_white">事業所を登録する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else>
        <div class="stepbarwrap" style="padding-top: 24px;">
          <div v-if="configStatus > 100 || configProgress.check_office_and_shift === false">
            <div class="stepcircle" style="position: relative;">2</div>
          </div>
          <div v-else>
            <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          </div>
          <div class="step_textarea">
            <p class="title">事業所の登録とシフト設定をしましょう</p>
            <p class="txt">シフトを利用する事業所の情報を登録してください。<br />事業所ごとのシフト設定も確認しておきましょう。</p>
            <button v-bind:disabled="configStatus > 100 || configProgress.check_office_and_shift === false" class="btn_white" @click="openOfficeList">事業所を登録する</button>
          </div>
          <div v-if="configStatus > 100 || configProgress.check_office_and_shift === false">
            <span class="stepline"></span>
          </div>
          <div v-else>
            <span class="stepline setting"></span>
          </div>
        </div>
      </div>

      <div v-if="configStatus < 100 && configProgress.check_staff === false">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;">3</div>
          <div class="step_textarea">
            <p class="title">職員を登録しましょう</p>
            <p class="txt">シフトを割り当てる職員の情報を登録してください。</p>
            <button class="btn_blue" @click="openStaffList">職員を登録する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else-if="configStatus > 100 && configProgress.check_staff === true">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">職員を登録しましょう</p>
            <p class="txt">シフトを割り当てる職員の情報を登録してください。</p>
            <button disabled class="btn_white">職員を登録する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else-if="configStatus == 100 && configProgress.check_staff === true">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">職員を登録しましょう</p>
            <p class="txt">シフトを割り当てる職員の情報を登録してください。</p>
            <button disabled class="btn_white">職員を登録する</button>
          </div>
          <span class="stepline"></span>
        </div>
      </div>
      <div v-else>
        <div class="stepbarwrap" style="padding-top: 24px;">
          <div v-if="configStatus > 10 || configProgress.check_staff === false">
            <div class="stepcircle" style="position: relative;">3</div>
          </div>
          <div v-else>
            <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          </div>
          <div class="step_textarea">
            <p class="title">職員を登録しましょう</p>
            <p class="txt">シフトを割り当てる職員の情報を登録してください。</p>
            <button v-bind:disabled="configStatus > 10 || configProgress.check_staff === false" class="btn_white" @click="openStaffList">職員を登録する</button>
          </div>
          <div v-if="configStatus > 10 || configProgress.check_staff === false">
            <span class="stepline"></span>
          </div>
          <div v-else>
            <span class="stepline setting"></span>
          </div>
        </div>
      </div>

      <div v-if="configStatus < 10 && configProgress.check_request_holiday === false">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;">4</div>
          <div class="step_textarea">
            <p class="title">職員にスマホアプリから希望休を申請してもらいましょう</p>
            <p class="txt">職員にログイン情報を共有し、アプリから希望休を申請してもらってください。</p>
            <button class="btn_blue" @click="openApplicationStatus">申請状況を確認する</button>
          </div>
        </div>
      </div>
      <div v-else-if="configStatus > 10 && configProgress.check_request_holiday === true">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">職員にスマホアプリから希望休を申請してもらいましょう</p>
            <p class="txt">職員にログイン情報を共有し、アプリから希望休を申請してもらってください。</p>
            <button disabled class="btn_white">申請状況を確認する</button>
          </div>
        </div>
      </div>
      <div v-else-if="configStatus == 10 && configProgress.check_request_holiday === true">
        <div class="stepbarwrap">
          <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          <div class="step_textarea">
            <p class="title">職員にスマホアプリから希望休を申請してもらいましょう</p>
            <p class="txt">職員にログイン情報を共有し、アプリから希望休を申請してもらってください。</p>
            <button disabled class="btn_white">申請状況を確認する</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="stepbarwrap" style="padding-top: 24px;">
          <div v-if="configStatus > 0 || configProgress.check_request_holiday === false">
            <div class="stepcircle" style="position: relative;">4</div>
          </div>
          <div v-else>
            <div class="stepcircle setting" style="position: relative;"><font-awesome-icon icon="check" /></div>
          </div>
          <div class="step_textarea">
            <p class="title">職員にスマホアプリから希望休を申請してもらいましょう</p>
            <p class="txt">職員にログイン情報を共有し、アプリから希望休を申請してもらってください。</p>
            <button v-bind:disabled="configStatus > 0 || configProgress.check_request_holiday === false" class="btn_white" @click="openApplicationStatus">申請状況を確認する</button>
          </div>
        </div>
      </div>

      <div class="footer_area">
        <button v-bind:disabled="configStatus > 0" class="btn_blue" style="margin: 0 30px 30px 30px;" @click="openShiftSchedule">シフトを自動作成してみる！</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn_white {
  padding: 10px 16px!important;
}
</style>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      configStatus: 0,
      configProgress: []
    }
  },
  mounted: function() {
    let self = this
    axios
      .get(this.$route('onboarding.status'))
      .then(function(response) {
        self.configProgress = response.data
        let count = 0;
        for(let key in response.data) {
          key == 'check_workpattern' && response.data[key] === false ? count += 10000 : count +=0;
          // TODO 半休対応（S3以降）
          //key == 'check_halfwork' && response.data[key] === false ? count += 1000 : count +=0;
          key == 'check_office_and_shift' && response.data[key] === false ? count += 100 : count +=0;
          key == 'check_staff' && response.data[key] === false ? count += 10 : count +=0;
          key == 'check_request_holiday' && response.data[key] === false ? count += 1 : count +=0;
        }
        self.configStatus = count
      });
  },
  methods: {
    // ページ遷移
    openWorkPattern() {
      location.href= this.$url("/workpatterns/list");
    },
    openOfficeList() {
      location.href= this.$url("/offices/list");
    },
    openStaffList() {
      location.href= this.$url("/staffs/list");
    },
    openApplicationStatus() {
      location.href= this.$url("/shifts/application-status");
    },
    openShiftSchedule() {
      location.href= this.$url("/shifts/schedule/");
    }
  },
};
</script>
