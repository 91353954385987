<template>
  <div style="height: 30px; padding-left: 27px;">
    <v-checkbox
      v-model="switch_consecutive_workpattern"
      label="連続配置"
      class="switch-col-view"
      @change="changeConsecutiveWorkpattern"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'ConsecutiveWorkpattern',
  components: {
  },
  props: {
  },
  data() {
    return {
      switch_consecutive_workpattern: false,
    }
  },
  methods: {
    changeConsecutiveWorkpattern() {
      this.$emit('changeConsecutiveWorkpattern', this.switch_consecutive_workpattern);
    },
  },
}
</script>
