<style deep>
table {
  position: relative;
  height: 100%;
  border: 1px solid #DEE2E6 !important;
}
th {
  position: sticky;
  top: 0;
}
td {
  height: 100%;
  padding: 0px;
}
.td_none {
  display: none;
}
.custom-b-tfoot {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn_shift_save {
  width: auto;
  padding: 0px 20px!important;
}
</style>

<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="onClickOutside()" persistent content-class="shift-select-offices-dialog" scrollable>
    <div id="modalform" tabindex="-1" ref="modalform" style="color: #000000; font-size: 16px;">
      <v-card>
        <v-card-title style="font-weight: 700; justify-content: space-between; background: #FDF2F7;">
          <div style="color:#FF8CB9; padding-left: 30px;">シフトを自動作成します</div>
          <div><button class="btn_close" @click="dialog = false">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 50px 0px 50px;">
          <p style="font-weight: 700; padding-top: 30px;">
            <font-awesome-icon icon="pencil-alt" />
            &nbsp;勤務区分ごとに、出勤する人数を設定してください。
          </p>
          <p style="font-size=14px;">
            たとえば、2人以上、5人以下を設定した場合、常に2～5人が事業所に出勤しているシフトが作成されます。<br>
            ※一括指定を入力すると、選択した勤務区分の行 すべてに反映することができます。
          </p>
          <VuePerfectScrollbar id="sub_table" class="app-sidebar-scroll" style="height:auto; max-height: calc(100vh - 450px);">
            <b-table-simple class="table_Permanent">
              <b-thead>
                <b-tr>
                  <b-th style="min-width: 230px; max-width: 230px;">勤務区分</b-th>
                  <b-th v-for="(day_of_week, key, index) in day_of_weeks" :key="index">
                    {{ day_of_week }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(workpattern, workpattern_id) in workpatterns">
                  <template v-if="workpattern.type.indexOf('_holidays') === -1
                    && workpattern.type.indexOf('_holiday') === -1
                    && workpattern.type.indexOf('half_work') === -1"
                  >
                    <WorkpatternRow
                      :index="workpattern_id"
                      :workpattern="workpattern"
                      :assignment="assignment[workpattern_id]"
                      :border_color_style="border_color_style"
                      @updateWpAssignment="updateWpAssignment"
                    />
                    <b-tr v-show="'wp_' + workpattern_id in error_message && error_message['wp_' + workpattern_id] !== '' 
                                || 'wp_combi_' + workpattern_id in work_pattern_error_message && work_pattern_error_message['wp_combi_' + workpattern_id] !== ''">
                      <b-td style="color: #ff0000; font-weight: bold;" nowrap>
                        {{ work_pattern_error_message['wp_combi_' + workpattern_id] }}
                      </b-td>
                      <b-td colspan="9" style="color: #ff0000; font-weight: bold;" nowrap>
                        {{ error_message['wp_' + workpattern_id] }}
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <!-- 勤務区分グループ -->
                <template v-for="(list, key) in wp_list_workpattern_id_list">
                  <WpListRow
                    :index="key"
                    :info="functions.getWpListInfo(workpatterns, list)"
                    :assignment="wp_list_assignment[key]"
                    :border_color_style="border_color_style"
                    @openWpListDialog="openWpListDialog"
                    @deleteWpList="deleteWpList"
                    @updateWpListAssignment="updateWpListAssignment"
                  />
                  <b-tr v-show="'wp_list_' + key in error_message && error_message['wp_list_' + key] !== ''
                              || 'wp_combi_' + key in work_pattern_error_message && work_pattern_error_message['wp_combi_' + key] !== ''">
                    <b-td style="color: #ff0000; font-weight: bold;" nowrap>
                      {{ work_pattern_error_message['wp_combi_' + key] }}
                    </b-td>
                    <b-td colspan="9" style="color: #ff0000; font-weight: bold;" nowrap>
                      {{ error_message['wp_list_' + key] }}
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
              <b-tfoot class="custom-b-tfoot">
                <b-tr style="text-align:center;" >
                  <b-td colspan="2" style="padding: 5px;">最小値の合計/職員数</b-td>
                  <b-td v-for="(min_count , day) of minCountListForDay" :key="day" style="padding: 5px;">
                    {{ min_count }}/{{ totalStaffCount }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td v-if="!totalStaffCountCheck" colspan="10" style="color:red;padding: 5px;">
                    &nbsp;職員数の合計は最小値の合計以上の値を設定してください。この状態では最適なシフトが作成できない可能性があります。
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </VuePerfectScrollbar>
          <b-button class="btn_shift_save" @click="openWpListDialog(-1)">勤務区分グループ登録</b-button>
          <p style="color: #979797; padding-top: 10px;">
            シフト作成後、リセットを押すと希望休反映後に戻すことができます。
          </p>
        </v-card-text>
        <v-card-actions style="padding-bottom: 30px;">
          <v-spacer></v-spacer>
          <v-btn
            class="btn_gray_shadow"
            style="color: #979797; background: #FFFFFF;"
            @click="beforeSubmit('submit')"
          >保存して閉じる</v-btn>
          <v-btn
            class="btn_green_shadow"
            style="color: #02B28A; background: #FFFFFF;"
            @click="beforeSubmit('autoCreate')"
          >保存して、シフト自動作成</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <WpListDialog
      ref="wpListDialog"
      :work_categories="work_categories_except_holiday"
      :workpatterns="workpatterns"
      @updateWpList="updateWpList"
    />
  </v-dialog>
</template>

<script>
import functions from '@/Pages/Shifts/Components/Dialog/PermanentPlacement/functions';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import WorkpatternRow from '@/Pages/Shifts/Components/Dialog/PermanentPlacement/AssignmentRow/Workpattern';
import WpListRow from '@/Pages/Shifts/Components/Dialog/PermanentPlacement/AssignmentRow/WpList';
import WpListDialog from '@/Pages/Shifts/Components/Dialog/WpListDialog';

export default {
  name: 'ShiftsSchedulePermanentPlacementDialog',
  components: {
    VuePerfectScrollbar,
    WorkpatternRow,
    WpListRow,
    WpListDialog,
  },
  props: {
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
    workpatterns: {
      type: Object,
      default: () => {
        return {};
      }
    },
    work_categories: {
      type: Object
    },
    staff_assignments: {
      type: Object,
      default: () => {
        return {};
      }
    },
    basic_infos: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data () {
    return {
      // functions
      functions: functions,

      dialog: false,
      bulk_add_url: '',
      day_of_weeks : {
        def: '一括指定',
        mon: '月',
        tue: '火',
        wed: '水',
        thu: '木',
        fri: '金',
        sat: '土',
        sun: '日',
        hol: '祝'
      },
      assignment: {},
      error_message: {},
      work_pattern_error_message: {},
      border_color_style: {},
      tr_message: false,
      // work_categoriesからholidayを除いたもの
      work_categories_except_holiday: {},
      // wp_listのassignment
      wp_list_workpattern_id_list: {},
      wp_list_assignment: {},
      // workpattern group選択dialogを開く際に設定
      // どのindexの情報を開いているかを記録する
      target_wp_dialog_index: -1,
      // wp_list_list 新規追加分の中で次に付与されるindex
      next_index: 0,

      // 初期のassignmentデータ
      initial_assignment: {
        def: { min: 0, max: 0 },
        sun: { min: 0, max: 0 },
        mon: { min: 0, max: 0 },
        tue: { min: 0, max: 0 },
        wed: { min: 0, max: 0 },
        thu: { min: 0, max: 0 },
        fri: { min: 0, max: 0 },
        sat: { min: 0, max: 0 },
        hol: { min: 0, max: 0 },
      }
    }
  },
  methods: {
    openDialog() {
      this.assignment = this.staff_assignments[this.team_id];

      // wp_listを初期化する team_idのリストが存在しなければ空
      let initial = this.office.wp_list_assignment;
      if (this.team_id in initial) {
        for (let key in initial[this.team_id]) {
          let group = initial[this.team_id][key];
          this.$set(this.wp_list_workpattern_id_list, key, group.workpattern_id_list);
          this.$set(this.wp_list_assignment, key, group.staff_assignment);
        }
      }

      this.dialog = true;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    // モーダルの外側をクリックした時にフォーカスを当てる
    onClickOutside() {
      this.$refs.modalform.focus();
    },
    closeDialog() {
      this.dialog = false;

      Object.keys(this.wp_list_workpattern_id_list).forEach(key => {
        this.$delete(this.wp_list_workpattern_id_list, key)
        this.$delete(this.wp_list_assignment, key)
      });

      Object.keys(this.error_message).forEach(key => {
        this.$delete(this.error_message, key)
      });
      Object.keys(this.border_color_style).forEach(key => {
        this.$delete(this.border_color_style, key)
      });
    },
    updateWpAssignment(workpattern_id, key, type, value) {
      this.assignment[workpattern_id][key][type] = value;
      this.validate(this.assignment, 'wp', workpattern_id, key);
    },
    updateWpListAssignment(index, key, type, value) {
      this.wp_list_assignment[index][key][type] = value;
      this.validate(this.wp_list_assignment, 'wp_list', index, key);
    },
    // バリデーション
    // a: workpattern_id/index, b: day of week
    validate(list, type, a, b) {
      console.log('validate');
      let min = Number(list[a][b].min);
      let max = Number(list[a][b].max);
      const index_error_message = type + '_' + a;
      const index_border_color_style = type + '_' + a + '_' + b;
      if (min > max) {
        this.$set(this.error_message, index_error_message, '最大値は最小値以上に設定してください。');
        this.$set(this.border_color_style, index_border_color_style, 'border-color: #ff0000');
      } else {
        this.$set(this.border_color_style, index_border_color_style, '');
      }
      let is_error = false;
      for (let i in this.border_color_style) {
        if (i.indexOf(a) == -1) continue;
        if (this.border_color_style[i] != '') {
          is_error = true;
          break;
        }
      }
      if (!is_error) {
        this.error_message[index_error_message] = '';
      }
    },
    // 保存前処理（勤怠区分の重複チェック）
    beforeSubmit(kind) {
      // エラー情報のリセット
      this.work_pattern_error_message = [];

      if (this.wp_list_workpattern_id_list == null 
          || this.wp_list_workpattern_id_list == undefined 
          || this.wp_list_workpattern_id_list.length == 0)
      {
        // 追加された勤務区分がない場合は保存処理へ移行
        if (kind == 'submit') {
          this.submit();
        } else if (kind == 'autoCreate') {
          this.autoCreate();
        }
        return;
      }

      // 常設されている勤務区分リストの抽出
      let permanent_work = [];
      for (const id in this.workpatterns) {
        if (this.workpatterns[id].type.indexOf('_holidays') === -1
          && this.workpatterns[id].type.indexOf('_holiday') === -1
          && this.workpatterns[id].type.indexOf('half_work') === -1)
        {
          permanent_work[id] = this.workpatterns[id];
        }
      }
      // 追加された勤務区分より重複された組み合わせがあるかチェックする
      Object.keys(this.wp_list_workpattern_id_list).forEach(key => {
        let is_error = false;
        // 常設されている勤怠区分リストの中に重複があるかをチェック
        if (this.wp_list_workpattern_id_list[key].length == 1) {
          // 複数組み合わせの場合は常設分と重複しないはずなのでチェックせず、単一の勤怠区分が指定されている場合のみチェックを行う
          Object.keys(permanent_work).forEach(index => {
            if (this.wp_list_workpattern_id_list[key][0] == index) {
              // 同じ勤怠区分があればエラー扱いとする
              this.$set(this.work_pattern_error_message, 'wp_combi_' + key, '勤怠区分の組み合わせが重複しています。');
              is_error = true;
              return;
            }
          });
        }
        if (is_error) {
          // 既にエラー済みである場合は次の勤務区分を検査する
          return;
        }

        // 追加された勤務区分の中に重複があるかチェック
        Object.keys(this.wp_list_workpattern_id_list).forEach(key2 => {
          if (key == key2) {
            // 自身のチェックは除外する
            return;
          }
          // 指定されている勤務区分全てを検証する
          let is_dup = false;
          this.wp_list_workpattern_id_list[key].forEach(id => {
            if (is_dup) {
              // 既に重複ありと判断している場合はチェックをスキップ
              return;
            }
            if (this.wp_list_workpattern_id_list[key2].includes(id)) {
              // 同一の勤務区分が含まれている場合はエラーとみなす
              is_dup = true;
            }
          });
          if (is_dup) {
            // 勤務区分の組み合わせが重複している場合はエラー扱いとする
            is_error = true;
            this.$set(this.work_pattern_error_message, 'wp_combi_' + key, '勤務区分の組み合わせが重複しています。');
          }
        });
      });
      if (Object.keys(this.work_pattern_error_message).length == 0) {
          // エラーがない場合は保存処理へ移行
        if (kind == 'submit') {
          this.submit();
        } else if (kind == 'autoCreate') {
          this.autoCreate();
        }
      }
    },
    submit() {
      let is_ok = true;
      for (let key in this.error_message) {
        if (this.error_message[key] != '') {
          is_ok = false;
          break;
        }
      }
      if (is_ok) {
        this.$emit('savePlacement', this.assignment, this.wp_list_workpattern_id_list, this.wp_list_assignment, 'save');
      } else {
        this.$swal.fire({
          icon: 'error',
          text: '最大値は最小値以上に設定してください。'
        })
      }
    },
    autoCreate() {
      if (Object.keys(this.assignment).length == 0) {
        // URLの作成
        let url = this.$route('staffs.workcons.bulk-add');
        if (url.slice(-1) == '/') {
          url = url.slice(0, -1);
        }
        url += '?office=' + this.office.id;
        url += '&type=1';

        this.$swal({
            html: '<a href="' + url + '">勤務条件一括登録画面</a>にて職員へ勤務区分を割り当ててください。',
            icon: 'warning',
            confirmButtonText: '閉じる',
        })
        return;
      }
      // 常時配置人数設定(max,min)がすべて0の場合はエラー
      if(!this.checkStaffAssignmentNum()){
        this.$swal.fire({
            icon: 'error',
            text: '常時配置人数設定では、最低1つの勤務区分に対して、上限値を1以上に設定する必要があります。'
        })
        return;
      }

      let is_ok = true;
      for (let key in this.error_message) {
        if (this.error_message[key] !== '') {
          is_ok = false;
          break;
        }
      }
      if (is_ok) {
        this.$emit('savePlacement', this.assignment, this.wp_list_workpattern_id_list, this.wp_list_assignment, 'auto_create');
      } else {
        this.$swal.fire({
          icon: 'error',
          text: '最大値は最小値以上に設定してください。'
        })
      }
    },
    // 常時配置人数設定(max,min)がすべて0の場合はエラー
    checkStaffAssignmentNum() {
      // オブジェクトのvalueが全て0であるかのチェック
      const checkZeroWorkpattern = Object.keys(this.assignment).map((workpattern_id) => {
        const workpattern = this.workpatterns[workpattern_id]
        // workpattern削除済み（idがない）だがoffice_staff_workpatternに残っているデータはチェック対象外
        if(!workpattern) return false
        // 休日系の勤務区分はチェック対象外
        if(workpattern.type.indexOf('_holidays') !== -1 || workpattern.type.indexOf('_holiday') !== -1) return false
        // 半日勤務系の勤務区分はチェック対象外
        if(workpattern.type.indexOf('half_work') !== -1) return false

        // 勤務区分のmin,maxがすべて0であるかのチェック
        const office_staff_workpattern = this.assignment[workpattern_id]
        const checkZeroMinMax =  Object.values(office_staff_workpattern).map((value) => {
          // 曜日（def含む）ごとのmin,maxがすべて0であるかのチェック
          if (Number(value.min) > 0 || Number(value.max) > 0) {
            return true
          }
          return false
        }).filter(Boolean) // falseをフィルタリング
        // 一つでもtrueならtrueを返す
        if (checkZeroMinMax.length > 0) {
          return true
        }
        return false
      }).filter(Boolean)

      // 一つでもtrueならtrueを返す
      return checkZeroWorkpattern.length > 0;
    },
    // 勤務区分選択ダイアログ表示
    openWpListDialog(index) {
      let list = [];
      if (index != -1) {
        // 編集処理
        this.target_wp_dialog_index = index;
        list = this.wp_list_workpattern_id_list[index];
      } else {
        this.target_wp_dialog_index = -1;
        list = [];
      }
      this.$refs.wpListDialog.openDialog(list);
    },
    // 勤務区分選択ダイアログから返却
    updateWpList(list) {
      if (this.target_wp_dialog_index == -1) {
        this.$set(this.wp_list_workpattern_id_list, 'new_' + this.next_index, list);
        this.$set(this.wp_list_assignment, 'new_' + this.next_index, structuredClone(this.initial_assignment));
        this.next_index++;
      } else {
        this.$set(this.wp_list_workpattern_id_list, this.target_wp_dialog_index, list);
      }
      this.target_wp_dialog_index = -1;
    },
    // 勤務区分グループの削除
    deleteWpList(index) {
      this.$delete(this.wp_list_workpattern_id_list, index);
      this.$delete(this.wp_list_assignment, index);
    },
  },
  watch: {
  },
  computed: {
    // 有効な職員数
    totalStaffCount () {
      // basic_infosからstaff_id数を取得
      return (new Set(this.basic_infos.map(basic_info => basic_info.staff_id))).size;
    },
    // 常時配置人数のminの合計
    minCountListForDay() {
      // 勤務区分合計のmin
      let total_min_for_day = {}
      Object.keys(this.assignment).map(workpattern_id => {
        // dayごと（縦列）の合計
        Object.keys(this.assignment[workpattern_id]).map(day => {
          // dayがdefなら飛ばす
          if(day === 'def') return
          // 初期化
          if(total_min_for_day[day] === undefined) {
            total_min_for_day[day] = 0
          }
          total_min_for_day[day] += Number(this.assignment[workpattern_id][day].min) || Number(this.assignment[workpattern_id]['def'].min)
        })
      })

      // mon,tue,wed,thu,fri,sat,sun,holの順に並び替え
      const day_of_weeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'hol']
      const total_min_for_day_sorted = {}
      day_of_weeks.forEach(day => {
        total_min_for_day_sorted[day] = total_min_for_day[day]
      })
      return total_min_for_day_sorted;
    },
    // 曜日のうち一つでもNGならfalseを返す
    totalStaffCountCheck() {
      return Object.keys(this.minCountListForDay).find(day => this.minCountListForDay[day] > this.totalStaffCount) === undefined
        ? true
        : false
    }
  },
  mounted() {
    this.assignment = this.staff_assignments[0];

    this.work_categories_except_holiday = { ...this.work_categories };
    delete  this.work_categories_except_holiday.holiday;
  },
}
</script>
