<template>
    <v-autocomplete 
        v-model="officeId"
        @change="onChange"
        @blur="$emit('blur')"
        :class="{ 'is-invalid': errors.office_id }"
        :items="officeList"
        class="autocomplete-style"
        item-text="text"
        item-value="value"
        hide-details="true"
        placeholder="選択してください"
        no-data-text="事業所が見つかりません。"
        dense
        filled
    />
</template>
<script>
export default {
    name: 'OfficeSelectBox',
    props: {
        value: {
            type: Number,
            required: false,
        },
        officeList: {
            type: Array,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
    },
    computed: {
        officeId: {
            get() {
                return this.value;
            },
            set(data) {
                this.$emit('input', data);
            },
        },
    },
    methods: {
        onChange(officeId) {
            this.$emit('change', officeId);
        },
    },
};
</script>
