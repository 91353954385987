<template>
  <div style="font-size: 14px; margin-left: 3px;">
    <div v-if="!error_item.fix" class="error-area" style="align-items: flex-start!important;">
      <div v-if="!bottom_message" class="label-message" style="color: #2F7EDC; background: #FFFFFF;">
        <font-awesome-icon icon="circle-exclamation" class="icon-message" />
        確認する
      </div>
      <div style="color: #2F7EDC; white-space: nowrap;">【{{ error_item.employee_number }} {{ error_item.staff_lastname }} {{ error_item.staff_firstname }}】</div>
      <div>{{ error_item.error_message }}</div>
    </div>
    <div v-else class="error-area">
      <div class="label-message" style="color: #0BB0BE; background: #F5F5F5;">
        <font-awesome-icon icon="check" class="icon-message" />
        解決しました
      </div>
      <div v-if="!bottom_message" style="color: #94BCED;">【{{ error_item.employee_number }} {{ error_item.staff_lastname }} {{ error_item.staff_firstname }}】</div>
      <div v-if="!bottom_message" style="color: #9EA1A7;">{{ error_item.error_message }}</div>
    </div>
  </div>
</template>

<style scope>
.error-area {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.icon-message {
  font-weight: 600;
  vertical-align: text-top;
  padding-right: 10px;
}
.label-message {
  display: flex;
  border: 1px solid #D2D6DB!important;
  border-radius: 8px!important;
  box-shadow: 0px 3px 0px #0000000F;
  align-items: center!important;
  height: 30px;
  width: 150px;
  padding-left: 20px;
  margin-right: 10px;
}
</style>

<script>
export default {
  components: {
  },
  props: {
    error_item: {
      type: Object,
      required: true
    },
    bottom_message: {
      type: Boolean,
      required: false,
      default: false
    },
/* memo error_item.error_type
    1:勤務区分（1つも割り当てなし）
    2:勤務種別：公休（全休）の勤務区分の複数割り当て
    3:労働時間下限（0未満）
    4:労働時間上限（0未満）
    5:労働時間（下限＞上限）
*/
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>
