<style scoped>
.working_select .v-select__selection--comma {
  margin: auto;
}
.v-card__title {
  position: sticky;
  top: 0;
}
.v-card__actions {
  position: sticky;
  background: #FFFFFF;
  bottom: 0;
}
</style>

<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="onClickOutside();" persistent max-width="650">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div></div>
          <div style="text-align: center;">
            勤務区分を選択
          </div>
          <div>
            <button class="btn_close" @click="dialog = false">✕</button>
          </div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <!-- category選択 -->
          <CategoryView
            ref="categoryView"
            :categories="work_categories"
            :selected="selected_category" :view_holiday="false"
            @changeCategory="changeCategory"
          />
          <div style="margin-top: 30px;">
            <div v-for="item in view_workpatterns">
              <label class="workpattern_change_button workpattern_change_data" :workpattern_id="item.id" :category="item.category" :type="item.type">
                <input
                  v-model="selected"
                  type="checkbox"
                  :key="item.id"
                  :value="item.id"
                >
                <span style="display: flex; justify-content: space-between; width: 100%">
                  <div
                    :class="'workpattern_change_color ' + wf.getWorkpatternColor(item)"
                    style="width: 30px !important; border: initial !important; background: initial !important;"
                  >●</div>
                  <div style="width: 100%;">{{ item.name }}</div>
                  <div style="width: 150px;">
                    {{ item.working_start_time.slice(0, 5) }} - {{ item.working_end_time.slice(0, 5) }}
                  </div>
                </span>
              </label>
            </div>
          </div>
          <div v-show="error_text != ''" style="color: #ff0000; font-weight: bold;">{{ error_text }}</div>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
          <div style="display: flex; justify-content: space-between; width: 100%; padding: 20px 70px;">
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="save"
            >保存</button>
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="dialog = false"
            >キャンセル</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import wf from '@/Pages/Shifts/functions/workpatterns';
import CategoryView from '@/Pages/Shifts/Components/CategoryView';

export default {
  name: 'WpListDialog',
  components: {
    CategoryView,
  },
  props: {
    // work categoryのリスト
    work_categories: {
      type: Object
    },
    workpatterns: {
      type: Object
    },
  },
  data () {
    return {
      // workpattern functions
      wf: wf,
      dialog: false,
      selected_category: 'all',
      view_workpatterns: {},
      // 選択された勤務区分ID
      selected: [],
      // エラーメッセージ
      error_text: ''
    }
  },
  methods: {
    openDialog(wp_list) {
      this.error_text = '';
      // 全てを選択
      this.selected_category = 'all';
      this.selected = []; // 選択状態をクリア

      // 選択済みのidを設定
      for (let id of wp_list) {
        this.selected.push(id);
      }

      // 初期化
      this.initialize();

      // モーダル画面表示
      this.dialog = true;
    },
    onClickOutside() {
      this.$refs.modalform.focus();
      this.dialog = false;
    },
    // 選択されたcategoryに応じてデータを初期化．
    initialize() {
      this.view_workpatterns = {};

      // 勤務区分抽出
      for (let key in this.workpatterns) {
        if (this.workpatterns.hasOwnProperty(key)) {
          let workpattern = this.workpatterns[key];
          if (
            (workpattern.category === this.selected_category || this.selected_category === 'all')
            && workpattern.category != 'holiday'
            && workpattern.type === 'work'
          ) {
              this.$set(this.view_workpatterns, workpattern.id, workpattern);
          }
        }
      }

      // 勤務区分のリストをソート
      this.view_workpatterns = wf.sortWorkpatterns(this.view_workpatterns) || {};
    },
    // 勤務カテゴリ変更
    changeCategory(val) {
      this.selected_category = val;
      this.initialize();
    },
    // 選択した勤務区分を親componentに送信
    save() {
      this.error_text = '';
      if (this.selected.length == 0) {
        // 未選択の場合はエラーとする
        this.error_text = '勤務区分を選択してください。';
        return;
      }

      // グループ情報をcomponentに送信
      this.$emit('updateWpList', [...this.selected]);

      // 選択済みのidを削除
      this.selected.splice(0);

      this.dialog = false;
    },
  },
}
</script>
