<template>
  <b-overlay :show="show" rounded="sm">
    <div style="font-size: 14px;">
      <h4 style="margin-bottom: 24px;">職員管理　一覧</h4>
      <b-row style="margin: 0px 0px 32px 0px;">
        <b-col cols="6" style="padding: 0px;">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="キーワード検索"
            outlined
            hide-details
            dense
            style="width: 320px; border-radius: 8px; background: #FFFFFF;"
          ></v-text-field>
        </b-col>
        <b-col cols="6" style="display:flex; justify-content: flex-end;">
            <a :href="bulk_add_url">
              <b-button class="btn_white" :disabled="isDisabled" @click="loding_start">
                勤務条件一括登録
              </b-button>
            </a>
            <b-button class="btn_white" @click="addItem()">
              <font-awesome-icon icon="plus" class="icon-style" />職員を追加
            </b-button>
            <div class="import-btn">
              <b-button class="btn_white" @click="csvimport()">
                <font-awesome-icon icon="upload" class="icon-style" />CSVインポート
              </b-button>
            </div>
        </b-col>
      </b-row>
      <div style="display: flex; margin: 0px 0px 16px;">
        <div style="width:20%; margin: 0px 12px 0px 0px;">
          <p>拠点名</p>
          <div class="box_1">{{ lodgment.name }}</div>
        </div>
        <div style="margin: 0px 12px 0px 0px; width:20%;">
          <p>事業所</p>
          <v-autocomplete
            v-model="target_office_id"
            :items="office_list"
            @change="changeOffice"
            class="autocomplete-style"
            style="width: 100%; min-width: 100%;"
            item-text="name"
            item-value="id"
            hide-details="true"
            no-data-text="事業所が見つかりません。"
            dense
            filled
          ></v-autocomplete>
        </div>
        <div style="margin: 0px 12px 0px 0px; width:20%;">
          <p>チーム</p>
          <v-autocomplete
            v-model="target_team_id"
            :items="team_list"
            @change="changeTeam"
            class="autocomplete-style"
            style="width: 100%; min-width: 100%;"
            item-text="name"
            item-value="id"
            hide-details="true"
            no-data-text="チームが見つかりません。"
            dense
            filled
          ></v-autocomplete>
        </div>
        <div style="margin: 0px 12px 0px 0px; width:20%;">
          <p>勤務状況</p>
          <v-autocomplete
            v-model="target_work_status"
            :items="work_status_list"
            @change="changeWorkStatus"
            class="autocomplete-style"
            style="width: 100%; min-width: 100%;"
            item-text="name"
            item-value="id"
            hide-details="true"
            no-data-text="勤務状況が見つかりません。"
            dense
            filled
          ></v-autocomplete>
        </div>
        <div style="margin: 0px 12px 0px 0px; width:20%;">
          <p>フラグ</p>
          <v-autocomplete
            v-model="target_flag"
            :items="flag_list"
            @change="changeFlag"
            class="autocomplete-style"
            style="width: 100%; min-width: 100%;"
            item-text="name"
            item-value="id"
            hide-details="true"
            no-data-text="フラグが見つかりません。"
            dense
            filled
          ></v-autocomplete>
        </div>
      </div>
      <b-row>
        <b-col cols="12" md="3">
        </b-col>
      </b-row>
      <Form>
        <v-data-table
          :headers="list_headers_basic"
          :items="items"
          :items-per-page="staffs_list"
          :footer-props="footerProps"
          :search="search"
          :custom-filter="staffNameFilter"
          item-key="office_staff_id"
          no-data-text="データが存在しません"
          no-results-text="データが存在しません"
          class="elevation-1 table_1"
          style="width: 100%;"
          fixed-header
          height="calc(100vh - 400px)"
          @update:sort-by="sortByUpdated"
          @update:sort-desc="sortDescUpdated"
          @update:items-per-page="savePerPage"
        >
          <template v-slot:item.id="{ item }">
            <div class="name_link" @click="editItem(item)">{{ getFullName(item.id) }}</div>
          </template>
          <template v-slot:item.user.role="{ item }">
            {{ getRoleName(item.user.role) }}
          </template>
          <template v-slot:item.employment_type="{ item }">
            {{ getEmploymentTypeName(item.employment_type) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <b-button class="btn_actions" @click="editItem(item)"><i class="pe-7s-pen"></i>変更</b-button>
            <b-button class="btn_actions" @click="deleteItem(item)" style="padding-right: 0px;"><i class="pe-7s-trash"></i>削除</b-button>
          </template>
          <template v-slot:no-data>
            データが存在しません
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.itemsLength }} 件中 {{ props.pageStart }} 件から
            {{ props.pageStop }} 件までを表示
          </template>
        </v-data-table>
      </Form>
    </div>
  </b-overlay>
</template>

<style scope>
.zsiq_cnt {
  display: initial !important;
}

.btn_white {
  height: 37px !important;
  margin-left: 20px;
}

.icon-style {
  color: #0BB0BE;
  vertical-align: text-top;
  padding-right: 10px;
}
</style>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import { Inertia } from '@inertiajs/inertia'
Vue.use(VueCookie);

export default {
  components: {
  },
  props: {
    staffs: {
      type: Object,
      required: true
    },
    offices: {
      type: Object,
      required: true,
    },
    teams: {
      type: Array | Object,
      required: true,
    },
    codes: {
      type: Object,
      required: true,
    },
    work_statuses: {
      type: Array | Object,
      required: true,
    },
    flags: {
      type: Array | Object,
      required: true,
    },
    // 検索用
    initial_office_id: {
      type: Number | String
    },
    // 検索用
    initial_team_id: {
      type: Number | String
    },
    // 検索用
    initial_work_status: {
      type: Number | String
    },
    // 検索用
    initial_flag: {
      type: Number | String
    },
    num_of_available_staffs: {
      type: Number,
      required: true
    },
    num_of_active_staffs: {
      type: Number,
      required: true
    },
    user_role: {
      type: String
    },
    plan_name: {
      type: String,
    },
  },
  computed: {
    // 拠点
    lodgment() {
      return this.$page.props.auth.lodgment
    },
    // 事業所リスト（「全事業所」を追加)
    office_list() {
      if (this.user_role == 'admin') {
        this.offices.data.push({ id: 0, name: '全事業所' })
      }
      return this.offices.data
    },
    // チームリスト（「全チーム」を追加)
    team_list() {
      this.teams[this.target_office_id].data.unshift({ id: 0, name: '全チーム' })
      return this.teams[this.target_office_id].data
    },
    // 勤務状況リスト
    work_status_list() {
      return this.work_statuses;
    },
    // フラグリスト
    flag_list() {
      return this.flags;
    },
    // システム権限リスト
    role_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "system_role";
      });
    },
    // 雇用タイプリスト
    employment_type_list() {
      return this.codes.data.filter(function (code) {
        return code.type === "employment_type";
      });
    },
    items() {
      return this.staffs.data
    },
    bulk_add_url() {
      let url = this.$route('staffs.workcons.bulk-add');
      let query_strings = [];
      if (this.target_office_id != 0) {
        query_strings.push('office=' + encodeURIComponent(this.target_office_id));
      }
      if (this.target_team_id != 0) {
        query_strings.push('team=' + encodeURIComponent(this.target_team_id));
      }
      const sort_sign = this.sort_desc ? -1 : 1;
      if (this.sort_by == 'user.employee_number') {
        query_strings.push('sort=' + (1 * sort_sign));
      } else if (this.sort_by == 'id') {
        query_strings.push('sort=' + (3 * sort_sign));
      }
      query_strings.push('type=1');
      if (query_strings.length > 0) {
        url += '?' + query_strings.join('&');
      }
      return url;
    },
    isDisabled() {
      // 勤務条件一括登録制御
      if (this.target_office_id == 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      search: '',
      // 表データ
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100, -1],
        'items-per-page-text': '表示件数： ',
        'items-per-page-all-text': 'すべて',
      },
      // 表ヘッダー（ユーザー情報列）
      list_headers_basic: [
        { text: '社員番号', value: 'user.employee_number', align: 'start', sortable: true, class: "text-center", width: '15%' },
        { text: '職員名', value: 'id', width: '35%', sortable: true },
        { text: 'システム権限', value: 'user.role', width: '15%', sortable: true },
        { text: '雇用形態', value: 'employment_type', width: '15%', sortable: true },
        { text: '', value: 'actions', width: '20%', sortable: false },
      ],
      // 選択事業所ID
      target_office_id: this.offices.data[0] ? this.offices.data[0].id : 0,
      // チームID（0:全チーム）
      target_team_id: 0,
      csv_form: this.$inertia.form({
        csv: null,
        office_id: null
      }),
      zoho_link: 'https://support.synchroshift.jp/portal/en/kb/articles/%E8%81%B7%E5%93%A1%E3%81%8C%E5%89%8A%E9%99%A4%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84',
      show: false,
      staffs_list: Number(this.$cookie.get('staffs_list')),
      sort_by: undefined,
      sort_desc: false,

      // 追加検索項目（勤務状況）
      target_work_status: 0,
      // 追加検索項目（フラグ）
      target_flag: 0,
    }
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
    savePerPage(val) {
      this.$cookie.set('staffs_list', val, 1);
    },
    /**
     * 表の操作
     */
    // 社員名フィルター
    staffNameFilter(value, search, item) {
      return (item.user && item.user.employee_number && item.user.employee_number.indexOf(search) !== -1)
        || (this.getFullName(item.id) && this.getFullName(item.id).indexOf(search) !== -1)
        || (item.user && item.user.role && this.getRoleName(item.user.role) && this.getRoleName(item.user.role).indexOf(search) !== -1)
        || (item.employment_type && this.getEmploymentTypeName(item.employment_type) && this.getEmploymentTypeName(item.employment_type).indexOf(search) !== -1)
    },

    // 表のフィルタの事業所選択
    changeOffice() {
      // 選択された事業所に紐づくデータを取得
      if (this.target_office_id != null) {
        this.updateLoading(true);
        let url = '/staffs/list?office_id=' + this.target_office_id
          + "&work_status=" + this.target_work_status
          + "&flag=" + this.target_flag
        this.$inertia.get(this.$url(url, { preserveState: true }));
      }
    },
    // 表のフィルタのチーム選択
    changeTeam() {
      // 選択されたチームに紐づくデータを取得
      if (this.target_office_id != null && this.target_team_id != null) {
        this.updateLoading(true);
        let url = '/staffs/list?office_id=' + this.target_office_id
          + "&team_id=" + this.target_team_id
          + "&work_status=" + this.target_work_status
          + "&flag=" + this.target_flag
        this.$inertia.get(this.$url(url, { preserveState: true }));
      }
    },
    // 表のフィルタの勤務状況選択
    changeWorkStatus() {
      this.updateLoading(true);

      let url = '/staffs/list?work_status=' + this.target_work_status;
      if (this.target_flag != null) {
        url = url + '&flag=' + this.target_flag
      }
      if (this.target_office_id != null) {
        url = url + '&office_id=' + this.target_office_id
      }
      if (this.target_office_id != null && this.target_team_id != null) {
        url = url + '&team_id=' + this.target_team_id
      }
      this.$inertia.get(this.$url(url, { preserveState: true }));
    },
    // 表のフィルタのフラグ選択
    changeFlag() {
      this.updateLoading(true);

      let url = '/staffs/list?flag=' + this.target_flag;
      if (this.target_work_status != null) {
        url = url + '&work_status=' + this.target_work_status
      }
      if (this.target_office_id != null) {
        url = url + '&office_id=' + this.target_office_id
      }
      if (this.target_office_id != null && this.target_team_id != null) {
        url = url + '&team_id=' + this.target_team_id
      }
      this.$inertia.get(this.$url(url, { preserveState: true }));
    },

    addItem() {
      if (this.num_of_available_staffs === 0) {
        this.$swal({
          text: '契約情報が正しく設定されていません。',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => { location.reload() })
      } else if (this.num_of_available_staffs < this.num_of_active_staffs + 1) {
        let massage = "";
        if (this.plan_name === 'free') {
          let plan_url = route('lodgments.plan-edit');
          massage = `機能制限無料プランでの職員登録の上限は${this.num_of_available_staffs}名までとなります。${this.num_of_available_staffs + 1} 名以上ご利用の際は、<a href=' ${plan_url} '>スタンダードプランへの変更</a>をお願いします。`;
        } else {
          massage = '契約数 (' + this.num_of_available_staffs + '名) を超過するため職員の追加ができません。';
        }
        this.$swal({
          html: massage,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => { location.reload() })
      } else {
        this.updateLoading(true);
        this.$inertia.get(route('staffs.add'), {
          office_id: this.target_office_id,
          team_id: this.target_team_id
        })
      }
    },
    // 変更ボタン
    editItem(item) {
      this.updateLoading(true);
      this.$inertia.get(route('staffs.edit', item.id), {
        office_id: this.target_office_id,
        team_id: this.target_team_id
      })
    },
    // 削除ボタン
    deleteItem(item) {
      this.$swal({
        title: "本当に削除しますか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.$inertia.delete(route('staffs.destroy', item.id),
            {
              preserveScroll: true,
              onError: (p) => {
                this.updateLoading(false);
                this.$swal(
                  'error',
                  '削除しようとしている職員は、他の情報と紐づいているため削除ができません。削除方法については<a href="' + this.zoho_link + '" target="_BLANK">職員が削除できない</a>をご確認ください。',
                  'error')
              },
              onSuccess: () => {
                this.updateLoading(false);
                this.$swal(
                  '職員を削除しました',
                  '',
                  'success')
                this.$delete(this.staffs.data, item.id)
              }
            })
        }
      })
    },
    /**
     * 初期値設定用
     */
    // フルネーム取得
    getFullName(value) {
      const staff = this.staffs.data.find(staff => staff.id === value)
      return value ? staff.lastname + staff.firstname : null
    },
    // システム権限名取得
    getRoleName(value) {
      return value ? this.role_list.find(role => role.code === value).ja : null
    },
    // 雇用形態名取得
    getEmploymentTypeName(value) {
      return value
        ? this.employment_type_list.find(employment_type => employment_type.code === value)
          ? this.employment_type_list.find(employment_type => employment_type.code === value).ja
          : null
        : null
    },
    // 事業所名取得
    getOfficeName(value) {
      return value
        ? this.offices.data.find(office => Number(office.id) === Number(value))
          ? this.offices.data.find(office => Number(office.id) === Number(value)).name
          : null
        : null
    },
    /**
     * CSVアップロード機能
     */
    csvimport() {
      this.$swal({
        text: "職員データ（CSV）をインポートします。",
        icon: 'warning',
        showCancelButton: true,
        input: 'file',
        inputAttributes: {
          name: "csvupload",
          id: "csvupload",
          accept: "application/vnd.csv",
          multiple: "multiple"
        },
        confirmButtonText: 'アップロード',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateLoading(true);
          this.csv_form.transform((data) => ({
            ...data,
            csv: result.value[0],
            office_id: this.target_office_id,
            team_id: this.target_team_id
          })).post(this.$url('/staffs/csvupload'), {
            preserveScroll: true,
            onError: (p) => {
              this.updateLoading(false);
              let errorMessages = '';
              let parsed_message = {};
              // JSON文字列でなかった場合を考慮して例外処理
              try {
                parsed_message = JSON.parse(p.csv_upload);
              } catch (e) {
                parsed_message = {};
                if ('csv_upload' in p) {
                  errorMessages = p.csv_upload;
                } else if ('csv' in p) {
                  errorMessages = p.csv;
                }else {
                  errorMessages = '';
                }
              }
              Object.keys(parsed_message).forEach(index => {
                let errorMessage = [];
                const message = parsed_message[index].message;

                try {
                  Object.keys(JSON.parse(message)).forEach(field => {
                    JSON.parse(message)[field].forEach(message => {
                      errorMessage.push(message)
                    })
                  })
                  errorMessages = errorMessages + index + '行目エラー：' + errorMessage.join(' ') + '<BR>'

                } catch (e) {
                  console.debug('【CSV_UPLOAD_ERROR】', message);
                  // エラー内容がDBエラー等の例外の場合
                  errorMessages = errorMessages + index + '行目エラー：データ不正です。<BR>'
                }
              })
              this.$swal({
                title: 'error',
                html: 'CSV登録に失敗しました。<BR>再度やり直してください。'
                  + '<BR><BR>'
                  + '【エラー詳細】'
                  + '<BR>'
                  + '--------------------------------------'
                  + '<div style="text-align:left;padding-right:15%;padding-left:15%;font-size: 0.8em;">'
                  + errorMessages
                  + '</div>',
                icon: 'error'
              })
            },
            onSuccess: () => {
              this.updateLoading(false);
              const results = this.$page.props.flash.results

              let title = '取り込みが完了しました。';
              let icon = 'success';

              if (results.failure > 0) {
                title = '取り込みに失敗しました。';
                icon = 'error';
              }
              
              this.$swal({
                html: `${title}<br />
                    総件数:${results.total}件<br />
                    成功件数:${results.success}件<br />
                    失敗件数:${results.failure}件<br />
                    ${results?.skipped}<br />`,
                icon: icon,
              })
              .then(() => {
                Inertia.visit(this.$route('staffs.list'))
              });

            },
            onFinish: () => {
              this.updateLoading(false);
              this.show = false;
              this.csv_form.csv = null;
            }
          })
        } else {
          this.updateLoading(false);
          this.show = false;
        }
      })
    },
    loding_start() {
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    sortByUpdated(sort_by) {
      this.sort_by = sort_by;
    },
    sortDescUpdated(sort_desc) {
      this.sort_desc = sort_desc
    }
  },
  mounted() {
    this.target_office_id = Number(this.initial_office_id);
    this.target_team_id = Number(this.initial_team_id);
    this.target_work_status = Number(this.initial_work_status);
    this.target_flag = Number(this.initial_flag);

    // ローディング終了
    this.updateLoading(false);
  },
}
</script>

<style>
.name_link {
  color: #1976d2;
  cursor: pointer;
}

.name_link:hover {
  text-decoration: underline;
}

table {
  margin: 0px !important;
}

th,
td {
  background: white !important;
}

.no_display {
  display: none;
}

.autocomplete-style .v-input__control {
  border: 1px solid #ced4da !important;
  border-radius: 8px !important;
}

.v-input__slot {
  background: #ffffff !important;
}
</style>
