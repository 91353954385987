<template>
	<div class="input_row">
	<label for="office_rule">回避したいシフトパターン</label>
	<div style="width: calc(100% - 200px);">
		<div
			v-for="(avoid_pattern, index) in modified_avoid_pattern"
			:key="index"
			style="width: calc(100% - 15px);"
		>
			<div class="input_row">
				<div class="office-shift" style="width: calc(100% - 25px);">
					<div style="display: flex; width: 100%; justify-content: flex-end;">
						<b-button class="del_button" @click="removeAvoidPattern(index)">削除</b-button>
					</div>
					<div style="display: flex; width: 100%;">
						<div style="width: 130px; min-width: 130px;">シフトパターン</div>
						<template v-for="i in avoid_pattern_length">
							<div :style="'width: calc((100% - 290px) / ' + avoid_pattern_length + ');'">
								<b-form-select
									label="選択"
									v-model="avoid_pattern.category_list[i - 1]"
									:options="getWorkCategoryOptions"
									style="width: 100%;"
								>
									<template #first>
										<b-form-select-option :value=undefined>選択</b-form-select-option>
									</template>
									</b-form-select>
							</div>
							<div
								v-if="i < avoid_pattern_length"
								style="display: flex; justify-content: center; align-items: center; width: 40px;"
							>
								→
							</div>
						</template>
					</div>

					<div style="display: flex; width: 100%; margin: 20px 0px;">
						<div style="width: 130px; min-width: 130px;">対象外の職員</div>
						<div style="padding: 0px;">
							<v-combobox
 							  v-if="combo_staffs.length === 0"
								 v-model="selectedExcluedStaffs[index]"
								:items="combo_staffs"
								item-value="office_staff_id"
								item-text="name"
								solo
								hide-selected
								hide-details
								multiple
								label="職員の登録がないため選択できません"
								style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px; background: #FFFFFF;"
								disabled
							>
							</v-combobox>
							<v-combobox
							  v-else
								v-model="selectedExcluedStaffs[index]"
								:items="combo_staffs"
								item-value="office_staff_id"
								item-text="name"
								solo
								hide-selected
								hide-details
								multiple
								label="選択してください（複数選択可）"
								style="border: 1px solid #ced4da!important; width: 400px; min-width: 400px; background: #FFFFFF;"
								@change="handleChangeSelectedExcludedStaff(index, $event)"
							>
								<template v-slot:selection="{ attrs, item, parent, selected }">
									<v-chip
										v-bind="attrs"
										color="#297ADB"
										text-color="#FFFFFF"
										:input-value="selected"
									>
										<span class="pr-2">
											{{ item.name }}
										</span>
										<v-icon
											color="#FFFFFF"
											@click="parent.selectItem(item)"
										>
											$delete
										</v-icon>
									</v-chip>
								</template>
							</v-combobox>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 50%; text-align: left;">
			<button
				v-if="avoidPatternNum < avoid_pattern_max"
				class="btn_add office-shift-btn"
				@click.prevent="addAvoidPattern()"
			>
				＋シフトパターンを追加
			</button>
		</div>
		<p class="explain_label" style="padding: 6px 0px 20px 0px!important;">
			シフトに配置してほしくない（回避したい）パターンを設定します。
		</p>
	</div>
</div>

</template>

<script>
export default {
  components: {},
  props: {
    avoid_pattern :{
      type : Object|Array,
      required :false
    },
		combo_staffs: {
			type: Array,
			required: true
		},
    codes :{
      type : Array|Object,
      required :true
    },
  },
  watch : {
		avoid_pattern: {
			handler (newVal) {
			  this.modified_avoid_pattern = newVal;
		  },
      deep:true
		},
		modified_avoid_pattern: {
			handler (newVal) {
				this.$emit('avoid_pattern', newVal)
			},
			deep: true
		}
  },
  computed : {
    // 勤務区分名取得
    getWorkCategoryOptions () {
      return this.codes.map(code => {
        if(code.type === 'work_category') {
          return {
            value: code.code,
            text: code.ja
          }
        }
      }).filter(Boolean)
    },
    // avoid_patternの数
    avoidPatternNum () {
      return this.modified_avoid_pattern ? this.modified_avoid_pattern.length : 0;
    },
  },
  methods : {
    // 回避ルール追加ボタン
    addAvoidPattern() {
        this.modified_avoid_pattern.push(
        {
            category_list : [],
            excluded_staff_list : []
          }
      )
    },
    // 回避ルール削除ボタン
    removeAvoidPattern(index) {
			this.modified_avoid_pattern.splice(index, 1);
			this.selectedExcluedStaffs.splice(index, 1);

		},
    // コンボボックスのルール除外スタッフ選択時
    handleChangeSelectedExcludedStaff (index) {
      this.modified_avoid_pattern[index].excluded_staff_list = this.selectedExcluedStaffs[index].map(staff => staff.office_staff_id)
    },
		// 選択済みの除外スタッフリストの初期値
		defaultSelectedExcludedStaffs (avoid_pattern_data) {
			return avoid_pattern_data.map(pattern =>
				pattern.excluded_staff_list.map(office_staff_id => {
					return this.combo_staffs.find(combo_staff => combo_staff.office_staff_id === office_staff_id)
				}).filter(Boolean)  // 退職済みや休職中になったスタッフの除外
			)
		},
	},
  data () {
    return {
      avoid_pattern_max: 5,
      avoid_pattern_length:6,
      // 選択済みの除外スタッフリスト
      selectedExcluedStaffs : this.defaultSelectedExcludedStaffs(this.avoid_pattern),
			// 回避したいシフトパターン
			modified_avoid_pattern: this.avoid_pattern,
    }
  },
}
</script>

<style>
.v-text-field.v-text-field--solo .v-label {
    padding: 0px !important;
    overflow: initial !important;
}
</style>
