<template>
  <div class="m-3 p-3" style="width: 600px;">
    <h4>解約ステップ</h4>
    <Form class="pt-5">
      <div>
        <b-row>
          <b-col class="pr-0 py-1" cols="4">
            <p class="font-weight-bold">ステップ1</p>
            <p class="font-weight-bold">ご解約理由について</p>
          </b-col>
          <b-col class="pr-0 py-1" cols="4">
            <p class="text-center">＞＞＞</p>
          </b-col>
          <b-col class="pr-0 py-1" cols="4">
            <p>ステップ2</p>
            <p>ご解約情報の確認</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pr-0 py-1" cols="12">
            アンケートは<a v-bind:href="base.survey" target="_blank">こちら</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pr-0 py-1 text-right" cols="12">
            <button type="button" class="btn btn-primary btn-lg" style="width: 200px;" @click="plancancel(form.id)">次へ</button>
          </b-col>
        </b-row>
      </div>
    </Form>
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import datepicker from "../Components/MyDatePicker";

export default {
  components: {
    datepicker
  },
  props: {
    base:Object,
  },
  data() {
    return {
      form: Inertia.form(this.base),
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.updateLoading(false);
  },
  updated() {
  },
  methods: {
    plancancel (id) {
      this.updateLoading(true);
      this.$inertia.get(route('lodgments.plan-cancel-2', id))
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
};
</script>