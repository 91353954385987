<template>
    <b-button class="btn_white btn_autocreatefooter">
        保存して閉じる
    </b-button>
</template>
<script>
export default {
    name: 'BtnSaveClose',
};
</script>
