<template>
  <div class="app-container app-theme-white">
    <div v-if="message != ''" class="alert">
      {{ message }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Layout2',
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    message() {
      if (this.$page.props.flash && this.$page.props.flash.message) {
        return this.$page.props.flash.message;
      } else {
        return '';
      }
    }
  },
};
</script>

<style lang="scss">
// @import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "@/assets/base.scss";
</style>
