<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="fit-content">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: space-between; background: #EFFAF4;">
          <div></div>
          <div>加算チェック</div>
          <div><button class="btn_close" @click="dialog = false">✕</button></div>
        </v-card-title>
        <v-card-text style="padding: 30px 70px;">
          <p class="title_green_line">サービス提供体制強化加算(Ⅰ)：前年度{{ addOnCheckData[0].judgment.last }}、今年度(予定) {{ addOnCheckData[0].judgment.current_plan }}、今年度 {{ addOnCheckData[0].judgment.current }}</p>
          <table class="text-center table-bordered">
            <tr>
              <th rowspan="2" style="width: 300px;">算定要件</th>
              <th rowspan="2" style="width: 45px;">前年度</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(予定)</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(実績)</th>
            </tr>
            <tr>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
            </tr>
            <tr v-for="data in addOnCheckData[0].table">
              <td class="text-left">{{ data.sentence }}</td>
              <td>{{ data.last.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current_plan[month] }}</td>
              <td>{{ data.current_plan.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current[month] }}</td>
              <td>{{ data.current.avg }}</td>
            </tr>
          </table>
          <p class="title_green_line">サービス提供体制強化加算(Ⅱ)：前年度{{ addOnCheckData[1].judgment.last }}、今年度(予定) {{ addOnCheckData[1].judgment.current_plan }}、今年度 {{ addOnCheckData[1].judgment.current }}</p>
          <table class="text-center table-bordered">
            <tr>
              <th rowspan="2" style="width: 300px;">算定要件</th>
              <th rowspan="2" style="width: 45px;">前年度</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(予定)</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(実績)</th>
            </tr>
            <tr>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
            </tr>
            <tr v-for="data in addOnCheckData[1].table">
              <td class="text-left">{{ data.sentence }}</td>
              <td>{{ data.last.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current_plan[month] }}</td>
              <td>{{ data.current_plan.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current[month] }}</td>
              <td>{{ data.current.avg }}</td>
            </tr>
          </table>
          <p class="title_green_line">サービス提供体制強化加算(Ⅲ)：前年度{{ addOnCheckData[2].judgment.last }}、今年度(予定) {{ addOnCheckData[2].judgment.current_plan }}、今年度 {{ addOnCheckData[2].judgment.current }}</p>
          <table class="text-center table-bordered">
            <tr>
              <th rowspan="2" style="width: 300px;">算定要件</th>
              <th rowspan="2" style="width: 45px;">前年度</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(予定)</th>
              <th :colspan="addOnCheckData['current_months'].length + 1" style="width: 40px;">今年度(実績)</th>
            </tr>
            <tr>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
              <th v-for="month in addOnCheckData['current_months']" style="width: 40px;">{{ month }}</th>
              <th style="width: 45px;">平均</th>
            </tr>
            <tr v-for="data in addOnCheckData[2].table">
              <td class="text-left">{{ data.sentence }}</td>
              <td>{{ data.last.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current_plan[month] }}</td>
              <td>{{ data.current_plan.avg }}</td>
              <td v-for="month in addOnCheckData['current_months']">{{ data.current[month] }}</td>
              <td>{{ data.current.avg }}</td>
            </tr>
          </table>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
            <div style="display: flex; justify-content: center; width: 100%; padding: 0px 70px 70px 70px;">
            <button class="btn_gray_shadow" style="width:150px; padding: 5px 0;" @click="dialog = false">閉じる</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
a {
  color: #1059CC!important;
  text-decoration: underline!important;
}
a:hover {
  cursor: pointer;
}
.title_green_line {
  display: flex;
  font-size: 15px;
}
.hope-icon {
  display: flex;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 0;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 2px;
}
.hope-icon-text {
  font-size: 14px!important;
  min-width: 16px!important;
  max-width: 16px!important;
  min-height: 16px!important;
  max-height: 16px!important;
  padding-top: 3px;
}
</style>

<script>
export default {
  name: 'AddOnCheckDialog',
  components: {
  },
  props: {
    addOnCheckData: {
        type: Object,
    },
  },
  data () {
    return {
      dialog: false,
      months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  },
  computed: {
  },
  mounted() {
  }
}
</script>
