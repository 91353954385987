<template>
  <div style="font-size: 14px">
    <div>
      <a class="link_text" :href="$route('external-kintais.index')" @click="pageSend"
        >外部連携</a
      >
      <span style="color: #d2d6db; margin: 0px 10px 16px 10px;">></span>
      <span style="color: #6c737f">外部連携の追加</span>
    </div>
    <h4 style="font-weight: 700; margin-top: 24px; margin-bottom: 16px;">外部連携の追加</h4>
    <p style="color: #384250; padding-top: 16px;">
      外部連携先となるサービス名を登録してください。<br />
      シフトデータのエクスポート、勤怠実績データのインポートができるようになります。
    </p>
    <div style="margin: 0px; padding: 0px">
      <form class="apiinfos-area" @submit.prevent="saveApiInfos">
        <div class="input_row">
          <label for="external_kintai_name" class="required">外部連携先 サービス名</label>
          <div>
            <v-autocomplete
              id="external_kintai_name"
              v-model="form.external_kintai_name"
              :items="external_kintai_name_list"
              class="apiinfo-autocomplete apiinfos-element"
              :class="{ 'is-invalid': errors.external_kintai_name }"
              item-value="code"
              item-text="ja"
              hide-details="true"
              no-data-text="外部連携先が見つかりません。"
              dense
              filled
              @blur="check_external_kintai_name"
              @change="init_form"
            ></v-autocomplete>
            <div v-if="errors.external_kintai_name" class="invalid-feedback">{{ errors.external_kintai_name }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="name" class="required">外部連携先名</label>
          <div>
            <input
              id="name"
              v-model="form.name"
              class="form-control apiinfos-element"
              :class="{ 'is-invalid': errors.name }"
              placeholder="外部連携先名を入力してください。"
              @blur="check_name"
              type="text"
            />
            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
          </div>
        </div>
        <div class="input_row">
          <label for="next_day_flg">0時を跨ぐ勤務区分あり</label>
          <div>
            <b-form-checkbox
              id="checkbox_next_day_flg"
              v-model="form.next_day_flg"
              class="checkbox"
            />
            <div v-if="errors.next_day_flg" class="invalid-feedback">{{ errors.next_day_flg }}</div>
          </div>
        </div>
        <div v-if="form.external_kintai_name == '1'" class="input_row">
          <label for="company_code" class="required">企業名</label>
          <div>
            <input
              id="company_code"
              v-model="form.company_code"
              type="text"
              class="form-control apiinfos-element"
              :class="{ 'is-invalid': errors.company_code }"
              placeholder="企業名を入力してください。"
              @blur="check_company_code"
            />
            <div v-if="errors.company_code" class="invalid-feedback">{{ errors.company_code }}</div>
          </div>
        </div>
        <div v-if="form.external_kintai_name == '1' || form.external_kintai_name == '3'" class="input_row">
          <label for="api_secret_key" class="required">{{ getKeyTitle }}</label>
          <div>
            <input
              id="api_secret_key"
              v-model="form.api_secret_key"
              type="text"
              class="form-control apiinfos-element"
              :class="{ 'is-invalid': errors.api_secret_key }"
              :placeholder="getKeyTitle + 'を入力してください。'"
              @blur="check_api_secret_key"
            />
            <div v-if="errors.api_secret_key" class="invalid-feedback">{{ errors.api_secret_key }}</div>
          </div>
        </div>
        <div v-if="form.external_kintai_name == '1'" class="input_row">
          <label for="invalid_at" class="required">APIキーの有効期限</label>
          <div>
            <datepicker
              :class="{
                'is-invalid': errors.invalid_at,
                'error--text': errors.invalid_at,
              }"
              :default="form.invalid_at"
              :clearable="datepicker_clearable"
              :validation_check="false"
              class="form-control apiinfos-element"
              @date="setInvalidAt"
              @focusout="check_invalid_at"
            />
            <div v-if="errors.invalid_at" class="invalid-feedback">{{ errors.invalid_at }}</div>
          </div>
        </div>
        <div v-if="form.external_kintai_name == '1'" class="input_row">
          <div style="width: 250px;">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span for="paid_holiday_id" class="required">休暇設定ID</span>
                <span
                  class="pe-7s-help1 pe-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                ></span>
              </template>
              <span style="font-size: 0.8rem">
                HRMOS勤怠の休暇設定とシンクロシフトの休暇設定を紐づけます。<br />
                HRMOS勤怠で利用している休暇設定IDのうち、シンクロシフトで利用するもののIDを設定します。
              </span>
            </v-tooltip>
          </div>
          <div>
            <input
              id="paid_holiday_id"
              v-model="form.paid_holiday_id"
              type="text"
              class="form-control apiinfos-element"
              :class="{ 'is-invalid': errors.paid_holiday_id }"
              placeholder="休暇設定IDを入力してください。"
              @blur="check_paid_holiday_id"
            />
            <div v-if="errors.paid_holiday_id" class="invalid-feedback">{{ errors.paid_holiday_id }}</div>
          </div>
        </div>
      </form>
      <b-button class="btn_save" :disabled="!checkValidation" variant="primary" @click="saveApiInfos">保存</b-button>
    </div>
  </div>
</template>

<style scope>
.v-menu {
  display: none;
}
.invalid-feedback {
  display: block;
}
</style>

<script>
import datepicker from "../Components/MyDatePicker.vue";

export default {
  components: {
    datepicker,
  },

  props: {
    external_kintai_name_list: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      datepicker_clearable: false,
      form: {
        external_kintai_name: null,
        company_code: null,
        api_secret_key: null,
        invalid_at: null,
        paid_holiday_id: null,
        next_day_flg: 0,
        name: null,
      },
      errors: {},
      check_external_validationcheck: false,
      company_code_validationcheck: false,
      name_validationcheck: false,
      api_secret_key_validationcheck: false,
      invalid_at_validationcheck: false,
      paid_holiday_id_validationcheck: false,
    };
  },
  mounted() {
    this.updateLoading(false);
  },
  computed: {
    checkValidation() {
      let result = false;

      if (this.form.external_kintai_name == "1") {
        // ハーモス勤怠の場合は全て必須
        result =  this.check_external_validationcheck &&
                  this.company_code_validationcheck &&
                  this.api_secret_key_validationcheck &&
                  this.invalid_at_validationcheck &&
                  this.name_validationcheck &&
                  this.paid_holiday_id_validationcheck;
      } else if (this.form.external_kintai_name == "3") {
        // KING OF TIMEの場合はサービス名とアクセストークンのみ必須
        result =  this.check_external_validationcheck &&
                  this.name_validationcheck &&
                  this.api_secret_key_validationcheck;
      } else {
        // その他はサービス名のみ必須
        result =  this.check_external_validationcheck &&
                  this.name_validationcheck;
      }
      return result;
    },
    getKeyTitle() {
      if (this.form.external_kintai_name == "1") {
        return "Secret key";
      } else {
        return "アクセストークン";
      }
    },
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit("updateLoading", flg);
    },
    pageSend() {
      // Ctrl+クリックでメニューを開いたときはローディングはしない
      if (event && !event.ctrlKey) {
        this.updateLoading(true);
      }
    },
    check_external_kintai_name() {
      if (this.form.external_kintai_name !== null && this.form.external_kintai_name !== "") {
        this.check_external_validationcheck = true;
        this.$delete(this.errors, "external_kintai_name");
      } else {
        this.check_external_validationcheck = false;
        this.$set(
          this.errors,
          "external_kintai_name",
          "外部連携先 サービス名は必ず指定してください。"
        );
      }
    },
    check_company_code() {
      if (this.form.company_code !== null && this.form.company_code !== "") {
        this.company_code_validationcheck = true;
        this.$delete(this.errors, "company_code");
      } else {
        this.company_code_validationcheck = false;
        this.$set(
          this.errors,
          "company_code",
          "企業名は必ず指定してください。"
        );
      }
    },
    check_name() {
      if (this.form.name !== null && this.form.name !== "") {
        this.name_validationcheck = true;
        this.$delete(this.errors, "name");
      } else {
        this.name_validationcheck = false;
        this.$set(
          this.errors,
          "name",
          "外部連携先名は必ず指定してください。"
        );
      }
    },
    check_api_secret_key() {
      let str_api_secret_key = this.form.api_secret_key;
      this.api_secret_key_validationcheck = true;
      this.$delete(this.errors, "api_secret_key");
      // ラベル取得
      let lblString = "";
      if (this.form.external_kintai_name == "1") {
        lblString = "Secret key";
      } else {
        lblString = "アクセストークン";
      }
      // 必須チェック
      if (str_api_secret_key == null || str_api_secret_key == "") {
        this.api_secret_key_validationcheck = false;
        this.$set(
          this.errors,
          "api_secret_key",
          lblString + "は必ず指定してください。"
        );
      } else {
        // 英数字チェック
        if (!str_api_secret_key.match(/^[A-Za-z0-9]*$/)) {
          this.api_secret_key_validationcheck = false;
          this.$set(
            this.errors,
            "api_secret_key",
            lblString + "は半角英数字で入力してください。"
          );
        }
      }
    },
    check_invalid_at(val) {
      this.invalid_at_validationcheck = true;
      this.$delete(this.errors, "invalid_at");
      if (
        val == "" ||
        val == null ||
        val == "Invalid Date" ||
        val == "Invalid date"
      ) {
        this.invalid_at_validationcheck = false;
        this.$set(
          this.errors,
          "invalid_at",
          "APIキーの有効期限を入力してください。"
        );
      } else {
        if (!val.match(/\d{4}\/\d{2}\/\d{2}/)) {
          this.invalid_at_validationcheck = false;
          this.$set(
            this.errors,
            "invalid_at",
            "APIキーの有効期限の形式が正しくありません。"
          );
        }
      }
    },
    check_paid_holiday_id() {
      if (
        this.form.paid_holiday_id !== null &&
        this.form.paid_holiday_id !== ""
      ) {
        this.paid_holiday_id_validationcheck = true;
        this.$delete(this.errors, "paid_holiday_id");
      } else {
        this.paid_holiday_id_validationcheck = false;
        this.$set(
          this.errors,
          "paid_holiday_id",
          "休暇設定IDは必ず指定してください。"
        );
      }
    },
    setInvalidAt: function (date) {
      this.form.invalid_at = date;
      this.invalid_at_validationcheck = true;
      this.$delete(this.errors, "invalid_at");
    },
    saveApiInfos: function () {
      this.$swal
        .fire({
          icon: "warning",
          title: "保存しますか？",
          showCancelButton: true,
          confirmButtonText: "保存",
          cancelButtonText: "キャンセル",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.errors = {};
            this.updateLoading(true);
            const axios = require("axios");
            axios
              .post(this.$route("external-kintais.store"), this.form)
              .then((res) => {
                this.updateLoading(false);
                if (res.status === 200) {
                  if (res.data.status !== 200) {
                    this.errors = res.data.errors;
                  } else {
                    this.$swal.fire({
                      icon: "success",
                      title: "保存しました",
                    })
                    .then(() => {
                      this.$inertia.get(route('external-kintais.index'));
                    });
                  }
                } else {
                  throw res.status;
                }
              })
              .catch((err) => {
                console.log(err);
                this.updateLoading(false);
                this.$swal.fire({
                  icon: "error",
                  title: "設定に失敗しました。しばらくたってから再試行してください。",
                });
                //location.reload();
              });
          }
        });
    },
    init_form() {
      this.form.company_code = null;
      this.form.api_secret_key = null;
      this.form.invalid_at = null;
      this.form.paid_holiday_id = null;
      this.form.next_day_flg = 0;
      this.form.name = null;
    }
  },
};
</script>
