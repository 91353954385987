<template>
  <div class="office_area">
    <h5>加算チェック関連項目</h5>
    <div class="input_row">
      <label for="is_shift_system" class="required">労働時間制</label>
      <div>
        <div class="input_row">
          <b-form-select
            v-model="form.is_shift_system" 
            :options="shift_system_options"
            style="min-width: 200px; 
            max-width: 200px;"
          />
        </div>
        <div v-if="errors.is_shift_system" class="invalid-feedback">{{ errors.is_shift_system }}</div>
      </div>
    </div>
    <div class="input_row">
      <label for="workingdays_per_week" >1週当たり勤務日数</label>
      <div>
        <div class="input_row">
          <input
              v-model.number="form.workingdays_per_week"
              type="number"
              class="form-control"
              style="width: 65px; margin-right: 8px;"
              min="0"
              max="7"
            />
          <p class="after_label">日</p>
        </div>
        <div v-if="errors.workingdays_per_week" class="invalid-feedback">{{ errors.workingdays_per_week }}</div>
      </div>
    </div>
    <div class="input_row">
      <label for="workinghours_per_week">1週当たり勤務時間</label>
      <div>
        <div class="input_row">
          <input
              v-model.number="form.workinghours_per_week"
              type="number"
              class="form-control"
              style="width: 65px; margin-right: 8px;"
              min="0"
              max="168"
            />
          <p class="after_label">時間</p>
        </div>
        <div v-if="errors.workinghours_per_week" class="invalid-feedback">{{ errors.workinghours_per_week }}</div>
      </div>
    </div>
    <div class="input_row">
      <label for="workinghours_per_day" class="required">1日当たり勤務時間</label>
      <div>
        <div class="input_row">
          <input
              v-model.number="form.workinghours_per_day"
              type="number"
              class="form-control"
              style="width: 65px; margin-right: 8px;"
              min="0"
              max="24"
            />
          <p class="after_label">時間</p>
        </div>
        <div v-if="errors.workinghours_per_day" class="invalid-feedback">{{ errors.workinghours_per_day }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      shift_system_options: [
        { value: false, text: '変形労働時間制' },
        { value: true, text: 'シフト制' }
      ],
    }
  },
}
</script>
