<template>
<form class="pt-5" @submit.prevent="save">
  <b-overlay :show="show" rounded="sm">
    <div class="m-3 p-3" style="width:600px;">
      <b-row class="pb-5">
        <b-col cols="12">
          <h4>組合わせ設定</h4>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col cols="12" md="10">
          <div style="display: flex;">
            <div style="width: 50%">
              <b-form-select
                v-model="form.staff_1"
                :class="{'is-invalid': errors.staff_1}"
                placeholder="職員を選択してください。"
              >
                <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
                  {{ staff.lastname }} {{ staff.firstname }}
                </option>
              </b-form-select>
              <div v-if="errors.staff_1" class="invalid-feedback">{{ errors.staff_1 }}</div>
            </div>
            <div class="text-center" style="width: 10%">✕</div>
            <div style="width: 50%;">
              <b-form-select
                v-model="form.staff_2"
                :class="{'is-invalid': errors.staff_2}"
                placeholder="職員を選択してください。"
              >
                <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
                  {{ staff.lastname }} {{ staff.firstname }}
                </option>
              </b-form-select>
              <div v-if="errors.staff_2" class="invalid-feedback">{{ errors.staff_2 }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col cols="12" md="10">
          <div style="display: flex;">
            <div class="pr-2">組合わせ</div>
            <b-form-radio-group v-model="form.is_valid" :class="{'is-invalid': errors.is_valid}">
              <b-form-radio :value="1" style="margin-left:10px;" :class="{'is-invalid': errors.is_valid}">OK</b-form-radio>
              <b-form-radio :value="0" :class="{'is-invalid': errors.is_valid}">NG</b-form-radio>
              <div v-if="errors.is_valid" class="text-danger"><small>{{ errors.is_valid }}</small></div>
            </b-form-radio-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col cols="12">
          対象勤務
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col cols="12" md="10">
          <v-combobox
            v-model="form.workpattern_list"
            :items="workpatterns"
            item-value="id"
            item-text="name"
            style="border: 1px solid #ced4da!important;"
            background-color="#ffffff"
            dense
            outlined
            hide-selected
            hide-details
            multiple
            small-chips
            :class="{'is-invalid': errors.workpattern_list}"
            placeholder="勤怠区分を選択してください。"
          ></v-combobox>
          <div v-if="errors.workpattern_list" class="text-danger"><small>{{ errors.workpattern_list }}</small></div>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col cols="12" md="4" offset-md="6">
          <b-button block variant="primary" size="lg" type="submit">編集</b-button>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</form>
</template>

<script>
import Vue from 'vue';
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);
import { Inertia } from "@inertiajs/inertia";

export default {
  components: {
  },
  props: {
        errors: {
            type: Object,
            required: false,
        },
        workpatterns: {
            type: Array,
            required: true
        },
        staffs: {
            type: Array,
            required: true
        },
        work_list: {
            type: Array,
            required: true
        },
        combinations: {
            type: Object,
            required: true
        },
  },
  computed: {
  },
  data() {
    return {
      form: Inertia.form({
          id:this.combinations.id,
          staff_1: this.combinations.office_staff_a_id,
          staff_2: this.combinations.office_staff_b_id,
          workpattern_list: this.work_list,
          is_valid: this.combinations.is_valid,
      }),
    }
  },
  methods: {
    save() {
      this.form.post(route('shifts.combinations.save'), {
        onSuccess:()=>{
          this.$swal({
            title: "保存しました",
            icon: 'success',
          });
        }
      })
    },
  },
  mounted() {
  },
}
</script>
