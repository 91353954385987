<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import wf from '@/Pages/Shifts/functions/workpatterns';
// category選択領域
import CategoryView from '@/Pages/Shifts/Components/CategoryView';
// 半稼働/半休選択モーダル
import HalfWorkpatternChangeDialog from '@/Pages/Shifts/Components/Dialog/ShiftSelectDialog/HalfWorkpatternChangeDialog';

export default {
  name: 'ShiftChangeDialog',
  components: {
    VuePerfectScrollbar,
    CategoryView,
    HalfWorkpatternChangeDialog
  },
  props: {
    // application か schedule か
    modal_type: {
      type: String,
      default: 'application'
    },
    // work categoryのリスト
    work_categories: {
      type: Object
    },
    // クリックされた日時
    full_day: {
      type: String,
      defult: null,
      required: false
    },
    // クリックされた曜日
    week: {
      type: String,
      defult: null,
      required: false
    },
  },
  data () {
    return {
      dialog: false,
      user: {},
      selected_category: 'all',
      // 主要なシフト
      main_id: null,
      // 半勤務/半休のシフト
      replacement_id: null,
      // 初期シフト
      initial_main_id: null,
      initial_replacement_id: null,
      initial_entry_user_code: null,
      // 勤務区分のリスト
      main_workpatterns: {},
      replacement_workpatterns: {},

      // workpattern functions
      wf: wf,

      // yyyy年mm月dd日（曜日）
      title_date: 'null',

      // 入力ユーザーの役割
      entry_user_code: '',
    }
  },
  methods: {
    // 選択されたcategoryに応じてデータを初期化．
    initialize() {
      this.main_workpatterns = {};
      this.replacement_workpatterns = {};

      if (this.modal_type === 'application') {
        // 勤務（半勤務除く）の勤務区分を設定
        for (let id in this.user.workpatterns.work) {
          const working_workpattern = this.user.workpatterns.work[id];
          if (working_workpattern.type === 'work' && (working_workpattern.category === this.selected_category || this.selected_category === 'all')) {
            this.$set(this.main_workpatterns, id, working_workpattern);
          }
        }
        // 休暇の勤務区分を設定
        for (let id in this.user.workpatterns.holiday) {
          const holiday_workpattern = this.user.workpatterns.holiday[id];
          if (this.selected_category === 'holiday' || this.selected_category === 'all') {
            this.$set(this.main_workpatterns, id, holiday_workpattern);
          }
        }
        // 半勤務の勤務区分を設定
        for (let id in this.user.workpatterns.work) {
          const working_workpattern = this.user.workpatterns.work[id];
          if (working_workpattern.type === 'half_work') {
            this.$set(this.replacement_workpatterns, id, working_workpattern);
          }
        }
      } else {
        // 勤務区分を設定
        for (let id in this.user.workpatterns.work) {
          const working_workpattern = this.user.workpatterns.work[id];
          if (working_workpattern.category === this.selected_category || this.selected_category === 'all') {
            this.$set(this.main_workpatterns, id, working_workpattern);
          }
        }

        // 休暇の勤務区分を設定
        // 半休はreplacement_workpatternsに，全休はmain_workpatternsに
        for (let id in this.user.workpatterns.holiday) {
          let holiday_workpattern = this.user.workpatterns.holiday[id];
          if (holiday_workpattern.type === 'public_half_holiday' || holiday_workpattern.type === 'paid_half_holiday') {
            this.$set(this.replacement_workpatterns, id, holiday_workpattern);
          } else if (holiday_workpattern.category === this.selected_category || this.selected_category === 'all') {
            this.$set(this.main_workpatterns, id, holiday_workpattern);
          }
        }
      }
      // 配列に変換
      this.main_workpatterns = Object.values(this.main_workpatterns);
      this.replacement_workpatterns = Object.values(this.replacement_workpatterns);
    },
    openDialog(data) {
      if (this.modal_type === 'application') {
        console.log('休暇申請変更modal open');
      } else if (this.modal_type === 'schedule') {
        console.log('シフト変更modal open');
      } else {
        console.log('連続配置modal open');
      }

      console.log(data);

      this.selected_category = 'all';

      this.user = data.item;

      this.entry_user_code = '';
      
      this.initialize();

      // 現在の選択
      this.main_id = data.main_id;
      this.replacement_id = data.replacement_id;
      // 初期選択
      this.initial_main_id = data.main_id;
      this.initial_replacement_id = data.replacement_id;

      this.dialog = true;

      // タイトルの年月日
      if (this.modal_type === 'application' || this.modal_type === 'schedule') {
        this.title_date = this.formatYmd(this.full_day) + '(' + this.week + ')';
      }

      if (data.entry_user_code === null || data.entry_user_code === undefined) {
        this.entry_user_code = 'manager'
      } else {
        this.entry_user_code = data.entry_user_code;
      }
      this.initial_entry_user_code = this.entry_user_code;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    // 時間の加工
    setTimeString(from, to) {
      let fromString = ''
      let toString = ''

      if (from !== null && from !== '') {
        fromString = from.slice(0, 5)
      }
      if (to !== null && to !== '') {
        toString = to.slice(0, 5)
      }

      if (fromString !== '' || toString !== '') {
        return fromString + '-' + toString;
      } else {
        return fromString + toString;
      }
    },
    // 勤務カテゴリ変更
    changeCategory(val) {
      console.log('changeCategory');
      this.selected_category = val;
      this.initialize();
    },
    // 勤務区分変更時の処理
    changeWorkpattern(id) {
      this.main_id = id;
      const workpattern = this.main_workpatterns.find((item) => item.id === this.main_id);
      console.log('勤務区分変更:', workpattern);

      // 半勤務(scheduleの場合)/半休(applicationの場合)を選択した場合は
      // モーダルを表示
      const target_list = [
        'half_work',
        // half_holidayは使わなくなったworktypeだが念のため条件に入れておく
        'half_holiday',
        'paid_half_holiday',
        'public_half_holiday'
      ];
      if (target_list.indexOf(workpattern.type) >= 0) {
        let data = {
          workpatterns: this.replacement_workpatterns,
          workpattern_id: this.replacement_id,
        };
        this.$refs.halfWorkpatternChangeDialog.openDialog(data);
        return;
      } else {
        // replacementはnullにする
        this.replacement_id = null;
        this.submit();
      }
    },
    // 再選択
    reselect() {
      this.main_id = this.initial_main_id;
      this.replacement_id = this.initial_replacement_id;
    },
    // 半勤務区分決定
    submitHalfWorkpattern(id) {
      this.replacement_id = id;
      this.submit();
    },
    // 選択解除
    clear() {
      this.main_id = null;
      this.replacement_id = null;
      this.entry_user_code = 'manager';
      this.submit();
    },
    // 値を親componentに送信
    submit() {
      console.log('変更確定 main_id:' + this.main_id + ', replacement_id:' + this.replacement_id + ', entry_user_code:' + this.entry_user_code);
      let is_changed = false;
      if (this.initial_main_id !== this.main_id) {
        is_changed = true;
      } else if (this.initial_replacement_id !== this.replacement_id) {
        is_changed = true;
      } else if (this.initial_entry_user_code !== this.entry_user_code) {
        is_changed = true;
      } else {
        is_changed = false;
      }

      if (this.modal_type === 'application' || this.modal_type === 'schedule') {
        this.$emit('changeShift', is_changed, this.user, this.main_id, this.replacement_id, this.entry_user_code);
      } else {
        this.$emit('selectShift', is_changed, this.main_id, this.replacement_id, this.entry_user_code);
      }

      this.entry_user_code = '';
      this.dialog = false;
    },
    onClickOutside() {
      this.$refs.modalform.focus();
      this.entry_user_code = '';
      this.dialog = false;

      if (this.modal_type === 'consecutive') {
        this.$emit('selectClear');
      }
    },
    onClickCancel() {
      this.entry_user_code = '';
      this.dialog = false;

      if (this.modal_type === 'consecutive') {
        this.$emit('selectClear');
      }
    },
    // 勤務categoryがworkかholidayか
    categoryWork(workpattern) {
      // workpattern.categoryがholidayの場合にはfalseを返す
      if (workpattern.category === 'holiday') {
        return false;
      }
      // workpattern.categoryがholiday以外の場合にはtrueを返す
      return true;
    },
    // yyyy/mm/dd → yyyy年mm月dd日
    formatYmd(day) {
      let formatted_date = '';

      if (day !== null && day !== '') {
        let ymd = day.split('-');
        formatted_date = `${ymd[0]}年${ymd[1]}月${ymd[2]}日`;
      }

      return formatted_date
    },
    // スクロール領域の設定
    getHeight() {
      if (this.modal_type === 'schedule') {
        return 'max-height: 40vh;'
      } else {
        return 'max-height: 30vh;'
      }
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="onClickOutside();" persistent max-width="500">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title style="color:#02B28A; font-weight: 700; justify-content: center; background: #EFFAF4;">
          <div 
            v-if="modal_type === 'application' || modal_type === 'schedule'"
            style="text-align: center;"
          >
            {{ user.lastname }} {{ user.firstname }}さん<br />
            {{ title_date }}の勤務区分を選択
          </div>
          <div
            v-else
            style="text-align: center;"
          >
            勤務区分を選択
          </div>
        </v-card-title>
        <v-card-text style="padding: 10px 10px;">
          <!-- category選択 -->
          <CategoryView
            ref="categoryView"
            :categories="work_categories"
            :selected="selected_category"
            @changeCategory="changeCategory"
          />
          <VuePerfectScrollbar :style="getHeight()" style="margin-top: 15px;">
            <div v-for="(item, index) in main_workpatterns">
              <label class="workpattern_change_button workpattern_change_data" :workpattern_id="item.id" :category="item.category" :type="item.type">
                <input
                  :key="index"
                  type="radio"
                  :value="item.id"
                  :checked="main_id === item.id"
                  @click="changeWorkpattern(item.id)"
                >
                <span style="display: flex; justify-content: space-between; width: 100%">
                  <div
                    :class="'workpattern_change_color ' + wf.getWorkpatternColor(item)"
                    style="width: 30px !important; border: initial !important; background: initial !important;"
                  >●</div>
                  <div style="width: 100%;">{{ item.name }}</div>
                  <div v-if="categoryWork(item)" style="width: 150px;">
                    {{ setTimeString(item.working_start_time, item.working_end_time) }}
                  </div>
                  <div v-else style="width: 150px;">
                    {{ setTimeString(item.rest_start_time, item.rest_end_time) }}
                  </div>
                </span>
              </label>
            </div>
          </VuePerfectScrollbar>
          <div v-if="this.modal_type === 'application'" style="margin-top: 10px;">
            <div class="d-input-row">
              <div style="margin: 8px 0px;">
                <input
                  id="entry_user_code_staff"
                  v-model="entry_user_code"
                  type="radio"
                  name="entry_user_code"
                  value="staff"
                >
              </div>
              <p class="radio_label" style="padding-right: 20px!important;">職員の希望による代理入力（カレンダー上に希望アイコンを表示<span style="color: red; font-weight: 600;">したい</span>場合に選択してください）</p>
            </div>
            <div class="d-input-row">
              <div style="margin: 8px 0px;">
                <input
                  id="entry_user_code_manager"
                  v-model="entry_user_code"
                  type="radio"
                  name="entry_user_code"
                  value="manager"
                >
              </div>
              <p class="radio_label" style="padding-right: 20px!important;">管理者による手入力（カレンダー上に希望アイコンを表示<span style="color: red; font-weight: 600;">したくない</span>場合に選択してください）</p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 0;">
          <v-spacer></v-spacer>
          <div 
            style="display: flex; width: 100%; padding: 0px 10px 10px 10px;"
            :style="'justify-content: ' + (modal_type === 'consecutive' ? 'flex-end' : 'space-between') + ';'"
          >
            <button
              v-if="modal_type === 'schedule'"
              class="btn_gray_shadow"
              style="display: none; width:150px; padding: 5px 0;" @click=""
            >下書き保存する</button>
            <button
              v-if="this.modal_type === 'application' || this.modal_type === 'schedule'"
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="clear"
            >選択解除</button>
            <button
              class="btn_green_shadow"
              style="width:150px; padding: 5px 0;"
              @click="onClickCancel"
            >キャンセル</button>
          </div>
        </v-card-actions>
      </v-card>
    </div>
    <HalfWorkpatternChangeDialog
      ref="halfWorkpatternChangeDialog"
      :modal_type="modal_type"
      :staff_name="user.lastname + ' ' + user.firstname"
      :full_day="title_date"
      @reselect="reselect"
      @submit="submitHalfWorkpattern"
    />
  </v-dialog>
</template>

<style deep>
.d-input-row {
  display: flex;
}
.working_select .v-select__selection--comma {
  margin: auto;
}
.v-card__title {
  position: sticky;
  top: 0;
}
.v-card__actions {
  position: sticky;
  background: #FFFFFF;
  bottom: 0;
}
.d-input-row input[type="radio"] {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.radio_label {
  padding: 5px 0px 2px 10px!important;
  margin: 0px!important;
}
</style>
