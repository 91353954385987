<template>
  <div class="h-100">
    <b-row class="h-100 no-gutters">
      <MySlick />
      <b-col lg="8" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
        <b-col lg="6" md="8" sm="12" class="mx-auto app-login-box">
          <div class="app-logo" />
          <h4>
            <span>パスワード再設定メールを送信しました。</span>
          </h4>
          <p>お送りしたメールの本文に、パスワード再設定を行うためのサービスURLが記載されています。このURLにアクセスしてパスワードを変更してください。</p>
          <p class="small">※URLの有効期限は1時間です。1時間以内にアクセスしていただけなかった場合は、再度お手続きをお願いいたします。</p>
          <div>
            <h6 class="mb-0">
              <Link :href="$url('/login')" class="text-primary">ログイン画面に戻る</Link>
            </h6>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Layout2 from '@/Pages/Layouts/Layout2'
import { Link } from '@inertiajs/inertia-vue'
import MySlick from "@/Pages/Components/MySlick"

export default {
  name: 'ForgotPasswordComplete',
  layout: Layout2,
  components: {
    MySlick
  },
  data: () => ({
  }),

  methods: {
  }
};
</script>

