<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
    transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="text" background-color="white" hide-details="false" v-on="on" clearable dense outlined>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="picker"
      locale="jp-ja"
      :day-format="date => new Date(date).getDate()"
      @input="formatDate(picker)">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: "",
      text: new Date().toISOString().substr(5, 2) + "/" + new Date().toISOString().substr(8, 2),
      picker: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.text = `${month}/${day}`;
      this.menu = false;
      return;
    },
  },
};
</script>
