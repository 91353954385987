<template>
  <td class="fixed fixed02 table-fixed-row show-flg">
    <BtnSelectMainFlg
      :staff="staff"
      :year="year"
      :month="month"
      :view_type="view_type === 'schedule' ? 'tab-2' : 'tab-1'"
    />
    <label class="rookie_button">
      <input
        name="rookie_button"
        type="checkbox"
        :checked="rookieChecked"
        @click.prevent="handleRookieClick"
      >
      <span>新</span>
    </label>
  </td>
</template>

<script>
import BtnSelectMainFlg from '@/Pages/Shifts/Components/Button/BtnSelectMainFlg.vue';
import axios from 'axios';

export default {
  name: 'ShiftsFlgColumn',
  components: {
    BtnSelectMainFlg,
  },
  props: {
    view_type: { type: String },
    year: { type: String },
    month: { type: String },
    staff: { type: Object, default: function() { return {}; }},
    shift_monthly_display_rule: { type: Object, default: function() { return {}; }},
  },
  data () {
    return {
      unshow_button: false,
    }
  },
  computed: {
    rookieChecked() {
      const displayStartDate = new Date(this.year + '-' + this.month + '-01');
      const firstEndDate = this.staff.first_rookie_end_date ? new Date(this.staff.first_rookie_end_date) : null;
      const secondEndDate = this.staff.second_rookie_end_date ? new Date(this.staff.second_rookie_end_date) : null;

      return this.staff.rookie && (
        (firstEndDate && firstEndDate > displayStartDate) ||
        (secondEndDate && secondEndDate > displayStartDate)
      );
    },
  },
  methods: {
    // 非表示ボタンの変更
    changeShow() {
      const data = {
        office_staff_id: this.staff.office_staff_id,
        target_month: this.year + this.month,
        occupation_id: this.staff.occupation_id,
        team_id: this.staff.team_id,
        display_flg: !this.unshow_button,
      };
      // axiosにて非表示フラグ変更(shift_monthly_display_rulesテーブルの更新)
      axios.patch(this.$route('shifts.staff.display'), data)
        .then((response) => {
          // 失敗時
          if(response.data.status === 'failure') {
            this.$swal({
              icon: 'error',
              html: response.data.message,
            });
            // 非表示ボタン戻す
            this.unshow_button = !this.unshow_button;
            return
          }

          // 成功時のみShiftMonthlyDisplayRule情報だけ更新して再描画
          this.$emit('updateShiftMonthlyDisplayRule', data)
        })
        .catch((error) => {
          this.$swal({
              icon: 'error',
              html: error,
          });
          // 非表示ボタン戻す
          this.unshow_button = !this.unshow_button;
        });
    },
    // 新人フラグのクリック
    handleRookieClick() {
      const data = {
        staff: this.staff.staff_id,
        office_id: this.staff.office_id,
        team_id: this.staff.team_id || 0,
        from_rookie: true,
      };
      const url = `${this.$route('staffs.edit', data)}#flg_section`;

      EventBus.$emit('updateLoading', true);
      this.$inertia.get(url);
    },
  },
  watch: {
    // 非表示ボタンの変更
    shift_monthly_display_rule: function(val) {
      this.unshow_button = Object.keys(val).length
      ? !val.display_flg
      : false;
    },
  },
  mounted() {
    // 非表示ボタンの初期値
    // - 表示フラグがtrue or レコードがない時はfalse=ボタンOFF
    // - 表示フラグがfalseの時はtrue=ボタンON
    this.unshow_button = Object.keys(this.shift_monthly_display_rule).length
      ? !this.shift_monthly_display_rule.display_flg
      : false;
  },

}
</script>
