<template>
  <div>
    <div class="staffs_area">
      <h5>所属事業所</h5>
      <!-- 事業所、役職、職種コンポーネント -->
      <div v-for="(office_staff, i) in office_staffs" :key="'tab-' + i" class="qualification_area">
        <div v-if="office_staffs.length > 1" style="display: flex; justify-content: flex-end;">
          <button type="button" class="btn_add" @click="closeTab(i)" style="width:auto!important; margin-right: 16px;"><i class="pe-7s-trash"></i>事業所の削除</button>
        </div>
        <div class="input_row">
          <label for="tab2-office">事業所</label>
          <div>
            <v-autocomplete
              v-model="office_staff.office_id"
              :items="office_list"
              @change="handleSelectChangedOffice(i, office_staff.office_id)"
              class="autocomplete-style staff-area-control"
              item-text="text"
              item-value="value"
              hide-details="true"
              placeholder="選択してください"
              no-data-text="事業所が見つかりません。"
              dense
              filled
            ></v-autocomplete>
            <p
              v-if="!duplicateOfficeCheck
                && duplicateOfficeIds.indexOf(office_staff.office_id) !== -1
                && office_staff.office_id !== ''"
              class="invalid-feedback"
            >
              同じ事業所が選択されています。
            </p>
            <div v-if="errors['office_staffs']" class="invalid-feedback" style="margin-left: 12px;">{{ errors['office_staffs'] }}</div>
            <div
              v-if="form_errors['office_staffs'] && form_errors['office_staffs'][i] && form_errors['office_staffs'][i]['office_id']"
              class="invalid-feedback"
              style="margin-left: 12px;"
              >
              {{ form_errors['office_staffs'][i]['office_id'] }}
            </div>
            <!-- error message -->
            <div v-if="!office_staff.office_id" class="invalid-feedback">事業所の選択は必ず指定してください。</div>
          </div>
        </div>
        <div class="input_row">
          <label for="external_point_name">外部連携先名</label>
          <div>
            <v-autocomplete
              @change="duplicateCheckExternalNameAndId()"
              v-model="office_staff['office_staff_kintai'].external_kintai_id"
              :items="external_kintai_name_list(office_staff.office_id)"
              class="autocomplete-style staff-area-control"
              item-value="id"
              item-text="name"
              hide-details="true"
              no-data-text="外部連携先が見つかりません。"
              dense
              filled
              ></v-autocomplete>
              <p
                v-if="!externalNameAndIdCheckValidation && duplicatEexternalNameAndIds.includes(i)"
                class="invalid-feedback"
              >
                外部連携先名と外部連携先IDが重複しています
              </p>
          </div>
        </div>

        <div class="input_row">
          <label for="external_point_name">外部連携先ID</label>
          <div>
            <input
              @change="duplicateCheckExternalNameAndId()"
              v-model="office_staff['office_staff_kintai'].external_link_id"
              class="form-control"
              style="width:270px;"
              ></input>
          </div>
        </div>

        <div class="input_row"> 
          <label for="tab2-team">チーム名</label>
          <div>
            <v-combobox
              v-model="selectedOfficeStaffTeams[i]"
              :items="team_list[i]"
              :search-input.sync="search"
              item-text="text"
              item-value="value"
              style="background: #FFFFFF; border: 1px solid #ced4da;"
              dense
              outlined
              hide-selected
              hide-details
              multiple
              small-chips
              placeholder=""
              id="tab2-team"
              class="staff-area-control"
              @change="handleChangeSelectedOfficeStaffTeam(i, selectedOfficeStaffTeams[i])"
            ></v-combobox>
          </div>
        </div>
        <div class="input_row" style="display: none;">
          <label for="tab2-member">チームメンバー</label>
          <input
            v-model="team_staff_name_list[i]"
            type="text"
            class="form-control officeControl"
            id="tab2-member"
            disabled
          />
        </div>
        <div class="input_row">
          <label for="tab2-positions">役職</label>
          <div>
            <v-autocomplete
              v-model="office_staff.position_id"
              :items="position_list"
              @change="handleSelectChangedPosition(i, office_staff.position_id)"
              class="autocomplete-style staff-area-control"
              item-text="text"
              item-value="value"
              hide-details="true"
              placeholder="選択してください"
              no-data-text="役職が見つかりません。"
              dense
              filled
            ></v-autocomplete>
          </div>
        </div>
        <div class="input_row">
          <!-- 職種エリアコンポーネント -->
          <Occupation
            @occupations="setOccupations"
            :tabNo="i"
            :occupations="occupations"
            :office_staff="office_staff"
            :form_errors="form_errors"
            :errors="errors"
          ></Occupation>
        </div>
      </div>
      <button class="btn_add" @click="newTab()" style="width: auto!important; margin:0px!important"><span>＋</span>事業所を追加</button>
    </div>
    <!-- 業務割合コンポーネント -->
    <OccupationRatio
      @staff_occupations_ratio="setOccupationRatio"
      :offices="offices"
      :occupations="occupations"
      :office_staffs="office_staffs"
      :errors="errors"
      :form_errors="form_errors"
      style="width: 100%"
    />
    <!-- シフト設定コンポーネント -->
    <ShiftSetting
      @shift_setting="setShiftSetting"
      :offices="offices"
      :workpatterns="workpatterns"
      :office_staffs="office_staffs"
      :codes="codes"
      :defaultMonthlyWorkingCounts="defaultMonthlyWorkingCounts"
      :staffEmploymentType="staffEmploymentType"
      :errors="errors"
      :form_errors="form_errors"
      style="width: 100%"
    />
  </div>
</template>

<script>
import Occupation from "./Occupation"
import OccupationRatio from "./OccupationRatio";
import ShiftSetting from "./ShiftSetting.vue";


export default {
  name: "OfficeStaff",
  components:{
    Occupation,
    ShiftSetting,
    OccupationRatio
  },
  props: {
    // 職員
    staff: {
      type: Object,
      required:false
    },
    // 職種
    occupations :{
      type : Object,
      required :true
    },
    // 役職
    positions :{
      type : Object,
      required :true
    },
    // コード
    codes :{
      type : Object,
      required :true
    },
    // 事業所
    offices : {
      type : Object,
      required :true
    },
    // チーム
    teams : {
      type : Array|Object,
      required :true
    },
    // 職員-チーム
    team_staffs : {
      type : Array|Object,
      required :true
    },
    // 勤務区分
    workpatterns : {
      type : Object,
      required :true
    },
    // 正規 / 非正規職員ごとの勤務カテゴリの勤務回数デフォルト値
    defaultMonthlyWorkingCounts : {
      type: Object,
      required: true
    },
    staffEmploymentType: {
      type: String|null,
      required: false
    },
    errors: {
      type: Object,
      required: false,
    },
    form_errors: {
      type: Object,
      required: false,
    },
    office_kintai: {
      type: Array,
      required: true,
    },
    default_external_kintai_id: {
      type: Number,
      required: true,
    },
    //1オフィスのoffice_staff_kintais情報 重複チェックに使いたい
    office_staff_kintais_at_one_office: {
      type: Array,
      required: false
    }
  },
  computed: {
    // 役職リスト
    position_list() {
      return this.positions.data.map(position => {
        return {
          text: position.name,
          value: position.id
        }
      })
    },
    // 事業所リスト
    office_list () {
      return this.offices.data.map(office => {
        return {
          text: office.name,
          value: office.id
        }
      })
    },
    //　チームリスト
    team_staff_name_list () {
      return this.office_staffs.map(office_staff => {
        let member_list = []
        Object.keys(office_staff.team_ids).forEach(team => {
          // team.value（コンボボックスが選択されてる場合）
          if(typeof office_staff.team_ids[team] === 'object' && office_staff.team_ids[team] !== undefined) {
            this.team_staffs[office_staff.team_ids[team].value].forEach(team_staff => {
              if(team_staff) {
                member_list.push(team_staff.lastname + team_staff.firstname)
              }
            })
          } else {
            // 初期値保存
            // 新規チーム作成の場合にはチームメンバー居ない判定
            if(this.team_staffs[team]) {
              this.team_staffs[team].forEach(team_staff => {
                if(team_staff) {
                  member_list.push(team_staff.lastname + team_staff.firstname)
                }
              })
            }
          }
        })
        return member_list.filter(Boolean).join(',')
      })
    },
    // バリデーション（保存ボタン制御用）
    checkValidation () {
      return this.duplicateOfficeCheck
        && this.occupationCheckValidation
        && this.occupationRatioCheckValidation
        && this.shiftSettingCheckValidation
        && this.externalNameAndIdCheckValidation
        && this.notSelectedOfficeCheck()
    }
  },
  data() {
    // console.log('【office_staffs】', this.staff && this.staff.data ? this.staff.data.office_staffs : null)
    return {
      // フリーワード検索用
      search: null,
      // 事業所職員
      office_staffs : (this.staff && this.staff.data && this.staff.data.office_staffs.length > 0)
      ? this.staff.data.office_staffs
      : [{
        office_id:'',
        position_id: '',
        occupations: [
          {
            percentage:100,
            main_flg:1,
            id:null
          }
        ], // 職種のリスト：id, percentage, main_flg
        team_ids: [],
        new_team_names :[],
        percentage: 100,
        workpatterns: [],
        shift_pattern: {
          mon :{ workpattern_id : '', workpattern_name: ''},
          tue :{ workpattern_id : '', workpattern_name: ''},
          wed :{ workpattern_id : '', workpattern_name: ''},
          thu :{ workpattern_id : '', workpattern_name: ''},
          fri :{ workpattern_id : '', workpattern_name: ''},
          sat :{ workpattern_id : '', workpattern_name: ''},
          sun :{ workpattern_id : '', workpattern_name: ''},
          hol: { workpattern_id : '', workpattern_name: ''}
        },
        min_worktime_minutes: 0,
        max_worktime_minutes: 0,
        office_staff_kintai: {
          id: null,
          external_kintai_id: null,
          external_link_id: null
        }
      }],
      // チームリスト
      team_list : this.staff && this.staff.data.office_staffs[0] ? [this.teams[this.staff.data.office_staffs[0].office_id].data.map(team => {
        return {
          text:team.name,
          value:team.id
        }
      })] : [],
      // 選択済みのチームリスト
      selectedOfficeStaffTeams : this.staff ? this.staff.data.office_staffs.map(office_staff => office_staff.team_ids) : [],
      duplicateOfficeCheck : true, // 事業所重複チェック
      occupationCheckValidation : false, // 職種コンポーネントのバリデーション
      occupationRatioCheckValidation : false, // 業務割合コンポーネントのバリデーション
      shiftSettingCheckValidation : false, // シフト設定コンポーネントのバリデーション
      duplicateOfficeIds : [], // 事業所重複チェック用
      externalNameAndIdCheckValidation : false, //外部連携先とIDの重複チェック
      duplicatEexternalNameAndIds : [], // 外部連携先とIDの重複チェックのコンポーネントのIDを入れる
    }
  },
  methods: {
    //外部連携先とIDをチェックして重複があるかどうか
    duplicateCheckExternalNameAndId() {
      this.externalNameAndIdCheckValidation = true
      let duplicateIds = []

      Object.entries(this.office_staffs).forEach(([index, office_staff]) => {
        this.office_staff_kintais_at_one_office.forEach((one_office, key) => {
          if(
            office_staff.office_staff_kintai.external_kintai_id == one_office['external_kintai_id'] &&
            office_staff.office_staff_kintai.external_link_id == one_office['external_link_id']
          ){
            this.externalNameAndIdCheckValidation = false
            duplicateIds.push(parseInt(index))
          }
        });
      });

      this.duplicatEexternalNameAndIds = duplicateIds

      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    /**
     * 各コンポーネントからのイベント
     */
    // 職種コンポーネントより
    setOccupations (...args) {
      const [value, tabNo , check] = args
      // 職種
      this.office_staffs[tabNo].occupations = value
      // 職種コンポーネントのバリデーション
      this.occupationCheckValidation = check
      // 職種コンポーネントのemitしておく
      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    // 業務割合コンポーネントより
    setOccupationRatio (...args) {
      const [value, check] = args
      // 業務割合
      this.office_staffs = value
      // 業務割合コンポーネントのバリデーション
      this.occupationRatioCheckValidation = check
      // 業務割合コンポーネントのemitしておく
      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    // シフト設定コンポーネントより
    setShiftSetting (...args) {
      const [value, check] = args

      this.office_staffs = value
      // シフト設定コンポーネントのバリデーション
      this.shiftSettingCheckValidation = check
      // シフト設定コンポーネントのemitしておく
      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    /**
     * セレクトボックス生成
     */
    // 事業所選択時のチームリスト
    setTeamList(index, value) {
      // チームリストが変更される時、選択済みのチームもリセット
      this.office_staffs.forEach((office_staff, index) => {
        if (office_staff.office_id === value) {
          this.$set(this.office_staffs[index], 'team_ids', [])
          this.selectedOfficeStaffTeams[index] = []
        }
      })

      // チームリスト新たに作成
      if (this.teams[value]) {
        this.team_list[index] = this.teams[value].data.map(team => {
          return {
            text: team.name,
            value: team.id
          }
        })
      } else {
        this.team_list = [];
      }
    },
    /**
     * セレクトボックス選択時
     */
    // 役職
    handleSelectChangedPosition (index, val) {
      this.office_staffs[index].position_id = val
    },
    // 事業所
    handleSelectChangedOffice (index, val) {
      // 同じ事業所を選択した場合のアラート処理
      const duplicateOfficeIds = this.getDuplicateValues(this.office_staffs.map(office_staff => office_staff.office_id))
      if(duplicateOfficeIds.length) {
        this.duplicateOfficeCheck = false
        this.duplicateOfficeIds = duplicateOfficeIds
      } else {
        this.duplicateOfficeCheck = true
        this.duplicateOfficeIds = []
      }

      this.office_staffs[index].office_id = val
      this.office_staffs[index]['office_staff_kintai'].external_kintai_id = this.default_external_kintai_id
      this.office_staffs[index]['office_staff_kintai'].external_link_id = null

      //外部連携先名、IDの重複チェック
      this.duplicateCheckExternalNameAndId()

      // チームリストの変更
      this.setTeamList(index, val)

      // 勤務区分のリセット
      this.office_staffs[index].workpatterns = []
    },
    // チーム選択のコンボボックス選択時
    handleChangeSelectedOfficeStaffTeam (index, value) {
      // console.log("【選択したチーム】", value)
      this.office_staffs[index].team_ids = value
    },
    /**
     * その他
     */
    // タブを閉じる
    closeTab(index) {
      // 事業所職員情報の削除
      this.office_staffs.splice(index, 1);

      // バリデーションをemitしておく
      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    // タブを追加
    newTab() {
      this.office_staffs.push({
        office_id:'',
        position_id: '',
        occupations: [
          {
            percentage:0,
            main_flg:0,
            id:null
          }
        ], // 職種のリスト：id, percentage, main_flg
        team_ids: [],
        new_team_names :[],
        percentage: 0,
        workpatterns: [],
        shift_pattern: {
          mon :{ workpattern_id : '', workpattern_name: ''},
          tue :{ workpattern_id : '', workpattern_name: ''},
          wed :{ workpattern_id : '', workpattern_name: ''},
          thu :{ workpattern_id : '', workpattern_name: ''},
          fri :{ workpattern_id : '', workpattern_name: ''},
          sat :{ workpattern_id : '', workpattern_name: ''},
          sun :{ workpattern_id : '', workpattern_name: ''},
          hol: { workpattern_id : '', workpattern_name: ''}
        },
        min_worktime_minutes: 0,
        max_worktime_minutes: 0,
        office_staff_kintai: {
          id: null,
          external_kintai_id: null,
          external_link_id: null
        }
      });

      // バリデーションをemitしておく
      this.$emit("office_staffs", this.office_staffs, this.checkValidation)
    },
    // 重複値を取得
    getDuplicateValues(a) {
      const duplicates = [];
      const seen = new Set();
      for (const value of a) {
        if (seen.has(value)) {
          duplicates.push(value);
        } else {
          seen.add(value);
        }
      }
      return duplicates;
    },
    // 事業所が一つでも未選択だったらfalse
    notSelectedOfficeCheck () {
      return this.office_staffs.every(office_staff => office_staff.office_id)
    },
    external_kintai_name_list(office_id) {
      let name_list = [];
      let office_external_kintai = [];
      if (office_id) {
         office_external_kintai = this.office_kintai.filter(kintai => kintai.office_id === office_id);
      }
      if (office_external_kintai) {
          office_external_kintai.forEach(element => {
          name_list.push(element['external_kintai']);
        });
      }

      //name_listをdisplay_orderの昇順にソート
      let sorted_name_list = name_list.sort((a, b) => {
        return a.external_kintai_codes[0].display_order - b.external_kintai_codes[0].display_order
      })

      return sorted_name_list;
    }
  },
  watch : {
    office_staffs : {
      handler (past, value) {
        // console.log('Formへの送信データ', value)
        this.$emit("office_staffs", value, this.checkValidation)
      },
      deep :true
    },
  },
  mounted() {
    // 編集の場合に値セット
    this.office_staffs = this.staff && this.staff.data.office_staffs.length
      ? this.staff.data.office_staffs
      : this.office_staffs
    
    this.duplicateCheckExternalNameAndId()

    // バリデーションをemitしておく
    this.$emit("office_staffs", this.office_staffs, this.checkValidation)
  },
}
</script>

<style>
.nav-wrapper-custom {
  overflow: scroll !important;
  white-space: nowrap !important;
}
.nav-custom {
  flex-wrap: nowrap !important;
}
.staff-area-control {
  height: 40px;
  min-width: 270px;
  max-width: 270px;
}
</style>
