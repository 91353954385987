<template>
  <div class="sort_box">
    <v-select
      :items="list"
      item-text="text"
      item-value="id"
      return-object
    >
    </v-select>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'SortBox',
  components: {
  },
  props: {
  },
  data () {
    return {
      list: [
        { id: 0, text: '役職順' },
        { id: 1, text: 'チーム順' },
        { id: 2, text: 'あいうえお順' },
        { id: 3, text: '新しい順' },
        { id: 4, text: '古い順' },
      ]
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  }
}
</script>

