<template>
    <v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="dialog = false" persistent max-width="650">
      <div id="modalform" tabindex="-1" ref="modalform">
        <v-card>
          <v-card-title style="color:#787878; font-weight: 700; justify-content: space-between; background: #EFEFEF;">
            <div></div>
            <div>メモを追加</div>
            <div><button class="btn_close" @click="dialog = false">✕</button></div>
          </v-card-title>
          <v-card-text style="padding: 30px 70px;">
            <v-textarea
              v-model="memo"
              style="border-radius: 4px;"
              height=200
              maxlength=256
              placeholder="テキストを入力してください"
              no-resize
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions style="padding: 0;">
            <v-spacer></v-spacer>
              <div style="display: flex; justify-content: center; width: 100%; padding: 0px 0px 35px 0px;">
              <button class="btn_green_shadow" style="width:150px; padding: 5px 0;" @click="saveMemo">保存</button>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
</template>

<style scoped>
.btn_close {
    color: #384250;
}
</style>


<script>
import axios from 'axios';

export default {
    name: 'MemoDialog',
    components: {},
    props: {
        year: {
            type: String
        },
        month: {
            type: String
        },
        office_id: {
            type: Number
        },
        shift_day_memo: {
            type: Array
        },
        // チーム選択がされているかどうか
        selected_team_id: {
            type: Number
        },
    },
    data() {
        return {
            dialog: false,
            day: '',
            memo: '',
            assignment: 'team',
        }
    },
    computed: {
        team_id: {
            get() {
                return this.selected_team_id;
            },
            set(value) {
                return value;
            }
        }
    },
    methods: {
        openDialog(key) {
            const filteredData = this.shift_day_memo.filter((item) =>
                item.target_date.substring(8, 10) === key &&
                ((this.selected_team_id === 0 && item.assignment === 'office') ||
                    (this.selected_team_id === -1 && item.assignment === 'noteam') ||
                    (item.team_id === this.selected_team_id)));
            this.day = key;
            this.memo = filteredData.length > 0 ? filteredData[0].event : '';
            this.checkMemoFlg = filteredData.length > 0;
            this.dialog = true;
        },
        // loading制御
        updateLoading(flg) {
            EventBus.$emit('updateLoading', flg);
        },
        saveMemo() {
            if (this.team_id === 0) {
                this.assignment = 'office';
            } else if (this.team_id === -1) {
                this.assignment = 'noteam';
            } else {
                this.assignment = 'team';
            }
            this.updateLoading(true);
            axios({
                method: 'post',
                url: this.$route('shifts.updateMemo', {
                    office_id: this.office_id,
                    target_date: this.year + "-" + this.month + "-" + this.day,
                    event: this.memo,
                    team_id: this.team_id,
                    assignment: this.assignment
                })
            })
                .then(response => {
                    this.$swal({
                        title: '保存しました',
                        icon: "success",
                    });
                    if (!this.checkMemoFlg) {
                        this.$emit('memo-added', this.memo, this.day);
                    }
                    else {
                        this.$emit('memo-updated', this.memo, this.day);
                    }
                    this.updateLoading(false);
                })
                .catch(error => {
                    this.updateLoading(false);
                    this.$swal.fire({
                        icon: 'error',
                        text: '保存に失敗しました。しばらくたってから再試行してください。'
                    });
                });
            this.dialog = false;
        }
    },
}
</script>
