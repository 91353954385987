<template>
  <tr>
    <td class="fixed fixed-add stripe_green"></td>
    <td
      class="fixed fixed01 show-workpattern"
      style="text-align: center;"
    >
      <div style="display:flex; justify-content: space-between!important;">
        <div style="width: 100%;">{{ name }}</div>
        <button class="btn_total_del" @click="deleteRow"><font-awesome-icon icon="xmark" /></button>
      </div>
    </td>
    <td 
      class="fixed fixed02 summary-row-todal show-total"
      :style="'left: ' + getTotalLeft() + ';'"
    >
      {{ getTotal() }}
    </td>
    <td
      v-for="(day, index) in days"
      style="text-align: center;"
      :class="colorSelect(day)"
      :key="index"
    >
      {{ list[day.day] }}
    </td>
  </tr>
</template>

<style scoped>
.lower{
  background: #f3f5fc;
  color: blue;
}
.upper {
  background: #fcf3f2;
  color: red;
  font-weight: 900;
}
.default {
  background: #FFFFFF;
  color: black;
}
</style>

<script>
export default {
  name: 'ShiftsScheduleListManualCalcColumn',
  components: {
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    // 日付データ
    days: {
      type: Array,
      default: function() {
        return [];
      }
    },
    workpatterns: {
      type: Array,
      default: () => {
        return {};
      }
    },
    time_list: {
      type: Object,
      default: () => {
        return {};
      }
    },
    id: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
        return [];
      }
    },
    staff_assignment_for_workpattern: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data () {
    return {
      name: '',
      list: {},
    }
  },
  methods: {
    // 合計列の左の固定位置取得
    getTotalLeft() {
      let elTarget = document.getElementsByClassName('show-total');
      return elTarget[0].style.left;
    },
    // 行削除
    deleteRow() {
      this.$swal({
        text: '集計列を削除します。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.isConfirmed) return;
        this.$emit('deleteRow', this.id);
      });
    },
    // 集計値の取得
    getTotal() {
      return Object.values(this.list).reduce((calc, val) => calc + val, 0);
    },
    setData() {
      let tmp = this.id.split('_');
      let id = tmp[1];
      if (this.id.match(/time/)) {
        this.name = this.time_list[id].ja;
      } else {
        const workpattern = this.workpatterns.find(workpattern => Number(workpattern.id) === Number(id));
        if (workpattern && 'name' in workpattern) {
          this.name = workpattern.name;
        } else {
          console.error('workpatternsの中に' + id + 'が存在しません');
          console.error(this.workpatterns);
          this.name = '';
        }
      }
      for (let day in this.item) {
        this.$set(this.list, day, this.item[day]);
      }
    },
    /**
     * 曜日英文字の逆引き用
     * @param {*} num
     */
     getDayEn(num) {
      return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][num];
    },
    /**
     * 該当日のスタッフ数が最大値を超えている場合はlowerクラスを返す
     * 該当日のスタッフ数が最小値を下回っている場合はupperクラスを返す
     * Table.vueにてmin,mizが0,0の場合には一括指定のmin,maxを採用済
     */
     colorSelect(day) {
      // this.listに{__ob__: Observer}が入って邪魔なので対策
      if(this.list[day.day] === undefined) return;

      if (day.holiday) {
        /**
         * 祝日の場合
         * 祝設定→曜日設定（曜日設定が0なら一括設定）
         */
        return this.colorSelectNotDef(day, 'hol')
          || this.colorSelectNotDef(day, this.getDayEn(day.w_num))
          || this.colorSelectWeekday(day, 'def');
      } else {
        /**
         * 曜日指定の場合
         */
        return this.colorSelectNotDef(day, this.getDayEn(day.w_num))
          || this.colorSelectWeekday(day, 'def');
      }
    },
    /**
     * 一括してい以外のmin,maxによる色分け
     */
     colorSelectNotDef(day, key) {
      if(this.staff_assignment_for_workpattern[key]
        && Number(this.staff_assignment_for_workpattern[key].max) !== 0
        && Number(this.staff_assignment_for_workpattern[key].min) !== 0
      ) {
          // 該当日のスタッフ数が最大値を超えている場合
          if(this.list[day.day] > Number(this.staff_assignment_for_workpattern[key].max)) {
            return 'lower';
          }
          // 該当日のスタッフ数が最小値を下回っている場合
          if(this.list[day.day] < Number(this.staff_assignment_for_workpattern[key].min)) {
            return 'upper';
          }
          // 範囲内なら何も返さない
          return 'default'
      }
      return null;
    },
    /**
     * 曜日指定のmin,maxによる色分け
     */
    colorSelectWeekday(day, key) {
      // maxとminがどちらも0の場合で、かつ、数値が0より大きい場合はupperクラスを返す
      if(this.staff_assignment_for_workpattern[key]
        && this.staff_assignment_for_workpattern[key].max === 0
        && this.staff_assignment_for_workpattern[key].min === 0
        && this.list[day.day] > 0) {
        return 'upper';
      }

      // 該当日のスタッフ数が最大値を超えている場合
      if(this.staff_assignment_for_workpattern[key]
        && this.list[day.day] > this.staff_assignment_for_workpattern[key].max) {
          return 'lower';
      }
      // 該当日のスタッフ数が最小値を下回っている場合
      if(this.staff_assignment_for_workpattern[key]
        && this.list[day.day] < this.staff_assignment_for_workpattern[key].min) {
        return 'upper';
      }
    },
  },
  computed: {
  },
  watch: {
    item: {
      handler(new_data, old_data) {
        this.setData();
      },
      deep: true,
    },
  },
  mounted() {
    this.setData();
  }
}
</script>
