<template>
  <div>
    <b-button
      class="btn-show-change-flg"
      @click="allRowShow"
    >
      {{ all_row_show ? '絞り込み表示' : '全表示'}}
    </b-button>
  </div>
</template>

<style scoped>
.btn-show-change-flg {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  color: #FFFFFF;
  min-width: 50px;
  height: 20px;
  border: solid 1px #FFFFFF;
  border-radius: 6px;
  background: #F04438;
  box-shadow: 0px 3px 0px #00000033;
}
</style>

<script>
export default {
  name: 'BtnAllRowShow',
  data () {
    return {
      all_row_show: false,
    };
  },
  methods: {
    allRowShow() {
      this.all_row_show = !this.all_row_show;
      this.$emit('allRowShow', this.all_row_show);
    },
  },
}
</script>
