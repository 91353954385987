<style scoped>
.base-edit {
  height: 30px;
}
.shift-edit {
  font-size: 9px;
  padding-top: 8px;
}
</style>

<template>
  <td
    class="workpattern_shift_td"
    @click="clickShift()"
    style="position: relative;"
    :style="col_style"
    :class="edit_mode ? 'td-day-edit' : 'td-day'"
  >
    <div :class="workpattern_color">
      <HopeReason :lock_type="lock_type" :hope_type="hope_type" :edit_mode="edit_mode" :reason="shift !== null ? shift.reason : ''" />
      <div class="workpattern-text" :title="shift_str" :class="edit_mode ? 'shift-edit' : ''">{{ workpattern_text }}</div>
      <div :class="edit_mode ? 'priority-edit' : 'priority'" :style="'background-color:' + priority_color">
        {{ priority }}
      </div>
    </div>
    <ShiftChangeDialog
      ref="shiftChangeDialog"
      modal_type="schedule"
      :full_day="this.day.full"
      :week="this.day.w"
      :work_categories="work_categories"
      @changeShift="changeShift"
    />
  </td>
</template>

<script>
import columnView from '@/Pages/Shifts/Components/Table/MainTable/Column/functions/column_view';
import HopeReason from '@/Pages/Shifts/Components/Table/MainTable/Column/HopeReason';
import ShiftChangeDialog from '@/Pages/Shifts/Components/Dialog/ShiftSelectDialog/ShiftChangeDialog';

export default {
  name: 'ShiftsScheduleColumn',
  components: {
    HopeReason,
    ShiftChangeDialog,
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: { type: Boolean, default: false },
    day: { type: Object },
    staff: {type: Object},
    work_categories: {type: Object},
    initial_shift: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 連続配置の状態
    is_consecutive_workpattern: { type: Boolean },
    consecutive_shift: { type: Object }
  },
  data () {
    return {
      // このファイルで利用する関数
      functions: columnView,

      new_shift: {},
    }
  },
  methods: {
    // セルのクリック
    clickShift() {
      if (this.is_consecutive_workpattern) {
        // main_idの有無をチェック
        let is_main_work = false;
        let is_replacement_work = true;
        let workpattern_name = '';
        is_main_work = Object.keys(this.staff.workpatterns.work).some(key => {
          return this.staff.workpatterns.work[key].id === this.consecutive_shift.main_id;
        });
        if (!is_main_work) {
          is_main_work = Object.keys(this.staff.workpatterns.holiday).some(key => {
              return this.staff.workpatterns.holiday[key].id === this.consecutive_shift.main_id;
          });
        }
        // replacement_workの有無をチェック
        if (is_main_work) {
          if (this.consecutive_shift.replacement_id !== null && this.consecutive_shift.replacement_id === '') {
            is_replacement_work = Object.keys(this.staff.workpatterns.work).some(key => {
                return this.staff.workpatterns.work[key].id === this.consecutive_shift.replacement_id;
            });
          }
          if (!is_replacement_work) {
            workpattern_name = this.consecutive_shift.replacement_name;
          }
        } else {
          workpattern_name = this.consecutive_shift.main_name;
        }
        // 選択したダイアログがユーザの勤務区分になければエラー
        if (!is_main_work || !is_replacement_work) {

          const url = this.$route('staffs.edit', { staff: this.staff.staff_id });

          this.$swal({
            html: '「' + this.staff.lastname + ' ' + this.staff.firstname + '」さんに「勤務区分：' + workpattern_name + '」を割り当てる場合は、事前に<a href="' + url + '">勤務区分の割り当て設定</a>を行う必要があります。',
            icon: 'warning',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          });
          return;
        }

        // 配置するシフト作成
        this.changeShift(this.consecutive_shift.is_changed, this.staff, this.consecutive_shift.main_id, this.consecutive_shift.replacement_id);
      } else {
        this.showShiftChangeDialog();
      }
    },
    showShiftChangeDialog() {
      let main_id = null;
      let replacement_id = null;
      if (this.shift !== null && 'workpattern_id' in this.shift) {
        main_id = this.shift.workpattern_id;
      }
      if (this.shift !== null && 'holiday_workpattern_id' in this.shift) {
        replacement_id = this.shift.holiday_workpattern_id;
      }
      let data = {
        item: this.staff,
        main_id: main_id,
        replacement_id: replacement_id,
      };

      this.$refs.shiftChangeDialog.openDialog(data);
    },
    // シフト変更
    changeShift(is_changed, user, workpattern_id, holiday_workpattern_id) {
      let new_shift = this.new_shift;

      // データが同じなら処理しない
      if ('shift' in new_shift && !is_changed) return;

      let shift = null;
      if (!('shift' in new_shift)) {
        shift = {};
        shift.staff_id = user.staff_id;
        shift.office_staff_id = user.office_staff_id;
        shift.shift_date = new_shift.day.full;
        shift.occupation_id = user.occupation_id;
      } else {
        shift = new_shift.shift;
      }
      shift.change = true;

      if (workpattern_id in user.workpatterns.work) {
        shift.workpattern = user.workpatterns.work[workpattern_id];
        if (shift.workpattern.type === 'half_work') {
          shift.holiday_workpattern = user.workpatterns.holiday[holiday_workpattern_id];
        } else {
          shift.holiday_workpattern = null;
        }
      } else if (workpattern_id in user.workpatterns.holiday) {
        shift.workpattern = user.workpatterns.holiday[workpattern_id];
        shift.holiday_workpattern = null;
      } else {
        shift.workpattern = null;
        shift.holiday_workpattern = null;
      }

      shift.workpattern_id = workpattern_id;
      shift.holiday_workpattern_id = holiday_workpattern_id;

      this.$set(this.new_shift, 'shift', shift);
      this.$emit('updateShift', {
        is_changed: true,
        day: this.day,
        user: user,
        shift: this.new_shift,
      });
    },
    // シフトデータの最新化
    updateShiftData(new_data) {
      // 初期データ設定
      if (new_data !== null) {
        Object.keys(this.new_shift).forEach(key => {
          this.$delete(this.new_shift, key)
        });
        for (let key in new_data) {
          this.$set(this.new_shift, key, new_data[key]);
        }
      }
    },
  },
  computed: {
    // 現在のシフトデータ
    shift() {
      if (this.new_shift === null || this.new_shift === undefined || !('shift' in this.new_shift)) {
        return null;
      } else {
        return this.new_shift['shift'];
      }
    },
    // シフトの文字列
    shift_str() {
      let workpattern = null;
      // データが存在しない場合は空文字を返却
      if (this.shift === null) return '';
      workpattern = this.shift.workpattern;
      if (workpattern === null) {
        return '';
      }
      if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) {
        return '削除';
      }
      let name = workpattern.name;
      return name;
    },
    // 背景色
    col_style() {
      if (this.shift !== null && 'change' in this.shift && this.shift.change) {
        return 'background-color: #ff82b4';
      } else {
        return '';
      }
    },
    // 希望休の場合の優先順位
    priority() {
      // シフトデータが存在しない場合は空文字を返却
      if (this.shift === null || this.shift.priority === null) {
        return '';
      } else {
        return this.shift.priority;
      }
    },
    // 希望バッジの有無判定に利用
    // 0:initial 1:希望休（公休） 2:希望休（有休） 3:勤務
    hope_type() {
      return this.functions.getHopeType(this.shift, 'shift');
    },
    lock_type() {
      return this.functions.getLockType(this.shift, 'shift');
    },
    // 勤務区分の表示文字取得
    workpattern_text() {
      return this.functions.getWorkpatternText(this.shift_str);
    },
    workpattern_color() {
      let workpattern = null;
      let priority_class = '';

      // データが存在しない場合は空文字を返却
      const shift = this.shift;
      if (shift === null) return '';

      workpattern = shift.workpattern;
      if (workpattern === null) return '';
      if (workpattern.deleted_at !== null && workpattern.deleted_at !== undefined) return '';

      // 優先順位取得
      if (shift.priority !== null && shift.priority !== undefined) {
        priority_class = 'priority_color ';
      }

      // 編集モード／通常モード
      if (this.edit_mode) {
        priority_class = priority_class + ' base-edit ';
      }

      // 勤務区分によってクラスを設定
      if (workpattern.category === 'holiday') {
        if (shift.hoped_flg) {
          if (workpattern.type === 'paid_holidays' || workpattern.type === 'paid_half_holiday') {
            return priority_class + 'workpattern_shift_base workpattern_hope_paid';
          } else {
            return priority_class + 'workpattern_shift_base workpattern_hope_off';
          }
        } else {
          if (workpattern.type === 'paid_holidays' || workpattern.type === 'paid_half_holiday') {
            return priority_class + 'workpattern_shift_base workpattern_nothope_paid';
          } else if (workpattern.type === 'public_holidays' || workpattern.type === 'public_half_holiday') {
            return priority_class + 'workpattern_shift_base workpattern_nothope_off';
          } else {
            return priority_class + 'workpattern_shift_base workpattern_other';
          }
        }
      } else {
        return priority_class + 'workpattern_shift_base workpattern_' + workpattern.category;
      }
    },
    priority_color() {
      return this.functions.getPriorityBgColor(this.shift, 'shift');
    },
  },
  watch: {
    initial_shift: {
      handler(new_data, old_data) {
        this.updateShiftData(structuredClone(new_data));
      },
      deep: true,
    }
  },
  created() {
  },
  mounted() {
    // 初期データ設定
    this.updateShiftData(structuredClone(this.initial_shift));
  },
  beforeDestroy() {
  },
  destroyed () {
  },
}
</script>
