<template>
  <b-button
    style="padding: 0px!important;"
    class="btn_white btn-bulk-setting"
    :style="'font-size: ' + ( edit_mode ? '12' : '16' ) + 'px; height: ' + (edit_mode ? '30px' : '60px' ) + '!important; width: '+ ( edit_mode ? '40px' : '144px' ) + '!important; margin-bottom:' + ( edit_mode ? '8px' : '' ) + ';'"
    @click="bulkSetting"
  >
    <span v-if="edit_mode">勤</span>
    <span v-else>勤務条件一括登録</span>
  </b-button>
</template>

<style scoped>
.btn-bulk-setting {
  color: #02B28A !important;
  width: 144px !important;
  height: 40px !important;
  border: solid 1px #02B28A !important;
  padding: 0px !important;
}
</style>

<script>

export default {
  name: 'BtnBulkSetting',
  components: {
  },
  props: {
    // edit_mode true:edit false:nomal
    edit_mode: {
      type: Boolean,
      default: false
    },
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    team_id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },

  methods: {
    // 勤務条件一括設定
    bulkSetting() {
      this.updateLoading(true);
      let url = this.$route('staffs.workcons.bulk-add');
      let query_strings = [];
      query_strings.push('office=' + encodeURIComponent(this.office.id));
      if (this.team_id != 0) {
        query_strings.push('team=' + encodeURIComponent(this.team_id));
      }
      query_strings.push('type=2');
      query_strings.push('year=' + this.year);
      query_strings.push('month=' + this.month);
      if (query_strings.length > 0) {
        url += '?' + query_strings.join('&');
      }
      this.$inertia.get(url);
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>

