<template>
  <b-button class="btn-shift-data" :style="additional_style" @click="importData" :class="[ is_free_plan ? 'disable-style' : '' ]">
    <font-awesome-icon icon="upload" style="color: #D2D6DB; vertical-align: text-top; padding-right: 5px;" />{{ label }}
  </b-button>
</template>

<script>
import axios from "axios"
export default {
  name: 'BtnActualDataImport',
  props: {
    is_free_plan: {
      type: Boolean,
      default: false
    },
    external_kintais: {
      type: Array,
      required: true
    },
    office_id: {
      type: Number,
      default: null
    },
    additional_style: {
      type: String,
      default: ""
    },
    label: {
      type: String
    }
  },
  methods: {
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg)
    },
    importData: function () {
      event.preventDefault()
      if (this.is_free_plan) {
        const url = this.$route('lodgments.plan-edit')
        const message = `機能制限無料プランでのご利用のため、実績のインポートができません。インポートする場合は、<a href='${url}'>スタンダードプランへの変更</a>をお願いします。`
        this.$swal({
          icon: 'info',
          html: message,
        })
        return
      }

      if (this.external_kintais.length === 0) {
        this.$swal({
          icon: 'error',
          html: '外部連携先が設定されていません。',
        })
        return
      }

      let external_kintai_id_options = []
      this.external_kintais.forEach(external_kintai => {
        const option_name = this.htmlspecialchars(external_kintai.name)
        const option_value = this.htmlspecialchars(external_kintai.id)
        external_kintai_id_options.push(`<option value="${option_value}">${option_name}</option>`)
      })
      this.$swal({
        html: '<div>勤務形態一覧表用の勤怠データ（CSV）をインポートします。</div>'
          + '<div style="font-size: 11pt;"><span style="width: 9em;">外部連携先名　</span><select id="fc-import-external-kintai-id" name="external_kintai_id" class="swal2-input" style="width: calc(100% - 9em);">' + external_kintai_id_options.join('') + '</select></div>',
        icon: 'warning',
        showCancelButton: true,
        input: 'file',
        inputAttributes: {
          name: 'actual_work_file',
          id: 'actual_work_file',
          accept: 'application/vnd.csv'
        },
        confirmButtonText: 'アップロード',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.updateLoading(true)
            const url = this.$route('fulltime-conversions.do-import')
            const formData = new FormData()
            const external_kintai_id = document.getElementById('fc-import-external-kintai-id')
            if (typeof external_kintai_id === 'undefined' || external_kintai_id === null || external_kintai_id === '') {
              this.updateLoading(false)
              this.$swal({
                icon: 'error',
                html: '<div style="text-align: left">外部連携先が選ばれていません。</div>',
              })
                .then(response => {
                  this.importData()
                })
              return
            }
            if (typeof result.value === 'undefined' || result.value === null || result.value === '') {
              this.updateLoading(false)
              this.$swal({
                icon: 'error',
                html: '<div style="text-align: left">インポートする勤怠データ（CSV）が選ばれていません。</div>',
              })
                .then(response => {
                  this.importData()
                })
              return
            }
            formData.append('external_kintai_id', external_kintai_id.value)
            if (this.office_id !== null) {
              formData.append('office', this.office_id)
            }
            formData.append('actual_work_file', result.value)
            axios.post(url, formData)
              .then(response => {
                this.updateLoading(false)
                if (response.data.success) {
                  if (response.data.code > 0) {
                    this.$swal({
                      icon: 'warning',
                      html: '実績のインポートに失敗しました。<br />総件数:' + response.data.data.rows + '件<br />成功件数:' + response.data.data.success_rows + '件<br />失敗件数:' + response.data.data.failed_rows + '件<br />' + this.nl2br(this.htmlspecialchars(response.data.message.join("\n"))),
                    })
                      .then(result => {
                        location.reload()
                      })
                  } else {
                    this.$swal({
                      icon: 'success',
                      html: '実績のインポートが完了しました。<br />総件数:' + response.data.data.rows + '件<br />成功件数:' + response.data.data.success_rows + '件<br />失敗件数:' + response.data.data.failed_rows + '件<br />',
                    })
                      .then(result => {
                        location.reload()
                      })
                  }
                } else {
                  this.$swal({
                    icon: 'error',
                    html: '<div style="text-align: left">実績のインポートに失敗しました。<br />' + response.data.message + '<br />再度インポートをお願いします。<br />インポートが成功しない場合は、<a href="https://synchroseries.jp/synchroshift/contact_form.html" target="_blank" rel="noopener noreferrer">こちら</a>にインポート対象のファイルを添付し、お問い合わせください。</div>',
                  })
                    .then(result => {
                      location.reload()
                    })
                }
              })
              .catch(error => {
                this.updateLoading(false)
                if (typeof error.response.data.message === 'undefined' || error.response.data.message === null || error.response.data.message === '') {
                  this.$swal({
                    icon: 'error',
                    html: '<div style="text-align: left">実績のインポートに失敗しました。再度インポートをお願いします。<br />インポートが成功しない場合は、<a href="https://synchroseries.jp/synchroshift/contact_form.html" target="_blank" rel="noopener noreferrer">こちら</a>にインポート対象のファイルを添付し、お問い合わせください。</div>',
                  })
                    .then(result => {
                      location.reload()
                    })
                } else {
                  this.$swal({
                    icon: 'error',
                    html: '<div style="text-align: left">実績のインポートに失敗しました。<br />' + this.nl2br(this.htmlspecialchars(error.response.data.message))
                  })
                    .then(result => {
                      location.reload()
                    })
                }
              })
          }
        })
    },
    htmlspecialchars(string) {
      if (typeof string !== 'string') {
        return string
      }
      return string.replace(/[<>&"'`]/g, match => {
          const escaped = {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;',
            "'": '&#x27;',
            '`': '&#x60;'
          }
          return escaped[match]
        })
    },
    nl2br(string) {
      string = string.replace(/\r\n/g, "<br />")
      string = string.replace(/(\n|\r)/g, "<br />")
      return string
    },
  }
}
</script>
