<template>
  <div></div>
</template>

<style>
</style>

<script>
export default {
  name: 'ShiftsApplicationStatusNoParam',
  components: {
  },
  props: {
    view_type: {
      type: String,
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    year: {
      type: String,
    },
    month: {
      type: String,
    },
  },
  data () {
    return {
    }
  },

  methods: {
  },
  computed: {
  },
  mounted() {
    const data = {
      office: this.office.id,
      year: this.year,
      month: this.month
    };
    if (this.view_type == 'application_status') {
      this.$inertia.get(this.$route('shifts.application-status', data));
    } else {
      this.$inertia.get(this.$route('shifts.schedule', data));
    }
  }
}
</script>

  }
}
</script>
