<template>
  <b-button class="btn-shift-save" @click="save">保存</b-button>
</template>

<script>
export default {
  name: 'BtnSave',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    save() {
      this.$swal({
        text: '保存します。よろしいですか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('saveApplicationStatus');
        }
      });
    },
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
