<template>
	<tr>
    <td
      class="fixed fixed02 show-workpattern"
      style="vertical-align: middle; text-align: center; background: #F3F4F6!important; z-index: 3;"
    >
      {{ category }}
    </td>
		<td
			v-for="(day, index) in days"
      class="fixed fixed02"
			style="text-align: center; vertical-align: middle; background: #FFFFFF;"
			:class="color_select_class_list[day.day]"
      :key="index"
		>
		  {{ list[category][day.day] }}
		</td>
	</tr>
</template>

<style scoped>
.upper {
  background: #fcf3f2;
  color: red;
  font-weight: 900;
}
.default {
  background: #FFFFFF;
  color: black;
}
</style>

<script>
export default {
  name: 'ShiftsHolidayLimitDefaultCalcColumn',
  components: {
  },
  props: {
    // 日付データ
    days: {
      type: Array,
      default: function() {
        return [];
      }
    },
    category: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {
        return {};
      }
    },
    color_select_class_list: {
      type: Object,
      default: () => {
        return {};
      }
    },
	},
}
</script>
