<template>
  <b-modal
    ref="my-modal"
    :no-enforce-focus="true"
    :hide-header="!title"
    centered
    size="lg"
  >
    <template #modal-title>
      パスワード変更
    </template>

    <b-container fluid>
      <b-row class="my-1 text-center">
        <b-col cols="12" class="mb-3">
          <input
            type="text"
            class="form-control"
            id="password"
            placeholder="パスワードを入力してください。※8文字以上、かつ半角英数字記号となる必要があります。"
            maxlength="255"
            style="width: 680px;"
            v-model="form.password"
          />
          <div v-if="is_error" class="invalid-feedback" style="text-align: left;">{{ error }}</div>
        </b-col>
        <b-col cols="12" class="help">
          <a href="#" @click="regeneratePassword">パスワード自動生成</a>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button @click="cancel" variant="secondary" class="mt-3" block>閉じる</b-button>
      <b-button :disabled="form.password == null || form.password == '' || is_error" @click="save" variant="primary" class="mt-3" block>変更する</b-button>
    </template>
  </b-modal>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import axios from 'axios';

export default {
  components: {
  },
  props: {
    staff: {
      type: Object,
      required :false,
    },
    // モーダル表示用
    open :{
      type: Boolean,
      required :true,
    },
    title : {
      type: String,
      required :false,
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        password : null
      }),
      password_length: 8,
      letters : 'abcdefghijklmnopqrstuvwxyz0123456789',
      modalDisplay: false,
      is_error: true,
      error: '',
    }
  },
  watch: {
    open () {
      this.modalDisplay = this.open
    },
    'form.password':{
      handler(val, oldval) {

        let check_string = this.form.password;
        this.is_error = false;
        this.error = '';

        // 必須チェック
        if (check_string == null || check_string == '') {
          this.is_error = true;
          this.error = 'パスワードは必ず入力してください。';
          return;
        }

        // 文字数チェック
        if (check_string.length < this.password_length) {
          this.is_error = true;
          this.error = 'パスワードは' + this.password_length + '文字以上で入力してください。';
          return;
        }
        if (check_string.length > 255) {
          this.is_error = true;
          this.error = 'パスワードは255文字以内で入力してください。';
          return;
        }

        // 半角英数字記号以外はNG
        if (check_string.match(/[^\x01-\x7E]/)) {
          this.is_error = true;
          this.error = '半角英数字と指定された記号以外の文字が含まれています。';
          return;
        }
      },
      immediate: true
    },
  },
  methods: {
    // 保存
    async save () {
      this.updateLoading(true);
      await axios.put(
        route('staffs.password', this.staff.data.id),{
          password : this.form.password,
        }
      ).then((result) => {
        if (result.status === 200) {
          this.updateLoading(false);
          this.$swal({
            title: "変更しました",
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.is_error = false;
          this.error = '';
          // modal閉じる
          this.hideModal();
        }
      }).catch((err) => {
        this.updateLoading(false);
        this.is_error = true;
        this.error = err.response.data.message;
      })
    },
    cancel() {
      // modal閉じる
      this.hideModal();
    },
    // モーダル開く
    showModal() {
      this.is_error = false;
      this.error = '';
      this.$refs['my-modal'].show()
    },
    // モーダル閉じる
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    // パスワード生成
    regeneratePassword() {
      let result = '';
      for (let i = 0; i < this.password_length; i++) {
        result +=  this.letters[Math.floor(Math.random() * this.letters.length)];
      }
      this.form.password = result
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
  }
}
</script>

<style scoped>
.modal-backdrop {
  opacity: 0.4 !important;
}
</style>
