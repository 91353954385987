<template>
  <div style="display: flex; padding-right: 15px;">
    <div :class="tab2_class" class="tab_style" style="width: 148px; height: 30px; text-align: center; float: left;" @click="goSchedule">
      <div :class="tab2_caption">シフト</div>
    </div>
    <div :class="tab1_class" class="tab_style" style="width: 148px; height: 30px; text-align: center; float: left;" @click="goApplicationStatus">
      <span :class="tab1_caption">希望申請状況</span>
    </div>
  </div>
</template>

<style scoped>
.tab {
  cursor: pointer;
}
.tab:focus {
  opacity: 0.7;
}
.tab-active {
  border-bottom: 3px solid #3f6ad8;
}
.tab-active-caption {
  color: #3f6ad8;
}
.tab-passive-caption {
  color: none;
}
</style>

<script>
export default {
  name: 'ShiftTab',
  components: {
  },
  props: {
    year: {
      type: String,
      default: 0
    },
    month: {
      type: String,
      default: 0
    },
    office: {
      type: Object,
      default: function() {
        return {};
      }
    },
    view_type: {
      type: String,
      default: 'application_status'
    },
    is_changed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    // 休暇申請画面へ
    goApplicationStatus() {
      if (this.view_type === 'application_status') return;
      if (this.is_changed) {
        this.$swal({
          text: "変更を保存していません。よろしいですか。",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.updateLoading(true);
            this.$inertia.get(this.$route('shifts.application-status', { year: this.year, month: this.month, office: this.office.id }));
          } else {
            return false;
          }
        });
      } else {
        this.updateLoading(true);
        this.$inertia.get(this.$route('shifts.application-status', { year: this.year, month: this.month, office: this.office.id }));
      }
    },
    // シフト画面へ
    goSchedule() {
      if (this.view_type === 'schedule') return;
      if (this.is_changed) {
        this.$swal({
          text: "変更を保存していません。よろしいですか。",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateLoading(true);
            this.$inertia.get(this.$route('shifts.schedule', { year: this.year, month: this.month, office: this.office.id }));
          } else {
            return false;
          }
        });
      } else {
        this.updateLoading(true);
        this.$inertia.get(this.$route('shifts.schedule', { year: this.year, month: this.month, office: this.office.id }));
      }
    },
    updateLoading(flg) {
      EventBus.$emit('updateLoading', flg);
    },
  },
  computed: {
    tab1_color() {
      if (this.view_type === 'application_status') {
        return 'secondary';
      } else {
        return ''
      }
    },
    tab2_color() {
      if (this.view_type === 'schedule') {
        return 'secondary';
      } else {
        return ''
      }
    },
    tab1_class() {
      if (this.view_type === 'application_status') {
        return 'tab tab-active';
      } else {
        return 'tab';
      }
    },
    tab2_class() {
      if (this.view_type === 'schedule') {
        return 'tab tab-active';
      } else {
        return 'tab';
      }
    },
    tab1_caption() {
      if (this.view_type === 'application_status') {
        return 'tab-active-caption';
      } else {
        return 'tab-passive-caption';
      }
    },
    tab2_caption() {
      if (this.view_type === 'schedule') {
        return 'tab-active-caption';
      } else {
        return 'tab-passive-caption';
      }
    },
  },
  mounted() {
  }
}
</script>
