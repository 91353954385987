<template>
  <div style="display: flex; height: 30px; padding-left: 27px;">
    <p style="padding: 0px;">表示設定</p>
    <v-checkbox
      v-if="view_type === 'schedule'"
      v-model="switch_view_sort"
      label="並替"
      class="switch-col-view"
      @change="changeOpenSort"
    ></v-checkbox>
    <v-checkbox
      v-model="switch_view_team"
      label="チーム名"
      class="switch-col-view"
      @change="changeOpenTeam"
    ></v-checkbox>
    <v-checkbox
      v-model="switch_view_flg"
      label="フラグ"
      class="switch-col-view"
      @change="changeOpenFlg"
    ></v-checkbox>
    <v-checkbox
      v-model="switch_view_occupation"
      label="職種"
      class="switch-col-view"
      @change="changeOpenOccupation"
    ></v-checkbox>
    <v-checkbox
      v-model="switch_view_table"
      label="上部の表"
      class="switch-col-view"
      @change="changeOpenTable"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'SwitchColView',
  components: {
  },
  props: {
    view_type: { 
      type: String, 
      default: 'schedule'
    },
  },
  data() {
    return {
      switch_view_sort: true,
      switch_view_team: true,
      switch_view_flg: true,
      switch_view_occupation: true,
      switch_view_table: true,
    }
  },
  methods: {
    changeOpenSort() {
      this.$emit('changeOpenSort');
    },
    changeOpenTeam() {
      this.$emit('changeOpenTeam');
    },
    changeOpenFlg() {
      this.$emit('changeOpenFlg');
    },
    changeOpenOccupation() {
      this.$emit('changeOpenOccupation');
    },
    changeOpenTable() {
      this.$emit('changeOpenTable');
    },
  },
  computed: {
  },
}
</script>
