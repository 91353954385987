<template>
<v-dialog v-model="dialog" @keydown.esc="dialog = false" @click:outside="onClickOutside()" persistent max-width="460">
    <div id="modalform" tabindex="-1" ref="modalform">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="all"
            label="すべて選択"
            value="all"
            @change="changeAll"
          ></v-checkbox>
          <div v-for="(item, key, index) in persons_belong_multiple_offices">
            <p style="font-size: 14px;">{{ item.staff_name }}さんは以下の事業所にも所属しています。同じシフトを設定したい場合は、対象を選択して職員へ展開ボタンをクリックしてください。</p>
            <div v-for="(office, key, index) in item.offices">
              <v-checkbox
                v-model="selected"
                :dense="true"
                :label="office.office_name"
                :value="office.id"
              ></v-checkbox>
            </div>
          </div>
          <div>
            兼務先にも同じシフトを設定する場合、兼務先でも同様に「シフトを職員へ展開」ボタンで職員へ最新のシフトを展開してください。
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="submit">職員へ展開</v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">キャンセル</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'SelectOfficeDialog',
  components: {
  },
  props: {
    persons_belong_multiple_offices: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      dialog: false,
      all: null,
      selected: [],
    }
  },
  methods: {
    openDialog(data) {
      this.selected = [];
      this.all = null;
      this.dialog = true;

      let interval = setInterval(() => {
        const elements = document.getElementsByClassName('v-dialog--active');
        if (!elements || !elements.length) {
          // 要素が取得できなかった場合は終了
          return;
        }
        elements[0].scrollTop = 0;
        clearInterval(interval);
      }, 50);
    },
    submit() {
      let target_list = {};
      for (let staff_id in this.persons_belong_multiple_offices) {
        for (let row of this.persons_belong_multiple_offices[staff_id].offices) {
          if (this.selected.indexOf(row.id) !== -1) {
            if (!(row.staff_id in target_list)) {
              target_list[staff_id] = [];
            }
            target_list[staff_id].push(row.office_id);
          }
        }
      }
      this.$emit('execDeployment', target_list);
      this.dialog = false;
    },
    onClickOutside() {
      this.$refs.modalform.focus();
      this.dialog = false;
    },
    changeAll() {
      if (this.all == "all") {
        this.selectedCatIds = [];
        for (let staff_id in this.persons_belong_multiple_offices) {
          for (let office of this.persons_belong_multiple_offices[staff_id].offices) {
            this.selected.push(office.id);
          }
        }
      } else {
        this.selected = [];
      }
    },
  },
  computed: {
  },
  mounted() {
  }
}
</script>

