<template>
  <table id="holiday-table" class="table table-Quantity" :style="'table-layout: fixed; border: none!important; width: ' + getWidth() + 'px;'">
    <thead>
        <tr>
          <th class="fixed fixed-add stripe_green"></th>
          <th class="fixed fixed01 show-holiday-count" style="text-align: center; z-index: 3!important; border-bottom: none!important;"></th>
          <th class="fixed fixed02 show-workpattern" style="text-align: center; z-index: 3!important">勤務区分</th>
          <th v-for="(day, index) in days" :key="index" class="summary" style="text-align: center;">
            {{ day.day }}(<span :style="{ color: getDateColor(day) }">{{ day.w }}</span>)
          </th>
        </tr>
      </thead>
    <tbody>
      <tr>
        <td :rowspan="rowspan" class="fixed fixed-add stripe_green" style="border: none!important;"></td>
        <td :rowspan="rowspan" class="fixed fixed01 show-holiday-count" style="text-align: center; vertical-align: middle; background: #F3F4F6; border-top: none!important;">
          希望休申請数
        </td>
      </tr>
      <DefaultCalcColumn
        v-for="(item, index) in work_categories"
        ref="defaultCalcColumn"
        :key="'default-calc-' + index"
        :category="item"
        :days="days"
        :list="list"
        :color_select_class_list="color_select_class_list"
      />
    </tbody>
  </table>
</template>

<style scoped>
.table-Quantity {
  border-collapse: separate;
  border-spacing: 0;
  background: #FFFFFF;
  margin-bottom: 0;
}
.table-Quantity th {
  height: 18px!important;
  color: #111927!important;
  font-size: 10px!important;
  font-weight: 500!important;
  background: #F3F4F6;
  border: solid 1px #D2D6DB;
  word-break: break-all;
  padding: 5px 5px!important;
}
.table-Quantity td {
  height: 18px!important;
  color: #111927!important;
  font-size: 10px!important;
  border: solid 1px #D2D6DB;
  word-break: break-all;
  padding: 11px 5px!important;
}
.table-Quantity .fixed {
  position: sticky;
  z-index: 2;
}
.table-Quantity .fixed-add {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  left: 0px;
  border: none!important;
}
.table-Quantity .fixed01 {
  width: 285px;
  min-width: 285px;
  max-width: 285px;
  left: 30px;
}
.table-Quantity .fixed02 {
  width: 285px;
  min-width: 285px;
  max-width: 285px;
  left: 315px;
}
.table-Quantity .summary {
  width: 42px;
  min-width: 42px;
  max-width: 42px;
}
.upper {
  background: #fcf3f2;
  color: red;
  font-weight: 900;
}
.default {
  background: #FFFFFF;
  color: black;
}
</style>

<script>
import DefaultCalcColumn from '@/Pages/Shifts/Components/Table/HolidayLimitTable/Default';

export default {
  name: 'ShiftsHolidayLimitTable',
  components: {
    DefaultCalcColumn,
  },
  props: {
    // 日付データ
    days: {
      type: Array,
      default: function() {
        return [];
      }
    },
    holiday_limit: {
      type: Object,
    },
    is_available_change_holiday_limit: {
      type: Boolean,
    },
    // ユーザの基本情報
    basic_infos: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // s11の勤務集計用
    // org_work_categories: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   }
    // },
    // 休暇申請状況データ
    shift_applies: {
      type: Object,
      default: function() {
        return {};
      }
    },
  },
  data () {
    return {
      work_categories: [],
      list: {},
      color_select_class_list: {},
      public_holiday_title: '所定休日',
      paid_holiday_title: '有休',
      other_holiday_title: 'その他',
      rowspan: 4,
    }
  },
  methods: {
    // tableの幅取得
    getWidth() {
      return 600 + (this.days.length * 42);
    },
    // カレンダーの曜日色取得
    getDateColor(day) {
      let result_color = day.color;
      if (day.holiday === true) {
        result_color = '#D61515';
      }
      return result_color;
    },
    calc() {
      // 集計0で初期化
      this.$set(this.list, this.public_holiday_title, {});
      this.$set(this.list, this.paid_holiday_title, {});
      this.$set(this.list, this.other_holiday_title, {});

      for (let key in this.days) {
        this.$set(this.list[this.public_holiday_title], this.days[key].day, 0);
        this.$set(this.list[this.paid_holiday_title], this.days[key].day, 0);
        this.$set(this.list[this.other_holiday_title], this.days[key].day, 0);
      }

      // 日毎（縦の計算）
      for (let key in this.days) {
        // 日付
        let day = this.days[key].day;
        // 年月日
        let full = this.days[key].full;
        // 休暇のstaff_idとworkpattern_categoryとworkpattern_typeのペアを格納する配列（重複カウント防止用）
        let pair = [];
        // スタッフごとにループ
        staff_loop: for (let staff of this.basic_infos) {
          // スタッフID
          let staff_id = staff.staff_id;
          // 希望データを取り出す(スタッフIDと年月日を指定)
          let col = this.shift_applies[staff_id].data[full];

          // データが存在する場合のみカウント
          // ここからカウント処理
          if (col && 'apply' in col) {
            // 希望申請データ
            let apply = col.apply;
            // 休暇タイプ
            let holiday_workpattern_type = apply.holiday_workpattern ? apply.holiday_workpattern.type : '';
            // 勤務カテゴリー
            let holiday_workpattern_category = apply.holiday_workpattern ? apply.holiday_workpattern.category : '';
            // 休暇でなければカウントしない
            if(holiday_workpattern_category !== 'holiday') continue;

            for (let tmp of pair) {
              // 半休（半勤務）の場合
              // 休日（全日：有休、所定休日、その他）の場合
              if (tmp === staff_id + '_' + holiday_workpattern_category + '_' + holiday_workpattern_type) {
                continue staff_loop;
              }
            }

            // 休日（全日）、半休（半勤務）の場合はペアに追加
            pair.push(staff_id + '_' + holiday_workpattern_category + '_' + holiday_workpattern_type);

            // カウント対象のカテゴリーによって処理を分岐
            switch (true) {
              // 所定休日（全休）
              case holiday_workpattern_type === 'public_holidays':
                this.$set(this.list[this.public_holiday_title], day, this.list[this.public_holiday_title][day] + 1);
                break;
              // 所定休日（半休）
              case holiday_workpattern_type === 'public_half_holiday':
                this.$set(this.list[this.public_holiday_title], day, this.list[this.public_holiday_title][day] + 0.5);
                break;
              // 有休（全休）
              case holiday_workpattern_type === 'paid_holidays':
                this.$set(this.list[this.paid_holiday_title], day, this.list[this.paid_holiday_title][day] + 1);
                break;
              // 有休（半休）
              case holiday_workpattern_type === 'paid_half_holiday':
                this.$set(this.list[this.paid_holiday_title], day, this.list[this.paid_holiday_title][day] + 0.5);
                break;
              // その他の休日（全休）
              case holiday_workpattern_type === 'other_holiday':
                this.$set(this.list[this.other_holiday_title], day, this.list[this.other_holiday_title][day] + 1);
                break;
              // その他の休日（半休）
              case holiday_workpattern_type === 'other_half_holiday':
                this.$set(this.list[this.other_holiday_title], day, this.list[this.other_holiday_title][day] + 0.5);
                break;
              default:
                break;
            }
          }
        }
        // 縦列のカラーの判定（休暇の合計が上限を超える場合には縦列赤色に）
        this.colorSelectClassList(this.list, day);
      }
    },
    // 日の合計（カテゴリーを超えて）が休暇上限を超えている場合は色を変える
    colorSelectClassList(list, day) {
      if(!this.holiday_limit.holiday) return;

      let sum = 0;
      for (let category in list) {
        // カテゴリーを超えて日毎の合計
        sum += list[category][day]
      }
      // 休暇上限を超えている場合は色を変える
      if (sum > this.holiday_limit.holiday[day]) {
        this.$set(this.color_select_class_list, day, 'upper');
      } else {
        this.$set(this.color_select_class_list, day, 'default');
      }
    },
  },
  watch: {
    // Mainテーブルにて休暇変更時に再計算
    shift_applies: {
      handler() {
        this.calc();
      },
      deep: true,
    },
    // チーム絞り込みや非表示などの変更時に再計算
    basic_infos: {
      handler() {
        this.calc();
      },
      deep: true,
    },
  },
  mounted() {
    // 勤務のカテゴリー表示はs11なのでコメント化
    let i = 0;
    // for (let key in this.org_work_categories) {
    //   if (key === 'holiday') continue;
    //   this.$set(this.work_categories, i, this.org_work_categories[key]);
    //   i++;
    // }
    this.$set(this.work_categories, i, this.public_holiday_title);
    this.$set(this.work_categories, i + 1, this.paid_holiday_title);
    this.$set(this.work_categories, i + 2, this.other_holiday_title);

    // rowspanはカテゴリーの数分+1
    this.rowspan = this.work_categories.length + 1;

    // カウント計算
    this.calc();
  },
  destroyed() {
    Object.keys(this.work_categories).forEach(key => {
      this.$delete(this.work_categories, key)
    });
  },
}
</script>
